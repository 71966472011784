import {useState} from "react";
import {useFormInput, useFormSelect, useFormCheck, useDatePicker} from "./index";
import {tiposExoneracion} from "../constants";
import {getDateFromStringWithTime, getStringFromDateWithTime} from "../helpers";

export const useExoneracion = () => {
  const [haciendaSearch, setHaciendaSearch] = useState(false);
  const [id, setId] = useState(0);
  const [cabys, setCabys] = useState([]);
  const poseeCabys = useFormCheck(false);
  const numeroDocumento = useFormInput("");
  const identificacion = useFormInput("");
  const nombreInstitucion = useFormInput("");
  const codigoProyectoCFIA = useFormInput(0);
  const porcentajeExoneracion = useFormInput(0);
  const tipoDocumento = useFormSelect("01", tiposExoneracion);
  const fechaEmisionDate = useDatePicker(undefined);
  const fechaVencimientoDate = useDatePicker(undefined);
  const helperInput = useFormInput("");

  const reset = () => {
    setId(0);
    setCabys([]);
    poseeCabys.setValue(false);
    numeroDocumento.setValue("");
    identificacion.setValue("");
    nombreInstitucion.setValue("");
    codigoProyectoCFIA.setValue(0);
    porcentajeExoneracion.setValue(0);
    tipoDocumento.setValue("01");
    fechaEmisionDate.setSelected(undefined);
    fechaVencimientoDate.setSelected(undefined);
  }

  const setObject = (data) => {
    setCabys(data.cabys || []);
    poseeCabys.setValue(!!data.poseeCabys);
    numeroDocumento.setValue(String(data.numeroDocumento || ""));
    identificacion.setValue(String(data.identificacion || ""));
    nombreInstitucion.setValue(String(data.nombreInstitucion || ""));
    codigoProyectoCFIA.setValue(Number(data.codigoProyectoCFIA || 0));
    porcentajeExoneracion.setValue(Number(data.porcentajeExoneracion || 0));
    tipoDocumento.setValue(String(data.tipoDocumento?.codigo || "01"));
    fechaEmisionDate.setSelected(data.fechaEmision ? getDateFromStringWithTime(data.fechaEmision) : undefined);
    fechaVencimientoDate.setSelected(data.fechaVencimiento ? getDateFromStringWithTime(data.fechaVencimiento) : undefined);
  }

  const cleanHaciendaSearch = (search = false) => {
    setTimeout(() => setHaciendaSearch(search));
    helperInput.setValue("");
    reset();
  }

  const getObject = () => {
    const object = {
      cabys,
      poseeCabys: poseeCabys.value,
      numeroDocumento: numeroDocumento.value,
      identificacion: identificacion.value,
      codigoProyectoCFIA: codigoProyectoCFIA.value,
      nombreInstitucion: nombreInstitucion.value,
      porcentajeExoneracion: porcentajeExoneracion.value,
      fechaEmision: fechaEmisionDate.selected ? getStringFromDateWithTime(fechaEmisionDate.selected) : "",
      fechaVencimiento: fechaVencimientoDate.selected ? getStringFromDateWithTime(fechaVencimientoDate.selected) : "",
      tipoDocumento: {
        codigo: tipoDocumento.value,
        descripcion: tiposExoneracion().find(item => item.value === tipoDocumento.value).text
      }
    };
    if (id) {
      object.id = id;
    }
    return object;
  }

  const agregarCabys = () => {
    if (helperInput.value) {
      setCabys([...cabys, helperInput.value]);
      helperInput.setValue("");
    }
  }

  const removerCabys = (value) => {
    setCabys(cabys.filter(item => item !== value));
  }

  return {
    fechaEmision: fechaEmisionDate.selected ? getStringFromDateWithTime(fechaEmisionDate.selected) : "",
    fechaVencimiento: fechaVencimientoDate.selected ? getStringFromDateWithTime(fechaVencimientoDate.selected) : "",
    helperInput,
    haciendaSearch,
    id,
    poseeCabys,
    cabys,
    numeroDocumento,
    identificacion,
    codigoProyectoCFIA,
    nombreInstitucion,
    porcentajeExoneracion,
    tipoDocumento,
    fechaEmisionDate,
    fechaVencimientoDate,
    tipoDocumentoDescripcion: () => tiposExoneracion().find(item => item.value === tipoDocumento.value).text,
    setHaciendaSearch,
    setId,
    reset,
    setObject,
    getObject,
    agregarCabys,
    removerCabys,
    cleanHaciendaSearch
  };
}
