import {useEffect} from "react";
import {useCodigoActividad as useItem, usePageHandler} from "../customHooks";
import {
  createCodigoActividadRequest as createRequest, updateCodigoActividadRequest as updateRequest, deleteCodigoActividadRequest as deleteRequest,
  listCodigosActividadRequest as listRequest, haciendaConsultarContribuyente, initForm
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert,
  CustomTextField, CustomSelectField, CustomPagination, CustomTableHeader
} from "../components";
const pageLabels = {
  itemCreate: "Crear c\u00f3digo de actividad", itemUpdate: "Modificar c\u00f3digo de actividad", itemDelete: "Eliminar c\u00f3digo de actividad",
  itemCreated: "C\u00f3digo de actividad creado", itemUpdated: "C\u00f3digo de actividad modificado", itemDeleted: "C\u00f3digo de actividad eliminado",
  totalItems: "C\u00f3digos de actividad", genericError: "Ha ocurrido un error"
};

export const CodigoActividadPage = ({controller}) => {
  const cuenta = controller.usuario.cuenta.get;
  const pageHandler = usePageHandler(controller, useItem, cuenta);

  useEffect(() => {
    if (cuenta) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [cuenta]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          if (pageHandler.modalAction === "create") {
            await createRequest(pageHandler.item.getObject);
          } else {
            await updateRequest(pageHandler.item.getObject, pageHandler.item.id);
          }
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
          pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const handleDelete = () => {
    const remove = async() => {
      try {
        await deleteRequest(pageHandler.item.id);
        await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
        pageHandler.handleDeleteItem(pageLabels.itemDeleted);
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    remove();
  }

  const closeModal = () => {
    pageHandler.setOpenModal(false);
    pageHandler.item.buscarPersona.cleanHaciendaSearch();
  }

  const handleSearch = () => {
    const search = async() => {
      try {
        pageHandler.initProcess(true);
        const searchResult = await haciendaConsultarContribuyente(pageHandler.item.buscarPersona.searchInput.value);
        if (pageHandler.isMounted) {
          pageHandler.item.buscarPersona.setObject(searchResult);
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
      } 
    }
    search();
  }

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const handleSeleccionar = (codigo) => {
    if (pageHandler.isMounted) {
      pageHandler.item.setBusqueda(codigo);
      pageHandler.item.buscarPersona.cleanHaciendaSearch();
    }
  }

  if (cuenta) {
    const haciendaSearch = pageHandler.item.buscarPersona.haciendaSearch;
    const collapse = controller.session.width < 1000;

    return (
      <Container className="full-container">
        <Modal centered animation={false} show={pageHandler.modalAction === "delete"} onHide={() => pageHandler.setModalAction("")}>
          <Modal.Header closeButton><Modal.Title>{pageLabels.itemDelete}</Modal.Title></Modal.Header>
          <Modal.Body>Confirmar esta acci&oacute;n</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.setModalAction("")}>Cerrar</Button>
            <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={pageHandler.openModal} onHide={closeModal} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : pageLabels.itemUpdate}</Modal.Title>
              <Button className="ml-20" variant="primary" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.buscarPersona.cleanHaciendaSearch(!haciendaSearch)}>
                {haciendaSearch ? "Regresar" : <>Buscar por n&uacute;mero de identificaci&oacute;n</>}
              </Button>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              {
                haciendaSearch ? (
                  <Row>
                    <Col lg={5}>
                      <CustomTextField id="searchIdentificacion" label="N&uacute;mero de Identificaci&oacute;n" input={pageHandler.item.buscarPersona.searchInput.input} onKeyDown={handleSearchEnter}/>
                      <Row className="ml-0"><strong>Nombre:</strong>&nbsp;{pageHandler.item.buscarPersona.nombre}</Row>
                      <Row className="ml-0"><strong>Tipo de Identificaci&oacute;n:</strong>&nbsp;{pageHandler.item.buscarPersona.getTipoIdentificacion()}</Row>
                      <Row className="ml-0"><strong>N&uacute;mero de Identificaci&oacute;n:</strong>&nbsp;{pageHandler.item.buscarPersona.numeroIdentificacion}</Row>
                      <Row className="ml-0"><strong>R&eacute;gimen:</strong>&nbsp;{pageHandler.item.buscarPersona.regimen}</Row>
                      <Row className="ml-0"><strong>Administraci&oacute;n Tributaria:</strong>&nbsp;{pageHandler.item.buscarPersona.administracion}</Row>
                      <Row className="ml-0"><strong>Estado:</strong>&nbsp;{pageHandler.item.buscarPersona.estado}</Row>
                    </Col>
                    <Col lg={7}>
                      <Row className="ml-0"><strong>Actividades:</strong></Row>
                      {
                        pageHandler.item.buscarPersona.actividades.length ?
                          <Row>
                            {
                              controller.session.width < 1200 ? (
                                pageHandler.item.buscarPersona.getActividades().map((element) =>
                                  <Row className="m-5" key={element.codigo}>
                                    <Card border="secondary" className="full-size">
                                      <Card.Header className="font-bold">C&oacute;digo: {element.codigo}</Card.Header>
                                      <ListGroup variant="flush">
                                        <ListGroup.Item>Descripci&oacute;n: {element.descripcion}</ListGroup.Item>
                                        <ListGroup.Item>Tipo: {element.tipo}</ListGroup.Item>
                                        <ListGroup.Item>Estado: {element.estado}</ListGroup.Item>
                                        <ListGroup.Item><Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => handleSeleccionar(element) : null}>Seleccionar</Button></ListGroup.Item>
                                      </ListGroup>
                                    </Card>
                                  </Row>
                                )
                              ) : (
                                <Table className="mr-10 mt-8" striped bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th>C&oacute;digo</th>
                                      <th>Descripci&oacute;n</th>
                                      <th>Tipo</th>
                                      <th>Estado</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                      pageHandler.item.buscarPersona.getActividades().map((element) =>
                                        <tr key={element.codigo}>
                                          <td>{element.codigo}</td>
                                          <td>{element.descripcion}</td>
                                          <td>{element.tipo}</td>
                                          <td>{element.estado}</td>
                                          <th><Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => handleSeleccionar(element) : null}>Seleccionar</Button></th>
                                        </tr>
                                      )
                                    }
                                  </tbody>
                                </Table>
                              )
                            }
                          </Row>
                        : null
                      }
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col lg>
                      <CustomSelectField id="itemTipo" label="Tipo de C&oacute;digo" input={pageHandler.item.isPrincipal.select} options={pageHandler.item.isPrincipal.options} />
                      <CustomTextField required id="itemNombre" label="Nombre" input={pageHandler.item.nombre.input} invalid="Este campo es requerido" />
                    </Col>
                    <Col lg>
                      <CustomTextField required id="itemCodigo" label="C&oacute;digo de actividad" input={pageHandler.item.codigoActividad.input} invalid="Este campo es requerido" />
                    </Col>
                  </Row>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal} disabled={pageHandler.isLoading}>Cerrar</Button>
              {
                haciendaSearch
                ? <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSearch : null}>{pageHandler.isLoading ? "Buscando…" : "Buscar"}</Button>
                : <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
              }
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems} showBadge={collapse} />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Nombre: {element.nombre}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>C&oacute;digo de actividad: {element.codigoActividad}</ListGroup.Item>
                    <ListGroup.Item>Tipo de C&oacute;digo: {element.isPrincipal === "P" ? "Principal" : "Secundario"}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                      <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="isPrincipal" headerLabel="Tipo de C&oacute;digo" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="nombre" headerLabel="Nombre" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="codigoActividad" headerLabel="C&oacute;digo de actividad" />
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      <td>{element.isPrincipal === "P" ? "Principal" : "Secundario"}</td>
                      <td>{element.nombre}</td>
                      <td>{element.codigoActividad}</td>
                      <td>
                        <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                        <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}