import {useState, useRef} from "react";
import {useFormSelect, useFormInput} from "./index";
import {localStringDate, roundNumber} from "../helpers";
import {codigosMensaje, condicionesImpuesto, tiposIdentificacion} from "../constants";

export const useAceptarFactura= () => {
  const [index, setIndex] = useState("");
  const [codigoActividad, setCodigoActividad] = useState("");
  const mensaje = useFormSelect("", codigosMensaje);
  const detalleMensaje = useFormInput("");
  const condicionImpuesto = useFormSelect("", condicionesImpuesto);
  const montoTotalImpuestoAcreditar = useFormInput("");
  const montoTotalDeGastoAplicable = useFormInput("");
  const codigosActividad = useRef([]);

  const setCodigos = (codigos) => {
    codigos.sort((a,b) => (a.isPrincipal > b.isPrincipal) ? 1 : ((b.isPrincipal > a.isPrincipal) ? -1 : 0));
    codigosActividad.current = codigos;
  }

  const handleCodigoActividad = (e) => setCodigoActividad(e.target.value);

  const displayCodigosActividad = () => codigosActividad.current.map(element => <option key={element.codigoActividad} value={element.codigoActividad}>{element.codigoActividad}</option>)

  const reset = () => {
    setIndex("");
    setCodigoActividad("");
    mensaje.setValue("");
    detalleMensaje.setValue("");
    condicionImpuesto.setValue("");
    montoTotalImpuestoAcreditar.setValue("");
    montoTotalDeGastoAplicable.setValue("");
  }

  const setObject = (item, itemIndex) => {
    setIndex(itemIndex);
    setCodigoActividad(item.codigoActividad);
    mensaje.setValue(item.mensaje);
    detalleMensaje.setValue(item.detalleMensaje);
    condicionImpuesto.setValue(item.condicionImpuesto);
    montoTotalImpuestoAcreditar.setValue(item.montoTotalImpuestoAcreditar);
    montoTotalDeGastoAplicable.setValue(item.montoTotalDeGastoAplicable);
  }

  const parse = (data, xml) => {
    if (data) {
      const lineas = Array.isArray(data.DetalleServicio?.LineaDetalle) ? data.DetalleServicio.LineaDetalle : [data.DetalleServicio?.LineaDetalle];
      return {
        fechaEmisionFactura: data.FechaEmision ?? "",
        tipoIdentificacionEmisor: data.Emisor?.Identificacion?.Tipo ?? "01",
        numeroCedulaEmisor: data.Emisor?.Identificacion?.Numero ?? "",
        nombreEmisor: data.Emisor?.Nombre ?? "",
        tipoIdentificacionReceptor: data.Receptor?.Identificacion?.Tipo ?? "01",
        numeroCedulaReceptor: data.Receptor?.Identificacion?.Numero ?? "",
        codigoActividad: codigosActividad.current[0]?.codigoActividad ?? "",
        clave: data.Clave ?? "",
        mensaje: "1",
        detalleMensaje: "Aceptado",
        condicionImpuesto: "04",
        montoTotalImpuesto: roundNumber(data.ResumenFactura?.TotalImpuesto) ?? "0.00000",
        montoTotalImpuestoAcreditar: "0.00000",
        montoTotalDeGastoAplicable: roundNumber(data.ResumenFactura?.TotalComprobante) ?? "0.00000",
        totalFactura: roundNumber(data.ResumenFactura?.TotalComprobante) ?? "0.00000",
        lineaDetalleAceptadas: lineas.map((item, index) => ({
          ...(item ? {
            numeroLinea: item.NumeroLinea ?? index+1,
            codigo: item.Codigo ?? "",
            ...(item.CodigoComercial ? {
              codigoComercial: [{
                tipo: item.CodigoComercial.Tipo ?? "",
                codigo: item.CodigoComercial.Codigo ?? ""
              }],
            } : {}),
            cantidad: roundNumber(item.Cantidad, 3),
            unidadMedida: item.UnidadMedida ?? "",
            unidadMedidaComercial: item.UnidadMedidaComercial ?? "",
            detalle: item.Detalle ?? "",
            precioUnitario: roundNumber(item.PrecioUnitario),
            montoTotal: roundNumber(item.MontoTotal),
            ...(item.Descuento ? {
              descuento: [{
                montoDescuento: item.Descuento.MontoDescuento ?? "",
                naturalezaDescuento: roundNumber(item.Descuento.NaturalezaDescuento)
              }],
            } : {}),
            subTotal: roundNumber(item.SubTotal),
            baseImponible: roundNumber(item.BaseImponible),
            ...(item.Impuesto ? {
              impuesto: [{
                codigo: item.Impuesto.Codigo ?? "",
                codigoTarifa: item.Impuesto.CodigoTarifa ?? "",
                tarifa: roundNumber(item.Impuesto.Tarifa, 2),
                factorIVA: roundNumber(item.Impuesto.FactorIVA, 4),
                monto: roundNumber(item.Impuesto.Monto),
                ...(item.Impuesto.Exoneracion ? {
                  exoneracion: {
                    tipoDocumento: item.Impuesto.Exoneracion.TipoDocumento ?? "",
                    numeroDocumento: item.Impuesto.Exoneracion.NumeroDocumento ?? "",
                    nombreInstitucion: item.Impuesto.Exoneracion.NombreInstitucion ?? "",
                    fechaEmision: item.Impuesto.Exoneracion.FechaEmision ?? "",
                    porcentajeExoneracion: item.Impuesto.Exoneracion.PorcentajeExoneracion ?? "",
                    montoExoneracion: roundNumber(item.Impuesto.Exoneracion.MontoExoneracion)
                  }
                } : {}),
              }],
            } : {}),
            impuestoNeto: roundNumber(item.ImpuestoNeto),
            montoTotalLinea: roundNumber(item.MontoTotalLinea)
          } : {})
        })),
        jsonFacturaAceptada: xml,
        helper: {
          nombreReceptor: data?.Receptor?.Nombre ?? "--",
          correoEmisor: data?.Emisor?.CorreoElectronico ?? "--",
          consecutivo: data?.NumeroConsecutivo ?? "--",
          fecha: localStringDate(data?.FechaEmision),
        },
      };
    }
    return {};
  }

  const getMensaje = ({helper, ...item}) => ({
    ...item,
    fechaEmisionDoc: (new Date()).toISOString()
  });

  const getTipoIdentificacion = (rawValue) => tiposIdentificacion().find(element => element.value === rawValue)?.text ?? "--"

  const parseRespuesta = (data, sucursales) => ({
    clave: String(data.clave ?? "--"),
    codigoActividad: String(data.codigoActividad ?? "--"),
    condicionImpuesto: condicionImpuesto.getText(String(data.condicionImpuesto)) ?? "--",
    detalleMensaje: String(data.detalleMensaje ?? "--"),
    fechaEmisionDoc: localStringDate(data.fechaEmisionDoc),
    fechaEmisionFactura: localStringDate(data.fechaEmisionFactura),
    mensaje: mensaje.getText(String(data.mensaje)) ?? "--",
    montoTotalDeGastoAplicable: String(data.montoTotalDeGastoAplicable ?? "--"),
    montoTotalImpuesto: String(data.montoTotalImpuesto ?? "--"),
    montoTotalImpuestoAcreditar: String(data.montoTotalImpuestoAcreditar ?? "--"),
    nombreEmisor: String(data.nombreEmisor ?? "--"),
    numeroCedulaEmisor: String(data.numeroCedulaEmisor ?? "--"),
    numeroCedulaReceptor: String(data.numeroCedulaReceptor ?? "--"),
    numeroConsecutivoReceptor: String(data.numeroConsecutivoReceptor ?? "--"),
    statusDeEnvio: String(data.statusDeEnvio ?? "--"),
    statusDeRespuesta: String(data.statusDeRespuesta ?? "--"),
    statusFactura: String(data.statusFactura ?? "--"),
    sucursal: sucursales.find(element => element.value === Number(data.sucursal))?.text ?? "--",
    tipoIdentificacionEmisor: getTipoIdentificacion(String(data.tipoIdentificacionEmisor)),
    tipoIdentificacionReceptor: getTipoIdentificacion(String(data.tipoIdentificacionReceptor)),
    totalFactura: String(data.totalFactura ?? "--"),
    lineaDetalleAceptadas: data.lineaDetalleAceptadas?.length > 0 ? data.lineaDetalleAceptadas : []
  })

  return {
    getObject: {
      codigoActividad,
      mensaje: mensaje.value,
      detalleMensaje: detalleMensaje.value,
      condicionImpuesto: condicionImpuesto.value,
      montoTotalImpuestoAcreditar: roundNumber(montoTotalImpuestoAcreditar.value),
      montoTotalDeGastoAplicable: roundNumber(montoTotalDeGastoAplicable.value)
    },
    codigoActividad: {value: codigoActividad, onChange: handleCodigoActividad},
    index,
    mensaje,
    detalleMensaje,
    condicionImpuesto,
    montoTotalImpuestoAcreditar,
    montoTotalDeGastoAplicable,
    displayCodigosActividad,
    setObject,
    reset,
    parse,
    setCodigos,
    getMensaje,
    parseRespuesta
  };
}