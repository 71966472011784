import {useEffect} from "react";
import {useLogin as useItem, usePageHandler} from "../customHooks";
import {loginRequest, getUserCuentasRequest, getUserSucursalesRequest, initForm} from "../helpers";
import {Row, Col, Form, Button, CustomAlert, CustomTextField, CustomCheckField, Card} from "../components";

export const LoginPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);

  useEffect(() => {
    if (controller.session.unauthorized.get) {
      controller.session.unauthorized.set(false);
      pageHandler.setAlert(String("La sesi\u00f3n ha expirado"));
      pageHandler.setShowAlert(true);
    }
  }, [controller.session.unauthorized.get]);

  useEffect(() => {
    if (controller.session.authenticated.get) {
      controller.session.history.push("/");
    }
  }, [controller.session.authenticated.get]);

  const handleSubmit = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const login = async() => {
        try {
          pageHandler.initProcess(true);
          const [usuarioId, usuarioRol] = await loginRequest(pageHandler.item.username.value, pageHandler.item.password.value, pageHandler.item.rememberMe.value);
          const cuentas = await getUserCuentasRequest(usuarioId);
          if (cuentas && cuentas.length === 1) {
            const sucursales = await getUserSucursalesRequest(usuarioId, cuentas[0].value);
            if (pageHandler.isMounted) {
              controller.usuario.sucursal.set(sucursales && sucursales.length === 1 ? sucursales[0].value : 0);
              controller.usuario.sucursales.set(sucursales);
              controller.usuario.cuenta.set(cuentas[0].value);
            }
          }
          if (pageHandler.isMounted) {
            controller.usuario.cuentas.set(cuentas);
            controller.usuario.id.set(usuarioId);
            controller.usuario.rol.set(usuarioRol);
            pageHandler.setIsLoading(false);
            controller.session.authenticated.set(true);
          }
        } catch(error) {
          pageHandler.handleAlert(error ? error : "Ha ocurrido un error");
        }
      }
      login();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  if (!controller.session.authenticated.get && controller.session.showLogin.get) {
    return (
      <Row className="container-centered">
        <Col xs={10} sm={6} md={5} lg={4} xl={3}>
          <Card bg="light">
            <Card.Body>
              <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSubmit : null}>
                <CustomAlert type="danger" dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
                <CustomTextField required id="loginFormUsername" label="Usuario" input={pageHandler.item.username.input} invalid="Este campo es requerido" />
                <CustomTextField required type="password" id="loginFormPassword" label="Contrase&ntilde;a" input={pageHandler.item.password.input} invalid="Este campo es requerido" />
                <CustomCheckField id="loginFormRemember" label="Mantener sesi&oacute;n abierta" input={pageHandler.item.rememberMe.check} />
                <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Cargando…" : <>Iniciar sesi&oacute;n</>}</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
  return null;
}