import {useState, useEffect} from "react";
import {useCuenta as useItem, usePageHandler} from "../customHooks";
import {
  createCuentaRequest as createRequest, updateCuentaRequest as updateRequest, listCuentasRequest as listRequest, uploadLogoRequest, cleanStringDate,
  getUsuarioCuentasRequest, uploadLlaveRequest, probarLlaveRequest, createCodigoActividadRequest, getUserCuentasRequest, initForm,
  haciendaConsultarContribuyente, haciendaConsultarCorreo
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert, CustomTextField, CustomSelectField,
  CustomPagination, CustomTableHeader, CustomCheckField, Image
} from "../components";

const pageLabels = {
  itemCreate: "Crear cuenta", itemUpdate: "Modificar cuenta", itemCreated: "Cuenta creada",
  itemUpdated: "Cuenta modificada", totalItems: "Cuentas", genericError: "Ha ocurrido un error"
};

export const CuentaPage = ({controller}) => {
  const [showLogo, setShowLogo] = useState(false);
  const [logo, setLogo] = useState("");
  const pageHandler = usePageHandler(controller, useItem);
  const usuario = controller.usuario.id.get;
  const haciendaSearch = pageHandler.item.buscarPersona.haciendaSearch;
  const collapse = controller.session.width < 1200;

  useEffect(() => {
    if (controller.usuario.rol.get && usuario) {
      loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
    }
  }, [controller.usuario.rol.get]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = pageHandler.isSuperAdmin ? await listRequest({page, size, sort}) : await getUsuarioCuentasRequest(usuario, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          let cuenta = undefined, cuentas = undefined;
          if (pageHandler.modalAction === "create") {
            cuenta = await createRequest(pageHandler.item.getObject);
            cuentas = await getUserCuentasRequest(usuario);
          } else {
            cuenta = await updateRequest(pageHandler.item.getObject, pageHandler.item.id);
          }
          if (cuenta?.id && pageHandler.item.actividades?.length) {
            for (let i = 0; i < pageHandler.item.actividades.length; i++) {
              await createCodigoActividadRequest(pageHandler.item.codigoActividad.getFromBusqueda(pageHandler.item.actividades[i], cuenta.id));
            }
          }
          if (pageHandler.isMounted) {
            if (cuentas) {
              controller.usuario.cuentas.set(cuentas);
            }
            if (controller.usuario.cuenta.data?.id === cuenta?.id) {
              controller.usuario.cuenta.setData(cuenta);
            }
            await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
            pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
          }
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const handleUploadLlave = (files, id) => {
    if (files && files.length) {
      const uploadLlave = async() => {
        try {
          if (files[0].type === "application/x-pkcs12" || files[0].name.endsWith(".p12")) {
            pageHandler.initProcess(true);
            const response = await uploadLlaveRequest(files[0], id);
            await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
            pageHandler.handleAlert(response);
          } else {
            pageHandler.handleAlert("Seleccione el archivo correcto, llave criptogr\u00e1fica con formato .p12", "warning");
          }
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError);
        }
      }
      uploadLlave();
    }
  }

  const handleProbarLlave = (id) => {
    const probarLlave = async() => {
      try {
        pageHandler.initProcess(true);
        const response = await probarLlaveRequest(id);
        pageHandler.handleAlert(response);
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    probarLlave();
  }

  const handleUploadLogo = (files, id) => {
    if (files && files.length) {
      const uploadLogo = async() => {
        try {
          if (files[0].type.startsWith("image/")) {
            pageHandler.initProcess(true);
            const response = await uploadLogoRequest(files[0], id);
            await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
            if (pageHandler.isMounted) {
              if (controller.usuario.cuenta.data?.id === id) {
                controller.usuario.cuenta.getCuentaData();
              }
              pageHandler.handleAlert(response);
            }
          } else {
            pageHandler.handleAlert("Seleccione el archivo correcto, imagen v\u00e1lida", "warning");
          }
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError);
        }
      }
      uploadLogo();
    }
  }

  const closeModal = () => {
    pageHandler.setOpenModal(false);
    pageHandler.item.buscarPersona.cleanHaciendaSearch();
  }

  const handleSearch = () => {
    const search = async() => {
      try {
        pageHandler.initProcess(true);
        const searchResult = await haciendaConsultarContribuyente(pageHandler.item.buscarPersona.searchInput.value);
        const {Resultado} = await haciendaConsultarCorreo(pageHandler.item.buscarPersona.searchInput.value);
        if (pageHandler.isMounted) {
          pageHandler.item.buscarPersona.setObject(searchResult, Resultado?.Correos?.[0].Correo);
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
      } 
    }
    search();
  }

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const handleSeleccionar = () => {
    if (pageHandler.isMounted) {
      pageHandler.item.setBusqueda();
      pageHandler.item.buscarPersona.cleanHaciendaSearch();
    }
  }

  const handleShowLogo = (logo, type) => {
    setLogo(`data:image/${type ?? "png"};base64, ${logo}`);
    setShowLogo(true);
  }

  const handleCloseLogo = () => {
    setShowLogo(false);
    setLogo("");
  }

  return (
    <Container className="full-container">
      <Modal size="sm" centered show={showLogo} onHide={handleCloseLogo} animation={false}>
          <Modal.Header closeButton><Modal.Title id="infoTitle">Logo</Modal.Title></Modal.Header>
          <Modal.Body className="m-auto"><Image src={logo} width="266" /></Modal.Body>
          <Modal.Footer><Button variant="secondary" onClick={handleCloseLogo}>Cerrar</Button></Modal.Footer>
      </Modal>
      <Modal size="xl" centered show={pageHandler.openModal} onHide={closeModal} animation={false} >
        <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
          <Modal.Header closeButton>
            <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : pageLabels.itemUpdate}</Modal.Title>
            <Button className="ml-20" variant="primary" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.buscarPersona.cleanHaciendaSearch(!haciendaSearch)}>
              {haciendaSearch ? "Regresar" : <>Buscar por n&uacute;mero de identificaci&oacute;n</>}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
            {
              haciendaSearch ? (
                <Row>
                  <Col lg={5}>
                    <CustomTextField id="searchIdentificacion" label="N&uacute;mero de Identificaci&oacute;n" input={pageHandler.item.buscarPersona.searchInput.input} onKeyDown={handleSearchEnter}/>
                    <Row className="ml-0"><strong>Nombre:</strong>&nbsp;{pageHandler.item.buscarPersona.nombre}</Row>
                    <Row className="ml-0"><strong>Tipo de Identificaci&oacute;n:</strong>&nbsp;{pageHandler.item.buscarPersona.getTipoIdentificacion()}</Row>
                    <Row className="ml-0"><strong>N&uacute;mero de Identificaci&oacute;n:</strong>&nbsp;{pageHandler.item.buscarPersona.numeroIdentificacion}</Row>
                    <Row className="ml-0"><strong>R&eacute;gimen:</strong>&nbsp;{pageHandler.item.buscarPersona.regimen}</Row>
                    <Row className="ml-0"><strong>Administraci&oacute;n Tributaria:</strong>&nbsp;{pageHandler.item.buscarPersona.administracion}</Row>
                    <Row className="ml-0"><strong>Estado:</strong>&nbsp;{pageHandler.item.buscarPersona.estado}</Row>
                    <Row className="ml-0"><strong>Correo Electr&oacute;nico:</strong>&nbsp;{pageHandler.item.buscarPersona.correoElectronico}</Row>
                  </Col>
                  <Col lg={7}>
                    <Row className="ml-0"><strong>Actividades:</strong></Row>
                    {
                      pageHandler.item.buscarPersona.actividades.length ?
                        <Row>
                          {
                            collapse ? (
                              pageHandler.item.buscarPersona.getActividades().map((element) =>
                                <Row className="m-5" key={element.codigo}>
                                  <Card border="secondary" className="full-size">
                                    <Card.Header className="font-bold">C&oacute;digo: {element.codigo}</Card.Header>
                                    <ListGroup variant="flush">
                                      <ListGroup.Item>Descripci&oacute;n: {element.descripcion}</ListGroup.Item>
                                      <ListGroup.Item>Tipo: {element.tipo}</ListGroup.Item>
                                      <ListGroup.Item>Estado: {element.estado}</ListGroup.Item>
                                      <ListGroup.Item><Form.Check label='Agregar' type="checkbox" name={element.codigo} checked={element.agregar} onChange={pageHandler.item.buscarPersona.agregarActividad} /></ListGroup.Item>
                                    </ListGroup>
                                  </Card>
                                </Row>
                              )
                            ) : (
                              <Table className="mr-10 mt-8" striped bordered hover size="sm">
                                <thead>
                                  <tr>
                                    <th>C&oacute;digo</th>
                                    <th>Descripci&oacute;n</th>
                                    <th>Tipo</th>
                                    <th>Estado</th>
                                    <th>Agregar</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
                                    pageHandler.item.buscarPersona.getActividades().map((element) =>
                                      <tr key={element.codigo}>
                                        <td>{element.codigo}</td>
                                        <td>{element.descripcion}</td>
                                        <td>{element.tipo}</td>
                                        <td>{element.estado}</td>
                                        <th><Form.Check type="checkbox" name={element.codigo} checked={element.agregar} onChange={pageHandler.item.buscarPersona.agregarActividad} /></th>
                                      </tr>
                                    )
                                  }
                                </tbody>
                              </Table>
                            )
                          }
                        </Row>
                      : null
                    }
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg>
                    <CustomTextField required id="itemNombre" label="Nombre" input={pageHandler.item.nombre.input} invalid="Este campo es requerido" />
                    <CustomTextField id="itemNombreComercial" label="Nombre Comercial" input={pageHandler.item.nombreComercial.input} invalid="Este campo es requerido" />
                    <CustomSelectField id="itemTipoIdentificacion" label="Tipo de Identificaci&oacute;n" input={pageHandler.item.identificacion.tipoIdentificacion} options={pageHandler.item.identificacion.tiposIdentificacion} />
                    <CustomTextField required id="itemNumeroIdentificacion" label="N&uacute;mero de Identificaci&oacute;n" input={pageHandler.item.identificacion.numero} invalid="Este campo es requerido" />
                    <CustomSelectField id="itemAmbienteHacienda" label="Ambiente (Hacienda)" input={pageHandler.item.isProduction.select} options={pageHandler.item.isProduction.options} />
                    <CustomTextField required id="itemUsuario" label="Usuario (Hacienda)" input={pageHandler.item.usuario.input} invalid="Este campo es requerido" />
                    <CustomTextField required id="itemContrasena" label="Contrase&ntilde;a (Hacienda)" input={pageHandler.item.contrasena.input} invalid="Este campo es requerido" />
                    <CustomTextField required id="itemPin" label="PIN (Hacienda)" input={pageHandler.item.pin.input} invalid="Este campo es requerido" />
                    {
                      pageHandler.isSuperAdmin ? (
                        <>
                          <CustomTextField required id="itemCantidadSucursales" label="Cantidad de Sucursales" input={pageHandler.item.cantSucursales.input} invalid="Este campo es requerido" />
                          <CustomTextField required id="itemCantidadUsuarios" label="Cantidad de Usuarios" input={pageHandler.item.cantUsuario.input} invalid="Este campo es requerido" />
                          <CustomTextField required id="itemMensualidad" label="Mensualidad" input={pageHandler.item.precio.input} invalid="Este campo es requerido" />
                        </>
                      ) : null
                    }
                    <CustomCheckField id="itemEnviarEmailTiquete" label="Enviar Correo Tiquetes" input={pageHandler.item.enviarEmailTiquete.check} />
                    <CustomCheckField id="itemEnviarEmailNotaCredito" label="Enviar Correo Notas de Cr&eacute;dito" input={pageHandler.item.enviarEmailNotaCredito.check} />
                    <CustomCheckField id="itemEmailMensajeReceptor" label="Enviar Correo Mensaje Recepci&oacute;n" input={pageHandler.item.enviarEmailMensajeReceptor.check} />
                  </Col>
                  <Col lg>
                    <CustomSelectField id="itemProvincia" label="Provincia" input={pageHandler.item.ubicacion.provincia} options={pageHandler.item.ubicacion.provincias} />
                    <CustomSelectField id="itemCanton" label="Cant&oacute;n" input={pageHandler.item.ubicacion.canton} options={pageHandler.item.ubicacion.cantones} />
                    <CustomSelectField id="itemDistrito" label="Distrito" input={pageHandler.item.ubicacion.distrito} options={pageHandler.item.ubicacion.distritos} />
                    <CustomSelectField id="itemBarrio" label="Barrio" input={pageHandler.item.ubicacion.barrio} options={pageHandler.item.ubicacion.barrios} />
                    <CustomTextField required id="itemDireccion" label="Direcci&oacute;n Exacta" input={pageHandler.item.ubicacion.otrasSenas} invalid="Este campo es requerido" />
                    <CustomSelectField id="itemCodigoTelefono" label="C&oacute;digo de Pa&iacute;s" input={pageHandler.item.telefono.codigoPais} options={pageHandler.item.telefono.codigosPais} />
                    <CustomTextField id="itemNumeroTelefono" label="N&uacute;mero de Tel&eacute;fono" input={pageHandler.item.telefono.numTelefono} invalid="Este campo es requerido" />
                    <CustomTextField required id="itemCorreo" label="Correo Electr&oacute;nico" input={pageHandler.item.correoElectronico.input} invalid="Formato de correo incorrecto" />
                    {
                      pageHandler.isSuperAdmin ? (
                        <>
                          <CustomTextField required id="itemMesCancelado" label="Mes Cancelado" input={pageHandler.item.ultimoMes.input} invalid="Este campo es requerido" />
                          <CustomTextField required id="itemMensaje" label="N&uacute;mero de Mensaje" input={pageHandler.item.mostrarMsj.input} invalid="Este campo es requerido" />
                          <CustomSelectField id="itemEstado" label="Estado de Cuenta" input={pageHandler.item.activo.select} options={pageHandler.item.activo.options} />
                        </>
                      ) : null
                    }
                  </Col>
                </Row>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal} disabled={pageHandler.isLoading}>Cerrar</Button>
            {
              haciendaSearch
              ? pageHandler.item.buscarPersona.nombre !== "--"
              ? <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSeleccionar : null}>Seleccionar</Button>
              : <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSearch : null}>{pageHandler.isLoading ? "Buscando…" : "Buscar"}</Button>
              : <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
      <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
      <CustomPagination
        pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems}
        showButton={pageHandler.isSuperAdmin} showBadge={collapse}
      />
      {
        collapse ? (
          pageHandler.items.map((element) =>
            <Row className="m-0" key={element.id}>
              <Card border="secondary" className="full-size mb-10">
                <Card.Header className="font-bold">Nombre: {element.nombre}</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>Identificaci&oacute;n: {element.identificacion.numero}</ListGroup.Item>
                  <ListGroup.Item>Mes Pago: {element.ultimoMes}</ListGroup.Item>
                  <ListGroup.Item>Mensualidad: {element.precio}</ListGroup.Item>
                  <ListGroup.Item>
                    Llave: {element.ultimaLlave ? <>Cargada ({cleanStringDate(element.ultimaLlave)})</> : null}
                    <Form>
                      <Form.File
                        className="width-96 float-start ml-10 mt-5" disabled={pageHandler.isLoading} id={`llaveCriptografica${element.id}`}
                        label="" data-browse={element.llave ? "Actualizar" : "Cargar\u00a0\u00a0\u00a0\u00a0\u00a0"}
                        custom accept=".p12" onChange={(e) => handleUploadLlave(e.target.files, element.id)}
                      />
                    </Form>
                    {
                      element.llave ? (
                        <Button className="float-start ml-10 mt-5" disabled={pageHandler.isLoading} variant="secondary" onClick={() => handleProbarLlave(element.id)}>Probar</Button>
                      ) : null
                    }
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Logo:
                    <Form>
                      <Form.File
                        className="width-75 float-start  ml-10 mt-5" disabled={pageHandler.isLoading}
                        label="" data-browse={"Cargar"}
                        custom accept="image/*" onChange={(e) => handleUploadLogo(e.target.files, element.id)}
                      />
                    </Form>
                    {
                      element.imagen ? (
                        <Button className="float-start ml-10 mt-5" disabled={pageHandler.isLoading} variant="secondary" onClick={() => handleShowLogo(element.imagen, element.imagenTipo)}>Ver</Button>
                      ) : null
                    }
                  </ListGroup.Item>
                  {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                  <ListGroup.Item><Button className="ml-10 mt-5" variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button></ListGroup.Item>
                </ListGroup>
              </Card>
            </Row>
          )
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                {pageHandler.isSuperAdmin ? <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" /> : null}
                <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="identificacion" headerLabel="Identificaci&oacute;n" />
                <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="nombre" headerLabel="Nombre" />
                <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="ultimoMes" headerLabel="Mes Pago" />
                <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="precio" headerLabel="Mensualidad" />
                <th className="width-200">Llave</th>
                <th>Cargada</th>
                <th className="width-155">Logo</th>
                <th className="width-125">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
              </tr>
            </thead>
            <tbody>
              {
                pageHandler.items.map((element) =>
                  <tr key={element.id}>
                    {pageHandler.isSuperAdmin ? <td>{element.id}</td> : null}
                    <td>{element.identificacion.numero}</td>
                    <td>{element.nombre}</td>
                    <td>{element.ultimoMes}</td>
                    <td>{element.precio}</td>
                    <td>
                      <Form>
                        <Form.File
                          className="width-96 float-left" disabled={pageHandler.isLoading} id={`llaveCriptografica${element.id}`}
                          label="" data-browse={element.llave ? "Actualizar" : "Cargar\u00a0\u00a0\u00a0\u00a0\u00a0"}
                          custom accept=".p12" onChange={(e) => handleUploadLlave(e.target.files, element.id)}
                        />
                      </Form>
                      {
                        element.llave ? (
                          <Button className="float-right" disabled={pageHandler.isLoading} variant="secondary" onClick={() => handleProbarLlave(element.id)}>Probar</Button>
                        ) : null
                      }
                    </td>
                    <td>{cleanStringDate(element.ultimaLlave)}</td>
                    <td>
                      <Form>
                        <Form.File
                          className="width-75 float-left" disabled={pageHandler.isLoading}
                          label="" data-browse={"Cargar"}
                          custom accept="image/*" onChange={(e) => handleUploadLogo(e.target.files, element.id)}
                        />
                      </Form>
                      {
                        element.imagen ? (
                          <Button className="float-right" disabled={pageHandler.isLoading} variant="secondary" onClick={() => handleShowLogo(element.imagen, element.imagenTipo)}>Ver</Button>
                        ) : null
                      }
                    </td>
                    <td><Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        )
      }
      <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} showButton={pageHandler.isSuperAdmin} />
    </Container>
  );
}