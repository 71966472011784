import {useEffect} from "react";
import {useExoneracion as useItem, usePageHandler} from "../customHooks";
import {
  saveExoneracionRequest as saveRequest, deleteExoneracionRequest as deleteRequest, listExoneracionRequest as listRequest,
  haciendaConsultarExoneracion, cleanStringDateTime, initForm
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert, CustomTextField, CustomSelectField, CustomCheckField,
  CustomPagination, CustomTableHeader, CustomDatePicker, InputGroup, Badge
} from "../components";
const pageLabels = {
  itemCreate: "Crear exoneraci\u00f3n", itemUpdate: "Modificar exoneraci\u00f3n", itemDelete: "Eliminar exoneraci\u00f3n", genericError: "Ha ocurrido un error",
  itemCreated: "Exoneraci\u00f3n creada", itemUpdated: "Exoneraci\u00f3n modificada", itemDeleted: "Exoneraci\u00f3n eliminada", totalItems: "Exoneraciones"
};

export const ExoneracionPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const buscarExoneracion = useItem();
  const cuenta = controller.usuario.cuenta.get;

  useEffect(() => {
    if (cuenta) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [cuenta]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          await saveRequest(pageHandler.item.getObject(), cuenta);
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
          pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const handleDelete = () => {
    const remove = async() => {
      try {
        await deleteRequest(pageHandler.item.id);
        await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
        pageHandler.handleDeleteItem(pageLabels.itemDeleted);
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    remove();
  }

  const closeModal = () => {
    pageHandler.setOpenModal(false);
    buscarExoneracion.cleanHaciendaSearch();
  }

  const handleSearch = () => {
    const search = async() => {
      try {
        pageHandler.initProcess(true);
        const searchResult = await haciendaConsultarExoneracion(buscarExoneracion.helperInput.value);
        if (pageHandler.isMounted) {
          buscarExoneracion.setObject(searchResult);
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
      } 
    }
    search();
  }

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const handleSeleccionar = () => {
    if (pageHandler.isMounted) {
      pageHandler.item.setObject(buscarExoneracion.getObject());
      buscarExoneracion.cleanHaciendaSearch();
    }
  }

  if (cuenta) {
    const collapse = controller.session.width < 1000;

    return (
      <Container className="full-container">
        <Modal centered animation={false} show={pageHandler.modalAction === "delete"} onHide={() => pageHandler.setModalAction("")}>
          <Modal.Header closeButton><Modal.Title>{pageLabels.itemDelete}</Modal.Title></Modal.Header>
          <Modal.Body>Confirmar esta acci&oacute;n</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.setModalAction("")}>Cerrar</Button>
            <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={pageHandler.openModal} onHide={closeModal} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : ""}</Modal.Title>
              <Button className="ml-20" variant="primary" disabled={pageHandler.isLoading} onClick={() => buscarExoneracion.cleanHaciendaSearch(!buscarExoneracion.haciendaSearch)}>
                {buscarExoneracion.haciendaSearch ? "Regresar" : <>Buscar por n&uacute;mero de Documento (Autorizaci&oacute;n)</>}
              </Button>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              {
                buscarExoneracion.haciendaSearch ? (
                  <Row>
                    <Col lg={5}>
                      <CustomTextField id="searchIdentificacion" label="N&uacute;mero de Documento (Autorizaci&oacute;n)" input={buscarExoneracion.helperInput.input} onKeyDown={handleSearchEnter}/>
                    </Col>
                    <Col lg={7}>
                      <Row className="ml-0"><strong>N&uacute;mero de Documento:</strong>&nbsp;{buscarExoneracion.numeroDocumento.value}</Row>
                      <Row className="ml-0"><strong>Identificaci&oacute;n:</strong>&nbsp;{buscarExoneracion.identificacion.value}</Row>
                      <Row className="ml-0"><strong>C&oacute;digo de Proyecto CFIA:</strong>&nbsp;{buscarExoneracion.codigoProyectoCFIA.value}</Row>
                      <Row className="ml-0"><strong>Fecha de Emisi&oacute;n:</strong>&nbsp;{cleanStringDateTime(buscarExoneracion.fechaEmision)}</Row>
                      <Row className="ml-0"><strong>Fecha de Vencimiento:</strong>&nbsp;{cleanStringDateTime(buscarExoneracion.fechaVencimiento)}</Row>
                      <Row className="ml-0"><strong>Porcentaje de Exoneraci&oacute;n:</strong>&nbsp;{buscarExoneracion.porcentajeExoneracion.value}</Row>
                      <Row className="ml-0"><strong>Tipo de Documento:</strong>&nbsp;{buscarExoneracion.tipoDocumentoDescripcion()}</Row>
                      <Row className="ml-0"><strong>Instituci&oacute;n:</strong>&nbsp;{buscarExoneracion.nombreInstitucion.value}</Row>
                      <Row className="ml-0"><strong>Posee Cabys:</strong>&nbsp;{buscarExoneracion.poseeCabys.value ? "S\u00ed" : "No"}</Row>
                      {buscarExoneracion.poseeCabys.value ? (buscarExoneracion.cabys.map(item => <Row key={item} className="ml-10">{item}</Row>)) : null}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col lg>
                      <CustomTextField required id="itemNumeroDocumento" label="N&uacute;mero de Documento" input={pageHandler.item.numeroDocumento.input} invalid="Este campo es requerido" />
                      <CustomSelectField id="itemTipoDocumento" label="Tipo de Documento" input={pageHandler.item.tipoDocumento.select} options={pageHandler.item.tipoDocumento.options} />
                      <CustomTextField required id="itemNombreInstitucion" label="Instituci&oacute;n" input={pageHandler.item.nombreInstitucion.input} invalid="Este campo es requerido" />
                      <CustomTextField required id="itemIdentificacion" label="Identificaci&oacute;n" input={pageHandler.item.identificacion.input} invalid="Este campo es requerido" />
                      <CustomTextField required id="itemPorcentajeExoneracion" label="Porcentaje de Exoneraci&oacute;n" input={pageHandler.item.porcentajeExoneracion.input} invalid="Este campo es requerido" />
                    </Col>
                    <Col lg>
                      <CustomDatePicker id="fechaEmisionDate" label="Fecha de Emisi&oacute;n" placeholder="Seleccionar Fecha" showTimeSelect handler={pageHandler.item.fechaEmisionDate.datePicker} />
                      <CustomDatePicker id="fechaVencimientoDate" label="Fecha de Vencimiento" placeholder="Seleccionar Fecha" showTimeSelect handler={pageHandler.item.fechaVencimientoDate.datePicker} />
                      <CustomTextField id="itemCodigoProyectoCFIA" label="C&oacute;digo de Proyecto CFIA" input={pageHandler.item.codigoProyectoCFIA.input} />
                      <CustomCheckField id="itemPoseeCabys" label="Posee C&oacute;digos Cabys" input={pageHandler.item.poseeCabys.check} />
                      {
                        pageHandler.item.poseeCabys.value ? (
                          <Form.Group controlId="itemNuevoCodigo">
                            <Form.Label>Agregar C&oacute;digos Cabys</Form.Label>
                            <InputGroup>
                              <Form.Control type="text" placeholder="Agregar Nuevo C&oacute;digo" {...pageHandler.item.helperInput.input} />
                              <InputGroup.Append><Button onClick={pageHandler.item.agregarCabys}>Agregar</Button></InputGroup.Append>
                            </InputGroup>
                          </Form.Group>
                        ) : null
                      }
                      {
                        pageHandler.item.poseeCabys.value
                        ? (pageHandler.item.cabys.map((item) =>
                          <Row key={item} className="ml-10 mb-5">
                            <Badge variant="danger" onClick={() => pageHandler.item.removerCabys(item)}>Remover</Badge>&#160;{item}
                          </Row>))
                        : null
                      }
                    </Col>
                  </Row>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal} disabled={pageHandler.isLoading}>Cerrar</Button>
              {
                buscarExoneracion.haciendaSearch
                ? buscarExoneracion.numeroDocumento !== ""
                ? <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSeleccionar : null}>Seleccionar</Button>
                : <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSearch : null}>{pageHandler.isLoading ? "Buscando…" : "Buscar"}</Button>
                : <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
              }
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems} showBadge={collapse} />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">N&uacute;mero de Documento: {element.numeroDocumento}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Identificaci&oacute;n: {element.identificacion}</ListGroup.Item>
                    <ListGroup.Item>Porcentaje de Exoneraci&oacute;n: {element.porcentajeExoneracion}</ListGroup.Item>
                    <ListGroup.Item>Fecha de Emisi&oacute;n: {cleanStringDateTime(element.fechaEmision)}</ListGroup.Item>
                    <ListGroup.Item>Fecha de Vencimiento: {cleanStringDateTime(element.fechaVencimiento)}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                      <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="identificacion" headerLabel="Identificaci&oacute;n" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="numeroDocumento" headerLabel="N&uacute;mero de Documento" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="porcentajeExoneracion" headerLabel="Porcentaje de Exoneraci&oacute;n" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="fechaEmision" headerLabel="Fecha de Emisi&oacute;n" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="fechaVencimiento" headerLabel="Fecha de Vencimiento" />
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      <td>{element.id}</td>
                      <td>{element.identificacion}</td>
                      <td>{element.numeroDocumento}</td>
                      <td>{element.porcentajeExoneracion}</td>
                      <td>{cleanStringDateTime(element.fechaEmision)}</td>
                      <td>{cleanStringDateTime(element.fechaVencimiento)}</td>
                      <td>
                        <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                        <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}