export const ubicaciones = () => [
  {key: "1pr", text: "San José", value: "1", cantones: [
      {key: "1cn", text: "San José", value: "1", distritos: [
          {key: "1ds", text: "Carmen", value: "1", barrios: [
              {key: "1br", text: "San José (parte)", value: "1"},
              {key: "2br", text: "Amón", value: "2"},
              {key: "3br", text: "Aranjuez", value: "3"},
              {key: "4br", text: "California (parte)", value: "4"},
              {key: "5br", text: "Carmen", value: "5"},
              {key: "6br", text: "Empalme", value: "6"},
              {key: "7br", text: "Escalante", value: "7"},
              {key: "8br", text: "Otoya", value: "8"}]},
          {key: "2ds", text: "Merced", value: "2", barrios: [
              {key: "1br", text: "San José (parte)", value: "1"},
              {key: "2br", text: "Bajos De La Unión", value: "2"},
              {key: "3br", text: "Claret", value: "3"},
              {key: "4br", text: "Cocacola", value: "4"},
              {key: "5br", text: "Iglesias Flores", value: "5"},
              {key: "6br", text: "Mantica", value: "6"},
              {key: "7br", text: "México", value: "7"},
              {key: "8br", text: "Paso De La Vaca", value: "8"},
              {key: "9br", text: "Pitahaya", value: "9"}]},
          {key: "3ds", text: "Hospital", value: "3", barrios: [
              {key: "1br", text: "San José (parte)", value: "1"},
              {key: "2br", text: "Almendares", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Bolívar", value: "4"},
              {key: "5br", text: "Carit", value: "5"},
              {key: "6br", text: "Colón (parte)", value: "6"},
              {key: "7br", text: "Corazón De Jesús", value: "7"},
              {key: "8br", text: "Cristo Rey", value: "8"},
              {key: "9br", text: "Cuba", value: "9"},
              {key: "10br", text: "Dolorosa (parte)", value: "10"},
              {key: "11br", text: "Merced", value: "11"},
              {key: "12br", text: "Pacífico (parte)", value: "12"},
              {key: "13br", text: "Pinos", value: "13"},
              {key: "14br", text: "Salubridad", value: "14"},
              {key: "15br", text: "San Bosco", value: "15"},
              {key: "16br", text: "San Francisco", value: "16"},
              {key: "17br", text: "Santa Lucía", value: "17"},
              {key: "18br", text: "Silos", value: "18"}]},
          {key: "4ds", text: "Catedral", value: "4", barrios: [
              {key: "1br", text: "San José (parte)", value: "1"},
              {key: "2br", text: "Bellavista", value: "2"},
              {key: "3br", text: "California (parte)", value: "3"},
              {key: "4br", text: "Carlos María Jiménez", value: "4"},
              {key: "5br", text: "Dolorosa (parte)", value: "5"},
              {key: "6br", text: "Dos Pinos", value: "6"},
              {key: "7br", text: "Francisco Peralta (parte)", value: "7"},
              {key: "8br", text: "González Lahmann", value: "8"},
              {key: "9br", text: "González Víquez", value: "9"},
              {key: "10br", text: "Güell", value: "10"},
              {key: "11br", text: "La Cruz", value: "11"},
              {key: "12br", text: "Lomas De Ocloro", value: "12"},
              {key: "13br", text: "Luján", value: "13"},
              {key: "14br", text: "Milflor", value: "14"},
              {key: "15br", text: "Naciones Unidas", value: "15"},
              {key: "16br", text: "Pacífico (parte)", value: "16"},
              {key: "17br", text: "San Cayetano (parte)", value: "17"},
              {key: "18br", text: "Soledad", value: "18"},
              {key: "19br", text: "Tabacalera", value: "19"},
              {key: "20br", text: "Vasconia", value: "20"}]},
          {key: "5ds", text: "Zapote", value: "5", barrios: [
              {key: "1br", text: "Zapote Centro", value: "1"},
              {key: "2br", text: "Alborada", value: "2"},
              {key: "3br", text: "Calderón Muñoz", value: "3"},
              {key: "4br", text: "Cerrito", value: "4"},
              {key: "5br", text: "Córdoba", value: "5"},
              {key: "6br", text: "Gloria", value: "6"},
              {key: "7br", text: "Jardín", value: "7"},
              {key: "8br", text: "Luisas", value: "8"},
              {key: "9br", text: "Mangos", value: "9"},
              {key: "10br", text: "Montealegre", value: "10"},
              {key: "11br", text: "Moreno Cañas", value: "11"},
              {key: "12br", text: "Quesada Durán", value: "12"},
              {key: "13br", text: "San Dimas", value: "13"},
              {key: "14br", text: "San Gerardo (parte)", value: "14"},
              {key: "15br", text: "Trébol", value: "15"},
              {key: "16br", text: "Ujarrás", value: "16"},
              {key: "17br", text: "Vista Hermosa", value: "17"},
              {key: "18br", text: "Yoses Sur", value: "18"}]},
          {key: "6ds", text: "San Francisco De Dos Ríos", value: "6", barrios: [
              {key: "1br", text: "San Francisco De Dos Ríos Centro", value: "1"},
              {key: "2br", text: "Ahogados (parte)", value: "2"},
              {key: "3br", text: "Bosque", value: "3"},
              {key: "4br", text: "Cabañas", value: "4"},
              {key: "5br", text: "Camelias", value: "5"},
              {key: "6br", text: "Coopeguaria", value: "6"},
              {key: "7br", text: "Faro", value: "7"},
              {key: "8br", text: "Fátima", value: "8"},
              {key: "9br", text: "Hispano", value: "9"},
              {key: "10br", text: "I Griega", value: "10"},
              {key: "11br", text: "Lincoln", value: "11"},
              {key: "12br", text: "Lomas De San Francisco", value: "12"},
              {key: "13br", text: "Maalot", value: "13"},
              {key: "14br", text: "Méndez", value: "14"},
              {key: "15br", text: "Pacífica", value: "15"},
              {key: "16br", text: "Sauces", value: "16"},
              {key: "17br", text: "Saucitos", value: "17"},
              {key: "18br", text: "Zurquí", value: "18"}]},
          {key: "7ds", text: "Uruca", value: "7", barrios: [
              {key: "1br", text: "Uruca Centro", value: "1"},
              {key: "2br", text: "Alborada", value: "2"},
              {key: "3br", text: "Ánimas", value: "3"},
              {key: "4br", text: "Árboles", value: "4"},
              {key: "5br", text: "Bajos Del Torres", value: "5"},
              {key: "6br", text: "Carranza", value: "6"},
              {key: "7br", text: "Corazón De Jesús", value: "7"},
              {key: "8br", text: "Cristal", value: "8"},
              {key: "9br", text: "Carvajal Castro", value: "9"},
              {key: "10br", text: "Jardines De Autopista", value: "10"},
              {key: "11br", text: "La Caja", value: "11"},
              {key: "12br", text: "La Carpio", value: "12"},
              {key: "13br", text: "Magnolia", value: "13"},
              {key: "14br", text: "Marimil", value: "14"},
              {key: "15br", text: "Monserrat", value: "15"},
              {key: "16br", text: "Peregrina", value: "16"},
              {key: "17br", text: "Robledal", value: "17"},
              {key: "18br", text: "Rossiter Carballo", value: "18"},
              {key: "19br", text: "Santander", value: "19"},
              {key: "20br", text: "Saturno", value: "20"},
              {key: "21br", text: "Vuelta Del Virilla", value: "21"}]},
          {key: "8ds", text: "Mata Redonda", value: "8", barrios: [
              {key: "1br", text: "San José (parte)", value: "1"},
              {key: "2br", text: "Américas", value: "2"},
              {key: "3br", text: "Bajo Cañada (parte)", value: "3"},
              {key: "4br", text: "Balcón Verde", value: "4"},
              {key: "5br", text: "Colón (parte)", value: "5"},
              {key: "6br", text: "Del Pino", value: "6"},
              {key: "7br", text: "Holanda", value: "7"},
              {key: "8br", text: "La Luisa", value: "8"},
              {key: "9br", text: "La Salle", value: "9"},
              {key: "10br", text: "Lomalinda", value: "10"},
              {key: "11br", text: "Morenos", value: "11"},
              {key: "12br", text: "Niza", value: "12"},
              {key: "13br", text: "Rancho Luna", value: "13"},
              {key: "14br", text: "Rohrmoser (parte)", value: "14"},
              {key: "15br", text: "Roma", value: "15"},
              {key: "16br", text: "Sabana", value: "16"},
              {key: "17br", text: "Tovar", value: "17"}]},
          {key: "9ds", text: "Pavas", value: "9", barrios: [
              {key: "1br", text: "Pavas Centro", value: "1"},
              {key: "2br", text: "Alfa", value: "2"},
              {key: "3br", text: "Asturias", value: "3"},
              {key: "4br", text: "Asunción", value: "4"},
              {key: "5br", text: "Bribrí", value: "5"},
              {key: "6br", text: "Favorita Norte", value: "6"},
              {key: "7br", text: "Favorita Sur", value: "7"},
              {key: "8br", text: "Galicia", value: "8"},
              {key: "9br", text: "Geroma", value: "9"},
              {key: "10br", text: "Hispania", value: "10"},
              {key: "11br", text: "Libertad", value: "11"},
              {key: "12br", text: "Lomas Del Río", value: "12"},
              {key: "13br", text: "Llanos Del Sol", value: "13"},
              {key: "14br", text: "María Reina", value: "14"},
              {key: "15br", text: "Metrópolis", value: "15"},
              {key: "16br", text: "Navarra", value: "16"},
              {key: "17br", text: "Pueblo Nuevo", value: "17"},
              {key: "18br", text: "Residencial Del Oeste", value: "18"},
              {key: "19br", text: "Rincón Grande", value: "19"},
              {key: "20br", text: "Rohrmoser (parte)", value: "20"},
              {key: "21br", text: "Rotonda", value: "21"},
              {key: "22br", text: "San Pedro", value: "22"},
              {key: "23br", text: "Santa Bárbara", value: "23"},
              {key: "24br", text: "Santa Catalina", value: "24"},
              {key: "25br", text: "Santa Fé", value: "25"},
              {key: "26br", text: "Triángulo", value: "26"},
              {key: "27br", text: "Villa Esperanza", value: "27"}]},
          {key: "10ds", text: "Hatillo", value: "10", barrios: [
              {key: "1br", text: "Hatillo Centro", value: "1"},
              {key: "2br", text: "Bajo Cañada (parte)", value: "2"},
              {key: "3br", text: "Belgrano", value: "3"},
              {key: "4br", text: "Hatillo 1", value: "4"},
              {key: "5br", text: "Hatillo 2", value: "5"},
              {key: "6br", text: "Hatillo 3", value: "6"},
              {key: "7br", text: "Hatillo 4", value: "7"},
              {key: "8br", text: "Hatillo 5", value: "8"},
              {key: "9br", text: "Hatillo 6", value: "9"},
              {key: "10br", text: "Hatillo 7", value: "10"},
              {key: "11br", text: "Hatillo 8", value: "11"},
              {key: "12br", text: "Quince De Setiembre", value: "12"},
              {key: "13br", text: "Sagrada Familia", value: "13"},
              {key: "14br", text: "Tiribí", value: "14"},
              {key: "15br", text: "Topacio", value: "15"},
              {key: "16br", text: "Veinticinco De Julio", value: "16"},
              {key: "17br", text: "Vivienda En Marcha", value: "17"}]},
          {key: "11ds", text: "San Sebastián", value: "11", barrios: [
              {key: "1br", text: "San Sebastián Centro", value: "1"},
              {key: "2br", text: "Bengala", value: "2"},
              {key: "3br", text: "Bilbao", value: "3"},
              {key: "4br", text: "Cañada Del Sur", value: "4"},
              {key: "5br", text: "Carmen", value: "5"},
              {key: "6br", text: "Cascajal", value: "6"},
              {key: "7br", text: "Cerro Azul", value: "7"},
              {key: "8br", text: "Colombari", value: "8"},
              {key: "9br", text: "Domingo Savio", value: "9"},
              {key: "10br", text: "Guacamaya", value: "10"},
              {key: "11br", text: "Jazmín", value: "11"},
              {key: "12br", text: "Hogar Propio", value: "12"},
              {key: "13br", text: "Kennedy", value: "13"},
              {key: "14br", text: "López Mateos", value: "14"},
              {key: "15br", text: "Luna Park", value: "15"},
              {key: "16br", text: "Martínez", value: "16"},
              {key: "17br", text: "Mojados", value: "17"},
              {key: "18br", text: "Mongito", value: "18"},
              {key: "19br", text: "Monte Azúl", value: "19"},
              {key: "20br", text: "Musmanni", value: "20"},
              {key: "21br", text: "Paso Ancho", value: "21"},
              {key: "22br", text: "Presidentes", value: "22"},
              {key: "23br", text: "San Cayetano (parte)", value: "23"},
              {key: "24br", text: "San Martín", value: "24"},
              {key: "25br", text: "Santa Rosa", value: "25"},
              {key: "26br", text: "Seminario", value: "26"},
              {key: "27br", text: "Sorobarú", value: "27"}]}]},
      {key: "2cn", text: "Escazú", value: "2", distritos: [
          {key: "1ds", text: "Escazú", value: "1", barrios: [
              {key: "1br", text: "Escazú Centro", value: "1"},
              {key: "2br", text: "Alto Carrizal", value: "2"},
              {key: "3br", text: "Carrizal (parte)", value: "3"},
              {key: "4br", text: "Faroles", value: "4"},
              {key: "5br", text: "Guapinol", value: "5"},
              {key: "6br", text: "Hulera", value: "6"},
              {key: "7br", text: "Itabas", value: "7"},
              {key: "8br", text: "Jaboncillo", value: "8"},
              {key: "9br", text: "Profesores (parte)", value: "9"}]},
          {key: "2ds", text: "San Antonio", value: "2", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Avellana", value: "2"},
              {key: "3br", text: "Bebedero", value: "3"},
              {key: "4br", text: "Belo Horizonte (parte)", value: "4"},
              {key: "5br", text: "Carrizal (parte)", value: "5"},
              {key: "6br", text: "Curío", value: "6"},
              {key: "7br", text: "Chirca", value: "7"},
              {key: "8br", text: "Chiverral", value: "8"},
              {key: "9br", text: "Entierrillo", value: "9"},
              {key: "10br", text: "Filtros", value: "10"},
              {key: "11br", text: "Guayabos", value: "11"},
              {key: "12br", text: "Hojablanca", value: "12"},
              {key: "13br", text: "Juan Santana", value: "13"},
              {key: "14br", text: "Lajas", value: "14"},
              {key: "15br", text: "Masilla", value: "15"},
              {key: "16br", text: "Muta", value: "16"},
              {key: "17br", text: "Pedrero", value: "17"},
              {key: "18br", text: "Perú", value: "18"},
              {key: "19br", text: "Profesores (parte)", value: "19"},
              {key: "20br", text: "Sabanillas", value: "20"},
              {key: "21br", text: "Salitrillos", value: "21"},
              {key: "22br", text: "Santa Eduvigis", value: "22"},
              {key: "23br", text: "Santa Teresa", value: "23"},
              {key: "24br", text: "Tejarcillos", value: "24"},
              {key: "25br", text: "Torrotillo", value: "25"},
              {key: "26br", text: "Vista De Oro", value: "26"}]},
          {key: "3ds", text: "San Rafael", value: "3", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Anonos", value: "2"},
              {key: "3br", text: "Ayala", value: "3"},
              {key: "4br", text: "Bajo Anonos", value: "4"},
              {key: "5br", text: "Bajo Palomas", value: "5"},
              {key: "6br", text: "Belo Horizonte (parte)", value: "6"},
              {key: "7br", text: "Betina", value: "7"},
              {key: "8br", text: "Ceiba", value: "8"},
              {key: "9br", text: "Facio Castro", value: "9"},
              {key: "10br", text: "Guachipelín", value: "10"},
              {key: "11br", text: "Herrera", value: "11"},
              {key: "12br", text: "Laureles", value: "12"},
              {key: "13br", text: "León", value: "13"},
              {key: "14br", text: "Loma Real", value: "14"},
              {key: "15br", text: "Matapalo", value: "15"},
              {key: "16br", text: "Maynard", value: "16"},
              {key: "17br", text: "Mirador", value: "17"},
              {key: "18br", text: "Miravalles", value: "18"},
              {key: "19br", text: "Palermo", value: "19"},
              {key: "20br", text: "Palma De Mallorca", value: "20"},
              {key: "21br", text: "Pinar", value: "21"},
              {key: "22br", text: "Primavera", value: "22"},
              {key: "23br", text: "Quesada", value: "23"},
              {key: "24br", text: "Real De Pereira (parte)", value: "24"},
              {key: "25br", text: "Santa Marta", value: "25"},
              {key: "26br", text: "Tena", value: "26"},
              {key: "27br", text: "Trejos Montealegre", value: "27"},
              {key: "28br", text: "Vista Alegre", value: "28"}]}]},
      {key: "3cn", text: "Desamparados", value: "3", distritos: [
          {key: "1ds", text: "Desamparados", value: "1", barrios: [
              {key: "1br", text: "Desamparados Centro", value: "1"},
              {key: "2br", text: "Altamira", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Calle Fallas", value: "4"},
              {key: "5br", text: "Camaquirí", value: "5"},
              {key: "6br", text: "Capilla", value: "6"},
              {key: "7br", text: "Centro De Amigos", value: "7"},
              {key: "8br", text: "Cerámica", value: "8"},
              {key: "9br", text: "Colonia Del Sur", value: "9"},
              {key: "10br", text: "Contadores", value: "10"},
              {key: "11br", text: "Cruce", value: "11"},
              {key: "12br", text: "Cucubres", value: "12"},
              {key: "13br", text: "Dorados", value: "13"},
              {key: "14br", text: "Florita", value: "14"},
              {key: "15br", text: "Fortuna", value: "15"},
              {key: "16br", text: "Jardín", value: "16"},
              {key: "17br", text: "Loto", value: "17"},
              {key: "18br", text: "Metrópoli", value: "18"},
              {key: "19br", text: "Monseñor Sanabria", value: "19"},
              {key: "20br", text: "Monteclaro", value: "20"},
              {key: "21br", text: "Palogrande", value: "21"},
              {key: "22br", text: "Pinos", value: "22"},
              {key: "23br", text: "Retoños", value: "23"},
              {key: "24br", text: "Río Jorco", value: "24"},
              {key: "25br", text: "Sabara", value: "25"},
              {key: "26br", text: "San Esteban Rey", value: "26"},
              {key: "27br", text: "San Jerónimo", value: "27"},
              {key: "28br", text: "San José", value: "28"},
              {key: "29br", text: "San Roque", value: "29"},
              {key: "30br", text: "Tauros", value: "30"},
              {key: "31br", text: "Torremolinos", value: "31"},
              {key: "32br", text: "Venecia", value: "32"},
              {key: "33br", text: "Vista Verde", value: "33"}]},
          {key: "2ds", text: "San Miguel", value: "2", barrios: [
              {key: "1br", text: "San Miguel Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Capri", value: "3"},
              {key: "4br", text: "Damas Israelitas", value: "4"},
              {key: "5br", text: "Girasol", value: "5"},
              {key: "6br", text: "Higuito", value: "6"},
              {key: "7br", text: "Lindavista", value: "7"},
              {key: "8br", text: "Lomas De Jorco", value: "8"},
              {key: "9br", text: "Llano", value: "9"},
              {key: "10br", text: "Meseguer", value: "10"},
              {key: "11br", text: "Olivos", value: "11"},
              {key: "12br", text: "Orquídeas", value: "12"},
              {key: "13br", text: "Peñascal", value: "13"},
              {key: "14br", text: "Rinconada", value: "14"},
              {key: "15br", text: "Rodillal", value: "15"},
              {key: "16br", text: "Sabanilla", value: "16"},
              {key: "17br", text: "San Martín", value: "17"},
              {key: "18br", text: "Santa Eduvigis", value: "18"},
              {key: "19br", text: "Valverde", value: "19"},
              {key: "20br", text: "Alto Alumbre", value: "20"},
              {key: "21br", text: "Hoyo", value: "21"},
              {key: "22br", text: "Jericó", value: "22"},
              {key: "23br", text: "Manzano", value: "23"},
              {key: "24br", text: "Pacaya", value: "24"},
              {key: "25br", text: "Roblar", value: "25"},
              {key: "26br", text: "Ticalpes (parte)", value: "26"},
              {key: "27br", text: "Calle Naranjos", value: "27"}]},
          {key: "3ds", text: "San Juan De Dios", value: "3", barrios: [
              {key: "1br", text: "San Juan De Dios Centro", value: "1"},
              {key: "2br", text: "Calabacitas", value: "2"},
              {key: "3br", text: "Calle Común", value: "3"},
              {key: "4br", text: "Cruz Roja", value: "4"},
              {key: "5br", text: "Itaipú", value: "5"},
              {key: "6br", text: "Máquinas", value: "6"},
              {key: "7br", text: "Mota", value: "7"},
              {key: "8br", text: "Novedades", value: "8"},
              {key: "9br", text: "Pedrito Monge", value: "9"},
              {key: "10br", text: "Río", value: "10"},
              {key: "11br", text: "Robles", value: "11"}]},
          {key: "4ds", text: "San Rafael Arriba", value: "4", barrios: [
              {key: "1br", text: "San Rafael Arriba Centro", value: "1"},
              {key: "2br", text: "Alpino", value: "2"},
              {key: "3br", text: "Arco Iris", value: "3"},
              {key: "4br", text: "Bambú", value: "4"},
              {key: "5br", text: "Berlay", value: "5"},
              {key: "6br", text: "Guaria", value: "6"},
              {key: "7br", text: "Huaso (parte)", value: "7"},
              {key: "8br", text: "Juncales", value: "8"},
              {key: "9br", text: "Lajas", value: "9"},
              {key: "10br", text: "Macarena", value: "10"},
              {key: "11br", text: "Maiquetía", value: "11"},
              {key: "12br", text: "Méndez", value: "12"}]},
          {key: "5ds", text: "San Antonio", value: "5", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Acacias", value: "2"},
              {key: "3br", text: "Calle Amador", value: "3"},
              {key: "4br", text: "Constancia", value: "4"},
              {key: "5br", text: "Churuca", value: "5"},
              {key: "6br", text: "Huetares", value: "6"},
              {key: "7br", text: "Plazoleta", value: "7"},
              {key: "8br", text: "Pueblo Nuevo", value: "8"},
              {key: "9br", text: "Río Damas", value: "9"},
              {key: "10br", text: "Rotondas", value: "10"},
              {key: "11br", text: "Solar", value: "11"}]},
          {key: "6ds", text: "Frailes", value: "6", barrios: [
              {key: "1br", text: "Frailes Centro", value: "1"},
              {key: "2br", text: "Bajos De Tarrazú", value: "2"},
              {key: "3br", text: "Bustamante", value: "3"},
              {key: "4br", text: "Santa Elena (parte)", value: "4"},
              {key: "5br", text: "Violeta", value: "5"}]},
          {key: "7ds", text: "Patarrá", value: "7", barrios: [
              {key: "1br", text: "Patarrá Centro", value: "1"},
              {key: "2br", text: "Aguacate", value: "2"},
              {key: "3br", text: "Balneario", value: "3"},
              {key: "4br", text: "Don Bosco", value: "4"},
              {key: "5br", text: "Guatuso", value: "5"},
              {key: "6br", text: "Güízaro", value: "6"},
              {key: "7br", text: "Jerusalén", value: "7"},
              {key: "8br", text: "Lince", value: "8"},
              {key: "9br", text: "Mesas", value: "9"},
              {key: "10br", text: "Quebrada Honda", value: "10"},
              {key: "11br", text: "Ticalpes (parte)", value: "11"}]},
          {key: "8ds", text: "San Cristóbal", value: "8", barrios: [
              {key: "1br", text: "San Cristóbal Norte", value: "1"},
              {key: "2br", text: "Empalme (parte)", value: "2"},
              {key: "3br", text: "Lucha (parte)", value: "3"},
              {key: "4br", text: "San Cristóbal Sur", value: "4"},
              {key: "5br", text: "Sierra", value: "5"}]},
          {key: "9ds", text: "Rosario", value: "9", barrios: [
              {key: "1br", text: "Rosario Centro", value: "1"},
              {key: "2br", text: "Bajo Tigre", value: "2"},
              {key: "3br", text: "Chirogres", value: "3"},
              {key: "4br", text: "Guadarrama", value: "4"},
              {key: "5br", text: "Joya", value: "5"},
              {key: "6br", text: "La Fila (parte)", value: "6"},
              {key: "7br", text: "Llano Bonito", value: "7"},
              {key: "8br", text: "Quebrada Honda", value: "8"},
              {key: "9br", text: "Trinidad (parte)", value: "9"}]},
          {key: "10ds", text: "Damas", value: "10", barrios: [
              {key: "1br", text: "Fátima", value: "1"},
              {key: "2br", text: "Cajita", value: "2"},
              {key: "3br", text: "Dorado", value: "3"},
              {key: "4br", text: "Dos Cercas", value: "4"},
              {key: "5br", text: "Fomentera", value: "5"},
              {key: "6br", text: "Nuestra Señora De La Esperanza", value: "6"},
              {key: "7br", text: "San Lorenzo", value: "7"}]},
          {key: "11ds", text: "San Rafael Abajo", value: "11", barrios: [
              {key: "1br", text: "San Rafael Abajo Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Autofores", value: "3"},
              {key: "4br", text: "Balboa", value: "4"},
              {key: "5br", text: "Coopelot", value: "5"},
              {key: "6br", text: "Gardenia", value: "6"},
              {key: "7br", text: "Higuerones", value: "7"},
              {key: "8br", text: "Leo", value: "8"},
              {key: "9br", text: "Mónaco", value: "9"},
              {key: "10br", text: "Sagitario", value: "10"},
              {key: "11br", text: "Santa Cecilia", value: "11"},
              {key: "12br", text: "Tejar (parte)", value: "12"},
              {key: "13br", text: "Treviso", value: "13"},
              {key: "14br", text: "Unidas", value: "14"},
              {key: "15br", text: "Valencia", value: "15"},
              {key: "16br", text: "Vizcaya", value: "16"}]},
          {key: "12ds", text: "Gravilias", value: "12", barrios: [
              {key: "1br", text: "Gravilias Centro", value: "1"},
              {key: "2br", text: "Cartonera", value: "2"},
              {key: "3br", text: "Claveles", value: "3"},
              {key: "4br", text: "Damasco", value: "4"},
              {key: "5br", text: "Diamante", value: "5"},
              {key: "6br", text: "Esmeraldas", value: "6"},
              {key: "7br", text: "Fortuna", value: "7"},
              {key: "8br", text: "Fortunita", value: "8"},
              {key: "9br", text: "Porvenir", value: "9"},
              {key: "10br", text: "Raya", value: "10"},
              {key: "11br", text: "Riberalta", value: "11"},
              {key: "12br", text: "Villanueva", value: "12"}]},
          {key: "13ds", text: "Los Guido", value: "13", barrios: [
              {key: "1br", text: "Los Guido Centro", value: "1"},
              {key: "2br", text: "Letras", value: "2"},
              {key: "3br", text: "Balcón Verde", value: "3"}]}]},
      {key: "4cn", text: "Puriscal", value: "4", distritos: [
          {key: "1ds", text: "Santiago", value: "1", barrios: [
              {key: "1br", text: "Santiago Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Buenos Aires", value: "3"},
              {key: "4br", text: "Corazón De María", value: "4"},
              {key: "5br", text: "Jarasal", value: "5"},
              {key: "6br", text: "Junquillo Arriba", value: "6"},
              {key: "7br", text: "Pueblo Nuevo", value: "7"},
              {key: "8br", text: "San Isidro", value: "8"},
              {key: "9br", text: "Bajo Badilla", value: "9"},
              {key: "10br", text: "Bajo Moras", value: "10"},
              {key: "11br", text: "Cañales Abajo", value: "11"},
              {key: "12br", text: "Cañales Arriba", value: "12"},
              {key: "13br", text: "Carit", value: "13"},
              {key: "14br", text: "Charcón (parte)", value: "14"},
              {key: "15br", text: "Cirrí", value: "15"},
              {key: "16br", text: "Junquillo Abajo", value: "16"},
              {key: "17br", text: "Pozos", value: "17"},
              {key: "18br", text: "San Francisco", value: "18"},
              {key: "19br", text: "San Martín", value: "19"},
              {key: "20br", text: "Zapote", value: "20"}]},
          {key: "2ds", text: "Mercedes Sur", value: "2", barrios: [
              {key: "1br", text: "Mercedes Sur Centro", value: "1"},
              {key: "2br", text: "Alto Palma", value: "2"},
              {key: "3br", text: "Bajo Lanas", value: "3"},
              {key: "4br", text: "Bajo Legua", value: "4"},
              {key: "5br", text: "Bajo Legüita", value: "5"},
              {key: "6br", text: "Bajo Quesada", value: "6"},
              {key: "7br", text: "Bocana", value: "7"},
              {key: "8br", text: "Carmona", value: "8"},
              {key: "9br", text: "Cerbatana", value: "9"},
              {key: "10br", text: "Charquillos", value: "10"},
              {key: "11br", text: "Jilgueral", value: "11"},
              {key: "12br", text: "Llano Grande", value: "12"},
              {key: "13br", text: "Mercedes Norte", value: "13"},
              {key: "14br", text: "Potenciana", value: "14"},
              {key: "15br", text: "Quebrada Honda", value: "15"},
              {key: "16br", text: "Quivel", value: "16"},
              {key: "17br", text: "Rancho Largo", value: "17"},
              {key: "18br", text: "Salitrales", value: "18"},
              {key: "19br", text: "Santa Marta", value: "19"},
              {key: "20br", text: "Túfares", value: "20"},
              {key: "21br", text: "Tulín", value: "21"},
              {key: "22br", text: "Víbora", value: "22"},
              {key: "23br", text: "Zapotal", value: "23"}]},
          {key: "3ds", text: "Barbacoas", value: "3", barrios: [
              {key: "1br", text: "Barbacoas Centro", value: "1"},
              {key: "2br", text: "Alto Barbacoas", value: "2"},
              {key: "3br", text: "Bajo Burgos", value: "3"},
              {key: "4br", text: "Cortezal", value: "4"},
              {key: "5br", text: "Guatuso", value: "5"},
              {key: "6br", text: "Piedades", value: "6"},
              {key: "7br", text: "San Juan", value: "7"}]},
          {key: "4ds", text: "Grifo Alto", value: "4", barrios: [
              {key: "1br", text: "Grifo Alto Centro", value: "1"},
              {key: "2br", text: "Cacao", value: "2"},
              {key: "3br", text: "Cuesta Mora", value: "3"},
              {key: "4br", text: "Grifo Bajo", value: "4"},
              {key: "5br", text: "Poró", value: "5"},
              {key: "6br", text: "Pueblo Nuevo", value: "6"},
              {key: "7br", text: "Salitrillo", value: "7"}]},
          {key: "5ds", text: "San Rafael", value: "5", barrios: [
              {key: "1br", text: "San Rafael Arriba", value: "1"},
              {key: "2br", text: "Bijagual", value: "2"},
              {key: "3br", text: "Floralia", value: "3"},
              {key: "4br", text: "Punta De Lanza", value: "4"},
              {key: "5br", text: "San Rafael Abajo", value: "5"}]},
          {key: "6ds", text: "Candelarita", value: "6", barrios: [
              {key: "1br", text: "Candelarita Centro", value: "1"},
              {key: "2br", text: "Alto Cebadilla", value: "2"},
              {key: "3br", text: "Bajo Chacones", value: "3"},
              {key: "4br", text: "Copalar", value: "4"},
              {key: "5br", text: "Pedernal", value: "5"},
              {key: "6br", text: "Polca", value: "6"},
              {key: "7br", text: "Sabanas", value: "7"}]},
          {key: "7ds", text: "Desamparaditos", value: "7", barrios: [
              {key: "1br", text: "Desamparaditos Centro", value: "1"},
              {key: "2br", text: "Bajo Guevara", value: "2"},
              {key: "3br", text: "Planta", value: "3"},
              {key: "4br", text: "Rinconada", value: "4"}]},
          {key: "8ds", text: "San Antonio", value: "8", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Bajo Herrera", value: "2"},
              {key: "3br", text: "Calle Herrera", value: "3"},
              {key: "4br", text: "Cruce Guanacaste", value: "4"},
              {key: "5br", text: "Charcón (parte)", value: "5"},
              {key: "6br", text: "Estero", value: "6"},
              {key: "7br", text: "Río Viejo", value: "7"},
              {key: "8br", text: "Salitral", value: "8"},
              {key: "9br", text: "Tinamaste", value: "9"}]},
          {key: "9ds", text: "Chires", value: "9", barrios: [
              {key: "1br", text: "Cristo Rey", value: "1"},
              {key: "2br", text: "Alto Concepción", value: "2"},
              {key: "3br", text: "Alto Pérez Astúa", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Angostura (parte)", value: "5"},
              {key: "6br", text: "Arenal", value: "6"},
              {key: "7br", text: "Bajo Chires", value: "7"},
              {key: "8br", text: "Bajo De Guarumal", value: "8"},
              {key: "9br", text: "Bajo El Rey", value: "9"},
              {key: "10br", text: "Bajo Vega", value: "10"},
              {key: "11br", text: "Cerdas", value: "11"},
              {key: "12br", text: "Fila Aguacate", value: "12"},
              {key: "13br", text: "Gamalotillo 1 (colonia)", value: "13"},
              {key: "14br", text: "Gamalotillo 2 (gamalotillo)", value: "14"},
              {key: "15br", text: "Gamalotillo 3 (tierra Fértil)", value: "15"},
              {key: "16br", text: "Gloria", value: "16"},
              {key: "17br", text: "Guarumal", value: "17"},
              {key: "18br", text: "Guarumalito", value: "18"},
              {key: "19br", text: "Mastatal", value: "19"},
              {key: "20br", text: "Pericos", value: "20"},
              {key: "21br", text: "Río Negro (parte)", value: "21"},
              {key: "22br", text: "San Miguel", value: "22"},
              {key: "23br", text: "San Vicente", value: "23"},
              {key: "24br", text: "Santa Rosa", value: "24"},
              {key: "25br", text: "Vista De Mar", value: "25"},
              {key: "26br", text: "Zapatón", value: "26"}]}]},
      {key: "5cn", text: "Tarrazú", value: "5", distritos: [
          {key: "1ds", text: "San Marcos", value: "1", barrios: [
              {key: "1br", text: "San Marcos Centro", value: "1"},
              {key: "2br", text: "Corea", value: "2"},
              {key: "3br", text: "I Griega", value: "3"},
              {key: "4br", text: "Las Tres Marías", value: "4"},
              {key: "5br", text: "Santa Cecilia", value: "5"},
              {key: "6br", text: "Rodeo", value: "6"},
              {key: "7br", text: "Sitio", value: "7"},
              {key: "8br", text: "Alto Pastora", value: "8"},
              {key: "9br", text: "Bajo Canet", value: "9"},
              {key: "10br", text: "Bajo San Juan", value: "10"},
              {key: "11br", text: "Canet", value: "11"},
              {key: "12br", text: "Cedral (parte)", value: "12"},
              {key: "13br", text: "Guadalupe", value: "13"},
              {key: "14br", text: "Llano Piedra", value: "14"},
              {key: "15br", text: "San Cayetano", value: "15"},
              {key: "16br", text: "San Guillermo", value: "16"},
              {key: "17br", text: "Sabana (parte)", value: "17"},
              {key: "18br", text: "San Pedro", value: "18"}]},
          {key: "2ds", text: "San Lorenzo", value: "2", barrios: [
              {key: "1br", text: "San Lorenzo Centro", value: "1"},
              {key: "2br", text: "Alto Guarumal", value: "2"},
              {key: "3br", text: "Alto Portal", value: "3"},
              {key: "4br", text: "Alto Zapotal", value: "4"},
              {key: "5br", text: "Ardilla", value: "5"},
              {key: "6br", text: "Bajo Quebrada Honda", value: "6"},
              {key: "7br", text: "Bajo Reyes", value: "7"},
              {key: "8br", text: "Bajo Zapotal", value: "8"},
              {key: "9br", text: "Cerro Nara", value: "9"},
              {key: "10br", text: "Concepción", value: "10"},
              {key: "11br", text: "Chilamate", value: "11"},
              {key: "12br", text: "Delicias", value: "12"},
              {key: "13br", text: "Esperanza", value: "13"},
              {key: "14br", text: "Esquipulas", value: "14"},
              {key: "15br", text: "La Pacaya", value: "15"},
              {key: "16br", text: "Las Pavas", value: "16"},
              {key: "17br", text: "Mata De Caña", value: "17"},
              {key: "18br", text: "Miramar", value: "18"},
              {key: "19br", text: "Nápoles", value: "19"},
              {key: "20br", text: "Naranjillo", value: "20"},
              {key: "21br", text: "Palma", value: "21"},
              {key: "22br", text: "Quebrada Arroyo", value: "22"},
              {key: "23br", text: "Rodeo", value: "23"},
              {key: "24br", text: "Sabana (parte)", value: "24"},
              {key: "25br", text: "Salado", value: "25"},
              {key: "26br", text: "San Bernardo", value: "26"},
              {key: "27br", text: "San Francisco", value: "27"},
              {key: "28br", text: "San Martín", value: "28"},
              {key: "29br", text: "Santa Cecilia", value: "29"},
              {key: "30br", text: "Santa Marta", value: "30"},
              {key: "31br", text: "Santa Rosa", value: "31"},
              {key: "32br", text: "Zapotal", value: "32"}]},
          {key: "3ds", text: "San Carlos", value: "3", barrios: [
              {key: "1br", text: "San Carlos Centro", value: "1"},
              {key: "2br", text: "Alto Chiral", value: "2"},
              {key: "3br", text: "San Juan (alto San Juan)", value: "3"},
              {key: "4br", text: "Bajo Jénaro", value: "4"},
              {key: "5br", text: "Bajo San José", value: "5"},
              {key: "6br", text: "Jamaica", value: "6"},
              {key: "7br", text: "Quebrada Seca (santa Ana)", value: "7"},
              {key: "8br", text: "San Jerónimo", value: "8"},
              {key: "9br", text: "San Josecito", value: "9"}]}]},
      {key: "6cn", text: "Aserrí", value: "6", distritos: [
          {key: "1ds", text: "Aserrí", value: "1", barrios: [
              {key: "1br", text: "Aserrí Centro", value: "1"},
              {key: "2br", text: "Alfonso XIII", value: "2"},
              {key: "3br", text: "Barro", value: "3"},
              {key: "4br", text: "Cinco Esquinas (parte)", value: "4"},
              {key: "5br", text: "Corazón De Jesús", value: "5"},
              {key: "6br", text: "Guapinol", value: "6"},
              {key: "7br", text: "Las Mercedes", value: "7"},
              {key: "8br", text: "Lomas De Aserrí", value: "8"},
              {key: "9br", text: "Lourdes", value: "9"},
              {key: "10br", text: "María Auxiliadora", value: "10"},
              {key: "11br", text: "Mesas", value: "11"},
              {key: "12br", text: "Poás", value: "12"},
              {key: "13br", text: "Santa Rita", value: "13"},
              {key: "14br", text: "Sáurez", value: "14"},
              {key: "15br", text: "Tres Marías", value: "15"},
              {key: "16br", text: "Vereda", value: "16"},
              {key: "17br", text: "Guatuso", value: "17"},
              {key: "18br", text: "Mirador", value: "18"}]},
          {key: "2ds", text: "Tarbaca", value: "2", barrios: [
              {key: "1br", text: "Tarbaca Centro", value: "1"},
              {key: "2br", text: "Bajos De Praga", value: "2"},
              {key: "3br", text: "Máquina Vieja", value: "3"},
              {key: "4br", text: "Tigre", value: "4"}]},
          {key: "3ds", text: "Vuelta De Jorco", value: "3", barrios: [
              {key: "1br", text: "Vuelta De Jorco Centro", value: "1"},
              {key: "2br", text: "Calvario", value: "2"},
              {key: "3br", text: "Ceiba Alta (parte)", value: "3"},
              {key: "4br", text: "Jocotal", value: "4"},
              {key: "5br", text: "Legua De Naranjo", value: "5"},
              {key: "6br", text: "Mangos", value: "6"},
              {key: "7br", text: "Meseta", value: "7"},
              {key: "8br", text: "Monte Redondo", value: "8"},
              {key: "9br", text: "Ojo De Agua", value: "9"},
              {key: "10br", text: "Rosalía", value: "10"},
              {key: "11br", text: "Uruca", value: "11"}]},
          {key: "4ds", text: "San Gabriel", value: "4", barrios: [
              {key: "1br", text: "San Gabriel Centro", value: "1"},
              {key: "2br", text: "Pueblo Nuevo", value: "2"},
              {key: "3br", text: "La Fila (parte)", value: "3"},
              {key: "4br", text: "Limonal", value: "4"},
              {key: "5br", text: "Los Solano", value: "5"},
              {key: "6br", text: "Rancho Grande", value: "6"},
              {key: "7br", text: "Salitral", value: "7"},
              {key: "8br", text: "Tranquerillas", value: "8"},
              {key: "9br", text: "Trinidad (parte)", value: "9"},
              {key: "10br", text: "Villanueva", value: "10"}]},
          {key: "5ds", text: "Legua", value: "5", barrios: [
              {key: "1br", text: "Legua Centro", value: "1"},
              {key: "2br", text: "Alto Buenavista", value: "2"},
              {key: "3br", text: "Altos Del Aguacate", value: "3"},
              {key: "4br", text: "Bajo Bijagual", value: "4"},
              {key: "5br", text: "Bajo Máquinas", value: "5"},
              {key: "6br", text: "Bajo Venegas (parte)", value: "6"},
              {key: "7br", text: "Carmen (parte)", value: "7"}]},
          {key: "6ds", text: "Monterrey", value: "6", barrios: [
              {key: "1br", text: "Monterrey Centro", value: "1"},
              {key: "2br", text: "Ojo De Agua (parte)", value: "2"},
              {key: "3br", text: "Portuguez", value: "3"}]},
          {key: "7ds", text: "Salitrillos", value: "7", barrios: [
              {key: "1br", text: "Salitrillos Centro", value: "1"},
              {key: "2br", text: "Cinco Esquinas (parte)", value: "2"},
              {key: "3br", text: "Santa Teresita", value: "3"},
              {key: "4br", text: "Cerro", value: "4"},
              {key: "5br", text: "Cuesta Grande", value: "5"},
              {key: "6br", text: "Guinealillo", value: "6"},
              {key: "7br", text: "Huaso (parte)", value: "7"},
              {key: "8br", text: "Lagunillas", value: "8"},
              {key: "9br", text: "Palo Blanco", value: "9"},
              {key: "10br", text: "Quebradas", value: "10"},
              {key: "11br", text: "Rincón", value: "11"}]}]},
      {key: "7cn", text: "Mora", value: "7", distritos: [
          {key: "1ds", text: "Colón", value: "1", barrios: [
              {key: "1br", text: "Colón Centro", value: "1"},
              {key: "2br", text: "Alhambra", value: "2"},
              {key: "3br", text: "Brasil", value: "3"},
              {key: "4br", text: "Carreras", value: "4"},
              {key: "5br", text: "Colonia Del Prado", value: "5"},
              {key: "6br", text: "Michoacán", value: "6"},
              {key: "7br", text: "Nuevo Brasil", value: "7"},
              {key: "8br", text: "Piñal", value: "8"},
              {key: "9br", text: "San Bosco", value: "9"},
              {key: "10br", text: "San Vicente", value: "10"},
              {key: "11br", text: "Tablera", value: "11"},
              {key: "12br", text: "Cabriola", value: "12"},
              {key: "13br", text: "Cedral", value: "13"},
              {key: "14br", text: "Cuesta Achiotal", value: "14"},
              {key: "15br", text: "Llano León", value: "15"},
              {key: "16br", text: "Llano Limón", value: "16"},
              {key: "17br", text: "Quebrada Honda", value: "17"},
              {key: "18br", text: "Rodeo", value: "18"},
              {key: "19br", text: "Santísima Trinidad", value: "19"},
              {key: "20br", text: "Ticufres", value: "20"}]},
          {key: "2ds", text: "Guayabo", value: "2", barrios: [
              {key: "1br", text: "Guayabo Centro", value: "1"},
              {key: "2br", text: "Bajo Claras", value: "2"},
              {key: "3br", text: "Bajo Morado", value: "3"},
              {key: "4br", text: "Corrogres", value: "4"},
              {key: "5br", text: "Monte Negro", value: "5"}]},
          {key: "3ds", text: "Tabarcia", value: "3", barrios: [
              {key: "1br", text: "Tabarcia Centro", value: "1"},
              {key: "2br", text: "Bajo Bustamante", value: "2"},
              {key: "3br", text: "Bajo Lima", value: "3"},
              {key: "4br", text: "Bajo Loaiza", value: "4"},
              {key: "5br", text: "Cañas", value: "5"},
              {key: "6br", text: "Corralar", value: "6"},
              {key: "7br", text: "Morado", value: "7"},
              {key: "8br", text: "Piedras Blancas", value: "8"},
              {key: "9br", text: "Salto", value: "9"}]},
          {key: "4ds", text: "Piedras Negras", value: "4", barrios: [
              {key: "1br", text: "Piedras Negras Centro", value: "1"},
              {key: "2br", text: "Chile", value: "2"},
              {key: "3br", text: "Danta", value: "3"},
              {key: "4br", text: "Palma", value: "4"},
              {key: "5br", text: "Quebrada Grande", value: "5"}]},
          {key: "5ds", text: "Picagres", value: "5", barrios: [
              {key: "1br", text: "Picagres Centro", value: "1"},
              {key: "2br", text: "Cordel", value: "2"},
              {key: "3br", text: "Chucás", value: "3"},
              {key: "4br", text: "Jateo", value: "4"},
              {key: "5br", text: "Llano Grande", value: "5"},
              {key: "6br", text: "Monte Frío (potrerillos)", value: "6"}]},
          {key: "6ds", text: "Jaris", value: "6", barrios: [
              {key: "1br", text: "Jaris Centro", value: "1"},
              {key: "2br", text: "Pito", value: "2"}]},
          {key: "7ds", text: "Quitirrisí", value: "7", barrios: [
              {key: "1br", text: "Quitirrisí Centro", value: "1"},
              {key: "2br", text: "San Juan", value: "2"},
              {key: "3br", text: "San Martín", value: "3"},
              {key: "4br", text: "Quebrada Honda", value: "4"}]}]},
      {key: "8cn", text: "Goicoechea", value: "8", distritos: [
          {key: "1ds", text: "Guadalupe", value: "1", barrios: [
              {key: "1br", text: "Guadalupe Centro", value: "1"},
              {key: "2br", text: "Árboles", value: "2"},
              {key: "3br", text: "Colonia Del Río", value: "3"},
              {key: "4br", text: "El Alto (parte)", value: "4"},
              {key: "5br", text: "Fátima", value: "5"},
              {key: "6br", text: "Independencia", value: "6"},
              {key: "7br", text: "Jardín", value: "7"},
              {key: "8br", text: "Magnolia", value: "8"},
              {key: "9br", text: "Maravilla", value: "9"},
              {key: "10br", text: "Margarita", value: "10"},
              {key: "11br", text: "Minerva", value: "11"},
              {key: "12br", text: "Moreno Cañas", value: "12"},
              {key: "13br", text: "Orquídea", value: "13"},
              {key: "14br", text: "Pilar Jiménez", value: "14"},
              {key: "15br", text: "Rothe", value: "15"},
              {key: "16br", text: "San Gerardo", value: "16"},
              {key: "17br", text: "Santa Cecilia", value: "17"},
              {key: "18br", text: "Santa Eduvigis", value: "18"},
              {key: "19br", text: "Santo Cristo", value: "19"},
              {key: "20br", text: "Unión", value: "20"},
              {key: "21br", text: "Yurusti", value: "21"}]},
          {key: "2ds", text: "San Francisco", value: "2", barrios: [
              {key: "1br", text: "San Francisco Centro", value: "1"},
              {key: "2br", text: "Carlos María Ulloa", value: "2"},
              {key: "3br", text: "Tournón", value: "3"}]},
          {key: "3ds", text: "Calle Blancos", value: "3", barrios: [
              {key: "1br", text: "Calle Blancos Centro", value: "1"},
              {key: "2br", text: "Ciprés", value: "2"},
              {key: "3br", text: "Encanto", value: "3"},
              {key: "4br", text: "Esquivel Bonilla", value: "4"},
              {key: "5br", text: "Montelimar", value: "5"},
              {key: "6br", text: "Pinos", value: "6"},
              {key: "7br", text: "San Antonio", value: "7"},
              {key: "8br", text: "San Gabriel", value: "8"},
              {key: "9br", text: "Santo Tomás", value: "9"},
              {key: "10br", text: "Volio", value: "10"}]},
          {key: "4ds", text: "Mata De Plátano", value: "4", barrios: [
              {key: "1br", text: "Mata De Plátano Centro", value: "1"},
              {key: "2br", text: "Bruncas", value: "2"},
              {key: "3br", text: "Carmen", value: "3"},
              {key: "4br", text: "Claraval", value: "4"},
              {key: "5br", text: "Cruz", value: "5"},
              {key: "6br", text: "Cuesta Grande (parte)", value: "6"},
              {key: "7br", text: "Estéfana (parte)", value: "7"},
              {key: "8br", text: "Hortensias", value: "8"},
              {key: "9br", text: "Jaboncillal", value: "9"},
              {key: "10br", text: "Jardines De La Paz", value: "10"},
              {key: "11br", text: "Lourdes", value: "11"},
              {key: "12br", text: "Praderas", value: "12"},
              {key: "13br", text: "Tepeyac", value: "13"},
              {key: "14br", text: "Térraba", value: "14"},
              {key: "15br", text: "Villalta", value: "15"},
              {key: "16br", text: "Villaverde", value: "16"},
              {key: "17br", text: "Vista Del Valle", value: "17"}]},
          {key: "5ds", text: "Ipís", value: "5", barrios: [
              {key: "1br", text: "Ipís Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "El Alto (parte)", value: "3"},
              {key: "4br", text: "Floresta", value: "4"},
              {key: "5br", text: "Korobó", value: "5"},
              {key: "6br", text: "La Mora", value: "6"},
              {key: "7br", text: "Morita", value: "7"},
              {key: "8br", text: "Mozotal", value: "8"},
              {key: "9br", text: "Nazareno", value: "9"},
              {key: "10br", text: "Orquídea", value: "10"},
              {key: "11br", text: "Rodrigo Facio", value: "11"},
              {key: "12br", text: "Santa Clara (parte)", value: "12"},
              {key: "13br", text: "Setillal", value: "13"},
              {key: "14br", text: "Vista Del Monte", value: "14"}]},
          {key: "6ds", text: "Rancho Redondo", value: "6", barrios: [
              {key: "1br", text: "Rancho Redondo Centro", value: "1"},
              {key: "2br", text: "Mirador", value: "2"},
              {key: "3br", text: "Corralillo", value: "3"},
              {key: "4br", text: "Guayabillos", value: "4"},
              {key: "5br", text: "Isla", value: "5"},
              {key: "6br", text: "San Miguel", value: "6"},
              {key: "7br", text: "Vista De Mar", value: "7"}]},
          {key: "7ds", text: "Purral", value: "7", barrios: [
              {key: "1br", text: "Purral Centro", value: "1"},
              {key: "2br", text: "Ana Frank", value: "2"},
              {key: "3br", text: "Castores", value: "3"},
              {key: "4br", text: "Cuadros", value: "4"},
              {key: "5br", text: "Don Carlos", value: "5"},
              {key: "6br", text: "El Alto (parte)", value: "6"},
              {key: "7br", text: "Flor De Liz", value: "7"},
              {key: "8br", text: "Kurú", value: "8"},
              {key: "9br", text: "Lomas De Tepeyac", value: "9"},
              {key: "10br", text: "Lupita", value: "10"},
              {key: "11br", text: "Montesol", value: "11"},
              {key: "12br", text: "Nogales", value: "12"},
              {key: "13br", text: "Pueblo", value: "13"},
              {key: "14br", text: "Violetas", value: "14"}]}]},
      {key: "9cn", text: "Santa Ana", value: "9", distritos: [
          {key: "1ds", text: "Santa Ana", value: "1", barrios: [
              {key: "1br", text: "Santa Ana Centro", value: "1"},
              {key: "2br", text: "Aguas Lindas", value: "2"},
              {key: "3br", text: "Cabañas", value: "3"},
              {key: "4br", text: "Casa Blanca", value: "4"},
              {key: "5br", text: "Lajas (parte)", value: "5"},
              {key: "6br", text: "Obando", value: "6"},
              {key: "7br", text: "Paso Machete (parte)", value: "7"},
              {key: "8br", text: "San Rafael", value: "8"},
              {key: "9br", text: "Corrogres", value: "9"},
              {key: "10br", text: "Pilas", value: "10"}]},
          {key: "2ds", text: "Salitral", value: "2", barrios: [
              {key: "1br", text: "Salitral Centro", value: "1"},
              {key: "2br", text: "Paso Machete (parte)", value: "2"},
              {key: "3br", text: "Robalillo", value: "3"},
              {key: "4br", text: "Alto Raicero", value: "4"},
              {key: "5br", text: "Chirracal", value: "5"},
              {key: "6br", text: "Matinilla", value: "6"},
              {key: "7br", text: "Pabellón", value: "7"},
              {key: "8br", text: "Perico", value: "8"}]},
          {key: "3ds", text: "Pozos", value: "3", barrios: [
              {key: "1br", text: "Pozos Centro", value: "1"},
              {key: "2br", text: "Alto Palomas", value: "2"},
              {key: "3br", text: "Concepción", value: "3"},
              {key: "4br", text: "Cuevas", value: "4"},
              {key: "5br", text: "Chispa", value: "5"},
              {key: "6br", text: "Gavilanes", value: "6"},
              {key: "7br", text: "Honduras", value: "7"},
              {key: "8br", text: "Lajas (parte)", value: "8"},
              {key: "9br", text: "Lindora", value: "9"},
              {key: "10br", text: "Manantial", value: "10"},
              {key: "11br", text: "Real De Pereira (parte)", value: "11"},
              {key: "12br", text: "Valle Del Sol", value: "12"}]},
          {key: "4ds", text: "Uruca", value: "4", barrios: [
              {key: "1br", text: "Río Oro", value: "1"},
              {key: "2br", text: "Chimba", value: "2"},
              {key: "3br", text: "Guapinol", value: "3"},
              {key: "4br", text: "Mata Grande", value: "4"},
              {key: "5br", text: "Mina", value: "5"},
              {key: "6br", text: "Paso Machete (parte)", value: "6"},
              {key: "7br", text: "Río Uruca", value: "7"}]},
          {key: "5ds", text: "Piedades", value: "5", barrios: [
              {key: "1br", text: "Piedades Centro", value: "1"},
              {key: "2br", text: "Caraña", value: "2"},
              {key: "3br", text: "Cebadilla", value: "3"},
              {key: "4br", text: "Finquitas", value: "4"},
              {key: "5br", text: "Montaña Del Sol", value: "5"},
              {key: "6br", text: "Rincón San Marcos", value: "6"},
              {key: "7br", text: "Triunfo", value: "7"}]},
          {key: "6ds", text: "Brasil", value: "6", barrios: [
              {key: "1br", text: "Brasil Centro", value: "1"},
              {key: "2br", text: "Canjel", value: "2"},
              {key: "3br", text: "Copey", value: "3"}]}]},
      {key: "10cn", text: "Alajuelita", value: "10", distritos: [
          {key: "1ds", text: "Alajuelita", value: "1", barrios: [
              {key: "1br", text: "Alajuelita Centro", value: "1"},
              {key: "2br", text: "Bellavista", value: "2"},
              {key: "3br", text: "Chorotega", value: "3"},
              {key: "4br", text: "Lagunilla", value: "4"},
              {key: "5br", text: "Macha", value: "5"},
              {key: "6br", text: "Madrigal", value: "6"}]},
          {key: "2ds", text: "San Josecito", value: "2", barrios: [
              {key: "1br", text: "San Josecito Centro", value: "1"},
              {key: "2br", text: "Aguilar Machado", value: "2"},
              {key: "3br", text: "Cochea", value: "3"},
              {key: "4br", text: "El Alto", value: "4"},
              {key: "5br", text: "Faro Del Suroeste", value: "5"},
              {key: "6br", text: "Filtros", value: "6"},
              {key: "7br", text: "Pueblo Escondido", value: "7"},
              {key: "8br", text: "Vistas De Alajuelita", value: "8"}]},
          {key: "3ds", text: "San Antonio", value: "3", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Caracas", value: "2"},
              {key: "3br", text: "Cascabela", value: "3"},
              {key: "4br", text: "Llano", value: "4"},
              {key: "5br", text: "Piedra De Fuego", value: "5"},
              {key: "6br", text: "Lámparas", value: "6"}]},
          {key: "4ds", text: "Concepción", value: "4", barrios: [
              {key: "1br", text: "Concepción Centro", value: "1"},
              {key: "2br", text: "Almendros", value: "2"},
              {key: "3br", text: "Boca Del Monte", value: "3"},
              {key: "4br", text: "Chirivico", value: "4"},
              {key: "5br", text: "Monte Alto", value: "5"},
              {key: "6br", text: "Once De Abril", value: "6"},
              {key: "7br", text: "Progreso", value: "7"},
              {key: "8br", text: "Tejar (parte)", value: "8"},
              {key: "9br", text: "Vista De San José", value: "9"},
              {key: "10br", text: "Vista Real", value: "10"}]},
          {key: "5ds", text: "San Felipe", value: "5", barrios: [
              {key: "1br", text: "San Felipe Centro", value: "1"},
              {key: "2br", text: "Aurora", value: "2"},
              {key: "3br", text: "Corina Rodríguez", value: "3"},
              {key: "4br", text: "Esquipulas Dos", value: "4"},
              {key: "5br", text: "La Guápil", value: "5"},
              {key: "6br", text: "Peralta", value: "6"},
              {key: "7br", text: "Verbena", value: "7"},
              {key: "8br", text: "Palo Campano", value: "8"}]}]},
      {key: "11cn", text: "Vázquez De Coronado", value: "11", distritos: [
          {key: "1ds", text: "San Isidro", value: "1", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Alamos", value: "2"},
              {key: "3br", text: "Alpes", value: "3"},
              {key: "4br", text: "Arias", value: "4"},
              {key: "5br", text: "Brisa", value: "5"},
              {key: "6br", text: "Cedros", value: "6"},
              {key: "7br", text: "Corazón De Jesús", value: "7"},
              {key: "8br", text: "Durazno", value: "8"},
              {key: "9br", text: "Girasoles (parte)", value: "9"},
              {key: "10br", text: "Huacas", value: "10"},
              {key: "11br", text: "Magnolias", value: "11"},
              {key: "12br", text: "Mercedes", value: "12"},
              {key: "13br", text: "Monte Azul", value: "13"},
              {key: "14br", text: "San Francisco", value: "14"},
              {key: "15br", text: "San Martín", value: "15"},
              {key: "16br", text: "Villa Solidarista", value: "16"}]},
          {key: "2ds", text: "San Rafael", value: "2", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Fanguillo", value: "2"},
              {key: "3br", text: "I Griega", value: "3"},
              {key: "4br", text: "Loma Bonita", value: "4"},
              {key: "5br", text: "Nubes", value: "5"},
              {key: "6br", text: "Patio De Agua", value: "6"},
              {key: "7br", text: "Villa Emaus", value: "7"}]},
          {key: "3ds", text: "Dulce Nombre De Jesús", value: "3", barrios: [
              {key: "1br", text: "Dulce Nombre De Jesús Centro", value: "1"},
              {key: "2br", text: "Calera", value: "2"},
              {key: "3br", text: "Carmen", value: "3"},
              {key: "4br", text: "Gemelas", value: "4"},
              {key: "5br", text: "Josué", value: "5"},
              {key: "6br", text: "Manzanos", value: "6"},
              {key: "7br", text: "Murtal", value: "7"},
              {key: "8br", text: "Sitrae", value: "8"},
              {key: "9br", text: "Valle Felíz", value: "9"},
              {key: "10br", text: "Alto Palma (parte)", value: "10"},
              {key: "11br", text: "Platanares", value: "11"},
              {key: "12br", text: "Rodeo (parte)", value: "12"}]},
          {key: "4ds", text: "Patalillo", value: "4", barrios: [
              {key: "1br", text: "San Antonio", value: "1"},
              {key: "2br", text: "Cornelia", value: "2"},
              {key: "3br", text: "Girasoles (parte)", value: "3"},
              {key: "4br", text: "Horizontes", value: "4"},
              {key: "5br", text: "Irazú", value: "5"},
              {key: "6br", text: "Jardines", value: "6"},
              {key: "7br", text: "Labrador", value: "7"},
              {key: "8br", text: "Patalillo", value: "8"},
              {key: "9br", text: "Santa Marta", value: "9"},
              {key: "10br", text: "Trapiche", value: "10"},
              {key: "11br", text: "Villalinda", value: "11"},
              {key: "12br", text: "Villanova", value: "12"}]},
          {key: "5ds", text: "Cascajal", value: "5", barrios: [
              {key: "1br", text: "San Pedro", value: "1"},
              {key: "2br", text: "Avilés", value: "2"},
              {key: "3br", text: "Cerro Indio", value: "3"},
              {key: "4br", text: "Guaba", value: "4"},
              {key: "5br", text: "Rojizo", value: "5"},
              {key: "6br", text: "Sinaí", value: "6"},
              {key: "7br", text: "Canoa", value: "7"},
              {key: "8br", text: "Cascajal", value: "8"},
              {key: "9br", text: "Choco", value: "9"},
              {key: "10br", text: "Isla", value: "10"},
              {key: "11br", text: "Monserrat", value: "11"},
              {key: "12br", text: "Patillos", value: "12"},
              {key: "13br", text: "Rodeo (parte)", value: "13"},
              {key: "14br", text: "Santa Rita De Casia", value: "14"},
              {key: "15br", text: "Tierras Morenas", value: "15"},
              {key: "16br", text: "Vegas De Cajón", value: "16"},
              {key: "17br", text: "Venita", value: "17"}]}]},
      {key: "12cn", text: "Acosta", value: "12", distritos: [
          {key: "1ds", text: "San Ignacio", value: "1", barrios: [
              {key: "1br", text: "San Ignacio Centro", value: "1"},
              {key: "2br", text: "Abarca", value: "2"},
              {key: "3br", text: "Corral", value: "3"},
              {key: "4br", text: "María Auxiliadora", value: "4"},
              {key: "5br", text: "Ortiga", value: "5"},
              {key: "6br", text: "Pozos", value: "6"},
              {key: "7br", text: "San Martín (san Gerardo)", value: "7"},
              {key: "8br", text: "San Luis", value: "8"},
              {key: "9br", text: "Turrujal", value: "9"},
              {key: "10br", text: "Vereda", value: "10"},
              {key: "11br", text: "Aguablanca (parte)", value: "11"},
              {key: "12br", text: "Alto Escalera", value: "12"},
              {key: "13br", text: "Alto Los Mora", value: "13"},
              {key: "14br", text: "Ángeles", value: "14"},
              {key: "15br", text: "Chirraca (parte)", value: "15"},
              {key: "16br", text: "Esperanza", value: "16"},
              {key: "17br", text: "Potrerillos", value: "17"},
              {key: "18br", text: "Resbalón", value: "18"},
              {key: "19br", text: "Tablazo", value: "19"}]},
          {key: "2ds", text: "Guaitil", value: "2", barrios: [
              {key: "1br", text: "Guaitil Centro", value: "1"},
              {key: "2br", text: "Alto Sierra", value: "2"},
              {key: "3br", text: "Alto Vigía", value: "3"},
              {key: "4br", text: "Bajo Arias", value: "4"},
              {key: "5br", text: "Bajo Bermúdez", value: "5"},
              {key: "6br", text: "Bajo Calvo", value: "6"},
              {key: "7br", text: "Bajo Cárdenas", value: "7"},
              {key: "8br", text: "Bajo Moras", value: "8"},
              {key: "9br", text: "Coyolar", value: "9"},
              {key: "10br", text: "Hondonada", value: "10"},
              {key: "11br", text: "La Cruz", value: "11"},
              {key: "12br", text: "Lagunillas (parte)", value: "12"},
              {key: "13br", text: "Ococa", value: "13"},
              {key: "14br", text: "Toledo", value: "14"},
              {key: "15br", text: "Zapote", value: "15"}]},
          {key: "3ds", text: "Palmichal", value: "3", barrios: [
              {key: "1br", text: "Palmichal Centro", value: "1"},
              {key: "2br", text: "San Pablo", value: "2"},
              {key: "3br", text: "Agua Blanca (parte)", value: "3"},
              {key: "4br", text: "Bajo Cerdas", value: "4"},
              {key: "5br", text: "Bajos De Jorco", value: "5"},
              {key: "6br", text: "Bolívar", value: "6"},
              {key: "7br", text: "Cañadas", value: "7"},
              {key: "8br", text: "Caragral", value: "8"},
              {key: "9br", text: "Corazón De Jesús", value: "9"},
              {key: "10br", text: "Charcalillo", value: "10"},
              {key: "11br", text: "Chirraca (parte)", value: "11"},
              {key: "12br", text: "Fila", value: "12"},
              {key: "13br", text: "Jaular", value: "13"},
              {key: "14br", text: "Lagunillas (parte)", value: "14"},
              {key: "15br", text: "La Mina", value: "15"},
              {key: "16br", text: "La Pita", value: "16"},
              {key: "17br", text: "Los Monge", value: "17"},
              {key: "18br", text: "Playa", value: "18"},
              {key: "19br", text: "San Pablo", value: "19"},
              {key: "20br", text: "Sevilla", value: "20"}]},
          {key: "4ds", text: "Cangrejal", value: "4", barrios: [
              {key: "1br", text: "Cangrejal Centro", value: "1"},
              {key: "2br", text: "Bajo Los Cruces", value: "2"},
              {key: "3br", text: "Ceiba Alta (parte)", value: "3"},
              {key: "4br", text: "Ceiba Baja", value: "4"},
              {key: "5br", text: "Ceiba Este", value: "5"},
              {key: "6br", text: "Escuadra", value: "6"},
              {key: "7br", text: "Gravilias", value: "7"},
              {key: "8br", text: "Lindavista", value: "8"},
              {key: "9br", text: "Llano Bonito", value: "9"},
              {key: "10br", text: "Mesa", value: "10"},
              {key: "11br", text: "Naranjal", value: "11"},
              {key: "12br", text: "Perpetuo Socorro", value: "12"},
              {key: "13br", text: "Tejar", value: "13"},
              {key: "14br", text: "Tiquires", value: "14"}]},
          {key: "5ds", text: "Sabanillas", value: "5", barrios: [
              {key: "1br", text: "Sabanillas Centro", value: "1"},
              {key: "2br", text: "Alto Parritón", value: "2"},
              {key: "3br", text: "Bajo Palma", value: "3"},
              {key: "4br", text: "Bajo Pérez", value: "4"},
              {key: "5br", text: "Bijagual", value: "5"},
              {key: "6br", text: "Breñón", value: "6"},
              {key: "7br", text: "Caspirola", value: "7"},
              {key: "8br", text: "Colorado", value: "8"},
              {key: "9br", text: "Cuesta Aguacate", value: "9"},
              {key: "10br", text: "Limas", value: "10"},
              {key: "11br", text: "Parritón", value: "11"},
              {key: "12br", text: "Plomo", value: "12"},
              {key: "13br", text: "Sabanas", value: "13"},
              {key: "14br", text: "San Jerónimo", value: "14"},
              {key: "15br", text: "Soledad", value: "15"},
              {key: "16br", text: "Téruel", value: "16"},
              {key: "17br", text: "Tiquiritos", value: "17"},
              {key: "18br", text: "Uruca", value: "18"},
              {key: "19br", text: "Zoncuano", value: "19"}]}]},
      {key: "13cn", text: "Tibás", value: "13", distritos: [
          {key: "1ds", text: "San Juan", value: "1", barrios: [
              {key: "1br", text: "San Juan Centro", value: "1"},
              {key: "2br", text: "Acacias", value: "2"},
              {key: "3br", text: "Arboleda", value: "3"},
              {key: "4br", text: "Asturias", value: "4"},
              {key: "5br", text: "Estudiantes", value: "5"},
              {key: "6br", text: "Florida", value: "6"},
              {key: "7br", text: "González Truque", value: "7"},
              {key: "8br", text: "Jesús Jiménez Zamora", value: "8"},
              {key: "9br", text: "Lindavista", value: "9"},
              {key: "10br", text: "Rosas", value: "10"},
              {key: "11br", text: "San Jerónimo", value: "11"},
              {key: "12br", text: "Santa Eduvigis", value: "12"},
              {key: "13br", text: "Valle", value: "13"},
              {key: "14br", text: "Versalles", value: "14"},
              {key: "15br", text: "Villafranca", value: "15"},
              {key: "16br", text: "Virginia", value: "16"}]},
          {key: "2ds", text: "Cinco Esquinas", value: "2", barrios: [
              {key: "1br", text: "Cinco Esquinas Centro", value: "1"},
              {key: "2br", text: "Bajo Piuses", value: "2"},
              {key: "3br", text: "Copey", value: "3"},
              {key: "4br", text: "Leiva Urcuyo", value: "4"},
              {key: "5br", text: "Lilas", value: "5"},
              {key: "6br", text: "Lomas Del Pinar", value: "6"},
              {key: "7br", text: "Montecarlo", value: "7"},
              {key: "8br", text: "Santa Teresa", value: "8"}]},
          {key: "3ds", text: "Anselmo Llorente", value: "3", barrios: [
              {key: "1br", text: "Anselmo Llorente Centro", value: "1"},
              {key: "2br", text: "Apolo", value: "2"},
              {key: "3br", text: "Dalia", value: "3"},
              {key: "4br", text: "Estancia", value: "4"},
              {key: "5br", text: "Fletcher", value: "5"},
              {key: "6br", text: "Franjo", value: "6"},
              {key: "7br", text: "Jardines De Tibás", value: "7"},
              {key: "8br", text: "Jardines La Trinidad", value: "8"},
              {key: "9br", text: "Monterreal", value: "9"},
              {key: "10br", text: "Palmeras", value: "10"},
              {key: "11br", text: "Santa Mónica", value: "11"},
              {key: "12br", text: "Talamanca", value: "12"},
              {key: "13br", text: "Vergel", value: "13"}]},
          {key: "4ds", text: "León XIII", value: "4", barrios: [
              {key: "1br", text: "León XIII Centro", value: "1"},
              {key: "2br", text: "Doña Fabiola", value: "2"},
              {key: "3br", text: "Garabito", value: "3"}]},
          {key: "5ds", text: "Colima", value: "5", barrios: [
              {key: "1br", text: "Colima Centro", value: "1"},
              {key: "2br", text: "Anselmo Alvarado", value: "2"},
              {key: "3br", text: "Balsa", value: "3"},
              {key: "4br", text: "Cuatro Reinas", value: "4"},
              {key: "5br", text: "Orquídeas", value: "5"},
              {key: "6br", text: "Rey", value: "6"},
              {key: "7br", text: "San Agustín", value: "7"}]}]},
      {key: "14cn", text: "Moravia", value: "14", distritos: [
          {key: "1ds", text: "San Vicente", value: "1", barrios: [
              {key: "1br", text: "San Vicente Centro", value: "1"},
              {key: "2br", text: "Alondra", value: "2"},
              {key: "3br", text: "Americano", value: "3"},
              {key: "4br", text: "Américas", value: "4"},
              {key: "5br", text: "Bajo Isla", value: "5"},
              {key: "6br", text: "Bajo Varelas", value: "6"},
              {key: "7br", text: "Barro De Olla", value: "7"},
              {key: "8br", text: "Caragua", value: "8"},
              {key: "9br", text: "Carmen", value: "9"},
              {key: "10br", text: "Colegios", value: "10"},
              {key: "11br", text: "Colegios Norte", value: "11"},
              {key: "12br", text: "Chaves", value: "12"},
              {key: "13br", text: "El Alto (parte)", value: "13"},
              {key: "14br", text: "Flor", value: "14"},
              {key: "15br", text: "Florencia", value: "15"},
              {key: "16br", text: "Guaria", value: "16"},
              {key: "17br", text: "Guaria Oriental", value: "17"},
              {key: "18br", text: "Isla", value: "18"},
              {key: "19br", text: "Jardines De Moravia", value: "19"},
              {key: "20br", text: "La Casa", value: "20"},
              {key: "21br", text: "Ladrillera", value: "21"},
              {key: "22br", text: "Robles", value: "22"},
              {key: "23br", text: "Romeral", value: "23"},
              {key: "24br", text: "Sagrado Corazón", value: "24"},
              {key: "25br", text: "San Blas", value: "25"},
              {key: "26br", text: "San Jorge", value: "26"},
              {key: "27br", text: "San Martín", value: "27"},
              {key: "28br", text: "San Rafael", value: "28"},
              {key: "29br", text: "Santa Clara (parte)", value: "29"},
              {key: "30br", text: "Santo Tomás", value: "30"},
              {key: "31br", text: "Saprissa", value: "31"}]},
          {key: "2ds", text: "San Jerónimo", value: "2", barrios: [
              {key: "1br", text: "San Jerónimo Centro", value: "1"},
              {key: "2br", text: "Alto Palma (parte)", value: "2"},
              {key: "3br", text: "Platanares", value: "3"},
              {key: "4br", text: "Tornillal", value: "4"},
              {key: "5br", text: "Torre", value: "5"}]},
          {key: "3ds", text: "La Trinidad", value: "3", barrios: [
              {key: "1br", text: "La Trinidad Centro (guayabal)", value: "1"},
              {key: "2br", text: "Altos De Trinidad", value: "2"},
              {key: "3br", text: "Cipreses", value: "3"},
              {key: "4br", text: "Colonia", value: "4"},
              {key: "5br", text: "Moral", value: "5"},
              {key: "6br", text: "Níspero", value: "6"},
              {key: "7br", text: "Rosal", value: "7"},
              {key: "8br", text: "Ruano", value: "8"},
              {key: "9br", text: "Sitios", value: "9"},
              {key: "10br", text: "Tanques", value: "10"},
              {key: "11br", text: "Virilla", value: "11"}]}]},
      {key: "15cn", text: "Montes De Oca", value: "15", distritos: [
          {key: "1ds", text: "San Pedro", value: "1", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Alhambra", value: "2"},
              {key: "3br", text: "Azáleas", value: "3"},
              {key: "4br", text: "Carmiol", value: "4"},
              {key: "5br", text: "Cedral", value: "5"},
              {key: "6br", text: "Dent (parte)", value: "6"},
              {key: "7br", text: "Francisco Peralta (parte)", value: "7"},
              {key: "8br", text: "Fuentes", value: "8"},
              {key: "9br", text: "Granja", value: "9"},
              {key: "10br", text: "Kezia", value: "10"},
              {key: "11br", text: "Lourdes", value: "11"},
              {key: "12br", text: "Monterrey", value: "12"},
              {key: "13br", text: "Nadori", value: "13"},
              {key: "14br", text: "Oriente", value: "14"},
              {key: "15br", text: "Pinto", value: "15"},
              {key: "16br", text: "Prados Del Este", value: "16"},
              {key: "17br", text: "Roosevelt", value: "17"},
              {key: "18br", text: "San Gerardo (parte)", value: "18"},
              {key: "19br", text: "Santa Marta", value: "19"},
              {key: "20br", text: "Saprissa", value: "20"},
              {key: "21br", text: "Vargas Araya", value: "21"},
              {key: "22br", text: "Yoses", value: "22"}]},
          {key: "2ds", text: "Sabanilla", value: "2", barrios: [
              {key: "1br", text: "Sabanilla Centro", value: "1"},
              {key: "2br", text: "Arboleda", value: "2"},
              {key: "3br", text: "Bloquera", value: "3"},
              {key: "4br", text: "Cedros", value: "4"},
              {key: "5br", text: "El Cristo (parte)", value: "5"},
              {key: "6br", text: "Españolita", value: "6"},
              {key: "7br", text: "Luciana", value: "7"},
              {key: "8br", text: "Marsella", value: "8"},
              {key: "9br", text: "Maravilla", value: "9"},
              {key: "10br", text: "Rodeo", value: "10"},
              {key: "11br", text: "Rosales", value: "11"},
              {key: "12br", text: "San Marino", value: "12"}]},
          {key: "3ds", text: "Mercedes", value: "3", barrios: [
              {key: "1br", text: "Betania", value: "1"},
              {key: "2br", text: "Alma Máter", value: "2"},
              {key: "3br", text: "Damiana", value: "3"},
              {key: "4br", text: "Dent (parte)", value: "4"},
              {key: "5br", text: "Guaymí", value: "5"},
              {key: "6br", text: "Paso Hondo", value: "6"},
              {key: "7br", text: "Paulina", value: "7"}]},
          {key: "4ds", text: "San Rafael", value: "4", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Alameda", value: "2"},
              {key: "3br", text: "Andrómeda", value: "3"},
              {key: "4br", text: "Begonia", value: "4"},
              {key: "5br", text: "Cuesta Grande (parte)", value: "5"},
              {key: "6br", text: "El Cristo (parte)", value: "6"},
              {key: "7br", text: "Estéfana (parte)", value: "7"},
              {key: "8br", text: "Europa", value: "8"},
              {key: "9br", text: "Liburgia", value: "9"},
              {key: "10br", text: "Mansiones (parte)", value: "10"},
              {key: "11br", text: "Maruz", value: "11"},
              {key: "12br", text: "Salitrillos", value: "12"}]}]},
      {key: "16cn", text: "Turrubares", value: "16", distritos: [
          {key: "1ds", text: "San Pablo", value: "1", barrios: [
              {key: "1br", text: "San Pablo Centro", value: "1"},
              {key: "2br", text: "Alto Poró", value: "2"},
              {key: "3br", text: "Bolsón", value: "3"},
              {key: "4br", text: "Purires", value: "4"}]},
          {key: "2ds", text: "San Pedro", value: "2", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Alto Limón", value: "2"},
              {key: "3br", text: "Florecilla", value: "3"},
              {key: "4br", text: "Limón", value: "4"},
              {key: "5br", text: "Palmar", value: "5"},
              {key: "6br", text: "Pita Villa Colina", value: "6"}]},
          {key: "3ds", text: "San Juan De Mata", value: "3", barrios: [
              {key: "1br", text: "San Juan De Mata Centro (llano Bonito)", value: "1"},
              {key: "2br", text: "Bajo Laguna", value: "2"},
              {key: "3br", text: "El Barro", value: "3"},
              {key: "4br", text: "Molenillo", value: "4"},
              {key: "5br", text: "Paso Agres", value: "5"},
              {key: "6br", text: "Surtubal", value: "6"},
              {key: "7br", text: "Tronco Negro", value: "7"}]},
          {key: "4ds", text: "San Luis", value: "4", barrios: [
              {key: "1br", text: "San Luis Centro", value: "1"},
              {key: "2br", text: "Pital", value: "2"},
              {key: "3br", text: "Potenciana Arriba", value: "3"},
              {key: "4br", text: "Quebrada Azul", value: "4"},
              {key: "5br", text: "San Francisco", value: "5"},
              {key: "6br", text: "San Rafael", value: "6"}]},
          {key: "5ds", text: "Carara", value: "5", barrios: [
              {key: "1br", text: "Bijagual", value: "1"},
              {key: "2br", text: "Alto Espavel", value: "2"},
              {key: "3br", text: "Angostura (parte)", value: "3"},
              {key: "4br", text: "Bijagualito", value: "4"},
              {key: "5br", text: "Bola", value: "5"},
              {key: "6br", text: "Carara", value: "6"},
              {key: "7br", text: "Cima", value: "7"},
              {key: "8br", text: "Delicias", value: "8"},
              {key: "9br", text: "El Sur", value: "9"},
              {key: "10br", text: "Esperanza", value: "10"},
              {key: "11br", text: "Fila Negra", value: "11"},
              {key: "12br", text: "Galán", value: "12"},
              {key: "13br", text: "La Trampa", value: "13"},
              {key: "14br", text: "Lajas", value: "14"},
              {key: "15br", text: "Mata De Platano", value: "15"},
              {key: "16br", text: "Montelimar", value: "16"},
              {key: "17br", text: "Pacayal", value: "17"},
              {key: "18br", text: "Pavona", value: "18"},
              {key: "19br", text: "Quina", value: "19"},
              {key: "20br", text: "Río Negro", value: "20"},
              {key: "21br", text: "Río Seco", value: "21"},
              {key: "22br", text: "San Francisco", value: "22"},
              {key: "23br", text: "San Gabriel", value: "23"},
              {key: "24br", text: "San Isidro", value: "24"},
              {key: "25br", text: "San Jerónimo", value: "25"},
              {key: "26br", text: "Tulín", value: "26"}]}]},
      {key: "17cn", text: "Dota", value: "17", distritos: [
          {key: "1ds", text: "Santa María", value: "1", barrios: [
              {key: "1br", text: "Santa María Centro", value: "1"},
              {key: "2br", text: "Bandera", value: "2"},
              {key: "3br", text: "Higueronal", value: "3"},
              {key: "4br", text: "Llano", value: "4"},
              {key: "5br", text: "Nubes", value: "5"},
              {key: "6br", text: "San Rafael", value: "6"},
              {key: "7br", text: "Botella", value: "7"},
              {key: "8br", text: "Cedral", value: "8"},
              {key: "9br", text: "Guaria", value: "9"},
              {key: "10br", text: "Naranjo", value: "10"},
              {key: "11br", text: "Quebrada Grande (parte)", value: "11"},
              {key: "12br", text: "Reyes", value: "12"},
              {key: "13br", text: "San Joaquín", value: "13"},
              {key: "14br", text: "San Lucas", value: "14"},
              {key: "15br", text: "Sukia", value: "15"},
              {key: "16br", text: "Vapor", value: "16"}]},
          {key: "2ds", text: "Jardín", value: "2", barrios: [
              {key: "1br", text: "Jardín Centro", value: "1"},
              {key: "2br", text: "Cabeceras De Tarrazú", value: "2"},
              {key: "3br", text: "Chonta (parte)", value: "3"},
              {key: "4br", text: "Quebradillas", value: "4"}]},
          {key: "3ds", text: "Copey", value: "3", barrios: [
              {key: "1br", text: "Copey Centro", value: "1"},
              {key: "2br", text: "Bernardo Ureña", value: "2"},
              {key: "3br", text: "Alto Cañazo", value: "3"},
              {key: "4br", text: "Alto Indio", value: "4"},
              {key: "5br", text: "Alto Miramar", value: "5"},
              {key: "6br", text: "Cañón (parte)", value: "6"},
              {key: "7br", text: "Cima", value: "7"},
              {key: "8br", text: "Cruce Chinchilla", value: "8"},
              {key: "9br", text: "Florida", value: "9"},
              {key: "10br", text: "Garrafa", value: "10"},
              {key: "11br", text: "Jaboncillo", value: "11"},
              {key: "12br", text: "Madreselva", value: "12"},
              {key: "13br", text: "Ojo De Agua (parte)", value: "13"},
              {key: "14br", text: "Paso Macho (parte)", value: "14"},
              {key: "15br", text: "Pedregoso", value: "15"},
              {key: "16br", text: "Providencia", value: "16"},
              {key: "17br", text: "Quebrada Grande (parte)", value: "17"},
              {key: "18br", text: "Quebrador", value: "18"},
              {key: "19br", text: "Río Blanco", value: "19"},
              {key: "20br", text: "Salsipuedes (parte)", value: "20"},
              {key: "21br", text: "San Carlos", value: "21"},
              {key: "22br", text: "San Gerardo", value: "22"},
              {key: "23br", text: "Trinidad", value: "23"},
              {key: "24br", text: "Vueltas", value: "24"}]}]},
      {key: "18cn", text: "Curridabat", value: "18", distritos: [
          {key: "1ds", text: "Curridabat", value: "1", barrios: [
              {key: "1br", text: "Curridabat Centro", value: "1"},
              {key: "2br", text: "Ahogados (parte)", value: "2"},
              {key: "3br", text: "Aromático", value: "3"},
              {key: "4br", text: "Cipreses", value: "4"},
              {key: "5br", text: "Chapultepec", value: "5"},
              {key: "6br", text: "Dorados", value: "6"},
              {key: "7br", text: "Guayabos", value: "7"},
              {key: "8br", text: "Hacienda Vieja", value: "8"},
              {key: "9br", text: "Hogar", value: "9"},
              {key: "10br", text: "José María Zeledón", value: "10"},
              {key: "11br", text: "Laguna", value: "11"},
              {key: "12br", text: "La Lía", value: "12"},
              {key: "13br", text: "Mallorca", value: "13"},
              {key: "14br", text: "María Auxiliadora", value: "14"},
              {key: "15br", text: "Miramontes", value: "15"},
              {key: "16br", text: "Nopalera", value: "16"},
              {key: "17br", text: "Plaza Del Sol", value: "17"},
              {key: "18br", text: "Prado", value: "18"},
              {key: "19br", text: "San José", value: "19"},
              {key: "20br", text: "Santa Cecilia", value: "20"},
              {key: "21br", text: "Tacaco", value: "21"}]},
          {key: "2ds", text: "Granadilla", value: "2", barrios: [
              {key: "1br", text: "Granadilla Centro", value: "1"},
              {key: "2br", text: "Biarquiria", value: "2"},
              {key: "3br", text: "Eucalipto", value: "3"},
              {key: "4br", text: "Freses", value: "4"},
              {key: "5br", text: "Granadilla Norte", value: "5"},
              {key: "6br", text: "Granadilla Sur", value: "6"},
              {key: "7br", text: "Montaña Rusa (parte)", value: "7"}]},
          {key: "3ds", text: "Sánchez", value: "3", barrios: [
              {key: "1br", text: "Sánchez Centro", value: "1"},
              {key: "2br", text: "Araucauria (parte)", value: "2"},
              {key: "3br", text: "Lomas De Ayarco", value: "3"},
              {key: "4br", text: "Pinares", value: "4"}]},
          {key: "4ds", text: "Tirrases", value: "4", barrios: [
              {key: "1br", text: "Tirrases Centro", value: "1"},
              {key: "2br", text: "Colina", value: "2"},
              {key: "3br", text: "Lomas De San Pancracio", value: "3"},
              {key: "4br", text: "Ponderosa", value: "4"},
              {key: "5br", text: "Quince De Agosto", value: "5"}]}]},
      {key: "19cn", text: "Pérez Zeledón", value: "19", distritos: [
          {key: "1ds", text: "San Isidro De El General", value: "1", barrios: [
              {key: "1br", text: "San Isidro De El General Centro", value: "1"},
              {key: "2br", text: "Aeropuerto", value: "2"},
              {key: "3br", text: "Alto Alonso", value: "3"},
              {key: "4br", text: "Boruca", value: "4"},
              {key: "5br", text: "Boston", value: "5"},
              {key: "6br", text: "Cementerio", value: "6"},
              {key: "7br", text: "Cooperativa", value: "7"},
              {key: "8br", text: "Cristo Rey", value: "8"},
              {key: "9br", text: "Doce De Marzo", value: "9"},
              {key: "10br", text: "Dorotea", value: "10"},
              {key: "11br", text: "Durán Picado", value: "11"},
              {key: "12br", text: "España", value: "12"},
              {key: "13br", text: "Estadio", value: "13"},
              {key: "14br", text: "Evans Gordon Wilson", value: "14"},
              {key: "15br", text: "González", value: "15"},
              {key: "16br", text: "Hospital", value: "16"},
              {key: "17br", text: "Hoyón", value: "17"},
              {key: "18br", text: "I Griega", value: "18"},
              {key: "19br", text: "Las Américas", value: "19"},
              {key: "20br", text: "Lomas De Cocorí", value: "20"},
              {key: "21br", text: "Luis Monge", value: "21"},
              {key: "22br", text: "Morazán", value: "22"},
              {key: "23br", text: "Pavones", value: "23"},
              {key: "24br", text: "Pedregoso", value: "24"},
              {key: "25br", text: "Pocito", value: "25"},
              {key: "26br", text: "Prado", value: "26"},
              {key: "27br", text: "Romero", value: "27"},
              {key: "28br", text: "Sagrada Familia", value: "28"},
              {key: "29br", text: "San Andrés", value: "29"},
              {key: "30br", text: "San Luis", value: "30"},
              {key: "31br", text: "San Rafael Sur", value: "31"},
              {key: "32br", text: "San Vicente", value: "32"},
              {key: "33br", text: "Santa Cecilia", value: "33"},
              {key: "34br", text: "Sinaí", value: "34"},
              {key: "35br", text: "Tierra Prometida", value: "35"},
              {key: "36br", text: "Tormenta", value: "36"},
              {key: "37br", text: "Unesco", value: "37"},
              {key: "38br", text: "Valverde", value: "38"},
              {key: "39br", text: "Alto Ceibo", value: "39"},
              {key: "40br", text: "Alto Huacas", value: "40"},
              {key: "41br", text: "Alto Sajaral", value: "41"},
              {key: "42br", text: "Alto San Juan", value: "42"},
              {key: "43br", text: "Alto Tumbas", value: "43"},
              {key: "44br", text: "Angostura", value: "44"},
              {key: "45br", text: "Bajo Ceibo", value: "45"},
              {key: "46br", text: "Bajo Esperanzas", value: "46"},
              {key: "47br", text: "Bajo Mora", value: "47"},
              {key: "48br", text: "Bijaguales", value: "48"},
              {key: "49br", text: "Bocana", value: "49"},
              {key: "50br", text: "Bonita", value: "50"},
              {key: "51br", text: "Ceibo", value: "51"},
              {key: "52br", text: "Ceniza", value: "52"},
              {key: "53br", text: "Dorado", value: "53"},
              {key: "54br", text: "Esperanzas", value: "54"},
              {key: "55br", text: "Guadalupe", value: "55"},
              {key: "56br", text: "Guaria", value: "56"},
              {key: "57br", text: "Higuerones", value: "57"},
              {key: "58br", text: "Jilguero", value: "58"},
              {key: "59br", text: "Jilguero Sur", value: "59"},
              {key: "60br", text: "Los Guayabos", value: "60"},
              {key: "61br", text: "María Auxiliadora", value: "61"},
              {key: "62br", text: "Miravalles", value: "62"},
              {key: "63br", text: "Morete", value: "63"},
              {key: "64br", text: "Ojo De Agua", value: "64"},
              {key: "65br", text: "Ocho De Diciembre", value: "65"},
              {key: "66br", text: "Pacuarito", value: "66"},
              {key: "67br", text: "Palma", value: "67"},
              {key: "68br", text: "Paso Beita", value: "68"},
              {key: "69br", text: "Paso Lagarto", value: "69"},
              {key: "70br", text: "Quebrada Honda (parte)", value: "70"},
              {key: "71br", text: "Quebrada Vueltas", value: "71"},
              {key: "72br", text: "Quebradas", value: "72"},
              {key: "73br", text: "Roble", value: "73"},
              {key: "74br", text: "Rosario", value: "74"},
              {key: "75br", text: "San Agustín", value: "75"},
              {key: "76br", text: "San Jorge", value: "76"},
              {key: "77br", text: "San Juan De Miramar", value: "77"},
              {key: "78br", text: "San Lorenzo", value: "78"},
              {key: "79br", text: "San Rafael Norte", value: "79"},
              {key: "80br", text: "Santa Fé", value: "80"},
              {key: "81br", text: "Santa Marta", value: "81"},
              {key: "82br", text: "Suiza (parte)", value: "82"},
              {key: "83br", text: "Tajo", value: "83"},
              {key: "84br", text: "Toledo", value: "84"},
              {key: "85br", text: "Tronconales", value: "85"},
              {key: "86br", text: "Tuis", value: "86"},
              {key: "87br", text: "Villanueva", value: "87"}]},
          {key: "2ds", text: "El General", value: "2", barrios: [
              {key: "1br", text: "General Viejo", value: "1"},
              {key: "2br", text: "Arepa", value: "2"},
              {key: "3br", text: "Carmen", value: "3"},
              {key: "4br", text: "Chanchos", value: "4"},
              {key: "5br", text: "Hermosa", value: "5"},
              {key: "6br", text: "Linda", value: "6"},
              {key: "7br", text: "Linda Arriba", value: "7"},
              {key: "8br", text: "Miraflores", value: "8"},
              {key: "9br", text: "Paraíso De La Tierra", value: "9"},
              {key: "10br", text: "Peñas Blancas", value: "10"},
              {key: "11br", text: "Quizarrá (parte)", value: "11"},
              {key: "12br", text: "San Blas", value: "12"},
              {key: "13br", text: "San Luis", value: "13"},
              {key: "14br", text: "Santa Cruz", value: "14"},
              {key: "15br", text: "Santa Elena", value: "15"},
              {key: "16br", text: "Trinidad", value: "16"}]},
          {key: "3ds", text: "Daniel Flores", value: "3", barrios: [
              {key: "1br", text: "Palmares", value: "1"},
              {key: "2br", text: "Alto Brisas", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Aurora", value: "4"},
              {key: "5br", text: "Chiles", value: "5"},
              {key: "6br", text: "Crematorio", value: "6"},
              {key: "7br", text: "Daniel Flores", value: "7"},
              {key: "8br", text: "Laboratorio", value: "8"},
              {key: "9br", text: "Los Pinos", value: "9"},
              {key: "10br", text: "Loma Verde", value: "10"},
              {key: "11br", text: "Lourdes", value: "11"},
              {key: "12br", text: "Rosas", value: "12"},
              {key: "13br", text: "Rosa Iris", value: "13"},
              {key: "14br", text: "San Francisco", value: "14"},
              {key: "15br", text: "Santa Margarita", value: "15"},
              {key: "16br", text: "Trocha", value: "16"},
              {key: "17br", text: "Villa Ligia", value: "17"},
              {key: "18br", text: "Aguas Buenas (parte)", value: "18"},
              {key: "19br", text: "Bajos De Pacuar", value: "19"},
              {key: "20br", text: "Concepción (parte)", value: "20"},
              {key: "21br", text: "Corazón De Jesús", value: "21"},
              {key: "22br", text: "Juntas De Pacuar", value: "22"},
              {key: "23br", text: "Paso Bote", value: "23"},
              {key: "24br", text: "Patio De Agua", value: "24"},
              {key: "25br", text: "Peje", value: "25"},
              {key: "26br", text: "Percal", value: "26"},
              {key: "27br", text: "Pinar Del Río", value: "27"},
              {key: "28br", text: "Quebrada Honda (parte)", value: "28"},
              {key: "29br", text: "Repunta", value: "29"},
              {key: "30br", text: "Reyes", value: "30"},
              {key: "31br", text: "Ribera", value: "31"},
              {key: "32br", text: "Suiza (parte)", value: "32"}]},
          {key: "4ds", text: "Rivas", value: "4", barrios: [
              {key: "1br", text: "Rivas Centro", value: "1"},
              {key: "2br", text: "Linda Vista", value: "2"},
              {key: "3br", text: "Lourdes", value: "3"},
              {key: "4br", text: "Alaska", value: "4"},
              {key: "5br", text: "Altamira", value: "5"},
              {key: "6br", text: "Alto Jaular", value: "6"},
              {key: "7br", text: "Ángeles", value: "7"},
              {key: "8br", text: "Boquete", value: "8"},
              {key: "9br", text: "Buenavista", value: "9"},
              {key: "10br", text: "Canaán", value: "10"},
              {key: "11br", text: "Chimirol", value: "11"},
              {key: "12br", text: "Chispa", value: "12"},
              {key: "13br", text: "Chuma", value: "13"},
              {key: "14br", text: "División (parte)", value: "14"},
              {key: "15br", text: "Guadalupe", value: "15"},
              {key: "16br", text: "Herradura", value: "16"},
              {key: "17br", text: "La Bambú", value: "17"},
              {key: "18br", text: "Los Monges", value: "18"},
              {key: "19br", text: "Monterrey", value: "19"},
              {key: "20br", text: "Palmital", value: "20"},
              {key: "21br", text: "Piedra Alta", value: "21"},
              {key: "22br", text: "Playa Quesada", value: "22"},
              {key: "23br", text: "Playas", value: "23"},
              {key: "24br", text: "Pueblo Nuevo", value: "24"},
              {key: "25br", text: "Río Blanco", value: "25"},
              {key: "26br", text: "San Antonio", value: "26"},
              {key: "27br", text: "San Gerardo", value: "27"},
              {key: "28br", text: "San José", value: "28"},
              {key: "29br", text: "San Juan Norte", value: "29"},
              {key: "30br", text: "Siberia (parte)", value: "30"},
              {key: "31br", text: "Tirrá", value: "31"},
              {key: "32br", text: "Zapotal", value: "32"}]},
          {key: "5ds", text: "San Pedro", value: "5", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Cruz Roja", value: "2"},
              {key: "3br", text: "Arenilla (junta)", value: "3"},
              {key: "4br", text: "Alto Calderón", value: "4"},
              {key: "5br", text: "Cedral (parte)", value: "5"},
              {key: "6br", text: "Fátima", value: "6"},
              {key: "7br", text: "Fortuna", value: "7"},
              {key: "8br", text: "Guaria", value: "8"},
              {key: "9br", text: "Laguna", value: "9"},
              {key: "10br", text: "Rinconada Vega", value: "10"},
              {key: "11br", text: "San Jerónimo", value: "11"},
              {key: "12br", text: "San Juan", value: "12"},
              {key: "13br", text: "San Juancito", value: "13"},
              {key: "14br", text: "San Rafael", value: "14"},
              {key: "15br", text: "Santa Ana", value: "15"},
              {key: "16br", text: "Santo Domingo", value: "16"},
              {key: "17br", text: "Tambor", value: "17"},
              {key: "18br", text: "Unión", value: "18"},
              {key: "19br", text: "Zapotal", value: "19"}]},
          {key: "6ds", text: "Platanares", value: "6", barrios: [
              {key: "1br", text: "San Rafael", value: "1"},
              {key: "2br", text: "Aguas Buenas (parte)", value: "2"},
              {key: "3br", text: "Bajo Bonitas", value: "3"},
              {key: "4br", text: "Bajo Espinoza", value: "4"},
              {key: "5br", text: "Bolivia", value: "5"},
              {key: "6br", text: "Bonitas", value: "6"},
              {key: "7br", text: "Buenos Aires", value: "7"},
              {key: "8br", text: "Concepción (parte)", value: "8"},
              {key: "9br", text: "Cristo Rey", value: "9"},
              {key: "10br", text: "La Sierra", value: "10"},
              {key: "11br", text: "Lourdes", value: "11"},
              {key: "12br", text: "Mastatal", value: "12"},
              {key: "13br", text: "Mollejoncito", value: "13"},
              {key: "14br", text: "Mollejones", value: "14"},
              {key: "15br", text: "Naranjos", value: "15"},
              {key: "16br", text: "San Pablito", value: "16"},
              {key: "17br", text: "San Pablo", value: "17"},
              {key: "18br", text: "Socorro", value: "18"},
              {key: "19br", text: "Surtubal (parte)", value: "19"},
              {key: "20br", text: "Villa Argentina", value: "20"},
              {key: "21br", text: "Villa Flor", value: "21"},
              {key: "22br", text: "Vista De Mar", value: "22"}]},
          {key: "7ds", text: "Pejibaye", value: "7", barrios: [
              {key: "1br", text: "Pejibaye Centro", value: "1"},
              {key: "2br", text: "Achiotal", value: "2"},
              {key: "3br", text: "Aguila", value: "3"},
              {key: "4br", text: "Alto Trinidad (puñal)", value: "4"},
              {key: "5br", text: "Bajo Caliente", value: "5"},
              {key: "6br", text: "Bajo Minas", value: "6"},
              {key: "7br", text: "Barrionuevo", value: "7"},
              {key: "8br", text: "Bellavista", value: "8"},
              {key: "9br", text: "Calientillo", value: "9"},
              {key: "10br", text: "Delicias", value: "10"},
              {key: "11br", text: "Desamparados", value: "11"},
              {key: "12br", text: "El Progreso", value: "12"},
              {key: "13br", text: "Gibre", value: "13"},
              {key: "14br", text: "Guadalupe", value: "14"},
              {key: "15br", text: "Las Cruces", value: "15"},
              {key: "16br", text: "Mesas", value: "16"},
              {key: "17br", text: "Minas", value: "17"},
              {key: "18br", text: "Paraiso", value: "18"},
              {key: "19br", text: "San Antonio", value: "19"},
              {key: "20br", text: "San Marcos", value: "20"},
              {key: "21br", text: "San Martín", value: "21"},
              {key: "22br", text: "San Miguel", value: "22"},
              {key: "23br", text: "Santa Fe", value: "23"},
              {key: "24br", text: "Surtubal (parte)", value: "24"},
              {key: "25br", text: "Trinidad", value: "25"},
              {key: "26br", text: "Veracruz", value: "26"},
              {key: "27br", text: "Zapote", value: "27"}]},
          {key: "8ds", text: "Cajón", value: "8", barrios: [
              {key: "1br", text: "Cajón Centro", value: "1"},
              {key: "2br", text: "Cedral (parte)", value: "2"},
              {key: "3br", text: "El Quemado", value: "3"},
              {key: "4br", text: "Gloria", value: "4"},
              {key: "5br", text: "Las Brisas", value: "5"},
              {key: "6br", text: "Las Vegas", value: "6"},
              {key: "7br", text: "Mercedes", value: "7"},
              {key: "8br", text: "Montecarlo", value: "8"},
              {key: "9br", text: "Navajuelar", value: "9"},
              {key: "10br", text: "Nubes", value: "10"},
              {key: "11br", text: "Paraíso", value: "11"},
              {key: "12br", text: "Pilar", value: "12"},
              {key: "13br", text: "Pueblo Nuevo", value: "13"},
              {key: "14br", text: "Quizarrá (parte)", value: "14"},
              {key: "15br", text: "Salitrales", value: "15"},
              {key: "16br", text: "San Francisco", value: "16"},
              {key: "17br", text: "San Ignacio", value: "17"},
              {key: "18br", text: "San Pedrito", value: "18"},
              {key: "19br", text: "Santa María", value: "19"},
              {key: "20br", text: "Santa Teresa", value: "20"}]},
          {key: "9ds", text: "Barú", value: "9", barrios: [
              {key: "1br", text: "Platanillo", value: "1"},
              {key: "2br", text: "Alfombra", value: "2"},
              {key: "3br", text: "Alto Perla", value: "3"},
              {key: "4br", text: "Bajos", value: "4"},
              {key: "5br", text: "Bajos De Zapotal", value: "5"},
              {key: "6br", text: "Barú", value: "6"},
              {key: "7br", text: "Barucito", value: "7"},
              {key: "8br", text: "Cacao", value: "8"},
              {key: "9br", text: "Camarones", value: "9"},
              {key: "10br", text: "Cañablanca", value: "10"},
              {key: "11br", text: "Ceiba", value: "11"},
              {key: "12br", text: "Chontales", value: "12"},
              {key: "13br", text: "Farallas", value: "13"},
              {key: "14br", text: "Florida", value: "14"},
              {key: "15br", text: "San Juan De Dios (guabo)", value: "15"},
              {key: "16br", text: "Líbano", value: "16"},
              {key: "17br", text: "Magnolia", value: "17"},
              {key: "18br", text: "Pozos", value: "18"},
              {key: "19br", text: "Reina", value: "19"},
              {key: "20br", text: "San Marcos", value: "20"},
              {key: "21br", text: "San Salvador", value: "21"},
              {key: "22br", text: "Santa Juana", value: "22"},
              {key: "23br", text: "Santo Cristo", value: "23"},
              {key: "24br", text: "Tinamaste (san Cristobal)", value: "24"},
              {key: "25br", text: "Torito", value: "25"},
              {key: "26br", text: "Tres Piedras (parte)", value: "26"},
              {key: "27br", text: "Tumbas", value: "27"},
              {key: "28br", text: "Villabonita Vista Mar", value: "28"}]},
          {key: "10ds", text: "Río Nuevo", value: "10", barrios: [
              {key: "1br", text: "Santa Rosa", value: "1"},
              {key: "2br", text: "Alto Mena", value: "2"},
              {key: "3br", text: "Brujo", value: "3"},
              {key: "4br", text: "Calle Los Mora", value: "4"},
              {key: "5br", text: "Chiricano", value: "5"},
              {key: "6br", text: "Gloria", value: "6"},
              {key: "7br", text: "Loma Guacal", value: "7"},
              {key: "8br", text: "Llano", value: "8"},
              {key: "9br", text: "Paraíso", value: "9"},
              {key: "10br", text: "Providencia (parte)", value: "10"},
              {key: "11br", text: "Purruja", value: "11"},
              {key: "12br", text: "Río Nuevo", value: "12"},
              {key: "13br", text: "San Antonio", value: "13"},
              {key: "14br", text: "Santa Marta", value: "14"},
              {key: "15br", text: "Savegre Abajo", value: "15"},
              {key: "16br", text: "Viento Fresco", value: "16"}]},
          {key: "11ds", text: "Páramo", value: "11", barrios: [
              {key: "1br", text: "San Rafael Sur", value: "1"},
              {key: "2br", text: "Alto Macho Mora", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Berlín", value: "4"},
              {key: "5br", text: "Chanchera", value: "5"},
              {key: "6br", text: "División (parte)", value: "6"},
              {key: "7br", text: "Fortuna", value: "7"},
              {key: "8br", text: "Hortensia", value: "8"},
              {key: "9br", text: "La Ese", value: "9"},
              {key: "10br", text: "La Piedra", value: "10"},
              {key: "11br", text: "Lira", value: "11"},
              {key: "12br", text: "Matasanos", value: "12"},
              {key: "13br", text: "Palma", value: "13"},
              {key: "14br", text: "Pedregosito", value: "14"},
              {key: "15br", text: "Providencia (parte)", value: "15"},
              {key: "16br", text: "San Ramón Norte", value: "16"},
              {key: "17br", text: "Santa Eduvigis", value: "17"},
              {key: "18br", text: "Santo Tomás", value: "18"},
              {key: "19br", text: "Siberia (parte)", value: "19"},
              {key: "20br", text: "Valencia", value: "20"}]},
          {key: "12ds", text: "La Amistad", value: "12", barrios: [
              {key: "1br", text: "San Antonio", value: "1"},
              {key: "2br", text: "Corralillo", value: "2"},
              {key: "3br", text: "China Kichá", value: "3"},
              {key: "4br", text: "Montezuma", value: "4"},
              {key: "5br", text: "Oratorio", value: "5"},
              {key: "6br", text: "San Carlos", value: "6"},
              {key: "7br", text: "San Gabriel", value: "7"},
              {key: "8br", text: "San Roque", value: "8"},
              {key: "9br", text: "Santa Cecilia", value: "9"},
              {key: "10br", text: "Santa Luisa", value: "10"}]}]},
      {key: "20cn", text: "León Cortés Castro", value: "20", distritos: [
          {key: "1ds", text: "San Pablo", value: "1", barrios: [
              {key: "1br", text: "San Pablo Centro", value: "1"},
              {key: "2br", text: "Estadio", value: "2"},
              {key: "3br", text: "La Clara", value: "3"},
              {key: "4br", text: "La Virgen", value: "4"},
              {key: "5br", text: "Los Ángeles", value: "5"},
              {key: "6br", text: "Sagrada Familia", value: "6"},
              {key: "7br", text: "Abejonal", value: "7"},
              {key: "8br", text: "Carrizales", value: "8"},
              {key: "9br", text: "Los Navarro", value: "9"},
              {key: "10br", text: "Montes De Oro", value: "10"},
              {key: "11br", text: "Rosario", value: "11"}]},
          {key: "2ds", text: "San Andrés", value: "2", barrios: [
              {key: "1br", text: "San Andrés Centro", value: "1"},
              {key: "2br", text: "Angostura (parte)", value: "2"},
              {key: "3br", text: "Bajo Gamboa", value: "3"},
              {key: "4br", text: "Higuerón", value: "4"},
              {key: "5br", text: "Llano Grande", value: "5"},
              {key: "6br", text: "Ojo De Agua (parte)", value: "6"},
              {key: "7br", text: "Rastrojales", value: "7"}]},
          {key: "3ds", text: "Llano Bonito", value: "3", barrios: [
              {key: "1br", text: "San Rafael Arriba", value: "1"},
              {key: "2br", text: "Bajo Mora", value: "2"},
              {key: "3br", text: "Bajo Venegas (parte)", value: "3"},
              {key: "4br", text: "Concepción", value: "4"},
              {key: "5br", text: "San Francisco", value: "5"},
              {key: "6br", text: "San Luis", value: "6"},
              {key: "7br", text: "San Rafael Abajo", value: "7"},
              {key: "8br", text: "Santa Juana", value: "8"},
              {key: "9br", text: "Santa Rosa (parte)", value: "9"}]},
          {key: "4ds", text: "San Isidro", value: "4", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Alto Carrizal", value: "2"},
              {key: "3br", text: "Loma De La Altura", value: "3"},
              {key: "4br", text: "Santa Rosa (parte)", value: "4"},
              {key: "5br", text: "Trinidad", value: "5"}]},
          {key: "5ds", text: "Santa Cruz", value: "5", barrios: [
              {key: "1br", text: "Santa Cruz Centro", value: "1"},
              {key: "2br", text: "Cedral (parte)", value: "2"},
              {key: "3br", text: "Lucha (parte)", value: "3"},
              {key: "4br", text: "San Martín", value: "4"},
              {key: "5br", text: "Rincón Gamboa", value: "5"}]},
          {key: "6ds", text: "San Antonio", value: "6", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Angostura (parte)", value: "2"},
              {key: "3br", text: "Cuesta", value: "3"}]}]}]},
  {key: "2pr", text: "Alajuela", value: "2", cantones: [
      {key: "1cn", text: "Alajuela", value: "1", distritos: [
          {key: "1ds", text: "Alajuela", value: "1", barrios: [
              {key: "1br", text: "Alajuela Centro", value: "1"},
              {key: "2br", text: "Acequia Grande (parte)", value: "2"},
              {key: "3br", text: "Agonía", value: "3"},
              {key: "4br", text: "Arroyo", value: "4"},
              {key: "5br", text: "Bajo Cornizal", value: "5"},
              {key: "6br", text: "Brasil (parte)", value: "6"},
              {key: "7br", text: "Cafetal", value: "7"},
              {key: "8br", text: "Canoas", value: "8"},
              {key: "9br", text: "Carmen", value: "9"},
              {key: "10br", text: "Cementerio", value: "10"},
              {key: "11br", text: "Concepción", value: "11"},
              {key: "12br", text: "Ciruelas", value: "12"},
              {key: "13br", text: "Corazón De Jesús", value: "13"},
              {key: "14br", text: "Cristo Rey", value: "14"},
              {key: "15br", text: "Gregorio José Ramírez", value: "15"},
              {key: "16br", text: "Guadalupe", value: "16"},
              {key: "17br", text: "Higuerones", value: "17"},
              {key: "18br", text: "Hospital", value: "18"},
              {key: "19br", text: "Llano", value: "19"},
              {key: "20br", text: "Llobet", value: "20"},
              {key: "21br", text: "Molinos", value: "21"},
              {key: "22br", text: "Montecillos (parte)", value: "22"},
              {key: "23br", text: "Montenegro", value: "23"},
              {key: "24br", text: "Monserrat (parte)", value: "24"},
              {key: "25br", text: "Paso Flores", value: "25"},
              {key: "26br", text: "Providencia", value: "26"},
              {key: "27br", text: "Retiro", value: "27"},
              {key: "28br", text: "San Luis", value: "28"},
              {key: "29br", text: "Tomás Guardia", value: "29"},
              {key: "30br", text: "Tropicana", value: "30"},
              {key: "31br", text: "Villabonita (parte)", value: "31"},
              {key: "32br", text: "Villahermosa", value: "32"}]},
          {key: "2ds", text: "San José", value: "2", barrios: [
              {key: "1br", text: "San José Centro", value: "1"},
              {key: "2br", text: "Amistad", value: "2"},
              {key: "3br", text: "Botánica", value: "3"},
              {key: "4br", text: "Copablanca", value: "4"},
              {key: "5br", text: "Flores", value: "5"},
              {key: "6br", text: "Jardines", value: "6"},
              {key: "7br", text: "Jocote", value: "7"},
              {key: "8br", text: "Juan Santamaría", value: "8"},
              {key: "9br", text: "Lagunilla", value: "9"},
              {key: "10br", text: "Mandarina (parte)", value: "10"},
              {key: "11br", text: "Maravilla (parte)", value: "11"},
              {key: "12br", text: "Montesol (parte)", value: "12"},
              {key: "13br", text: "Pueblo Nuevo", value: "13"},
              {key: "14br", text: "San Rafael", value: "14"},
              {key: "15br", text: "Santa Rita", value: "15"},
              {key: "16br", text: "Tigra", value: "16"},
              {key: "17br", text: "Torre", value: "17"},
              {key: "18br", text: "Trinidad", value: "18"},
              {key: "19br", text: "Tuetal Sur", value: "19"},
              {key: "20br", text: "Coyol", value: "20"},
              {key: "21br", text: "Flores", value: "21"}]},
          {key: "3ds", text: "Carrizal", value: "3", barrios: [
              {key: "1br", text: "Carrizal Centro", value: "1"},
              {key: "2br", text: "Alto Pavas", value: "2"},
              {key: "3br", text: "Bambú", value: "3"},
              {key: "4br", text: "Cinco Esquinas", value: "4"},
              {key: "5br", text: "Concordia", value: "5"},
              {key: "6br", text: "Domingas", value: "6"},
              {key: "7br", text: "El Plan", value: "7"}]},
          {key: "4ds", text: "San Antonio", value: "4", barrios: [
              {key: "1br", text: "San Antonio Del Tejar", value: "1"},
              {key: "2br", text: "Acequia Grande (parte)", value: "2"},
              {key: "3br", text: "Bajo Monge", value: "3"},
              {key: "4br", text: "Cañada", value: "4"},
              {key: "5br", text: "Montecillos (parte)", value: "5"},
              {key: "6br", text: "Monserrat (parte)", value: "6"},
              {key: "7br", text: "Puente Arena (parte)", value: "7"},
              {key: "8br", text: "Tejar", value: "8"},
              {key: "9br", text: "Vegas", value: "9"},
              {key: "10br", text: "Villabonita (parte)", value: "10"},
              {key: "11br", text: "Ciruelas", value: "11"},
              {key: "12br", text: "Roble", value: "12"},
              {key: "13br", text: "Sánchez", value: "13"}]},
          {key: "5ds", text: "Guácima", value: "5", barrios: [
              {key: "1br", text: "Guácima Centro", value: "1"},
              {key: "2br", text: "Bajo Tejar", value: "2"},
              {key: "3br", text: "Cacao", value: "3"},
              {key: "4br", text: "Cañada", value: "4"},
              {key: "5br", text: "Coco", value: "5"},
              {key: "6br", text: "El Bajo", value: "6"},
              {key: "7br", text: "Hacienda Los Reyes", value: "7"},
              {key: "8br", text: "Nuestro Amo", value: "8"},
              {key: "9br", text: "Rincón Chiquito", value: "9"},
              {key: "10br", text: "Rincón De Herrera", value: "10"},
              {key: "11br", text: "Rincón De Monge", value: "11"},
              {key: "12br", text: "Ventanas", value: "12"},
              {key: "13br", text: "Vueltas", value: "13"}]},
          {key: "6ds", text: "San Isidro", value: "6", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Aguilar", value: "2"},
              {key: "3br", text: "Ceiba", value: "3"},
              {key: "4br", text: "San Martín", value: "4"},
              {key: "5br", text: "Alto Pilas", value: "5"},
              {key: "6br", text: "Buríos", value: "6"},
              {key: "7br", text: "Carbonal", value: "7"},
              {key: "8br", text: "Cerrillal", value: "8"},
              {key: "9br", text: "Dulce Nombre", value: "9"},
              {key: "10br", text: "Espino (parte)", value: "10"},
              {key: "11br", text: "Itiquís", value: "11"},
              {key: "12br", text: "Laguna", value: "12"},
              {key: "13br", text: "Loría", value: "13"},
              {key: "14br", text: "Maravilla (parte)", value: "14"},
              {key: "15br", text: "Montaña", value: "15"},
              {key: "16br", text: "Pilas", value: "16"},
              {key: "17br", text: "Potrerillos", value: "17"},
              {key: "18br", text: "San Jerónimo", value: "18"},
              {key: "19br", text: "San Rafael", value: "19"},
              {key: "20br", text: "Tacacorí", value: "20"},
              {key: "21br", text: "Tuetal Norte (parte)", value: "21"},
              {key: "22br", text: "Villas De La Ceiba", value: "22"}]},
          {key: "7ds", text: "Sabanilla", value: "7", barrios: [
              {key: "1br", text: "Sabanilla Centro", value: "1"},
              {key: "2br", text: "Alto Del Desengaño", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Bajo Santa Bárbara", value: "4"},
              {key: "5br", text: "Cerro", value: "5"},
              {key: "6br", text: "Doka", value: "6"},
              {key: "7br", text: "Espino (parte)", value: "7"},
              {key: "8br", text: "Fraijanes", value: "8"},
              {key: "9br", text: "Lajas", value: "9"},
              {key: "10br", text: "Poasito", value: "10"},
              {key: "11br", text: "San Luis", value: "11"},
              {key: "12br", text: "San Rafael", value: "12"},
              {key: "13br", text: "Vargas (parte)", value: "13"}]},
          {key: "8ds", text: "San Rafael", value: "8", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "La Paz", value: "2"},
              {key: "3br", text: "Perla", value: "3"},
              {key: "4br", text: "Cañada", value: "4"},
              {key: "5br", text: "Ojo De Agua", value: "5"},
              {key: "6br", text: "Paires", value: "6"},
              {key: "7br", text: "Potrerillos", value: "7"},
              {key: "8br", text: "Rincón Venegas", value: "8"}]},
          {key: "9ds", text: "Río Segundo", value: "9", barrios: [
              {key: "1br", text: "Río Segundo Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Bajo Cañas (parte)", value: "3"},
              {key: "4br", text: "Bajo La Sorda", value: "4"},
              {key: "5br", text: "Cacique", value: "5"},
              {key: "6br", text: "California", value: "6"},
              {key: "7br", text: "Cañas (parte)", value: "7"},
              {key: "8br", text: "Guayabo", value: "8"},
              {key: "9br", text: "Monserrat (parte)", value: "9"},
              {key: "10br", text: "Puente Arena (parte)", value: "10"},
              {key: "11br", text: "Villalobos", value: "11"},
              {key: "12br", text: "Víquez", value: "12"}]},
          {key: "10ds", text: "Desamparados", value: "10", barrios: [
              {key: "1br", text: "Desamparados Centro", value: "1"},
              {key: "2br", text: "Bajo Cañas (parte)", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Brasil (parte)", value: "4"},
              {key: "5br", text: "Calicanto", value: "5"},
              {key: "6br", text: "Cañas (parte)", value: "6"},
              {key: "7br", text: "Erizo", value: "7"},
              {key: "8br", text: "Mojón", value: "8"},
              {key: "9br", text: "Pasito", value: "9"},
              {key: "10br", text: "Rincón", value: "10"},
              {key: "11br", text: "Rosales (parte)", value: "11"},
              {key: "12br", text: "Targuases", value: "12"},
              {key: "13br", text: "Tres Piedras", value: "13"}]},
          {key: "11ds", text: "Turrúcares", value: "11", barrios: [
              {key: "1br", text: "Turrúcares Centro", value: "1"},
              {key: "2br", text: "Bajo Pita", value: "2"},
              {key: "3br", text: "Granja", value: "3"},
              {key: "4br", text: "Morera", value: "4"},
              {key: "5br", text: "San Martín", value: "5"},
              {key: "6br", text: "Santa Rita", value: "6"},
              {key: "7br", text: "Turrucareña", value: "7"},
              {key: "8br", text: "Villacares", value: "8"},
              {key: "9br", text: "Bajo San Miguel", value: "9"},
              {key: "10br", text: "Candelaria", value: "10"},
              {key: "11br", text: "Carrera Buena", value: "11"},
              {key: "12br", text: "Cebadilla", value: "12"},
              {key: "13br", text: "Cerrillos (san Miguel)", value: "13"},
              {key: "14br", text: "Conejo", value: "14"},
              {key: "15br", text: "Juntas", value: "15"},
              {key: "16br", text: "Siquiares", value: "16"},
              {key: "17br", text: "Tamarindo", value: "17"}]},
          {key: "12ds", text: "Tambor", value: "12", barrios: [
              {key: "1br", text: "Tambor Centro", value: "1"},
              {key: "2br", text: "Cacao", value: "2"},
              {key: "3br", text: "Calle Liles", value: "3"},
              {key: "4br", text: "González", value: "4"},
              {key: "5br", text: "Quebradas", value: "5"},
              {key: "6br", text: "Rincón Cacao", value: "6"},
              {key: "7br", text: "Tuetal Norte (parte)", value: "7"},
              {key: "8br", text: "Vargas (parte)", value: "8"}]},
          {key: "13ds", text: "Garita", value: "13", barrios: [
              {key: "1br", text: "Garita Centro", value: "1"},
              {key: "2br", text: "Animas (dulce Nombre)", value: "2"},
              {key: "3br", text: "Cuesta Colorada", value: "3"},
              {key: "4br", text: "Copeyal", value: "4"},
              {key: "5br", text: "Horcones", value: "5"},
              {key: "6br", text: "Lagos Del Coyol", value: "6"},
              {key: "7br", text: "Llanos", value: "7"},
              {key: "8br", text: "Mandarina (parte)", value: "8"},
              {key: "9br", text: "Manolos", value: "9"},
              {key: "10br", text: "Mina", value: "10"},
              {key: "11br", text: "Montesol (parte)", value: "11"},
              {key: "12br", text: "Monticel", value: "12"},
              {key: "13br", text: "Saltillo", value: "13"}]},
          {key: "14ds", text: "Sarapiquí", value: "14", barrios: [
              {key: "1br", text: "San Miguel", value: "1"},
              {key: "2br", text: "Bajo Latas", value: "2"},
              {key: "3br", text: "Cariblanco", value: "3"},
              {key: "4br", text: "Corazón De Jesús", value: "4"},
              {key: "5br", text: "Isla Bonita", value: "5"},
              {key: "6br", text: "Nueva Cinchona", value: "6"},
              {key: "7br", text: "San Antonio", value: "7"},
              {key: "8br", text: "Paraíso", value: "8"},
              {key: "9br", text: "Punta Mala", value: "9"},
              {key: "10br", text: "Ujarrás", value: "10"},
              {key: "11br", text: "Virgen Del Socorro (parte)", value: "11"}]}]},
      {key: "2cn", text: "San Ramón", value: "2", distritos: [
          {key: "1ds", text: "San Ramón", value: "1", barrios: [
              {key: "1br", text: "San Ramón Centro", value: "1"},
              {key: "2br", text: "Bajo Ladrillera", value: "2"},
              {key: "3br", text: "Cachera", value: "3"},
              {key: "4br", text: "Lisímaco Chavarría", value: "4"},
              {key: "5br", text: "Sabana (parte)", value: "5"},
              {key: "6br", text: "San José", value: "6"},
              {key: "7br", text: "Tremedal (parte)", value: "7"}]},
          {key: "2ds", text: "Santiago", value: "2", barrios: [
              {key: "1br", text: "Santiago Centro", value: "1"},
              {key: "2br", text: "Arias", value: "2"},
              {key: "3br", text: "Montserrat", value: "3"},
              {key: "4br", text: "Alto Santiago", value: "4"},
              {key: "5br", text: "Alto Salas", value: "5"},
              {key: "6br", text: "Angostura", value: "6"},
              {key: "7br", text: "Balboa", value: "7"},
              {key: "8br", text: "Cambronero", value: "8"},
              {key: "9br", text: "Constancia", value: "9"},
              {key: "10br", text: "Cuesta Del Toro", value: "10"},
              {key: "11br", text: "Empalme", value: "11"},
              {key: "12br", text: "La Ese", value: "12"},
              {key: "13br", text: "León", value: "13"},
              {key: "14br", text: "Magallanes", value: "14"},
              {key: "15br", text: "Moncada", value: "15"},
              {key: "16br", text: "Río Jesús", value: "16"}]},
          {key: "3ds", text: "San Juan", value: "3", barrios: [
              {key: "1br", text: "San Juan Centro", value: "1"},
              {key: "2br", text: "Americana", value: "2"},
              {key: "3br", text: "Bajo Tajos", value: "3"},
              {key: "4br", text: "Belén", value: "4"},
              {key: "5br", text: "Cipreses", value: "5"},
              {key: "6br", text: "Lirios", value: "6"},
              {key: "7br", text: "Llamarón", value: "7"},
              {key: "8br", text: "Pueblo Nuevo", value: "8"},
              {key: "9br", text: "Tanque", value: "9"},
              {key: "10br", text: "Tejar", value: "10"},
              {key: "11br", text: "Vicente Badilla", value: "11"},
              {key: "12br", text: "Juntas (parte)", value: "12"}]},
          {key: "4ds", text: "Piedades Norte", value: "4", barrios: [
              {key: "1br", text: "Piedades Norte Centro", value: "1"},
              {key: "2br", text: "Copán", value: "2"},
              {key: "3br", text: "Araya", value: "3"},
              {key: "4br", text: "Bajo Matamoros (parte)", value: "4"},
              {key: "5br", text: "Bolívar", value: "5"},
              {key: "6br", text: "Campos", value: "6"},
              {key: "7br", text: "Esperanza", value: "7"},
              {key: "8br", text: "La Paz", value: "8"},
              {key: "9br", text: "Lomas", value: "9"},
              {key: "10br", text: "Piedades Noroeste", value: "10"}]},
          {key: "5ds", text: "Piedades Sur", value: "5", barrios: [
              {key: "1br", text: "Piedades Sur Centro", value: "1"},
              {key: "2br", text: "Bajo Barranca", value: "2"},
              {key: "3br", text: "Barranca", value: "3"},
              {key: "4br", text: "Bureal", value: "4"},
              {key: "5br", text: "Carmen", value: "5"},
              {key: "6br", text: "Chassoul", value: "6"},
              {key: "7br", text: "Guaria", value: "7"},
              {key: "8br", text: "Nagatac", value: "8"},
              {key: "9br", text: "Palma", value: "9"},
              {key: "10br", text: "Potrerillos", value: "10"},
              {key: "11br", text: "Quebradillas", value: "11"},
              {key: "12br", text: "Salvador", value: "12"},
              {key: "13br", text: "San Bosco", value: "13"},
              {key: "14br", text: "San Francisco", value: "14"},
              {key: "15br", text: "San Miguel", value: "15"},
              {key: "16br", text: "Sardinal", value: "16"},
              {key: "17br", text: "Socorro", value: "17"}]},
          {key: "6ds", text: "San Rafael", value: "6", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Tres Marías", value: "2"},
              {key: "3br", text: "Unión", value: "3"},
              {key: "4br", text: "Alto Llano", value: "4"},
              {key: "5br", text: "Berlín", value: "5"},
              {key: "6br", text: "Calera", value: "6"},
              {key: "7br", text: "Chavarría", value: "7"},
              {key: "8br", text: "Llano Brenes", value: "8"},
              {key: "9br", text: "Orlich", value: "9"},
              {key: "10br", text: "Orozco", value: "10"},
              {key: "11br", text: "Pata De Gallo", value: "11"},
              {key: "12br", text: "Rincón De Mora", value: "12"},
              {key: "13br", text: "Rincón Orozco", value: "13"},
              {key: "14br", text: "San Joaquín", value: "14"},
              {key: "15br", text: "Zamora", value: "15"}]},
          {key: "7ds", text: "San Isidro", value: "7", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Progreso", value: "2"},
              {key: "3br", text: "Bajo Ramírez", value: "3"},
              {key: "4br", text: "Fernández", value: "4"},
              {key: "5br", text: "Guaria", value: "5"},
              {key: "6br", text: "Varela", value: "6"}]},
          {key: "8ds", text: "Ángeles", value: "8", barrios: [
              {key: "1br", text: "Ángeles Sur", value: "1"},
              {key: "2br", text: "Los Jardines", value: "2"},
              {key: "3br", text: "Ranchera", value: "3"},
              {key: "4br", text: "Ángeles Norte", value: "4"},
              {key: "5br", text: "Balsa", value: "5"},
              {key: "6br", text: "Juntas (parte)", value: "6"},
              {key: "7br", text: "Silencio", value: "7"},
              {key: "8br", text: "Valle Azul", value: "8"},
              {key: "9br", text: "Zúñiga", value: "9"}]},
          {key: "9ds", text: "Alfaro", value: "9", barrios: [
              {key: "1br", text: "San Pedro", value: "1"},
              {key: "2br", text: "Badilla Alpizar", value: "2"},
              {key: "3br", text: "Sabana (parte)", value: "3"},
              {key: "4br", text: "Tremedal (parte)", value: "4"},
              {key: "5br", text: "Bajo Matamoros (parte)", value: "5"},
              {key: "6br", text: "Catarata", value: "6"},
              {key: "7br", text: "Valverde", value: "7"}]},
          {key: "10ds", text: "Volio", value: "10", barrios: [
              {key: "1br", text: "Volio Centro", value: "1"},
              {key: "2br", text: "Alto Villegas", value: "2"},
              {key: "3br", text: "Bajo Tejares", value: "3"},
              {key: "4br", text: "Dulce Nombre", value: "4"},
              {key: "5br", text: "Sifón", value: "5"}]},
          {key: "11ds", text: "Concepción", value: "11", barrios: [
              {key: "1br", text: "Concepción Centro", value: "1"},
              {key: "2br", text: "Cañuela", value: "2"},
              {key: "3br", text: "Concepción Arriba", value: "3"},
              {key: "4br", text: "Chaparral", value: "4"},
              {key: "5br", text: "Pérez", value: "5"}]},
          {key: "12ds", text: "Zapotal", value: "12", barrios: [
              {key: "1br", text: "San Antonio", value: "1"},
              {key: "2br", text: "Bajo Castillo", value: "2"},
              {key: "3br", text: "Bajos", value: "3"},
              {key: "4br", text: "Barranquilla", value: "4"},
              {key: "5br", text: "Carrera Buena", value: "5"},
              {key: "6br", text: "Jabonal", value: "6"},
              {key: "7br", text: "Jabonalito", value: "7"},
              {key: "8br", text: "Rincón Chaves", value: "8"},
              {key: "9br", text: "Victoria", value: "9"},
              {key: "10br", text: "Zapotal (parte)", value: "10"}]},
          {key: "13ds", text: "Peñas Blancas", value: "13", barrios: [
              {key: "1br", text: "San Isidro", value: "1"},
              {key: "2br", text: "Abanico", value: "2"},
              {key: "3br", text: "Altura", value: "3"},
              {key: "4br", text: "Bosque", value: "4"},
              {key: "5br", text: "Burrito", value: "5"},
              {key: "6br", text: "Cairo", value: "6"},
              {key: "7br", text: "Castillo (parte)", value: "7"},
              {key: "8br", text: "Castillo Nuevo", value: "8"},
              {key: "9br", text: "Colonia Trinidad", value: "9"},
              {key: "10br", text: "Chachagua", value: "10"},
              {key: "11br", text: "La Cruz", value: "11"},
              {key: "12br", text: "Pocosol", value: "12"},
              {key: "13br", text: "San Carlos", value: "13"},
              {key: "14br", text: "San Rafael", value: "14"},
              {key: "15br", text: "Sector Ángeles", value: "15"}]},
          {key: "14ds", text: "San Lorenzo", value: "14", barrios: [
              {key: "1br", text: "Valle Azul", value: "1"},
              {key: "2br", text: "Bajo Córdoba", value: "2"},
              {key: "3br", text: "Bajo Rodríguez", value: "3"},
              {key: "4br", text: "Cataratas", value: "4"},
              {key: "5br", text: "Colonia Palmareña", value: "5"},
              {key: "6br", text: "Coopezamora", value: "6"},
              {key: "7br", text: "Criques", value: "7"},
              {key: "8br", text: "Kooper", value: "8"},
              {key: "9br", text: "Los Lagos", value: "9"},
              {key: "10br", text: "Las Rocas", value: "10"},
              {key: "11br", text: "San Jorge", value: "11"}]}]},
      {key: "3cn", text: "Grecia", value: "3", distritos: [
          {key: "1ds", text: "Grecia", value: "1", barrios: [
              {key: "1br", text: "Grecia Centro", value: "1"},
              {key: "2br", text: "Carmona", value: "2"},
              {key: "3br", text: "Chavarría", value: "3"},
              {key: "4br", text: "Colón", value: "4"},
              {key: "5br", text: "Jiménez", value: "5"},
              {key: "6br", text: "Pinos", value: "6"},
              {key: "7br", text: "Rincón De Arias", value: "7"},
              {key: "8br", text: "San Antonio", value: "8"},
              {key: "9br", text: "San Vicente", value: "9"},
              {key: "10br", text: "Celina", value: "10"}]},
          {key: "2ds", text: "San Isidro", value: "2", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Barrio (grecia)(primavera)", value: "2"},
              {key: "3br", text: "Alfaro", value: "3"},
              {key: "4br", text: "Bajo Achiote", value: "4"},
              {key: "5br", text: "Camejo", value: "5"},
              {key: "6br", text: "Coopevictoria", value: "6"},
              {key: "7br", text: "Corinto", value: "7"},
              {key: "8br", text: "Higuerones", value: "8"},
              {key: "9br", text: "Mesón", value: "9"},
              {key: "10br", text: "Mojón", value: "10"},
              {key: "11br", text: "Quizarrazal", value: "11"}]},
          {key: "3ds", text: "San José", value: "3", barrios: [
              {key: "1br", text: "San José Centro", value: "1"},
              {key: "2br", text: "Arena", value: "2"},
              {key: "3br", text: "Cedro", value: "3"},
              {key: "4br", text: "Delicias (parte)", value: "4"},
              {key: "5br", text: "Guayabal (parte)", value: "5"},
              {key: "6br", text: "Loma", value: "6"},
              {key: "7br", text: "Rodríguez", value: "7"},
              {key: "8br", text: "Santa Gertrudis Norte", value: "8"},
              {key: "9br", text: "Santa Gertrudis Sur", value: "9"}]},
          {key: "4ds", text: "San Roque", value: "4", barrios: [
              {key: "1br", text: "San Roque Centro", value: "1"},
              {key: "2br", text: "Agualote", value: "2"},
              {key: "3br", text: "Bajo Sapera", value: "3"},
              {key: "4br", text: "Casillas", value: "4"},
              {key: "5br", text: "Latino", value: "5"},
              {key: "6br", text: "San Miguel Arriba", value: "6"},
              {key: "7br", text: "Cabuyal", value: "7"},
              {key: "8br", text: "Carbonal", value: "8"},
              {key: "9br", text: "Coyotera", value: "9"},
              {key: "10br", text: "San Miguel", value: "10"}]},
          {key: "5ds", text: "Tacares", value: "5", barrios: [
              {key: "1br", text: "Tacares Centro", value: "1"},
              {key: "2br", text: "Pinto", value: "2"},
              {key: "3br", text: "Bodegas", value: "3"},
              {key: "4br", text: "Cataluña", value: "4"},
              {key: "5br", text: "Cerdas", value: "5"},
              {key: "6br", text: "Delicias (parte)", value: "6"},
              {key: "7br", text: "Flores", value: "7"},
              {key: "8br", text: "Guayabal (parte)", value: "8"},
              {key: "9br", text: "Pilas", value: "9"},
              {key: "10br", text: "Planta", value: "10"},
              {key: "11br", text: "Porvenir", value: "11"},
              {key: "12br", text: "Yoses", value: "12"}]},
          {key: "6ds", text: "Puente De Piedra", value: "6", barrios: [
              {key: "1br", text: "Puente De Piedra Centro", value: "1"},
              {key: "2br", text: "Poró", value: "2"},
              {key: "3br", text: "Sevilla", value: "3"},
              {key: "4br", text: "Altos De Peralta", value: "4"},
              {key: "5br", text: "Argentina", value: "5"},
              {key: "6br", text: "Bajo Cedros", value: "6"},
              {key: "7br", text: "Lomas", value: "7"},
              {key: "8br", text: "Montezuma", value: "8"},
              {key: "9br", text: "Puerto Escondido", value: "9"},
              {key: "10br", text: "Raiceros", value: "10"},
              {key: "11br", text: "Rincón De Salas", value: "11"},
              {key: "12br", text: "Rosales", value: "12"}]},
          {key: "7ds", text: "Bolívar", value: "7", barrios: [
              {key: "1br", text: "Ángeles", value: "1"},
              {key: "2br", text: "Cajón", value: "2"},
              {key: "3br", text: "Cocobolo", value: "3"},
              {key: "4br", text: "Murillo", value: "4"},
              {key: "5br", text: "San Juan", value: "5"},
              {key: "6br", text: "San Luis", value: "6"},
              {key: "7br", text: "Virgencita", value: "7"}]}]},
      {key: "4cn", text: "San Mateo", value: "4", distritos: [
          {key: "1ds", text: "San Mateo", value: "1", barrios: [
              {key: "1br", text: "San Mateo Centro", value: "1"},
              {key: "2br", text: "Agua Agria", value: "2"},
              {key: "3br", text: "Calera", value: "3"},
              {key: "4br", text: "Cenízaro", value: "4"},
              {key: "5br", text: "Centeno", value: "5"},
              {key: "6br", text: "Desamparados", value: "6"},
              {key: "7br", text: "Dulce Nombre", value: "7"},
              {key: "8br", text: "Higuito", value: "8"},
              {key: "9br", text: "Izarco", value: "9"},
              {key: "10br", text: "Maderal", value: "10"},
              {key: "11br", text: "Ramadas", value: "11"},
              {key: "12br", text: "San Juan De Dios", value: "12"}]},
          {key: "2ds", text: "Desmonte", value: "2", barrios: [
              {key: "1br", text: "Desmonte Centro", value: "1"},
              {key: "2br", text: "Cuesta Colorada", value: "2"},
              {key: "3br", text: "Libertad", value: "3"},
              {key: "4br", text: "Quebrada Honda", value: "4"},
              {key: "5br", text: "Limón", value: "5"},
              {key: "6br", text: "Patio De Agua Norte", value: "6"},
              {key: "7br", text: "Sacra Familia", value: "7"},
              {key: "8br", text: "San Juan Uno", value: "8"},
              {key: "9br", text: "Zapote", value: "9"}]},
          {key: "3ds", text: "Jesús María", value: "3", barrios: [
              {key: "1br", text: "Jesús María Centro", value: "1"},
              {key: "2br", text: "Garabito", value: "2"},
              {key: "3br", text: "Quebrada Grande (parte)", value: "3"},
              {key: "4br", text: "Quinta", value: "4"}]},
          {key: "4ds", text: "Labrador", value: "4", barrios: [
              {key: "1br", text: "Labrador Centro", value: "1"},
              {key: "2br", text: "Altamira", value: "2"},
              {key: "3br", text: "Oricuajo", value: "3"},
              {key: "4br", text: "Poza Redonda", value: "4"},
              {key: "5br", text: "Quebrada Grande (parte)", value: "5"}]}]},
      {key: "5cn", text: "Atenas", value: "5", distritos: [
          {key: "1ds", text: "Atenas", value: "1", barrios: [
              {key: "1br", text: "Atenas Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Atenea", value: "3"},
              {key: "4br", text: "Boquerón", value: "4"},
              {key: "5br", text: "Escorpio", value: "5"},
              {key: "6br", text: "Güizaro", value: "6"},
              {key: "7br", text: "Oásis", value: "7"},
              {key: "8br", text: "Olivo", value: "8"},
              {key: "9br", text: "Matías", value: "9"}]},
          {key: "2ds", text: "Jesús", value: "2", barrios: [
              {key: "1br", text: "Jesús Centro", value: "1"},
              {key: "2br", text: "Guacalillo", value: "2"},
              {key: "3br", text: "Sabanalarga", value: "3"},
              {key: "4br", text: "San Vicente", value: "4"},
              {key: "5br", text: "Barroeta", value: "5"},
              {key: "6br", text: "Boca Del Monte", value: "6"},
              {key: "7br", text: "Cuajiniquil", value: "7"},
              {key: "8br", text: "Estanquillo", value: "8"},
              {key: "9br", text: "Pato De Agua (parte)", value: "9"}]},
          {key: "3ds", text: "Mercedes", value: "3", barrios: [
              {key: "1br", text: "Mercedes Centro", value: "1"},
              {key: "2br", text: "Cajón", value: "2"},
              {key: "3br", text: "Callao", value: "3"},
              {key: "4br", text: "Plancillo", value: "4"},
              {key: "5br", text: "Plazoleta", value: "5"}]},
          {key: "4ds", text: "San Isidro", value: "4", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Alto Naranjo", value: "2"},
              {key: "3br", text: "Bajo Cacao", value: "3"},
              {key: "4br", text: "Morazán", value: "4"},
              {key: "5br", text: "Pato De Agua (parte)", value: "5"},
              {key: "6br", text: "Pavas", value: "6"},
              {key: "7br", text: "Rincón", value: "7"}]},
          {key: "5ds", text: "Concepcion", value: "5", barrios: [
              {key: "1br", text: "Concepcion Centro", value: "1"},
              {key: "2br", text: "Río Grande", value: "2"},
              {key: "3br", text: "Balsa", value: "3"},
              {key: "4br", text: "Calle Garita", value: "4"},
              {key: "5br", text: "Coyoles", value: "5"},
              {key: "6br", text: "Pan De Azúcar", value: "6"},
              {key: "7br", text: "Tornos", value: "7"}]},
          {key: "6ds", text: "San José", value: "6", barrios: [
              {key: "1br", text: "San José Sur", value: "1"},
              {key: "2br", text: "Alto Cima", value: "2"},
              {key: "3br", text: "Alto López", value: "3"},
              {key: "4br", text: "Legua", value: "4"},
              {key: "5br", text: "San José Norte", value: "5"},
              {key: "6br", text: "Torunes", value: "6"},
              {key: "7br", text: "Vainilla", value: "7"}]},
          {key: "7ds", text: "Santa Eulalia", value: "7", barrios: [
              {key: "1br", text: "Santa Eulalia Centro", value: "1"},
              {key: "2br", text: "Rincón Rodríguez", value: "2"}]},
          {key: "8ds", text: "Escobal", value: "8", barrios: [
              {key: "1br", text: "Escobal Centro", value: "1"},
              {key: "2br", text: "Cerrillo", value: "2"},
              {key: "3br", text: "Guácimos", value: "3"},
              {key: "4br", text: "Kilómetro 51", value: "4"},
              {key: "5br", text: "Lapas", value: "5"},
              {key: "6br", text: "Mangos", value: "6"},
              {key: "7br", text: "Quebradas", value: "7"},
              {key: "8br", text: "Vuelta Herrera", value: "8"}]}]},
      {key: "6cn", text: "Naranjo", value: "6", distritos: [
          {key: "1ds", text: "Naranjo", value: "1", barrios: [
              {key: "1br", text: "Naranjo Centro", value: "1"},
              {key: "2br", text: "Bajo Pilas", value: "2"},
              {key: "3br", text: "Belén", value: "3"},
              {key: "4br", text: "Caña Dura", value: "4"},
              {key: "5br", text: "Gradas", value: "5"},
              {key: "6br", text: "María Auxiliadora", value: "6"},
              {key: "7br", text: "Muro", value: "7"},
              {key: "8br", text: "Pueblo Nuevo", value: "8"},
              {key: "9br", text: "Sacramento", value: "9"},
              {key: "10br", text: "San Lucas (carmen)", value: "10"},
              {key: "11br", text: "San Rafael", value: "11"},
              {key: "12br", text: "Candelaria", value: "12"},
              {key: "13br", text: "Cantarrana (parte)", value: "13"},
              {key: "14br", text: "Cinco Esquinas (parte)", value: "14"},
              {key: "15br", text: "Ciprés", value: "15"},
              {key: "16br", text: "Común", value: "16"},
              {key: "17br", text: "Dulce Nombre", value: "17"}]},
          {key: "2ds", text: "San Miguel", value: "2", barrios: [
              {key: "1br", text: "San Miguel Centro", value: "1"},
              {key: "2br", text: "Planes", value: "2"},
              {key: "3br", text: "Bajo Seevers", value: "3"},
              {key: "4br", text: "Bajos", value: "4"},
              {key: "5br", text: "Palmas", value: "5"},
              {key: "6br", text: "Quesera", value: "6"},
              {key: "7br", text: "San Francisco", value: "7"},
              {key: "8br", text: "San Miguel Oeste", value: "8"}]},
          {key: "3ds", text: "San José", value: "3", barrios: [
              {key: "1br", text: "San Juanillo", value: "1"},
              {key: "2br", text: "Barranca", value: "2"},
              {key: "3br", text: "Cañuela Arriba", value: "3"},
              {key: "4br", text: "Cuesta Venada", value: "4"},
              {key: "5br", text: "Desamparados", value: "5"},
              {key: "6br", text: "Isla", value: "6"},
              {key: "7br", text: "San Antonio De Barranca", value: "7"},
              {key: "8br", text: "San Pedro", value: "8"},
              {key: "9br", text: "Solís (parte)", value: "9"},
              {key: "10br", text: "Vuelta San Gerardo", value: "10"}]},
          {key: "4ds", text: "Cirrí Sur", value: "4", barrios: [
              {key: "1br", text: "Cirrí Sur Centro", value: "1"},
              {key: "2br", text: "Bajo Arrieta", value: "2"},
              {key: "3br", text: "Bajo Valverde", value: "3"},
              {key: "4br", text: "Bajo Zúñiga", value: "4"},
              {key: "5br", text: "Cruce", value: "5"},
              {key: "6br", text: "Isla Cocora", value: "6"},
              {key: "7br", text: "Lourdes", value: "7"},
              {key: "8br", text: "Llano Bonito", value: "8"},
              {key: "9br", text: "Llano Bonito Sur", value: "9"},
              {key: "10br", text: "Palmita", value: "10"},
              {key: "11br", text: "Quebrada Honda", value: "11"},
              {key: "12br", text: "Rincón", value: "12"},
              {key: "13br", text: "Solís (parte)", value: "13"},
              {key: "14br", text: "Zapote", value: "14"}]},
          {key: "5ds", text: "San Jerónimo", value: "5", barrios: [
              {key: "1br", text: "San Jerónimo Centro", value: "1"},
              {key: "2br", text: "Puebla", value: "2"},
              {key: "3br", text: "Robles", value: "3"},
              {key: "4br", text: "Tacacal", value: "4"}]},
          {key: "6ds", text: "San Juan", value: "6", barrios: [
              {key: "1br", text: "San Juan Centro", value: "1"},
              {key: "2br", text: "Bajo Murciélago", value: "2"},
              {key: "3br", text: "Cueva", value: "3"},
              {key: "4br", text: "Guarumal", value: "4"},
              {key: "5br", text: "Rincón Elizondo", value: "5"},
              {key: "6br", text: "San Antonio", value: "6"},
              {key: "7br", text: "Yoses", value: "7"}]},
          {key: "7ds", text: "El Rosario", value: "7", barrios: [
              {key: "1br", text: "El Rosario Centro", value: "1"},
              {key: "2br", text: "Pérez", value: "2"},
              {key: "3br", text: "Hornos", value: "3"},
              {key: "4br", text: "Llano", value: "4"},
              {key: "5br", text: "Santa Margarita", value: "5"},
              {key: "6br", text: "Vistas Del Valle", value: "6"}]},
          {key: "8ds", text: "Palmitos", value: "8", barrios: [
              {key: "1br", text: "Palmitos Centro", value: "1"},
              {key: "2br", text: "Alto Murillo", value: "2"},
              {key: "3br", text: "Alto Palmas", value: "3"},
              {key: "4br", text: "Cantarrana (parte)", value: "4"},
              {key: "5br", text: "Cinco Esquinas (parte)", value: "5"},
              {key: "6br", text: "Concepción", value: "6"},
              {key: "7br", text: "Roquillo", value: "7"},
              {key: "8br", text: "San Roque", value: "8"}]}]},
      {key: "7cn", text: "Palmares", value: "7", distritos: [
          {key: "1ds", text: "Palmares", value: "1", barrios: [
              {key: "1br", text: "Palmares Centro", value: "1"},
              {key: "2br", text: "Santa Fe", value: "2"},
              {key: "3br", text: "San Vicente", value: "3"}]},
          {key: "2ds", text: "Zaragoza", value: "2", barrios: [
              {key: "1br", text: "Zaragoza Centro", value: "1"},
              {key: "2br", text: "Cocaleca (parte)", value: "2"},
              {key: "3br", text: "Quebrada", value: "3"},
              {key: "4br", text: "Rincón (quebrada)", value: "4"},
              {key: "5br", text: "Rincón De Zaragoza", value: "5"},
              {key: "6br", text: "Vargas", value: "6"},
              {key: "7br", text: "Vásquez", value: "7"}]},
          {key: "3ds", text: "Buenos Aires", value: "3", barrios: [
              {key: "1br", text: "Buenos Aires Centro", value: "1"},
              {key: "2br", text: "Bajo Cabra", value: "2"},
              {key: "3br", text: "Barreal", value: "3"},
              {key: "4br", text: "Tres Marías", value: "4"},
              {key: "5br", text: "Valle", value: "5"},
              {key: "6br", text: "Victoria", value: "6"},
              {key: "7br", text: "Calle Ramírez", value: "7"}]},
          {key: "4ds", text: "Santiago", value: "4", barrios: [
              {key: "1br", text: "Santiago Centro", value: "1"},
              {key: "2br", text: "Pinos (parte)", value: "2"}]},
          {key: "5ds", text: "Candelaria", value: "5", barrios: [
              {key: "1br", text: "Candelaria Centro", value: "1"},
              {key: "2br", text: "Pinos (parte)", value: "2"}]},
          {key: "6ds", text: "Esquipulas", value: "6", barrios: [
              {key: "1br", text: "Esquipulas Centro", value: "1"},
              {key: "2br", text: "Calle Roble", value: "2"},
              {key: "3br", text: "Cocaleca (parte)", value: "3"},
              {key: "4br", text: "Peraza", value: "4"},
              {key: "5br", text: "Rincón De Salas", value: "5"}]},
          {key: "7ds", text: "La Granja", value: "7", barrios: [
              {key: "1br", text: "La Granja Centro", value: "1"},
              {key: "2br", text: "Amistad", value: "2"}]}]},
      {key: "8cn", text: "Poás", value: "8", distritos: [
          {key: "1ds", text: "San Pedro", value: "1", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Santa Cecilia (bajo Piedras)", value: "2"},
              {key: "3br", text: "Rastro", value: "3"},
              {key: "4br", text: "Cerro", value: "4"},
              {key: "5br", text: "Chilamate", value: "5"},
              {key: "6br", text: "Hilda", value: "6"},
              {key: "7br", text: "Sitio (parte)", value: "7"},
              {key: "8br", text: "Zamora", value: "8"}]},
          {key: "2ds", text: "San Juan", value: "2", barrios: [
              {key: "1br", text: "San Juan Sur", value: "1"},
              {key: "2br", text: "Altura (parte)", value: "2"},
              {key: "3br", text: "Corazón De Jesús", value: "3"},
              {key: "4br", text: "Guapinol", value: "4"},
              {key: "5br", text: "Mastate", value: "5"},
              {key: "6br", text: "San Juan Norte", value: "6"},
              {key: "7br", text: "Tablones (parte)", value: "7"}]},
          {key: "3ds", text: "San Rafael", value: "3", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Bajo Zamora", value: "2"},
              {key: "3br", text: "Churuca", value: "3"},
              {key: "4br", text: "Guatuza", value: "4"},
              {key: "5br", text: "Monte", value: "5"},
              {key: "6br", text: "Potrero Chiquito", value: "6"},
              {key: "7br", text: "Santa Rosa", value: "7"},
              {key: "8br", text: "Sitio (parte)", value: "8"},
              {key: "9br", text: "Solís", value: "9"},
              {key: "10br", text: "Tablones (parte)", value: "10"},
              {key: "11br", text: "Volcán", value: "11"}]},
          {key: "4ds", text: "Carrillos", value: "4", barrios: [
              {key: "1br", text: "Carrillos Bajo", value: "1"},
              {key: "2br", text: "Bajo Barahona", value: "2"},
              {key: "3br", text: "Cuatro Esquinas", value: "3"},
              {key: "4br", text: "Santísima Trinidad", value: "4"},
              {key: "5br", text: "Carrillos Alto", value: "5"},
              {key: "6br", text: "Senda", value: "6"},
              {key: "7br", text: "Sonora", value: "7"}]},
          {key: "5ds", text: "Sabana Redonda", value: "5", barrios: [
              {key: "1br", text: "Sabana Redonda Centro", value: "1"},
              {key: "2br", text: "Altura (parte)", value: "2"},
              {key: "3br", text: "Bajos Del Tigre", value: "3"}]}]},
      {key: "9cn", text: "Orotina", value: "9", distritos: [
          {key: "1ds", text: "Orotina", value: "1", barrios: [
              {key: "1br", text: "Orotina Centro", value: "1"},
              {key: "2br", text: "Aguacate", value: "2"},
              {key: "3br", text: "Arboleda", value: "3"},
              {key: "4br", text: "Carmen", value: "4"},
              {key: "5br", text: "Cortezal", value: "5"},
              {key: "6br", text: "Cuatro Esquinas Norte", value: "6"},
              {key: "7br", text: "Jesús", value: "7"},
              {key: "8br", text: "Kilómetro", value: "8"},
              {key: "9br", text: "López", value: "9"},
              {key: "10br", text: "Miraflores", value: "10"},
              {key: "11br", text: "Rastro Viejo", value: "11"},
              {key: "12br", text: "San Rafael", value: "12"},
              {key: "13br", text: "San Vicente", value: "13"},
              {key: "14br", text: "Tres Marías", value: "14"},
              {key: "15br", text: "Villa Los Reyes", value: "15"},
              {key: "16br", text: "Alto Vindas", value: "16"},
              {key: "17br", text: "Esperanza", value: "17"},
              {key: "18br", text: "Tigre", value: "18"}]},
          {key: "2ds", text: "El Mastate", value: "2", barrios: [
              {key: "1br", text: "El Mastate Centro", value: "1"},
              {key: "2br", text: "Cuatro Esquinas Este", value: "2"},
              {key: "3br", text: "Piedra Azul", value: "3"},
              {key: "4br", text: "Guayabal", value: "4"}]},
          {key: "3ds", text: "Hacienda Vieja", value: "3", barrios: [
              {key: "1br", text: "Hacienda Vieja Centro", value: "1"},
              {key: "2br", text: "Marichal", value: "2"},
              {key: "3br", text: "Concepción", value: "3"},
              {key: "4br", text: "Dantas", value: "4"}]},
          {key: "4ds", text: "Coyolar", value: "4", barrios: [
              {key: "1br", text: "Coyolar Centro", value: "1"},
              {key: "2br", text: "Corazón De María", value: "2"},
              {key: "3br", text: "Bajos Del Coyote", value: "3"},
              {key: "4br", text: "Cebadilla", value: "4"},
              {key: "5br", text: "Guápiles", value: "5"},
              {key: "6br", text: "Limonal", value: "6"},
              {key: "7br", text: "Mangos", value: "7"},
              {key: "8br", text: "Mollejones", value: "8"},
              {key: "9br", text: "Piedras De Fuego", value: "9"},
              {key: "10br", text: "Pozón", value: "10"},
              {key: "11br", text: "San Jerónimo", value: "11"},
              {key: "12br", text: "Santa Rita", value: "12"}]},
          {key: "5ds", text: "La Ceiba", value: "5", barrios: [
              {key: "1br", text: "La Ceiba Centro", value: "1"},
              {key: "2br", text: "Angostura Matamoros", value: "2"},
              {key: "3br", text: "Cascajal", value: "3"},
              {key: "4br", text: "Cuesta Pitahaya", value: "4"},
              {key: "5br", text: "Guácimo", value: "5"},
              {key: "6br", text: "Hidalgo", value: "6"},
              {key: "7br", text: "Kilómetro 81", value: "7"},
              {key: "8br", text: "Machuca", value: "8"},
              {key: "9br", text: "Matamoros", value: "9"},
              {key: "10br", text: "Túnel", value: "10"},
              {key: "11br", text: "Uvita", value: "11"},
              {key: "12br", text: "Zapote", value: "12"}]}]},
      {key: "10cn", text: "San Carlos", value: "10", distritos: [
          {key: "1ds", text: "Quesada", value: "1", barrios: [
              {key: "1br", text: "Quesada Centro", value: "1"},
              {key: "2br", text: "Alto Cruz", value: "2"},
              {key: "3br", text: "Ana Mercedes", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Arco Iris", value: "5"},
              {key: "6br", text: "Bajo Los Arce", value: "6"},
              {key: "7br", text: "Bajo Lourdes", value: "7"},
              {key: "8br", text: "Baltazar Quesada", value: "8"},
              {key: "9br", text: "Bellavista", value: "9"},
              {key: "10br", text: "Carmen", value: "10"},
              {key: "11br", text: "Casilda Matamoros", value: "11"},
              {key: "12br", text: "Cementerio", value: "12"},
              {key: "13br", text: "Coocique", value: "13"},
              {key: "14br", text: "Colina 1", value: "14"},
              {key: "15br", text: "Colina 2", value: "15"},
              {key: "16br", text: "Corazón De Jesús", value: "16"},
              {key: "17br", text: "Corobicí", value: "17"},
              {key: "18br", text: "Don Victorino", value: "18"},
              {key: "19br", text: "El Campo", value: "19"},
              {key: "20br", text: "Gamonales", value: "20"},
              {key: "21br", text: "Guadalupe", value: "21"},
              {key: "22br", text: "La Cruz", value: "22"},
              {key: "23br", text: "La Leila", value: "23"},
              {key: "24br", text: "La Margarita", value: "24"},
              {key: "25br", text: "La Roca", value: "25"},
              {key: "26br", text: "La Torre", value: "26"},
              {key: "27br", text: "Los Abuelos", value: "27"},
              {key: "28br", text: "Lomas Del Norte", value: "28"},
              {key: "29br", text: "Lutz", value: "29"},
              {key: "30br", text: "Meco", value: "30"},
              {key: "31br", text: "Mercedes", value: "31"},
              {key: "32br", text: "Peje", value: "32"},
              {key: "33br", text: "San Antonio", value: "33"},
              {key: "34br", text: "San Gerardo", value: "34"},
              {key: "35br", text: "San Martín", value: "35"},
              {key: "36br", text: "San Pablo", value: "36"},
              {key: "37br", text: "San Roque", value: "37"},
              {key: "38br", text: "Santa Fe", value: "38"},
              {key: "39br", text: "Selva Verde", value: "39"},
              {key: "40br", text: "Unión", value: "40"},
              {key: "41br", text: "Villarreal", value: "41"},
              {key: "42br", text: "Abundancia", value: "42"},
              {key: "43br", text: "Brumas", value: "43"},
              {key: "44br", text: "Calle Guerrero", value: "44"},
              {key: "45br", text: "San Ramón (cariblanca)", value: "45"},
              {key: "46br", text: "Cedral Norte", value: "46"},
              {key: "47br", text: "Cedral Sur", value: "47"},
              {key: "48br", text: "Colón", value: "48"},
              {key: "49br", text: "Dulce Nombre", value: "49"},
              {key: "50br", text: "Leones", value: "50"},
              {key: "51br", text: "Lindavista", value: "51"},
              {key: "52br", text: "Manzanos", value: "52"},
              {key: "53br", text: "Montañitas", value: "53"},
              {key: "54br", text: "Palmas", value: "54"},
              {key: "55br", text: "Porvenir", value: "55"},
              {key: "56br", text: "San Juan (quebrada Palo)", value: "56"},
              {key: "57br", text: "Ronrón Abajo", value: "57"},
              {key: "58br", text: "Ronrón Arriba", value: "58"},
              {key: "59br", text: "San Isidro", value: "59"},
              {key: "60br", text: "San José De La Montaña", value: "60"},
              {key: "61br", text: "San Luis", value: "61"},
              {key: "62br", text: "San Rafael", value: "62"},
              {key: "63br", text: "San Vicente", value: "63"},
              {key: "64br", text: "Sucre", value: "64"},
              {key: "65br", text: "Tesalia", value: "65"}]},
          {key: "2ds", text: "Florencia", value: "2", barrios: [
              {key: "1br", text: "Florencia Centro", value: "1"},
              {key: "2br", text: "Alto Gloria", value: "2"},
              {key: "3br", text: "Aquilea (san Francisco)", value: "3"},
              {key: "4br", text: "Bonanza", value: "4"},
              {key: "5br", text: "Caimitos", value: "5"},
              {key: "6br", text: "Chaparral", value: "6"},
              {key: "7br", text: "Cuestillas", value: "7"},
              {key: "8br", text: "Jabillos (parte)", value: "8"},
              {key: "9br", text: "Molino", value: "9"},
              {key: "10br", text: "Muelle De San Carlos", value: "10"},
              {key: "11br", text: "Pejeviejo", value: "11"},
              {key: "12br", text: "Pénjamo", value: "12"},
              {key: "13br", text: "Platanar", value: "13"},
              {key: "14br", text: "Puente Casa", value: "14"},
              {key: "15br", text: "Puerto Escondido", value: "15"},
              {key: "16br", text: "Quebrada Azul", value: "16"},
              {key: "17br", text: "San Juan", value: "17"},
              {key: "18br", text: "San Luis", value: "18"},
              {key: "19br", text: "San Rafael", value: "19"},
              {key: "20br", text: "Santa Clara", value: "20"},
              {key: "21br", text: "Santa María", value: "21"},
              {key: "22br", text: "Santa Rita", value: "22"},
              {key: "23br", text: "Sapera", value: "23"},
              {key: "24br", text: "Ulima", value: "24"},
              {key: "25br", text: "Vega", value: "25"},
              {key: "26br", text: "Vieja", value: "26"}]},
          {key: "3ds", text: "Buenavista", value: "3", barrios: [
              {key: "1br", text: "Buenavista Centro", value: "1"},
              {key: "2br", text: "Culebra", value: "2"},
              {key: "3br", text: "Quina (parte)", value: "3"},
              {key: "4br", text: "San Antonio", value: "4"},
              {key: "5br", text: "San Bosco", value: "5"}]},
          {key: "4ds", text: "Aguas Zarcas", value: "4", barrios: [
              {key: "1br", text: "Aguas Zarcas Centro", value: "1"},
              {key: "2br", text: "Latino", value: "2"},
              {key: "3br", text: "Manantial", value: "3"},
              {key: "4br", text: "Montecristo", value: "4"},
              {key: "5br", text: "Nazareth", value: "5"},
              {key: "6br", text: "San Bosco", value: "6"},
              {key: "7br", text: "San Gerardo Viento Fresco", value: "7"},
              {key: "8br", text: "Vistas De La Llanura", value: "8"},
              {key: "9br", text: "Altamira", value: "9"},
              {key: "10br", text: "Alto Jiménez (montecristo)", value: "10"},
              {key: "11br", text: "Bijagual", value: "11"},
              {key: "12br", text: "Boca Los Chiles", value: "12"},
              {key: "13br", text: "Cantarrana (santa Fe)", value: "13"},
              {key: "14br", text: "Coopesanjuan", value: "14"},
              {key: "15br", text: "Caño Negro", value: "15"},
              {key: "16br", text: "Cerrito", value: "16"},
              {key: "17br", text: "Cerro Cortés", value: "17"},
              {key: "18br", text: "Concepción", value: "18"},
              {key: "19br", text: "Chiles", value: "19"},
              {key: "20br", text: "Danta", value: "20"},
              {key: "21br", text: "Delicias", value: "21"},
              {key: "22br", text: "Faroles", value: "22"},
              {key: "23br", text: "Gloria", value: "23"},
              {key: "24br", text: "Guabo", value: "24"},
              {key: "25br", text: "Llanos De Altamirita", value: "25"},
              {key: "26br", text: "Pitalito Norte (esquipulas)", value: "26"},
              {key: "27br", text: "Pitalito", value: "27"},
              {key: "28br", text: "San José", value: "28"},
              {key: "29br", text: "Valle Hermoso", value: "29"},
              {key: "30br", text: "Vasconia", value: "30"},
              {key: "31br", text: "Vuelta De Kooper", value: "31"}]},
          {key: "5ds", text: "Venecia", value: "5", barrios: [
              {key: "1br", text: "Venecia Centro", value: "1"},
              {key: "2br", text: "Carmen", value: "2"},
              {key: "3br", text: "Corazón De Jesús", value: "3"},
              {key: "4br", text: "El Ceibo", value: "4"},
              {key: "5br", text: "Jardín", value: "5"},
              {key: "6br", text: "La Gloria", value: "6"},
              {key: "7br", text: "Alpes", value: "7"},
              {key: "8br", text: "Brisas", value: "8"},
              {key: "9br", text: "Buenos Aires", value: "9"},
              {key: "10br", text: "Guayabo", value: "10"},
              {key: "11br", text: "Latas", value: "11"},
              {key: "12br", text: "Marsella", value: "12"},
              {key: "13br", text: "Mesén", value: "13"},
              {key: "14br", text: "Nazareth", value: "14"},
              {key: "15br", text: "Negritos", value: "15"},
              {key: "16br", text: "Paraíso", value: "16"},
              {key: "17br", text: "Pueblo Viejo", value: "17"},
              {key: "18br", text: "San Cayetano", value: "18"},
              {key: "19br", text: "San Martín", value: "19"},
              {key: "20br", text: "Unión", value: "20"}]},
          {key: "6ds", text: "Pital", value: "6", barrios: [
              {key: "1br", text: "Pital Centro", value: "1"},
              {key: "2br", text: "Bosque", value: "2"},
              {key: "3br", text: "Comarca", value: "3"},
              {key: "4br", text: "San Cristóbal", value: "4"},
              {key: "5br", text: "Ángeles", value: "5"},
              {key: "6br", text: "Boca Sahíno", value: "6"},
              {key: "7br", text: "Boca Tapada", value: "7"},
              {key: "8br", text: "Boca Tres Amigos", value: "8"},
              {key: "9br", text: "Cabra", value: "9"},
              {key: "10br", text: "Canacas", value: "10"},
              {key: "11br", text: "Caño Chu", value: "11"},
              {key: "12br", text: "Cerro Blanco (san Marcos)", value: "12"},
              {key: "13br", text: "Cuatro Esquinas", value: "13"},
              {key: "14br", text: "Chaparrón", value: "14"},
              {key: "15br", text: "Chirivico (coopeisabel)", value: "15"},
              {key: "16br", text: "Encanto", value: "16"},
              {key: "17br", text: "Fama (carmen)", value: "17"},
              {key: "18br", text: "Flor", value: "18"},
              {key: "19br", text: "I Griega", value: "19"},
              {key: "20br", text: "Josefina", value: "20"},
              {key: "21br", text: "Legua", value: "21"},
              {key: "22br", text: "Ojoche", value: "22"},
              {key: "23br", text: "Ojochito", value: "23"},
              {key: "24br", text: "Palmar", value: "24"},
              {key: "25br", text: "Pegón", value: "25"},
              {key: "26br", text: "Piedra Alegre", value: "26"},
              {key: "27br", text: "Puerto Escondido", value: "27"},
              {key: "28br", text: "Quebrada Grande", value: "28"},
              {key: "29br", text: "Sahíno", value: "29"},
              {key: "30br", text: "San Luis", value: "30"},
              {key: "31br", text: "Santa Elena", value: "31"},
              {key: "32br", text: "Tigre", value: "32"},
              {key: "33br", text: "Trinchera", value: "33"},
              {key: "34br", text: "Vegas", value: "34"},
              {key: "35br", text: "Veracrúz", value: "35"},
              {key: "36br", text: "Vuelta Bolsón (parte)", value: "36"},
              {key: "37br", text: "Vuelta Tablón", value: "37"},
              {key: "38br", text: "Yucatán", value: "38"}]},
          {key: "7ds", text: "La Fortuna", value: "7", barrios: [
              {key: "1br", text: "La Fortuna Centro", value: "1"},
              {key: "2br", text: "Dora", value: "2"},
              {key: "3br", text: "Manolos", value: "3"},
              {key: "4br", text: "Olivos", value: "4"},
              {key: "5br", text: "Pastoral", value: "5"},
              {key: "6br", text: "Pito", value: "6"},
              {key: "7br", text: "San Isidro", value: "7"},
              {key: "8br", text: "Sinaí", value: "8"},
              {key: "9br", text: "Agua Azul", value: "9"},
              {key: "10br", text: "Alamo", value: "10"},
              {key: "11br", text: "Ángeles", value: "11"},
              {key: "12br", text: "Burío", value: "12"},
              {key: "13br", text: "Castillo (parte)", value: "13"},
              {key: "14br", text: "Guaria", value: "14"},
              {key: "15br", text: "El Campo (guayabal)", value: "15"},
              {key: "16br", text: "Jilguero", value: "16"},
              {key: "17br", text: "Llano Verde", value: "17"},
              {key: "18br", text: "Orquídeas", value: "18"},
              {key: "19br", text: "Palma", value: "19"},
              {key: "20br", text: "Perla", value: "20"},
              {key: "21br", text: "San Isidro", value: "21"},
              {key: "22br", text: "San Jorge", value: "22"},
              {key: "23br", text: "Santa Eduviges", value: "23"},
              {key: "24br", text: "Sonafluca", value: "24"},
              {key: "25br", text: "Tanque", value: "25"},
              {key: "26br", text: "Tres Esquinas", value: "26"},
              {key: "27br", text: "Zeta Trece", value: "27"}]},
          {key: "8ds", text: "La Tigra", value: "8", barrios: [
              {key: "1br", text: "La Tigra Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Palmas", value: "3"},
              {key: "4br", text: "Cerritos", value: "4"},
              {key: "5br", text: "Esperanza", value: "5"},
              {key: "6br", text: "Futuro", value: "6"},
              {key: "7br", text: "Jabillos (parte)", value: "7"},
              {key: "8br", text: "Lucha", value: "8"},
              {key: "9br", text: "San Gerardo", value: "9"},
              {key: "10br", text: "San Isidro", value: "10"},
              {key: "11br", text: "San José", value: "11"},
              {key: "12br", text: "San Miguel", value: "12"},
              {key: "13br", text: "San Pedro", value: "13"},
              {key: "14br", text: "San Rafael", value: "14"}]},
          {key: "9ds", text: "La Palmera", value: "9", barrios: [
              {key: "1br", text: "La Palmera Centro", value: "1"},
              {key: "2br", text: "Altura", value: "2"},
              {key: "3br", text: "Calle Damas", value: "3"},
              {key: "4br", text: "Corea (concepción)", value: "4"},
              {key: "5br", text: "Corte", value: "5"},
              {key: "6br", text: "Loma", value: "6"},
              {key: "7br", text: "Lourdes", value: "7"},
              {key: "8br", text: "Marina", value: "8"},
              {key: "9br", text: "Marinita", value: "9"},
              {key: "10br", text: "San Rafael", value: "10"},
              {key: "11br", text: "San Rafael Sur", value: "11"},
              {key: "12br", text: "Santa Rosa", value: "12"},
              {key: "13br", text: "Surtubal", value: "13"},
              {key: "14br", text: "Unión", value: "14"},
              {key: "15br", text: "Vacablanca (san Francisco)", value: "15"},
              {key: "16br", text: "Villa María", value: "16"}]},
          {key: "10ds", text: "Venado", value: "10", barrios: [
              {key: "1br", text: "Venado Centro", value: "1"},
              {key: "2br", text: "Altamirita", value: "2"},
              {key: "3br", text: "Alto Blanca Lucía", value: "3"},
              {key: "4br", text: "Burío", value: "4"},
              {key: "5br", text: "Cacao", value: "5"},
              {key: "6br", text: "Delicias", value: "6"},
              {key: "7br", text: "Esperanza", value: "7"},
              {key: "8br", text: "Jicarito", value: "8"},
              {key: "9br", text: "Lindavista", value: "9"},
              {key: "10br", text: "Poma (parte)", value: "10"},
              {key: "11br", text: "Puerto Seco", value: "11"},
              {key: "12br", text: "San Isidro", value: "12"},
              {key: "13br", text: "Santa Eulalia (parte)", value: "13"},
              {key: "14br", text: "Santa Lucía", value: "14"},
              {key: "15br", text: "Tigra", value: "15"}]},
          {key: "11ds", text: "Cutris", value: "11", barrios: [
              {key: "1br", text: "Boca Arenal", value: "1"},
              {key: "2br", text: "Almendros", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Betania", value: "4"},
              {key: "5br", text: "Boca De San Carlos", value: "5"},
              {key: "6br", text: "Boca Providencia (parte)", value: "6"},
              {key: "7br", text: "Cabeceras De Aguas Zarquitas", value: "7"},
              {key: "8br", text: "Carmen", value: "8"},
              {key: "9br", text: "Cascada", value: "9"},
              {key: "10br", text: "Castelmare", value: "10"},
              {key: "11br", text: "Cocobolo", value: "11"},
              {key: "12br", text: "Coopevega", value: "12"},
              {key: "13br", text: "Corazón De Jesús", value: "13"},
              {key: "14br", text: "Crucitas", value: "14"},
              {key: "15br", text: "Chamorrito", value: "15"},
              {key: "16br", text: "Chamorro", value: "16"},
              {key: "17br", text: "Chorreras", value: "17"},
              {key: "18br", text: "Hebrón", value: "18"},
              {key: "19br", text: "Isla Del Cura", value: "19"},
              {key: "20br", text: "Isla Pobres", value: "20"},
              {key: "21br", text: "Isla Sábalo", value: "21"},
              {key: "22br", text: "Jardín", value: "22"},
              {key: "23br", text: "Kopper", value: "23"},
              {key: "24br", text: "La Cajeta", value: "24"},
              {key: "25br", text: "Laurel Galán", value: "25"},
              {key: "26br", text: "Limoncito", value: "26"},
              {key: "27br", text: "Moravia", value: "27"},
              {key: "28br", text: "Patastillo", value: "28"},
              {key: "29br", text: "Pueblo Nuevo", value: "29"},
              {key: "30br", text: "Recreo", value: "30"},
              {key: "31br", text: "Río Tico", value: "31"},
              {key: "32br", text: "Roble", value: "32"},
              {key: "33br", text: "San Antonio", value: "33"},
              {key: "34br", text: "San Fernando", value: "34"},
              {key: "35br", text: "San Francisco", value: "35"},
              {key: "36br", text: "San Joaquín", value: "36"},
              {key: "37br", text: "San Jorge", value: "37"},
              {key: "38br", text: "San José", value: "38"},
              {key: "39br", text: "San Marcos", value: "39"},
              {key: "40br", text: "Santa Rita", value: "40"},
              {key: "41br", text: "Santa Teresa", value: "41"},
              {key: "42br", text: "San Vito", value: "42"},
              {key: "43br", text: "Tabla Grande (san Pedro)", value: "43"},
              {key: "44br", text: "Terrón Colorado (parte)", value: "44"},
              {key: "45br", text: "Ventanas", value: "45"},
              {key: "46br", text: "Vuelta Bolsón (parte)", value: "46"},
              {key: "47br", text: "Vuelta Millonarios", value: "47"},
              {key: "48br", text: "Vuelta Ruedas", value: "48"}]},
          {key: "12ds", text: "Monterrey", value: "12", barrios: [
              {key: "1br", text: "Santo Domingo", value: "1"},
              {key: "2br", text: "Pericos", value: "2"},
              {key: "3br", text: "Bajillo", value: "3"},
              {key: "4br", text: "Caño Ciego", value: "4"},
              {key: "5br", text: "Cedros", value: "5"},
              {key: "6br", text: "Chambacú", value: "6"},
              {key: "7br", text: "Delicias", value: "7"},
              {key: "8br", text: "La Unión", value: "8"},
              {key: "9br", text: "Maquencal", value: "9"},
              {key: "10br", text: "Mirador", value: "10"},
              {key: "11br", text: "Montelimar", value: "11"},
              {key: "12br", text: "Monterrey", value: "12"},
              {key: "13br", text: "Orquídea", value: "13"},
              {key: "14br", text: "Pataste Arriba", value: "14"},
              {key: "15br", text: "Sabalito", value: "15"},
              {key: "16br", text: "San Andrés", value: "16"},
              {key: "17br", text: "San Antonio", value: "17"},
              {key: "18br", text: "San Cristóbal", value: "18"},
              {key: "19br", text: "San Miguel", value: "19"},
              {key: "20br", text: "Santa Eulalia (parte)", value: "20"},
              {key: "21br", text: "Santa Marta", value: "21"}]},
          {key: "13ds", text: "Pocosol", value: "13", barrios: [
              {key: "1br", text: "Santa Rosa", value: "1"},
              {key: "2br", text: "Fátima", value: "2"},
              {key: "3br", text: "Parajeles", value: "3"},
              {key: "4br", text: "Tres Perlas", value: "4"},
              {key: "5br", text: "Valle Hermoso", value: "5"},
              {key: "6br", text: "Acapulco", value: "6"},
              {key: "7br", text: "Aldea", value: "7"},
              {key: "8br", text: "Ángeles", value: "8"},
              {key: "9br", text: "Azucena", value: "9"},
              {key: "10br", text: "Banderas", value: "10"},
              {key: "11br", text: "Boca Providencia (parte)", value: "11"},
              {key: "12br", text: "Brisas", value: "12"},
              {key: "13br", text: "Buenavista", value: "13"},
              {key: "14br", text: "Buenos Aires", value: "14"},
              {key: "15br", text: "Carrizal", value: "15"},
              {key: "16br", text: "Ceiba", value: "16"},
              {key: "17br", text: "Conchito", value: "17"},
              {key: "18br", text: "Concho", value: "18"},
              {key: "19br", text: "Cuatro Esquinas", value: "19"},
              {key: "20br", text: "Esterito", value: "20"},
              {key: "21br", text: "Estero", value: "21"},
              {key: "22br", text: "Estrella", value: "22"},
              {key: "23br", text: "Guaria", value: "23"},
              {key: "24br", text: "Jazmín", value: "24"},
              {key: "25br", text: "Jocote", value: "25"},
              {key: "26br", text: "Juanilama", value: "26"},
              {key: "27br", text: "Luisa", value: "27"},
              {key: "28br", text: "Llano Verde", value: "28"},
              {key: "29br", text: "Morazán", value: "29"},
              {key: "30br", text: "Nieves", value: "30"},
              {key: "31br", text: "Paraíso", value: "31"},
              {key: "32br", text: "Paso Real", value: "32"},
              {key: "33br", text: "Plomo", value: "33"},
              {key: "34br", text: "Pocosol", value: "34"},
              {key: "35br", text: "Providencia (san Luis)", value: "35"},
              {key: "36br", text: "Pueblo Nuevo", value: "36"},
              {key: "37br", text: "Pueblo Santo", value: "37"},
              {key: "38br", text: "Rancho Quemado", value: "38"},
              {key: "39br", text: "Rubí", value: "39"},
              {key: "40br", text: "San Alejo", value: "40"},
              {key: "41br", text: "San Bosco", value: "41"},
              {key: "42br", text: "San Cristobal", value: "42"},
              {key: "43br", text: "San Diego", value: "43"},
              {key: "44br", text: "San Gerardo", value: "44"},
              {key: "45br", text: "San Isidro", value: "45"},
              {key: "46br", text: "San Martín", value: "46"},
              {key: "47br", text: "San Rafael", value: "47"},
              {key: "48br", text: "Santa Cecilia", value: "48"},
              {key: "49br", text: "Santa Esperanza", value: "49"},
              {key: "50br", text: "Santa María", value: "50"},
              {key: "51br", text: "Terrón Colorado (parte)", value: "51"},
              {key: "52br", text: "Tiricias", value: "52"},
              {key: "53br", text: "Tres Y Tres", value: "53"}]}]},
      {key: "11cn", text: "Zarcero", value: "11", distritos: [
          {key: "1ds", text: "Zarcero", value: "1", barrios: [
              {key: "1br", text: "Zarcero Centro", value: "1"},
              {key: "2br", text: "Cantarranas", value: "2"},
              {key: "3br", text: "Santa Teresita", value: "3"}]},
          {key: "2ds", text: "Laguna", value: "2", barrios: [
              {key: "1br", text: "Laguna Centro", value: "1"},
              {key: "2br", text: "Peña", value: "2"}]},
          {key: "3ds", text: "Tapezco", value: "3", barrios: [
              {key: "1br", text: "Tapezco Centro", value: "1"}]},
          {key: "4ds", text: "Guadalupe", value: "4", barrios: [
              {key: "1br", text: "Guadalupe Centro", value: "1"},
              {key: "2br", text: "Anateri", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Morelos (san Luis)", value: "4"}]},
          {key: "5ds", text: "Palmira", value: "5", barrios: [
              {key: "1br", text: "Palmira Centro", value: "1"},
              {key: "2br", text: "Picada (pueblo Nuevo)", value: "2"}]},
          {key: "6ds", text: "Zapote", value: "6", barrios: [
              {key: "1br", text: "Zapote Centro", value: "1"},
              {key: "2br", text: "Quina (parte)", value: "2"},
              {key: "3br", text: "San Juan De Lajas", value: "3"},
              {key: "4br", text: "Santa Elena", value: "4"}]},
          {key: "7ds", text: "Brisas", value: "7", barrios: [
              {key: "1br", text: "Santa Rosa (la Brisa)", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Legua", value: "3"}]}]},
      {key: "12cn", text: "Sarchí", value: "12", distritos: [
          {key: "1ds", text: "Sarchí Norte", value: "1", barrios: [
              {key: "1br", text: "Sarchí Norte Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Bajo Raimundo", value: "3"},
              {key: "4br", text: "Canto", value: "4"},
              {key: "5br", text: "Eva", value: "5"},
              {key: "6br", text: "Luisa", value: "6"},
              {key: "7br", text: "San Rafael (rincón Colorado)", value: "7"},
              {key: "8br", text: "Sahinal", value: "8"}]},
          {key: "2ds", text: "Sarchí Sur", value: "2", barrios: [
              {key: "1br", text: "Sarchí Sur Centro", value: "1"},
              {key: "2br", text: "Bajo Trapiche", value: "2"},
              {key: "3br", text: "Ranera", value: "3"},
              {key: "4br", text: "Alto Castro", value: "4"},
              {key: "5br", text: "Coopeoctava", value: "5"},
              {key: "6br", text: "Ratoncillal", value: "6"},
              {key: "7br", text: "Rincón De Alpízar", value: "7"},
              {key: "8br", text: "Rincón De Ulate", value: "8"},
              {key: "9br", text: "San Miguel", value: "9"}]},
          {key: "3ds", text: "Toro Amarillo", value: "3", barrios: [
              {key: "1br", text: "Bajos Del Toro", value: "1"},
              {key: "2br", text: "Alto Palomo", value: "2"}]},
          {key: "4ds", text: "San Pedro", value: "4", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Castro", value: "2"},
              {key: "3br", text: "Concha", value: "3"},
              {key: "4br", text: "Pueblo Seco", value: "4"},
              {key: "5br", text: "Talolinga", value: "5"},
              {key: "6br", text: "Trojas", value: "6"}]},
          {key: "5ds", text: "Rodríguez", value: "5", barrios: [
              {key: "1br", text: "San Juan", value: "1"},
              {key: "2br", text: "Bambú", value: "2"},
              {key: "3br", text: "Sabanilla", value: "3"}]}]},
      {key: "13cn", text: "Upala", value: "13", distritos: [
          {key: "1ds", text: "Upala", value: "1", barrios: [
              {key: "1br", text: "Upala Centro", value: "1"},
              {key: "2br", text: "Don Chu", value: "2"},
              {key: "3br", text: "La Unión", value: "3"},
              {key: "4br", text: "Las Palmas", value: "4"},
              {key: "5br", text: "Venecia", value: "5"},
              {key: "6br", text: "Ángeles (parte)", value: "6"},
              {key: "7br", text: "Carmen", value: "7"},
              {key: "8br", text: "Colonia Puntarenas", value: "8"},
              {key: "9br", text: "Corteza", value: "9"},
              {key: "10br", text: "Fósforo", value: "10"},
              {key: "11br", text: "Jazmines", value: "11"},
              {key: "12br", text: "Llano Azul", value: "12"},
              {key: "13br", text: "Maravilla", value: "13"},
              {key: "14br", text: "Miravalles", value: "14"},
              {key: "15br", text: "Moreno Cañas", value: "15"},
              {key: "16br", text: "Recreo", value: "16"},
              {key: "17br", text: "San Fernando", value: "17"},
              {key: "18br", text: "San Luis", value: "18"},
              {key: "19br", text: "San Martín", value: "19"},
              {key: "20br", text: "Santa Cecilia", value: "20"},
              {key: "21br", text: "Santa Rosa", value: "21"},
              {key: "22br", text: "Verbena (parte)", value: "22"}]},
          {key: "2ds", text: "Aguas Claras", value: "2", barrios: [
              {key: "1br", text: "San Isidro", value: "1"},
              {key: "2br", text: "Ceiba", value: "2"},
              {key: "3br", text: "Golfo", value: "3"},
              {key: "4br", text: "Porras", value: "4"},
              {key: "5br", text: "Aguas Claras", value: "5"},
              {key: "6br", text: "Buenos Aires", value: "6"},
              {key: "7br", text: "Colonia Blanca", value: "7"},
              {key: "8br", text: "Colonia Libertad", value: "8"},
              {key: "9br", text: "Cuatro Bocas", value: "9"},
              {key: "10br", text: "Chepa (ángeles)", value: "10"},
              {key: "11br", text: "Guayabal", value: "11"},
              {key: "12br", text: "Guinea", value: "12"},
              {key: "13br", text: "La Gloria", value: "13"},
              {key: "14br", text: "Porvenir", value: "14"},
              {key: "15br", text: "Río Negro", value: "15"},
              {key: "16br", text: "Tigra", value: "16"},
              {key: "17br", text: "Torre", value: "17"},
              {key: "18br", text: "Vuelta San Pedro", value: "18"}]},
          {key: "3ds", text: "San José O Pizote", value: "3", barrios: [
              {key: "1br", text: "San José O Pizote Centro", value: "1"},
              {key: "2br", text: "Camelias", value: "2"},
              {key: "3br", text: "Líbano (finanzas)", value: "3"},
              {key: "4br", text: "Nazareno", value: "4"},
              {key: "5br", text: "Ángeles (parte)", value: "5"},
              {key: "6br", text: "Betania", value: "6"},
              {key: "7br", text: "Caño Blanco", value: "7"},
              {key: "8br", text: "Cartagos Norte", value: "8"},
              {key: "9br", text: "Cartagos Sur", value: "9"},
              {key: "10br", text: "Copey (santa Lucía)", value: "10"},
              {key: "11br", text: "Delirio", value: "11"},
              {key: "12br", text: "Fátima", value: "12"},
              {key: "13br", text: "Jesús María", value: "13"},
              {key: "14br", text: "Linda Vista", value: "14"},
              {key: "15br", text: "Mango", value: "15"},
              {key: "16br", text: "Papagayo", value: "16"},
              {key: "17br", text: "Pinol", value: "17"},
              {key: "18br", text: "Pizotillo", value: "18"},
              {key: "19br", text: "Popoyuapa", value: "19"},
              {key: "20br", text: "Progreso", value: "20"},
              {key: "21br", text: "Pueblo Nuevo", value: "21"},
              {key: "22br", text: "San Bosco", value: "22"},
              {key: "23br", text: "San Pedro", value: "23"},
              {key: "24br", text: "San Ramón", value: "24"},
              {key: "25br", text: "Santa Clara Norte (parte)", value: "25"},
              {key: "26br", text: "Unión", value: "26"},
              {key: "27br", text: "Valle", value: "27"},
              {key: "28br", text: "Valle Bonito", value: "28"},
              {key: "29br", text: "Victoria (parte)", value: "29"},
              {key: "30br", text: "Villahermosa", value: "30"},
              {key: "31br", text: "Villanueva", value: "31"}]},
          {key: "4ds", text: "Bijagua", value: "4", barrios: [
              {key: "1br", text: "Bijagua Centro", value: "1"},
              {key: "2br", text: "Altamira", value: "2"},
              {key: "3br", text: "Carlos Vargas", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Achiote", value: "5"},
              {key: "6br", text: "Cuesta Pichardo", value: "6"},
              {key: "7br", text: "Chorros", value: "7"},
              {key: "8br", text: "Florecitas", value: "8"},
              {key: "9br", text: "Flores", value: "9"},
              {key: "10br", text: "Higuerón", value: "10"},
              {key: "11br", text: "Jardín", value: "11"},
              {key: "12br", text: "Macho", value: "12"},
              {key: "13br", text: "Pata De Gallo (san Cristóbal) (parte)", value: "13"},
              {key: "14br", text: "Pueblo Nuevo", value: "14"},
              {key: "15br", text: "Reserva", value: "15"},
              {key: "16br", text: "San Miguel", value: "16"},
              {key: "17br", text: "Santo Domingo", value: "17"},
              {key: "18br", text: "Zapote", value: "18"}]},
          {key: "5ds", text: "Delicias", value: "5", barrios: [
              {key: "1br", text: "Delicias Centro", value: "1"},
              {key: "2br", text: "Camelias", value: "2"},
              {key: "3br", text: "La Cruz", value: "3"},
              {key: "4br", text: "México", value: "4"},
              {key: "5br", text: "Mocorón", value: "5"},
              {key: "6br", text: "Pavas", value: "6"},
              {key: "7br", text: "Perla", value: "7"},
              {key: "8br", text: "Quebradón", value: "8"},
              {key: "9br", text: "Santa Clara Norte (parte)", value: "9"},
              {key: "10br", text: "Santa Clara Sur", value: "10"},
              {key: "11br", text: "Trapiche", value: "11"},
              {key: "12br", text: "Victoria (parte)", value: "12"}]},
          {key: "6ds", text: "Dos Ríos", value: "6", barrios: [
              {key: "1br", text: "Dos Ríos Centro", value: "1"},
              {key: "2br", text: "Birmania", value: "2"},
              {key: "3br", text: "Brasilia", value: "3"},
              {key: "4br", text: "Colonia", value: "4"},
              {key: "5br", text: "Gavilán", value: "5"},
              {key: "6br", text: "Jabalina", value: "6"},
              {key: "7br", text: "Progreso", value: "7"},
              {key: "8br", text: "San Luis", value: "8"}]},
          {key: "7ds", text: "Yolillal", value: "7", barrios: [
              {key: "1br", text: "San Isidro De Zapote", value: "1"},
              {key: "2br", text: "Cabanga", value: "2"},
              {key: "3br", text: "Campo Verde", value: "3"},
              {key: "4br", text: "Carmen", value: "4"},
              {key: "5br", text: "Cinco Esquinas", value: "5"},
              {key: "6br", text: "Chimurria Abajo", value: "6"},
              {key: "7br", text: "Flores", value: "7"},
              {key: "8br", text: "Jobo", value: "8"},
              {key: "9br", text: "Montecristo", value: "9"},
              {key: "10br", text: "Nazareth", value: "10"},
              {key: "11br", text: "Quebrada Grande", value: "11"},
              {key: "12br", text: "San Gabriel", value: "12"},
              {key: "13br", text: "San Jorge", value: "13"},
              {key: "14br", text: "Socorro", value: "14"},
              {key: "15br", text: "Virgen", value: "15"},
              {key: "16br", text: "Yolillal (san Antonio)", value: "16"}]},
          {key: "8ds", text: "Canalete", value: "8", barrios: [
              {key: "1br", text: "Canalete Centro", value: "1"},
              {key: "2br", text: "Armenias", value: "2"},
              {key: "3br", text: "Las Brisas", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Cuatro Cruces", value: "5"},
              {key: "6br", text: "Guacalito", value: "6"},
              {key: "7br", text: "Milpas", value: "7"},
              {key: "8br", text: "Miramar", value: "8"},
              {key: "9br", text: "Pata De Gallo (san Cristóbal) (parte)", value: "9"},
              {key: "10br", text: "Rosario", value: "10"},
              {key: "11br", text: "Verbena (parte)", value: "11"}]}]},
      {key: "14cn", text: "Los Chiles", value: "14", distritos: [
          {key: "1ds", text: "Los Chiles", value: "1", barrios: [
              {key: "1br", text: "Los Chiles Centro", value: "1"},
              {key: "2br", text: "Loma", value: "2"},
              {key: "3br", text: "Portón", value: "3"},
              {key: "4br", text: "Arco Iris", value: "4"},
              {key: "5br", text: "Berlín", value: "5"},
              {key: "6br", text: "Brisas", value: "6"},
              {key: "7br", text: "Buenos Aires", value: "7"},
              {key: "8br", text: "Cabro", value: "8"},
              {key: "9br", text: "Cachito", value: "9"},
              {key: "10br", text: "Caña Castilla", value: "10"},
              {key: "11br", text: "Combate", value: "11"},
              {key: "12br", text: "Coquital", value: "12"},
              {key: "13br", text: "Cristo Rey", value: "13"},
              {key: "14br", text: "Cuacas", value: "14"},
              {key: "15br", text: "Cuatro Esquinas", value: "15"},
              {key: "16br", text: "Delicias", value: "16"},
              {key: "17br", text: "El Cruce", value: "17"},
              {key: "18br", text: "Escaleras", value: "18"},
              {key: "19br", text: "Esperanza", value: "19"},
              {key: "20br", text: "Estrella", value: "20"},
              {key: "21br", text: "Hernández", value: "21"},
              {key: "22br", text: "Isla Chica", value: "22"},
              {key: "23br", text: "Las Nubes", value: "23"},
              {key: "24br", text: "Maramba", value: "24"},
              {key: "25br", text: "Masaya", value: "25"},
              {key: "26br", text: "Medio Queso", value: "26"},
              {key: "27br", text: "Parque", value: "27"},
              {key: "28br", text: "Playuelitas", value: "28"},
              {key: "29br", text: "Primavera", value: "29"},
              {key: "30br", text: "Pueblo Nuevo", value: "30"},
              {key: "31br", text: "Punta Cortés", value: "31"},
              {key: "32br", text: "Rampla", value: "32"},
              {key: "33br", text: "Refugio", value: "33"},
              {key: "34br", text: "Roble", value: "34"},
              {key: "35br", text: "San Alejandro", value: "35"},
              {key: "36br", text: "San Gerardo", value: "36"},
              {key: "37br", text: "San Jerónimo", value: "37"},
              {key: "38br", text: "San Pablo", value: "38"},
              {key: "39br", text: "Santa Elena", value: "39"},
              {key: "40br", text: "Santa Fe", value: "40"},
              {key: "41br", text: "Santa Rita", value: "41"},
              {key: "42br", text: "Santa Rosa", value: "42"},
              {key: "43br", text: "Solanos", value: "43"},
              {key: "44br", text: "Trocha", value: "44"},
              {key: "45br", text: "Virgen", value: "45"}]},
          {key: "2ds", text: "Caño Negro", value: "2", barrios: [
              {key: "1br", text: "Caño Negro Centro", value: "1"},
              {key: "2br", text: "Aguas Negras", value: "2"},
              {key: "3br", text: "Islas Cubas", value: "3"},
              {key: "4br", text: "Nueva Esperanza", value: "4"},
              {key: "5br", text: "Pilones", value: "5"},
              {key: "6br", text: "Playuelas", value: "6"},
              {key: "7br", text: "Porvenir", value: "7"},
              {key: "8br", text: "San Antonio", value: "8"},
              {key: "9br", text: "San Emilio", value: "9"},
              {key: "10br", text: "Veracruz", value: "10"}]},
          {key: "3ds", text: "El Amparo", value: "3", barrios: [
              {key: "1br", text: "El Amparo Centro", value: "1"},
              {key: "2br", text: "Alto Los Reyes", value: "2"},
              {key: "3br", text: "Caño Ciego", value: "3"},
              {key: "4br", text: "Cóbano", value: "4"},
              {key: "5br", text: "Corozo", value: "5"},
              {key: "6br", text: "Corrales", value: "6"},
              {key: "7br", text: "Coyol", value: "7"},
              {key: "8br", text: "Dos Aguas", value: "8"},
              {key: "9br", text: "Gallito", value: "9"},
              {key: "10br", text: "Gallo Pinto (parte)", value: "10"},
              {key: "11br", text: "Montealegre", value: "11"},
              {key: "12br", text: "Nueva Lucha", value: "12"},
              {key: "13br", text: "Pavón", value: "13"},
              {key: "14br", text: "Quebrada Grande", value: "14"},
              {key: "15br", text: "Sabogal", value: "15"},
              {key: "16br", text: "San Antonio", value: "16"},
              {key: "17br", text: "San Francisco", value: "17"},
              {key: "18br", text: "San Isidro", value: "18"},
              {key: "19br", text: "San José Del Amparo", value: "19"},
              {key: "20br", text: "San Macario", value: "20"},
              {key: "21br", text: "Santa Cecilia", value: "21"},
              {key: "22br", text: "Trinidad", value: "22"},
              {key: "23br", text: "Vasconia", value: "23"}]},
          {key: "4ds", text: "San Jorge", value: "4", barrios: [
              {key: "1br", text: "Porvenir", value: "1"},
              {key: "2br", text: "Botijo", value: "2"},
              {key: "3br", text: "Chimurria", value: "3"},
              {key: "4br", text: "Colonia París", value: "4"},
              {key: "5br", text: "Coquitales", value: "5"},
              {key: "6br", text: "Gallo Pinto (parte)", value: "6"},
              {key: "7br", text: "Lirios", value: "7"},
              {key: "8br", text: "Lucha", value: "8"},
              {key: "9br", text: "Montealegre (parte)", value: "9"},
              {key: "10br", text: "Pueblo Nuevo", value: "10"},
              {key: "11br", text: "San Humberto", value: "11"},
              {key: "12br", text: "San Isidro", value: "12"},
              {key: "13br", text: "San Jorge", value: "13"},
              {key: "14br", text: "San Rafael", value: "14"},
              {key: "15br", text: "Terranova", value: "15"},
              {key: "16br", text: "Tigra", value: "16"},
              {key: "17br", text: "Zamba", value: "17"}]}]},
      {key: "15cn", text: "Guatuso", value: "15", distritos: [
          {key: "1ds", text: "San Rafael", value: "1", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Betania", value: "2"},
              {key: "3br", text: "Buenos Aires", value: "3"},
              {key: "4br", text: "Curirre", value: "4"},
              {key: "5br", text: "El Edén", value: "5"},
              {key: "6br", text: "El Silencio", value: "6"},
              {key: "7br", text: "El Sol", value: "7"},
              {key: "8br", text: "Flor", value: "8"},
              {key: "9br", text: "Garita", value: "9"},
              {key: "10br", text: "Guayabo", value: "10"},
              {key: "11br", text: "Jade", value: "11"},
              {key: "12br", text: "La Granja", value: "12"},
              {key: "13br", text: "La Rivera", value: "13"},
              {key: "14br", text: "Los Ángeles", value: "14"},
              {key: "15br", text: "Maquencal", value: "15"},
              {key: "16br", text: "Margarita", value: "16"},
              {key: "17br", text: "Montealegre (parte)", value: "17"},
              {key: "18br", text: "Moravia", value: "18"},
              {key: "19br", text: "Verde", value: "19"},
              {key: "20br", text: "Muerte", value: "20"},
              {key: "21br", text: "Palmera", value: "21"},
              {key: "22br", text: "Pataste", value: "22"},
              {key: "23br", text: "Pataste Abajo", value: "23"},
              {key: "24br", text: "Poma (parte)", value: "24"},
              {key: "25br", text: "Puerto Nuevo", value: "25"},
              {key: "26br", text: "Samén Arriba", value: "26"},
              {key: "27br", text: "San Juan", value: "27"},
              {key: "28br", text: "San Luis", value: "28"},
              {key: "29br", text: "San Miguel", value: "29"},
              {key: "30br", text: "Santa Fé", value: "30"},
              {key: "31br", text: "Santa Marta", value: "31"},
              {key: "32br", text: "Tonjibe", value: "32"},
              {key: "33br", text: "Treminio", value: "33"},
              {key: "34br", text: "Triunfo", value: "34"},
              {key: "35br", text: "Viento Fresco", value: "35"}]},
          {key: "2ds", text: "Buena Vista", value: "2", barrios: [
              {key: "1br", text: "Buena Vista Centro", value: "1"},
              {key: "2br", text: "Cabaña (parte)", value: "2"},
              {key: "3br", text: "Costa Ana", value: "3"},
              {key: "4br", text: "El Cruce", value: "4"},
              {key: "5br", text: "Guayabito", value: "5"},
              {key: "6br", text: "Mónico", value: "6"},
              {key: "7br", text: "Samén Abajo", value: "7"},
              {key: "8br", text: "San José", value: "8"},
              {key: "9br", text: "Thiales", value: "9"},
              {key: "10br", text: "Valle Del Río", value: "10"}]},
          {key: "3ds", text: "Cote", value: "3", barrios: [
              {key: "1br", text: "Cabanga (pueblo Nuevo)", value: "1"},
              {key: "2br", text: "Altagracia", value: "2"},
              {key: "3br", text: "Alto Sahíno", value: "3"},
              {key: "4br", text: "Bajo Cartagos", value: "4"},
              {key: "5br", text: "Pato", value: "5"},
              {key: "6br", text: "Pejibaye", value: "6"},
              {key: "7br", text: "Pimiento", value: "7"},
              {key: "8br", text: "Quebradón", value: "8"}]},
          {key: "4ds", text: "Katira", value: "4", barrios: [
              {key: "1br", text: "Katira Centro", value: "1"},
              {key: "2br", text: "Cabaña (parte)", value: "2"},
              {key: "3br", text: "Colonia Naranjeña", value: "3"},
              {key: "4br", text: "El Valle", value: "4"},
              {key: "5br", text: "Florida", value: "5"},
              {key: "6br", text: "Las Letras", value: "6"},
              {key: "7br", text: "La Paz", value: "7"},
              {key: "8br", text: "La Unión", value: "8"},
              {key: "9br", text: "Llano Bonito 1", value: "9"},
              {key: "10br", text: "Llano Bonito 2", value: "10"},
              {key: "11br", text: "Palmera", value: "11"},
              {key: "12br", text: "Río Celeste", value: "12"},
              {key: "13br", text: "Tujankir 1", value: "13"},
              {key: "14br", text: "Tujankir 2", value: "14"}]}]},
      {key: "16cn", text: "Río Cuarto", value: "16", distritos: [
          {key: "1ds", text: "Río Cuarto", value: "1", barrios: [
              {key: "1br", text: "Río Cuarto Centro", value: "1"},
              {key: "2br", text: "Bolaños", value: "2"},
              {key: "3br", text: "Caño Negro", value: "3"},
              {key: "4br", text: "Carmen", value: "4"},
              {key: "5br", text: "Carrizal", value: "5"},
              {key: "6br", text: "Colonia Del Toro", value: "6"},
              {key: "7br", text: "Crucero", value: "7"},
              {key: "8br", text: "El Hule", value: "8"},
              {key: "9br", text: "\"laguna", value: "9"},
              {key: "10br", text: "Palmar", value: "10"},
              {key: "11br", text: "Palmera", value: "11"},
              {key: "12br", text: "Pata De Gallo", value: "12"},
              {key: "13br", text: "Pueblo Nuevo", value: "13"},
              {key: "14br", text: "Río Cuarto", value: "14"},
              {key: "15br", text: "San Gerardo (parte) San Jorge", value: "15"}]},
          {key: "2ds", text: "Santa Rita", value: "2", barrios: [
              {key: "1br", text: "Santa Rita Centro", value: "1"},
              {key: "2br", text: "Ángeles Norte", value: "2"},
              {key: "3br", text: "Flor", value: "3"},
              {key: "4br", text: "La Trinidad", value: "4"},
              {key: "5br", text: "Montelirio", value: "5"},
              {key: "6br", text: "Naranjales", value: "6"},
              {key: "7br", text: "Peoresnada", value: "7"},
              {key: "8br", text: "San Gerardo", value: "8"},
              {key: "9br", text: "Santa Rita", value: "9"},
              {key: "10br", text: "Tabla", value: "10"}]},
          {key: "3ds", text: "Santa Isabel", value: "3", barrios: [
              {key: "1br", text: "Santa Isabel Centro", value: "1"},
              {key: "2br", text: "Los Lagos", value: "2"},
              {key: "3br", text: "Merced", value: "3"},
              {key: "4br", text: "Pinar", value: "4"},
              {key: "5br", text: "San Fernando", value: "5"},
              {key: "6br", text: "San José", value: "6"},
              {key: "7br", text: "San Rafael", value: "7"},
              {key: "8br", text: "San Vicente", value: "8"},
              {key: "9br", text: "Santa Isabel", value: "9"}]}]}]},
  {key: "3pr", text: "Cartago", value: "3", cantones: [
      {key: "1cn", text: "Cartago", value: "1", distritos: [
          {key: "1ds", text: "Oriental", value: "1", barrios: [
              {key: "1br", text: "Cartago (parte)", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Asís", value: "3"},
              {key: "4br", text: "Brisas", value: "4"},
              {key: "5br", text: "Calvario", value: "5"},
              {key: "6br", text: "Cerrillos", value: "6"},
              {key: "7br", text: "Corazón De Jesús", value: "7"},
              {key: "8br", text: "Cortinas", value: "8"},
              {key: "9br", text: "Cruz De Caravaca (parte)", value: "9"},
              {key: "10br", text: "Estadio", value: "10"},
              {key: "11br", text: "Galera", value: "11"},
              {key: "12br", text: "Hospital (parte)", value: "12"},
              {key: "13br", text: "Istarú", value: "13"},
              {key: "14br", text: "Jesús Jiménez (parte)", value: "14"},
              {key: "15br", text: "Matamoros", value: "15"},
              {key: "16br", text: "Montelimar", value: "16"},
              {key: "17br", text: "Puebla", value: "17"},
              {key: "18br", text: "Soledad", value: "18"},
              {key: "19br", text: "Telles", value: "19"}]},
          {key: "2ds", text: "Occidental", value: "2", barrios: [
              {key: "1br", text: "Cartago (parte)", value: "1"},
              {key: "2br", text: "Cinco Esquinas", value: "2"},
              {key: "3br", text: "Fátima", value: "3"},
              {key: "4br", text: "Hospital (parte)", value: "4"},
              {key: "5br", text: "Jesús Jiménez (parte)", value: "5"},
              {key: "6br", text: "Laborio", value: "6"},
              {key: "7br", text: "Molino", value: "7"},
              {key: "8br", text: "Murillo", value: "8"},
              {key: "9br", text: "Palmas", value: "9"},
              {key: "10br", text: "San Cayetano", value: "10"}]},
          {key: "3ds", text: "Carmen", value: "3", barrios: [
              {key: "1br", text: "Carmen Centro", value: "1"},
              {key: "2br", text: "Alpes", value: "2"},
              {key: "3br", text: "Asilo", value: "3"},
              {key: "4br", text: "Cruz De Caravaca (parte)", value: "4"},
              {key: "5br", text: "Diques", value: "5"},
              {key: "6br", text: "Fontana", value: "6"},
              {key: "7br", text: "Jora", value: "7"},
              {key: "8br", text: "López", value: "8"},
              {key: "9br", text: "San Blas", value: "9"},
              {key: "10br", text: "Santa Eduvigis", value: "10"},
              {key: "11br", text: "Santa Fe", value: "11"},
              {key: "12br", text: "Solano", value: "12"},
              {key: "13br", text: "Turbina", value: "13"}]},
          {key: "4ds", text: "San Nicolás", value: "4", barrios: [
              {key: "1br", text: "Taras", value: "1"},
              {key: "2br", text: "Alto De Ochomogo", value: "2"},
              {key: "3br", text: "Angelina", value: "3"},
              {key: "4br", text: "Banderilla", value: "4"},
              {key: "5br", text: "Caracol", value: "5"},
              {key: "6br", text: "Cooperrosales", value: "6"},
              {key: "7br", text: "Cruz", value: "7"},
              {key: "8br", text: "Espinal", value: "8"},
              {key: "9br", text: "Johnson", value: "9"},
              {key: "10br", text: "Kerkua", value: "10"},
              {key: "11br", text: "Lima", value: "11"},
              {key: "12br", text: "Loyola", value: "12"},
              {key: "13br", text: "Molina", value: "13"},
              {key: "14br", text: "Nazareth", value: "14"},
              {key: "15br", text: "Ochomogo", value: "15"},
              {key: "16br", text: "Orontes", value: "16"},
              {key: "17br", text: "Pedregal", value: "17"},
              {key: "18br", text: "Poroses", value: "18"},
              {key: "19br", text: "Quircot", value: "19"},
              {key: "20br", text: "Ronda", value: "20"},
              {key: "21br", text: "Rosas", value: "21"},
              {key: "22br", text: "San Nicolás", value: "22"},
              {key: "23br", text: "Violín", value: "23"}]},
          {key: "5ds", text: "Aguacaliente O San Francisco", value: "5", barrios: [
              {key: "1br", text: "Aguacaliente O San Francisco Centro", value: "1"},
              {key: "2br", text: "Cocorí", value: "2"},
              {key: "3br", text: "Coronado", value: "3"},
              {key: "4br", text: "Guayabal (parte)", value: "4"},
              {key: "5br", text: "Hervidero", value: "5"},
              {key: "6br", text: "López", value: "6"},
              {key: "7br", text: "Lourdes", value: "7"},
              {key: "8br", text: "Padua", value: "8"},
              {key: "9br", text: "Pitahaya", value: "9"},
              {key: "10br", text: "Barro Morado", value: "10"},
              {key: "11br", text: "Cenicero", value: "11"},
              {key: "12br", text: "Muñeco", value: "12"},
              {key: "13br", text: "Navarrito", value: "13"}]},
          {key: "6ds", text: "Guadalupe O Arenilla", value: "6", barrios: [
              {key: "1br", text: "Guadalupe O Arenilla Centro", value: "1"},
              {key: "2br", text: "Américas", value: "2"},
              {key: "3br", text: "Higuerón", value: "3"},
              {key: "4br", text: "Joya", value: "4"},
              {key: "5br", text: "Marías", value: "5"},
              {key: "6br", text: "Las Palmas", value: "6"}]},
          {key: "7ds", text: "Corralillo", value: "7", barrios: [
              {key: "1br", text: "Corralillo Centro", value: "1"},
              {key: "2br", text: "Alumbre", value: "2"},
              {key: "3br", text: "Bajo Amador", value: "3"},
              {key: "4br", text: "Calle Valverdes", value: "4"},
              {key: "5br", text: "Guaria", value: "5"},
              {key: "6br", text: "Hortensia", value: "6"},
              {key: "7br", text: "Loma Larga", value: "7"},
              {key: "8br", text: "Llano Ángeles", value: "8"},
              {key: "9br", text: "Palangana", value: "9"},
              {key: "10br", text: "Rincón De Abarca", value: "10"},
              {key: "11br", text: "Río Conejo", value: "11"},
              {key: "12br", text: "Salitrillo", value: "12"},
              {key: "13br", text: "San Antonio", value: "13"},
              {key: "14br", text: "San Joaquín", value: "14"},
              {key: "15br", text: "San Juan Norte", value: "15"},
              {key: "16br", text: "San Juan Sur", value: "16"},
              {key: "17br", text: "Santa Elena (parte)", value: "17"},
              {key: "18br", text: "Santa Elena Arriba", value: "18"}]},
          {key: "8ds", text: "Tierra Blanca", value: "8", barrios: [
              {key: "1br", text: "Tierra Blanca Centro", value: "1"},
              {key: "2br", text: "Cuesta De Piedra", value: "2"},
              {key: "3br", text: "Misión Norte", value: "3"},
              {key: "4br", text: "Misión Sur", value: "4"},
              {key: "5br", text: "Ortiga", value: "5"},
              {key: "6br", text: "Rodeo", value: "6"},
              {key: "7br", text: "Sabanilla", value: "7"},
              {key: "8br", text: "Sabanillas", value: "8"},
              {key: "9br", text: "Santísima Trinidad", value: "9"}]},
          {key: "9ds", text: "Dulce Nombre", value: "9", barrios: [
              {key: "1br", text: "Dulce Nombre Centro", value: "1"},
              {key: "2br", text: "Caballo Blanco (parte)", value: "2"},
              {key: "3br", text: "San José", value: "3"},
              {key: "4br", text: "Cóncavas", value: "4"},
              {key: "5br", text: "Navarro", value: "5"},
              {key: "6br", text: "Perlas", value: "6"},
              {key: "7br", text: "Río Claro", value: "7"}]},
          {key: "10ds", text: "Llano Grande", value: "10", barrios: [
              {key: "1br", text: "Llano Grande Centro", value: "1"},
              {key: "2br", text: "Azahar", value: "2"},
              {key: "3br", text: "Avance", value: "3"},
              {key: "4br", text: "Barquero", value: "4"},
              {key: "5br", text: "Cañada", value: "5"},
              {key: "6br", text: "Laguna", value: "6"},
              {key: "7br", text: "Pénjamo", value: "7"},
              {key: "8br", text: "Rodeo", value: "8"}]},
          {key: "11ds", text: "Quebradilla", value: "11", barrios: [
              {key: "1br", text: "Quebradilla Centro", value: "1"},
              {key: "2br", text: "Alto Quebradilla", value: "2"},
              {key: "3br", text: "Azahar", value: "3"},
              {key: "4br", text: "Bermejo", value: "4"},
              {key: "5br", text: "Cañada", value: "5"},
              {key: "6br", text: "Copalchí", value: "6"},
              {key: "7br", text: "Coris", value: "7"},
              {key: "8br", text: "Garita (parte)", value: "8"},
              {key: "9br", text: "Rueda", value: "9"},
              {key: "10br", text: "Valle Verde", value: "10"}]}]},
      {key: "2cn", text: "Paraíso", value: "2", distritos: [
          {key: "1ds", text: "Paraíso", value: "1", barrios: [
              {key: "1br", text: "Paraíso Centro", value: "1"},
              {key: "2br", text: "Alto Birrisito", value: "2"},
              {key: "3br", text: "Barro Hondo", value: "3"},
              {key: "4br", text: "Birrisito", value: "4"},
              {key: "5br", text: "Chiral", value: "5"},
              {key: "6br", text: "Chiverre", value: "6"},
              {key: "7br", text: "Cruz Roja", value: "7"},
              {key: "8br", text: "Cucaracho", value: "8"},
              {key: "9br", text: "Estación", value: "9"},
              {key: "10br", text: "Joya", value: "10"},
              {key: "11br", text: "Luisiana", value: "11"},
              {key: "12br", text: "Pandora", value: "12"},
              {key: "13br", text: "Piedra Grande", value: "13"},
              {key: "14br", text: "Rincón", value: "14"},
              {key: "15br", text: "Sanchirí", value: "15"},
              {key: "16br", text: "Solares", value: "16"},
              {key: "17br", text: "Soledad", value: "17"},
              {key: "18br", text: "Ujarrás", value: "18"},
              {key: "19br", text: "Veintiocho De Diciembre", value: "19"},
              {key: "20br", text: "Villa Isabel", value: "20"}]},
          {key: "2ds", text: "Santiago", value: "2", barrios: [
              {key: "1br", text: "Santiago Centro", value: "1"},
              {key: "2br", text: "Acevedo", value: "2"},
              {key: "3br", text: "Ajenjal", value: "3"},
              {key: "4br", text: "Arrabará", value: "4"},
              {key: "5br", text: "Birrís (este)", value: "5"},
              {key: "6br", text: "Cúscares", value: "6"},
              {key: "7br", text: "Flor", value: "7"},
              {key: "8br", text: "Lapuente", value: "8"},
              {key: "9br", text: "Mesas", value: "9"},
              {key: "10br", text: "Mesitas", value: "10"},
              {key: "11br", text: "Nueva Ujarrás", value: "11"},
              {key: "12br", text: "Pedregal", value: "12"},
              {key: "13br", text: "Piedra Azul", value: "13"},
              {key: "14br", text: "Puente Fajardo", value: "14"},
              {key: "15br", text: "Río Regado", value: "15"},
              {key: "16br", text: "Talolinga", value: "16"},
              {key: "17br", text: "Yas", value: "17"}]},
          {key: "3ds", text: "Orosi", value: "3", barrios: [
              {key: "1br", text: "Orosi Centro", value: "1"},
              {key: "2br", text: "Alegría", value: "2"},
              {key: "3br", text: "Alto Araya", value: "3"},
              {key: "4br", text: "Calle Jucó", value: "4"},
              {key: "5br", text: "Hotel", value: "5"},
              {key: "6br", text: "Nubes", value: "6"},
              {key: "7br", text: "Palomas", value: "7"},
              {key: "8br", text: "Palomo", value: "8"},
              {key: "9br", text: "Patillos", value: "9"},
              {key: "10br", text: "Puente Negro", value: "10"},
              {key: "11br", text: "Purisil", value: "11"},
              {key: "12br", text: "Queverí", value: "12"},
              {key: "13br", text: "Río Macho", value: "13"},
              {key: "14br", text: "San Rafael", value: "14"},
              {key: "15br", text: "Sitio", value: "15"},
              {key: "16br", text: "Tapantí", value: "16"},
              {key: "17br", text: "Troya", value: "17"},
              {key: "18br", text: "Villa Mills", value: "18"}]},
          {key: "4ds", text: "Cachí", value: "4", barrios: [
              {key: "1br", text: "Cachí Centro", value: "1"},
              {key: "2br", text: "Peñas Blancas", value: "2"},
              {key: "3br", text: "Pueblo Nuevo", value: "3"},
              {key: "4br", text: "Bajos De Dorotea", value: "4"},
              {key: "5br", text: "Bajos De Urasca", value: "5"},
              {key: "6br", text: "Guábata", value: "6"},
              {key: "7br", text: "Guatusito", value: "7"},
              {key: "8br", text: "Hamaca (parte)", value: "8"},
              {key: "9br", text: "Joyas", value: "9"},
              {key: "10br", text: "Loaiza", value: "10"},
              {key: "11br", text: "San Jerónimo", value: "11"},
              {key: "12br", text: "Urasca", value: "12"},
              {key: "13br", text: "Volio", value: "13"}]},
          {key: "5ds", text: "Llanos De Santa Lucia", value: "5", barrios: [
              {key: "1br", text: "Llanos", value: "1"},
              {key: "2br", text: "Ayala", value: "2"},
              {key: "3br", text: "Páez (parte)", value: "3"},
              {key: "4br", text: "Salvador", value: "4"}]}]},
      {key: "3cn", text: "La Unión", value: "3", distritos: [
          {key: "1ds", text: "Tres Ríos", value: "1", barrios: [
              {key: "1br", text: "Tres Ríos Centro", value: "1"},
              {key: "2br", text: "Antigua", value: "2"},
              {key: "3br", text: "Villas", value: "3"}]},
          {key: "2ds", text: "San Diego", value: "2", barrios: [
              {key: "1br", text: "San Diego Centro", value: "1"},
              {key: "2br", text: "Eulalia", value: "2"},
              {key: "3br", text: "Florencio Del Castillo", value: "3"},
              {key: "4br", text: "Jirales", value: "4"},
              {key: "5br", text: "Mariana", value: "5"},
              {key: "6br", text: "Tacora", value: "6"},
              {key: "7br", text: "Rincón Mesén (parte)", value: "7"},
              {key: "8br", text: "Santiago Del Monte", value: "8"}]},
          {key: "3ds", text: "San Juan", value: "3", barrios: [
              {key: "1br", text: "San Juan Centro", value: "1"},
              {key: "2br", text: "Araucarias (parte)", value: "2"},
              {key: "3br", text: "Danza Del Sol", value: "3"},
              {key: "4br", text: "Herrán", value: "4"},
              {key: "5br", text: "Loma Verde", value: "5"},
              {key: "6br", text: "Unión", value: "6"},
              {key: "7br", text: "Villas De Ayarco", value: "7"}]},
          {key: "4ds", text: "San Rafael", value: "4", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Carpintera", value: "2"},
              {key: "3br", text: "San Miguel", value: "3"},
              {key: "4br", text: "San Vicente", value: "4"},
              {key: "5br", text: "Pilarica", value: "5"},
              {key: "6br", text: "Quebrada Del Fierro", value: "6"},
              {key: "7br", text: "Fierro", value: "7"},
              {key: "8br", text: "Yerbabuena", value: "8"}]},
          {key: "5ds", text: "Concepción", value: "5", barrios: [
              {key: "1br", text: "Concepción Centro", value: "1"},
              {key: "2br", text: "Cima", value: "2"},
              {key: "3br", text: "Cuadrante", value: "3"},
              {key: "4br", text: "Lirios", value: "4"},
              {key: "5br", text: "Llanos De Concepción", value: "5"},
              {key: "6br", text: "Naranjal (parte)", value: "6"},
              {key: "7br", text: "Poró", value: "7"},
              {key: "8br", text: "Salitrillo", value: "8"},
              {key: "9br", text: "San Francisco", value: "9"},
              {key: "10br", text: "San Josecito (parte)", value: "10"}]},
          {key: "6ds", text: "Dulce Nombre", value: "6", barrios: [
              {key: "1br", text: "Dulce Nombre Centro", value: "1"},
              {key: "2br", text: "Alto Del Carmen", value: "2"},
              {key: "3br", text: "Tirrá", value: "3"}]},
          {key: "7ds", text: "San Ramón", value: "7", barrios: [
              {key: "1br", text: "San Ramón Centro", value: "1"},
              {key: "2br", text: "Bellomonte", value: "2"},
              {key: "3br", text: "Cerrillo", value: "3"},
              {key: "4br", text: "Cumbres", value: "4"},
              {key: "5br", text: "Holandés", value: "5"},
              {key: "6br", text: "Mansiones (parte)", value: "6"},
              {key: "7br", text: "Montaña Rusa (parte)", value: "7"},
              {key: "8br", text: "Naranjal (parte)", value: "8"},
              {key: "9br", text: "San Josecito (parte)", value: "9"}]},
          {key: "8ds", text: "Río Azul", value: "8", barrios: [
              {key: "1br", text: "Río Azul Centro", value: "1"},
              {key: "2br", text: "Lindavista (loma Gobierno)", value: "2"},
              {key: "3br", text: "Quebradas", value: "3"},
              {key: "4br", text: "Rincón Mesén (parte)", value: "4"}]}]},
      {key: "4cn", text: "Jiménez", value: "4", distritos: [
          {key: "1ds", text: "Juan Viñas", value: "1", barrios: [
              {key: "1br", text: "Juan Viñas Centro", value: "1"},
              {key: "2br", text: "Alpes", value: "2"},
              {key: "3br", text: "Buenos Aires", value: "3"},
              {key: "4br", text: "Maravilla", value: "4"},
              {key: "5br", text: "Naranjito", value: "5"},
              {key: "6br", text: "Naranjo", value: "6"},
              {key: "7br", text: "San Martín", value: "7"},
              {key: "8br", text: "Durán", value: "8"},
              {key: "9br", text: "Gloria", value: "9"},
              {key: "10br", text: "Quebrada Honda", value: "10"},
              {key: "11br", text: "Santa Elena", value: "11"},
              {key: "12br", text: "Santa Marta", value: "12"},
              {key: "13br", text: "Victoria (alto Victoria)", value: "13"}]},
          {key: "2ds", text: "Tucurrique", value: "2", barrios: [
              {key: "1br", text: "Tucurrique Centro", value: "1"},
              {key: "2br", text: "Alto Campos", value: "2"},
              {key: "3br", text: "Bajo Congo", value: "3"},
              {key: "4br", text: "Congo", value: "4"},
              {key: "5br", text: "Duan", value: "5"},
              {key: "6br", text: "Esperanza", value: "6"},
              {key: "7br", text: "Hamaca (parte)", value: "7"},
              {key: "8br", text: "Sabanilla", value: "8"},
              {key: "9br", text: "San Antonio Del Monte", value: "9"},
              {key: "10br", text: "Volconda", value: "10"},
              {key: "11br", text: "Vueltas", value: "11"}]},
          {key: "3ds", text: "Pejibaye", value: "3", barrios: [
              {key: "1br", text: "Pejibaye Centro", value: "1"},
              {key: "2br", text: "Alto Humo", value: "2"},
              {key: "3br", text: "Cacao", value: "3"},
              {key: "4br", text: "Cantarrana", value: "4"},
              {key: "5br", text: "Casa De Teja", value: "5"},
              {key: "6br", text: "Ceiba", value: "6"},
              {key: "7br", text: "Chucuyo", value: "7"},
              {key: "8br", text: "Esperanza", value: "8"},
              {key: "9br", text: "Gato", value: "9"},
              {key: "10br", text: "Humo", value: "10"},
              {key: "11br", text: "Joyas", value: "11"},
              {key: "12br", text: "Juray", value: "12"},
              {key: "13br", text: "Omega", value: "13"},
              {key: "14br", text: "Oriente", value: "14"},
              {key: "15br", text: "San Gerardo", value: "15"},
              {key: "16br", text: "Selva", value: "16"},
              {key: "17br", text: "Taus", value: "17"},
              {key: "18br", text: "Tausito", value: "18"},
              {key: "19br", text: "Yolanda", value: "19"},
              {key: "20br", text: "Zapote", value: "20"}]}]},
      {key: "5cn", text: "Turrialba", value: "5", distritos: [
          {key: "1ds", text: "Turrialba", value: "1", barrios: [
              {key: "1br", text: "Turrialba Centro", value: "1"},
              {key: "2br", text: "Américas", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Bajo Barrientos", value: "4"},
              {key: "5br", text: "Cabiria", value: "5"},
              {key: "6br", text: "Campabadal", value: "6"},
              {key: "7br", text: "Cañaveral", value: "7"},
              {key: "8br", text: "Castro Salazar", value: "8"},
              {key: "9br", text: "Cementerio", value: "9"},
              {key: "10br", text: "Chiz", value: "10"},
              {key: "11br", text: "Clorito Picado", value: "11"},
              {key: "12br", text: "Colorado", value: "12"},
              {key: "13br", text: "Dominica", value: "13"},
              {key: "14br", text: "El Silencio", value: "14"},
              {key: "15br", text: "Esmeralda", value: "15"},
              {key: "16br", text: "Florencia", value: "16"},
              {key: "17br", text: "Guaria", value: "17"},
              {key: "18br", text: "Haciendita", value: "18"},
              {key: "19br", text: "Margot", value: "19"},
              {key: "20br", text: "Murcia", value: "20"},
              {key: "21br", text: "Nochebuena", value: "21"},
              {key: "22br", text: "Numa", value: "22"},
              {key: "23br", text: "Pastor", value: "23"},
              {key: "24br", text: "Pavas", value: "24"},
              {key: "25br", text: "Poró", value: "25"},
              {key: "26br", text: "Pueblo Nuevo", value: "26"},
              {key: "27br", text: "Recreo", value: "27"},
              {key: "28br", text: "Repasto", value: "28"},
              {key: "29br", text: "Roncha", value: "29"},
              {key: "30br", text: "San Cayetano", value: "30"},
              {key: "31br", text: "San Juan Norte", value: "31"},
              {key: "32br", text: "San Juan Sur", value: "32"},
              {key: "33br", text: "San Rafael", value: "33"},
              {key: "34br", text: "Sictaya", value: "34"}]},
          {key: "2ds", text: "La Suiza", value: "2", barrios: [
              {key: "1br", text: "La Suiza Centro", value: "1"},
              {key: "2br", text: "Leona (parte)", value: "2"},
              {key: "3br", text: "Abelardo Rojas", value: "3"},
              {key: "4br", text: "Alto Alemania", value: "4"},
              {key: "5br", text: "Atirro", value: "5"},
              {key: "6br", text: "Balalaica", value: "6"},
              {key: "7br", text: "Buenos Aires", value: "7"},
              {key: "8br", text: "Canadá", value: "8"},
              {key: "9br", text: "Carmen", value: "9"},
              {key: "10br", text: "Cruzada", value: "10"},
              {key: "11br", text: "Danta", value: "11"},
              {key: "12br", text: "Gaviotas", value: "12"},
              {key: "13br", text: "Guadalupe", value: "13"},
              {key: "14br", text: "Máquina Vieja", value: "14"},
              {key: "15br", text: "Margarita", value: "15"},
              {key: "16br", text: "Mollejones", value: "16"},
              {key: "17br", text: "Pacayitas", value: "17"},
              {key: "18br", text: "Pacuare", value: "18"},
              {key: "19br", text: "Piedra Grande", value: "19"},
              {key: "20br", text: "Porvenir De La Esperanza", value: "20"},
              {key: "21br", text: "Puente Alto", value: "21"},
              {key: "22br", text: "San Vicente", value: "22"},
              {key: "23br", text: "Selva (parte)", value: "23"},
              {key: "24br", text: "Silencio", value: "24"},
              {key: "25br", text: "Sonia", value: "25"}]},
          {key: "3ds", text: "Peralta", value: "3", barrios: [
              {key: "1br", text: "Peralta Centro", value: "1"},
              {key: "2br", text: "El Seis", value: "2"}]},
          {key: "4ds", text: "Santa Cruz", value: "4", barrios: [
              {key: "1br", text: "Santa Cruz Centro", value: "1"},
              {key: "2br", text: "Bajos De Bonilla", value: "2"},
              {key: "3br", text: "Bolsón (parte)", value: "3"},
              {key: "4br", text: "Bonilla", value: "4"},
              {key: "5br", text: "Buenos Aires", value: "5"},
              {key: "6br", text: "Calle Vargas", value: "6"},
              {key: "7br", text: "Carmen (parte)", value: "7"},
              {key: "8br", text: "Esperanza", value: "8"},
              {key: "9br", text: "Guayabo Arriba", value: "9"},
              {key: "10br", text: "La Central", value: "10"},
              {key: "11br", text: "La Fuente", value: "11"},
              {key: "12br", text: "Pastora", value: "12"},
              {key: "13br", text: "Picada", value: "13"},
              {key: "14br", text: "Raicero", value: "14"},
              {key: "15br", text: "Reunión", value: "15"},
              {key: "16br", text: "San Antonio", value: "16"},
              {key: "17br", text: "San Diego", value: "17"},
              {key: "18br", text: "Torito (parte)", value: "18"}]},
          {key: "5ds", text: "Santa Teresita", value: "5", barrios: [
              {key: "1br", text: "Santa Teresita Centro (lajas)", value: "1"},
              {key: "2br", text: "Cooperativa", value: "2"},
              {key: "3br", text: "Bonilla Arriba", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Cas", value: "5"},
              {key: "6br", text: "Cimarrones", value: "6"},
              {key: "7br", text: "Colonia Guayabo", value: "7"},
              {key: "8br", text: "Colonia San Ramón", value: "8"},
              {key: "9br", text: "Corralón", value: "9"},
              {key: "10br", text: "Dulce Nombre", value: "10"},
              {key: "11br", text: "El Dos", value: "11"},
              {key: "12br", text: "Fuente", value: "12"},
              {key: "13br", text: "Guayabo (parte)", value: "13"},
              {key: "14br", text: "Líbano", value: "14"},
              {key: "15br", text: "Nueva Flor", value: "15"},
              {key: "16br", text: "Palomo", value: "16"},
              {key: "17br", text: "Pradera", value: "17"},
              {key: "18br", text: "Sandoval", value: "18"},
              {key: "19br", text: "Santa Tecla", value: "19"},
              {key: "20br", text: "Sauce", value: "20"},
              {key: "21br", text: "Torito (parte)", value: "21"},
              {key: "22br", text: "Torito Sur", value: "22"}]},
          {key: "6ds", text: "Pavones", value: "6", barrios: [
              {key: "1br", text: "Pavones Centro", value: "1"},
              {key: "2br", text: "Angostura", value: "2"},
              {key: "3br", text: "Bóveda", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Chitaría", value: "5"},
              {key: "6br", text: "Eslabón", value: "6"},
              {key: "7br", text: "Isla Bonita (parte)", value: "7"},
              {key: "8br", text: "Jabillos", value: "8"},
              {key: "9br", text: "San Rafael", value: "9"},
              {key: "10br", text: "Sitio Mata", value: "10"},
              {key: "11br", text: "Yama", value: "11"}]},
          {key: "7ds", text: "Tuis", value: "7", barrios: [
              {key: "1br", text: "Tuis Centro", value: "1"},
              {key: "2br", text: "Alto Surtubal", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Bajo Pacuare (norte)", value: "4"},
              {key: "5br", text: "Cabeza De Buey", value: "5"},
              {key: "6br", text: "Cien Manzanas", value: "6"},
              {key: "7br", text: "Colonia San Lucas", value: "7"},
              {key: "8br", text: "Colonia Silencio", value: "8"},
              {key: "9br", text: "Colonias", value: "9"},
              {key: "10br", text: "Leona (parte)", value: "10"},
              {key: "11br", text: "Mata De Guineo", value: "11"},
              {key: "12br", text: "Nubes", value: "12"},
              {key: "13br", text: "Paulina", value: "13"},
              {key: "14br", text: "Sacro", value: "14"},
              {key: "15br", text: "San Bosco", value: "15"},
              {key: "16br", text: "Selva (parte)", value: "16"}]},
          {key: "8ds", text: "Tayutic", value: "8", barrios: [
              {key: "1br", text: "Platanillo", value: "1"},
              {key: "2br", text: "Bajo Pacuare (sur)", value: "2"},
              {key: "3br", text: "Dos Amigos", value: "3"},
              {key: "4br", text: "Dulce Nombre", value: "4"},
              {key: "5br", text: "Guineal", value: "5"},
              {key: "6br", text: "Jicotea", value: "6"},
              {key: "7br", text: "Mina", value: "7"},
              {key: "8br", text: "Morado", value: "8"},
              {key: "9br", text: "Quebradas", value: "9"},
              {key: "10br", text: "San Martín", value: "10"},
              {key: "11br", text: "San Rafael", value: "11"},
              {key: "12br", text: "Tacotal", value: "12"}]},
          {key: "9ds", text: "Santa Rosa", value: "9", barrios: [
              {key: "1br", text: "Santa Rosa Centro", value: "1"},
              {key: "2br", text: "Aquiares", value: "2"},
              {key: "3br", text: "Bolsón (parte)", value: "3"},
              {key: "4br", text: "Carmen (parte)", value: "4"},
              {key: "5br", text: "Río Claro", value: "5"},
              {key: "6br", text: "San Rafael", value: "6"},
              {key: "7br", text: "Verbena Norte", value: "7"},
              {key: "8br", text: "Verbena Sur", value: "8"}]},
          {key: "10ds", text: "Tres Equis", value: "10", barrios: [
              {key: "1br", text: "Tres Equis Centro", value: "1"},
              {key: "2br", text: "Alto June", value: "2"},
              {key: "3br", text: "Corozal", value: "3"},
              {key: "4br", text: "Flor", value: "4"},
              {key: "5br", text: "Guanacasteca", value: "5"},
              {key: "6br", text: "Isla Bonita(parte)", value: "6"},
              {key: "7br", text: "Pilón De Azúcar", value: "7"},
              {key: "8br", text: "San Pablo", value: "8"},
              {key: "9br", text: "Sol", value: "9"}]},
          {key: "11ds", text: "La Isabel", value: "11", barrios: [
              {key: "1br", text: "El Mora", value: "1"},
              {key: "2br", text: "Azul", value: "2"},
              {key: "3br", text: "Ánimas", value: "3"},
              {key: "4br", text: "Alto De Varas (alto Varal)", value: "4"},
              {key: "5br", text: "Guayabo (parte)", value: "5"},
              {key: "6br", text: "Jesús María", value: "6"},
              {key: "7br", text: "La Isabel", value: "7"},
              {key: "8br", text: "San Martín", value: "8"}]},
          {key: "12ds", text: "Chirripó", value: "12", barrios: [
              {key: "1br", text: "Grano De Oro O Xara", value: "1"},
              {key: "2br", text: "Carolina", value: "2"},
              {key: "3br", text: "Chirripó Abajo (parte)", value: "3"},
              {key: "4br", text: "Chirripó Arriba", value: "4"},
              {key: "5br", text: "Damaris", value: "5"},
              {key: "6br", text: "Fortuna", value: "6"},
              {key: "7br", text: "Jekui", value: "7"},
              {key: "8br", text: "Moravia", value: "8"},
              {key: "9br", text: "Namaldí", value: "9"},
              {key: "10br", text: "Pacuare Arriba", value: "10"},
              {key: "11br", text: "Paso Marcos", value: "11"},
              {key: "12br", text: "Playa Hermosa", value: "12"},
              {key: "13br", text: "Porvenir", value: "13"},
              {key: "14br", text: "Quetzal", value: "14"},
              {key: "15br", text: "Raíz De Hule", value: "15"},
              {key: "16br", text: "Río Blanco", value: "16"},
              {key: "17br", text: "Santubal", value: "17"},
              {key: "18br", text: "Surí", value: "18"},
              {key: "19br", text: "Tsipiri (platanillo)", value: "19"},
              {key: "20br", text: "Vereh", value: "20"}]}]},
      {key: "6cn", text: "Alvarado", value: "6", distritos: [
          {key: "1ds", text: "Pacayas", value: "1", barrios: [
              {key: "1br", text: "Pacayas Centro", value: "1"},
              {key: "2br", text: "Lourdes", value: "2"},
              {key: "3br", text: "Patalillo", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Buenos Aires", value: "5"},
              {key: "6br", text: "Charcalillos", value: "6"},
              {key: "7br", text: "Encierrillo", value: "7"},
              {key: "8br", text: "Los Pinos (coliblanco)", value: "8"},
              {key: "9br", text: "Llano Grande", value: "9"},
              {key: "10br", text: "Pascón", value: "10"},
              {key: "11br", text: "Pastora", value: "11"},
              {key: "12br", text: "Plantón", value: "12"},
              {key: "13br", text: "San Martín (irazú Sur)", value: "13"},
              {key: "14br", text: "San Rafael De Irazú", value: "14"}]},
          {key: "2ds", text: "Cervantes", value: "2", barrios: [
              {key: "1br", text: "Cervantes Centro", value: "1"},
              {key: "2br", text: "Bajo Malanga", value: "2"},
              {key: "3br", text: "Aguas (parte)", value: "3"},
              {key: "4br", text: "Bajo Solano", value: "4"},
              {key: "5br", text: "Ciudad Del Cielo", value: "5"},
              {key: "6br", text: "Descanso", value: "6"},
              {key: "7br", text: "El Alto", value: "7"},
              {key: "8br", text: "Mata De Guineo", value: "8"},
              {key: "9br", text: "Monticel", value: "9"}]},
          {key: "3ds", text: "Capellades", value: "3", barrios: [
              {key: "1br", text: "Capellades Centro", value: "1"},
              {key: "2br", text: "Bajo Abarca", value: "2"},
              {key: "3br", text: "Lourdes (callejón)", value: "3"},
              {key: "4br", text: "Coliblanco", value: "4"},
              {key: "5br", text: "Santa Teresa", value: "5"}]}]},
      {key: "7cn", text: "Oreamuno", value: "7", distritos: [
          {key: "1ds", text: "San Rafael", value: "1", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "3br", text: "Alto Cerrillos (corazón De Jesús)", value: "3"},
              {key: "4br", text: "Artavia", value: "4"},
              {key: "5br", text: "Barrial", value: "5"},
              {key: "6br", text: "Bosque", value: "6"},
              {key: "7br", text: "Breñas", value: "7"},
              {key: "8br", text: "Caballo Blanco (parte)", value: "8"},
              {key: "9br", text: "Chircagre", value: "9"},
              {key: "10br", text: "Cuesta Chinchilla", value: "10"},
              {key: "11br", text: "Flores", value: "11"},
              {key: "12br", text: "Gamboa", value: "12"},
              {key: "13br", text: "José Jesús Méndez", value: "13"},
              {key: "14br", text: "Juan Pablo II", value: "14"},
              {key: "15br", text: "Llano", value: "15"},
              {key: "16br", text: "Monseñor Sanabria", value: "16"},
              {key: "17br", text: "Sagrada Familia", value: "17"}]},
          {key: "2ds", text: "Cot", value: "2", barrios: [
              {key: "1br", text: "Cot Centro", value: "1"},
              {key: "2br", text: "Mata De Mora", value: "2"},
              {key: "3br", text: "Páez (parte)", value: "3"},
              {key: "4br", text: "Paso Ancho", value: "4"},
              {key: "5br", text: "San Cayetano", value: "5"}]},
          {key: "3ds", text: "Potrero Cerrado", value: "3", barrios: [
              {key: "1br", text: "Potrero Cerrado Centro", value: "1"},
              {key: "2br", text: "Maya", value: "2"},
              {key: "3br", text: "Cruce", value: "3"},
              {key: "4br", text: "Pisco", value: "4"},
              {key: "5br", text: "Sanabria", value: "5"},
              {key: "6br", text: "San Juan De Chicuá", value: "6"}]},
          {key: "4ds", text: "Cipreses", value: "4", barrios: [
              {key: "1br", text: "Cipreses Centro", value: "1"},
              {key: "2br", text: "Aguas (parte)", value: "2"},
              {key: "3br", text: "Barrionuevo", value: "3"},
              {key: "4br", text: "Boquerón", value: "4"},
              {key: "5br", text: "Capira", value: "5"},
              {key: "6br", text: "Oratorio", value: "6"}]},
          {key: "5ds", text: "Santa Rosa", value: "5", barrios: [
              {key: "1br", text: "Santa Rosa Centro", value: "1"},
              {key: "2br", text: "Cuesta Quemados", value: "2"},
              {key: "3br", text: "Pasquí", value: "3"},
              {key: "4br", text: "Platanillal", value: "4"},
              {key: "5br", text: "San Gerardo", value: "5"},
              {key: "6br", text: "San Juan", value: "6"},
              {key: "7br", text: "San Martín", value: "7"},
              {key: "8br", text: "San Pablo", value: "8"},
              {key: "9br", text: "Titoral", value: "9"}]}]},
      {key: "8cn", text: "El Guarco", value: "8", distritos: [
          {key: "1ds", text: "El Tejar", value: "1", barrios: [
              {key: "1br", text: "El Tejar Centro", value: "1"},
              {key: "2br", text: "Asunción", value: "2"},
              {key: "3br", text: "Barahona", value: "3"},
              {key: "4br", text: "Barrio Nuevo", value: "4"},
              {key: "5br", text: "Colonia", value: "5"},
              {key: "6br", text: "Chavarría", value: "6"},
              {key: "7br", text: "Sabana", value: "7"},
              {key: "8br", text: "Sabana Grande", value: "8"},
              {key: "9br", text: "San Rafael", value: "9"},
              {key: "10br", text: "Santa Gertrudis", value: "10"},
              {key: "11br", text: "Sauces", value: "11"},
              {key: "12br", text: "Silo", value: "12"},
              {key: "13br", text: "Viento Fresco", value: "13"}]},
          {key: "2ds", text: "San Isidro", value: "2", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Guatuso", value: "2"},
              {key: "3br", text: "Higuito", value: "3"},
              {key: "4br", text: "Potrerillos", value: "4"},
              {key: "5br", text: "Altamiradas", value: "5"},
              {key: "6br", text: "Alto San Francisco", value: "6"},
              {key: "7br", text: "Bajo Gloria", value: "7"},
              {key: "8br", text: "Bajos De León", value: "8"},
              {key: "9br", text: "Barrancas (parte)", value: "9"},
              {key: "10br", text: "Cangreja", value: "10"},
              {key: "11br", text: "Cañón (parte)", value: "11"},
              {key: "12br", text: "Casablanca", value: "12"},
              {key: "13br", text: "Casamata", value: "13"},
              {key: "14br", text: "Cascajal", value: "14"},
              {key: "15br", text: "Conventillo", value: "15"},
              {key: "16br", text: "Cruces", value: "16"},
              {key: "17br", text: "Cruz", value: "17"},
              {key: "18br", text: "Chonta (parte)", value: "18"},
              {key: "19br", text: "Damita", value: "19"},
              {key: "20br", text: "Dos Amigos", value: "20"},
              {key: "21br", text: "Empalme (parte)", value: "21"},
              {key: "22br", text: "Esperanza", value: "22"},
              {key: "23br", text: "Estrella", value: "23"},
              {key: "24br", text: "Guayabal (parte)", value: "24"},
              {key: "25br", text: "La Luchita", value: "25"},
              {key: "26br", text: "La Paz", value: "26"},
              {key: "27br", text: "Macho Gaff", value: "27"},
              {key: "28br", text: "Montserrat", value: "28"},
              {key: "29br", text: "Ojo De Agua (parte)", value: "29"},
              {key: "30br", text: "Palmital", value: "30"},
              {key: "31br", text: "Palmital Sur", value: "31"},
              {key: "32br", text: "Palo Verde", value: "32"},
              {key: "33br", text: "Paso Macho (parte)", value: "33"},
              {key: "34br", text: "Purires (parte)", value: "34"},
              {key: "35br", text: "Salsipuedes (parte)", value: "35"},
              {key: "36br", text: "San Cayetano", value: "36"},
              {key: "37br", text: "Surtubal", value: "37"},
              {key: "38br", text: "Tres De Junio", value: "38"},
              {key: "39br", text: "Vara Del Roble", value: "39"}]},
          {key: "3ds", text: "Tobosi", value: "3", barrios: [
              {key: "1br", text: "Tobosi Centro", value: "1"},
              {key: "2br", text: "Achiotillo", value: "2"},
              {key: "3br", text: "Barrancas (parte)", value: "3"},
              {key: "4br", text: "Bodocal", value: "4"},
              {key: "5br", text: "Garita (parte)", value: "5"},
              {key: "6br", text: "Purires (parte)", value: "6"},
              {key: "7br", text: "Tablón", value: "7"}]},
          {key: "4ds", text: "Patio De Agua", value: "4", barrios: [
              {key: "1br", text: "Patio De Agua Centro", value: "1"},
              {key: "2br", text: "Bajo Zopilote", value: "2"},
              {key: "3br", text: "Caragral", value: "3"},
              {key: "4br", text: "Común", value: "4"}]}]}]},
  {key: "4pr", text: "Heredia", value: "4", cantones: [
      {key: "1cn", text: "Heredia", value: "1", distritos: [
          {key: "1ds", text: "Heredia", value: "1", barrios: [
              {key: "1br", text: "Heredia Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Carmen", value: "3"},
              {key: "4br", text: "Corazón De Jesús", value: "4"},
              {key: "5br", text: "Chino", value: "5"},
              {key: "6br", text: "Estadio", value: "6"},
              {key: "7br", text: "Fátima", value: "7"},
              {key: "8br", text: "Guayabal", value: "8"},
              {key: "9br", text: "La India", value: "9"},
              {key: "10br", text: "Lourdes", value: "10"},
              {key: "11br", text: "Hospital", value: "11"},
              {key: "12br", text: "María Auxiliadora (parte)", value: "12"},
              {key: "13br", text: "Oriente", value: "13"},
              {key: "14br", text: "Pirro", value: "14"},
              {key: "15br", text: "Puebla (parte)", value: "15"},
              {key: "16br", text: "Rancho Chico", value: "16"},
              {key: "17br", text: "San Fernando", value: "17"},
              {key: "18br", text: "San Vicente", value: "18"}]},
          {key: "2ds", text: "Mercedes", value: "2", barrios: [
              {key: "1br", text: "Mercedes Sur", value: "1"},
              {key: "2br", text: "Burío", value: "2"},
              {key: "3br", text: "Carbonal", value: "3"},
              {key: "4br", text: "Cubujuquí", value: "4"},
              {key: "5br", text: "España", value: "5"},
              {key: "6br", text: "Labrador", value: "6"},
              {key: "7br", text: "Mercedes Sur", value: "7"},
              {key: "8br", text: "Monte Bello", value: "8"},
              {key: "9br", text: "San Jorge", value: "9"},
              {key: "10br", text: "Santa Inés", value: "10"}]},
          {key: "3ds", text: "San Francisco", value: "3", barrios: [
              {key: "1br", text: "San Francisco Centro", value: "1"},
              {key: "2br", text: "Aries", value: "2"},
              {key: "3br", text: "Aurora (parte)", value: "3"},
              {key: "4br", text: "Bernardo Benavides", value: "4"},
              {key: "5br", text: "Chucos", value: "5"},
              {key: "6br", text: "El Cristo (parte)", value: "6"},
              {key: "7br", text: "Esmeralda", value: "7"},
              {key: "8br", text: "Esperanza", value: "8"},
              {key: "9br", text: "Granada", value: "9"},
              {key: "10br", text: "Gran Samaria", value: "10"},
              {key: "11br", text: "Guararí", value: "11"},
              {key: "12br", text: "Lagos", value: "12"},
              {key: "13br", text: "Malinches", value: "13"},
              {key: "14br", text: "Mayorga (parte)", value: "14"},
              {key: "15br", text: "Nísperos 3", value: "15"},
              {key: "16br", text: "Palma", value: "16"},
              {key: "17br", text: "Trébol", value: "17"},
              {key: "18br", text: "Tropical", value: "18"}]},
          {key: "4ds", text: "Ulloa", value: "4", barrios: [
              {key: "1br", text: "Barreal", value: "1"},
              {key: "2br", text: "Arcos", value: "2"},
              {key: "3br", text: "Aurora (parte)", value: "3"},
              {key: "4br", text: "Bajos Del Virilla (san Rafael)", value: "4"},
              {key: "5br", text: "Cariari (parte)", value: "5"},
              {key: "6br", text: "Carpintera", value: "6"},
              {key: "7br", text: "El Cristo (parte)", value: "7"},
              {key: "8br", text: "Lagunilla", value: "8"},
              {key: "9br", text: "Linda Del Norte", value: "9"},
              {key: "10br", text: "Mayorga (parte)", value: "10"},
              {key: "11br", text: "Monterrey", value: "11"},
              {key: "12br", text: "Pitahaya", value: "12"},
              {key: "13br", text: "Pueblo Nuevo", value: "13"},
              {key: "14br", text: "Valencia (parte)", value: "14"},
              {key: "15br", text: "Vista Nosara", value: "15"}]},
          {key: "5ds", text: "Vara Blanca", value: "5", barrios: [
              {key: "1br", text: "Vara Blanca Centro", value: "1"},
              {key: "2br", text: "Jesús María", value: "2"},
              {key: "3br", text: "Legua", value: "3"},
              {key: "4br", text: "Legua De Barva", value: "4"},
              {key: "5br", text: "Montaña Azul", value: "5"},
              {key: "6br", text: "San Rafael", value: "6"},
              {key: "7br", text: "Virgen Del Socorro (parte)", value: "7"}]}]},
      {key: "2cn", text: "Barva", value: "2", distritos: [
          {key: "1ds", text: "Barva", value: "1", barrios: [
              {key: "1br", text: "Barva Centro", value: "1"},
              {key: "2br", text: "Don Abraham", value: "2"},
              {key: "3br", text: "San Bartolomé", value: "3"}]},
          {key: "2ds", text: "San Pedro", value: "2", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Bosque", value: "2"},
              {key: "3br", text: "Calle Amada", value: "3"},
              {key: "4br", text: "Calle Los Naranjos", value: "4"},
              {key: "5br", text: "Espinos", value: "5"},
              {key: "6br", text: "Máquina", value: "6"},
              {key: "7br", text: "Mirador", value: "7"},
              {key: "8br", text: "Morazán", value: "8"},
              {key: "9br", text: "Puente Salas", value: "9"},
              {key: "10br", text: "Segura", value: "10"},
              {key: "11br", text: "Vista Llana", value: "11"}]},
          {key: "3ds", text: "San Pablo", value: "3", barrios: [
              {key: "1br", text: "San Pablo Centro", value: "1"},
              {key: "2br", text: "Cementerio", value: "2"},
              {key: "3br", text: "Ibís", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"}]},
          {key: "4ds", text: "San Roque", value: "4", barrios: [
              {key: "1br", text: "San Roque Centro", value: "1"},
              {key: "2br", text: "Bello Higuerón", value: "2"},
              {key: "3br", text: "Los Luises", value: "3"},
              {key: "4br", text: "Plantación", value: "4"},
              {key: "5br", text: "Pórtico", value: "5"}]},
          {key: "5ds", text: "Santa Lucía", value: "5", barrios: [
              {key: "1br", text: "Santa Lucía Centro", value: "1"},
              {key: "2br", text: "Doña Iris", value: "2"},
              {key: "3br", text: "Getsemaní (parte)", value: "3"},
              {key: "4br", text: "Jardines Del Beneficio", value: "4"},
              {key: "5br", text: "Palmar (parte)", value: "5"},
              {key: "6br", text: "Paso Viga (parte)", value: "6"},
              {key: "7br", text: "Pedregal", value: "7"}]},
          {key: "6ds", text: "San José De La Montaña", value: "6", barrios: [
              {key: "1br", text: "San José De La Montaña Centro", value: "1"},
              {key: "2br", text: "Cipresal", value: "2"},
              {key: "3br", text: "Doña Blanca", value: "3"},
              {key: "4br", text: "Doña Elena", value: "4"},
              {key: "5br", text: "El Collado", value: "5"},
              {key: "6br", text: "Gallito", value: "6"},
              {key: "7br", text: "Higuerón", value: "7"},
              {key: "8br", text: "Huacalillo", value: "8"},
              {key: "9br", text: "Meseta", value: "9"},
              {key: "10br", text: "Monte Alto", value: "10"},
              {key: "11br", text: "Paso Llano", value: "11"},
              {key: "12br", text: "Plan De Birrí", value: "12"},
              {key: "13br", text: "Porrosatí (paso Llano)", value: "13"},
              {key: "14br", text: "Roblealto", value: "14"},
              {key: "15br", text: "Sacramento", value: "15"},
              {key: "16br", text: "San Martín", value: "16"},
              {key: "17br", text: "San Miguel", value: "17"},
              {key: "18br", text: "Santa Clara", value: "18"},
              {key: "19br", text: "Zapata", value: "19"}]}]},
      {key: "3cn", text: "Santo Domingo", value: "3", distritos: [
          {key: "1ds", text: "Santo Domingo", value: "1", barrios: [
              {key: "1br", text: "Santo Domingo Centro", value: "1"}]},
          {key: "2ds", text: "San Vicente", value: "2", barrios: [
              {key: "1br", text: "San Vicente Centro", value: "1"},
              {key: "2br", text: "Barro De Olla", value: "2"},
              {key: "3br", text: "Calle Don Pedro", value: "3"},
              {key: "4br", text: "Quintana", value: "4"},
              {key: "5br", text: "Yurusti", value: "5"}]},
          {key: "3ds", text: "San Miguel", value: "3", barrios: [
              {key: "1br", text: "San Miguel Sur", value: "1"},
              {key: "2br", text: "Canoa (parte)", value: "2"},
              {key: "3br", text: "Castilla (el Carmen)", value: "3"},
              {key: "4br", text: "Cuesta Rojas", value: "4"},
              {key: "5br", text: "Montero", value: "5"},
              {key: "6br", text: "Socorro", value: "6"},
              {key: "7br", text: "Villa Rossi", value: "7"}]},
          {key: "4ds", text: "Paracito", value: "4", barrios: [
              {key: "1br", text: "Paracito Centro", value: "1"},
              {key: "2br", text: "Represa", value: "2"}]},
          {key: "5ds", text: "Santo Tomás", value: "5", barrios: [
              {key: "1br", text: "Santo Tomás Centro", value: "1"},
              {key: "2br", text: "Barquero", value: "2"},
              {key: "3br", text: "Higinia", value: "3"},
              {key: "4br", text: "Pacífica", value: "4"}]},
          {key: "6ds", text: "Santa Rosa", value: "6", barrios: [
              {key: "1br", text: "Santa Rosa Centro", value: "1"},
              {key: "2br", text: "La Cooperativa", value: "2"},
              {key: "3br", text: "Pedro León", value: "3"},
              {key: "4br", text: "Primero De Mayo", value: "4"},
              {key: "5br", text: "Quisqueya", value: "5"},
              {key: "6br", text: "Rinconada", value: "6"},
              {key: "7br", text: "Valencia (parte)", value: "7"}]},
          {key: "7ds", text: "Tures", value: "7", barrios: [
              {key: "1br", text: "Ángeles", value: "1"},
              {key: "2br", text: "Calle Vieja", value: "2"},
              {key: "3br", text: "Lourdes", value: "3"},
              {key: "4br", text: "Quebradas (parte)", value: "4"}]},
          {key: "8ds", text: "Pará", value: "8", barrios: [
              {key: "1br", text: "San Luis", value: "1"},
              {key: "2br", text: "Caballero", value: "2"},
              {key: "3br", text: "Canoa (parte)", value: "3"}]}]},
      {key: "4cn", text: "Santa Bárbara", value: "4", distritos: [
          {key: "1ds", text: "Santa Bárbara", value: "1", barrios: [
              {key: "1br", text: "Santa Bárbara Centro", value: "1"},
              {key: "2br", text: "Trompezón", value: "2"}]},
          {key: "2ds", text: "San Pedro", value: "2", barrios: [
              {key: "1br", text: "San Pedro Centro", value: "1"},
              {key: "2br", text: "Betania", value: "2"},
              {key: "3br", text: "Rosales (parte)", value: "3"}]},
          {key: "3ds", text: "San Juan", value: "3", barrios: [
              {key: "1br", text: "San Juan Abajo", value: "1"},
              {key: "2br", text: "Cinco Esquinas", value: "2"},
              {key: "3br", text: "Lotes Moreira", value: "3"},
              {key: "4br", text: "San Juan Arriba", value: "4"}]},
          {key: "4ds", text: "Jesús", value: "4", barrios: [
              {key: "1br", text: "Jesús Centro", value: "1"},
              {key: "2br", text: "Altagracia", value: "2"},
              {key: "3br", text: "Birrí", value: "3"},
              {key: "4br", text: "Calle Quirós (parte)", value: "4"},
              {key: "5br", text: "Catalina", value: "5"},
              {key: "6br", text: "Común (parte)", value: "6"},
              {key: "7br", text: "Cuesta Colorada", value: "7"},
              {key: "8br", text: "Guachipelines", value: "8"},
              {key: "9br", text: "Guaracha", value: "9"},
              {key: "10br", text: "Ulises", value: "10"}]},
          {key: "5ds", text: "Santo Domingo", value: "5", barrios: [
              {key: "1br", text: "Roble", value: "1"},
              {key: "2br", text: "Amapola", value: "2"},
              {key: "3br", text: "Cartagos", value: "3"},
              {key: "4br", text: "Chagüite", value: "4"},
              {key: "5br", text: "Giralda", value: "5"},
              {key: "6br", text: "Guararí", value: "6"},
              {key: "7br", text: "Tranquera", value: "7"}]},
          {key: "6ds", text: "Purabá", value: "6", barrios: [
              {key: "1br", text: "Setilla", value: "1"},
              {key: "2br", text: "Marías", value: "2"},
              {key: "3br", text: "Purabá", value: "3"},
              {key: "4br", text: "San Bosco", value: "4"},
              {key: "5br", text: "Calle Quirós (parte)", value: "5"},
              {key: "6br", text: "Común (parte)", value: "6"},
              {key: "7br", text: "Lajas", value: "7"}]}]},
      {key: "5cn", text: "San Rafael", value: "5", distritos: [
          {key: "1ds", text: "San Rafael", value: "1", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Amistad", value: "2"},
              {key: "3br", text: "Matasano (parte)", value: "3"},
              {key: "4br", text: "Paso Viga (parte)", value: "4"}]},
          {key: "2ds", text: "San Josecito", value: "2", barrios: [
              {key: "1br", text: "San Josecito Centro", value: "1"},
              {key: "2br", text: "Bajo Molinos", value: "2"},
              {key: "3br", text: "Joya", value: "3"},
              {key: "4br", text: "Matasano (parte)", value: "4"},
              {key: "5br", text: "Peralta", value: "5"},
              {key: "6br", text: "Santísima Trinidad", value: "6"}]},
          {key: "3ds", text: "Santiago", value: "3", barrios: [
              {key: "1br", text: "Santiago Centro", value: "1"},
              {key: "2br", text: "Jardines De Roma", value: "2"},
              {key: "3br", text: "Jardines Universitarios", value: "3"},
              {key: "4br", text: "Suiza", value: "4"}]},
          {key: "4ds", text: "Ángeles", value: "4", barrios: [
              {key: "1br", text: "Ángeles Centro", value: "1"},
              {key: "2br", text: "Calle Hernández (parte)", value: "2"},
              {key: "3br", text: "Castillo", value: "3"},
              {key: "4br", text: "Cerro Redondo", value: "4"},
              {key: "5br", text: "Getsemaní (parte)", value: "5"},
              {key: "6br", text: "Joaquina", value: "6"},
              {key: "7br", text: "Lobos", value: "7"},
              {key: "8br", text: "Montecito", value: "8"},
              {key: "9br", text: "Palmar (parte)", value: "9"},
              {key: "10br", text: "Paso Viga (parte)", value: "10"},
              {key: "11br", text: "Puente Piedra (parte)", value: "11"},
              {key: "12br", text: "Quintanar De La Sierra", value: "12"},
              {key: "13br", text: "Saca", value: "13"},
              {key: "14br", text: "Uvita", value: "14"}]},
          {key: "5ds", text: "Concepción", value: "5", barrios: [
              {key: "1br", text: "Concepción Centro", value: "1"},
              {key: "2br", text: "Anonos", value: "2"},
              {key: "3br", text: "Burial", value: "3"},
              {key: "4br", text: "Calle Chávez (parte)", value: "4"},
              {key: "5br", text: "Calle Hernández (parte)", value: "5"},
              {key: "6br", text: "Charquillo", value: "6"},
              {key: "7br", text: "Ciénagas", value: "7"},
              {key: "8br", text: "Mora", value: "8"},
              {key: "9br", text: "Palenque", value: "9"},
              {key: "10br", text: "Pilas", value: "10"},
              {key: "11br", text: "Puente Piedra (parte)", value: "11"},
              {key: "12br", text: "Tierra Blanca (parte)", value: "12"},
              {key: "13br", text: "Turú", value: "13"}]}]},
      {key: "6cn", text: "San Isidro", value: "6", distritos: [
          {key: "1ds", text: "San Isidro", value: "1", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Calle Cementerio", value: "2"},
              {key: "3br", text: "Colonia Isidreña", value: "3"},
              {key: "4br", text: "Cooperativa", value: "4"},
              {key: "5br", text: "Cristo Rey", value: "5"},
              {key: "6br", text: "El Volador", value: "6"},
              {key: "7br", text: "Villaval", value: "7"}]},
          {key: "2ds", text: "San José", value: "2", barrios: [
              {key: "1br", text: "San Josecito", value: "1"},
              {key: "2br", text: "Santa Cruz", value: "2"},
              {key: "3br", text: "El Arroyo", value: "3"},
              {key: "4br", text: "Huacalillos", value: "4"},
              {key: "5br", text: "Santa Cecilia (parte)", value: "5"},
              {key: "6br", text: "Santa Elena", value: "6"},
              {key: "7br", text: "Trapiche", value: "7"},
              {key: "8br", text: "Yerbabuena", value: "8"}]},
          {key: "3ds", text: "Concepción", value: "3", barrios: [
              {key: "1br", text: "Concepción Centro", value: "1"},
              {key: "2br", text: "Alhajas", value: "2"},
              {key: "3br", text: "Calle Caricias", value: "3"},
              {key: "4br", text: "Calle Chávez (parte)", value: "4"},
              {key: "5br", text: "Santa Cecilia", value: "5"}]},
          {key: "4ds", text: "San Franciso", value: "4", barrios: [
              {key: "1br", text: "San Franciso Centro", value: "1"},
              {key: "2br", text: "Aguacate", value: "2"},
              {key: "3br", text: "Astillero", value: "3"},
              {key: "4br", text: "Quebradas (parte)", value: "4"},
              {key: "5br", text: "Rinconada", value: "5"},
              {key: "6br", text: "Tierra Blanca (parte)", value: "6"},
              {key: "7br", text: "Vallevistar", value: "7"},
              {key: "8br", text: "Viento Fresco", value: "8"}]}]},
      {key: "7cn", text: "Belén", value: "7", distritos: [
          {key: "1ds", text: "San Antonio", value: "1", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Chompipes (parte)", value: "2"},
              {key: "3br", text: "Escobal", value: "3"},
              {key: "4br", text: "Labores (parte)", value: "4"},
              {key: "5br", text: "San Vicente", value: "5"},
              {key: "6br", text: "Zaiquí", value: "6"}]},
          {key: "2ds", text: "La Ribera", value: "2", barrios: [
              {key: "1br", text: "La Ribera Centro", value: "1"},
              {key: "2br", text: "Cristo Rey (parte)", value: "2"},
              {key: "3br", text: "Echeverría (parte)", value: "3"},
              {key: "4br", text: "Fuente", value: "4"},
              {key: "5br", text: "Labores (parte)", value: "5"},
              {key: "6br", text: "Vista Linda", value: "6"}]},
          {key: "3ds", text: "La Asuncion", value: "3", barrios: [
              {key: "1br", text: "La Asuncion Centro", value: "1"},
              {key: "2br", text: "Arbolito", value: "2"},
              {key: "3br", text: "Bonanza", value: "3"},
              {key: "4br", text: "Bosques De Doña Rosa", value: "4"},
              {key: "5br", text: "Cariari (parte)", value: "5"},
              {key: "6br", text: "Chompipes (parte)", value: "6"},
              {key: "7br", text: "Cristo Rey (parte)", value: "7"}]}]},
      {key: "8cn", text: "Flores", value: "8", distritos: [
          {key: "1ds", text: "San Joaquín", value: "1", barrios: [
              {key: "1br", text: "San Joaquín Centro", value: "1"},
              {key: "2br", text: "Campanario", value: "2"},
              {key: "3br", text: "Joaquineños", value: "3"},
              {key: "4br", text: "Luisiana", value: "4"},
              {key: "5br", text: "Santa Marta", value: "5"},
              {key: "6br", text: "Trinidad", value: "6"},
              {key: "7br", text: "Villa Lico", value: "7"},
              {key: "8br", text: "Villa María", value: "8"}]},
          {key: "2ds", text: "Barrantes", value: "2", barrios: [
              {key: "1br", text: "San Lorenzo", value: "1"},
              {key: "2br", text: "Barrantes", value: "2"},
              {key: "3br", text: "Ugalde", value: "3"}]},
          {key: "3ds", text: "Llorente", value: "3", barrios: [
              {key: "1br", text: "Llorente Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Año 2000", value: "3"},
              {key: "4br", text: "Cristo Rey (parte)", value: "4"},
              {key: "5br", text: "Echeverría (parte)", value: "5"},
              {key: "6br", text: "Geranios", value: "6"},
              {key: "7br", text: "Las Hadas", value: "7"},
              {key: "8br", text: "Santa Elena", value: "8"},
              {key: "9br", text: "Siglo Veintiuno", value: "9"}]}]},
      {key: "9cn", text: "San Pablo", value: "9", distritos: [
          {key: "1ds", text: "San Pablo", value: "1", barrios: [
              {key: "1br", text: "San Pablo Centro", value: "1"},
              {key: "2br", text: "Acapulco", value: "2"},
              {key: "3br", text: "Amada", value: "3"},
              {key: "4br", text: "Asovigui", value: "4"},
              {key: "5br", text: "Colonial", value: "5"},
              {key: "6br", text: "Cruces", value: "6"},
              {key: "7br", text: "Doña Nina", value: "7"},
              {key: "8br", text: "Irazú", value: "8"},
              {key: "9br", text: "Irma", value: "9"},
              {key: "10br", text: "July", value: "10"},
              {key: "11br", text: "María Auxiliadora (parte)", value: "11"},
              {key: "12br", text: "Nueva Jerusalén", value: "12"},
              {key: "13br", text: "Pastoras", value: "13"},
              {key: "14br", text: "Puebla (parte)", value: "14"},
              {key: "15br", text: "Santa Isabel", value: "15"},
              {key: "16br", text: "Villa Cortés", value: "16"},
              {key: "17br", text: "Villa Dolores", value: "17"},
              {key: "18br", text: "Villa Quintana", value: "18"},
              {key: "19br", text: "Uriche", value: "19"},
              {key: "20br", text: "Uruca", value: "20"}]},
          {key: "2ds", text: "Rincon De Sabanilla", value: "2", barrios: [
              {key: "1br", text: "Miraflores", value: "1"},
              {key: "2br", text: "Corobicí", value: "2"},
              {key: "3br", text: "Estrella", value: "3"},
              {key: "4br", text: "Rincón De Ricardo", value: "4"},
              {key: "5br", text: "Santa Fe", value: "5"}]}]},
      {key: "10cn", text: "Sarapiquí", value: "10", distritos: [
          {key: "1ds", text: "Puerto Viejo", value: "1", barrios: [
              {key: "1br", text: "Puerto Viejo Centro", value: "1"},
              {key: "2br", text: "Colina", value: "2"},
              {key: "3br", text: "Esperanza", value: "3"},
              {key: "4br", text: "Jardín", value: "4"},
              {key: "5br", text: "Loma Linda", value: "5"},
              {key: "6br", text: "Progreso", value: "6"},
              {key: "7br", text: "Achiote", value: "7"},
              {key: "8br", text: "Ahogados", value: "8"},
              {key: "9br", text: "Arbolitos (parte)", value: "9"},
              {key: "10br", text: "Arrepentidos", value: "10"},
              {key: "11br", text: "Boca Ceiba", value: "11"},
              {key: "12br", text: "Boca Río Sucio", value: "12"},
              {key: "13br", text: "Bun", value: "13"},
              {key: "14br", text: "Cabezas", value: "14"},
              {key: "15br", text: "Canfín", value: "15"},
              {key: "16br", text: "Caño Negro", value: "16"},
              {key: "17br", text: "Cerro Negro (parte)", value: "17"},
              {key: "18br", text: "Colonia San José", value: "18"},
              {key: "19br", text: "Coyol", value: "19"},
              {key: "20br", text: "Cristo Rey", value: "20"},
              {key: "21br", text: "Chilamate", value: "21"},
              {key: "22br", text: "El Progreso", value: "22"},
              {key: "23br", text: "Esperanza", value: "23"},
              {key: "24br", text: "Estrellales", value: "24"},
              {key: "25br", text: "Guaria", value: "25"},
              {key: "26br", text: "Jardín", value: "26"},
              {key: "27br", text: "Jormo", value: "27"},
              {key: "28br", text: "Las Marías", value: "28"},
              {key: "29br", text: "Las Orquídeas", value: "29"},
              {key: "30br", text: "Los Lirios", value: "30"},
              {key: "31br", text: "Malinche", value: "31"},
              {key: "32br", text: "Media Vuelta", value: "32"},
              {key: "33br", text: "Medias (parte)", value: "33"},
              {key: "34br", text: "Muelle", value: "34"},
              {key: "35br", text: "Naranjal", value: "35"},
              {key: "36br", text: "Nogal", value: "36"},
              {key: "37br", text: "Pavas", value: "37"},
              {key: "38br", text: "Rojomaca", value: "38"},
              {key: "39br", text: "San José", value: "39"},
              {key: "40br", text: "San Julián", value: "40"},
              {key: "41br", text: "Tres Rosales", value: "41"},
              {key: "42br", text: "Vega De Sardinal (parte)", value: "42"},
              {key: "43br", text: "Zapote", value: "43"}]},
          {key: "2ds", text: "La Virgen", value: "2", barrios: [
              {key: "1br", text: "La Virgen Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Arbolitos (parte)", value: "3"},
              {key: "4br", text: "Bajos De Chilamate", value: "4"},
              {key: "5br", text: "Boca Sardinal", value: "5"},
              {key: "6br", text: "Bosque", value: "6"},
              {key: "7br", text: "Búfalo", value: "7"},
              {key: "8br", text: "Delicias", value: "8"},
              {key: "9br", text: "El Uno", value: "9"},
              {key: "10br", text: "Esquipulas", value: "10"},
              {key: "11br", text: "Las Palmitas", value: "11"},
              {key: "12br", text: "Laquí", value: "12"},
              {key: "13br", text: "Lomas", value: "13"},
              {key: "14br", text: "Llano Grande", value: "14"},
              {key: "15br", text: "Magsaysay", value: "15"},
              {key: "16br", text: "Masaya", value: "16"},
              {key: "17br", text: "Medias (parte)", value: "17"},
              {key: "18br", text: "Pangola", value: "18"},
              {key: "19br", text: "Pozo Azul", value: "19"},
              {key: "20br", text: "Quebrada Grande", value: "20"},
              {key: "21br", text: "Río Magdaleno", value: "21"},
              {key: "22br", text: "Roble", value: "22"},
              {key: "23br", text: "San Gerardo (parte)", value: "23"},
              {key: "24br", text: "San Isidro", value: "24"},
              {key: "25br", text: "San José Sur", value: "25"},
              {key: "26br", text: "San Ramón", value: "26"},
              {key: "27br", text: "La Delia", value: "27"},
              {key: "28br", text: "Sardinal", value: "28"},
              {key: "29br", text: "Tirimbina", value: "29"},
              {key: "30br", text: "Vega De Sardinal (parte)", value: "30"},
              {key: "31br", text: "Venados", value: "31"}]},
          {key: "3ds", text: "Las Horquetas", value: "3", barrios: [
              {key: "1br", text: "Las Horquetas Centro", value: "1"},
              {key: "2br", text: "Bambuzal", value: "2"},
              {key: "3br", text: "Cerro Negro (parte)", value: "3"},
              {key: "4br", text: "Colonia Bambú", value: "4"},
              {key: "5br", text: "Colonia Colegio", value: "5"},
              {key: "6br", text: "Colonia Esperanza", value: "6"},
              {key: "7br", text: "Colonia Huetar", value: "7"},
              {key: "8br", text: "Colonia Nazareth", value: "8"},
              {key: "9br", text: "Colonia Victoria", value: "9"},
              {key: "10br", text: "Colonia Villalobos", value: "10"},
              {key: "11br", text: "Cubujuquí", value: "11"},
              {key: "12br", text: "Chiripa", value: "12"},
              {key: "13br", text: "Fátima", value: "13"},
              {key: "14br", text: "Flaminia", value: "14"},
              {key: "15br", text: "Finca Uno", value: "15"},
              {key: "16br", text: "Finca Dos", value: "16"},
              {key: "17br", text: "Finca Tres", value: "17"},
              {key: "18br", text: "Finca Cinco", value: "18"},
              {key: "19br", text: "Finca Agua", value: "19"},
              {key: "20br", text: "Finca Zona Siete", value: "20"},
              {key: "21br", text: "Finca Zona Ocho", value: "21"},
              {key: "22br", text: "Finca Zona Diez", value: "22"},
              {key: "23br", text: "Finca Zona Once", value: "23"},
              {key: "24br", text: "Isla", value: "24"},
              {key: "25br", text: "Isla Grande", value: "25"},
              {key: "26br", text: "Isla Israel", value: "26"},
              {key: "27br", text: "La Conquista", value: "27"},
              {key: "28br", text: "La Chávez", value: "28"},
              {key: "29br", text: "La Vuelta", value: "29"},
              {key: "30br", text: "Rambla", value: "30"},
              {key: "31br", text: "Pedernales", value: "31"},
              {key: "32br", text: "Platanera", value: "32"},
              {key: "33br", text: "Río Frío", value: "33"},
              {key: "34br", text: "San Bernardino", value: "34"},
              {key: "35br", text: "San Luis", value: "35"},
              {key: "36br", text: "Santa Clara", value: "36"},
              {key: "37br", text: "Tapa Viento", value: "37"},
              {key: "38br", text: "Ticarí", value: "38"},
              {key: "39br", text: "Tigre", value: "39"},
              {key: "40br", text: "Villa Isabel", value: "40"},
              {key: "41br", text: "Villa Nueva", value: "41"}]},
          {key: "4ds", text: "Llanuras Del Gaspar", value: "4", barrios: [
              {key: "1br", text: "La Aldea", value: "1"},
              {key: "2br", text: "Caño San Luis", value: "2"},
              {key: "3br", text: "Chimurria", value: "3"},
              {key: "4br", text: "Chirriposito", value: "4"},
              {key: "5br", text: "Delta", value: "5"},
              {key: "6br", text: "Gaspar", value: "6"},
              {key: "7br", text: "Lagunilla", value: "7"},
              {key: "8br", text: "La Lucha", value: "8"},
              {key: "9br", text: "Tigra", value: "9"}]},
          {key: "5ds", text: "Cureña", value: "5", barrios: [
              {key: "1br", text: "Golfito", value: "1"},
              {key: "2br", text: "Caño Tambor", value: "2"},
              {key: "3br", text: "Copalchí", value: "3"},
              {key: "4br", text: "Cureña", value: "4"},
              {key: "5br", text: "Paloseco", value: "5"},
              {key: "6br", text: "Remolinito", value: "6"},
              {key: "7br", text: "Tambor", value: "7"},
              {key: "8br", text: "Tierrabuena", value: "8"},
              {key: "9br", text: "Trinidad", value: "9"},
              {key: "10br", text: "Unión Del Toro", value: "10"},
              {key: "11br", text: "Vuelta Cabo De Hornos", value: "11"}]}]}]},
  {key: "5pr", text: "Guanacaste", value: "5", cantones: [
      {key: "1cn", text: "Liberia", value: "1", distritos: [
          {key: "1ds", text: "Liberia", value: "1", barrios: [
              {key: "1br", text: "Liberia Centro", value: "1"},
              {key: "2br", text: "Alaska", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Arena", value: "4"},
              {key: "5br", text: "Buenos Aires", value: "5"},
              {key: "6br", text: "Capulín", value: "6"},
              {key: "7br", text: "Caraña", value: "7"},
              {key: "8br", text: "Cerros", value: "8"},
              {key: "9br", text: "Choricera", value: "9"},
              {key: "10br", text: "Chorotega", value: "10"},
              {key: "11br", text: "Condega", value: "11"},
              {key: "12br", text: "Corazón De Jesús", value: "12"},
              {key: "13br", text: "Curime", value: "13"},
              {key: "14br", text: "Gallera", value: "14"},
              {key: "15br", text: "Guaria", value: "15"},
              {key: "16br", text: "Isleta (parte)", value: "16"},
              {key: "17br", text: "Jícaro", value: "17"},
              {key: "18br", text: "Juanilama", value: "18"},
              {key: "19br", text: "La Carreta", value: "19"},
              {key: "20br", text: "Llano La Cruz", value: "20"},
              {key: "21br", text: "Mocho (santa Lucía)", value: "21"},
              {key: "22br", text: "Montañita", value: "22"},
              {key: "23br", text: "Moracia", value: "23"},
              {key: "24br", text: "Nazareth", value: "24"},
              {key: "25br", text: "Paso Tempisque (parte)", value: "25"},
              {key: "26br", text: "Pelón De La Bajura", value: "26"},
              {key: "27br", text: "Polvazales", value: "27"},
              {key: "28br", text: "Pueblo Nuevo", value: "28"},
              {key: "29br", text: "Roble De Sabana", value: "29"},
              {key: "30br", text: "Rodeito", value: "30"},
              {key: "31br", text: "Sabanero", value: "31"},
              {key: "32br", text: "Salto (parte)", value: "32"},
              {key: "33br", text: "San Benito", value: "33"},
              {key: "34br", text: "San Hernán", value: "34"},
              {key: "35br", text: "San Lucas", value: "35"},
              {key: "36br", text: "San Miguel", value: "36"},
              {key: "37br", text: "San Roque", value: "37"},
              {key: "38br", text: "Santa Ana", value: "38"},
              {key: "39br", text: "Sitio", value: "39"},
              {key: "40br", text: "Terreros", value: "40"},
              {key: "41br", text: "Veinticinco De Julio", value: "41"},
              {key: "42br", text: "Victoria", value: "42"},
              {key: "43br", text: "Villanueva", value: "43"},
              {key: "44br", text: "Zanjita", value: "44"}]},
          {key: "2ds", text: "Cañas Dulces", value: "2", barrios: [
              {key: "1br", text: "Cañas Dulces Centro", value: "1"},
              {key: "2br", text: "Alcántaro", value: "2"},
              {key: "3br", text: "Buenavista", value: "3"},
              {key: "4br", text: "Guayacán", value: "4"},
              {key: "5br", text: "Pochote", value: "5"},
              {key: "6br", text: "Brisas", value: "6"},
              {key: "7br", text: "Cedro", value: "7"},
              {key: "8br", text: "Congo", value: "8"},
              {key: "9br", text: "Cueva", value: "9"},
              {key: "10br", text: "Fortuna", value: "10"},
              {key: "11br", text: "Irigaray", value: "11"},
              {key: "12br", text: "Lilas", value: "12"},
              {key: "13br", text: "Pacayales", value: "13"},
              {key: "14br", text: "Panamacito", value: "14"},
              {key: "15br", text: "Pedregal", value: "15"},
              {key: "16br", text: "Pital", value: "16"},
              {key: "17br", text: "Pueblo Nuevo", value: "17"}]},
          {key: "3ds", text: "Mayorga", value: "3", barrios: [
              {key: "1br", text: "García Flamenco (quebrada Grande)", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Argentina", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Consuelo", value: "5"},
              {key: "6br", text: "Lourdes", value: "6"},
              {key: "7br", text: "San Antonio", value: "7"}]},
          {key: "4ds", text: "Nacascolo", value: "4", barrios: [
              {key: "1br", text: "Guardia", value: "1"},
              {key: "2br", text: "Bejuco", value: "2"},
              {key: "3br", text: "Los Lagos", value: "3"},
              {key: "4br", text: "Nacascolo", value: "4"},
              {key: "5br", text: "Oratorio", value: "5"},
              {key: "6br", text: "Puerto Culebra", value: "6"},
              {key: "7br", text: "Triunfo", value: "7"}]},
          {key: "5ds", text: "Curubandé", value: "5", barrios: [
              {key: "1br", text: "Cereceda", value: "1"},
              {key: "2br", text: "Gallo", value: "2"},
              {key: "3br", text: "San Rafael", value: "3"},
              {key: "4br", text: "Colorado", value: "4"},
              {key: "5br", text: "Curubandé", value: "5"},
              {key: "6br", text: "Porvenir", value: "6"}]}]},
      {key: "2cn", text: "Nicoya", value: "2", distritos: [
          {key: "1ds", text: "Nicoya", value: "1", barrios: [
              {key: "1br", text: "Nicoya Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Barro Negro", value: "3"},
              {key: "4br", text: "Cananga", value: "4"},
              {key: "5br", text: "Carmen", value: "5"},
              {key: "6br", text: "Chorotega", value: "6"},
              {key: "7br", text: "Guadalupe", value: "7"},
              {key: "8br", text: "Granja", value: "8"},
              {key: "9br", text: "San Martín", value: "9"},
              {key: "10br", text: "Santa Lucía", value: "10"},
              {key: "11br", text: "Virginia", value: "11"},
              {key: "12br", text: "Cabeceras", value: "12"},
              {key: "13br", text: "Caimital", value: "13"},
              {key: "14br", text: "Carreta", value: "14"},
              {key: "15br", text: "Casitas", value: "15"},
              {key: "16br", text: "Cerro Verde", value: "16"},
              {key: "17br", text: "Cerro Redondo", value: "17"},
              {key: "18br", text: "Cola De Gallo", value: "18"},
              {key: "19br", text: "Cuesta", value: "19"},
              {key: "20br", text: "Cuesta Buenos Aires", value: "20"},
              {key: "21br", text: "Curime", value: "21"},
              {key: "22br", text: "Chivo", value: "22"},
              {key: "23br", text: "Dulce Nombre", value: "23"},
              {key: "24br", text: "Esperanza Norte", value: "24"},
              {key: "25br", text: "Estrella", value: "25"},
              {key: "26br", text: "Gamalotal", value: "26"},
              {key: "27br", text: "Garcimuñóz", value: "27"},
              {key: "28br", text: "Guaitil", value: "28"},
              {key: "29br", text: "Guastomatal", value: "29"},
              {key: "30br", text: "Guineas", value: "30"},
              {key: "31br", text: "Hondores", value: "31"},
              {key: "32br", text: "Jobo", value: "32"},
              {key: "33br", text: "Juan Díaz", value: "33"},
              {key: "34br", text: "Lajas", value: "34"},
              {key: "35br", text: "Loma Caucela", value: "35"},
              {key: "36br", text: "Miramar (noroeste)", value: "36"},
              {key: "37br", text: "Nambí", value: "37"},
              {key: "38br", text: "Oriente", value: "38"},
              {key: "39br", text: "Los Planes", value: "39"},
              {key: "40br", text: "Pedernal", value: "40"},
              {key: "41br", text: "Picudas", value: "41"},
              {key: "42br", text: "Pilahonda", value: "42"},
              {key: "43br", text: "Pilas", value: "43"},
              {key: "44br", text: "Pilas Blancas", value: "44"},
              {key: "45br", text: "Piragua", value: "45"},
              {key: "46br", text: "Ponedero", value: "46"},
              {key: "47br", text: "Quirimán", value: "47"},
              {key: "48br", text: "Quirimancito", value: "48"},
              {key: "49br", text: "Sabana Grande", value: "49"},
              {key: "50br", text: "Santa Ana", value: "50"},
              {key: "51br", text: "Sitio Botija", value: "51"},
              {key: "52br", text: "Tierra Blanca", value: "52"},
              {key: "53br", text: "Tres Quebradas", value: "53"},
              {key: "54br", text: "Varillas (zapotillo)", value: "54"},
              {key: "55br", text: "Virginia", value: "55"},
              {key: "56br", text: "Zompopa", value: "56"}]},
          {key: "2ds", text: "Mansión", value: "2", barrios: [
              {key: "1br", text: "Mansión Centro", value: "1"},
              {key: "2br", text: "Acoyapa", value: "2"},
              {key: "3br", text: "Boquete", value: "3"},
              {key: "4br", text: "Camarones", value: "4"},
              {key: "5br", text: "Guastomatal", value: "5"},
              {key: "6br", text: "Iguanita", value: "6"},
              {key: "7br", text: "Lapas", value: "7"},
              {key: "8br", text: "Limonal", value: "8"},
              {key: "9br", text: "Matambuguito", value: "9"},
              {key: "10br", text: "Matina", value: "10"},
              {key: "11br", text: "Mercedes", value: "11"},
              {key: "12br", text: "Monte Alto", value: "12"},
              {key: "13br", text: "Morote Norte", value: "13"},
              {key: "14br", text: "Nacaome", value: "14"},
              {key: "15br", text: "Obispo", value: "15"},
              {key: "16br", text: "Pital", value: "16"},
              {key: "17br", text: "Polvazales", value: "17"},
              {key: "18br", text: "Pueblo Viejo", value: "18"},
              {key: "19br", text: "Puente Guillermina", value: "19"},
              {key: "20br", text: "Puerto Jesús", value: "20"},
              {key: "21br", text: "Río Vueltas", value: "21"},
              {key: "22br", text: "San Joaquín", value: "22"},
              {key: "23br", text: "San Juan (parte)", value: "23"},
              {key: "24br", text: "Uvita (parte)", value: "24"},
              {key: "25br", text: "Vigía", value: "25"},
              {key: "26br", text: "Yerbabuena (parte)", value: "26"},
              {key: "27br", text: "Zapandí", value: "27"}]},
          {key: "3ds", text: "San Antonio", value: "3", barrios: [
              {key: "1br", text: "San Antonio Centro", value: "1"},
              {key: "2br", text: "Biscoyol", value: "2"},
              {key: "3br", text: "Bolsa", value: "3"},
              {key: "4br", text: "Boquete", value: "4"},
              {key: "5br", text: "Buenos Aires", value: "5"},
              {key: "6br", text: "Cañal", value: "6"},
              {key: "7br", text: "Carao", value: "7"},
              {key: "8br", text: "Cerro Mesas", value: "8"},
              {key: "9br", text: "Chira", value: "9"},
              {key: "10br", text: "Conchal", value: "10"},
              {key: "11br", text: "Corral De Piedra", value: "11"},
              {key: "12br", text: "Corralillo", value: "12"},
              {key: "13br", text: "Coyolar", value: "13"},
              {key: "14br", text: "Cuba", value: "14"},
              {key: "15br", text: "Cuesta Madroño", value: "15"},
              {key: "16br", text: "Flor", value: "16"},
              {key: "17br", text: "Florida", value: "17"},
              {key: "18br", text: "Guayabal", value: "18"},
              {key: "19br", text: "Guayabo", value: "19"},
              {key: "20br", text: "Loma Ayote", value: "20"},
              {key: "21br", text: "Matamba", value: "21"},
              {key: "22br", text: "México", value: "22"},
              {key: "23br", text: "Montañita", value: "23"},
              {key: "24br", text: "Monte Galán", value: "24"},
              {key: "25br", text: "Moracia", value: "25"},
              {key: "26br", text: "Ojo De Agua", value: "26"},
              {key: "27br", text: "Palos Negros", value: "27"},
              {key: "28br", text: "Piave", value: "28"},
              {key: "29br", text: "Piedras Blancas", value: "29"},
              {key: "30br", text: "Pozas", value: "30"},
              {key: "31br", text: "Pozo De Agua", value: "31"},
              {key: "32br", text: "Pueblo Nuevo", value: "32"},
              {key: "33br", text: "Puerto Humo", value: "33"},
              {key: "34br", text: "Rosario", value: "34"},
              {key: "35br", text: "San Lázaro", value: "35"},
              {key: "36br", text: "San Vicente", value: "36"},
              {key: "37br", text: "Silencio", value: "37"},
              {key: "38br", text: "Talolinga", value: "38"},
              {key: "39br", text: "Tamarindo", value: "39"},
              {key: "40br", text: "Zapote", value: "40"}]},
          {key: "4ds", text: "Quebrada Honda", value: "4", barrios: [
              {key: "1br", text: "Quebrada Honda Centro", value: "1"},
              {key: "2br", text: "Botija", value: "2"},
              {key: "3br", text: "Caballito", value: "3"},
              {key: "4br", text: "Copal", value: "4"},
              {key: "5br", text: "Embarcadero", value: "5"},
              {key: "6br", text: "Loma Bonita", value: "6"},
              {key: "7br", text: "Millal", value: "7"},
              {key: "8br", text: "Paraíso", value: "8"},
              {key: "9br", text: "Paso Guabo", value: "9"},
              {key: "10br", text: "Pochote", value: "10"},
              {key: "11br", text: "Puerto Moreno", value: "11"},
              {key: "12br", text: "Roblar", value: "12"},
              {key: "13br", text: "San Juan (parte)", value: "13"},
              {key: "14br", text: "Sombrero", value: "14"},
              {key: "15br", text: "Sonzapote", value: "15"},
              {key: "16br", text: "Tortuguero", value: "16"},
              {key: "17br", text: "Tres Esquinas", value: "17"}]},
          {key: "5ds", text: "Sámara", value: "5", barrios: [
              {key: "1br", text: "Sámara Centro", value: "1"},
              {key: "2br", text: "Bajo Escondido", value: "2"},
              {key: "3br", text: "Barco Quebrado", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Buenos Aires", value: "5"},
              {key: "6br", text: "Cambutes", value: "6"},
              {key: "7br", text: "Cangrejal", value: "7"},
              {key: "8br", text: "Cantarrana", value: "8"},
              {key: "9br", text: "Chinampas", value: "9"},
              {key: "10br", text: "Esterones", value: "10"},
              {key: "11br", text: "Galilea", value: "11"},
              {key: "12br", text: "Mala Noche", value: "12"},
              {key: "13br", text: "Matapalo", value: "13"},
              {key: "14br", text: "Palmar", value: "14"},
              {key: "15br", text: "Panamá", value: "15"},
              {key: "16br", text: "Pavones", value: "16"},
              {key: "17br", text: "Playa Buenavista", value: "17"},
              {key: "18br", text: "Primavera", value: "18"},
              {key: "19br", text: "Pueblo Nuevo", value: "19"},
              {key: "20br", text: "Samaria", value: "20"},
              {key: "21br", text: "San Fernando", value: "21"},
              {key: "22br", text: "Santo Domingo", value: "22"},
              {key: "23br", text: "Taranta", value: "23"},
              {key: "24br", text: "Terciopelo", value: "24"},
              {key: "25br", text: "Torito", value: "25"}]},
          {key: "6ds", text: "Nosara", value: "6", barrios: [
              {key: "1br", text: "Bocas De Nosara", value: "1"},
              {key: "2br", text: "Ángeles De Garza", value: "2"},
              {key: "3br", text: "Bijagua", value: "3"},
              {key: "4br", text: "Cabeceras De Garza", value: "4"},
              {key: "5br", text: "Coyoles", value: "5"},
              {key: "6br", text: "Cuesta Winch", value: "6"},
              {key: "7br", text: "Delicias", value: "7"},
              {key: "8br", text: "Esperanza Sur", value: "8"},
              {key: "9br", text: "Flores", value: "9"},
              {key: "10br", text: "Garza", value: "10"},
              {key: "11br", text: "Guiones", value: "11"},
              {key: "12br", text: "Ligia", value: "12"},
              {key: "13br", text: "Nosara", value: "13"},
              {key: "14br", text: "Playa Nosara", value: "14"},
              {key: "15br", text: "Playa Pelada", value: "15"},
              {key: "16br", text: "Portal", value: "16"},
              {key: "17br", text: "Río Montaña", value: "17"},
              {key: "18br", text: "San Juan", value: "18"},
              {key: "19br", text: "Santa Marta", value: "19"},
              {key: "20br", text: "Santa Teresa", value: "20"}]},
          {key: "7ds", text: "Belén De Nosarita", value: "7", barrios: [
              {key: "1br", text: "Belén De Nosarita Centro", value: "1"},
              {key: "2br", text: "Arcos", value: "2"},
              {key: "3br", text: "Balsal", value: "3"},
              {key: "4br", text: "Caimitalito", value: "4"},
              {key: "5br", text: "Cuajiniquil", value: "5"},
              {key: "6br", text: "Cuesta Grande", value: "6"},
              {key: "7br", text: "Chumburán", value: "7"},
              {key: "8br", text: "Juntas", value: "8"},
              {key: "9br", text: "Maquenco", value: "9"},
              {key: "10br", text: "Minas", value: "10"},
              {key: "11br", text: "Miramar Sureste", value: "11"},
              {key: "12br", text: "Naranjal", value: "12"},
              {key: "13br", text: "Naranjalito", value: "13"},
              {key: "14br", text: "Nosarita", value: "14"},
              {key: "15br", text: "Platanillo", value: "15"},
              {key: "16br", text: "Quebrada Bonita", value: "16"},
              {key: "17br", text: "Santa Elena (parte)", value: "17"},
              {key: "18br", text: "Zaragoza", value: "18"}]}]},
      {key: "3cn", text: "Santa Cruz", value: "3", distritos: [
          {key: "1ds", text: "Santa Cruz", value: "1", barrios: [
              {key: "1br", text: "Santa Cruz Centro", value: "1"},
              {key: "2br", text: "Buenos Aires", value: "2"},
              {key: "3br", text: "Camarenos", value: "3"},
              {key: "4br", text: "Cátalo Rojas", value: "4"},
              {key: "5br", text: "Corobicí", value: "5"},
              {key: "6br", text: "Chorotega", value: "6"},
              {key: "7br", text: "Esquipulas", value: "7"},
              {key: "8br", text: "Estocolmo", value: "8"},
              {key: "9br", text: "Flores", value: "9"},
              {key: "10br", text: "Garúa", value: "10"},
              {key: "11br", text: "Guabo", value: "11"},
              {key: "12br", text: "Lajas", value: "12"},
              {key: "13br", text: "Los Amigos", value: "13"},
              {key: "14br", text: "Malinches", value: "14"},
              {key: "15br", text: "Manchón", value: "15"},
              {key: "16br", text: "Panamá", value: "16"},
              {key: "17br", text: "Pepe Lujan", value: "17"},
              {key: "18br", text: "Sagamat", value: "18"},
              {key: "19br", text: "San Martín", value: "19"},
              {key: "20br", text: "Santa Cecilia", value: "20"},
              {key: "21br", text: "Tenorio", value: "21"},
              {key: "22br", text: "Tulita Sandino", value: "22"},
              {key: "23br", text: "Ángeles", value: "23"},
              {key: "24br", text: "Arado", value: "24"},
              {key: "25br", text: "Bernabela", value: "25"},
              {key: "26br", text: "Cacao", value: "26"},
              {key: "27br", text: "Caimito", value: "27"},
              {key: "28br", text: "Congal", value: "28"},
              {key: "29br", text: "Cuatro Esquinas", value: "29"},
              {key: "30br", text: "Chibola", value: "30"},
              {key: "31br", text: "Chircó", value: "31"},
              {key: "32br", text: "Chumico (parte)", value: "32"},
              {key: "33br", text: "Guayabal", value: "33"},
              {key: "34br", text: "Hato Viejo", value: "34"},
              {key: "35br", text: "Lagunilla", value: "35"},
              {key: "36br", text: "Lechuza", value: "36"},
              {key: "37br", text: "Limón", value: "37"},
              {key: "38br", text: "Moya", value: "38"},
              {key: "39br", text: "Puente Negro", value: "39"},
              {key: "40br", text: "Retallano (parte)", value: "40"},
              {key: "41br", text: "Rincón", value: "41"},
              {key: "42br", text: "Río Cañas Viejo", value: "42"},
              {key: "43br", text: "San Juan", value: "43"},
              {key: "44br", text: "San Pedro", value: "44"},
              {key: "45br", text: "San Pedro Viejo", value: "45"},
              {key: "46br", text: "Vista Al Mar", value: "46"}]},
          {key: "2ds", text: "Bolsón", value: "2", barrios: [
              {key: "1br", text: "Bolsón Centro", value: "1"},
              {key: "2br", text: "Ortega", value: "2"},
              {key: "3br", text: "Ballena (parte)", value: "3"},
              {key: "4br", text: "Lagartero", value: "4"},
              {key: "5br", text: "Pochotada", value: "5"}]},
          {key: "3ds", text: "Veintisiete De Abril", value: "3", barrios: [
              {key: "1br", text: "Veintisiete De Abril Centro", value: "1"},
              {key: "2br", text: "Jobos", value: "2"},
              {key: "3br", text: "Aguacate", value: "3"},
              {key: "4br", text: "Avellana", value: "4"},
              {key: "5br", text: "Barrosa", value: "5"},
              {key: "6br", text: "Brisas", value: "6"},
              {key: "7br", text: "Bruno", value: "7"},
              {key: "8br", text: "Cacaovano", value: "8"},
              {key: "9br", text: "Camones", value: "9"},
              {key: "10br", text: "Cañas Gordas", value: "10"},
              {key: "11br", text: "Ceiba Mocha", value: "11"},
              {key: "12br", text: "Cerro Brujo", value: "12"},
              {key: "13br", text: "Congo", value: "13"},
              {key: "14br", text: "Delicias", value: "14"},
              {key: "15br", text: "Espavelar", value: "15"},
              {key: "16br", text: "Florida", value: "16"},
              {key: "17br", text: "Gongolona", value: "17"},
              {key: "18br", text: "Guachipelín", value: "18"},
              {key: "19br", text: "Guapote", value: "19"},
              {key: "20br", text: "Hatillo", value: "20"},
              {key: "21br", text: "Isla Verde", value: "21"},
              {key: "22br", text: "Junquillal", value: "22"},
              {key: "23br", text: "Junta De Río Verde", value: "23"},
              {key: "24br", text: "Mesas", value: "24"},
              {key: "25br", text: "Montaña", value: "25"},
              {key: "26br", text: "Monteverde", value: "26"},
              {key: "27br", text: "Níspero", value: "27"},
              {key: "28br", text: "Paraíso", value: "28"},
              {key: "29br", text: "Pargos", value: "29"},
              {key: "30br", text: "Paso Hondo", value: "30"},
              {key: "31br", text: "Pilas", value: "31"},
              {key: "32br", text: "Playa Lagartillo", value: "32"},
              {key: "33br", text: "Playa Negra", value: "33"},
              {key: "34br", text: "Pochotes", value: "34"},
              {key: "35br", text: "Ranchos", value: "35"},
              {key: "36br", text: "Retallano (parte)", value: "36"},
              {key: "37br", text: "Río Seco", value: "37"},
              {key: "38br", text: "Río Tabaco", value: "38"},
              {key: "39br", text: "San Francisco", value: "39"},
              {key: "40br", text: "San Jerónimo", value: "40"},
              {key: "41br", text: "Soncoyo", value: "41"},
              {key: "42br", text: "Tieso (san Rafael)", value: "42"},
              {key: "43br", text: "Trapiche", value: "43"},
              {key: "44br", text: "Venado", value: "44"},
              {key: "45br", text: "Vergel", value: "45"}]},
          {key: "4ds", text: "Tempate", value: "4", barrios: [
              {key: "1br", text: "Tempate Centro", value: "1"},
              {key: "2br", text: "Paraíso", value: "2"},
              {key: "3br", text: "Bejuco", value: "3"},
              {key: "4br", text: "Chiles", value: "4"},
              {key: "5br", text: "El Llano", value: "5"},
              {key: "6br", text: "Huacas", value: "6"},
              {key: "7br", text: "Portegolpe", value: "7"},
              {key: "8br", text: "Potrero", value: "8"},
              {key: "9br", text: "Rincón", value: "9"}]},
          {key: "5ds", text: "Cartagena", value: "5", barrios: [
              {key: "1br", text: "Cartagena Centro", value: "1"},
              {key: "2br", text: "Edén", value: "2"},
              {key: "3br", text: "Toyosa", value: "3"},
              {key: "4br", text: "Cañafístula", value: "4"},
              {key: "5br", text: "Corocitos", value: "5"},
              {key: "6br", text: "Higuerón", value: "6"},
              {key: "7br", text: "Jobo", value: "7"},
              {key: "8br", text: "Lorena", value: "8"},
              {key: "9br", text: "Oratorio", value: "9"},
              {key: "10br", text: "Sacatipe", value: "10"}]},
          {key: "6ds", text: "Cuajiniquil", value: "6", barrios: [
              {key: "1br", text: "San Juanillo", value: "1"},
              {key: "2br", text: "Alemania", value: "2"},
              {key: "3br", text: "Bolillos", value: "3"},
              {key: "4br", text: "Cuajiniquil", value: "4"},
              {key: "5br", text: "Chiquero", value: "5"},
              {key: "6br", text: "Fortuna", value: "6"},
              {key: "7br", text: "Frijolar", value: "7"},
              {key: "8br", text: "Jazminal", value: "8"},
              {key: "9br", text: "Lagarto", value: "9"},
              {key: "10br", text: "Libertad", value: "10"},
              {key: "11br", text: "Limonal", value: "11"},
              {key: "12br", text: "Manzanillo", value: "12"},
              {key: "13br", text: "Marbella", value: "13"},
              {key: "14br", text: "Ostional", value: "14"},
              {key: "15br", text: "Palmares", value: "15"},
              {key: "16br", text: "Piedras Amarillas", value: "16"},
              {key: "17br", text: "Progreso", value: "17"},
              {key: "18br", text: "Punta Caliente", value: "18"},
              {key: "19br", text: "Quebrada Seca", value: "19"},
              {key: "20br", text: "Quebrada Zapote", value: "20"},
              {key: "21br", text: "Rayo", value: "21"},
              {key: "22br", text: "Roble", value: "22"},
              {key: "23br", text: "Rosario", value: "23"},
              {key: "24br", text: "Santa Cecilia", value: "24"},
              {key: "25br", text: "Santa Elena", value: "25"},
              {key: "26br", text: "Socorro", value: "26"},
              {key: "27br", text: "Unión", value: "27"},
              {key: "28br", text: "Veracrúz", value: "28"}]},
          {key: "7ds", text: "Diriá", value: "7", barrios: [
              {key: "1br", text: "Santa Bárbara", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Duendes", value: "3"},
              {key: "4br", text: "Lomitas", value: "4"},
              {key: "5br", text: "Oriente", value: "5"},
              {key: "6br", text: "Calle Vieja", value: "6"},
              {key: "7br", text: "Coyolar", value: "7"},
              {key: "8br", text: "Chumico (parte)", value: "8"},
              {key: "9br", text: "Diría", value: "9"},
              {key: "10br", text: "Guaitil", value: "10"},
              {key: "11br", text: "Polvazal", value: "11"},
              {key: "12br", text: "Sequeira", value: "12"},
              {key: "13br", text: "Talolinguita", value: "13"},
              {key: "14br", text: "Trompillal", value: "14"}]},
          {key: "8ds", text: "Cabo Velas", value: "8", barrios: [
              {key: "1br", text: "Matapalo", value: "1"},
              {key: "2br", text: "Brasilito", value: "2"},
              {key: "3br", text: "Buen Pastor", value: "3"},
              {key: "4br", text: "Conchal", value: "4"},
              {key: "5br", text: "Flamenco", value: "5"},
              {key: "6br", text: "Garita Vieja", value: "6"},
              {key: "7br", text: "Jesús María", value: "7"},
              {key: "8br", text: "Lajas", value: "8"},
              {key: "9br", text: "Lomas", value: "9"},
              {key: "10br", text: "Playa Cabuya", value: "10"},
              {key: "11br", text: "Playa Grande", value: "11"},
              {key: "12br", text: "Playa Mina", value: "12"},
              {key: "13br", text: "Playa Real", value: "13"},
              {key: "14br", text: "Puerto Viejo", value: "14"},
              {key: "15br", text: "Salinas", value: "15"},
              {key: "16br", text: "Salinitas", value: "16"},
              {key: "17br", text: "Tacasolapa", value: "17"},
              {key: "18br", text: "Zapotillal", value: "18"}]},
          {key: "9ds", text: "Tamarindo", value: "9", barrios: [
              {key: "1br", text: "Villarreal", value: "1"},
              {key: "2br", text: "Cañafístula", value: "2"},
              {key: "3br", text: "Cebadilla", value: "3"},
              {key: "4br", text: "El Llano", value: "4"},
              {key: "5br", text: "Garita", value: "5"},
              {key: "6br", text: "Guatemala", value: "6"},
              {key: "7br", text: "Hernández", value: "7"},
              {key: "8br", text: "Icacal", value: "8"},
              {key: "9br", text: "La Loma", value: "9"},
              {key: "10br", text: "Linderos", value: "10"},
              {key: "11br", text: "Mangos", value: "11"},
              {key: "12br", text: "Palmar", value: "12"},
              {key: "13br", text: "San Andrés", value: "13"},
              {key: "14br", text: "San José Pinilla", value: "14"},
              {key: "15br", text: "Santa Rosa", value: "15"},
              {key: "16br", text: "Tamarindo", value: "16"}]}]},
      {key: "4cn", text: "Bagaces", value: "4", distritos: [
          {key: "1ds", text: "Bagaces", value: "1", barrios: [
              {key: "1br", text: "Bagaces Centro", value: "1"},
              {key: "2br", text: "Aguacaliente", value: "2"},
              {key: "3br", text: "Arbolito", value: "3"},
              {key: "4br", text: "Bagatzi", value: "4"},
              {key: "5br", text: "Bebedero (parte)", value: "5"},
              {key: "6br", text: "Brisas", value: "6"},
              {key: "7br", text: "Casavieja (parte)", value: "7"},
              {key: "8br", text: "Cofradía", value: "8"},
              {key: "9br", text: "Colmenar", value: "9"},
              {key: "10br", text: "Lima", value: "10"},
              {key: "11br", text: "Llanos De Cortés", value: "11"},
              {key: "12br", text: "Mojica", value: "12"},
              {key: "13br", text: "Montano", value: "13"},
              {key: "14br", text: "Montenegro", value: "14"},
              {key: "15br", text: "Pedro Nolasco", value: "15"},
              {key: "16br", text: "Piedras", value: "16"},
              {key: "17br", text: "Pijije", value: "17"},
              {key: "18br", text: "Plazuela", value: "18"},
              {key: "19br", text: "Redondel", value: "19"},
              {key: "20br", text: "Salitral", value: "20"},
              {key: "21br", text: "Salto (parte)", value: "21"},
              {key: "22br", text: "Santa Rosa", value: "22"}]},
          {key: "2ds", text: "La Fortuna", value: "2", barrios: [
              {key: "1br", text: "La Fortuna Centro", value: "1"},
              {key: "2br", text: "Casavieja (parte)", value: "2"},
              {key: "3br", text: "Cuipilapa", value: "3"},
              {key: "4br", text: "Giganta", value: "4"},
              {key: "5br", text: "Hornillas", value: "5"},
              {key: "6br", text: "Macuá", value: "6"},
              {key: "7br", text: "Martillete", value: "7"},
              {key: "8br", text: "Miravalles", value: "8"},
              {key: "9br", text: "Mozotal", value: "9"},
              {key: "10br", text: "Pozo Azul", value: "10"},
              {key: "11br", text: "Sagrada Familia", value: "11"},
              {key: "12br", text: "San Bernardo", value: "12"},
              {key: "13br", text: "San Joaquín", value: "13"},
              {key: "14br", text: "Santa Cecilia", value: "14"},
              {key: "15br", text: "Santa Fe", value: "15"},
              {key: "16br", text: "Santa Rosa", value: "16"},
              {key: "17br", text: "Unión Ferrer", value: "17"}]},
          {key: "3ds", text: "Mogote", value: "3", barrios: [
              {key: "1br", text: "Guayabo", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Barro De Olla", value: "3"},
              {key: "4br", text: "Horcones", value: "4"},
              {key: "5br", text: "La Ese", value: "5"},
              {key: "6br", text: "Limonal", value: "6"},
              {key: "7br", text: "Manglar", value: "7"},
              {key: "8br", text: "Mochadero", value: "8"},
              {key: "9br", text: "Oses", value: "9"},
              {key: "10br", text: "Pueblo Nuevo", value: "10"},
              {key: "11br", text: "Rincón De La Cruz", value: "11"},
              {key: "12br", text: "San Isidro De Limonal", value: "12"},
              {key: "13br", text: "San Jorge", value: "13"},
              {key: "14br", text: "San Pedro", value: "14"},
              {key: "15br", text: "Torno", value: "15"}]},
          {key: "4ds", text: "Río Naranjo", value: "4", barrios: [
              {key: "1br", text: "Río Naranjo Centro", value: "1"},
              {key: "2br", text: "Naranjito", value: "2"},
              {key: "3br", text: "Río Chiquito", value: "3"}]}]},
      {key: "5cn", text: "Carrillo", value: "5", distritos: [
          {key: "1ds", text: "Filadelfia", value: "1", barrios: [
              {key: "1br", text: "Filadelfia Centro", value: "1"},
              {key: "2br", text: "Ballena (parte)", value: "2"},
              {key: "3br", text: "Bambú", value: "3"},
              {key: "4br", text: "Cinco Esquinas", value: "4"},
              {key: "5br", text: "Corralillo", value: "5"},
              {key: "6br", text: "Guinea", value: "6"},
              {key: "7br", text: "Hollywood", value: "7"},
              {key: "8br", text: "Isleta (parte)", value: "8"},
              {key: "9br", text: "Jocote", value: "9"},
              {key: "10br", text: "Juanilama", value: "10"},
              {key: "11br", text: "La Cruz", value: "11"},
              {key: "12br", text: "Moralito", value: "12"},
              {key: "13br", text: "Ojoche", value: "13"},
              {key: "14br", text: "San Francisco", value: "14"},
              {key: "15br", text: "Santa Lucía", value: "15"}]},
          {key: "2ds", text: "Palmira", value: "2", barrios: [
              {key: "1br", text: "Palmira Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Comunidad", value: "3"},
              {key: "4br", text: "Coyolera", value: "4"},
              {key: "5br", text: "María Auxiliadora", value: "5"},
              {key: "6br", text: "Paso Tempisque (parte)", value: "6"},
              {key: "7br", text: "San Rafael", value: "7"}]},
          {key: "3ds", text: "Sardinal", value: "3", barrios: [
              {key: "1br", text: "Sardinal Centro", value: "1"},
              {key: "2br", text: "Artola", value: "2"},
              {key: "3br", text: "Cacique", value: "3"},
              {key: "4br", text: "Carpintera", value: "4"},
              {key: "5br", text: "Chorrera", value: "5"},
              {key: "6br", text: "Coco", value: "6"},
              {key: "7br", text: "Colegios", value: "7"},
              {key: "8br", text: "Guacamaya", value: "8"},
              {key: "9br", text: "Huaquitas", value: "9"},
              {key: "10br", text: "Libertad", value: "10"},
              {key: "11br", text: "Los Canales", value: "11"},
              {key: "12br", text: "Matapalo", value: "12"},
              {key: "13br", text: "Nancital", value: "13"},
              {key: "14br", text: "Nuevo Colón", value: "14"},
              {key: "15br", text: "Obandito", value: "15"},
              {key: "16br", text: "Ocotal", value: "16"},
              {key: "17br", text: "Pilas", value: "17"},
              {key: "18br", text: "Playa Hermosa", value: "18"},
              {key: "19br", text: "Playones", value: "19"},
              {key: "20br", text: "San Blas", value: "20"},
              {key: "21br", text: "San Martín", value: "21"},
              {key: "22br", text: "Santa Rita", value: "22"},
              {key: "23br", text: "Segovia", value: "23"},
              {key: "24br", text: "Tabores", value: "24"},
              {key: "25br", text: "Verdún", value: "25"},
              {key: "26br", text: "Zapotal", value: "26"}]},
          {key: "4ds", text: "Belén", value: "4", barrios: [
              {key: "1br", text: "Belén Centro", value: "1"},
              {key: "2br", text: "Alto San Antonio", value: "2"},
              {key: "3br", text: "Cachimbo", value: "3"},
              {key: "4br", text: "Castilla De Oro", value: "4"},
              {key: "5br", text: "Coyolito", value: "5"},
              {key: "6br", text: "Gallina", value: "6"},
              {key: "7br", text: "Juanilama", value: "7"},
              {key: "8br", text: "Llano", value: "8"},
              {key: "9br", text: "Loma Bonita", value: "9"},
              {key: "10br", text: "Ojochal", value: "10"},
              {key: "11br", text: "Palestina", value: "11"},
              {key: "12br", text: "Palmas", value: "12"},
              {key: "13br", text: "Paraíso", value: "13"},
              {key: "14br", text: "Penca", value: "14"},
              {key: "15br", text: "Planes", value: "15"},
              {key: "16br", text: "Poroporo", value: "16"},
              {key: "17br", text: "Río Cañas Nuevo", value: "17"},
              {key: "18br", text: "Santa Ana", value: "18"},
              {key: "19br", text: "Santo Domingo", value: "19"},
              {key: "20br", text: "Villita", value: "20"}]}]},
      {key: "6cn", text: "Cañas", value: "6", distritos: [
          {key: "1ds", text: "Cañas", value: "1", barrios: [
              {key: "1br", text: "Cañas Centro", value: "1"},
              {key: "2br", text: "Albania", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Bello Horizonte", value: "4"},
              {key: "5br", text: "Cantarrana", value: "5"},
              {key: "6br", text: "Castillo", value: "6"},
              {key: "7br", text: "Cedros", value: "7"},
              {key: "8br", text: "Cepo", value: "8"},
              {key: "9br", text: "Chorotega", value: "9"},
              {key: "10br", text: "Concepción", value: "10"},
              {key: "11br", text: "Corobicí", value: "11"},
              {key: "12br", text: "Correntadas", value: "12"},
              {key: "13br", text: "Cuesta El Diablo", value: "13"},
              {key: "14br", text: "Cuesta El Mico", value: "14"},
              {key: "15br", text: "Cueva", value: "15"},
              {key: "16br", text: "Hotel", value: "16"},
              {key: "17br", text: "Jabilla Abajo", value: "17"},
              {key: "18br", text: "Jabilla Arriba", value: "18"},
              {key: "19br", text: "Las Cañas", value: "19"},
              {key: "20br", text: "Libertad", value: "20"},
              {key: "21br", text: "Malinches", value: "21"},
              {key: "22br", text: "Miravalles", value: "22"},
              {key: "23br", text: "Montes De Oro", value: "23"},
              {key: "24br", text: "Palmas", value: "24"},
              {key: "25br", text: "Paso Lajas", value: "25"},
              {key: "26br", text: "Pedregal", value: "26"},
              {key: "27br", text: "Pochota", value: "27"},
              {key: "28br", text: "Pueblo Nuevo", value: "28"},
              {key: "29br", text: "San Cristóbal", value: "29"},
              {key: "30br", text: "San Isidro (parte)", value: "30"},
              {key: "31br", text: "San Martín", value: "31"},
              {key: "32br", text: "San Pedro", value: "32"},
              {key: "33br", text: "Sandial (sandillal)", value: "33"},
              {key: "34br", text: "Santa Isabel Abajo", value: "34"},
              {key: "35br", text: "Santa Isabel Arriba", value: "35"},
              {key: "36br", text: "Santa Lucía (parte)", value: "36"},
              {key: "37br", text: "Tenorio", value: "37"},
              {key: "38br", text: "Tres Marías", value: "38"},
              {key: "39br", text: "Unión", value: "39"},
              {key: "40br", text: "Vergel", value: "40"}]},
          {key: "2ds", text: "Palmira", value: "2", barrios: [
              {key: "1br", text: "Palmira Centro", value: "1"},
              {key: "2br", text: "Aguacaliente", value: "2"},
              {key: "3br", text: "Aguas Gatas (parte)", value: "3"},
              {key: "4br", text: "Coyota", value: "4"},
              {key: "5br", text: "Huacal", value: "5"},
              {key: "6br", text: "Las Flores", value: "6"},
              {key: "7br", text: "Martirio", value: "7"},
              {key: "8br", text: "Panales", value: "8"},
              {key: "9br", text: "Paraíso (parte)", value: "9"},
              {key: "10br", text: "San Isidro (parte)", value: "10"},
              {key: "11br", text: "Santa Lucía (parte)", value: "11"},
              {key: "12br", text: "Tenorio", value: "12"},
              {key: "13br", text: "Vueltas", value: "13"}]},
          {key: "3ds", text: "San Miguel", value: "3", barrios: [
              {key: "1br", text: "San Miguel Centro", value: "1"},
              {key: "2br", text: "El Coco", value: "2"},
              {key: "3br", text: "El Güis", value: "3"},
              {key: "4br", text: "Eskameca (parte)", value: "4"},
              {key: "5br", text: "Gotera", value: "5"},
              {key: "6br", text: "Higuerón", value: "6"},
              {key: "7br", text: "Higuerón Viejo", value: "7"},
              {key: "8br", text: "San Juan", value: "8"}]},
          {key: "4ds", text: "Bebedero", value: "4", barrios: [
              {key: "1br", text: "Bebedero Centro", value: "1"},
              {key: "2br", text: "Coopetaboga", value: "2"},
              {key: "3br", text: "Loma", value: "3"},
              {key: "4br", text: "Taboga (parte)", value: "4"}]},
          {key: "5ds", text: "Porozal", value: "5", barrios: [
              {key: "1br", text: "Porozal Centro", value: "1"},
              {key: "2br", text: "Brisas", value: "2"},
              {key: "3br", text: "Eskameca (parte)", value: "3"},
              {key: "4br", text: "Guapinol", value: "4"},
              {key: "5br", text: "Pozas", value: "5"},
              {key: "6br", text: "Puerto Alegre", value: "6"},
              {key: "7br", text: "Quesera", value: "7"},
              {key: "8br", text: "Santa Lucía", value: "8"},
              {key: "9br", text: "Taboga (parte)", value: "9"},
              {key: "10br", text: "Tiquirusas", value: "10"}]}]},
      {key: "7cn", text: "Abangares", value: "7", distritos: [
          {key: "1ds", text: "Las Juntas", value: "1", barrios: [
              {key: "1br", text: "Las Juntas Centro", value: "1"},
              {key: "2br", text: "Bellavista", value: "2"},
              {key: "3br", text: "Blanco", value: "3"},
              {key: "4br", text: "Cecilia", value: "4"},
              {key: "5br", text: "Chiqueros", value: "5"},
              {key: "6br", text: "Cinco Esquinas", value: "6"},
              {key: "7br", text: "Codornices", value: "7"},
              {key: "8br", text: "Concepción", value: "8"},
              {key: "9br", text: "Coyolito (parte)", value: "9"},
              {key: "10br", text: "Desjarretado", value: "10"},
              {key: "11br", text: "Irma", value: "11"},
              {key: "12br", text: "Jarquín (parte)", value: "12"},
              {key: "13br", text: "Jesús", value: "13"},
              {key: "14br", text: "La Gloria", value: "14"},
              {key: "15br", text: "Lajas", value: "15"},
              {key: "16br", text: "Las Huacas (parte)", value: "16"},
              {key: "17br", text: "Limonal", value: "17"},
              {key: "18br", text: "Limonal Viejo", value: "18"},
              {key: "19br", text: "Lourdes (rancho Ania) (parte)", value: "19"},
              {key: "20br", text: "Matapalo", value: "20"},
              {key: "21br", text: "Naranjos Agrios", value: "21"},
              {key: "22br", text: "Palma", value: "22"},
              {key: "23br", text: "Paso Ancho", value: "23"},
              {key: "24br", text: "Peña", value: "24"},
              {key: "25br", text: "Puente De Tierra", value: "25"},
              {key: "26br", text: "Rancho Alegre (parte)", value: "26"},
              {key: "27br", text: "San Antonio", value: "27"},
              {key: "28br", text: "San Cristóbal", value: "28"},
              {key: "29br", text: "San Francisco", value: "29"},
              {key: "30br", text: "San Jorge", value: "30"},
              {key: "31br", text: "San Juan Chiquito", value: "31"},
              {key: "32br", text: "San Pablo", value: "32"},
              {key: "33br", text: "Santa Lucía", value: "33"},
              {key: "34br", text: "Tortugal", value: "34"},
              {key: "35br", text: "Zapote", value: "35"}]},
          {key: "2ds", text: "Sierra", value: "2", barrios: [
              {key: "1br", text: "Sierra Centro (las Minas)", value: "1"},
              {key: "2br", text: "Aguas Claras", value: "2"},
              {key: "3br", text: "Alto Cebadilla", value: "3"},
              {key: "4br", text: "Campos De Oro", value: "4"},
              {key: "5br", text: "Candelaria", value: "5"},
              {key: "6br", text: "Cañitas", value: "6"},
              {key: "7br", text: "Cruz", value: "7"},
              {key: "8br", text: "Cuesta Yugo", value: "8"},
              {key: "9br", text: "Dos De Abangares", value: "9"},
              {key: "10br", text: "Marsellesa", value: "10"},
              {key: "11br", text: "San Antonio", value: "11"},
              {key: "12br", text: "San Rafael", value: "12"},
              {key: "13br", text: "Tornos", value: "13"},
              {key: "14br", text: "Tres Amigos", value: "14"},
              {key: "15br", text: "Turín (parte)", value: "15"}]},
          {key: "3ds", text: "San Juan", value: "3", barrios: [
              {key: "1br", text: "San Juan Grande", value: "1"},
              {key: "2br", text: "Arizona", value: "2"},
              {key: "3br", text: "Congo", value: "3"},
              {key: "4br", text: "Nancital", value: "4"},
              {key: "5br", text: "Portones", value: "5"},
              {key: "6br", text: "Pozo Azul", value: "6"},
              {key: "7br", text: "Rancho Alegre (parte)", value: "7"},
              {key: "8br", text: "Lourdes (rancho Ania) (parte)", value: "8"},
              {key: "9br", text: "Tierra Colorada", value: "9"},
              {key: "10br", text: "Vainilla", value: "10"}]},
          {key: "4ds", text: "Colorado", value: "4", barrios: [
              {key: "1br", text: "Colorado Centro", value: "1"},
              {key: "2br", text: "Almendros", value: "2"},
              {key: "3br", text: "Barbudal", value: "3"},
              {key: "4br", text: "Gavilanes", value: "4"},
              {key: "5br", text: "Higuerillas", value: "5"},
              {key: "6br", text: "Las Huacas (parte)", value: "6"},
              {key: "7br", text: "Monte Potrero", value: "7"},
              {key: "8br", text: "Quebracho", value: "8"},
              {key: "9br", text: "Peñablanca", value: "9"},
              {key: "10br", text: "San Buenaventura", value: "10"},
              {key: "11br", text: "San Joaquín", value: "11"},
              {key: "12br", text: "Solimar", value: "12"},
              {key: "13br", text: "Villafuerte", value: "13"}]}]},
      {key: "8cn", text: "Tilarán", value: "8", distritos: [
          {key: "1ds", text: "Tilarán", value: "1", barrios: [
              {key: "1br", text: "Tilarán Centro", value: "1"},
              {key: "2br", text: "Cabra", value: "2"},
              {key: "3br", text: "Carmen", value: "3"},
              {key: "4br", text: "Juan XXIII", value: "4"},
              {key: "5br", text: "Lomalinda", value: "5"},
              {key: "6br", text: "Cuatro Esquinas", value: "6"},
              {key: "7br", text: "Chiripa", value: "7"},
              {key: "8br", text: "Piamonte", value: "8"},
              {key: "9br", text: "Río Chiquito", value: "9"},
              {key: "10br", text: "San Luis", value: "10"},
              {key: "11br", text: "Tejona", value: "11"},
              {key: "12br", text: "Tres Esquinas", value: "12"}]},
          {key: "2ds", text: "Quebrada Grande", value: "2", barrios: [
              {key: "1br", text: "Quebrada Grande Centro", value: "1"},
              {key: "2br", text: "Barrionuevo", value: "2"},
              {key: "3br", text: "Campos De Oro", value: "3"},
              {key: "4br", text: "Florida", value: "4"},
              {key: "5br", text: "San Miguel", value: "5"},
              {key: "6br", text: "Vueltas", value: "6"}]},
          {key: "3ds", text: "Tronadora", value: "3", barrios: [
              {key: "1br", text: "Tronadora Centro", value: "1"},
              {key: "2br", text: "Arenal Viejo", value: "2"},
              {key: "3br", text: "Colonia Menonita", value: "3"},
              {key: "4br", text: "Río Chiquito Abajo", value: "4"},
              {key: "5br", text: "Silencio", value: "5"}]},
          {key: "4ds", text: "Santa Rosa", value: "4", barrios: [
              {key: "1br", text: "Los Ángeles", value: "1"},
              {key: "2br", text: "Aguilares", value: "2"},
              {key: "3br", text: "Campos Azules", value: "3"},
              {key: "4br", text: "Montes De Oro (parte)", value: "4"},
              {key: "5br", text: "Naranjos Agrios", value: "5"},
              {key: "6br", text: "Palma", value: "6"},
              {key: "7br", text: "Quebrada Azul", value: "7"},
              {key: "8br", text: "Ranchitos", value: "8"},
              {key: "9br", text: "Santa Rosa", value: "9"}]},
          {key: "5ds", text: "Líbano", value: "5", barrios: [
              {key: "1br", text: "Líbano Centro", value: "1"},
              {key: "2br", text: "Alto Cartago", value: "2"},
              {key: "3br", text: "Maravilla", value: "3"},
              {key: "4br", text: "San José", value: "4"},
              {key: "5br", text: "Solania", value: "5"}]},
          {key: "6ds", text: "Tierras Morenas", value: "6", barrios: [
              {key: "1br", text: "Tierras Morenas Centro", value: "1"},
              {key: "2br", text: "Aguacate", value: "2"},
              {key: "3br", text: "Aguas Gatas (parte)", value: "3"},
              {key: "4br", text: "Bajo Paires", value: "4"},
              {key: "5br", text: "Guadalajara", value: "5"},
              {key: "6br", text: "Montes De Oro (parte)", value: "6"},
              {key: "7br", text: "Paraíso (parte)", value: "7"},
              {key: "8br", text: "Río Piedras", value: "8"},
              {key: "9br", text: "Sabalito", value: "9"}]},
          {key: "7ds", text: "Arenal", value: "7", barrios: [
              {key: "1br", text: "Arenal Centro", value: "1"},
              {key: "2br", text: "Mata De Caña", value: "2"},
              {key: "3br", text: "Sangregado", value: "3"},
              {key: "4br", text: "San Antonio", value: "4"},
              {key: "5br", text: "Unión", value: "5"}]},
          {key: "8ds", text: "Cabeceras (hasta Ser Publicado En El Diario Oficial La Gaceta)", value: "8", barrios: [
              {key: "1br", text: "Cabeceras De Cañas", value: "1"},
              {key: "2br", text: "Brisas", value: "2"},
              {key: "3br", text: "Dos De Tilarán (san Ramón)", value: "3"},
              {key: "4br", text: "Esperanza", value: "4"},
              {key: "5br", text: "Monte Olivos", value: "5"},
              {key: "6br", text: "Nubes", value: "6"},
              {key: "7br", text: "Turín (parte)", value: "7"}]}]},
      {key: "9cn", text: "Nandayure", value: "9", distritos: [
          {key: "1ds", text: "Carmona", value: "1", barrios: [
              {key: "1br", text: "Carmona Centro", value: "1"},
              {key: "2br", text: "Camas", value: "2"},
              {key: "3br", text: "Limones", value: "3"},
              {key: "4br", text: "Maquenco", value: "4"},
              {key: "5br", text: "San Rafael", value: "5"},
              {key: "6br", text: "Vista De Mar", value: "6"}]},
          {key: "2ds", text: "Santa Rita", value: "2", barrios: [
              {key: "1br", text: "Santa Rita Centro", value: "1"},
              {key: "2br", text: "Angostura", value: "2"},
              {key: "3br", text: "Cacao", value: "3"},
              {key: "4br", text: "Chumico", value: "4"},
              {key: "5br", text: "Guaria", value: "5"},
              {key: "6br", text: "Guastomatal", value: "6"},
              {key: "7br", text: "Morote", value: "7"},
              {key: "8br", text: "Tacanis", value: "8"},
              {key: "9br", text: "Uvita (parte)", value: "9"},
              {key: "10br", text: "Yerbabuena (parte)", value: "10"}]},
          {key: "3ds", text: "Zapotal", value: "3", barrios: [
              {key: "1br", text: "Zapotal Centro", value: "1"},
              {key: "2br", text: "Altos De Mora", value: "2"},
              {key: "3br", text: "Cabeceras De Río Ora", value: "3"},
              {key: "4br", text: "Camaronal", value: "4"},
              {key: "5br", text: "Carmen", value: "5"},
              {key: "6br", text: "Cuesta Bijagua", value: "6"},
              {key: "7br", text: "Leona", value: "7"},
              {key: "8br", text: "Manzanales", value: "8"},
              {key: "9br", text: "Río Blanco Este", value: "9"},
              {key: "10br", text: "Río De Oro", value: "10"},
              {key: "11br", text: "Río Ora", value: "11"},
              {key: "12br", text: "San Martín", value: "12"},
              {key: "13br", text: "San Pedro", value: "13"},
              {key: "14br", text: "Soledad", value: "14"}]},
          {key: "4ds", text: "San Pablo", value: "4", barrios: [
              {key: "1br", text: "San Pablo Centro", value: "1"},
              {key: "2br", text: "Canjel", value: "2"},
              {key: "3br", text: "Canjelito", value: "3"},
              {key: "4br", text: "Corozal Oeste", value: "4"},
              {key: "5br", text: "Chamarro", value: "5"},
              {key: "6br", text: "Isla Berrugate", value: "6"},
              {key: "7br", text: "Pavones", value: "7"},
              {key: "8br", text: "Puerto Thiel", value: "8"},
              {key: "9br", text: "San Pablo Viejo", value: "9"}]},
          {key: "5ds", text: "Porvenir", value: "5", barrios: [
              {key: "1br", text: "Cerro Azul", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Cabeceras De Río Bejuco", value: "4"},
              {key: "5br", text: "Chompipe (parte)", value: "5"},
              {key: "6br", text: "Delicias", value: "6"},
              {key: "7br", text: "Quebrada Grande", value: "7"},
              {key: "8br", text: "San Josecito", value: "8"}]},
          {key: "6ds", text: "Bejuco", value: "6", barrios: [
              {key: "1br", text: "Bejuco Centro", value: "1"},
              {key: "2br", text: "Caletas", value: "2"},
              {key: "3br", text: "Candelillo", value: "3"},
              {key: "4br", text: "Corozalito", value: "4"},
              {key: "5br", text: "Chiruta", value: "5"},
              {key: "6br", text: "Chompipe (parte)", value: "6"},
              {key: "7br", text: "I Griega", value: "7"},
              {key: "8br", text: "Islita", value: "8"},
              {key: "9br", text: "Jabilla", value: "9"},
              {key: "10br", text: "Jabillos", value: "10"},
              {key: "11br", text: "Maicillal", value: "11"},
              {key: "12br", text: "Maquencal", value: "12"},
              {key: "13br", text: "Milagro", value: "13"},
              {key: "14br", text: "Millal", value: "14"},
              {key: "15br", text: "Mono", value: "15"},
              {key: "16br", text: "Pampas", value: "16"},
              {key: "17br", text: "Paso Vigas", value: "17"},
              {key: "18br", text: "Pencal", value: "18"},
              {key: "19br", text: "Playa Coyote", value: "19"},
              {key: "20br", text: "Playa San Miguel", value: "20"},
              {key: "21br", text: "Pueblo Nuevo", value: "21"},
              {key: "22br", text: "Punta Bejuco", value: "22"},
              {key: "23br", text: "Puerto Coyote", value: "23"},
              {key: "24br", text: "Quebrada Nando", value: "24"},
              {key: "25br", text: "Quebrada Seca", value: "25"},
              {key: "26br", text: "Rancho Floriana", value: "26"},
              {key: "27br", text: "San Francisco De Coyote", value: "27"},
              {key: "28br", text: "San Gabriel", value: "28"},
              {key: "29br", text: "San Miguel", value: "29"},
              {key: "30br", text: "Triunfo", value: "30"},
              {key: "31br", text: "Zapote", value: "31"}]}]},
      {key: "10cn", text: "La Cruz", value: "10", distritos: [
          {key: "1ds", text: "La Cruz", value: "1", barrios: [
              {key: "1br", text: "La Cruz Centro", value: "1"},
              {key: "2br", text: "Bellavista", value: "2"},
              {key: "3br", text: "Bello Horizonte", value: "3"},
              {key: "4br", text: "Brisas", value: "4"},
              {key: "5br", text: "Cacao", value: "5"},
              {key: "6br", text: "Carrizal", value: "6"},
              {key: "7br", text: "Carrizales", value: "7"},
              {key: "8br", text: "Colonia Bolaños", value: "8"},
              {key: "9br", text: "Copalchí", value: "9"},
              {key: "10br", text: "Corazón De Jesús", value: "10"},
              {key: "11br", text: "Fátima", value: "11"},
              {key: "12br", text: "Infierno", value: "12"},
              {key: "13br", text: "Irving", value: "13"},
              {key: "14br", text: "Jobo", value: "14"},
              {key: "15br", text: "Libertad", value: "15"},
              {key: "16br", text: "Monte Plata", value: "16"},
              {key: "17br", text: "Montes De Oro", value: "17"},
              {key: "18br", text: "Orosí", value: "18"},
              {key: "19br", text: "Pampa", value: "19"},
              {key: "20br", text: "Pegón", value: "20"},
              {key: "21br", text: "Peñas Blancas", value: "21"},
              {key: "22br", text: "Piedra Pómez", value: "22"},
              {key: "23br", text: "Pinos", value: "23"},
              {key: "24br", text: "Puerto Soley", value: "24"},
              {key: "25br", text: "Recreo", value: "25"},
              {key: "26br", text: "San Buenaventura", value: "26"},
              {key: "27br", text: "San Dimas", value: "27"},
              {key: "28br", text: "San Paco", value: "28"},
              {key: "29br", text: "San Roque", value: "29"},
              {key: "30br", text: "Santa Rogelia", value: "30"},
              {key: "31br", text: "Santa Rosa", value: "31"},
              {key: "32br", text: "Santa Rosa", value: "32"},
              {key: "33br", text: "Soley", value: "33"},
              {key: "34br", text: "Sonzapote", value: "34"},
              {key: "35br", text: "Tempatal", value: "35"},
              {key: "36br", text: "Vueltas", value: "36"}]},
          {key: "2ds", text: "Santa Cecilia", value: "2", barrios: [
              {key: "1br", text: "Santa Cecilia Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Argendora", value: "3"},
              {key: "4br", text: "Armenia", value: "4"},
              {key: "5br", text: "Belice", value: "5"},
              {key: "6br", text: "Bellavista", value: "6"},
              {key: "7br", text: "Brisas", value: "7"},
              {key: "8br", text: "Caoba", value: "8"},
              {key: "9br", text: "Corrales Negros", value: "9"},
              {key: "10br", text: "Esperanza", value: "10"},
              {key: "11br", text: "Flor Del Norte", value: "11"},
              {key: "12br", text: "Lajosa", value: "12"},
              {key: "13br", text: "Marías", value: "13"},
              {key: "14br", text: "Palmares", value: "14"},
              {key: "15br", text: "Pueblo Nuevo", value: "15"},
              {key: "16br", text: "San Antonio", value: "16"},
              {key: "17br", text: "San Cristóbal", value: "17"},
              {key: "18br", text: "San Rafael", value: "18"},
              {key: "19br", text: "San Vicente", value: "19"},
              {key: "20br", text: "Santa Elena", value: "20"},
              {key: "21br", text: "Sardina", value: "21"},
              {key: "22br", text: "Virgen", value: "22"}]},
          {key: "3ds", text: "La Garita", value: "3", barrios: [
              {key: "1br", text: "La Garita Centro", value: "1"},
              {key: "2br", text: "Agua Muerta", value: "2"},
              {key: "3br", text: "Andes", value: "3"},
              {key: "4br", text: "Asilo", value: "4"},
              {key: "5br", text: "Cañita", value: "5"},
              {key: "6br", text: "Carmen", value: "6"},
              {key: "7br", text: "Fortuna", value: "7"},
              {key: "8br", text: "Gloria", value: "8"},
              {key: "9br", text: "Guapinol", value: "9"},
              {key: "10br", text: "Inocentes", value: "10"},
              {key: "11br", text: "Lavaderos", value: "11"},
              {key: "12br", text: "Paraíso", value: "12"},
              {key: "13br", text: "Pochote", value: "13"},
              {key: "14br", text: "San Antonio", value: "14"},
              {key: "15br", text: "Tapesco", value: "15"}]},
          {key: "4ds", text: "Santa Elena", value: "4", barrios: [
              {key: "1br", text: "Cuajiniquil", value: "1"},
              {key: "2br", text: "Cedros", value: "2"},
              {key: "3br", text: "Guaria", value: "3"},
              {key: "4br", text: "Puerto Castilla", value: "4"},
              {key: "5br", text: "Rabo De Mico (aguacaliente)", value: "5"}]}]},
      {key: "11cn", text: "Hojancha", value: "11", distritos: [
          {key: "1ds", text: "Hojancha", value: "1", barrios: [
              {key: "1br", text: "Hojancha Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Arena", value: "3"},
              {key: "4br", text: "Ceiba", value: "4"},
              {key: "5br", text: "Cuesta Blanca", value: "5"},
              {key: "6br", text: "Libertad", value: "6"},
              {key: "7br", text: "Maravilla", value: "7"},
              {key: "8br", text: "Palo De Jabón", value: "8"},
              {key: "9br", text: "Pilangosta", value: "9"},
              {key: "10br", text: "San Juan Bosco", value: "10"},
              {key: "11br", text: "San Rafael", value: "11"},
              {key: "12br", text: "Santa Elena (parte)", value: "12"},
              {key: "13br", text: "Varillal", value: "13"}]},
          {key: "2ds", text: "Monte Romo", value: "2", barrios: [
              {key: "1br", text: "Monte Romo Centro", value: "1"},
              {key: "2br", text: "Altos Del Socorro", value: "2"},
              {key: "3br", text: "Bajo Saltos", value: "3"},
              {key: "4br", text: "Cabrera", value: "4"},
              {key: "5br", text: "Cuesta Roja", value: "5"},
              {key: "6br", text: "Delicias", value: "6"},
              {key: "7br", text: "Guapinol", value: "7"},
              {key: "8br", text: "Loros", value: "8"},
              {key: "9br", text: "Mercedes", value: "9"},
              {key: "10br", text: "Palmares", value: "10"},
              {key: "11br", text: "Río Zapotal", value: "11"},
              {key: "12br", text: "San Isidro", value: "12"},
              {key: "13br", text: "Trinidad", value: "13"}]},
          {key: "3ds", text: "Puerto Carrillo", value: "3", barrios: [
              {key: "1br", text: "Puerto Carrillo Centro", value: "1"},
              {key: "2br", text: "Angostura", value: "2"},
              {key: "3br", text: "Arbolito", value: "3"},
              {key: "4br", text: "Cuesta Malanoche", value: "4"},
              {key: "5br", text: "Estrada", value: "5"},
              {key: "6br", text: "Jobo", value: "6"},
              {key: "7br", text: "Lajas", value: "7"},
              {key: "8br", text: "Quebrada Bonita (parte)", value: "8"},
              {key: "9br", text: "San Miguel", value: "9"},
              {key: "10br", text: "Santa María", value: "10"}]},
          {key: "4ds", text: "Huacas", value: "4", barrios: [
              {key: "1br", text: "Huacas Centro", value: "1"},
              {key: "2br", text: "Avellana", value: "2"},
              {key: "3br", text: "Pita Rayada", value: "3"},
              {key: "4br", text: "Río Blanco Oeste", value: "4"},
              {key: "5br", text: "Tres Quebradas", value: "5"}]},
          {key: "5ds", text: "Matambú", value: "5", barrios: [
              {key: "1br", text: "Matambú Centro", value: "1"},
              {key: "2br", text: "Ceibo", value: "2"},
              {key: "3br", text: "Esquipulas", value: "3"},
              {key: "4br", text: "Polvazales (cerritos) (parte)", value: "4"},
              {key: "5br", text: "Vegas", value: "5"}]}]}]},
  {key: "6pr", text: "Puntarenas", value: "6", cantones: [
      {key: "1cn", text: "Puntarenas", value: "1", distritos: [
          {key: "1ds", text: "Puntarenas", value: "1", barrios: [
              {key: "1br", text: "Puntarenas Centro", value: "1"},
              {key: "2br", text: "Angostura", value: "2"},
              {key: "3br", text: "Carmen", value: "3"},
              {key: "4br", text: "Cocal", value: "4"},
              {key: "5br", text: "Playitas", value: "5"},
              {key: "6br", text: "Pochote", value: "6"},
              {key: "7br", text: "Pueblo Nuevo", value: "7"},
              {key: "8br", text: "Isla Bejuco", value: "8"},
              {key: "9br", text: "Isla Caballo", value: "9"},
              {key: "10br", text: "Palmar", value: "10"}]},
          {key: "2ds", text: "Pitahaya", value: "2", barrios: [
              {key: "1br", text: "Pitahaya Centro", value: "1"},
              {key: "2br", text: "Aranjuéz", value: "2"},
              {key: "3br", text: "Brillante (parte)", value: "3"},
              {key: "4br", text: "Cebadilla", value: "4"},
              {key: "5br", text: "Chapernal", value: "5"},
              {key: "6br", text: "Palermo", value: "6"},
              {key: "7br", text: "Pitahaya Vieja", value: "7"},
              {key: "8br", text: "Rancho Grande", value: "8"},
              {key: "9br", text: "San Marcos (parte)", value: "9"},
              {key: "10br", text: "Zapotal", value: "10"}]},
          {key: "3ds", text: "Chomes", value: "3", barrios: [
              {key: "1br", text: "Chomes Centro", value: "1"},
              {key: "2br", text: "Alto Pie De Paloma", value: "2"},
              {key: "3br", text: "Cambalache", value: "3"},
              {key: "4br", text: "Cocoroca", value: "4"},
              {key: "5br", text: "Coyoles Motos", value: "5"},
              {key: "6br", text: "Don Jaime", value: "6"},
              {key: "7br", text: "Jarquín (parte)", value: "7"},
              {key: "8br", text: "Judas", value: "8"},
              {key: "9br", text: "Laberinto", value: "9"},
              {key: "10br", text: "Lagarto", value: "10"},
              {key: "11br", text: "Malinche", value: "11"},
              {key: "12br", text: "Morales", value: "12"},
              {key: "13br", text: "Pita", value: "13"},
              {key: "14br", text: "Playa Coco", value: "14"},
              {key: "15br", text: "Pocitos", value: "15"},
              {key: "16br", text: "Punta Morales", value: "16"},
              {key: "17br", text: "San Agustín", value: "17"},
              {key: "18br", text: "San Gerardo", value: "18"},
              {key: "19br", text: "Santa Juana", value: "19"},
              {key: "20br", text: "Sarmiento", value: "20"},
              {key: "21br", text: "Terrero", value: "21"},
              {key: "22br", text: "Vanegas", value: "22"},
              {key: "23br", text: "Yomalé", value: "23"}]},
          {key: "4ds", text: "Lepanto", value: "4", barrios: [
              {key: "1br", text: "Lepanto Centro", value: "1"},
              {key: "2br", text: "Alto Fresca", value: "2"},
              {key: "3br", text: "Bajo Mora", value: "3"},
              {key: "4br", text: "Balsa", value: "4"},
              {key: "5br", text: "Balso", value: "5"},
              {key: "6br", text: "Bijagua", value: "6"},
              {key: "7br", text: "Brisas", value: "7"},
              {key: "8br", text: "Cabo Blanco", value: "8"},
              {key: "9br", text: "Camaronal", value: "9"},
              {key: "10br", text: "Cantil", value: "10"},
              {key: "11br", text: "Cañablancal", value: "11"},
              {key: "12br", text: "Cerro Frío", value: "12"},
              {key: "13br", text: "Cerro Indio", value: "13"},
              {key: "14br", text: "Cerro Pando", value: "14"},
              {key: "15br", text: "Corozal", value: "15"},
              {key: "16br", text: "Coto", value: "16"},
              {key: "17br", text: "Cuajiniquil", value: "17"},
              {key: "18br", text: "Chanchos", value: "18"},
              {key: "19br", text: "Chiqueros", value: "19"},
              {key: "20br", text: "Dominica", value: "20"},
              {key: "21br", text: "El Mora", value: "21"},
              {key: "22br", text: "Encanto", value: "22"},
              {key: "23br", text: "Fresca", value: "23"},
              {key: "24br", text: "Gloria", value: "24"},
              {key: "25br", text: "Golfo", value: "25"},
              {key: "26br", text: "Guabo", value: "26"},
              {key: "27br", text: "Guadalupe", value: "27"},
              {key: "28br", text: "Ilusión", value: "28"},
              {key: "29br", text: "Isla Venado", value: "29"},
              {key: "30br", text: "Jicaral", value: "30"},
              {key: "31br", text: "Juan De León", value: "31"},
              {key: "32br", text: "Milpa", value: "32"},
              {key: "33br", text: "Montaña Grande", value: "33"},
              {key: "34br", text: "Níspero", value: "34"},
              {key: "35br", text: "Nubes", value: "35"},
              {key: "36br", text: "Once Estrellas", value: "36"},
              {key: "37br", text: "Piedades", value: "37"},
              {key: "38br", text: "Pilas De Canjel", value: "38"},
              {key: "39br", text: "Punta De Cera", value: "39"},
              {key: "40br", text: "Río Seco", value: "40"},
              {key: "41br", text: "Sahíno", value: "41"},
              {key: "42br", text: "San Blas", value: "42"},
              {key: "43br", text: "San Miguel", value: "43"},
              {key: "44br", text: "San Miguel De Río Blanco", value: "44"},
              {key: "45br", text: "San Pedro", value: "45"},
              {key: "46br", text: "San Rafael", value: "46"},
              {key: "47br", text: "San Ramón De Río Blanco", value: "47"},
              {key: "48br", text: "Santa Rosa", value: "48"},
              {key: "49br", text: "Tigra", value: "49"},
              {key: "50br", text: "Tres Ríos", value: "50"},
              {key: "51br", text: "Tronconal", value: "51"},
              {key: "52br", text: "Unión", value: "52"},
              {key: "53br", text: "Vainilla", value: "53"}]},
          {key: "5ds", text: "Paquera", value: "5", barrios: [
              {key: "1br", text: "Paquera Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Astro Blanco", value: "3"},
              {key: "4br", text: "Bajo Negro", value: "4"},
              {key: "5br", text: "Cabeceras De Río Seco", value: "5"},
              {key: "6br", text: "Campiñas", value: "6"},
              {key: "7br", text: "Cerro Brujo", value: "7"},
              {key: "8br", text: "Concepción", value: "8"},
              {key: "9br", text: "Curú", value: "9"},
              {key: "10br", text: "Dulce Nombre", value: "10"},
              {key: "11br", text: "Espaveles", value: "11"},
              {key: "12br", text: "Esperanza", value: "12"},
              {key: "13br", text: "Flor", value: "13"},
              {key: "14br", text: "Gigante", value: "14"},
              {key: "15br", text: "Guaria", value: "15"},
              {key: "16br", text: "Higueronal", value: "16"},
              {key: "17br", text: "Isla Cedros", value: "17"},
              {key: "18br", text: "Isla Jesucita", value: "18"},
              {key: "19br", text: "Isla Tortuga", value: "19"},
              {key: "20br", text: "Leona", value: "20"},
              {key: "21br", text: "Mango", value: "21"},
              {key: "22br", text: "Naranjo", value: "22"},
              {key: "23br", text: "Pánica", value: "23"},
              {key: "24br", text: "Paraíso", value: "24"},
              {key: "25br", text: "Playa Blanca", value: "25"},
              {key: "26br", text: "Playa Cuchillo", value: "26"},
              {key: "27br", text: "Pochote", value: "27"},
              {key: "28br", text: "Punta Del Río", value: "28"},
              {key: "29br", text: "Quebrada Bonita", value: "29"},
              {key: "30br", text: "Río Grande", value: "30"},
              {key: "31br", text: "Río Guarial", value: "31"},
              {key: "32br", text: "Río Seco", value: "32"},
              {key: "33br", text: "Rivas", value: "33"},
              {key: "34br", text: "San Fernando", value: "34"},
              {key: "35br", text: "San Luis", value: "35"},
              {key: "36br", text: "San Pedro", value: "36"},
              {key: "37br", text: "San Rafael", value: "37"},
              {key: "38br", text: "San Vicente", value: "38"},
              {key: "39br", text: "Santa Cecilia", value: "39"},
              {key: "40br", text: "Santa Lucía", value: "40"},
              {key: "41br", text: "Santa Rosa", value: "41"},
              {key: "42br", text: "Sonzapote", value: "42"},
              {key: "43br", text: "Tronco Negro", value: "43"},
              {key: "44br", text: "Valle Azul", value: "44"},
              {key: "45br", text: "Vueltas", value: "45"}]},
          {key: "6ds", text: "Manzanillo", value: "6", barrios: [
              {key: "1br", text: "Manzanillo Centro", value: "1"},
              {key: "2br", text: "Abangaritos", value: "2"},
              {key: "3br", text: "Camarita", value: "3"},
              {key: "4br", text: "Costa De Pájaros", value: "4"},
              {key: "5br", text: "Coyolito (parte)", value: "5"},
              {key: "6br", text: "Cuesta Portillo", value: "6"}]},
          {key: "7ds", text: "Guacimal", value: "7", barrios: [
              {key: "1br", text: "Guacimal Centro", value: "1"},
              {key: "2br", text: "Alto Méndez", value: "2"},
              {key: "3br", text: "Altos Fernández", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Guaria", value: "5"},
              {key: "6br", text: "Lajón", value: "6"},
              {key: "7br", text: "San Antonio", value: "7"},
              {key: "8br", text: "Santa Rosa", value: "8"},
              {key: "9br", text: "Surtubal", value: "9"},
              {key: "10br", text: "Veracruz", value: "10"}]},
          {key: "8ds", text: "Barranca", value: "8", barrios: [
              {key: "1br", text: "Barranca Centro", value: "1"},
              {key: "2br", text: "Obregón", value: "2"},
              {key: "3br", text: "Rioja", value: "3"},
              {key: "4br", text: "San Joaquín", value: "4"},
              {key: "5br", text: "San Miguel", value: "5"},
              {key: "6br", text: "San Miguelito", value: "6"},
              {key: "7br", text: "Santa Ana", value: "7"}]},
          {key: "9ds", text: "Monte Verde", value: "9", barrios: [
              {key: "1br", text: "Santa Elena", value: "1"},
              {key: "2br", text: "Cerro Plano", value: "2"},
              {key: "3br", text: "Cuesta Blanca", value: "3"},
              {key: "4br", text: "Lindora", value: "4"},
              {key: "5br", text: "Llanos", value: "5"},
              {key: "6br", text: "Monte Verde", value: "6"},
              {key: "7br", text: "San Luis", value: "7"}]},
          {key: "10ds", text: "Isla Del Coco", value: "10", barrios: [
              {key: "1br", text: "Isla Del Coco", value: "1"}]},
          {key: "11ds", text: "Cóbano", value: "11", barrios: [
              {key: "1br", text: "Cóbano Centro", value: "1"},
              {key: "2br", text: "Abuela", value: "2"},
              {key: "3br", text: "Arío", value: "3"},
              {key: "4br", text: "Bajos De Arío", value: "4"},
              {key: "5br", text: "Bajos De Fernández", value: "5"},
              {key: "6br", text: "Bello Horizonte", value: "6"},
              {key: "7br", text: "Betel", value: "7"},
              {key: "8br", text: "Cabuya", value: "8"},
              {key: "9br", text: "Canaán", value: "9"},
              {key: "10br", text: "Cañada", value: "10"},
              {key: "11br", text: "Caño Seco Abajo", value: "11"},
              {key: "12br", text: "Caño Seco Arriba", value: "12"},
              {key: "13br", text: "Caño Seco Enmedio", value: "13"},
              {key: "14br", text: "Carmen", value: "14"},
              {key: "15br", text: "Cedro", value: "15"},
              {key: "16br", text: "Cerital", value: "16"},
              {key: "17br", text: "Cerro Buenavista", value: "17"},
              {key: "18br", text: "Cocal", value: "18"},
              {key: "19br", text: "Cocalito", value: "19"},
              {key: "20br", text: "Delicias", value: "20"},
              {key: "21br", text: "Malpaís", value: "21"},
              {key: "22br", text: "Montezuma", value: "22"},
              {key: "23br", text: "Muelle", value: "23"},
              {key: "24br", text: "Pachanga", value: "24"},
              {key: "25br", text: "Pavón", value: "25"},
              {key: "26br", text: "Pénjamo", value: "26"},
              {key: "27br", text: "Piedra Amarilla", value: "27"},
              {key: "28br", text: "Pita", value: "28"},
              {key: "29br", text: "Río Enmedio", value: "29"},
              {key: "30br", text: "Río Frío", value: "30"},
              {key: "31br", text: "Río Negro", value: "31"},
              {key: "32br", text: "San Antonio", value: "32"},
              {key: "33br", text: "San Isidro", value: "33"},
              {key: "34br", text: "San Jorge", value: "34"},
              {key: "35br", text: "San Ramón", value: "35"},
              {key: "36br", text: "Santa Clemencia", value: "36"},
              {key: "37br", text: "Santa Fe", value: "37"},
              {key: "38br", text: "Santa Teresa", value: "38"},
              {key: "39br", text: "Santiago", value: "39"},
              {key: "40br", text: "Tacotales", value: "40"},
              {key: "41br", text: "Tambor", value: "41"},
              {key: "42br", text: "Villalta", value: "42"}]},
          {key: "12ds", text: "Chacarita", value: "12", barrios: [
              {key: "1br", text: "Chacarita Centro", value: "1"},
              {key: "2br", text: "Camboya", value: "2"},
              {key: "3br", text: "Carrizal", value: "3"},
              {key: "4br", text: "Chacarita", value: "4"},
              {key: "5br", text: "Chacarita Norte", value: "5"},
              {key: "6br", text: "Fertica", value: "6"},
              {key: "7br", text: "Fray Casiano", value: "7"},
              {key: "8br", text: "Huerto", value: "8"},
              {key: "9br", text: "Lindavista", value: "9"},
              {key: "10br", text: "Pueblo Redondo", value: "10"},
              {key: "11br", text: "Reyes", value: "11"},
              {key: "12br", text: "San Isidro", value: "12"},
              {key: "13br", text: "Santa Eduvigis", value: "13"},
              {key: "14br", text: "Tanque", value: "14"},
              {key: "15br", text: "Veinte De Noviembre", value: "15"}]},
          {key: "13ds", text: "Chira", value: "13", barrios: [
              {key: "1br", text: "Nancite", value: "1"},
              {key: "2br", text: "Bocana", value: "2"},
              {key: "3br", text: "Lagartero", value: "3"},
              {key: "4br", text: "Montero", value: "4"},
              {key: "5br", text: "Pilas", value: "5"},
              {key: "6br", text: "Pochote", value: "6"},
              {key: "7br", text: "Puerto Coloradito", value: "7"},
              {key: "8br", text: "Puerto Mauricio", value: "8"},
              {key: "9br", text: "Puerto Palito", value: "9"}]},
          {key: "14ds", text: "Acapulco", value: "14", barrios: [
              {key: "1br", text: "Sardinal", value: "1"},
              {key: "2br", text: "Acapulco", value: "2"},
              {key: "3br", text: "Aranjuecito", value: "3"},
              {key: "4br", text: "Chapernalito", value: "4"},
              {key: "5br", text: "Claraboya", value: "5"},
              {key: "6br", text: "Coyolar", value: "6"},
              {key: "7br", text: "Quebrada Honda", value: "7"},
              {key: "8br", text: "San Marcos (parte)", value: "8"}]},
          {key: "15ds", text: "El Roble", value: "15", barrios: [
              {key: "1br", text: "El Roble Centro", value: "1"},
              {key: "2br", text: "Boca De Barranca", value: "2"},
              {key: "3br", text: "Chagüite", value: "3"},
              {key: "4br", text: "El Roble", value: "4"}]},
          {key: "16ds", text: "Arancibia", value: "16", barrios: [
              {key: "1br", text: "Bajo Caliente (parte)", value: "1"},
              {key: "2br", text: "Arancibia Norte", value: "2"},
              {key: "3br", text: "Arancibia Sur", value: "3"},
              {key: "4br", text: "Lagunas", value: "4"},
              {key: "5br", text: "Ojo De Agua", value: "5"},
              {key: "6br", text: "Rincón", value: "6"},
              {key: "7br", text: "San Martín Norte", value: "7"},
              {key: "8br", text: "San Martín Sur", value: "8"}]}]},
      {key: "2cn", text: "Esparza", value: "2", distritos: [
          {key: "1ds", text: "Espíritu Santo", value: "1", barrios: [
              {key: "1br", text: "Esparza Centro", value: "1"},
              {key: "2br", text: "Gregg", value: "2"},
              {key: "3br", text: "Humo", value: "3"},
              {key: "4br", text: "Marañonal (parte)", value: "4"},
              {key: "5br", text: "Mojón", value: "5"},
              {key: "6br", text: "Mojoncito", value: "6"},
              {key: "7br", text: "Pan De Azúcar", value: "7"},
              {key: "8br", text: "Tejar", value: "8"}]},
          {key: "2ds", text: "San Juan Grande", value: "2", barrios: [
              {key: "1br", text: "San Juan Grande Centro", value: "1"},
              {key: "2br", text: "Jocote", value: "2"},
              {key: "3br", text: "Juanilama", value: "3"},
              {key: "4br", text: "San Juan Chiquito", value: "4"}]},
          {key: "3ds", text: "Macacona", value: "3", barrios: [
              {key: "1br", text: "Macacona Centro", value: "1"},
              {key: "2br", text: "Bruselas", value: "2"},
              {key: "3br", text: "Guapinol", value: "3"},
              {key: "4br", text: "Marañonal (parte)", value: "4"},
              {key: "5br", text: "Nances", value: "5"},
              {key: "6br", text: "San Roque", value: "6"}]},
          {key: "4ds", text: "San Rafael", value: "4", barrios: [
              {key: "1br", text: "San Rafael Centro", value: "1"},
              {key: "2br", text: "Alto Corteza", value: "2"},
              {key: "3br", text: "Barón", value: "3"},
              {key: "4br", text: "Facio", value: "4"},
              {key: "5br", text: "Llanada Del Cacao", value: "5"},
              {key: "6br", text: "Maratón", value: "6"}]},
          {key: "5ds", text: "San Jerónimo", value: "5", barrios: [
              {key: "1br", text: "San Jerónimo Centro", value: "1"},
              {key: "2br", text: "Cerrillos", value: "2"},
              {key: "3br", text: "Mesetas Abajo", value: "3"},
              {key: "4br", text: "Mesetas Arriba", value: "4"},
              {key: "5br", text: "Peña Blanca", value: "5"},
              {key: "6br", text: "Pretiles", value: "6"},
              {key: "7br", text: "Quebradas", value: "7"},
              {key: "8br", text: "Sabana Bonita", value: "8"}]},
          {key: "6ds", text: "Caldera", value: "6", barrios: [
              {key: "1br", text: "Mata De Limón", value: "1"},
              {key: "2br", text: "Alto De Las Mesas", value: "2"},
              {key: "3br", text: "Artieda", value: "3"},
              {key: "4br", text: "Caldera", value: "4"},
              {key: "5br", text: "Cabezas", value: "5"},
              {key: "6br", text: "Cambalache", value: "6"},
              {key: "7br", text: "Cascabel", value: "7"},
              {key: "8br", text: "Corralillo", value: "8"},
              {key: "9br", text: "Cuesta Jocote", value: "9"},
              {key: "10br", text: "Figueroa", value: "10"},
              {key: "11br", text: "Finca Brazo Seco", value: "11"},
              {key: "12br", text: "Finca Cortijo", value: "12"},
              {key: "13br", text: "Guardianes De La Piedra", value: "13"},
              {key: "14br", text: "Hacienda La Moncha", value: "14"},
              {key: "15br", text: "Hacienda Mata De Guinea", value: "15"},
              {key: "16br", text: "Hacienda Playa Linda", value: "16"},
              {key: "17br", text: "Hacienda Salinas", value: "17"},
              {key: "18br", text: "Jesús María", value: "18"},
              {key: "19br", text: "Quebrada Honda", value: "19"},
              {key: "20br", text: "Salinas", value: "20"},
              {key: "21br", text: "San Antonio", value: "21"},
              {key: "22br", text: "Silencio", value: "22"},
              {key: "23br", text: "Tivives", value: "23"},
              {key: "24br", text: "Villanueva", value: "24"}]}]},
      {key: "3cn", text: "Buenos Aires", value: "3", distritos: [
          {key: "1ds", text: "Buenos Aires", value: "1", barrios: [
              {key: "1br", text: "Buenos Aires Centro", value: "1"},
              {key: "2br", text: "Alto Buenos Aires", value: "2"},
              {key: "3br", text: "Lomas", value: "3"},
              {key: "4br", text: "Alto Alejo", value: "4"},
              {key: "5br", text: "Alto Brisas", value: "5"},
              {key: "6br", text: "Alto Calderón", value: "6"},
              {key: "7br", text: "Bajo Brisas", value: "7"},
              {key: "8br", text: "Bolas", value: "8"},
              {key: "9br", text: "Brujo", value: "9"},
              {key: "10br", text: "Cabagra (parte)", value: "10"},
              {key: "11br", text: "Caracol", value: "11"},
              {key: "12br", text: "Ceibo", value: "12"},
              {key: "13br", text: "Colepato", value: "13"},
              {key: "14br", text: "El Carmen", value: "14"},
              {key: "15br", text: "Guanacaste", value: "15"},
              {key: "16br", text: "Guadalupe", value: "16"},
              {key: "17br", text: "López", value: "17"},
              {key: "18br", text: "Los Altos", value: "18"},
              {key: "19br", text: "Llano Verde", value: "19"},
              {key: "20br", text: "Machomontes", value: "20"},
              {key: "21br", text: "Palmital", value: "21"},
              {key: "22br", text: "Paraíso (ánimas)", value: "22"},
              {key: "23br", text: "Paso Verbá", value: "23"},
              {key: "24br", text: "Piñera", value: "24"},
              {key: "25br", text: "Platanares", value: "25"},
              {key: "26br", text: "Potrero Cerrado", value: "26"},
              {key: "27br", text: "Puente De Salitre", value: "27"},
              {key: "28br", text: "Río Azul", value: "28"},
              {key: "29br", text: "Salitre", value: "29"},
              {key: "30br", text: "San Carlos", value: "30"},
              {key: "31br", text: "San Luis (florida)", value: "31"},
              {key: "32br", text: "San Miguel Este", value: "32"},
              {key: "33br", text: "San Miguel Oeste", value: "33"},
              {key: "34br", text: "San Vicente", value: "34"},
              {key: "35br", text: "Santa Cruz", value: "35"},
              {key: "36br", text: "Santa Eduvigis", value: "36"},
              {key: "37br", text: "Santa Marta", value: "37"},
              {key: "38br", text: "Sipar", value: "38"},
              {key: "39br", text: "Ujarrás", value: "39"},
              {key: "40br", text: "Villahermosa", value: "40"},
              {key: "41br", text: "Yheri", value: "41"}]},
          {key: "2ds", text: "Volcán", value: "2", barrios: [
              {key: "1br", text: "Volcán Centro", value: "1"},
              {key: "2br", text: "Altamira", value: "2"},
              {key: "3br", text: "Ángel Arriba", value: "3"},
              {key: "4br", text: "Bajos Del Río Grande", value: "4"},
              {key: "5br", text: "Cacao", value: "5"},
              {key: "6br", text: "Convento", value: "6"},
              {key: "7br", text: "Cordoncillo", value: "7"},
              {key: "8br", text: "Los Ángeles", value: "8"},
              {key: "9br", text: "Longo Mai", value: "9"},
              {key: "10br", text: "Peje", value: "10"},
              {key: "11br", text: "Quebradas", value: "11"},
              {key: "12br", text: "Río Grande", value: "12"},
              {key: "13br", text: "Sabanilla", value: "13"},
              {key: "14br", text: "Sonador", value: "14"},
              {key: "15br", text: "Tarise", value: "15"},
              {key: "16br", text: "Tres Ríos", value: "16"},
              {key: "17br", text: "Ultrapez", value: "17"}]},
          {key: "3ds", text: "Potrero Grande", value: "3", barrios: [
              {key: "1br", text: "Potrero Grande Centro", value: "1"},
              {key: "2br", text: "Alto La Cruz", value: "2"},
              {key: "3br", text: "Alto Tigre", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Boca Limón", value: "5"},
              {key: "6br", text: "Brazos De Oro", value: "6"},
              {key: "7br", text: "Cabagra (parte)", value: "7"},
              {key: "8br", text: "Campo Alegre", value: "8"},
              {key: "9br", text: "Capri", value: "9"},
              {key: "10br", text: "Caracol", value: "10"},
              {key: "11br", text: "Caracucho", value: "11"},
              {key: "12br", text: "Clavera", value: "12"},
              {key: "13br", text: "Colegallo", value: "13"},
              {key: "14br", text: "Copal", value: "14"},
              {key: "15br", text: "Coto Brus (parte)", value: "15"},
              {key: "16br", text: "Cuesta Marañones", value: "16"},
              {key: "17br", text: "Delicias", value: "17"},
              {key: "18br", text: "Garrote", value: "18"},
              {key: "19br", text: "Guácimo", value: "19"},
              {key: "20br", text: "Guaria", value: "20"},
              {key: "21br", text: "Helechales", value: "21"},
              {key: "22br", text: "Jabillo", value: "22"},
              {key: "23br", text: "Jorón", value: "23"},
              {key: "24br", text: "Juntas", value: "24"},
              {key: "25br", text: "Lucha", value: "25"},
              {key: "26br", text: "Maravilla", value: "26"},
              {key: "27br", text: "Mesas", value: "27"},
              {key: "28br", text: "Mirador", value: "28"},
              {key: "29br", text: "Montelimar", value: "29"},
              {key: "30br", text: "Mosca", value: "30"},
              {key: "31br", text: "Palmira", value: "31"},
              {key: "32br", text: "Paso Real", value: "32"},
              {key: "33br", text: "Peje", value: "33"},
              {key: "34br", text: "Pita", value: "34"},
              {key: "35br", text: "Platanillal", value: "35"},
              {key: "36br", text: "Quijada", value: "36"},
              {key: "37br", text: "Río Coto", value: "37"},
              {key: "38br", text: "San Antonio", value: "38"},
              {key: "39br", text: "San Carlos", value: "39"},
              {key: "40br", text: "San Rafael De Cabagra", value: "40"},
              {key: "41br", text: "Santa Cecilia", value: "41"},
              {key: "42br", text: "Singri", value: "42"},
              {key: "43br", text: "Tablas", value: "43"},
              {key: "44br", text: "Tamarindo", value: "44"},
              {key: "45br", text: "Térraba", value: "45"},
              {key: "46br", text: "Tres Colinas", value: "46"},
              {key: "47br", text: "Tierras Negras", value: "47"},
              {key: "48br", text: "Volcancito", value: "48"},
              {key: "49br", text: "Vueltas", value: "49"}]},
          {key: "4ds", text: "Boruca", value: "4", barrios: [
              {key: "1br", text: "Boruca Centro", value: "1"},
              {key: "2br", text: "Alto Del Mojón", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Cajón", value: "4"},
              {key: "5br", text: "Curré", value: "5"},
              {key: "6br", text: "Chamba", value: "6"},
              {key: "7br", text: "Changuenita", value: "7"},
              {key: "8br", text: "Doboncragua", value: "8"},
              {key: "9br", text: "Iguana", value: "9"},
              {key: "10br", text: "Kuibín", value: "10"},
              {key: "11br", text: "Lagarto", value: "11"},
              {key: "12br", text: "Mano De Tigre", value: "12"},
              {key: "13br", text: "Miravalles", value: "13"},
              {key: "14br", text: "Ojo De Agua (parte)", value: "14"},
              {key: "15br", text: "Presa", value: "15"},
              {key: "16br", text: "Puerto Nuevo", value: "16"},
              {key: "17br", text: "Sabanas (barranco) (parte)", value: "17"},
              {key: "18br", text: "San Joaquín", value: "18"},
              {key: "19br", text: "Santa Cruz", value: "19"},
              {key: "20br", text: "Tigre", value: "20"},
              {key: "21br", text: "Tres Ríos", value: "21"}]},
          {key: "5ds", text: "Pilas", value: "5", barrios: [
              {key: "1br", text: "Pilas Centro", value: "1"},
              {key: "2br", text: "Alto Pilas", value: "2"},
              {key: "3br", text: "Bajo Pilas", value: "3"},
              {key: "4br", text: "Bijagual", value: "4"},
              {key: "5br", text: "Ceibón", value: "5"},
              {key: "6br", text: "Concepción (la Danta)", value: "6"},
              {key: "7br", text: "Dibujada", value: "7"},
              {key: "8br", text: "Fortuna", value: "8"},
              {key: "9br", text: "La Gloria (los Mangos)", value: "9"},
              {key: "10br", text: "Laguna", value: "10"},
              {key: "11br", text: "Ojo De Agua", value: "11"},
              {key: "12br", text: "Paso La Tinta", value: "12"},
              {key: "13br", text: "Pueblo Nuevo", value: "13"},
              {key: "14br", text: "Sabanas (barranco Parte)", value: "14"},
              {key: "15br", text: "Silencio", value: "15"},
              {key: "16br", text: "Tumbas", value: "16"}]},
          {key: "6ds", text: "Colinas", value: "6", barrios: [
              {key: "1br", text: "Colinas Centro (maíz De Los Uva)", value: "1"},
              {key: "2br", text: "Aguas Frescas", value: "2"},
              {key: "3br", text: "Alto Esmeralda", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Bajo Dioses", value: "5"},
              {key: "6br", text: "Bajo Maíz", value: "6"},
              {key: "7br", text: "Bolsa", value: "7"},
              {key: "8br", text: "Cedral (boquete)", value: "8"},
              {key: "9br", text: "Escuadra", value: "9"},
              {key: "10br", text: "Filadelfia (aguabuena)", value: "10"},
              {key: "11br", text: "Guagaral", value: "11"},
              {key: "12br", text: "Jabillo", value: "12"},
              {key: "13br", text: "Jalisco", value: "13"},
              {key: "14br", text: "Laguna", value: "14"},
              {key: "15br", text: "Lajas", value: "15"},
              {key: "16br", text: "Maíz De Boruca", value: "16"},
              {key: "17br", text: "Mallal", value: "17"},
              {key: "18br", text: "Nubes", value: "18"},
              {key: "19br", text: "Ojo De Agua (parte)", value: "19"},
              {key: "20br", text: "San Luis", value: "20"},
              {key: "21br", text: "Virgen", value: "21"}]},
          {key: "7ds", text: "Chánguena", value: "7", barrios: [
              {key: "1br", text: "Chánguena Centro (la Fila)", value: "1"},
              {key: "2br", text: "Alto Cacao", value: "2"},
              {key: "3br", text: "Bajo Mamey", value: "3"},
              {key: "4br", text: "Bonga", value: "4"},
              {key: "5br", text: "Cacique", value: "5"},
              {key: "6br", text: "Cantú", value: "6"},
              {key: "7br", text: "Cruces", value: "7"},
              {key: "8br", text: "Limón", value: "8"},
              {key: "9br", text: "Paraíso", value: "9"},
              {key: "10br", text: "Pataste", value: "10"},
              {key: "11br", text: "Pilón", value: "11"},
              {key: "12br", text: "Quebrada Bonita", value: "12"},
              {key: "13br", text: "San Luis", value: "13"},
              {key: "14br", text: "Santa Lucía", value: "14"},
              {key: "15br", text: "Santa María", value: "15"},
              {key: "16br", text: "Tres Ríos", value: "16"},
              {key: "17br", text: "Vegas De Chánguena", value: "17"},
              {key: "18br", text: "Vuelta Campana", value: "18"},
              {key: "19br", text: "Zapotal", value: "19"}]},
          {key: "8ds", text: "Biolley", value: "8", barrios: [
              {key: "1br", text: "Colorado Centro", value: "1"},
              {key: "2br", text: "Almácigo", value: "2"},
              {key: "3br", text: "Altamira", value: "3"},
              {key: "4br", text: "Alto Sábalo", value: "4"},
              {key: "5br", text: "Bajo Sábalo", value: "5"},
              {key: "6br", text: "Bajos De Coto", value: "6"},
              {key: "7br", text: "Biolley", value: "7"},
              {key: "8br", text: "Carmen", value: "8"},
              {key: "9br", text: "Hamacas", value: "9"},
              {key: "10br", text: "Guayacán", value: "10"},
              {key: "11br", text: "Manzano", value: "11"},
              {key: "12br", text: "Naranjos", value: "12"},
              {key: "13br", text: "Puna", value: "13"}]},
          {key: "9ds", text: "Brunka", value: "9", barrios: [
              {key: "1br", text: "Santa Marta", value: "1"},
              {key: "2br", text: "Achiote", value: "2"},
              {key: "3br", text: "Alto Achiote", value: "3"},
              {key: "4br", text: "Cañas", value: "4"},
              {key: "5br", text: "Guadalajara", value: "5"},
              {key: "6br", text: "Llano Bonito", value: "6"},
              {key: "7br", text: "Oasis", value: "7"},
              {key: "8br", text: "San Rafael", value: "8"},
              {key: "9br", text: "Santa Cecilia", value: "9"},
              {key: "10br", text: "Santa María", value: "10"},
              {key: "11br", text: "Santa Rosa", value: "11"},
              {key: "12br", text: "Socorro", value: "12"}]}]},
      {key: "4cn", text: "Montes De Oro", value: "4", distritos: [
          {key: "1ds", text: "Miramar", value: "1", barrios: [
              {key: "1br", text: "Miramar Centro", value: "1"},
              {key: "2br", text: "Alto Pavones", value: "2"},
              {key: "3br", text: "Bajo Zamora", value: "3"},
              {key: "4br", text: "Barbudal", value: "4"},
              {key: "5br", text: "Bellavista", value: "5"},
              {key: "6br", text: "Brillante (parte)", value: "6"},
              {key: "7br", text: "Cabuyal", value: "7"},
              {key: "8br", text: "Delicias", value: "8"},
              {key: "9br", text: "Fraijanes", value: "9"},
              {key: "10br", text: "Lagunilla", value: "10"},
              {key: "11br", text: "Río Seco", value: "11"},
              {key: "12br", text: "Tajo Alto", value: "12"},
              {key: "13br", text: "Trinidad", value: "13"},
              {key: "14br", text: "Zagala Vieja", value: "14"},
              {key: "15br", text: "Zamora", value: "15"},
              {key: "16br", text: "Zapotal (parte)", value: "16"}]},
          {key: "2ds", text: "La Unión", value: "2", barrios: [
              {key: "1br", text: "La Unión Centro", value: "1"},
              {key: "2br", text: "Bajo Caliente (parte)", value: "2"},
              {key: "3br", text: "Cedral", value: "3"},
              {key: "4br", text: "Laguna", value: "4"},
              {key: "5br", text: "Micas", value: "5"},
              {key: "6br", text: "Palmital", value: "6"},
              {key: "7br", text: "San Buenaventura", value: "7"},
              {key: "8br", text: "Velásquez", value: "8"},
              {key: "9br", text: "Ventanas", value: "9"},
              {key: "10br", text: "Zagala Nueva", value: "10"}]},
          {key: "3ds", text: "San Isidro", value: "3", barrios: [
              {key: "1br", text: "San Isidro Centro", value: "1"},
              {key: "2br", text: "Aguabuena", value: "2"},
              {key: "3br", text: "Ciruelas", value: "3"},
              {key: "4br", text: "Cuatro Cruces", value: "4"},
              {key: "5br", text: "Isla", value: "5"},
              {key: "6br", text: "Santa Rosa", value: "6"},
              {key: "7br", text: "Tiocinto", value: "7"}]}]},
      {key: "5cn", text: "Osa", value: "5", distritos: [
          {key: "1ds", text: "Puerto Cortés", value: "1", barrios: [
              {key: "1br", text: "Puerto Cortés Centro", value: "1"},
              {key: "2br", text: "Balsar", value: "2"},
              {key: "3br", text: "Bocabrava", value: "3"},
              {key: "4br", text: "Bocachica", value: "4"},
              {key: "5br", text: "Canadá", value: "5"},
              {key: "6br", text: "Cementerio", value: "6"},
              {key: "7br", text: "Cerrón", value: "7"},
              {key: "8br", text: "Chontales", value: "8"},
              {key: "9br", text: "Cinco Esquinas", value: "9"},
              {key: "10br", text: "Coronado", value: "10"},
              {key: "11br", text: "Delicias", value: "11"},
              {key: "12br", text: "Embarcadero", value: "12"},
              {key: "13br", text: "Fuente", value: "13"},
              {key: "14br", text: "Isla Sorpresa", value: "14"},
              {key: "15br", text: "Lindavista", value: "15"},
              {key: "16br", text: "Lourdes", value: "16"},
              {key: "17br", text: "Ojo De Agua", value: "17"},
              {key: "18br", text: "Ojochal", value: "18"},
              {key: "19br", text: "Parcelas", value: "19"},
              {key: "20br", text: "Pozo", value: "20"},
              {key: "21br", text: "Precario", value: "21"},
              {key: "22br", text: "Pueblo Nuevo", value: "22"},
              {key: "23br", text: "Punta Mala", value: "23"},
              {key: "24br", text: "Punta Mala Arriba", value: "24"},
              {key: "25br", text: "Renacimiento", value: "25"},
              {key: "26br", text: "San Buenaventura", value: "26"},
              {key: "27br", text: "San Juan", value: "27"},
              {key: "28br", text: "San Marcos", value: "28"},
              {key: "29br", text: "Tagual", value: "29"},
              {key: "30br", text: "Tortuga Abajo", value: "30"},
              {key: "31br", text: "Tres Ríos", value: "31"},
              {key: "32br", text: "Vista De Térraba", value: "32"},
              {key: "33br", text: "Yuca", value: "33"}]},
          {key: "2ds", text: "Palmar", value: "2", barrios: [
              {key: "1br", text: "Palmar Norte", value: "1"},
              {key: "2br", text: "Alemania", value: "2"},
              {key: "3br", text: "Alto Ángeles", value: "3"},
              {key: "4br", text: "Alto Encanto", value: "4"},
              {key: "5br", text: "Alto Montura", value: "5"},
              {key: "6br", text: "Bellavista", value: "6"},
              {key: "7br", text: "Betania", value: "7"},
              {key: "8br", text: "Calavera", value: "8"},
              {key: "9br", text: "Cansot", value: "9"},
              {key: "10br", text: "Cañablancal (este)", value: "10"},
              {key: "11br", text: "Cañablancal (oeste)", value: "11"},
              {key: "12br", text: "Coobó (progreso)", value: "12"},
              {key: "13br", text: "Coquito", value: "13"},
              {key: "14br", text: "Gorrión", value: "14"},
              {key: "15br", text: "Jalaca (parte)", value: "15"},
              {key: "16br", text: "Olla Cero", value: "16"},
              {key: "17br", text: "Once De Abril", value: "17"},
              {key: "18br", text: "Palma", value: "18"},
              {key: "19br", text: "Palmar Sur", value: "19"},
              {key: "20br", text: "Paraíso", value: "20"},
              {key: "21br", text: "Primero De Marzo", value: "21"},
              {key: "22br", text: "Puerta Del Sol", value: "22"},
              {key: "23br", text: "San Cristóbal", value: "23"},
              {key: "24br", text: "San Francisco (tinoco)", value: "24"},
              {key: "25br", text: "San Gabriel", value: "25"},
              {key: "26br", text: "San Isidro", value: "26"},
              {key: "27br", text: "San Rafael", value: "27"},
              {key: "28br", text: "Santa Elena", value: "28"},
              {key: "29br", text: "Silencio", value: "29"},
              {key: "30br", text: "Trocha", value: "30"},
              {key: "31br", text: "Vergel", value: "31"},
              {key: "32br", text: "Victoria", value: "32"},
              {key: "33br", text: "Zapote", value: "33"}]},
          {key: "3ds", text: "Sierpe", value: "3", barrios: [
              {key: "1br", text: "Sierpe Centro", value: "1"},
              {key: "2br", text: "Ajuntaderas", value: "2"},
              {key: "3br", text: "Alto Los Mogos", value: "3"},
              {key: "4br", text: "Alto San Juan", value: "4"},
              {key: "5br", text: "Bahía Chal", value: "5"},
              {key: "6br", text: "Bajos Matías", value: "6"},
              {key: "7br", text: "Barco", value: "7"},
              {key: "8br", text: "Bejuco", value: "8"},
              {key: "9br", text: "Boca Chocuaco", value: "9"},
              {key: "10br", text: "Gallega", value: "10"},
              {key: "11br", text: "Camíbar", value: "11"},
              {key: "12br", text: "Campo De Aguabuena", value: "12"},
              {key: "13br", text: "Cantarrana", value: "13"},
              {key: "14br", text: "Charcos", value: "14"},
              {key: "15br", text: "Chocuaco", value: "15"},
              {key: "16br", text: "Garrobo", value: "16"},
              {key: "17br", text: "Guabos", value: "17"},
              {key: "18br", text: "Isidora", value: "18"},
              {key: "19br", text: "Islotes", value: "19"},
              {key: "20br", text: "Jalaca (parte)", value: "20"},
              {key: "21br", text: "Julia", value: "21"},
              {key: "22br", text: "Miramar", value: "22"},
              {key: "23br", text: "Mogos", value: "23"},
              {key: "24br", text: "Monterrey", value: "24"},
              {key: "25br", text: "Playa Palma", value: "25"},
              {key: "26br", text: "Playitas", value: "26"},
              {key: "27br", text: "Potrero", value: "27"},
              {key: "28br", text: "Puerto Escondido", value: "28"},
              {key: "29br", text: "Rincón", value: "29"},
              {key: "30br", text: "Sábalo", value: "30"},
              {key: "31br", text: "San Gerardo", value: "31"},
              {key: "32br", text: "San Juan", value: "32"},
              {key: "33br", text: "Taboga", value: "33"},
              {key: "34br", text: "Taboguita", value: "34"},
              {key: "35br", text: "Tigre", value: "35"},
              {key: "36br", text: "Varillal", value: "36"}]},
          {key: "4ds", text: "Bahía Ballena", value: "4", barrios: [
              {key: "1br", text: "Uvita", value: "1"},
              {key: "2br", text: "Bahía", value: "2"},
              {key: "3br", text: "Ballena", value: "3"},
              {key: "4br", text: "Brisas", value: "4"},
              {key: "5br", text: "Cambutal", value: "5"},
              {key: "6br", text: "Dominical", value: "6"},
              {key: "7br", text: "Dominicalito", value: "7"},
              {key: "8br", text: "Escaleras", value: "8"},
              {key: "9br", text: "Piedra Achiote", value: "9"},
              {key: "10br", text: "Piñuela", value: "10"},
              {key: "11br", text: "Playa Hermosa", value: "11"},
              {key: "12br", text: "Poza Azul", value: "12"},
              {key: "13br", text: "Puerto Nuevo", value: "13"},
              {key: "14br", text: "Quebrada Grande", value: "14"},
              {key: "15br", text: "Rocas Amancio", value: "15"},
              {key: "16br", text: "San Josecito", value: "16"},
              {key: "17br", text: "San Martín", value: "17"},
              {key: "18br", text: "Tortuga Arriba", value: "18"},
              {key: "19br", text: "Ventanas", value: "19"}]},
          {key: "5ds", text: "Piedras Blancas", value: "5", barrios: [
              {key: "1br", text: "Piedras Blancas Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Calera", value: "4"},
              {key: "5br", text: "Cerro Oscuro", value: "5"},
              {key: "6br", text: "Chacarita", value: "6"},
              {key: "7br", text: "Fila", value: "7"},
              {key: "8br", text: "Finca Alajuela", value: "8"},
              {key: "9br", text: "Finca Guanacaste", value: "9"},
              {key: "10br", text: "Finca Puntarenas", value: "10"},
              {key: "11br", text: "Florida", value: "11"},
              {key: "12br", text: "Guaria", value: "12"},
              {key: "13br", text: "Kilómetro 40", value: "13"},
              {key: "14br", text: "Navidad", value: "14"},
              {key: "15br", text: "Nubes", value: "15"},
              {key: "16br", text: "Porvenir", value: "16"},
              {key: "17br", text: "Rincón Caliente", value: "17"},
              {key: "18br", text: "Salamá", value: "18"},
              {key: "19br", text: "San Martín", value: "19"},
              {key: "20br", text: "Santa Cecilia", value: "20"},
              {key: "21br", text: "Santa Rosa", value: "21"},
              {key: "22br", text: "Sinaí", value: "22"},
              {key: "23br", text: "Venecia", value: "23"},
              {key: "24br", text: "Villa Bonita", value: "24"},
              {key: "25br", text: "Villa Colón", value: "25"}]},
          {key: "6ds", text: "Bahía Drake", value: "6", barrios: [
              {key: "1br", text: "Bahía Drake Centro (agujitas)", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Banegas", value: "3"},
              {key: "4br", text: "Boca Ganado", value: "4"},
              {key: "5br", text: "Campanario", value: "5"},
              {key: "6br", text: "Caletas", value: "6"},
              {key: "7br", text: "Guerra", value: "7"},
              {key: "8br", text: "Planes", value: "8"},
              {key: "9br", text: "Progreso", value: "9"},
              {key: "10br", text: "Quebrada Ganado", value: "10"},
              {key: "11br", text: "Rancho Quemado", value: "11"},
              {key: "12br", text: "Riyito", value: "12"},
              {key: "13br", text: "San Josecito (rincón)", value: "13"},
              {key: "14br", text: "San Pedrillo", value: "14"}]}]},
      {key: "6cn", text: "Quepos", value: "6", distritos: [
          {key: "1ds", text: "Quepos", value: "1", barrios: [
              {key: "1br", text: "Quepos Centro", value: "1"},
              {key: "2br", text: "Boca Vieja", value: "2"},
              {key: "3br", text: "Cocal", value: "3"},
              {key: "4br", text: "Colinas Del Este", value: "4"},
              {key: "5br", text: "Inmaculada", value: "5"},
              {key: "6br", text: "Junta Naranjo", value: "6"},
              {key: "7br", text: "La Zona", value: "7"},
              {key: "8br", text: "Rancho Grande", value: "8"},
              {key: "9br", text: "Anita", value: "9"},
              {key: "10br", text: "Bartolo", value: "10"},
              {key: "11br", text: "Boca Naranjo", value: "11"},
              {key: "12br", text: "Cañas", value: "12"},
              {key: "13br", text: "Cañitas", value: "13"},
              {key: "14br", text: "Cerritos", value: "14"},
              {key: "15br", text: "Cerros", value: "15"},
              {key: "16br", text: "Damas", value: "16"},
              {key: "17br", text: "Delicias", value: "17"},
              {key: "18br", text: "Espadilla", value: "18"},
              {key: "19br", text: "Estero Damas", value: "19"},
              {key: "20br", text: "Estero Garita", value: "20"},
              {key: "21br", text: "Gallega", value: "21"},
              {key: "22br", text: "Llamarón", value: "22"},
              {key: "23br", text: "Llorona", value: "23"},
              {key: "24br", text: "Managua", value: "24"},
              {key: "25br", text: "Manuel Antonio", value: "25"},
              {key: "26br", text: "Marítima", value: "26"},
              {key: "27br", text: "Mona", value: "27"},
              {key: "28br", text: "Papaturro", value: "28"},
              {key: "29br", text: "Paquita", value: "29"},
              {key: "30br", text: "Pastora", value: "30"},
              {key: "31br", text: "Quebrada Azul", value: "31"},
              {key: "32br", text: "Rey", value: "32"},
              {key: "33br", text: "Ríos", value: "33"},
              {key: "34br", text: "Roncador", value: "34"},
              {key: "35br", text: "San Rafael", value: "35"}]},
          {key: "2ds", text: "Savegre", value: "2", barrios: [
              {key: "1br", text: "Matapalo", value: "1"},
              {key: "2br", text: "Dos Bocas", value: "2"},
              {key: "3br", text: "Guabas", value: "3"},
              {key: "4br", text: "Guápil", value: "4"},
              {key: "5br", text: "Hatillo Nuevo", value: "5"},
              {key: "6br", text: "Hatillo Viejo", value: "6"},
              {key: "7br", text: "Laguna", value: "7"},
              {key: "8br", text: "Nubes", value: "8"},
              {key: "9br", text: "Palma Quemada", value: "9"},
              {key: "10br", text: "Pasito", value: "10"},
              {key: "11br", text: "Paso", value: "11"},
              {key: "12br", text: "Paso Guanacaste", value: "12"},
              {key: "13br", text: "Platanillo", value: "13"},
              {key: "14br", text: "Playa Matapalo", value: "14"},
              {key: "15br", text: "Portalón", value: "15"},
              {key: "16br", text: "Punto De Mira", value: "16"},
              {key: "17br", text: "Salitral", value: "17"},
              {key: "18br", text: "Salsipuedes", value: "18"},
              {key: "19br", text: "San Andrés", value: "19"},
              {key: "20br", text: "Santo Domingo", value: "20"},
              {key: "21br", text: "Silencio", value: "21"},
              {key: "22br", text: "Tierras Morenas", value: "22"},
              {key: "23br", text: "Tres Piedras (parte)", value: "23"}]},
          {key: "3ds", text: "Naranjito", value: "3", barrios: [
              {key: "1br", text: "Naranjito Centro", value: "1"},
              {key: "2br", text: "Bijagual", value: "2"},
              {key: "3br", text: "Buenos Aires", value: "3"},
              {key: "4br", text: "Capital", value: "4"},
              {key: "5br", text: "Concepción", value: "5"},
              {key: "6br", text: "Cotos", value: "6"},
              {key: "7br", text: "Londres", value: "7"},
              {key: "8br", text: "Negro", value: "8"},
              {key: "9br", text: "Pascua", value: "9"},
              {key: "10br", text: "Paso Indios", value: "10"},
              {key: "11br", text: "Paso Real", value: "11"},
              {key: "12br", text: "Sábalo", value: "12"},
              {key: "13br", text: "Santa Juana", value: "13"},
              {key: "14br", text: "Tocorí", value: "14"},
              {key: "15br", text: "Villanueva", value: "15"}]}]},
      {key: "7cn", text: "Golfito", value: "7", distritos: [
          {key: "1ds", text: "Golfito", value: "1", barrios: [
              {key: "1br", text: "Golfito Centro", value: "1"},
              {key: "2br", text: "Aguada", value: "2"},
              {key: "3br", text: "Alamedas", value: "3"},
              {key: "4br", text: "Ánimas", value: "4"},
              {key: "5br", text: "Atrocho", value: "5"},
              {key: "6br", text: "Bajo Chontales", value: "6"},
              {key: "7br", text: "Bajo De Coto", value: "7"},
              {key: "8br", text: "Bajo Grapa", value: "8"},
              {key: "9br", text: "Bajo Mansito", value: "9"},
              {key: "10br", text: "Bajo Sucio", value: "10"},
              {key: "11br", text: "Bajos De Cañablanca", value: "11"},
              {key: "12br", text: "Bellavista", value: "12"},
              {key: "13br", text: "Bolsa", value: "13"},
              {key: "14br", text: "Cuarenta Y Cinco", value: "14"},
              {key: "15br", text: "Disco", value: "15"},
              {key: "16br", text: "Dos Ríos", value: "16"},
              {key: "17br", text: "Esperanza De Coto", value: "17"},
              {key: "18br", text: "Gallardo", value: "18"},
              {key: "19br", text: "Huacas", value: "19"},
              {key: "20br", text: "Jorge Brenes Durán", value: "20"},
              {key: "21br", text: "Kilómetro 1", value: "21"},
              {key: "22br", text: "Kilómetro 16", value: "22"},
              {key: "23br", text: "Kilómetro 2", value: "23"},
              {key: "24br", text: "Kilómetro 20", value: "24"},
              {key: "25br", text: "Kilómetro 24", value: "25"},
              {key: "26br", text: "Kilómetro 3", value: "26"},
              {key: "27br", text: "Kilómetro 5", value: "27"},
              {key: "28br", text: "Kilómetro 7", value: "28"},
              {key: "29br", text: "Kilómetro 9", value: "29"},
              {key: "30br", text: "Laguna", value: "30"},
              {key: "31br", text: "Llano Bonito", value: "31"},
              {key: "32br", text: "Manuel Tucker Martínez", value: "32"},
              {key: "33br", text: "Minerva", value: "33"},
              {key: "34br", text: "Mona", value: "34"},
              {key: "35br", text: "Naranjal", value: "35"},
              {key: "36br", text: "Nazaret", value: "36"},
              {key: "37br", text: "Oasis De Esperanza", value: "37"},
              {key: "38br", text: "Parroquial", value: "38"},
              {key: "39br", text: "Paso Higuerón", value: "39"},
              {key: "40br", text: "Playa Cacao", value: "40"},
              {key: "41br", text: "Pueblo Civil", value: "41"},
              {key: "42br", text: "Puerto Escondido", value: "42"},
              {key: "43br", text: "Puntarenitas", value: "43"},
              {key: "44br", text: "Purruja", value: "44"},
              {key: "45br", text: "Rancho Relámpago", value: "45"},
              {key: "46br", text: "Riyito", value: "46"},
              {key: "47br", text: "Rotonda", value: "47"},
              {key: "48br", text: "Saladerito", value: "48"},
              {key: "49br", text: "Saladero", value: "49"},
              {key: "50br", text: "San Andrés", value: "50"},
              {key: "51br", text: "San Francisco", value: "51"},
              {key: "52br", text: "San Josecito", value: "52"},
              {key: "53br", text: "San Martín", value: "53"},
              {key: "54br", text: "Torres", value: "54"},
              {key: "55br", text: "Trenzas", value: "55"},
              {key: "56br", text: "Unión De Coto", value: "56"},
              {key: "57br", text: "Ureña", value: "57"},
              {key: "58br", text: "Valle Bonito", value: "58"},
              {key: "59br", text: "Viquilla Dos", value: "59"},
              {key: "60br", text: "Zona Gris", value: "60"}]},
          {key: "2ds", text: "Puerto Jiménez", value: "2", barrios: [
              {key: "1br", text: "Puerto Jiménez Centro", value: "1"},
              {key: "2br", text: "Aguabuena", value: "2"},
              {key: "3br", text: "Agujas", value: "3"},
              {key: "4br", text: "Amapola", value: "4"},
              {key: "5br", text: "Balsa", value: "5"},
              {key: "6br", text: "Bambú", value: "6"},
              {key: "7br", text: "Barrigones", value: "7"},
              {key: "8br", text: "Barrio Bonito", value: "8"},
              {key: "9br", text: "Boca Gallardo", value: "9"},
              {key: "10br", text: "Cañaza", value: "10"},
              {key: "11br", text: "Carate", value: "11"},
              {key: "12br", text: "Carbonera", value: "12"},
              {key: "13br", text: "Cerro De Oro", value: "13"},
              {key: "14br", text: "Dos Brazos", value: "14"},
              {key: "15br", text: "Guadalupe", value: "15"},
              {key: "16br", text: "Independencia", value: "16"},
              {key: "17br", text: "Lajitas", value: "17"},
              {key: "18br", text: "Miramar (altos Corozal)", value: "18"},
              {key: "19br", text: "Ñeque", value: "19"},
              {key: "20br", text: "Palma", value: "20"},
              {key: "21br", text: "Paloseco", value: "21"},
              {key: "22br", text: "Playa Blanca", value: "22"},
              {key: "23br", text: "Playa Tigre", value: "23"},
              {key: "24br", text: "Pueblo Nuevo", value: "24"},
              {key: "25br", text: "Puerto Escondido", value: "25"},
              {key: "26br", text: "Quebrada Latarde", value: "26"},
              {key: "27br", text: "Río Nuevo", value: "27"},
              {key: "28br", text: "Río Oro", value: "28"},
              {key: "29br", text: "Río Piro (coyunda)", value: "29"},
              {key: "30br", text: "San Miguel", value: "30"},
              {key: "31br", text: "Sándalo", value: "31"},
              {key: "32br", text: "Sombrero", value: "32"},
              {key: "33br", text: "Terrones", value: "33"},
              {key: "34br", text: "Tigre", value: "34"}]},
          {key: "3ds", text: "Guaycará", value: "3", barrios: [
              {key: "1br", text: "Río Claro", value: "1"},
              {key: "2br", text: "Bajo Bonita", value: "2"},
              {key: "3br", text: "Bajo Cedros", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Cerro Café", value: "5"},
              {key: "6br", text: "Chiqueros", value: "6"},
              {key: "7br", text: "Delicias", value: "7"},
              {key: "8br", text: "El Alto", value: "8"},
              {key: "9br", text: "Esperanza", value: "9"},
              {key: "10br", text: "Gamba", value: "10"},
              {key: "11br", text: "Kilómetro 29", value: "11"},
              {key: "12br", text: "Kilómetro 33", value: "12"},
              {key: "13br", text: "La Julieta", value: "13"},
              {key: "14br", text: "Santiago", value: "14"},
              {key: "15br", text: "Santiago De Caracol", value: "15"},
              {key: "16br", text: "Tigre (caracol Norte)", value: "16"},
              {key: "17br", text: "Valle Cedros", value: "17"},
              {key: "18br", text: "Vegas De Río Claro", value: "18"},
              {key: "19br", text: "Villa Briceño", value: "19"},
              {key: "20br", text: "Viquilla Uno", value: "20"}]},
          {key: "4ds", text: "Pavón", value: "4", barrios: [
              {key: "1br", text: "Conte", value: "1"},
              {key: "2br", text: "Altos De Conte", value: "2"},
              {key: "3br", text: "Banco", value: "3"},
              {key: "4br", text: "Burica", value: "4"},
              {key: "5br", text: "Clarita", value: "5"},
              {key: "6br", text: "Cocal Amarillo", value: "6"},
              {key: "7br", text: "Cuervito", value: "7"},
              {key: "8br", text: "Escuadra", value: "8"},
              {key: "9br", text: "Esperanza De Sábalos", value: "9"},
              {key: "10br", text: "Estero Colorado", value: "10"},
              {key: "11br", text: "Estrella", value: "11"},
              {key: "12br", text: "Flor De Coto", value: "12"},
              {key: "13br", text: "Fortuna De Coto", value: "13"},
              {key: "14br", text: "Guaymí", value: "14"},
              {key: "15br", text: "Higo", value: "15"},
              {key: "16br", text: "Jardín", value: "16"},
              {key: "17br", text: "La Virgen", value: "17"},
              {key: "18br", text: "Lindamar", value: "18"},
              {key: "19br", text: "Manzanillo", value: "19"},
              {key: "20br", text: "Pavones", value: "20"},
              {key: "21br", text: "Peñas", value: "21"},
              {key: "22br", text: "Peñita", value: "22"},
              {key: "23br", text: "Puerto Pilón", value: "23"},
              {key: "24br", text: "Puesto La Playa", value: "24"},
              {key: "25br", text: "Punta Banco", value: "25"},
              {key: "26br", text: "Quebrada Honda", value: "26"},
              {key: "27br", text: "Riviera", value: "27"},
              {key: "28br", text: "Sábalos", value: "28"},
              {key: "29br", text: "Tigrito", value: "29"},
              {key: "30br", text: "Unión Del Sur", value: "30"},
              {key: "31br", text: "Vanegas", value: "31"},
              {key: "32br", text: "Yerba", value: "32"},
              {key: "33br", text: "Zancudo", value: "33"}]}]},
      {key: "8cn", text: "Coto Brus", value: "8", distritos: [
          {key: "1ds", text: "San Vito", value: "1", barrios: [
              {key: "1br", text: "San Vito Centro", value: "1"},
              {key: "2br", text: "Canadá", value: "2"},
              {key: "3br", text: "María Auxiliadora", value: "3"},
              {key: "4br", text: "Tres Ríos", value: "4"},
              {key: "5br", text: "Aguas Claras", value: "5"},
              {key: "6br", text: "Bajo Reyes", value: "6"},
              {key: "7br", text: "Bajo Venado", value: "7"},
              {key: "8br", text: "Barrantes", value: "8"},
              {key: "9br", text: "Ceibo", value: "9"},
              {key: "10br", text: "Cruces", value: "10"},
              {key: "11br", text: "Cuenca De Oro", value: "11"},
              {key: "12br", text: "Danto", value: "12"},
              {key: "13br", text: "Fila Guinea", value: "13"},
              {key: "14br", text: "Isla", value: "14"},
              {key: "15br", text: "Lindavista", value: "15"},
              {key: "16br", text: "Lourdes", value: "16"},
              {key: "17br", text: "Maravilla", value: "17"},
              {key: "18br", text: "Piedra Pintada", value: "18"},
              {key: "19br", text: "San Joaquín", value: "19"},
              {key: "20br", text: "Santa Clara", value: "20"},
              {key: "21br", text: "Torre Alta", value: "21"}]},
          {key: "2ds", text: "Sabalito", value: "2", barrios: [
              {key: "1br", text: "Sabalito Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Brasilia", value: "3"},
              {key: "4br", text: "Casablanca", value: "4"},
              {key: "5br", text: "Chanchera", value: "5"},
              {key: "6br", text: "El Gallo", value: "6"},
              {key: "7br", text: "Juntas", value: "7"},
              {key: "8br", text: "La Esmeralda", value: "8"},
              {key: "9br", text: "Lucha", value: "9"},
              {key: "10br", text: "Mellizas", value: "10"},
              {key: "11br", text: "Miraflores", value: "11"},
              {key: "12br", text: "Piedra De Candela", value: "12"},
              {key: "13br", text: "Plantel", value: "13"},
              {key: "14br", text: "Porto Llano", value: "14"},
              {key: "15br", text: "Primavera", value: "15"},
              {key: "16br", text: "Progreso", value: "16"},
              {key: "17br", text: "Providencia", value: "17"},
              {key: "18br", text: "Pueblo Nuevo", value: "18"},
              {key: "19br", text: "Río Negro", value: "19"},
              {key: "20br", text: "Río Sereno", value: "20"},
              {key: "21br", text: "San Antonio", value: "21"},
              {key: "22br", text: "San Bosco", value: "22"},
              {key: "23br", text: "San Francisco", value: "23"},
              {key: "24br", text: "San Luis", value: "24"},
              {key: "25br", text: "San Marcos", value: "25"},
              {key: "26br", text: "San Miguel", value: "26"},
              {key: "27br", text: "San Rafael", value: "27"},
              {key: "28br", text: "San Ramón", value: "28"},
              {key: "29br", text: "Santa Rosa", value: "29"},
              {key: "30br", text: "Santa Teresa", value: "30"},
              {key: "31br", text: "Tigra", value: "31"},
              {key: "32br", text: "Trinidad", value: "32"},
              {key: "33br", text: "Unión", value: "33"},
              {key: "34br", text: "Valle Hermoso", value: "34"}]},
          {key: "3ds", text: "Aguabuena", value: "3", barrios: [
              {key: "1br", text: "Aguabuena Centro", value: "1"},
              {key: "2br", text: "Bello Oriente", value: "2"},
              {key: "3br", text: "Campo Tres", value: "3"},
              {key: "4br", text: "Cañas Gordas", value: "4"},
              {key: "5br", text: "Concepción", value: "5"},
              {key: "6br", text: "Copabuena", value: "6"},
              {key: "7br", text: "Copal", value: "7"},
              {key: "8br", text: "Fila Zapote", value: "8"},
              {key: "9br", text: "Metaponto", value: "9"},
              {key: "10br", text: "Pilares", value: "10"},
              {key: "11br", text: "Pueblo Nuevo", value: "11"},
              {key: "12br", text: "Quebrada Bonita", value: "12"},
              {key: "13br", text: "Río Salto", value: "13"},
              {key: "14br", text: "San Francisco", value: "14"},
              {key: "15br", text: "San Gabriel", value: "15"},
              {key: "16br", text: "San Pedro", value: "16"},
              {key: "17br", text: "Santa Cecilia", value: "17"},
              {key: "18br", text: "Santa Marta", value: "18"},
              {key: "19br", text: "Santo Domingo", value: "19"},
              {key: "20br", text: "Valle Azul", value: "20"}]},
          {key: "4ds", text: "Limoncito", value: "4", barrios: [
              {key: "1br", text: "Limoncito Centro", value: "1"},
              {key: "2br", text: "Aguacate", value: "2"},
              {key: "3br", text: "Alto Limoncito", value: "3"},
              {key: "4br", text: "Ángeles", value: "4"},
              {key: "5br", text: "Bonanza", value: "5"},
              {key: "6br", text: "Brusmalis", value: "6"},
              {key: "7br", text: "Chiva", value: "7"},
              {key: "8br", text: "Desamparados", value: "8"},
              {key: "9br", text: "Esperanza", value: "9"},
              {key: "10br", text: "Fila", value: "10"},
              {key: "11br", text: "La Casona", value: "11"},
              {key: "12br", text: "Manchuria", value: "12"},
              {key: "13br", text: "Sabanilla", value: "13"},
              {key: "14br", text: "San Gerardo", value: "14"},
              {key: "15br", text: "San Juan", value: "15"},
              {key: "16br", text: "San Miguel", value: "16"},
              {key: "17br", text: "San Rafael", value: "17"},
              {key: "18br", text: "Santa Marta", value: "18"},
              {key: "19br", text: "Santa Rita", value: "19"},
              {key: "20br", text: "Unión", value: "20"},
              {key: "21br", text: "Valle", value: "21"},
              {key: "22br", text: "Villapalacios", value: "22"},
              {key: "23br", text: "Zumbona", value: "23"}]},
          {key: "5ds", text: "Pittier", value: "5", barrios: [
              {key: "1br", text: "Santa Elena", value: "1"},
              {key: "2br", text: "Aguacaliente", value: "2"},
              {key: "3br", text: "Camaquiri", value: "3"},
              {key: "4br", text: "Cocorí", value: "4"},
              {key: "5br", text: "Coto Brus (parte)", value: "5"},
              {key: "6br", text: "Fila Méndez", value: "6"},
              {key: "7br", text: "Fila Naranjo", value: "7"},
              {key: "8br", text: "Fila Tigre", value: "8"},
              {key: "9br", text: "Marías", value: "9"},
              {key: "10br", text: "Monterrey", value: "10"},
              {key: "11br", text: "Palmira", value: "11"},
              {key: "12br", text: "Santa Fe", value: "12"}]},
          {key: "6ds", text: "Gutiérrez Braun", value: "6", barrios: [
              {key: "1br", text: "Fila Guinea", value: "1"},
              {key: "2br", text: "Alpha", value: "2"},
              {key: "3br", text: "Alturas De Cotón", value: "3"},
              {key: "4br", text: "Brisas", value: "4"},
              {key: "5br", text: "Fila Pinar", value: "5"},
              {key: "6br", text: "Fila San Rafael", value: "6"},
              {key: "7br", text: "Flor Del Roble", value: "7"},
              {key: "8br", text: "Guinea Arriba", value: "8"},
              {key: "9br", text: "La Administración (gutiérrez Braun)", value: "9"},
              {key: "10br", text: "Libertad", value: "10"},
              {key: "11br", text: "Poma", value: "11"},
              {key: "12br", text: "Río Marzo", value: "12"},
              {key: "13br", text: "Roble", value: "13"},
              {key: "14br", text: "Roble Arriba", value: "14"},
              {key: "15br", text: "Siete Colinas", value: "15"}]}]},
      {key: "9cn", text: "Parrita", value: "9", distritos: [
          {key: "1ds", text: "Parrita", value: "1", barrios: [
              {key: "1br", text: "Parrita Centro", value: "1"},
              {key: "2br", text: "Alto Camacho", value: "2"},
              {key: "3br", text: "Ángeles", value: "3"},
              {key: "4br", text: "Bajos Jicote", value: "4"},
              {key: "5br", text: "Bambú", value: "5"},
              {key: "6br", text: "Bandera", value: "6"},
              {key: "7br", text: "Barbudal", value: "7"},
              {key: "8br", text: "Bejuco", value: "8"},
              {key: "9br", text: "Boca Del Parrita", value: "9"},
              {key: "10br", text: "Carmen (parte)", value: "10"},
              {key: "11br", text: "Chires", value: "11"},
              {key: "12br", text: "Chires Arriba", value: "12"},
              {key: "13br", text: "Chirraca Abajo", value: "13"},
              {key: "14br", text: "Chirraca Arriba", value: "14"},
              {key: "15br", text: "Esterillos Centro", value: "15"},
              {key: "16br", text: "Esterillos Este", value: "16"},
              {key: "17br", text: "Esterillos Oeste", value: "17"},
              {key: "18br", text: "Fila Surubres", value: "18"},
              {key: "19br", text: "Guapinol", value: "19"},
              {key: "20br", text: "Higuito", value: "20"},
              {key: "21br", text: "I Griega", value: "21"},
              {key: "22br", text: "Isla Damas", value: "22"},
              {key: "23br", text: "Isla Palo Seco", value: "23"},
              {key: "24br", text: "Jicote", value: "24"},
              {key: "25br", text: "Julieta", value: "25"},
              {key: "26br", text: "Loma", value: "26"},
              {key: "27br", text: "Mesas", value: "27"},
              {key: "28br", text: "Palmas", value: "28"},
              {key: "29br", text: "Palo Seco", value: "29"},
              {key: "30br", text: "Pirrís", value: "30"},
              {key: "31br", text: "Pirrís (las Parcelas)", value: "31"},
              {key: "32br", text: "Playa Palma", value: "32"},
              {key: "33br", text: "Playón", value: "33"},
              {key: "34br", text: "Playón Sur", value: "34"},
              {key: "35br", text: "Pocares", value: "35"},
              {key: "36br", text: "Pocarito", value: "36"},
              {key: "37br", text: "Porvenir", value: "37"},
              {key: "38br", text: "Pueblo Nuevo", value: "38"},
              {key: "39br", text: "Punta Judas", value: "39"},
              {key: "40br", text: "Rincón Morales", value: "40"},
              {key: "41br", text: "Río Negro (parte)", value: "41"},
              {key: "42br", text: "Río Seco", value: "42"},
              {key: "43br", text: "San Antonio", value: "43"},
              {key: "44br", text: "San Bosco", value: "44"},
              {key: "45br", text: "San Gerardo", value: "45"},
              {key: "46br", text: "San Isidro", value: "46"},
              {key: "47br", text: "San Juan", value: "47"},
              {key: "48br", text: "San Julián", value: "48"},
              {key: "49br", text: "San Rafael Norte", value: "49"},
              {key: "50br", text: "Sardinal", value: "50"},
              {key: "51br", text: "Sardinal Sur", value: "51"},
              {key: "52br", text: "Surubres", value: "52"},
              {key: "53br", text: "Teca", value: "53"},
              {key: "54br", text: "Tigre", value: "54"},
              {key: "55br", text: "Turbio", value: "55"},
              {key: "56br", text: "Valle Vasconia", value: "56"},
              {key: "57br", text: "Vueltas", value: "57"}]}]},
      {key: "10cn", text: "Corredores", value: "10", distritos: [
          {key: "1ds", text: "Corredor", value: "1", barrios: [
              {key: "1br", text: "Neily Centro", value: "1"},
              {key: "2br", text: "Abrojo", value: "2"},
              {key: "3br", text: "Aguilares", value: "3"},
              {key: "4br", text: "Alto Limoncito", value: "4"},
              {key: "5br", text: "Bajo Indios", value: "5"},
              {key: "6br", text: "Betel", value: "6"},
              {key: "7br", text: "Bosque", value: "7"},
              {key: "8br", text: "Cacoragua", value: "8"},
              {key: "9br", text: "Campiña", value: "9"},
              {key: "10br", text: "Campo Dos", value: "10"},
              {key: "11br", text: "Campo Dos Y Medio", value: "11"},
              {key: "12br", text: "Cañada", value: "12"},
              {key: "13br", text: "Caño Seco", value: "13"},
              {key: "14br", text: "Capri", value: "14"},
              {key: "15br", text: "Caracol Sur", value: "15"},
              {key: "16br", text: "Carmen", value: "16"},
              {key: "17br", text: "Castaños", value: "17"},
              {key: "18br", text: "Coloradito", value: "18"},
              {key: "19br", text: "Concordia", value: "19"},
              {key: "20br", text: "Corredor", value: "20"},
              {key: "21br", text: "Coto 42", value: "21"},
              {key: "22br", text: "Coto 44", value: "22"},
              {key: "23br", text: "Coto 45", value: "23"},
              {key: "24br", text: "Coto 47", value: "24"},
              {key: "25br", text: "Coto 49", value: "25"},
              {key: "26br", text: "Coto 50-51", value: "26"},
              {key: "27br", text: "Coto 52-53", value: "27"},
              {key: "28br", text: "Cuesta Fila De Cal", value: "28"},
              {key: "29br", text: "Estrella Del Sur", value: "29"},
              {key: "30br", text: "Florida", value: "30"},
              {key: "31br", text: "Fortuna", value: "31"},
              {key: "32br", text: "Kilómetro 10", value: "32"},
              {key: "33br", text: "Miramar", value: "33"},
              {key: "34br", text: "Montezuma", value: "34"},
              {key: "35br", text: "Nubes", value: "35"},
              {key: "36br", text: "Pangas", value: "36"},
              {key: "37br", text: "Planes", value: "37"},
              {key: "38br", text: "Progreso", value: "38"},
              {key: "39br", text: "Pueblo Nuevo", value: "39"},
              {key: "40br", text: "Río Bonito", value: "40"},
              {key: "41br", text: "Río Nuevo (norte)", value: "41"},
              {key: "42br", text: "Río Nuevo (sur)", value: "42"},
              {key: "43br", text: "San Antonio Abajo", value: "43"},
              {key: "44br", text: "San Francisco", value: "44"},
              {key: "45br", text: "San Josecito", value: "45"},
              {key: "46br", text: "San Juan", value: "46"},
              {key: "47br", text: "San Rafael", value: "47"},
              {key: "48br", text: "Santa Cecilia", value: "48"},
              {key: "49br", text: "Santa Marta (parte)", value: "49"},
              {key: "50br", text: "Santa Rita", value: "50"},
              {key: "51br", text: "Tropezón", value: "51"},
              {key: "52br", text: "Unión", value: "52"},
              {key: "53br", text: "Valle Del Sur", value: "53"},
              {key: "54br", text: "Vegas De Abrojo", value: "54"},
              {key: "55br", text: "Villa Roma", value: "55"}]},
          {key: "2ds", text: "La Cuesta", value: "2", barrios: [
              {key: "1br", text: "La Cuesta Centro", value: "1"},
              {key: "2br", text: "Canoas Abajo (parte)", value: "2"},
              {key: "3br", text: "Chorro", value: "3"},
              {key: "4br", text: "Control", value: "4"},
              {key: "5br", text: "Cuervito", value: "5"}]},
          {key: "3ds", text: "Canoas", value: "3", barrios: [
              {key: "1br", text: "Canoas Centro", value: "1"},
              {key: "2br", text: "Altos Del Brujo", value: "2"},
              {key: "3br", text: "Bajo", value: "3"},
              {key: "4br", text: "Bajo Brujo", value: "4"},
              {key: "5br", text: "Barrionuevo", value: "5"},
              {key: "6br", text: "Canoas Abajo (parte)", value: "6"},
              {key: "7br", text: "Canoas Arriba", value: "7"},
              {key: "8br", text: "Cañaza", value: "8"},
              {key: "9br", text: "Cerro Brujo", value: "9"},
              {key: "10br", text: "Chiva", value: "10"},
              {key: "11br", text: "Colorado", value: "11"},
              {key: "12br", text: "Darizara", value: "12"},
              {key: "13br", text: "Gloria", value: "13"},
              {key: "14br", text: "Guay", value: "14"},
              {key: "15br", text: "Guayabal", value: "15"},
              {key: "16br", text: "Lotes (san Jorge)", value: "16"},
              {key: "17br", text: "Mariposa", value: "17"},
              {key: "18br", text: "Níspero", value: "18"},
              {key: "19br", text: "Palma", value: "19"},
              {key: "20br", text: "Paso Canoas", value: "20"},
              {key: "21br", text: "San Antonio", value: "21"},
              {key: "22br", text: "San Isidro", value: "22"},
              {key: "23br", text: "San Martín", value: "23"},
              {key: "24br", text: "San Miguel", value: "24"},
              {key: "25br", text: "Santa Marta (parte)", value: "25"},
              {key: "26br", text: "Veguitas De Colorado", value: "26"},
              {key: "27br", text: "Veracruz", value: "27"},
              {key: "28br", text: "Villas De Darizara", value: "28"}]},
          {key: "4ds", text: "Laurel", value: "4", barrios: [
              {key: "1br", text: "Laurel Centro", value: "1"},
              {key: "2br", text: "Alto Vaquita", value: "2"},
              {key: "3br", text: "Bambito", value: "3"},
              {key: "4br", text: "Bella Luz", value: "4"},
              {key: "5br", text: "Bijagual", value: "5"},
              {key: "6br", text: "Caimito", value: "6"},
              {key: "7br", text: "Cangrejo Verde", value: "7"},
              {key: "8br", text: "Caracol De La Vaca", value: "8"},
              {key: "9br", text: "Cariari", value: "9"},
              {key: "10br", text: "Caucho", value: "10"},
              {key: "11br", text: "Cenizo", value: "11"},
              {key: "12br", text: "Colonia Libertad", value: "12"},
              {key: "13br", text: "Coyoche", value: "13"},
              {key: "14br", text: "Jobo Civil", value: "14"},
              {key: "15br", text: "Kilómetro 22", value: "15"},
              {key: "16br", text: "Kilómetro 25", value: "16"},
              {key: "17br", text: "Kilómetro 27", value: "17"},
              {key: "18br", text: "Kilómetro 29", value: "18"},
              {key: "19br", text: "Mango", value: "19"},
              {key: "20br", text: "Pueblo De Dios", value: "20"},
              {key: "21br", text: "Puerto González Víquez", value: "21"},
              {key: "22br", text: "Río Incendio", value: "22"},
              {key: "23br", text: "Roble", value: "23"},
              {key: "24br", text: "San Juan", value: "24"},
              {key: "25br", text: "Santa Lucía", value: "25"},
              {key: "26br", text: "Tamarindo", value: "26"},
              {key: "27br", text: "Vaca (santa Rosa)", value: "27"},
              {key: "28br", text: "Vereh", value: "28"},
              {key: "29br", text: "Zaragoza", value: "29"}]}]},
      {key: "11cn", text: "Garabito", value: "11", distritos: [
          {key: "1ds", text: "Jacó", value: "1", barrios: [
              {key: "1br", text: "Jacó Centro", value: "1"},
              {key: "2br", text: "Agujitas", value: "2"},
              {key: "3br", text: "Buenos Aires", value: "3"},
              {key: "4br", text: "Cañablancal", value: "4"},
              {key: "5br", text: "Cerro Fresco", value: "5"},
              {key: "6br", text: "Herradura", value: "6"},
              {key: "7br", text: "Mona", value: "7"},
              {key: "8br", text: "Playa Hermosa", value: "8"},
              {key: "9br", text: "Playa Herradura", value: "9"},
              {key: "10br", text: "Pochotal", value: "10"},
              {key: "11br", text: "Puerto Escondido", value: "11"},
              {key: "12br", text: "Quebrada Amarilla", value: "12"},
              {key: "13br", text: "San Antonio", value: "13"},
              {key: "14br", text: "Turrubaritos", value: "14"},
              {key: "15br", text: "Tusubres", value: "15"}]},
          {key: "2ds", text: "Tárcoles", value: "2", barrios: [
              {key: "1br", text: "Quebrada Ganado", value: "1"},
              {key: "2br", text: "Agujas", value: "2"},
              {key: "3br", text: "Bajamar", value: "3"},
              {key: "4br", text: "Bellavista", value: "4"},
              {key: "5br", text: "Caletas", value: "5"},
              {key: "6br", text: "Camaronal", value: "6"},
              {key: "7br", text: "Camaronal Arriba", value: "7"},
              {key: "8br", text: "Capulín", value: "8"},
              {key: "9br", text: "Carrizal De Bajamar", value: "9"},
              {key: "10br", text: "Guacalillo", value: "10"},
              {key: "11br", text: "Mantas", value: "11"},
              {key: "12br", text: "Nambí", value: "12"},
              {key: "13br", text: "Peñón De Tivives", value: "13"},
              {key: "14br", text: "Pigres", value: "14"},
              {key: "15br", text: "Pita", value: "15"},
              {key: "16br", text: "Playa Azul", value: "16"},
              {key: "17br", text: "Pógeres", value: "17"},
              {key: "18br", text: "Puerto Peje", value: "18"},
              {key: "19br", text: "Punta Leona", value: "19"},
              {key: "20br", text: "Tárcoles", value: "20"},
              {key: "21br", text: "Tarcolitos", value: "21"}]}]}]},
  {key: "7pr", text: "Limón", value: "7", cantones: [
      {key: "1cn", text: "Limón", value: "1", distritos: [
          {key: "1ds", text: "Limón", value: "1", barrios: [
              {key: "1br", text: "Limón Centro", value: "1"},
              {key: "2br", text: "Bellavista", value: "2"},
              {key: "3br", text: "Bohío", value: "3"},
              {key: "4br", text: "Bosque", value: "4"},
              {key: "5br", text: "Buenos Aires", value: "5"},
              {key: "6br", text: "Buenos Aires", value: "6"},
              {key: "7br", text: "Cangrejos", value: "7"},
              {key: "8br", text: "Cariari", value: "8"},
              {key: "9br", text: "Cerro Mocho", value: "9"},
              {key: "10br", text: "Cielo Amarillo", value: "10"},
              {key: "11br", text: "Cieneguita", value: "11"},
              {key: "12br", text: "Cocal", value: "12"},
              {key: "13br", text: "Colina", value: "13"},
              {key: "14br", text: "Corales", value: "14"},
              {key: "15br", text: "Cruce", value: "15"},
              {key: "16br", text: "Dos Bocas", value: "16"},
              {key: "17br", text: "Empalme Moín", value: "17"},
              {key: "18br", text: "Fortín", value: "18"},
              {key: "19br", text: "Garrón", value: "19"},
              {key: "20br", text: "Hospital", value: "20"},
              {key: "21br", text: "Jamaica Town", value: "21"},
              {key: "22br", text: "Japdeva", value: "22"},
              {key: "23br", text: "Laureles", value: "23"},
              {key: "24br", text: "Limoncito", value: "24"},
              {key: "25br", text: "Lirios", value: "25"},
              {key: "26br", text: "Milla Nueve", value: "26"},
              {key: "27br", text: "Moín", value: "27"},
              {key: "28br", text: "Piuta", value: "28"},
              {key: "29br", text: "Portete", value: "29"},
              {key: "30br", text: "Pueblo Nuevo", value: "30"},
              {key: "31br", text: "San Juan", value: "31"},
              {key: "32br", text: "Santa Eduvigis", value: "32"},
              {key: "33br", text: "Santa Rosa", value: "33"},
              {key: "34br", text: "Trinidad", value: "34"},
              {key: "35br", text: "Valle La Aurora", value: "35"},
              {key: "36br", text: "Veracruz", value: "36"},
              {key: "37br", text: "Villa Hermosa", value: "37"},
              {key: "38br", text: "Villas Del Mar Dos", value: "38"},
              {key: "39br", text: "Villas Del Mar Uno", value: "39"}]},
          {key: "2ds", text: "Valle La Estrella", value: "2", barrios: [
              {key: "1br", text: "Pandora Centro", value: "1"},
              {key: "2br", text: "Alsacia", value: "2"},
              {key: "3br", text: "Armenia", value: "3"},
              {key: "4br", text: "Atalanta", value: "4"},
              {key: "5br", text: "Bananito Sur", value: "5"},
              {key: "6br", text: "Boca Cuen", value: "6"},
              {key: "7br", text: "Boca Río Estrella", value: "7"},
              {key: "8br", text: "Bocuare", value: "8"},
              {key: "9br", text: "Bonifacio", value: "9"},
              {key: "10br", text: "Brisas", value: "10"},
              {key: "11br", text: "Buenavista", value: "11"},
              {key: "12br", text: "Burrico", value: "12"},
              {key: "13br", text: "Calveri", value: "13"},
              {key: "14br", text: "Caño Negro", value: "14"},
              {key: "15br", text: "Cartagena", value: "15"},
              {key: "16br", text: "Casa Amarilla", value: "16"},
              {key: "17br", text: "Cerere", value: "17"},
              {key: "18br", text: "Chirripó Abajo (parte)", value: "18"},
              {key: "19br", text: "Colonia", value: "19"},
              {key: "20br", text: "Concepción", value: "20"},
              {key: "21br", text: "Cuen", value: "21"},
              {key: "22br", text: "Durfuy (san Miguel)", value: "22"},
              {key: "23br", text: "Duruy", value: "23"},
              {key: "24br", text: "Finca Ocho", value: "24"},
              {key: "25br", text: "Fortuna", value: "25"},
              {key: "26br", text: "Gavilán", value: "26"},
              {key: "27br", text: "Guaria", value: "27"},
              {key: "28br", text: "Hueco", value: "28"},
              {key: "29br", text: "I Griega", value: "29"},
              {key: "30br", text: "Jabuy", value: "30"},
              {key: "31br", text: "Llano Grande", value: "31"},
              {key: "32br", text: "Loras", value: "32"},
              {key: "33br", text: "Manú", value: "33"},
              {key: "34br", text: "Miramar", value: "34"},
              {key: "35br", text: "Moi (san Vicente)", value: "35"},
              {key: "36br", text: "Nanabre", value: "36"},
              {key: "37br", text: "Nubes", value: "37"},
              {key: "38br", text: "Pandora Oeste", value: "38"},
              {key: "39br", text: "Penshurt", value: "39"},
              {key: "40br", text: "Pléyades", value: "40"},
              {key: "41br", text: "Porvenir", value: "41"},
              {key: "42br", text: "Progreso", value: "42"},
              {key: "43br", text: "Río Ley", value: "43"},
              {key: "44br", text: "Río Seco", value: "44"},
              {key: "45br", text: "San Andrés", value: "45"},
              {key: "46br", text: "San Carlos", value: "46"},
              {key: "47br", text: "San Clemente", value: "47"},
              {key: "48br", text: "San Rafael", value: "48"},
              {key: "49br", text: "Suruy", value: "49"},
              {key: "50br", text: "Talía", value: "50"},
              {key: "51br", text: "Tobruk", value: "51"},
              {key: "52br", text: "Tuba Creek (parte)", value: "52"},
              {key: "53br", text: "Valle De Las Rosas", value: "53"},
              {key: "54br", text: "Vegas De Cerere", value: "54"},
              {key: "55br", text: "Vesta", value: "55"}]},
          {key: "3ds", text: "Río Blanco", value: "3", barrios: [
              {key: "1br", text: "Liverpool", value: "1"},
              {key: "2br", text: "Brisas", value: "2"},
              {key: "3br", text: "Brisas De Veragua", value: "3"},
              {key: "4br", text: "Búfalo", value: "4"},
              {key: "5br", text: "Limón 2000", value: "5"},
              {key: "6br", text: "Loma Linda", value: "6"},
              {key: "7br", text: "México", value: "7"},
              {key: "8br", text: "Milla 9", value: "8"},
              {key: "9br", text: "Miravalles", value: "9"},
              {key: "10br", text: "Río Blanco", value: "10"},
              {key: "11br", text: "Río Cedro", value: "11"},
              {key: "12br", text: "Río Madre", value: "12"},
              {key: "13br", text: "Río Quito", value: "13"},
              {key: "14br", text: "Río Victoria", value: "14"},
              {key: "15br", text: "Sandoval", value: "15"},
              {key: "16br", text: "Santa Rita", value: "16"},
              {key: "17br", text: "Victoria", value: "17"}]},
          {key: "4ds", text: "Matama", value: "4", barrios: [
              {key: "1br", text: "La Bomba", value: "1"},
              {key: "2br", text: "Aguas Zarcas", value: "2"},
              {key: "3br", text: "Asunción", value: "3"},
              {key: "4br", text: "Bananito Norte", value: "4"},
              {key: "5br", text: "Bearesem", value: "5"},
              {key: "6br", text: "Beverley", value: "6"},
              {key: "7br", text: "Calle Tranvía", value: "7"},
              {key: "8br", text: "Castillo Nuevo", value: "8"},
              {key: "9br", text: "Dondonia", value: "9"},
              {key: "10br", text: "Filadelfia Norte", value: "10"},
              {key: "11br", text: "Filadelfia Sur", value: "11"},
              {key: "12br", text: "Kent", value: "12"},
              {key: "13br", text: "María Luisa", value: "13"},
              {key: "14br", text: "Mountain Cow", value: "14"},
              {key: "15br", text: "Paraíso", value: "15"},
              {key: "16br", text: "Polonia", value: "16"},
              {key: "17br", text: "Quitaría", value: "17"},
              {key: "18br", text: "Río Banano", value: "18"},
              {key: "19br", text: "San Cecilio", value: "19"},
              {key: "20br", text: "Tigre", value: "20"},
              {key: "21br", text: "Trébol", value: "21"},
              {key: "22br", text: "Westfalia", value: "22"}]}]},
      {key: "2cn", text: "Pococí", value: "2", distritos: [
          {key: "1ds", text: "Guápiles", value: "1", barrios: [
              {key: "1br", text: "Guápiles", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Blanco", value: "3"},
              {key: "4br", text: "Cacique", value: "4"},
              {key: "5br", text: "Calle Ángeles", value: "5"},
              {key: "6br", text: "Calle Gobierno", value: "6"},
              {key: "7br", text: "Calle Vargas", value: "7"},
              {key: "8br", text: "Cecilia", value: "8"},
              {key: "9br", text: "Coopevigua", value: "9"},
              {key: "10br", text: "Corinto", value: "10"},
              {key: "11br", text: "Diamantes", value: "11"},
              {key: "12br", text: "Emilia", value: "12"},
              {key: "13br", text: "Flores", value: "13"},
              {key: "14br", text: "Floresta", value: "14"},
              {key: "15br", text: "Garabito", value: "15"},
              {key: "16br", text: "Jesús", value: "16"},
              {key: "17br", text: "La Guaria", value: "17"},
              {key: "18br", text: "Marina", value: "18"},
              {key: "19br", text: "Palma Dorada", value: "19"},
              {key: "20br", text: "Palmera", value: "20"},
              {key: "21br", text: "Rancho Redondo", value: "21"},
              {key: "22br", text: "San Miguel", value: "22"},
              {key: "23br", text: "Sauces", value: "23"},
              {key: "24br", text: "Toro Amarillo", value: "24"}]},
          {key: "2ds", text: "Jiménez", value: "2", barrios: [
              {key: "1br", text: "Jiménez Centro", value: "1"},
              {key: "2br", text: "Anita Grande", value: "2"},
              {key: "3br", text: "Calle Diez", value: "3"},
              {key: "4br", text: "Calle Emilia", value: "4"},
              {key: "5br", text: "Calle Seis", value: "5"},
              {key: "6br", text: "Calle Uno", value: "6"},
              {key: "7br", text: "Condado Del Río", value: "7"},
              {key: "8br", text: "Floritas", value: "8"},
              {key: "9br", text: "Granja", value: "9"},
              {key: "10br", text: "Molino", value: "10"},
              {key: "11br", text: "Numancia", value: "11"},
              {key: "12br", text: "Parasal", value: "12"},
              {key: "13br", text: "San Luis", value: "13"},
              {key: "14br", text: "San Martín", value: "14"},
              {key: "15br", text: "San Valentín", value: "15"},
              {key: "16br", text: "Santa Clara", value: "16"},
              {key: "17br", text: "Suerre", value: "17"}]},
          {key: "3ds", text: "Rita", value: "3", barrios: [
              {key: "1br", text: "Rita Centro", value: "1"},
              {key: "2br", text: "Balastre", value: "2"},
              {key: "3br", text: "Cantagallo", value: "3"},
              {key: "4br", text: "Cartagena", value: "4"},
              {key: "5br", text: "Cayuga", value: "5"},
              {key: "6br", text: "Chirvalo", value: "6"},
              {key: "7br", text: "Cocorí", value: "7"},
              {key: "8br", text: "Cruce De Jordán", value: "8"},
              {key: "9br", text: "Encina", value: "9"},
              {key: "10br", text: "Gallopinto", value: "10"},
              {key: "11br", text: "Hamburgo", value: "11"},
              {key: "12br", text: "I Griega", value: "12"},
              {key: "13br", text: "Indio", value: "13"},
              {key: "14br", text: "Jardín", value: "14"},
              {key: "15br", text: "Mercedes", value: "15"},
              {key: "16br", text: "Palmitas", value: "16"},
              {key: "17br", text: "Peligro", value: "17"},
              {key: "18br", text: "Porvenir", value: "18"},
              {key: "19br", text: "Primavera", value: "19"},
              {key: "20br", text: "Pueblo Nuevo", value: "20"},
              {key: "21br", text: "Rótulo", value: "21"},
              {key: "22br", text: "San Carlos", value: "22"},
              {key: "23br", text: "San Cristóbal", value: "23"},
              {key: "24br", text: "San Gerardo", value: "24"},
              {key: "25br", text: "San Pedro", value: "25"},
              {key: "26br", text: "Santa Elena", value: "26"},
              {key: "27br", text: "Santa Rosa", value: "27"},
              {key: "28br", text: "Sirena", value: "28"},
              {key: "29br", text: "Suárez", value: "29"},
              {key: "30br", text: "Suerte", value: "30"},
              {key: "31br", text: "Tarire", value: "31"},
              {key: "32br", text: "Teresa", value: "32"},
              {key: "33br", text: "Ticabán", value: "33"},
              {key: "34br", text: "Triángulo", value: "34"},
              {key: "35br", text: "Victoria", value: "35"}]},
          {key: "4ds", text: "Roxana", value: "4", barrios: [
              {key: "1br", text: "Roxana Centro", value: "1"},
              {key: "2br", text: "Aguas Frías", value: "2"},
              {key: "3br", text: "Anabán", value: "3"},
              {key: "4br", text: "Boca Guápiles (parte)", value: "4"},
              {key: "5br", text: "Castañal", value: "5"},
              {key: "6br", text: "Cruce", value: "6"},
              {key: "7br", text: "Curia", value: "7"},
              {key: "8br", text: "Curva", value: "8"},
              {key: "9br", text: "Curva Del Humo", value: "9"},
              {key: "10br", text: "Esperanza", value: "10"},
              {key: "11br", text: "Fortuna", value: "11"},
              {key: "12br", text: "Humo", value: "12"},
              {key: "13br", text: "La Cruz", value: "13"},
              {key: "14br", text: "La Lidia", value: "14"},
              {key: "15br", text: "Lesville", value: "15"},
              {key: "16br", text: "Llano Bonito", value: "16"},
              {key: "17br", text: "Lomas Azules", value: "17"},
              {key: "18br", text: "Londres", value: "18"},
              {key: "19br", text: "Maravilla", value: "19"},
              {key: "20br", text: "Mata De Limón", value: "20"},
              {key: "21br", text: "Millón", value: "21"},
              {key: "22br", text: "Milloncito", value: "22"},
              {key: "23br", text: "Oeste", value: "23"},
              {key: "24br", text: "Prado (parte)", value: "24"},
              {key: "25br", text: "Punta De Riel", value: "25"},
              {key: "26br", text: "Roxana Tres", value: "26"},
              {key: "27br", text: "San Francisco", value: "27"},
              {key: "28br", text: "San Jorge", value: "28"},
              {key: "29br", text: "Vegas De Tortuguero", value: "29"}]},
          {key: "5ds", text: "Cariari", value: "5", barrios: [
              {key: "1br", text: "Cariari Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Astúa-pirie", value: "3"},
              {key: "4br", text: "Banamola", value: "4"},
              {key: "5br", text: "Boca Guápiles (parte)", value: "5"},
              {key: "6br", text: "Campo Cuatro", value: "6"},
              {key: "7br", text: "Campo De Aterrizaje (pueblo Triste)", value: "7"},
              {key: "8br", text: "Campo Dos", value: "8"},
              {key: "9br", text: "Campo Tres", value: "9"},
              {key: "10br", text: "Campo Tres Este", value: "10"},
              {key: "11br", text: "Campo Tres Oeste", value: "11"},
              {key: "12br", text: "Caño Chiquero", value: "12"},
              {key: "13br", text: "Carolina", value: "13"},
              {key: "14br", text: "Ceibo", value: "14"},
              {key: "15br", text: "Coopecariari", value: "15"},
              {key: "16br", text: "Cuatro Esquinas", value: "16"},
              {key: "17br", text: "Encanto", value: "17"},
              {key: "18br", text: "Esperanza (cantarrana)", value: "18"},
              {key: "19br", text: "Formosa", value: "19"},
              {key: "20br", text: "Frutera", value: "20"},
              {key: "21br", text: "Gaviotas", value: "21"},
              {key: "22br", text: "Hojancha", value: "22"},
              {key: "23br", text: "Maná", value: "23"},
              {key: "24br", text: "Monterrey", value: "24"},
              {key: "25br", text: "Nazaret", value: "25"},
              {key: "26br", text: "Palermo", value: "26"},
              {key: "27br", text: "Progreso", value: "27"},
              {key: "28br", text: "Pueblo Nuevo", value: "28"},
              {key: "29br", text: "Sagrada Familia", value: "29"},
              {key: "30br", text: "San Miguel", value: "30"},
              {key: "31br", text: "Semillero", value: "31"},
              {key: "32br", text: "Vega De Río Palacios", value: "32"},
              {key: "33br", text: "Zacatales", value: "33"}]},
          {key: "6ds", text: "Colorado", value: "6", barrios: [
              {key: "1br", text: "Barra Del Colorado", value: "1"},
              {key: "2br", text: "Aragón", value: "2"},
              {key: "3br", text: "Barra Del Colorado Este", value: "3"},
              {key: "4br", text: "Buenavista", value: "4"},
              {key: "5br", text: "Malanga", value: "5"},
              {key: "6br", text: "Puerto Lindo", value: "6"},
              {key: "7br", text: "San Gerardo", value: "7"},
              {key: "8br", text: "Tortuguero", value: "8"},
              {key: "9br", text: "Verdades", value: "9"}]},
          {key: "7ds", text: "La Colonia", value: "7", barrios: [
              {key: "1br", text: "San Rafael", value: "1"},
              {key: "2br", text: "Brisas Del Toro Amarillo", value: "2"},
              {key: "3br", text: "Cascadas", value: "3"},
              {key: "4br", text: "La Victoria", value: "4"},
              {key: "5br", text: "Losilla", value: "5"},
              {key: "6br", text: "Prado (parte)", value: "6"},
              {key: "7br", text: "San Bosco", value: "7"},
              {key: "8br", text: "Santa Elena", value: "8"}]}]},
      {key: "3cn", text: "Siquirres", value: "3", distritos: [
          {key: "1ds", text: "Siquirres", value: "1", barrios: [
              {key: "1br", text: "Siquirres Centro", value: "1"},
              {key: "2br", text: "Amelia", value: "2"},
              {key: "3br", text: "Amistad", value: "3"},
              {key: "4br", text: "Bajo Tigre", value: "4"},
              {key: "5br", text: "Barnstorf", value: "5"},
              {key: "6br", text: "Betania", value: "6"},
              {key: "7br", text: "Brooklin", value: "7"},
              {key: "8br", text: "Calle Tajo", value: "8"},
              {key: "9br", text: "Calvario", value: "9"},
              {key: "10br", text: "Canadá", value: "10"},
              {key: "11br", text: "Caño Blanco", value: "11"},
              {key: "12br", text: "Cocal", value: "12"},
              {key: "13br", text: "Coco", value: "13"},
              {key: "14br", text: "Ganga", value: "14"},
              {key: "15br", text: "Guayacán", value: "15"},
              {key: "16br", text: "Indiana Dos", value: "16"},
              {key: "17br", text: "Indiana Tres", value: "17"},
              {key: "18br", text: "Indiana Uno", value: "18"},
              {key: "19br", text: "Invu", value: "19"},
              {key: "20br", text: "Lindavista", value: "20"},
              {key: "21br", text: "Livingston", value: "21"},
              {key: "22br", text: "Lucha", value: "22"},
              {key: "23br", text: "María Auxiliadora", value: "23"},
              {key: "24br", text: "Milla 52", value: "24"},
              {key: "25br", text: "Moravia", value: "25"},
              {key: "26br", text: "Morazán", value: "26"},
              {key: "27br", text: "Palmiras", value: "27"},
              {key: "28br", text: "Quebrador", value: "28"},
              {key: "29br", text: "San Alberto Nuevo", value: "29"},
              {key: "30br", text: "San Alberto Viejo", value: "30"},
              {key: "31br", text: "San Alejo", value: "31"},
              {key: "32br", text: "San Joaquín", value: "32"},
              {key: "33br", text: "San Martín", value: "33"},
              {key: "34br", text: "San Rafael", value: "34"},
              {key: "35br", text: "Triunfo", value: "35"}]},
          {key: "2ds", text: "Pacuarito", value: "2", barrios: [
              {key: "1br", text: "Pacuarito Centro", value: "1"},
              {key: "2br", text: "Alto Mirador", value: "2"},
              {key: "3br", text: "Altos De Pacuarito", value: "3"},
              {key: "4br", text: "Buenos Aires", value: "4"},
              {key: "5br", text: "Cimarrones", value: "5"},
              {key: "6br", text: "Culpeper", value: "6"},
              {key: "7br", text: "Cultivez", value: "7"},
              {key: "8br", text: "Freehold", value: "8"},
              {key: "9br", text: "Freeman (san Rafael)", value: "9"},
              {key: "10br", text: "Galicia", value: "10"},
              {key: "11br", text: "Isla Nueva", value: "11"},
              {key: "12br", text: "Leona", value: "12"},
              {key: "13br", text: "Madre De Dios", value: "13"},
              {key: "14br", text: "Manila", value: "14"},
              {key: "15br", text: "Monteverde", value: "15"},
              {key: "16br", text: "Pacuare", value: "16"},
              {key: "17br", text: "Perla", value: "17"},
              {key: "18br", text: "Perlita", value: "18"},
              {key: "19br", text: "Río Hondo", value: "19"},
              {key: "20br", text: "San Luis", value: "20"},
              {key: "21br", text: "San Carlos", value: "21"},
              {key: "22br", text: "San Isidro", value: "22"},
              {key: "23br", text: "San Pablo", value: "23"},
              {key: "24br", text: "Santa Rosa", value: "24"},
              {key: "25br", text: "Ten Switch", value: "25"},
              {key: "26br", text: "Trinidad", value: "26"},
              {key: "27br", text: "Unión Campesina", value: "27"},
              {key: "28br", text: "Waldeck", value: "28"}]},
          {key: "3ds", text: "Florida", value: "3", barrios: [
              {key: "1br", text: "Florida Centro", value: "1"},
              {key: "2br", text: "Alto Gracias A Dios", value: "2"},
              {key: "3br", text: "Alto Laurelar", value: "3"},
              {key: "4br", text: "Altos De Pascua", value: "4"},
              {key: "5br", text: "Bonilla Abajo", value: "5"},
              {key: "6br", text: "Casorla", value: "6"},
              {key: "7br", text: "Chonta", value: "7"},
              {key: "8br", text: "Destierro", value: "8"},
              {key: "9br", text: "El Alto", value: "9"},
              {key: "10br", text: "Fourth Cliff", value: "10"},
              {key: "11br", text: "Huecos", value: "11"},
              {key: "12br", text: "Llano", value: "12"},
              {key: "13br", text: "Lomas", value: "13"},
              {key: "14br", text: "Pascua", value: "14"},
              {key: "15br", text: "Roca", value: "15"},
              {key: "16br", text: "Rubí", value: "16"},
              {key: "17br", text: "San Antonio", value: "17"},
              {key: "18br", text: "Tunel Camp", value: "18"}]},
          {key: "4ds", text: "Germania", value: "4", barrios: [
              {key: "1br", text: "Germania Centro", value: "1"},
              {key: "2br", text: "América", value: "2"},
              {key: "3br", text: "Babilonia", value: "3"},
              {key: "4br", text: "Cacao", value: "4"},
              {key: "5br", text: "Colombiana", value: "5"},
              {key: "6br", text: "Herediana", value: "6"},
              {key: "7br", text: "Milano", value: "7"},
              {key: "8br", text: "Trinidad", value: "8"},
              {key: "9br", text: "Williamsburg", value: "9"}]},
          {key: "5ds", text: "El Cairo", value: "5", barrios: [
              {key: "1br", text: "El Cairo Centro", value: "1"},
              {key: "2br", text: "Ana", value: "2"},
              {key: "3br", text: "Bellavista", value: "3"},
              {key: "4br", text: "Boca Río Jiménez", value: "4"},
              {key: "5br", text: "Castilla", value: "5"},
              {key: "6br", text: "Catalinas", value: "6"},
              {key: "7br", text: "Cocal", value: "7"},
              {key: "8br", text: "Francia", value: "8"},
              {key: "9br", text: "Golden Grove", value: "9"},
              {key: "10br", text: "Josefina", value: "10"},
              {key: "11br", text: "Junta", value: "11"},
              {key: "12br", text: "Laureles", value: "12"},
              {key: "13br", text: "Luisiana", value: "13"},
              {key: "14br", text: "Milla 3", value: "14"},
              {key: "15br", text: "Milla 4", value: "15"},
              {key: "16br", text: "Milla 5", value: "16"},
              {key: "17br", text: "Milla 6", value: "17"},
              {key: "18br", text: "Ontario", value: "18"},
              {key: "19br", text: "Peje", value: "19"},
              {key: "20br", text: "Seis Amigos", value: "20"},
              {key: "21br", text: "Silencio", value: "21"}]},
          {key: "6ds", text: "Alegría", value: "6", barrios: [
              {key: "1br", text: "Alegría Centro", value: "1"},
              {key: "2br", text: "Alto Herediana", value: "2"},
              {key: "3br", text: "Cruce", value: "3"},
              {key: "4br", text: "Portón Iberia", value: "4"},
              {key: "5br", text: "Río Peje", value: "5"},
              {key: "6br", text: "Vueltas", value: "6"}]},
          {key: "7ds", text: "Reventazón", value: "7", barrios: [
              {key: "1br", text: "Nueva Esperanza", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Boca Pacuare", value: "3"},
              {key: "4br", text: "Boca Parismina", value: "4"},
              {key: "5br", text: "Carmen", value: "5"},
              {key: "6br", text: "Caño Blanco", value: "6"},
              {key: "7br", text: "Celina", value: "7"},
              {key: "8br", text: "Ciudadela Flores", value: "8"},
              {key: "9br", text: "Dos Bocas (suerre)", value: "9"},
              {key: "10br", text: "Encanto Norte", value: "10"},
              {key: "11br", text: "Encanto Sur", value: "11"},
              {key: "12br", text: "Imperio", value: "12"},
              {key: "13br", text: "Islona", value: "13"},
              {key: "14br", text: "Nueva Virginia", value: "14"},
              {key: "15br", text: "Maryland", value: "15"},
              {key: "16br", text: "Pueblo Civil", value: "16"},
              {key: "17br", text: "Santo Domingo", value: "17"},
              {key: "18br", text: "Vegas De Imperio", value: "18"}]}]},
      {key: "4cn", text: "Talamanca", value: "4", distritos: [
          {key: "1ds", text: "Bratsi", value: "1", barrios: [
              {key: "1br", text: "Bribri centro", value: "1"},
              {key: "2br", text: "Akberie (piedra Grande)", value: "2"},
              {key: "3br", text: "Altamira", value: "3"},
              {key: "5br", text: "Bambú", value: "5"},
              {key: "6br", text: "Chase", value: "6"},
              {key: "7br", text: "Cuabre", value: "7"},
              {key: "8br", text: "Fields", value: "8"},
              {key: "9br", text: "Gavilán Canta", value: "9"},
              {key: "10br", text: "Hu-berie (rancho Grande)", value: "10"},
              {key: "11br", text: "Mleyuk 1", value: "11"},
              {key: "12br", text: "Mleyuk 2", value: "12"},
              {key: "13br", text: "Monte Sión", value: "13"},
              {key: "14br", text: "Olivia", value: "14"},
              {key: "15br", text: "Sand Box", value: "15"},
              {key: "16br", text: "Shiroles", value: "16"},
              {key: "17br", text: "Sibujú", value: "17"},
              {key: "18br", text: "Suretka", value: "18"},
              {key: "19br", text: "Uatsi", value: "19"}]},
          {key: "2ds", text: "Sixaola", value: "2", barrios: [
              {key: "1br", text: "Sixaola Centro", value: "1"},
              {key: "2br", text: "Ania", value: "2"},
              {key: "3br", text: "Boca Sixaola", value: "3"},
              {key: "4br", text: "Catarina", value: "4"},
              {key: "5br", text: "Celia", value: "5"},
              {key: "6br", text: "Daytonia", value: "6"},
              {key: "7br", text: "Gandoca", value: "7"},
              {key: "8br", text: "Margarita", value: "8"},
              {key: "9br", text: "Mata De Limón", value: "9"},
              {key: "10br", text: "Noventa Y Seis", value: "10"},
              {key: "11br", text: "Palma", value: "11"},
              {key: "12br", text: "Paraíso", value: "12"},
              {key: "13br", text: "Parque", value: "13"},
              {key: "14br", text: "San Miguel", value: "14"},
              {key: "15br", text: "San Miguelito", value: "15"},
              {key: "16br", text: "San Rafael", value: "16"},
              {key: "17br", text: "Virginia", value: "17"},
              {key: "18br", text: "Zavala", value: "18"}]},
          {key: "3ds", text: "Cahuita", value: "3", barrios: [
              {key: "1br", text: "Cahuita Centro", value: "1"},
              {key: "2br", text: "Buenavista (katuir)", value: "2"},
              {key: "3br", text: "Bordón", value: "3"},
              {key: "4br", text: "Carbón", value: "4"},
              {key: "5br", text: "Carbón 1", value: "5"},
              {key: "6br", text: "Carbón 2", value: "6"},
              {key: "7br", text: "Catarata", value: "7"},
              {key: "8br", text: "Cocles", value: "8"},
              {key: "9br", text: "Comadre", value: "9"},
              {key: "10br", text: "Dindirí", value: "10"},
              {key: "11br", text: "Gibraltar", value: "11"},
              {key: "12br", text: "Hone Creek", value: "12"},
              {key: "13br", text: "Hotel Creek", value: "13"},
              {key: "14br", text: "Kekoldi", value: "14"},
              {key: "15br", text: "Limonal", value: "15"},
              {key: "16br", text: "Manzanillo", value: "16"},
              {key: "17br", text: "Mile Creek", value: "17"},
              {key: "18br", text: "Patiño", value: "18"},
              {key: "19br", text: "Playa Chiquita", value: "19"},
              {key: "20br", text: "Puerto Viejo", value: "20"},
              {key: "21br", text: "Punta Caliente", value: "21"},
              {key: "22br", text: "Punta Cocles", value: "22"},
              {key: "23br", text: "Punta Mona", value: "23"},
              {key: "24br", text: "Punta Uva", value: "24"},
              {key: "25br", text: "Tuba Creek (parte)", value: "25"}]},
          {key: "4ds", text: "Telire", value: "4", barrios: [
              {key: "1br", text: "Amubri", value: "1"},
              {key: "2br", text: "Alto Cuen (kjacka Bata)", value: "2"},
              {key: "3br", text: "Alto Lari (duriñak)", value: "3"},
              {key: "4br", text: "Alto Urén", value: "4"},
              {key: "5br", text: "Arenal", value: "5"},
              {key: "6br", text: "Bajo Blei", value: "6"},
              {key: "7br", text: "Bajo Cuen", value: "7"},
              {key: "8br", text: "Boca Urén", value: "8"},
              {key: "9br", text: "Bris", value: "9"},
              {key: "10br", text: "Cachabli", value: "10"},
              {key: "11br", text: "Coroma", value: "11"},
              {key: "12br", text: "Croriña", value: "12"},
              {key: "13br", text: "China Kichá", value: "13"},
              {key: "14br", text: "Dururpe", value: "14"},
              {key: "15br", text: "Guachalaba", value: "15"},
              {key: "16br", text: "Katsi", value: "16"},
              {key: "17br", text: "Kichuguecha", value: "17"},
              {key: "18br", text: "Kivut", value: "18"},
              {key: "19br", text: "Mojoncito", value: "19"},
              {key: "20br", text: "Namuwakir", value: "20"},
              {key: "21br", text: "Orochico", value: "21"},
              {key: "22br", text: "Ourut", value: "22"},
              {key: "23br", text: "Purisquí", value: "23"},
              {key: "24br", text: "Purita", value: "24"},
              {key: "25br", text: "Rangalle", value: "25"},
              {key: "26br", text: "San José Cabecar", value: "26"},
              {key: "27br", text: "Sepeque", value: "27"},
              {key: "28br", text: "Shewab", value: "28"},
              {key: "29br", text: "Sipurio", value: "29"},
              {key: "30br", text: "Soky", value: "30"},
              {key: "31br", text: "Sorókicha", value: "31"},
              {key: "32br", text: "Sukut", value: "32"},
              {key: "33br", text: "Surayo", value: "33"},
              {key: "34br", text: "Suiri", value: "34"},
              {key: "35br", text: "Telire", value: "35"},
              {key: "36br", text: "Turubokicha", value: "36"},
              {key: "37br", text: "Urén", value: "37"}]}]},
      {key: "5cn", text: "Matina", value: "5", distritos: [
          {key: "1ds", text: "Matina", value: "1", barrios: [
              {key: "1br", text: "Matina Centro", value: "1"},
              {key: "2br", text: "Baltimore", value: "2"},
              {key: "3br", text: "Barra De Matina Norte", value: "3"},
              {key: "4br", text: "Bristol", value: "4"},
              {key: "5br", text: "Chirripó", value: "5"},
              {key: "6br", text: "Chumico", value: "6"},
              {key: "7br", text: "Colonia Puriscaleña", value: "7"},
              {key: "8br", text: "Corina", value: "8"},
              {key: "9br", text: "Esperanza", value: "9"},
              {key: "10br", text: "Goli", value: "10"},
              {key: "11br", text: "Helvetia", value: "11"},
              {key: "12br", text: "Hilda", value: "12"},
              {key: "13br", text: "Línea B", value: "13"},
              {key: "14br", text: "Luisa Oeste", value: "14"},
              {key: "15br", text: "Milla 23", value: "15"},
              {key: "16br", text: "Milla 4", value: "16"},
              {key: "17br", text: "Palmeras", value: "17"},
              {key: "18br", text: "Pozo Azul", value: "18"},
              {key: "19br", text: "Punta De Lanza", value: "19"},
              {key: "20br", text: "San Miguel", value: "20"},
              {key: "21br", text: "Victoria", value: "21"},
              {key: "22br", text: "Xirinachs", value: "22"}]},
          {key: "2ds", text: "Batán", value: "2", barrios: [
              {key: "1br", text: "Batán Centro", value: "1"},
              {key: "2br", text: "Almendros", value: "2"},
              {key: "3br", text: "Barbilla", value: "3"},
              {key: "4br", text: "Berta", value: "4"},
              {key: "5br", text: "Damasco", value: "5"},
              {key: "6br", text: "Davao", value: "6"},
              {key: "7br", text: "Dos Ramas", value: "7"},
              {key: "8br", text: "Espavel", value: "8"},
              {key: "9br", text: "Goshen", value: "9"},
              {key: "10br", text: "Leyte", value: "10"},
              {key: "11br", text: "Lola", value: "11"},
              {key: "12br", text: "Luzón", value: "12"},
              {key: "13br", text: "Margarita", value: "13"},
              {key: "14br", text: "Milla 24", value: "14"},
              {key: "15br", text: "Milla 25", value: "15"},
              {key: "16br", text: "Milla 27", value: "16"},
              {key: "17br", text: "Milla 28", value: "17"},
              {key: "18br", text: "Oracabesa", value: "18"},
              {key: "19br", text: "Parcelas", value: "19"},
              {key: "20br", text: "Ramal Siete", value: "20"},
              {key: "21br", text: "Sahara", value: "21"},
              {key: "22br", text: "Santa Marta", value: "22"},
              {key: "23br", text: "Titán", value: "23"},
              {key: "24br", text: "Vegas", value: "24"}]},
          {key: "3ds", text: "Carrandi", value: "3", barrios: [
              {key: "1br", text: "Estrada", value: "1"},
              {key: "2br", text: "Bananita", value: "2"},
              {key: "3br", text: "Barra De Matina Sur", value: "3"},
              {key: "4br", text: "Boca Del Pantano", value: "4"},
              {key: "5br", text: "Boca Río Matina", value: "5"},
              {key: "6br", text: "Boston", value: "6"},
              {key: "7br", text: "Brisas", value: "7"},
              {key: "8br", text: "California", value: "8"},
              {key: "9br", text: "Indio", value: "9"},
              {key: "10br", text: "Larga Distancia", value: "10"},
              {key: "11br", text: "Lomas Del Toro", value: "11"},
              {key: "12br", text: "Luisa Este", value: "12"},
              {key: "13br", text: "Maravilla", value: "13"},
              {key: "14br", text: "Milla 14", value: "14"},
              {key: "15br", text: "Nueva York", value: "15"},
              {key: "16br", text: "Palacios", value: "16"},
              {key: "17br", text: "Palestina", value: "17"},
              {key: "18br", text: "Peje", value: "18"},
              {key: "19br", text: "Punta De Riel", value: "19"},
              {key: "20br", text: "Río Cuba", value: "20"},
              {key: "21br", text: "Río Peje", value: "21"},
              {key: "22br", text: "Saborío", value: "22"},
              {key: "23br", text: "San Edmundo", value: "23"},
              {key: "24br", text: "San José", value: "24"},
              {key: "25br", text: "Santa María", value: "25"},
              {key: "26br", text: "Sterling", value: "26"},
              {key: "27br", text: "Strafford", value: "27"},
              {key: "28br", text: "Toro", value: "28"},
              {key: "29br", text: "Trinidad", value: "29"},
              {key: "30br", text: "Venecia", value: "30"},
              {key: "31br", text: "Zent", value: "31"}]}]},
      {key: "6cn", text: "Guácimo", value: "6", distritos: [
          {key: "1ds", text: "Guácimo", value: "1", barrios: [
              {key: "1br", text: "Guácimo Centro", value: "1"},
              {key: "2br", text: "África", value: "2"},
              {key: "3br", text: "Cantarrana", value: "3"},
              {key: "4br", text: "Estación Rudín", value: "4"},
              {key: "5br", text: "Guayacán", value: "5"},
              {key: "6br", text: "Aguacate", value: "6"},
              {key: "7br", text: "Angelina", value: "7"},
              {key: "8br", text: "Bosque", value: "8"},
              {key: "9br", text: "Cabaña", value: "9"},
              {key: "10br", text: "Edén", value: "10"},
              {key: "11br", text: "El Tres", value: "11"},
              {key: "12br", text: "Fox Hall", value: "12"},
              {key: "13br", text: "Guaira", value: "13"},
              {key: "14br", text: "Hogar", value: "14"},
              {key: "15br", text: "Parismina", value: "15"},
              {key: "16br", text: "San Luis", value: "16"},
              {key: "17br", text: "Selva", value: "17"}]},
          {key: "2ds", text: "Mercedes", value: "2", barrios: [
              {key: "1br", text: "Mercedes Centro", value: "1"},
              {key: "2br", text: "Bremen", value: "2"},
              {key: "3br", text: "Argentina", value: "3"},
              {key: "4br", text: "Confianza", value: "4"},
              {key: "5br", text: "Iroquois", value: "5"}]},
          {key: "3ds", text: "Pocora", value: "3", barrios: [
              {key: "1br", text: "Pocora Centro", value: "1"},
              {key: "2br", text: "Ojo De Agua", value: "2"},
              {key: "3br", text: "Pocora Sur", value: "3"}]},
          {key: "4ds", text: "Río Jiménez", value: "4", barrios: [
              {key: "1br", text: "Río Jiménez Centro", value: "1"},
              {key: "2br", text: "Ángeles", value: "2"},
              {key: "3br", text: "Bocas Del Río Silencio", value: "3"},
              {key: "4br", text: "Camarón", value: "4"},
              {key: "5br", text: "Cartagena", value: "5"},
              {key: "6br", text: "Dulce Nombre", value: "6"},
              {key: "7br", text: "Escocia", value: "7"},
              {key: "8br", text: "Irlanda", value: "8"},
              {key: "9br", text: "Jardín", value: "9"},
              {key: "10br", text: "Ligia", value: "10"},
              {key: "11br", text: "Lucha", value: "11"},
              {key: "12br", text: "Santa María", value: "12"},
              {key: "13br", text: "Santa Rosa", value: "13"},
              {key: "14br", text: "Socorro", value: "14"}]},
          {key: "5ds", text: "Duacarí", value: "5", barrios: [
              {key: "1br", text: "Villafranca", value: "1"},
              {key: "2br", text: "Aguas Gatas", value: "2"},
              {key: "3br", text: "Carambola", value: "3"},
              {key: "4br", text: "Castaño", value: "4"},
              {key: "5br", text: "Esperanza", value: "5"},
              {key: "6br", text: "Fruta De Pan", value: "6"},
              {key: "7br", text: "Limbo", value: "7"},
              {key: "8br", text: "San Cristóbal", value: "8"},
              {key: "9br", text: "Zancudo", value: "9"}]}]}]}
];
