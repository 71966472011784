import {createHeaders, createBody, request} from "./api";
import {insert, select, clean} from "./storage";
import {getUsuarioRequest} from "./apiUsuario";

export const loginRequest = async(username, password, rememberMe) => {
  try {
    const response = await request("oauth/token", {method: "POST", body: createBody({username, password, grant_type: "password"}), headers: await createHeaders(false)});
    if (typeof response === "object") {
      if (await select("username") !== username) {
        await clean();
      }
      if (rememberMe) {
        await insert("authenticated", true);
      }
      await insert("tokens", response);
      await insert("username", username);
      const usuario = await getUsuarioRequest(username);
      await insert("usuarioId", usuario.id);
      await insert("usuarioRol", usuario.roles[0]);
      return [usuario.id, usuario.roles[0]];
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "unauthorized" || error === "Status 400" ? "Usuario o contrase\u00f1a incorrectos" : error;
    throw errorMessage;
  }
}