import {useEffect} from "react";
import {useCaja as useItem, usePageHandler} from "../customHooks";
import {abrirCajaRequest, cerrarCajaRequest, estadoCajaRequest} from "../helpers";
import {Container, Button, Card, ListGroup, CustomAlert} from "../components";

const pageLabels = {genericError: "Ha ocurrido un error"};

export const CajaPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const sucursal = controller.usuario.sucursal.get;

  useEffect(() => {
    if (sucursal) {
      pageHandler.item.reset();
      pageHandler.item.setEstadoCaja("");
      getEstadoCaja();
    }
  }, [sucursal]);

  const getEstadoCaja = async() => {
    try {
      pageHandler.initProcess(true);
      const response = await estadoCajaRequest(sucursal);
      if (pageHandler.isMounted) {
        if (response) {
          pageHandler.item.setObject(response);
          pageHandler.item.setEstadoCaja("abierta");
        } else {
          pageHandler.item.setEstadoCaja("cerrada");
        }
        pageHandler.setIsLoading(false);
      }
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleAbrirCaja = () => {
    const abrirCaja = async() => {
      try {
        pageHandler.initProcess(true);
        const response = await abrirCajaRequest(sucursal);
        if (pageHandler.isMounted) {
          pageHandler.item.setObject(response);
          pageHandler.item.setEstadoCaja("abierta");
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    abrirCaja();
  }

  const handleCerrarCaja = () => {
    const cerrarCaja = async() => {
      try {
        pageHandler.initProcess(true);
        const response = await cerrarCajaRequest(sucursal);
        if (pageHandler.isMounted) {
          pageHandler.item.setObject(response);
          pageHandler.item.setEstadoCaja("cerrada");
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    cerrarCaja();
  }

  if (sucursal) {
    if (pageHandler.item.estadoCaja) {
      const cajaAbierta = pageHandler.item.estadoCaja === "abierta";

      return (
        <Container className="mid-container">
          <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
          <Card className="text-center">
            <Card.Header>
              <Button variant={cajaAbierta ? "danger" : "primary"} disabled={pageHandler.isLoading} onClick={cajaAbierta ? handleCerrarCaja : handleAbrirCaja}>
                {cajaAbierta ? "Cerrar Caja" : "Abrir Caja"}
              </Button>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>Fecha de apertura: {pageHandler.item.abierta}</ListGroup.Item>
                <ListGroup.Item>Fecha de cierre: {pageHandler.item.cerrada}</ListGroup.Item>
                <ListGroup.Item>Efectivo: {pageHandler.item.totalEfectivo}</ListGroup.Item>
                <ListGroup.Item>Tarjeta: {pageHandler.item.totalTarjeta}</ListGroup.Item>
                <ListGroup.Item>Cheque: {pageHandler.item.totalCheque}</ListGroup.Item>
                <ListGroup.Item>Transferencia / dep&oacute;sito bancario: {pageHandler.item.totalTransferencia}</ListGroup.Item>
                <ListGroup.Item>Recaudado por terceros: {pageHandler.item.totalRecaudadoTerceros}</ListGroup.Item>
                <ListGroup.Item>Otros: {pageHandler.item.totalOtros}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
            <Card.Footer>Total: {pageHandler.item.totales()}</Card.Footer>
          </Card>
        </Container>
      );
    }
    return (<Container className="full-container"><CustomAlert type="info" body="Cargando informaci&oacute;n" show /></Container>);
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>);
}