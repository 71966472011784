import {usePageImportHandler, useCliente} from "../customHooks";
import {createClienteRequest, normalizeString} from "../helpers";
import {Container, CustomAlert} from "../components";
import {clienteFields, ubicaciones} from "../constants";

export const ImportarClientesPage = ({controller}) => {
  const pageHandler = usePageImportHandler(useCliente, clienteFields());

  const validateItem = (itemData, index, validGeneral) => {
    const rowValidFields = [...pageHandler.tableBodyValidFields[index]];
    let validRow = validGeneral;
    for (let i = 0; i < pageHandler.selectValues.length; i++) {
      const option = pageHandler.selectOptions.find(item => item.value === pageHandler.selectValues[i]);
      if (!option.dependency) {
        let value = String(itemData[i]);
        if (option.general) {
          rowValidFields[i] = 3;
          value = option.generalValue;
        } else {
          let invalidField = false;
          const invalidBlank = option.required && value === "";
          if (option.editInput === "select") {
            const validOptions = option.selectOptions.map(item => item.import ?? item.value);
            const invalidOption = value !== "" && !validOptions.includes(value);
            invalidField = invalidBlank || invalidOption;
          } else if (option.editInput === "text") {
            const invalidNumber = option.number && value !== "" && isNaN(value);
            const invalidLength = option.length && value.length !== option.length;
            const invalidMax = option.max && value.length > option.max;
            const invalidMin = option.min && value.length < option.min;
            invalidField = invalidNumber || invalidLength || invalidMax || invalidMin || invalidBlank;
          }
          if (option.value === "correoElectronico") {
            if (value.length < 5 || !value.includes("@")) {
              invalidField = true;
            }
          }
          rowValidFields[i] = invalidField ? 2 : 1;
          if (invalidField) {
            validRow = false;
          }
        }
      }
    }
    const provinciaIndex = pageHandler.selectValues.findIndex(value => value === "provincia");
    const cantonIndex = pageHandler.selectValues.findIndex(value => value === "canton");
    const distritoIndex = pageHandler.selectValues.findIndex(value => value === "distrito");
    const barrioIndex = pageHandler.selectValues.findIndex(value => value === "barrio");
    let provincia = true, canton = true, distrito = true, barrio = true;
    if (provinciaIndex > -1) {
      provincia = ubicaciones().find(item => normalizeString(item.text) === normalizeString(String(itemData[provinciaIndex])) || item.value === String(itemData[provinciaIndex]));
      rowValidFields[provinciaIndex] = !provincia ? 2 : 1;
    }
    if (cantonIndex > -1) {
      canton = !!provincia ? provincia.cantones.find(item => normalizeString(item.text) === normalizeString(String(itemData[cantonIndex])) || item.value === String(itemData[cantonIndex])) : false;
      rowValidFields[cantonIndex] = !canton ? 2 : 1;
    }
    if (distritoIndex > -1) {
      distrito = !!canton ? canton.distritos.find(item => normalizeString(item.text) === normalizeString(String(itemData[distritoIndex])) || item.value === String(itemData[distritoIndex])) : false;
      rowValidFields[distritoIndex] = !distrito ? 2 : 1;
    }
    if (barrioIndex > -1) {
      barrio = !!distrito ? distrito.barrios.find(item => normalizeString(item.text) === normalizeString(String(itemData[barrioIndex])) || item.value === String(itemData[barrioIndex])) : false;
      rowValidFields[barrioIndex] = !barrio ? 2 : 1;
    }
    if (!provincia || !canton || !distrito || !barrio) {
      validRow = false;
    }
    return [validRow, rowValidFields];
  }

  const validateRow = (itemData, index) => {
    const validFields = pageHandler.validateOptionFields();
    const [validRow, rowValidFields] = validateItem(itemData, index, validFields);
    const tableBodyValidAux = [...pageHandler.tableBodyValid];
    const tableBodyValidFieldsAux = JSON.parse(JSON.stringify(pageHandler.tableBodyValidFields));
    tableBodyValidAux[index] = validRow;
    tableBodyValidFieldsAux[index] = rowValidFields;
    pageHandler.setTableBodyValid(tableBodyValidAux);
    pageHandler.setTableBodyValidFields(tableBodyValidFieldsAux);
  }

  const validateTable = () => {
    const validFields = pageHandler.validateOptionFields();
    const tableBodyValidAux = [...pageHandler.tableBodyValid];
    const tableBodyValidFieldsAux = JSON.parse(JSON.stringify(pageHandler.tableBodyValidFields));
    for (let index = 0; index < pageHandler.tableBody.length; index++) {
      const [validRow, rowValidFields] = validateItem(pageHandler.tableBody[index], index, validFields);
      tableBodyValidAux[index] = validRow;
      tableBodyValidFieldsAux[index] = rowValidFields;
    }
    pageHandler.setTableBodyValid(tableBodyValidAux);
    pageHandler.setTableBodyValidFields(tableBodyValidFieldsAux);
  }

  const saveItem = async(itemData, index) => {
    try {
      if (pageHandler.tableBodyValid[index] && !pageHandler.tableBodyCreated[index]) {
        const clienteObject = pageHandler.item.getObjectFromImport(itemData, pageHandler.selectValues, pageHandler.selectOptions);
        await createClienteRequest(clienteObject, controller.usuario.cuenta.get);
        const tableBodyCreatedAux = [...pageHandler.tableBodyCreated];
        tableBodyCreatedAux[index] = true;
        pageHandler.setTableBodyCreated(tableBodyCreatedAux);
        return true;
      }
      return false;
    } catch(error) {
      console.log(error);
      return false;
    }
  }

  if (controller.usuario.cuenta.get) {
    return (
      <Container className="full-container">
        {pageHandler.getAlert()}
        {pageHandler.getInfoModal("cliente", "clientes", <>
          <p><strong>Nombre (nombre):</strong> nombre completo o raz&oacute;n social del cliente. Texto de hasta 100 caracteres. Es obligatorio.</p>
          <p><strong>Nombre Comercial (comercial):</strong> nombre comercial del cliente en caso de tener uno. Texto de hasta 80 caracteres. No es obligatorio.</p>
          <p><strong>Correo Electr&oacute;nico (correo):</strong> correo electr&oacute;nico del cliente al cual recibir los comprobantes electr&oacute;nicos. Texto de hasta 160 caracteres. Es obligatorio.</p>
          <p><strong>Tipo de Identificaci&oacute;n (tipo):</strong> tipo de identificaci&oacute;n del cliente (Utilizar el c&oacute;digo de 2 d&iacute;gitos, por ejemplo 01 para Cédula Física). Opciones: 01 - Cédula Física, 02 - Cédula Jurídica, 03 - DIMEX, 04 - NITE. Es obligatorio.</p>
          <p><strong>N&uacute;mero de Identificaci&oacute;n (identificacion):</strong> n&uacute;mero de identificaci&oacute;n del cliente. La Cédula física debe de contener 9 dígitos, sin cero al inicio y sin guiones. La cédula de personas Jurídicas debe contener 10 dígitos y sin guiones. El Documento de Identificación Migratorio para Extranjeros (DIMEX) debe contener 11 o 12 dígitos, sin ceros al inicio y sin guiones. El Documento de Identificación de la DGT (NITE) debe contener 10 dígitos y sin guiones. Es obligatorio.</p>
          <p><strong>Identificaci&oacute;n Extranjero (extranjero):</strong> en caso de que el extranjero no cuente con uno de los 4 tipos de identificaci&oacute;n aceptados puede agregar la identificaci&oacute;n del pa&iacute;s extranjero. Opciones: producto, servicio. Texto de hasta 20 caracteres. No es obligatorio.</p>
          <p><strong>C&oacute;digo de Pa&iacute;s (pais):</strong> c&oacute;digo telef&oacute;nico del pa&iacute;s, por defecto se utilizara el de Costa Rica. No es obligatorio.</p>
          <p><strong>N&uacute;mero de Tel&eacute;fono (telefono):</strong> n&uacute;mero telef&oacute;nico del cliente. No es obligatorio.</p>
          <p><strong>Provincia (provincia):</strong> provincia en la que reside el cliente. Es obligatorio.</p>
          <p><strong>Cant&oacute;n (canton):</strong> cant&oacute;n en el que reside el cliente. Es obligatorio.</p>
          <p><strong>Distrito (distrito):</strong> distrito en el que reside el cliente. Es obligatorio.</p>
          <p><strong>Barrio (barrio):</strong> barrio en el que reside el cliente. No es obligatorio.</p>
          <p><strong>Direcci&oacute;n Exacta (direccion):</strong> direcci&oacute;n lo m&aacute;s exacta posible. Es obligatorio.</p>
        </>)}
        {pageHandler.getEditModal("Cliente")}
        {pageHandler.getFileForm()}
        {pageHandler.getTable(saveItem, validateRow, validateTable)}
      </Container>
    );
  }
  return (
    <Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>
  );
}