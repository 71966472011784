import {StrictMode} from "react";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {CustomRouter} from "./router";
import {register} from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "./styles";

render(
  <StrictMode>
    <BrowserRouter>
      <CustomRouter/>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
register();