import {useState, useEffect} from "react";
import XMLViewer from 'react-xml-viewer';
import {useAceptarFactura as useItem, usePageHandlerReduced} from "../customHooks";
import {Container, Row, Col, Form, CustomAlert, Button, Modal, Card, CustomSelectField, CustomTextField} from "../components";
import {
  parseXml, listCuentaCodigosActividadRequest, aceptarFacturaRequest, getRespuestaRequest, getRespuestaClaveRequest
} from "../helpers";

const statusColor = ["secondary", "warning", "success", "primary", "danger"];

const getStatusColor = (statusItem) => statusColor[statusItem];

export const AceptarFacturaPage = ({controller}) => {
  const [respuesta, setRespuesta] = useState({});
  const [respuestas, setRespuestas] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [status, setStatus] = useState([]);
  const [errors, setErrors] = useState([]);
  const [xmlData, setXmlData] = useState("");
  const pageHandler = usePageHandlerReduced(useItem);
  const cuenta = controller.usuario.cuenta.get;
  const cuentaData = controller.usuario.cuenta.data;
  const sucursal = controller.usuario.sucursal.get;
  const sucursales = controller.usuario.sucursales.get;

  useEffect(() => {
    if (cuenta) {
      const loadData = async() => {
        try {
          const codigos = await listCuentaCodigosActividadRequest(cuenta);
          if (pageHandler.isMounted) {
            setRespuesta({});
            setRespuestas([]);
            setFacturas([]);
            setStatus([]);
            setErrors([]);
            setXmlData("");
            pageHandler.item.setCodigos(codigos);
          }
        } catch(error) {
          console.log(error);
        }
      }
      loadData();
    }
  }, [cuenta]);

  const checkLength = (params, index) => {
    if (index < params.files.length) {
      readFile(params, index);
    } else {
      setStatus(params.arrayFacturas.map(item => item.error ? 4 : 0));
      setRespuestas(params.arrayFacturas.map(_ => 0));
      setErrors(params.arrayFacturas.map(_ => ""));
      setFacturas(params.arrayFacturas);
    }
  }

  const readFile = (params, index) => {
    if (params.files[index].type === "text/xml" || params.files[index].name.endsWith(".xml")) {
      const fileReader = new FileReader();
      fileReader.onload = function() {
        const xmlDoc = params.parser.parseFromString(fileReader.result, "text/xml");
        const factura = parseXml(xmlDoc);
        if (factura) {
          if (factura.MensajeHacienda) {
            params.arrayFacturas.push({error: "Este es el archivo XML de respuesta, por favor utilizar el archivo XML de la factura.", nombre: params.files[index].name});
          } else {
            params.arrayFacturas.push(pageHandler.item.parse(Object.values(factura)[0], xmlDoc.documentElement?.outerHTML));
          }
        }
        checkLength(params, index+1);
      }
      fileReader.readAsText(params.files[index]);
    } else {
      checkLength(params, index+1);
    }
  }

  const handleUploadFiles = (files) => {
    if (files && files.length) {
      const parser = new DOMParser(), arrayFacturas = [];
      readFile({parser, files, arrayFacturas}, 0);
    }
  }

  const setUpdateItem = (item, index) => pageHandler.item.setObject(item, index);

  const setItemStatus = (index, itemStatus) => {
    const statusAux = [...status];
    statusAux[index] = itemStatus;
    setStatus(statusAux);
  }

  const setItemRespuesta = (index, respuestaId) => {
    const respuestasAux = [...respuestas];
    respuestasAux[index] = respuestaId;
    setRespuestas(respuestasAux);
  }

  const setItemError = (index, error) => {
    const errorsAux = [...errors];
    errorsAux[index] = error;
    setErrors(errorsAux);
  }

  const updateItem = () => {
    const index = pageHandler.item.index;
    const facturasAux = JSON.parse(JSON.stringify(facturas));
    facturasAux[index] = {
      ...facturasAux[index],
      ...pageHandler.item.getObject
    };
    setFacturas(facturasAux);
    setItemStatus(index, 0);
    pageHandler.item.reset();
  }

  const validateItem = async(item, index, persist = true) => {
    try {
      if (persist) {
        pageHandler.setIsLoading(true);
      }
      let itemStatus = 2, respuestaId;
      const respuestaClave = await getRespuestaClaveRequest(item.clave);
      if (String(item.numeroCedulaReceptor) !== String(cuentaData?.identificacion?.numero)) {
        itemStatus = 4;
      } else if (respuestaClave ) {
        itemStatus = 5;
        respuestaId = respuestaClave.id || 0;
        if (persist) {
          setItemRespuesta(index, respuestaId);
        }
      }
      if (persist) {
        setItemStatus(index, itemStatus);
        pageHandler.setIsLoading(false);
      }
      return [itemStatus, respuestaId];
    } catch(error) {
      console.log(error);
      if (persist) {
        setItemError(index, error);
        setItemStatus(index, 1);
        pageHandler.setIsLoading(false);
      }
      return [1, 0];
    }
  }  

  const validateAll = async() => {
    pageHandler.setIsLoading(true);
    const statusAux = [...status]; 
    const respuestasAux = [...respuestas];
    for (let i = 0; i < facturas.length; i++) {
      if (status[i] < 2) {
        const [facturaStatus, facturaId] = await validateItem(facturas[i], i, false);
        statusAux[i] = facturaStatus;
        respuestasAux[i] = facturaId;
      }
    }
    setStatus(statusAux);
    setRespuestas(respuestasAux);
    pageHandler.setIsLoading(false);
  }

  const saveItem = async(item, index, persist = true) => {
    try {
      if (persist) {
        pageHandler.setIsLoading(true);
      }
      const mensaje = pageHandler.item.getMensaje(item);
      const response = await aceptarFacturaRequest(cuenta, sucursal, mensaje);
      if (persist) {
        setItemStatus(index, response.id ? 3 : 1);
        setItemRespuesta(index, response.id || 0);
        pageHandler.setIsLoading(false);
      }
      return [!!response.id, response.id || 0];
    } catch(error) {
      console.log(error);
      setItemError(index, error);
      if (persist) {
        setItemStatus(index, 1);
        pageHandler.setIsLoading(false);
      }
      return [false, 0];
    }
  }

  const saveAll = async() => {
    pageHandler.setIsLoading(true);
    const statusAux = [...status]; 
    const respuestasAux = [...respuestas];
    for (let i = 0; i < facturas.length; i++) {
      if (status[i] === 2) {
        const [valid, facturaId] = await saveItem(facturas[i], i, false);
        statusAux[i] = valid ? 3 : 1;
        respuestasAux[i] = facturaId;
      }
    }
    setStatus(statusAux);
    setRespuestas(respuestasAux);
    pageHandler.setIsLoading(false);
  }

  const showRespuesta = async(id) => {
    try {
      const response = await getRespuestaRequest(cuenta, sucursal, id);
      setRespuesta(pageHandler.item.parseRespuesta(response, sucursales));
    } catch(error) {
      console.log(error);
    }
  }

  const showXml = (xml) => setXmlData(xml);

  const lgSize = controller.session.width > 1175 ? 6 : 12;
  const xlSize = controller.session.width > 1763 ? 4 : 6;

  if (sucursal) {
    return (
      <Container className="full-container">
        <Modal size="xl" centered show={pageHandler.openModal} onHide={() => pageHandler.setOpenModal(false)} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Aceptar Facturas en Hacienda</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Formatos aceptados</h4>
            <p>Se deben utilizar los archivos XML ( archivos con extensi&oacute;n .xml) correspondiente a la factura o facturas que se quieran aceptar, verificar que se est&aacute; seleccionando el archivo correspondiente a la factura y no el archivo correspondiente al mensaje de Hacienda, ya que los dos archivos son de tipo XML.</p>
            <h4>Cantidad de facturas</h4>
            <p>Se pueden aceptar una o m&aacute;s facturas al mismo tiempo, utilice el bot&oacute;n <strong>Cargar Archivos XML</strong> para seleccionar todas las facturas que se deseen aceptar.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.setOpenModal(false)}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="lg" centered show={pageHandler.item.mensaje.value !== ""} onHide={() => pageHandler.item.reset()} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Editar Factura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomSelectField id="itemCodigoActividad" label="C&oacute;digo de actividad" input={pageHandler.item.codigoActividad} options={pageHandler.item.displayCodigosActividad} />
            <CustomSelectField id="itemMensaje" label="C&oacute;digo de mensaje" input={pageHandler.item.mensaje.select} options={pageHandler.item.mensaje.options} />
            <CustomTextField id="itemDetalleMensaje" label="Detalle de mensaje" input={pageHandler.item.detalleMensaje.input} />
            <CustomSelectField id="itemCondicionImpuesto" label="Condicion del impuesto" input={pageHandler.item.condicionImpuesto.select} options={pageHandler.item.condicionImpuesto.options} />
            <CustomTextField id="itemImpuestoAcreditar" label="Monto del impuesto a acreditar" input={pageHandler.item.montoTotalImpuestoAcreditar.input} />
            <CustomTextField id="itemGastoAplicable" label="Monto del gasto aplicable" input={pageHandler.item.montoTotalDeGastoAplicable.input} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.item.reset()}>Cerrar</Button>
            <Button onClick={updateItem}>Guardar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={xmlData !== ""} onHide={() => setXmlData("")} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Archivo XML</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <XMLViewer className="xml-viewer" xml={xmlData} theme={{overflowBreak: true}} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setXmlData("")}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="lg" centered show={!!respuesta.clave} onHide={() => setRespuesta({})} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Respuesta Enviada</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Estado de Env&iacute;o:</strong> {respuesta.statusDeEnvio}</p>
            <p><strong>Estado de Respuesta:</strong> {respuesta.statusDeRespuesta}</p>
            <p><strong>Estado de Factura:</strong> {respuesta.statusFactura}</p>
            <p><strong>Mensaje:</strong> {respuesta.mensaje}</p>
            <p><strong>Detalle de Mensaje:</strong> {respuesta.detalleMensaje}</p>
            <p><strong>Sucursal:</strong> {respuesta.sucursal}</p>
            <p><strong>C&oacute;digo de Actividad:</strong> {respuesta.codigoActividad}</p>
            <p><strong>Consecutivo de Receptor:</strong> {respuesta.numeroConsecutivoReceptor}</p>
            <p><strong>Clave de Factura:</strong> {respuesta.clave}</p>
            <p><strong>Emisi&oacute;n de Mensaje:</strong> {respuesta.fechaEmisionDoc}</p>
            <p><strong>Emisi&oacute;n de Factura:</strong> {respuesta.fechaEmisionFactura}</p>
            <p><strong>Tipo de Emisor:</strong> {respuesta.tipoIdentificacionEmisor}</p>
            <p><strong>Identificaci&oacute;n de Emisor:</strong> {respuesta.numeroCedulaEmisor}</p>
            <p><strong>Nombre de Emisor:</strong> {respuesta.nombreEmisor}</p>
            <p><strong>Tipo de Receptor:</strong> {respuesta.tipoIdentificacionReceptor}</p>
            <p><strong>Identificaci&oacute;n de Receptor:</strong> {respuesta.numeroCedulaReceptor}</p>
            <p><strong>Condici&oacute;n de Impuesto:</strong> {respuesta.condicionImpuesto}</p>
            <p><strong>Impuesto:</strong> {respuesta.montoTotalImpuesto}</p>
            <p><strong>Impuesto a Acreditar:</strong> {respuesta.montoTotalImpuestoAcreditar}</p>
            <p><strong>Gasto Aplicable:</strong> {respuesta.montoTotalDeGastoAplicable}</p>
            <p><strong>Total Factura:</strong> {respuesta.totalFactura}</p>
            <p><strong>L&iacute;neas:</strong></p>
            {respuesta.lineaDetalleAceptadas?.map((item, index) => (
              <p key={`linea${index}`}>
                <strong>Cabys:</strong> {item.codigo ?? "--"}, <strong>Detalle:</strong> {item.detalle ?? "--"},{" "}
                <strong>Cantidad:</strong> {item.cantidad ?? "--"}, <strong>Impuestos:</strong> {item.impuesto?.monto ?? "--"},{" "}
                <strong>Total:</strong> {item.montoTotalLinea ?? "--"}
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setRespuesta({})}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            <Form>
              <Form.File
                className="width-180" disabled={pageHandler.isLoading} id="cargarXmlInput"
                label="" data-browse="Cargar Archivos XML" multiple
                custom accept=".xml" onChange={(e) => handleUploadFiles(e.target.files)}
              />
              <Button className="ml-5 mt-10" disabled={pageHandler.isLoading} variant="info" onClick={() => pageHandler.setOpenModal(true)}>Ayuda</Button>
              {facturas.length > 0 && ([0, 1].includes(status.find(item => item < 2))) ? <Button className="ml-5 mt-10" disabled={pageHandler.isLoading} variant="info" onClick={validateAll}>Validar Todo</Button> : null}
              {facturas.length > 0 && status.find(item => item === 2) ? <Button className="ml-5 mt-10" disabled={pageHandler.isLoading} variant="success" onClick={saveAll}>Aceptar Todo</Button> : null}
            </Form>
          </Col>
        </Row>
        <Row>
          {
            facturas.map((item, index) => (
              <Col key={`aceptarFactura${index}`} className="p-10" xs={12} sm={12} md={12} lg={lgSize} xl={xlSize}>
                <Card className="height-full card-border-5" border={getStatusColor(status[index])}>
                  {
                    item.error ? (
                      <>
                        <Card.Header><strong>Nombre de Archivo:</strong> {item.nombre}</Card.Header>
                        <Card.Body>{item.error}</Card.Body>
                      </>
                    ) : (
                      <>
                        <Card.Header>
                          <p><strong>Emisor:</strong> {item.nombreEmisor}</p>
                          <p><strong>Identificaci&oacute;n:</strong> {item.numeroCedulaEmisor}</p>
                          <p><strong>Correo:</strong> {item.helper.correoEmisor}</p>
                          <p><strong>Clave:</strong> {item.clave}</p>
                          <p><strong>Consecutivo:</strong> {item.helper.consecutivo}</p>
                          <p><strong>Fecha:</strong> {item.helper.fecha}</p>
                        </Card.Header>
                        <Card.Body>
                          <p><strong>Receptor:</strong> {item.helper.nombreReceptor}</p>
                          <p><strong>Identificaci&oacute;n:</strong> {item.numeroCedulaReceptor}</p>
                          <p><strong>Actividad:</strong> {item.codigoActividad}</p>
                          <p><strong>Mensaje:</strong> {pageHandler.item.mensaje.getText(item.mensaje)}</p>
                          <p><strong>Detalle:</strong> {item.detalleMensaje}</p>
                          <p><strong>Condici&oacute;n:</strong> {pageHandler.item.condicionImpuesto.getText(item.condicionImpuesto)}</p>
                          <p><strong>Total Impuesto:</strong> {item.montoTotalImpuesto}</p>
                          <p><strong>Impuesto Acreditar:</strong> {item.montoTotalImpuestoAcreditar}</p>
                          <p><strong>Gasto Aplicable:</strong> {item.montoTotalDeGastoAplicable}</p>
                          <p><strong>Total Factura:</strong> {item.totalFactura}</p>
                        </Card.Body>
                        <Card.Footer>
                          <Button disabled={pageHandler.isLoading} onClick={() => showXml(item.jsonFacturaAceptada)}>Ver XML</Button>
                          {respuestas[index] > 0 ? <Button className="ml-5" disabled={pageHandler.isLoading} onClick={() => showRespuesta(respuestas[index])}>Ver Respuesta</Button> : null}
                          {status[index] < 3 ? <Button className="ml-5" disabled={pageHandler.isLoading} onClick={() => setUpdateItem(item, index)}>Editar</Button> : null}
                          {status[index] < 2 ? <Button className="ml-5" disabled={pageHandler.isLoading} variant="info" onClick={() => validateItem(item, index)}>Validar</Button> : null}
                          {status[index] === 2 ? <Button className="ml-5" disabled={pageHandler.isLoading} variant="success" onClick={() => saveItem(item, index)}>Aceptar</Button> : null}
                          {status[index] === 4 ? <span className="float-end">El receptor de la factura no corresponde a esta cuenta</span> : null}
                          {status[index] === 5 ? <span className="float-end">La factura ya fue aceptada</span> : null}
                          {errors[index] !== "" ? <span className="float-end">{errors[index]}</span> : null}
                        </Card.Footer>
                      </>
                    )
                  }
                </Card>
              </Col>
            ))
          }
        </Row>
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>);
}