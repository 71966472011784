import {useEffect} from "react";
import {useSucursal as useItem, usePageHandler} from "../customHooks";
import {
  createSucursalRequest as createRequest, updateSucursalRequest as updateRequest, listSucursalesRequest as listRequest, getUserSucursalesRequest,
  initForm
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert, CustomTextField,
  CustomSelectField, CustomPagination, CustomTableHeader
} from "../components";
const pageLabels = {
  itemCreate: "Crear sucursal", itemUpdate: "Modificar sucursal", itemCreated: "Sucursal creada",
  itemUpdated: "Sucursal modificada", totalItems: "Sucursales", genericError: "Ha ocurrido un error"
};

export const SucursalPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const cuenta = controller.usuario.cuenta.get;
  const limiteSucursales = controller.usuario.limite.sucursales;
  const collapse = controller.session.width < 1000;

  useEffect(() => {
    if (cuenta) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [cuenta]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          let sucursal = undefined, sucursales = undefined;
          if (pageHandler.modalAction === "create") {
            sucursal = await createRequest(pageHandler.item.getObject, cuenta);
            sucursales = await getUserSucursalesRequest(controller.usuario.id.get, cuenta);
          } else {
            sucursal = await updateRequest(pageHandler.item.getObject, pageHandler.item.id);
          }
          if (pageHandler.isMounted) {
            if (sucursales && sucursales.length) {
              controller.usuario.sucursales.set(sucursales);
            }
            if (controller.usuario.sucursal.data?.id === sucursal?.id) {
              controller.usuario.sucursal.setData(sucursal);
            }
            await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
            pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
          }
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  if (cuenta) {
    return (
      <Container className="full-container">
        <Modal size="xl" centered show={pageHandler.openModal} onHide={() => pageHandler.setOpenModal(false)} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : pageLabels.itemUpdate}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              <Row>
                <Col lg>
                  <CustomTextField required id="itemNumeroSucursal" label="N&uacute;mero de Sucursal" input={pageHandler.item.numeroSucursal.input} invalid="Este campo es requerido" />
                  <CustomTextField required id="itemNombre" label="Nombre" input={pageHandler.item.nombre.input} invalid="Este campo es requerido" />
                  <CustomSelectField id="itemCodigoTelefono" label="C&oacute;digo de Pa&iacute;s" input={pageHandler.item.telefono.codigoPais} options={pageHandler.item.telefono.codigosPais} />
                  <CustomTextField required id="itemNumeroTelefono" label="N&uacute;mero de Tel&eacute;fono" input={pageHandler.item.telefono.numTelefono} invalid="Este campo es requerido" />
                  <CustomTextField required id="itemCorreo" label="Correo Electr&oacute;nico" input={pageHandler.item.correoElectronico.input} invalid="Formato de correo incorrecto" />
                </Col>
                <Col lg>
                  <CustomSelectField id="itemProvincia" label="Provincia" input={pageHandler.item.ubicacion.provincia} options={pageHandler.item.ubicacion.provincias} />
                  <CustomSelectField id="itemCanton" label="Cant&oacute;n" input={pageHandler.item.ubicacion.canton} options={pageHandler.item.ubicacion.cantones} />
                  <CustomSelectField id="itemDistrito" label="Distrito" input={pageHandler.item.ubicacion.distrito} options={pageHandler.item.ubicacion.distritos} />
                  <CustomSelectField id="itemBarrio" label="Barrio" input={pageHandler.item.ubicacion.barrio} options={pageHandler.item.ubicacion.barrios} />
                  <CustomTextField required id="itemDireccion" label="Direcci&oacute;n Exacta" input={pageHandler.item.ubicacion.otrasSenas} invalid="Este campo es requerido" />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => pageHandler.setOpenModal(false)} disabled={pageHandler.isLoading}>Cerrar</Button>
              <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <CustomPagination
          pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems}
          showBadge={collapse} limite={limiteSucursales} disableButton={pageHandler.totalItems >= limiteSucursales}
        />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Nombre: {element.nombre}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>N&uacute;mero de Sucursal: {element.numeroSucursal}</ListGroup.Item>
                    <ListGroup.Item>Correo Electr&oacute;nico: {element.correoElectronico}</ListGroup.Item>
                    <ListGroup.Item>N&uacute;mero de Tel&eacute;fono: (&#43;{element.telefono.codigoPais}) {element.telefono.numTelefono}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item><Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button></ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  {pageHandler.isSuperAdmin ? <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" /> : null}
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="numeroSucursal" headerLabel="N&uacute;mero de Sucursal" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="nombre" headerLabel="Nombre" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="correoElectronico" headerLabel="Correo Electr&oacute;nico" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="telefono" headerLabel="N&uacute;mero de Tel&eacute;fono" />
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems} &nbsp;&nbsp; L&iacute;mite: {limiteSucursales}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      {pageHandler.isSuperAdmin ? <td>{element.id}</td> : null}
                      <td>{element.numeroSucursal}</td>
                      <td>{element.nombre}</td>
                      <td>{element.correoElectronico}</td>
                      <td>(&#43;{element.telefono.codigoPais}) {element.telefono.numTelefono}</td>
                      <td><Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button></td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} disableButton={pageHandler.totalItems >= limiteSucursales} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}