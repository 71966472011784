export const tarifasIVA = () => [
  {key: "1ti", text: "Tarifa general 13%", value: "08", tarifa: 13},
  {key: "2ti", text: "Tarifa 0% (Exento)", value: "01", tarifa: 0},
  {key: "3ti", text: "Tarifa reducida 1%", value: "02", tarifa: 1},
  {key: "4ti", text: "Tarifa reducida 2%", value: "03", tarifa: 2},
  {key: "5ti", text: "Tarifa reducida 4%", value: "04", tarifa: 4},
  {key: "6ti", text: "Transitorio 0%", value: "05", tarifa: 0},
  {key: "7ti", text: "Transitorio 4%", value: "06", tarifa: 4},
  {key: "8ti", text: "Transitorio 8%", value: "07", tarifa: 8}
];
