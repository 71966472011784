import {Form} from "./bootstrap";

export const CustomSelectField = ({id, label, multiple, input, options, ...htmlSizeProp}) => (
  <Form.Group controlId={id}>
    <Form.Label>{label}</Form.Label>
    <Form.Control as="select" custom multiple={multiple} {...input} {...htmlSizeProp}>
      {options()}
    </Form.Control>
  </Form.Group>
);
