import {useState} from "react";
import {useFormSelect} from "./index";
import {condicionesVenta, mediosPago, tiposComprobante} from "../constants";
import {roundNumber} from "../helpers";

const tiposDocumento = [
  {key: "1tc", text: "Facturas", value: "facturas"},
  {key: "2tc", text: "Tiquetes", value: "tiquetes"},
  {key: "3tc", text: "Notas de Cr\u00E9dito", value: "notasCredito"}
];

const tipoCorreo = {
  facturas: 'FacturaElectronica',
  notasCredito: 'NotaCreditoElectronica'
};

export const useHistorialVentas = () => {
  const tipoDocumento = useFormSelect("facturas", () => tiposDocumento);
  const [anular, setAnular] = useState({});
  const [detalle, setDetalle] = useState({});

  const getNotaCreditoObject = () => {
    const lineas = Array.isArray(anular.detalleServicio?.lineaDetalle) ? anular.detalleServicio.lineaDetalle : [anular.detalleServicio?.lineaDetalle];
    return {
      codigoActividad: anular.codigoActividad,
      fechaEmision: (new Date()).toISOString(),
      emisor: {
        nombre: anular.emisor.nombre,
        identificacion: anular.emisor.identificacion,
        nombreComercial: anular.emisor.nombreComercial,
        ubicacion: anular.emisor.ubicacion,
        correoElectronico: anular.emisor.correoElectronico,
        ...(anular.emisor.telefono?.numTelefono ? {
          telefono: {
            codigoPais: String(anular.emisor.telefono.codigoPais),
            numTelefono: String(anular.emisor.telefono.numTelefono)
          }
        } : {})
      },
      ...(anular.receptor ? {
        receptor: {
          nombre: anular.receptor.nombre,
          identificacion: anular.receptor.identificacion,
          ubicacion: anular.receptor.ubicacion,
          correoElectronico: anular.receptor.correoElectronico,
          ...(anular.receptor.nombreComercial ? {
            nombreComercial: anular.receptor.nombreComercial
          } : {}),
          ...(anular.receptor.identificacionExtranjero ? {
            identificacionExtranjero: anular.receptor.identificacionExtranjero
          } : {}),
          ...(anular.receptor.telefono?.numTelefono ? {
            telefono: {
              codigoPais: String(anular.receptor.telefono.codigoPais),
              numTelefono: String(anular.receptor.telefono.numTelefono)
            }
          } : {})
        },
      } : {}),
      condicionVenta: anular.condicionVenta,
      medioPago: anular.medioPago,
      detalleServicio: {
        lineaDetalle: lineas.map((linea) => ({
          productoId: linea.productoId,
          numeroLinea: linea.numeroLinea,
          codigo: linea.codigo,
          codigoComercial: linea.codigoComercial,
          cantidad: roundNumber(linea.cantidad, 3),
          unidadMedida: linea.unidadMedida,
          detalle: linea.detalle,
          precioUnitario: roundNumber(linea.precioUnitario),
          montoTotal: roundNumber(linea.montoTotal),
          subTotal: roundNumber(linea.subTotal),
          impuestoNeto: roundNumber(linea.impuestoNeto),
          montoTotalLinea: roundNumber(linea.montoTotalLinea),
          ...(linea.descuento[0] ? {
            descuento: [{
              montoDescuento: roundNumber(linea.descuento[0].montoDescuento),
              naturalezaDescuento: linea.descuento[0].naturalezaDescuento
            }]
          } : {}),
          ...(linea.impuesto[0] ? {
            impuesto: [{
              codigo: linea.impuesto[0].codigo,
              codigoTarifa: linea.impuesto[0].codigoTarifa,
              tarifa: roundNumber(linea.impuesto[0].tarifa, 2),
              monto: roundNumber(linea.impuesto[0].monto),
              ...(linea.impuesto[0].factorIVA ? {
                factorIVA: linea.impuesto[0].factorIVA
              } : {}),
              ...(linea.impuesto[0].exoneracion ? {
                exoneracion: linea.impuesto[0].exoneracion
              } : {})
            }]
          } : {}),
        }))
      },
      resumenFactura: {
        totalServGravados: roundNumber(anular.resumenFactura.totalServGravados),
        totalServExentos: roundNumber(anular.resumenFactura.totalServExentos),
        totalServExonerado: roundNumber(anular.resumenFactura.totalServExonerado),
        totalMercanciasGravadas: roundNumber(anular.resumenFactura.totalMercanciasGravadas),
        totalMercanciasExentas: roundNumber(anular.resumenFactura.totalMercanciasExentas),
        totalMercExonerada: roundNumber(anular.resumenFactura.totalMercExonerada),
        totalGravado: roundNumber(anular.resumenFactura.totalGravado),
        totalExento: roundNumber(anular.resumenFactura.totalExento),
        totalExonerado: roundNumber(anular.resumenFactura.totalExonerado),
        totalVenta: roundNumber(anular.resumenFactura.totalVenta),
        totalDescuentos: roundNumber(anular.resumenFactura.totalDescuentos),
        totalVentaNeta: roundNumber(anular.resumenFactura.totalVentaNeta),
        totalImpuesto: roundNumber(anular.resumenFactura.totalImpuesto),
        totalComprobante: roundNumber(anular.resumenFactura.totalComprobante)
      },
      otrosCargos : anular.otrosCargos,
      informacionReferencia : [{
        tipoDoc: anular.numeroConsecutivo.substring(8, 10),
        numero: anular.clave,
        fechaEmision: anular.fechaEmision,
        codigo: "01",
        razon: "Anula Documento de Referencia"
      }]
    }
  }

  const mapObject = (data) => {
    const lineas = data.detalleServicio?.lineaDetalle ?? [];
    return {
      ...data,
      lineas,
      tipoDocumento: tiposComprobante().find(item => item.value === data.numeroConsecutivo.substring(8, 10))?.text ?? "",
      condicion: condicionesVenta().find(element => element.value === data.condicionVenta)?.text ?? "",
      medios: data.medioPago.map(medio => mediosPago().find(element => element.value === medio)?.text)?.toString(),
      fecha: data.fechaEmision ? new Date(data.fechaEmision).toLocaleString('es-US') : "",
      isSet: true
    }
  };

  return {
    tipoCorreo: tipoCorreo[tipoDocumento.value],
    tipoDocumento,
    anular,
    detalle,
    setDetalle,
    setAnular,
    getNotaCreditoObject,
    mapObject
  };
}