import {useEffect} from "react";
import {Container, Card, Row, Col, Button} from "../components";
import {variants} from "../constants";

const variantsArray = variants();
const routes = [
  {title: "Ventas", route: "/venta", body: "Realizar ventas en una sucursal espec\u00EDfica."},
  {title: "Historial de Ventas", route: "/ventaHistorial", body: "Historial de ventas en una sucursal espec\u00EDfica (facturas, tiquetes y notas de cr\u00E9dito), anular comprobantes mediante notas de cr\u00E9dito."},
  {title: "Aceptar Facturas", route: "/aceptarFactura", body: "Aceptar, aceptar parcialmente o rechazar documentos electr\u00F3nicos."},
  {title: "Historial de Facturas Aceptadas", route: "/aceptarHistorial", body: "Historial de documentos electr\u00F3nicos aceptados, aceptados parcialmente o rechazados."},
  {title: "Reportes", route: "/reportes", body: "Generar reportes para una sucursal espec\u00EDfica."},
  {title: "Productos", route: "/producto", body: "Crear, modificar o eliminar productos en cada sucursal, buscar c\u00F3digo Cabys desde Hacienda."},
  {title: "Clientes", route: "/cliente", body: "Crear, modificar o eliminar clientes en cada cuenta, buscar informaci\u00F3n desde Hacienda por n\u00FAmero de identificación."},
  {title: "Usuarios", route: "/usuario", body: "Crear, modificar o eliminar usuarios en cada cuenta, control de acceso a las cuentas y sucursales."},
  {title: "C\u00F3digos de actividad", route: "/codigoActividad", body: "Crear, modificar o eliminar c\u00F3digos de actividad en cada cuenta, buscar c\u00F3digo desde Hacienda por n\u00FAmero de identificación."},
  {title: "Exoneraciones", route: "/exoneracion", body: "Crear, modificar o eliminar exoneraciones en cada cuenta y para cada cliente, buscar informaci\u00F3n desde Hacienda por n\u00FAmero de documento."},
  {title: "Consecutivos", route: "/consecutivo", body: "Consultar o editar los n\u00FAmeros de consecutivos para los diferentes tipos de documentos en cada sucursal."},
  {title: "Caja", route: "/caja", body: "Abrir, cerrar o consultar el estado de caja en cada sucursal."},
  {title: "Cuentas", route: "/cuenta", body: "Modificar informaci\u00F3n general de la cuenta, cargar llave criptogr\u00E1fica, cargar logo, buscar informaci\u00F3n desde Hacienda por n\u00FAmero de identificación."},
  {title: "Sucursales", route: "/sucursal", body: "Crear o modificar sucursales en cada cuenta."},
  {title: "Importar Productos", route: "/importarProductos", body: "Importar productos desde un archivo de Excel o CSV para una sucursal espec\u00EDfica, consultar como construir el archivo."},
  {title: "Importar Clientes", route: "/importarClientes", body: "Importar clientes desde un archivo de Excel o CSV para una sucursal espec\u00EDfica, consultar como construir el archivo."},
  {title: "Registro de Pagos", route: "/pago", body: "Registro de pagos del sistema"},
  {title: "Configuraci\u00F3n", route: "/configuracion", body: "Configuraciones de la cuenta"}
];

export const HomePage = ({controller: {session: {history}}}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="full-container">
      <Row>
        {
          routes.map((item, index) => (
            <Col key={`homeRoute${index}`} className="p-10" xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card className="height-full" border={variantsArray[index % 7]}>
                <Card.Header><Button onClick={() => history.push(item.route)} block variant={variantsArray[index % 7]}>{item.title}</Button></Card.Header>
                <Card.Body><Card.Text>{item.body}</Card.Text></Card.Body>
              </Card>
            </Col>
          ))
        }
      </Row>
    </Container>
  )
};