import {useState, useRef} from "react";
import {CustomOption} from "../components";
import {useFormInput, useFormSelect} from "./index";
import {usuarioRoles} from "../constants";
import {getUserSucursalesRequest} from "../helpers";

export const useUsuario = ({cuentasUsuario, usuarioId, role}) => {
  const [id, setId] = useState(0);
  const userName = useFormInput("");
  const password = useFormInput("");
  const correoElectronico = useFormInput("");
  const roles= useFormSelect("vendedor", () => usuarioRoles().filter(item => item.level <= usuarioRoles().find(itemRole => itemRole.value === role)?.level));
  const [cuentas, setCuentas] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [sucursalesUsuario, setSucursalesUsuario] = useState([]);
  const userNameOriginal = useRef("");
  const correoElectronicoOriginal = useRef("");
  const cuentasOcultas = useRef([]);
  const sucursalesOcultas = useRef([]);

  const handleCuentas = (e) => {
    const cuentasSelected = Array.from(e.target.selectedOptions, element => element.value);
    setCuentas(cuentasSelected);
    let sucursalesForCuentasSelected = [];
    const handleSelectCuentas = async() => {
      try {
        for (let i = 0; i < cuentasSelected.length; i++) {
          const sucursalesForCuentaSelected = await getUserSucursalesRequest(usuarioId, cuentasSelected[i]);
          sucursalesForCuentasSelected = sucursalesForCuentasSelected.concat(sucursalesForCuentaSelected);
        }
        setSucursalesUsuario(sucursalesForCuentasSelected);
      } catch(error) {
        if (error) {
          console.log(error);
        }
      }
    }
    handleSelectCuentas();
  }

  const handleSucursales = (e) => setSucursales(Array.from(e.target.selectedOptions, element => element.value));

  const cuentasOptions = () => cuentasUsuario.map(element => <CustomOption key={element.key} item={element} />)

  const sucursalesOptions = () => sucursalesUsuario.map(element => <CustomOption key={element.key} item={element} />)

  const reset = () => {
    setId(0);
    userName.setValue("");
    password.setValue("");
    correoElectronico.setValue("");
    roles.setValue("vendedor");
    setCuentas([]);
    setSucursales([]);
    setSucursalesUsuario([]);
    userNameOriginal.current = "";
    correoElectronicoOriginal.current = "";
    cuentasOcultas.current = [];
    sucursalesOcultas.current = [];
  }

  const setObject = async(data) => {
    try {
      const cuentasId = cuentasUsuario.map(element => element.value);
      cuentasOcultas.current = data.cuentas.filter(cuentaId => !cuentasId.includes(cuentaId));
      for (let i = 0; i < cuentasOcultas.current.length; i++) {
        const sucursalesForCuentasOcultas = await getUserSucursalesRequest(data.id, cuentasOcultas.current[i]);
        const sucursalesId = sucursalesForCuentasOcultas.map(element => element.value);
        sucursalesOcultas.current = sucursalesOcultas.current.concat(data.sucursales.filter(sucursalId => sucursalesId.includes(sucursalId)));
      }
      let sucursalesForCuentas = [];
      for (let i = 0; i < data.cuentas.length; i++) {
        if (cuentasId.includes(data.cuentas[i])) {
          const sucursalesForCuenta = await getUserSucursalesRequest(usuarioId, data.cuentas[i]);
          sucursalesForCuentas = sucursalesForCuentas.concat(sucursalesForCuenta);
        }
      }
      userName.setValue(String(data.userName || ""));
      password.setValue("");
      correoElectronico.setValue(data.correoElectronico || "");
      roles.setValue(data.roles && data.roles.length ? data.roles[0] : "vendedor");
      setCuentas(data.cuentas || []);
      setSucursales(data.sucursales || []);
      setSucursalesUsuario(sucursalesForCuentas);
      userNameOriginal.current = data.userName || "";
      correoElectronicoOriginal.current = data.correoElectronico || "";
    } catch(error) {
      throw error;
    }
  }

  const getObject = () => {
    const object = {
      userName: userName.value,
      correoElectronico: correoElectronico.value,
      roles: [roles.value],
      cuentas: cuentas.concat(cuentasOcultas.current),
      sucursales: sucursales.concat(sucursalesOcultas.current)
    };
    if (!!password.value) {
      object.password = password.value;
    }
    return object;
  }

  const userNameChanged = () => userName.value !== userNameOriginal.current;

  const correoElectronicoChanged = () => correoElectronico.value !== correoElectronicoOriginal.current;

  return {
    getObject: getObject(),
    cuentas : {
      select: {
        value: cuentas,
        onChange: handleCuentas
      },
      options: cuentasOptions
    },
    sucursales : {
      select: {
        value: sucursales,
        onChange: handleSucursales
      },
      options: sucursalesOptions
    },
    id,
    userName,
    password,
    correoElectronico,
    roles,
    setId,
    reset,
    setObject,
    userNameChanged,
    correoElectronicoChanged
  };
}
