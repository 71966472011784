export const condicionesVenta = () => [
  {key: "1cd", text: "Contado", value: "01"},
  {key: "2cd", text: "Crédito", value: "02"},
  {key: "3cd", text: "Consignación", value: "03"},
  {key: "4cd", text: "Apartado", value: "04"},
  {key: "5cd", text: "Arrendamiento con opción de compra", value: "05"},
  {key: "6cd", text: "Arrendamiento en función financiera", value: "06"},
  {key: "7cd", text: "Cobro a favor de un tercero", value: "07"},
  {key: "8cd", text: "Servicios prestados al Estado a crédito", value: "08"},
  {key: "9cd", text: "Pago de servicios prestado al Estado", value: "09"},
  {key: "10cd", text: "Otros", value: "99"}
];
