import {createHeaders, createParams, createBody, request} from "./api";

export const createCuentaRequest = async(cuenta) => {
  try {
    const response = await request("cuentas/create", {method: "POST", body: JSON.stringify(cuenta), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Datos incorrectos" : error;
    throw errorMessage;
  }
}

export const updateCuentaRequest = async(cuenta, id) => {
  try {
    const response = await request(`cuentas/${id}`, {method: "POST", body: JSON.stringify(cuenta), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Datos incorrectos" : error;
    throw errorMessage;
  }
}

export const getCuentaRequest = async(id) => {
  try {
    const response = await request(`cuentas/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getCuentaLimiteRequest = async(id) => {
  try {
    const response = await request(`cuentas/${id}/cuantas`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listCuentasRequest = async(queryParams) => {
  try {
    const response = await request(`cuentas/list${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const uploadLlaveRequest = async(file, cuentaId) => {
  try {
    await request(`cuentas/upload/${cuentaId}`, {method: "POST", body: createBody({file}), headers: await createHeaders(true)});
    return "Llave cargada correctamente";
  } catch(error) {
    throw error;
  }
}

export const probarLlaveRequest = async(cuentaId) => {
  try {
    await request(`cuentas/probarLLave/${cuentaId}`, {method: "POST", headers: await createHeaders(true)});
    return "Llave probada correctamente"
  } catch(error) {
    const errorMessage = error === "Status 418" ? "Llave o PIN incorrectos" : error;
    throw errorMessage;
  }
}

export const uploadLogoRequest = async(file, cuentaId) => {
  try {
    await request(`cuentas/cargar-imagen/${cuentaId}`, {method: "POST", body: createBody({file}), headers: await createHeaders(true)});
    return "Logo cargado correctamente";
  } catch(error) {
    throw error;
  }
}