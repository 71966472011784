import {createHeaders, request, createParams} from "./api";

export const createPagoRequest = async(pago) => {
  try {
    const response = await request("registroPagos", {method: "POST", body: JSON.stringify(pago), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getPagoRequest = async(id) => {
  try {
    const response = await request(`registroPagos/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listPagoRequest = async(cuentaId, queryParams) => {
  try {
    const response = await request(`registroPagos/cuenta/${cuentaId}${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}