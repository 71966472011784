import {useState, useEffect, useRef} from "react";
import {useFormInput} from "./index";

export const usePageHandler = (controller, useItem, itemArgs = null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showFormAlert, setShowFormAlert] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [formAlert, setFormAlert] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("id,desc");
  const [items, setItems] = useState([]);
  const item = useItem(itemArgs);
  const textoBusqueda = useFormInput("");
  const searchObject = useRef({});
  const isMounted = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => isMounted.current = false;
  }, []);

  const initProcess = (loading = false) => {
    if (loading) {
      setIsLoading(true);
    }
    setShowAlert(false);
    setShowFormAlert(false);
    setFormValidated(false);
    setAlert("");
    setAlertType("");
    setFormAlert("");
  }

  const handleErrors = (error, genericError = "", formError = false, elementIdToScroll = "") => {
    if (isMounted.current) {
      setIsLoading(false);
      if (error === "unauthorized") {
        controller.session.unauthorized.set(true);
      } else if (formError) {
        setFormAlert(String(error ? error : genericError));
        setShowFormAlert(true);
        document.getElementById(elementIdToScroll).scrollIntoView();
      } else {
        setAlertType("danger");
        setAlert(String(error ? error : genericError));
        setShowAlert(true);
      }
    }
  }

  const handleLoadItems = (response) => {
    if (isMounted.current) {
      setTotalItems(response.totalElements || 0);
      setTotalPages(response.totalPages || 0);
      setItems(response.content || []);
      setIsLoading(false);
    }
  }

  const handleAlert = (alertMessage, type = "primary") => {
    if (isMounted.current) {
      setAlertType(type);
      setAlert(String(alertMessage));
      setShowAlert(true);
      setIsLoading(false);
    }
  }

  const handleSaveItem = (alertMessage, type = "primary") => {
    if (isMounted.current) {
      item.reset();
      handleAlert(alertMessage, type);
      setOpenModal(false);
    }
  }

  const handleDeleteItem = (alertMessage, type = "warning") => {
    if (isMounted.current) {
      handleAlert(alertMessage, type);
      setModalAction("");
    }
  }

  const openCreateModal = () => {
    initProcess();
    if (modalAction !== "create") {
      item.reset();
    }
    setModalAction("create");
    setOpenModal(true);
  }

  const openUpdateModal = (data) => {
    initProcess();
    item.setId(data.id);
    item.setObject(data);
    setModalAction("update");
    setOpenModal(true);
  }

  const openDeleteModal = (id) => {
    initProcess();
    item.setId(id);
    setModalAction("delete");
  }

  const changePage = (page, loadProcess) => {
    if (page !== offSet && page >= 0 && page < totalPages && !isLoading) {
      setOffSet(page);
      loadProcess(page, limit, filter);
    }
  }

  const changeSize = (size, loadProcess) => {
    if (!isLoading) {
      setOffSet(0);
      setLimit(size);
      loadProcess(0, size, filter);
    }
  }

  const handleSortSymbol = (key) => filter === `${key},asc` ? "\u21c8" : filter === `${key},desc` ? "\u21ca" : "";

  const handleSortParam = (key) => filter === `${key},asc` ? `${key},desc` : filter === `${key},desc` ? "" : `${key},asc`;

  const changeSort = (sort, loadProcess) => {
    if (!isLoading) {
      const sortFilter = handleSortParam(sort);
      setOffSet(0);
      setFilter(sortFilter);
      loadProcess(0, limit, sortFilter);
    }
  }

  const preventEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  const setSearchObject = (object = {}) => searchObject.current = object;

  return {
    isSuperAdmin: controller.usuario.rol.get === "SUPER_ADMIN",
    isMounted: isMounted.current,
    searchObject: searchObject.current,
    isLoading,
    showAlert,
    showFormAlert,
    formValidated,
    openModal,
    modalAction,
    alert,
    alertType,
    formAlert,
    totalPages,
    totalItems,
    offSet,
    limit,
    filter,
    items,
    item,
    textoBusqueda,
    setSearchObject,
    setIsLoading,
    setShowAlert,
    setShowFormAlert,
    setFormValidated,
    setOpenModal,
    setModalAction,
    setAlert,
    setAlertType,
    setFormAlert,
    setOffSet,
    initProcess,
    handleErrors,
    handleLoadItems,
    handleAlert,
    handleSaveItem,
    handleDeleteItem,
    openCreateModal,
    openUpdateModal,
    openDeleteModal,
    changePage,
    changeSize,
    changeSort,
    handleSortSymbol,
    preventEnterKey
  };
}