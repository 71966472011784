import {useEffect} from "react";
import {Container, CustomAlert, Card, Row, Col, CustomSelectField, Button} from "../components";
import {useConfiguracion, usePageHandlerReduced} from "../customHooks";
import {updateCuentaRequest} from "../helpers";

export const ConfiguracionPage = ({controller}) => {
  const pageHandler = usePageHandlerReduced(useConfiguracion);
  const cuenta = controller.usuario.cuenta.get;
  const cuentaConfiguracion = controller.usuario.cuenta.data.features;

  useEffect(() => {
    if (cuentaConfiguracion) {
      pageHandler.item.setObject(cuentaConfiguracion);
    }
  }, [cuentaConfiguracion]);

  const save = () => {
    const save = async() => {
      try {
        pageHandler.setIsLoading(true);
        const response = await updateCuentaRequest(pageHandler.item.getObject(), cuenta);
        if (pageHandler.isMounted) {
          controller.usuario.cuenta.setData(response);
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        console.log(error);
        if (pageHandler.isMounted) {
          pageHandler.setIsLoading(false);
        }
      }
    }
    save();
  }

  if (cuenta) {
    return (
      <Container className="full-container">
        <Row className="pb-10">
          <Col xs={12}>
            <Button variant="primary" onClick={save} disabled={pageHandler.isLoading} >Guardar</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card>
              <Card.Body>
                <p>Impresi&oacute;n de comprobantes</p>
                <CustomSelectField id="printSize" label="Tama&#241;o de Impresi&oacute;n" input={pageHandler.item.printSize.select} options={pageHandler.item.printSize.options} />
                <CustomSelectField id="fontSize" label="Tama&#241;o de Letra" input={pageHandler.item.fontSize.select} options={pageHandler.item.fontSize.options} />
                <CustomSelectField id="logoSize" label="Tama&#241;o del Logo" input={pageHandler.item.logoSize.select} options={pageHandler.item.logoSize.options} />
                <CustomSelectField id="logoProporcion" label="Proporci&oacute;n del Logo" input={pageHandler.item.logoProporcion.select} options={pageHandler.item.logoProporcion.options} />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card>
              <Card.Body>
                <p>C&aacute;lculo de totales</p>
                <CustomSelectField id="tipoPrecio" label="Tipo de precio en productos / servicios" input={pageHandler.item.tipoPrecio.select} options={pageHandler.item.tipoPrecio.options} />
                <CustomSelectField id="tipoDescuento" label="Tipo de descuento en ventas" input={pageHandler.item.tipoDescuento.select} options={pageHandler.item.tipoDescuento.options} />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={4}>
            <Card>
              <Card.Body>
                <p>Formato de n&uacute;meros</p>
                <CustomSelectField id="cantidadDecimales" label="Cantidad de decimales" input={pageHandler.item.cantidadDecimales.select} options={pageHandler.item.cantidadDecimales.options} />
                <CustomSelectField id="numeroFormato" label="Formato de n&uacute;meros" input={pageHandler.item.numeroFormato.select} options={pageHandler.item.numeroFormato.options} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="pt-10">
          <Col xs={12}>
            <Button variant="primary" onClick={save} disabled={pageHandler.isLoading} >Guardar</Button>
          </Col>
        </Row>
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}