export const tiposExoneracion = () => [
  {key: "1te", text: "Compras autorizadas", value: "01"},
  {key: "2te", text: "Ventas exentas a diplomáticos", value: "02"},
  {key: "3te", text: "Autorizado por Ley especial", value: "03"},
  {key: "4te", text: "Exenciones Dirección General de Hacienda", value: "04"},
  {key: "5te", text: "Transitorio V", value: "05"},
  {key: "6te", text: "Transitorio IX", value: "06"},
  {key: "7te", text: "Transitorio XVII", value: "07"},
  {key: "8te", text: "Otros", value: "99"}
];
