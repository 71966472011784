import {Form, InputGroup} from "./bootstrap";

export const CustomTextField = ({id, label, placeholder, required, input, invalid = false, type = "text", onKeyDown = null, append = false, appendLabel, readOnly = false, disabled = false, className = ""}) => (
  <Form.Group className={className} controlId={id}>
    {label || appendLabel ? <Form.Label>{label} {appendLabel}</Form.Label> : null}
    <InputGroup>
      <Form.Control disabled={disabled} readOnly={readOnly} required={required} type={type} placeholder={placeholder || label} onKeyDown={onKeyDown} {...input}/>
      {append ? <InputGroup.Append>{append}</InputGroup.Append> : null}
      {invalid ? <Form.Control.Feedback type="invalid">{invalid}</Form.Control.Feedback> : null}
    </InputGroup>
  </Form.Group>
);