import {useState, useEffect} from "react";
import XMLViewer from 'react-xml-viewer';
import {useAceptarFactura as useItem, usePageHandler} from "../customHooks";
import {listRespuestaRequest as listRequest, localStringDate} from "../helpers";
import {Container, Button, Modal, Row, Table, Card, ListGroup, CustomAlert, CustomPagination, CustomTableHeader} from "../components";

const pageLabels = {totalItems: "Facturas Aceptadas", genericError: "Ha ocurrido un error"};

export const AceptarHistorialPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const [xmlData, setXmlData] = useState("");
  const [respuesta, setRespuesta] = useState({});
  const cuenta = controller.usuario.cuenta.get;
  const sucursal = controller.usuario.sucursal.get;
  const sucursales = controller.usuario.sucursales.get;
  const collapse = controller.session.width < 1000;

  useEffect(() => {
    if (sucursal) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [sucursal]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, sucursal, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const showRespuesta = (item) => setRespuesta(pageHandler.item.parseRespuesta(item, sucursales));

  const showXml = (xml) => setXmlData(xml);

  if (sucursal) {
    return (
      <Container className="full-container">
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <Modal size="lg" centered show={!!respuesta.clave} onHide={() => setRespuesta({})} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Respuesta Enviada</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Estado de Env&iacute;o:</strong> {respuesta.statusDeEnvio}</p>
            <p><strong>Estado de Respuesta:</strong> {respuesta.statusDeRespuesta}</p>
            <p><strong>Estado de Factura:</strong> {respuesta.statusFactura}</p>
            <p><strong>Mensaje:</strong> {respuesta.mensaje}</p>
            <p><strong>Detalle de Mensaje:</strong> {respuesta.detalleMensaje}</p>
            <p><strong>Sucursal:</strong> {respuesta.sucursal}</p>
            <p><strong>C&oacute;digo de Actividad:</strong> {respuesta.codigoActividad}</p>
            <p><strong>Consecutivo de Receptor:</strong> {respuesta.numeroConsecutivoReceptor}</p>
            <p><strong>Clave de Factura:</strong> {respuesta.clave}</p>
            <p><strong>Emisi&oacute;n de Mensaje:</strong> {respuesta.fechaEmisionDoc}</p>
            <p><strong>Emisi&oacute;n de Factura:</strong> {respuesta.fechaEmisionFactura}</p>
            <p><strong>Tipo de Emisor:</strong> {respuesta.tipoIdentificacionEmisor}</p>
            <p><strong>Identificaci&oacute;n de Emisor:</strong> {respuesta.numeroCedulaEmisor}</p>
            <p><strong>Nombre de Emisor:</strong> {respuesta.nombreEmisor}</p>
            <p><strong>Tipo de Receptor:</strong> {respuesta.tipoIdentificacionReceptor}</p>
            <p><strong>Identificaci&oacute;n de Receptor:</strong> {respuesta.numeroCedulaReceptor}</p>
            <p><strong>Condici&oacute;n de Impuesto:</strong> {respuesta.condicionImpuesto}</p>
            <p><strong>Impuesto:</strong> {respuesta.montoTotalImpuesto}</p>
            <p><strong>Impuesto a Acreditar:</strong> {respuesta.montoTotalImpuestoAcreditar}</p>
            <p><strong>Gasto Aplicable:</strong> {respuesta.montoTotalDeGastoAplicable}</p>
            <p><strong>Total Factura:</strong> {respuesta.totalFactura}</p>
            <p><strong>L&iacute;neas:</strong></p>
            {respuesta.lineaDetalleAceptadas?.map((item, index) => (
              <p key={`linea${index}`}>
                <strong>Cabys:</strong> {item.codigo ?? "--"}, <strong>Detalle:</strong> {item.detalle ?? "--"},{" "}
                <strong>Cantidad:</strong> {item.cantidad ?? "--"}, <strong>Impuestos:</strong> {item.impuestoNeto ?? item.impuesto?.[0]?.monto ?? "--"},{" "}
                <strong>Total:</strong> {item.montoTotalLinea ?? "--"}
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setRespuesta({})}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={xmlData !== ""} onHide={() => setXmlData("")} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Archivo XML</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <XMLViewer className="xml-viewer" xml={xmlData} theme={{overflowBreak: true}} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setXmlData("")}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} badgeLabel={pageLabels.totalItems} showBadge={collapse} showButton={false} />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Emisor: {String(element.nombreEmisor ?? "--")}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Fecha Factura: {localStringDate(element.fechaEmisionFactura)}</ListGroup.Item>
                    <ListGroup.Item>Fecha Aceptaci&oacute;n: {localStringDate(element.fechaEmisionDoc)}</ListGroup.Item>
                    <ListGroup.Item>Impuestos: {String(element.montoTotalImpuesto ?? "--")}</ListGroup.Item>
                    <ListGroup.Item>Total: {String(element.totalFactura ?? "--")}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button disabled={pageHandler.isLoading} onClick={() => showRespuesta(element)}>Respuesta</Button>
                      <Button className="ml-5" disabled={pageHandler.isLoading} onClick={() => showXml(element.jsonFacturaAceptada)}>XML</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  {pageHandler.isSuperAdmin ? <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" /> : null}
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="fechaEmisionFactura" headerLabel="Fecha Factura" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="fechaEmisionDoc" headerLabel="Fecha Aceptaci&oacute;n" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="nombreEmisor" headerLabel="Emisor" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="montoTotalImpuesto" headerLabel="Impuestos" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="totalFactura" headerLabel="Total" />
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      {pageHandler.isSuperAdmin ? <td>{element.id}</td> : null}
                      <td>{localStringDate(element.fechaEmisionFactura)}</td>
                      <td>{localStringDate(element.fechaEmisionDoc)}</td>
                      <td>{String(element.nombreEmisor ?? "--")}</td>
                      <td>{String(element.montoTotalImpuesto ?? "--")}</td>
                      <td>{String(element.totalFactura ?? "--")}</td>
                      <td>
                        <Button disabled={pageHandler.isLoading} onClick={() => showRespuesta(element)}>Respuesta</Button>
                        <Button className="ml-5" disabled={pageHandler.isLoading} onClick={() => showXml(element.jsonFacturaAceptada)}>XML</Button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} showButton={false} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>);
}