import {clean, insert, getUserSucursalesRequest} from "../helpers";
import {Row, Navbar, NavDropdown, Nav, Form, Button, CustomOption} from "../components";
import {CustomLink, CustomLinkDropdown} from "./index"

const dropdownArray = [
  {title: "Cuenta", options: [{name: "Cuentas", key: "cuenta"}, {name: "Sucursales", key: "sucursal"}, {name: "Usuarios", key: "usuario"}, {name: "Registro de Pagos", key: "pago"}, {name: "Configuraci\u00F3n", key: "configuracion"}]},
  {title: "Mantenimiento", options: [{name: "C\u00F3digos de actividad", key: "codigoActividad"}, {name: "Exoneraciones", key: "exoneracion"}, {name: "Clientes", key: "cliente"}, {name: "Productos", key: "producto"}]},
  {title: "Venta", options: [{name: "Consecutivos", key: "consecutivo"}, {name: "Caja", key: "caja"}, {name: "Ventas", key: "venta"}, {name: "Historial", key: "ventaHistorial"}]},
  {title: "Gastos", options: [{name: "Aceptar Facturas", key: "aceptarFactura"}, {name: "Historial", key: "aceptarHistorial"}]},
  {title: "Importar", options: [{name: "Productos", key: "importarProductos"}, {name: "Clientes", key: "importarClientes"}]},
  {title: "Reportes", options: [{name: "Reportes", key: "reportes"}]}
];

const actionClass = (color) => `navbar-select nav-btn-${color}`;

export const CustomNavbar = ({controller: {session, usuario}}) => {

  const handleLogout = () => {
    const logout = async() => {
      try {
        await clean();
        session.authenticated.set(false);
      } catch(error) {
        if (error) {
          console.log(error);
        }
      }
    }
    logout();
  }

  const handleRoute = (eventKey) => session.history.push(eventKey);

  const handleCuenta = (e) => {
    const cuentaId = e.target.value;
    const selectCuenta = async() => {
      try {
        insert("cuentaSelected", cuentaId);
        await usuario.cuenta.getCuentaData(cuentaId);
        usuario.cuenta.set(cuentaId);
        const sucursales = await getUserSucursalesRequest(usuario.id.get, cuentaId);
        usuario.sucursales.set(sucursales);
        const sucursalId = sucursales?.length === 1 ? sucursales[0].value : 0;
        if (sucursalId) {
          await usuario.sucursal.getSucursalData(sucursalId);
        } else {
          usuario.sucursal.setData({});
        }
        usuario.sucursal.set(sucursalId);
      } catch(error) {
        if (error) {
          console.log(error);
        }
      }
    }
    selectCuenta();
  }

  const handleSucursal = (e) => {
    const sucursalId = e.target.value;
    const selectSucursal = async() => {
      try {
        insert("sucursalSelected", sucursalId);
        await usuario.sucursal.getSucursalData(sucursalId);
        usuario.sucursal.set(sucursalId);
      } catch(error) {
        if (error) {
          console.log(error);
        }
      }
    }
    selectSucursal();
  }

  if (session.authenticated.get && !session.hideNavbar) {
    const sucursal = usuario.sucursal.get;
    const cuenta = usuario.cuenta.get;
    const itemClass = session.width > 991 ? " float-left" : "";
    const dropdownClass = `navbar-link ptb-2 ${session.width > 991 ? "float-left" : "plr-10"}`;
    const isActive = (page) => session.activePage.get === page;

    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="navbar-component" />
        <Navbar.Collapse id="navbar-component">
          <Nav className="mr-auto display-inline-block" onSelect={handleRoute}>
            <CustomLink active={isActive("/")} eventKey="/" name="Inicio" itemClass={itemClass} />
            {dropdownArray.map((item, index) =>
              <NavDropdown key={`dropdown-${index}`} id={`dropdown-${index}`} className={dropdownClass} title={item.title}>
                {item.options.map((option, optionIndex) => <CustomLinkDropdown key={`option-${index}-${optionIndex}`} active={isActive(`/${option.key}`)} eventKey={`/${option.key}`} name={option.name} />)}
              </NavDropdown>
            )}
          </Nav>
          <Nav>
            <Form inline>
              <Row noGutters>
                <Form.Control className={actionClass("white")} as="select" custom onChange={handleCuenta} value={cuenta}>
                  {cuenta ? null : <option value="0">Seleccionar Cuenta</option>}
                  {usuario.cuentas.get.map(element => <CustomOption key={element.key} item={element} />)}
                </Form.Control>
                <Form.Control className={actionClass("white")} as="select" custom onChange={handleSucursal} value={sucursal}>
                  {sucursal ? null : <option value="0">Seleccionar Sucursal</option>}
                  {usuario.sucursales.get.map(element => <CustomOption key={element.key} item={element} />)}
                </Form.Control>
                <Button className={actionClass("red")} variant="outline-info" onClick={handleLogout} >Cerrar sesi&oacute;n</Button>
              </Row>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
  return null;
}