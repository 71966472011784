import {useEffect} from "react";
import {usePago as useItem, usePageHandler} from "../customHooks";
import {
  listPagoRequest as listRequest, createPagoRequest as createRequest, updateCuentaRequest, initForm, localStringDate, getDateFromString, isDateString
} from "../helpers";
import {
  Container, Form, Row, Col, CustomDatePicker, CustomAlert, Modal, Button, CustomPagination, CustomTextField, CustomTableHeader, ListGroup, Card, Table
} from "../components";

const pageLabels = {
  itemCreate: "Agregar registro", itemCreated: "Registro agregado", totalItems: "Registros", genericError: "Ha ocurrido un error"
};

export const PagosPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const cuenta = controller.usuario.cuenta.get;
  const collapse = controller.session.width < 1000;
  const precio = controller.usuario?.cuenta?.data?.precio ?? 15;
  const ultimoMes = controller.usuario?.cuenta?.data?.ultimoMes ?? false;

  useEffect(() => {
    if (cuenta) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [cuenta]);

  useEffect(() => {
    pageHandler.item.setPrecio(precio);
  }, [precio]);

  useEffect(() => {
    let fecha = undefined;
    if (isDateString(ultimoMes)) {
      fecha = getDateFromString(ultimoMes, 1);
    }
    pageHandler.item.fechaInicio.setSelected(fecha);
  }, [ultimoMes]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          await createRequest({...pageHandler.item.getObject(), cuenta});
          const cuentaUpdate = JSON.parse(JSON.stringify(controller.usuario.cuenta.data));
          cuentaUpdate.ultimoMes = pageHandler.item.getUltimoMes();
          const cuentaUpdated = await updateCuentaRequest(cuentaUpdate, cuenta);
          controller.usuario.cuenta.setData(cuentaUpdated);
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
          pageHandler.handleSaveItem(pageLabels.itemCreated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  if (cuenta) {
    return (
      <Container className="full-container">
        <Modal size="xl" centered show={pageHandler.openModal} onHide={() => pageHandler.setOpenModal(false)} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageLabels.itemCreate}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              <Row>
                <Col lg>
                  <CustomDatePicker id="itemMesInicio" monthYear label="Mes a cancelar" placeholder="Mes inicial" disabled={!!ultimoMes && isDateString(ultimoMes)} handler={pageHandler.item.fechaInicio.datePicker} maxDate={pageHandler.item.fechaFinal.selected ? pageHandler.item.fechaFinal.selected : null} />
                  <CustomDatePicker id="itemMesFinal" monthYear label="Seleccionar para m&#250;ltiples meses" placeholder="Mes final" handler={pageHandler.item.fechaFinal.datePicker} minDate={pageHandler.item.fechaInicio.selected ? pageHandler.item.fechaInicio.selected : null} />
                  <CustomTextField id="itemCalculo" label="Monto calculado" readOnly input={{value: pageHandler.item.montoCalculado}} />
                </Col>
                <Col lg>
                <CustomTextField id="itemDetalle" label="Detalle" input={pageHandler.item.detalle.input} />
                <CustomTextField required id="itemReferencia" label="Referencia" input={pageHandler.item.referencia.input} invalid="Este campo es requerido" />
                <CustomTextField required id="itemMonto" label="Monto cancelado" input={pageHandler.item.monto.input} invalid="Este campo es requerido" />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => pageHandler.setOpenModal(false)} disabled={pageHandler.isLoading}>Cerrar</Button>
              <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <CustomPagination
          pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems}
          showBadge={collapse}
        />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Referencia: {element.numeroReferencia}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Agregado: {localStringDate(element.createdAt)}</ListGroup.Item>
                    <ListGroup.Item>Detalle: {element.detalles}</ListGroup.Item>
                    <ListGroup.Item>Monto: {element.montoCancelado}</ListGroup.Item>
                    <ListGroup.Item>Meses: {element.mesesCancelados}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="numeroReferencia" headerLabel="Referencia" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="createdAt" headerLabel="Agregado" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="detalles" headerLabel="Detalle" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="montoCancelado" headerLabel="Monto" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="mesesCancelados" headerLabel="Meses" />
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      <td>{element.numeroReferencia}</td>
                      <td>{localStringDate(element.createdAt)}</td>
                      <td>{element.detalles}</td>
                      <td>{element.montoCancelado}</td>
                      <td>{element.mesesCancelados}</td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}