import {set, get, del, clear, keys} from "idb-keyval";
const isSupported = () => !!window.indexedDB;

const query = async(queryFunction) => {
  try {
    return isSupported() ? await queryFunction : false;
  } catch(error) {
    throw error;
  }
}

export const insert = (key, value) => query(set(key, value));
export const select = (key) => query(get(key));
export const remove = (key) => query(del(key));
export const clean = () => query(clear());
export const getKeys = () => query(keys());