import {Row, Pagination, Form, Button, Badge} from "./bootstrap";

export const CustomPagination = ({pageHandler, loadItems, buttonLabel, badgeLabel, showBadge = false, limite = false, showButton = true, disableButton = false}) => (
  <Row className="m-0">
    {showBadge ? (<Badge className="float-left font-inherit p-10 mr-10 mb-10" variant="primary">{badgeLabel}: {pageHandler.totalItems}</Badge>) : null}
    {showBadge && limite ? (<Badge className="float-left font-inherit p-10 mr-10 mb-10" variant="primary">L&iacute;mite: {limite}</Badge>) : null}
    {showButton ? (<Button className="float-left mr-10 mb-10" variant="primary" disabled={pageHandler.isLoading || disableButton} onClick={pageHandler.openCreateModal}>{buttonLabel}</Button>) : null}
    <Pagination className="float-left mr-10 mb-10">
      <Pagination.First disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => pageHandler.changePage(0, loadItems) : null}/>
      <Pagination.Prev disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => pageHandler.changePage(pageHandler.offSet - 1, loadItems) : null}/>
      <Pagination.Item className="pagination-item">
        <Form.Control as="select" custom className="pagination-select" disabled={pageHandler.isLoading} value={pageHandler.offSet} onChange={(e) => pageHandler.changePage(Number(e.target.value), loadItems)}>
          {
            Array(pageHandler.totalPages).fill().map((_, index) => <option key={`option${index}`} value={index}>P&aacute;gina {index + 1}</option>)
          }
        </Form.Control>
      </Pagination.Item>
      <Pagination.Item className="pagination-item">
        <Form.Control as="select" custom className="pagination-select" disabled={pageHandler.isLoading} value={pageHandler.limit} onChange={(e) => pageHandler.changeSize(Number(e.target.value), loadItems)}>
          <option value={10}>10 Filas/P&aacute;gina</option>;
          <option value={20}>20 Filas/P&aacute;gina</option>;
          <option value={50}>50 Filas/P&aacute;gina</option>;
          <option value={100}>100 Filas/P&aacute;gina</option>;
        </Form.Control>
      </Pagination.Item>
      <Pagination.Next disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => pageHandler.changePage(pageHandler.offSet + 1, loadItems) : null}/>
      <Pagination.Last disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => pageHandler.changePage(pageHandler.totalPages - 1, loadItems) : null}/>
    </Pagination>
  </Row>
);
