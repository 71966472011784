import {Form, AsyncTypeahead} from "./bootstrap";

export const CustomSearchField = ({id, label, isLoading, key = "value", minLength = 1, onSearch, onKeyDown, onChange, options, placeholder, reference, appendLabel, useCache = false}) => (
  <Form.Group controlId={id}>
    <Form.Label>{label} {appendLabel}</Form.Label>
    <AsyncTypeahead
      filterBy={() => true} id={id} isLoading={isLoading} labelKey={key} minLength={minLength} onSearch={onSearch} onKeyDown={onKeyDown}
      onChange={onChange} options={options} placeholder={placeholder} renderMenuItemChildren={(option) => (option.value)} ref={reference}
      useCache={useCache}
    />
</Form.Group>
);