import {useState} from "react";
import {useFormInput} from "./index";
import {tiposIdentificacion, tiposCodigosActividad, estadosCodigosActividad} from "../constants";

export const useBuscarPersona = () => {
  const [haciendaSearch, setHaciendaSearch] = useState(false);
  const searchInput = useFormInput("");
  const [nombre, setNombre] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const [numeroIdentificacion, setNumeroIdentificacion] = useState("");
  const [regimen, setRegimen] = useState("");
  const [administracion, setAdministracion] = useState("");
  const [estado, setEstado] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [actividades, setActividades] = useState([]);

  const reset = () => {
    setNombre("");
    setTipoIdentificacion("");
    setNumeroIdentificacion("");
    setRegimen("");
    setAdministracion("");
    setEstado("");
    setCorreoElectronico("");
    setActividades([]);
  }

  const setObject = (data, correo) => {
    setNombre(data?.nombre || "");
    setTipoIdentificacion(data?.tipoIdentificacion || "");
    setNumeroIdentificacion(searchInput.value || "");
    setRegimen(data?.regimen?.descripcion || "");
    setAdministracion(data?.situacion?.administracionTributaria || "");
    setEstado(data?.situacion?.estado || "");
    setCorreoElectronico(correo || "");
    setActividades(data?.actividades?.map(item => ({...item, agregar: false})) || []);
  }

  const getTipoIdentificacion = () => tiposIdentificacion().find(tipo => tipo.value === tipoIdentificacion)?.text || "--"

  const getActividades = () => actividades.map(actividad => ({
    codigo: actividad.codigo, descripcion: actividad.descripcion, agregar: actividad.agregar,
    tipo: tiposCodigosActividad().find(tipo => tipo.value === actividad.tipo)?.text,
    estado: estadosCodigosActividad().find(estado => estado.value === actividad.estado)?.text
  }))

  const agregarActividad = (e) => {
    setActividades(actividades.map(actividad => ({...actividad, agregar: actividad.codigo === e.target.name ? e.target.checked : actividad.agregar})));
  }

  const cleanHaciendaSearch = (search = false) => {
    setTimeout(() => setHaciendaSearch(search));
    searchInput.setValue("");
    reset();
  }

  return {
    searchInput,
    haciendaSearch,
    nombre: nombre || "--",
    numeroIdentificacion: numeroIdentificacion || "--",
    regimen: regimen || "--",
    administracion: administracion || "--",
    estado: estado || "--",
    correoElectronico: correoElectronico || "--",
    actividades,
    tipoIdentificacion,
    setHaciendaSearch,
    reset,
    setObject,
    getTipoIdentificacion,
    getActividades,
    agregarActividad,
    cleanHaciendaSearch
  };
}