import {CustomQRcode} from "../components";
import {resolucionHacienda, urlPublico} from "../constants";
import {cutString, roundNumber, numberFormat} from "../helpers";

export const CustomFacturaPos = ({facturaRef, factura, fontSize, decimales, formato}) => {
  const getNumber = (number) => numberFormat(roundNumber(number ?? 0, decimales ?? 5), formato ?? "number");
  const styleClass = `width-pos ml-10 font-pos-${fontSize || "01"}`;

  return(
    <div className="display-none width-pos">
      <div className={styleClass} ref={facturaRef}>
        <p align="center" className="mt-10 mb-0">{factura.emisor.nombreComercial || factura.emisor.nombre}</p>
        <p align="center" className="m-0">Ced: {factura.emisor.identificacion.numero}</p>
        {
          factura.emisor.telefono
          ? <p align="center" className="m-0">Tel: {factura.emisor.telefono?.numTelefono || "--"}</p>
          : null
        }
        <p align="center" className="m-0">{factura.emisor.correoElectronico}</p>
        <p align="center" className="m-0">{factura.emisor.ubicacion?.otrasSenas || "--"}</p>
        <p align="center" className="mt-10 mb-0 font-bold">{factura.tipoDocumento}</p>
        <p align="center" className="mb-0"># {factura.numeroConsecutivo}</p>
        <p align="center" className="mb-0">Clave: {factura.clave.substring(0,22)} {factura.clave.substring(22)}</p>
        <p align="center" className="mb-10">Fecha: {factura.fecha}</p>
        {
          factura.otros?.otroTexto?.[0]
          ? <p align="center" className="mb-10">Observaciones: {factura.otros.otroTexto[0]}</p>
          : null
        }
        {
          factura.informacionReferencia?.length ? (
            factura.informacionReferencia.map((referencia, index) => (
              <div key={`referencia${index}`}>
                <p align="center" className="mb-0 font-bold">Documento de referencia</p>
                <p align="center" className="mb-0">Clave: {referencia.numero.substring(0,22)} {referencia.numero.substring(22)}</p>
                <p align="center" className="mb-0"># {referencia.numero.substring(21, 41)}</p>
                <p align="center" className="mb-10">Raz&oacute;n: {referencia.razon ?? ""}</p>
              </div>
            ))
          ) : null
        }
        {
          factura.receptor ? (
            <>
              <p align="center" className="m-0">Cliente: {factura.receptor.nombreComercial || factura.receptor.nombre}</p>
              <p align="center" className="m-0">Ced: {factura.receptor.identificacion.numero || factura.receptor.identificacionExtranjero}</p>
              <p align="center" className="mb-10">{factura.receptor.correoElectronico}</p>
            </>
          ) : null
        }
        <table className="full-size mb-10">
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Detalle</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          {
            factura.lineas.map((element, index) => (
              <tr key={`lineaFacturaImprimir${index}`}>
                <td>{numberFormat(Number(element.cantidad ?? 0), formato ?? "number")}</td>
                <td>{element.detalle.length > 45 ? `${cutString(element.detalle, 45)}...` : element.detalle}</td>
                <td>{getNumber(element.subTotal)}</td>
              </tr>
            ))
          }
        </tbody>
        </table>
        <p align="center" className="m-0">Subtotal: {getNumber(factura.resumenFactura.totalVenta)}</p>
        <p align="center" className="m-0">Descuento: {getNumber(factura.resumenFactura.totalDescuentos)}</p>
        <p align="center" className="m-0">Impuestos: {getNumber(factura.resumenFactura.totalImpuesto)}</p>
        <p align="center" className="mb-10 font-bold">Total: {getNumber(factura.resumenFactura.totalComprobante)}</p>
        <p align="center"><CustomQRcode url={urlPublico(factura.clave)} /></p>
        <p align="center" className="mb-10">{resolucionHacienda()}</p>
      </div>
    </div>
  );
};