import {isJson} from "./methods";
const api = "https://api.hacienda.go.cr";

const request = async(url, headers = {}) => {
  try {
    const response = await fetch(`${api}/${url}`, {method: "GET", headers, signal: AbortSignal.timeout(5000)});
    if (response.status === 200) {
      const data = await response.text();
      return isJson(data) ? JSON.parse(data) : data;
    }
    const error = `Status ${response.status}`;
    throw error;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarContribuyente = async(identificacion) => {
  try {
    const response = await request(`fe/ae?identificacion=${identificacion}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Identificaci\u00f3n no v\u00e1lida" : error === "Status 404" ? "Identificaci\u00f3n no registrada" : error;
    throw errorMessage;
  }
}

export const haciendaConsultarCambioDolar = async() => {
  try {
    const response = await request("indicadores/tc/dolar");
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarHistoricoDolar = async(desde, hasta) => {
  try {
    const response = await request(`indicadores/tc/dolar/historico?d=${desde}&h=${hasta}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarCambioEuro = async() => {
  try {
    const response = await request("indicadores/tc/euro");
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarCambio = async() => {
  try {
    const response = await request("indicadores/tc");
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarExoneracion = async(autorizacion) => {
  try {
    const response = await request(`fe/ex?autorizacion=${autorizacion}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Autorizaci\u00f3n no v\u00e1lida" : error === "Status 404" ? "Autorizaci\u00f3n no registrada" : error;
    throw errorMessage;
  }
}

export const haciendaConsultarAgropecuario = async(identificacion) => {
  try {
    const response = await request(`fe/agropecuario?identificacion=${identificacion}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarPesca = async(identificacion) => {
  try {
    const response = await request(`fe/pesca?identificacion=${identificacion}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const haciendaConsultarCabysCodigo = async(codigo) => {
  try {
    const response = await request(`fe/cabys?codigo=${codigo}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "C\u00f3digo no v\u00e1lido" : error;
    throw errorMessage;
  }
}

export const haciendaConsultarCabysDescripcion = async(descripcion) => {
  try {
    const response = await request(`fe/cabys?q=${descripcion}`);
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Descripci\u00f3n no v\u00e1lida" : error;
    throw errorMessage;
  }
}

export const haciendaConsultarCorreo = async(identificacion) => {
  try {
    const headers = new Headers();
    headers.append("access-token", "wsT66Kdx3dgOg66GSnuA");
    headers.append("access-user", "114020923");
    const response = await request(`fe/mifacturacorreo?identificacion=${identificacion}`, headers);
    if (typeof response === "object") {
      return response;
    }
    return {};
  } catch(error) {
    return {};
  }
}