import {useState} from "react";

export const useFormCheck = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => setValue(e.target.checked);

  return {
    check: {value, onChange, checked: value},
    value,
    setValue
  };
}
