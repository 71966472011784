import {CustomNavbar, CustomSwitch} from "./index";
import {useController} from "../customHooks";

export const CustomRouter = () => {
  const controller = useController();

  return (
    <>
      <CustomNavbar controller={controller}/>
      <CustomSwitch controller={controller}/>
    </>
  );
}