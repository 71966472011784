import {useState} from "react";
import {localStringDate} from "../helpers";

export const useCaja = () => {
  const [estadoCaja, setEstadoCaja] = useState("");
  const [abierta, setAbierta] = useState("--");
  const [cerrada, setCerrada] = useState("--");
  const [totalEfectivo, setTotalEfectivo] = useState(0);
  const [totalTarjeta, setTotalTarjeta] = useState(0);
  const [totalCheque, setTotalCheque] = useState(0);
  const [totalTransferencia, setTotalTransferencia] = useState(0);
  const [totalRecaudadoTerceros, setTotalRecaudadoTerceros] = useState(0);
  const [totalOtros, setTotalOtros] = useState(0);

  const reset = () => {
    setEstadoCaja("");
    setAbierta("--");
    setCerrada("--");
    setTotalEfectivo(0);
    setTotalTarjeta(0);
    setTotalCheque(0);
    setTotalTransferencia(0);
    setTotalRecaudadoTerceros(0);
    setTotalOtros(0);
  }

  const setObject = (data) => {
    setAbierta(localStringDate(data.abierta));
    setCerrada(localStringDate(data.cerrada));
    setTotalEfectivo(Number(data.totalEfectivo || 0));
    setTotalTarjeta(Number(data.totalTarjeta || 0));
    setTotalCheque(Number(data.totalCheque || 0));
    setTotalTransferencia(Number(data.totalTransferencia || 0));
    setTotalRecaudadoTerceros(Number(data.totalRecaudadoTerceros || 0));
    setTotalOtros(Number(data.totalOtros || 0));
  }

  const totales = () => Number(totalEfectivo + totalTarjeta + totalCheque + totalTransferencia + totalRecaudadoTerceros + totalOtros);

  return {
    estadoCaja,
    abierta,
    cerrada,
    totalEfectivo,
    totalTarjeta,
    totalCheque,
    totalTransferencia,
    totalRecaudadoTerceros,
    totalOtros,
    setEstadoCaja,
    reset,
    setObject,
    totales
  };
}
