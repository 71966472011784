import {createHeaders, createParams, request} from "./api";
import {insert} from "./storage";
import {sortAlphabetical} from ".";

export const getUserCuentasRequest = async(usuarioId) => {
  try {
    const response = await request(`users/cuentas/${usuarioId}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      let cuentas = sortAlphabetical(response, "name");
      cuentas = cuentas.map(cuenta => ({key: Number(cuenta.id), text: cuenta.name, value: Number(cuenta.id)}));
      await insert("usuarioCuentas", cuentas);
      await insert("cuentaSelected", cuentas && cuentas.length === 1 ? cuentas[0].value : 0);
      return cuentas;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getUserSucursalesRequest = async(usuarioId, cuentaId) => {
  try {
    const response = await request(`users/sucursales/${usuarioId}/${cuentaId}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      let sucursales = sortAlphabetical(response, "name");
      sucursales = sucursales.map(sucursal => ({key: Number(sucursal.id), text: sucursal.name, value: Number(sucursal.id), cuentaId: Number(sucursal.parent)}));
      await insert("usuarioSucursales", sucursales);
      await insert("cuentaSelected", cuentaId);
      await insert("sucursalSelected", sucursales && sucursales.length === 1 ? sucursales[0].value : 0);
      return sucursales;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getUsuarioCuentasRequest = async(id, queryParams) => {
  try {
    const response = await request(`users/${id}/cuentas${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listUsuariosRequest = async(cuentaId, queryParams) => {
  try {
    const response = await request(`users/list/${cuentaId}${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const validarUsernameRequest = async(username) => {
  try {
    await request(`users/username/${username}`, {method: "GET", headers: await createHeaders(true)});
    return "Nombre de usuario disponible";
  } catch(error) {
    const errorMessage = error === "Status 451" ? "Ya existe este nombre de usuario" : error;
    throw errorMessage;
  }
}

export const validarEmailRequest = async(email) => {
  try {
    await request(`users/validateCorreoElectronico/${email}`, {method: "GET", headers: await createHeaders(true)});
    return "Correo electr\u00f3nico disponible";
  } catch(error) {
    const errorMessage = error === "Status 451" ? "Ya existe este correo electr\u00f3nico" : error;
    throw errorMessage;
  }
}

export const createUsuarioRequest = async(usuario, cuentaId) => {
  try {
    const response = await request(`users/create/${cuentaId}`, {method: "POST", body: JSON.stringify(usuario), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const updateUsuarioRequest = async(usuario, id) => {
  try {
    const response = await request(`users/${id}`, {method: "POST", body: JSON.stringify(usuario), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const deleteUsuarioRequest = async(usuarioId, cuentaId) => {
  try {
    await request(`users/${usuarioId}/cuenta/${cuentaId}`, {method: "DELETE", headers: await createHeaders(true)});
    return "Usuario eliminado";
  } catch(error) {
    throw error;
  }
}

export const getUsuarioRequest = async(username) => {
  try {
    const response = await request(`users/${username}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}