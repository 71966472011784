import {createHeaders, request} from "./api";

export const updateRangoRequest = async(rango, id) => {
  try {
    const response = await request(`rangos/${id}`, {method: "POST", body: JSON.stringify(rango), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getRangoRequest = async(id) => {
  try {
    const response = await request(`rangos/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listRangoRequest = async(sucursalId) => {
  try {
    const response = await request(`rangos/sucursal/${sucursalId}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}