import {useEffect} from "react";
import {useProducto as useItem, usePageHandler} from "../customHooks";
import {
  createProductoRequest as createRequest, updateProductoRequest as updateRequest, deleteProductoRequest as deleteRequest,
  listProductoRequest as listRequest, haciendaConsultarCabysCodigo, haciendaConsultarCabysDescripcion, initForm
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert, CustomTextField,
  CustomSelectField, CustomPagination, CustomTableHeader, Accordion
} from "../components";
const pageLabels = {
  itemCreate: "Crear producto", itemUpdate: "Modificar producto", itemDelete: "Eliminar producto", itemCreated: "Producto creado",
  itemUpdated: "Producto modificado", itemDeleted: "Producto eliminado", totalItems: "Productos", genericError: "Ha ocurrido un error"
};

export const ProductoPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem, controller.usuario.sucursal.get);
  const collapse = controller.session.width < 1000;

  useEffect(() => {
    if (controller.usuario.sucursal.get) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [controller.usuario.sucursal.get]);

  const loadItems = async(page, size, sort, searchObject) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(controller.usuario.sucursal.get, {page, size, sort}, searchObject);
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          if (pageHandler.modalAction === "create") {
            await createRequest(pageHandler.item.getObject);
          } else {
            await updateRequest(pageHandler.item.getObject, pageHandler.item.id);
          }
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
          pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const handleDelete = () => {
    const remove = async() => {
      try {
        await deleteRequest(pageHandler.item.id);
        await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
        pageHandler.handleDeleteItem(pageLabels.itemDeleted);
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    remove();
  }

  const closeModal = () => {
    pageHandler.setOpenModal(false);
    pageHandler.item.cleanHaciendaSearch();
  }

  const handleSearch = () => {
    const search = async() => {
      try {
        pageHandler.initProcess(true);
        const searchResultCodigo = await haciendaConsultarCabysCodigo(pageHandler.item.searchInput.value);
        const searchResultDescripcion = await haciendaConsultarCabysDescripcion(pageHandler.item.searchInput.value);
        const searchResult = searchResultCodigo.concat(searchResultDescripcion.cabys)
        if (pageHandler.isMounted) {
          pageHandler.item.setCodigos(searchResult);
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
      } 
    }
    search();
  }

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const handleSeleccionar = (codigo) => {
    if (pageHandler.isMounted) {
      pageHandler.item.setBusqueda(codigo);
      pageHandler.item.cleanHaciendaSearch();
    }
  }

  const executeSearch = (searchObject = {}) => {
    pageHandler.setSearchObject(searchObject);
    pageHandler.setOffSet(0);
    loadItems(0, pageHandler.limit, pageHandler.filter, searchObject);
  }

  const handleBuscar = () => {
    if (pageHandler.isMounted) {
      const searchObject = {text: pageHandler.textoBusqueda.value};
      executeSearch(searchObject);
    }
  }

  const handleLimpiar= () => {
    if (pageHandler.isMounted) {
      pageHandler.textoBusqueda.setValue("");
      executeSearch();
    }
  }

  if (controller.usuario.sucursal.get) {
    return (
      <Container className="full-container">
        <Modal centered animation={false} show={pageHandler.modalAction === "delete"} onHide={() => pageHandler.setModalAction("")}>
          <Modal.Header closeButton><Modal.Title>{pageLabels.itemDelete}</Modal.Title></Modal.Header>
          <Modal.Body>Confirmar esta acci&oacute;n</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.setModalAction("")}>Cerrar</Button>
            <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={pageHandler.openModal} onHide={closeModal} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : pageLabels.itemUpdate}</Modal.Title>
              <Button className="ml-20" variant="primary" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.cleanHaciendaSearch(!pageHandler.item.haciendaSearch)}>
                {pageHandler.item.haciendaSearch ? "Regresar" : <>Buscar C&oacute;digo Cabys</>}
              </Button>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              {
                pageHandler.item.haciendaSearch ? (
                  <Row>
                    <Col lg>
                      <CustomTextField id="pageHandler.item.searchInput" label="Buscar por c&oacute;digo o descripci&oacute;n" input={pageHandler.item.searchInput.input} onKeyDown={handleSearchEnter}/>
                      {
                        pageHandler.item.codigos.length ? (
                          <>
                            {
                              collapse ? (
                                pageHandler.item.codigos.sort((a, b) => Number(a.codigo) - Number(b.codigo)).map((element) =>
                                  <Row className="m-5" key={element.codigo}>
                                    <Card border="secondary" className="full-size">
                                      <Card.Header className="font-bold">C&oacute;digo: {element.codigo}</Card.Header>
                                      <ListGroup variant="flush">
                                        <ListGroup.Item>Descripci&oacute;n: {element.descripcion}</ListGroup.Item>
                                        <ListGroup.Item>Impuesto: {element.impuesto}</ListGroup.Item>
                                        <ListGroup.Item><Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => handleSeleccionar(element) : null}>Seleccionar</Button></ListGroup.Item>
                                      </ListGroup>
                                    </Card>
                                  </Row>
                                )
                              ) : (
                                <Table className="mr-10 mt-8" striped bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th>C&oacute;digo</th>
                                      <th>Descripci&oacute;n</th>
                                      <th>Impuesto</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    pageHandler.item.codigos.sort((a, b) => Number(a.codigo) - Number(b.codigo)).map((element) =>
                                      <tr key={element.codigo}>
                                        <td>{element.codigo}</td>
                                        <td>{element.descripcion}</td>
                                        <td>{element.impuesto}&#37;</td>
                                        <td><Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? () => handleSeleccionar(element) : null}>Seleccionar</Button></td>
                                      </tr>
                                    )
                                  }
                                  </tbody>
                                </Table>
                              )
                            }
                          </>
                        ) : null
                      }
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col lg>
                      <CustomSelectField id="itemProducto" label="Producto o Servicio" input={pageHandler.item.isProducto.select} options={pageHandler.item.isProducto.options} />
                      <CustomTextField required id="itemDetalle" label="Detalle" placeholder="Nombre o descripci&oacute;n" input={pageHandler.item.detalle.input} invalid="Este campo es requerido" />
                      <CustomTextField required id="itemCodigo" label="C&oacute;digo Cabys" input={pageHandler.item.codigo.input} invalid="Este campo es requerido" />
                      <CustomTextField required id="itemCodigoInterno" label="C&oacute;digo Interno" placeholder="C&oacute;digo de Barras o interno" input={pageHandler.item.codigoComercial.input} invalid="Este campo es requerido" />
                      <CustomTextField required id="itemStock" label="Stock" input={pageHandler.item.stock.input} invalid="Este campo es requerido" />
                    </Col>
                    <Col lg>
                      <CustomTextField required id="itemPrecioMinorista" label="Precio Neto Minorista" placeholder="Precio sin impuestos" input={pageHandler.item.precioUnitarioMinorista.input} invalid="Este campo es requerido" />
                      <CustomTextField required id="itemPrecioMayorista" label="Precio Neto Mayorista" placeholder="Precio sin impuestos" input={pageHandler.item.precioUnitarioMayorista.input} invalid="Este campo es requerido" />
                      <CustomSelectField id="itemCodigoImpuesto" label="C&oacute;digo de Impuesto" input={pageHandler.item.impuestoCodigoImpuesto.select} options={pageHandler.item.impuestoCodigoImpuesto.options} />
                      {
                        pageHandler.item.impuestoCodigoImpuesto.value === "01" || pageHandler.item.impuestoCodigoImpuesto.value === "07"
                        ? <CustomSelectField id="itemCodigoTarifa" label="C&oacute;digo de Tarifa" input={pageHandler.item.impuestoCodigoTarifa.select} options={pageHandler.item.impuestoCodigoTarifa.options} />
                        : <CustomTextField required id="itemFactorImpuesto" label={pageHandler.item.impuestoCodigoImpuesto.value === "08" ? "Factor IVA" : "Tarifa del Impuesto"} input={pageHandler.item.impuestoFactorIVA.input} invalid="Este campo es requerido" />
                      }
                      <CustomSelectField id="itemUnidadMedida" label="Unidad de Medida" input={pageHandler.item.unidadMedida.select} options={pageHandler.item.unidadMedida.options} />
                    </Col>
                  </Row>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal} disabled={pageHandler.isLoading}>Cerrar</Button>
              {
                pageHandler.item.haciendaSearch
                ? <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSearch : null}>{pageHandler.isLoading ? "Buscando…" : "Buscar"}</Button>
                : <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
              }
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <Row>
          <Accordion className="full-size">
            <Accordion.Toggle as={Button} variant="link" eventKey="0">B&uacute;squeda de Productos</Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Col>
                <Row>
                  <Col xs={12} sm={7} md={6} lg={5} xl={4}>
                    <CustomTextField required id="searchText" placeholder="Texto de b&uacute;squeda" input={pageHandler.textoBusqueda.input} />
                  </Col>
                  <Col>
                    <Button variant="primary" className="mr-10 mb-10" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleBuscar : null}>Buscar</Button>
                    <Button variant="secondary" className="mb-10" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleLimpiar : null}>Limpiar</Button>
                  </Col>
                </Row>
              </Col>
            </Accordion.Collapse>
          </Accordion>
        </Row>
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems} showBadge={collapse} />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Detalle: {element.detalle}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Tipo: {element.isProducto ? "Producto" : "Servicio"}</ListGroup.Item>
                    <ListGroup.Item>C&oacute;digo Cabys: {element.codigo}</ListGroup.Item>
                    <ListGroup.Item>C&oacute;digo Interno: {element.codigoComercial}</ListGroup.Item>
                    <ListGroup.Item>Stock: {element.stock}</ListGroup.Item>
                    <ListGroup.Item>Precio Neto Minorista: {element.precioUnitarioMinorista}</ListGroup.Item>
                    <ListGroup.Item>Impuesto: {element.impuestoTarifa}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                      <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="isProducto" headerLabel="Tipo" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="detalle" headerLabel="Detalle" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="codigo" headerLabel="C&oacute;digo Cabys" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="codigoComercial" headerLabel="C&oacute;digo Interno" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="stock" headerLabel="Stock" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="precioUnitarioMinorista" headerLabel="Precio Neto Minorista" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="impuestoTarifa" headerLabel="Impuesto" />
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      <td>{element.isProducto ? "Producto" : "Servicio"}</td>
                      <td>{element.detalle}</td>
                      <td>{element.codigo}</td>
                      <td>{element.codigoComercial}</td>
                      <td>{element.stock}</td>
                      <td>{element.precioUnitarioMinorista}</td>
                      <td>{element.impuestoTarifa}</td>
                      <td>
                        <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                        <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} />
      </Container>
    );
  }
  return (
    <Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>
  );
}