import {useState} from "react";
import {useFormSelect, useFormInput, useDatePicker} from "./index";

const tipoArchivos = () => [
  {key: "1ta", value: "xlsx", ext: ".xlsx", text: "Excel (.xlsx)"},
  {key: "2ta", value: "biff8", ext: ".xls", text: "Excel 97-2003 (.xls)"},
  {key: "3ta", value: "csv", ext: ".csv", text: "CSV (.csv)"}
];

export const useReporte = (tipoReportes) => {
  const [showModal, setShowModal] = useState(false);
  const [download, setDownload] = useState(false);
  const nombreArchivo = useFormInput("");
  const tipoReporte = useFormSelect("consolidado", tipoReportes);
  const tipoArchivo = useFormSelect("xlsx", tipoArchivos);
  const fechaInicio = useDatePicker(undefined);
  const fechaFinal = useDatePicker(undefined);

  return {
    showModal,
    download,
    nombreArchivo,
    tipoReporte,
    tipoArchivo,
    fechaInicio,
    fechaFinal,
    setShowModal,
    setDownload
  };
}