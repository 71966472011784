import {createHeaders, createParams, request} from "./api";

export const createCodigoActividadRequest = async(codigo) => {
  try {
    const response = await request("codigoactividad", {method: "POST", body: JSON.stringify(codigo), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const updateCodigoActividadRequest = async(codigo, id) => {
  try {
    const response = await request(`codigoactividad/${id}/update`, {method: "POST", body: JSON.stringify(codigo), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const deleteCodigoActividadRequest = async(id) => {
  try {
    await request(`codigoactividad/${id}`, {method: "DELETE", headers: await createHeaders(true)});
    return "C\u00f3digo de actividad eliminado";
  } catch(error) {
    throw error;
  }
}

export const getCodigoActividadRequest = async(id) => {
  try {
    const response = await request(`codigoactividad/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listCodigosActividadRequest = async(cuentaId, queryParams) => {
  try {
    const response = await request(`codigoactividad/${cuentaId}/list${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listCuentaCodigosActividadRequest = async(cuentaId) => {
  try {
    const response = await request(`codigoactividad/${cuentaId}/listDetail`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}