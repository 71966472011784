import {useState} from "react";
import {addLeadingZero} from "../helpers";

export const useContribuyente = () => {
  const [nombre, setNombre] = useState("");
  const [tipo, setTipo] = useState("01");
  const [numero, setNumero] = useState("");
  // const [identificacionExtranjero, setIdentificacionExtranjero] = useState("");
  const [nombreComercial, setNombreComercial] = useState("");
  const [provincia, setProvincia] = useState("1");
  const [canton, setCanton] = useState("01");
  const [distrito, setDistrito] = useState("01");
  const [barrio, setBarrio] = useState("01");
  const [otrasSenas, setOtrasSenas] = useState("");
  // const [otrasSenasExtranjero, setOtrasSenasExtranjero] = useState("");
  const [codigoPais, setCodigoPais] = useState("506");
  const [numTelefono, setNumTelefono] = useState("");
  // const fax = useFax();
  const [correoElectronico, setCorreoElectronico] = useState("");

  const reset = () => {
    setNombre("");
    setTipo("01");
    setNumero("");
    setNombreComercial("");
    setProvincia("1");
    setCanton("01");
    setDistrito("01");
    setBarrio("01");
    setOtrasSenas("");
    setCodigoPais("506");
    setNumTelefono("");
    setCorreoElectronico("");
  }

  const setObject = (contribuyente, sucursal = {ubicacion: {}, telefono: {}}) => {
    setNombre(String(contribuyente.nombre || ""));
    setTipo(String(contribuyente.identificacion.tipo || "01"));
    setNumero(String(contribuyente.identificacion.numero || ""));
    setNombreComercial(String(contribuyente.nombreComercial || ""));
    setProvincia(String(sucursal.ubicacion.provincia || contribuyente.ubicacion.provincia || "1"));
    setCanton(addLeadingZero(String(sucursal.ubicacion.canton || contribuyente.ubicacion.canton || "01")));
    setDistrito(addLeadingZero(String(sucursal.ubicacion.distrito || contribuyente.ubicacion.distrito || "01")));
    setBarrio(addLeadingZero(String(sucursal.ubicacion.barrio || contribuyente.ubicacion.barrio || "01")));
    setOtrasSenas(String(sucursal.ubicacion.otrasSenas || contribuyente.ubicacion.otrasSenas || ""));
    setCodigoPais(String(sucursal.telefono.codigoPais || contribuyente.telefono?.codigoPais || "506"));
    setNumTelefono(String(sucursal.telefono.numTelefono || contribuyente.telefono?.numTelefono || ""));
    setCorreoElectronico(String(sucursal.correoElectronico || contribuyente.correoElectronico || ""));
  }

  return {
    getObject: {
      nombre,
      identificacion: {
        tipo,
        numero
      },
      nombreComercial,
      ubicacion: {
        provincia,
        canton,
        distrito,
        barrio,
        otrasSenas
      },
      ...numTelefono ? {
        telefono: {
          codigoPais,
          numTelefono
        }
      } : {},
      correoElectronico
    },
    nombre: nombreComercial || nombre,
    identificacion: numero,
    telefono: numTelefono ? `(${codigoPais}) ${numTelefono}` : false,
    direccion: otrasSenas,
    correoElectronico,
    isSet: numero !== "",
    reset,
    setObject
  };
}
