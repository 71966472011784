import {useFormInput, useFormCheck} from "./index";

export const useLogin= () => {
  const username = useFormInput("");
  const password = useFormInput("");
  const rememberMe = useFormCheck(true);

  return {
    username,
    password,
    rememberMe,
  };
}
