import {useState} from "react";
import {useFormInput, useIdentificacion, useTelefono, useUbicacion, useBuscarPersona} from "./index";
import {normalizeString} from "../helpers";

export const useCliente = () => {
  const buscarPersona = useBuscarPersona();
  const [id, setId] = useState(0);
  const nombre = useFormInput("");
  const nombreComercial = useFormInput("");
  const correoElectronico = useFormInput("");
  const identificacionExtranjero = useFormInput("");
  const identificacion = useIdentificacion();
  const telefono = useTelefono();
  const ubicacion = useUbicacion();

  const reset = () => {
    setId(0);
    nombre.setValue("");
    nombreComercial.setValue("");
    correoElectronico.setValue("");
    identificacionExtranjero.setValue("");
    identificacion.reset();
    telefono.reset();
    ubicacion.reset();
  }

  const setObject = (data) => {
    nombre.setValue(String(data.nombre || ""));
    nombreComercial.setValue(String(data.nombreComercial || ""));
    correoElectronico.setValue(String(data.correoElectronico || ""));
    identificacionExtranjero.setValue(String(data.identificacionExtranjero || ""));
    identificacion.setObject(data.identificacion || {});
    telefono.setObject(data.telefono || {});
    ubicacion.setObject(data.ubicacion || {});
  }

  const setBusqueda = () => {
    nombre.setValue(String(buscarPersona.nombre || nombre.value));
    identificacion.setNumero(String(buscarPersona.numeroIdentificacion || identificacion.numero.value));
    identificacion.setTipoIdentificacion(String(buscarPersona.tipoIdentificacion || identificacion.tipoIdentificacion.value));
    correoElectronico.setValue(String(buscarPersona.correoElectronico !== "--" ? buscarPersona.correoElectronico : correoElectronico.value));
  }

  const getObjectFromImport = (data, keys, options) => {
    const tipo = options.find(item => item.value === "tipoIdentificacion");
    const codigo = options.find(item => item.value === "codigoPais");
    const provincia = ubicacion.ubicaciones().find(item => normalizeString(item.text) === normalizeString(String(data[keys.findIndex(value => value === "provincia")])) || item.value === String(data[keys.findIndex(value => value === "provincia")]));
    const canton = provincia?.cantones.find(item => normalizeString(item.text) === normalizeString(String(data[keys.findIndex(value => value === "canton")])) || item.value === String(data[keys.findIndex(value => value === "canton")]));
    const distrito = canton?.distritos.find(item => normalizeString(item.text) === normalizeString(String(data[keys.findIndex(value => value === "distrito")])) || item.value === String(data[keys.findIndex(value => value === "distrito")]));
    const barrio = distrito?.barrios.find(item => normalizeString(item.text) === normalizeString(String(data[keys.findIndex(value => value === "barrio")])) || item.value === String(data[keys.findIndex(value => value === "barrio")]));
    return keys.reduce((object, key, index) => {
      if (!["numero", "tipoIdentificacion", "provincia", "canton", "distrito", "barrio", "direccion", "codigoPais", "numTelefono"].includes(key)) {
        const option = options.find(item => item.value === key);
        let value = option.general ? option.generalValue : data[index];
        value = option.equalTo ? (option.equalTo === value) : value;
        return { ...object, [key]: value}
      }
      return object;
    }, {
      identificacion: {
        tipo: tipo.general ? tipo.generalValue : data[keys.findIndex(value => value === "tipoIdentificacion")] ?? "",
        numero: data[keys.findIndex(value => value === "numero")] ?? ""
      },
      ubicacion: {
        provincia: provincia?.value ?? "",
        canton: canton?.value ?? "",
        distrito: distrito?.value ?? "",
        barrio: barrio?.value ?? "",
        otrasSenas: data[keys.findIndex(value => value === "direccion")] ?? ""
      },
      telefono: {
        codigoPais: codigo.general ? codigo.generalValue : data[keys.findIndex(value => value === "codigoPais")] ?? "506",
        numTelefono: data[keys.findIndex(value => value === "numTelefono")] ?? ""
      }
    });
  }

  return {
    getObject: {
      nombre: nombre.value,
      nombreComercial: nombreComercial.value,
      correoElectronico: correoElectronico.value,
      identificacionExtranjero: identificacionExtranjero.value,
      identificacion: identificacion.getObject,
      telefono: telefono.getObject,
      ubicacion: ubicacion.getObject
    },
    id,
    nombre,
    nombreComercial,
    correoElectronico,
    identificacionExtranjero,
    identificacion,
    telefono,
    ubicacion,
    buscarPersona,
    setId,
    reset,
    setObject,
    setBusqueda,
    getObjectFromImport
  };
}
