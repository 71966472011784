import {useState} from "react";
import {CustomOption} from "../components";

export const useFormSelect = (initialValue, selectOptions) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => setValue(["true", "false"].includes(e.target.value) ? (e.target.value === "true") : e.target.value);

  const options = () => selectOptions().map(element => <CustomOption key={element.key} item={element} />)

  const textValue = () => selectOptions().find(element => element.value === value).text;

  const objectSelected = () => selectOptions().find(element => element.value === value);

  const getText = (rawValue) => selectOptions().find(element => element.value === rawValue)?.text;

  return {
    select: {value, onChange},
    value,
    textValue,
    objectSelected,
    setValue,
    options,
    getText,
  };
}