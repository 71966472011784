export const tiposComprobante = () => [
  {key: "1tc", text: "Factura electrónica", value: "01"},
  {key: "2tc", text: "Nota de débito electrónica", value: "02"},
  {key: "3tc", text: "Nota de crédito electrónica", value: "03"},
  {key: "4tc", text: "Tiquete electrónico", value: "04"},
  {key: "5tc", text: "Confirmación de aceptación del comprobante electrónico", value: "05"},
  {key: "6tc", text: "Confirmación de aceptación parcial del comprobante electrónico", value: "06"},
  {key: "7tc", text: "Confirmación de rechazo del comprobante electrónico", value: "07"},
  {key: "8tc", text: "Factura electrónica de compras", value: "08"},
  {key: "9tc", text: "Factura electrónica de exportación", value: "09"}
];
