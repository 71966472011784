import {Alert} from "./bootstrap";

export const CustomAlert = ({type, show = false, onClose, dismissible = false, title, body}) => {
  if (show) {
    return (
      <Alert variant={type} show={show} onClose={() => onClose(false)} dismissible={dismissible}>
        {title ? (<Alert.Heading>{title}</Alert.Heading>) : null}
        {body ? (<span>{body}</span>) : null}
      </Alert>
    );
  }
  return null;
}
