import {useState, useEffect, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import {useHistorialVentas as useItem, usePageHandler, useConfiguracion} from "../customHooks";
import {
  listFacturaRequest, listTiqueteRequest, listNotaCreditoRequest, crearNotaCreditoRequest, reenviarEmail, localStringDate, setBoolean, getStringReducedFromDate
} from "../helpers";
import {
  Container, Button, Modal, Row, Col, Table, Card, ListGroup, CustomAlert, CustomPagination, CustomTableHeader, CustomSelectField, CustomFacturaPos,
  CustomFacturaCarta, CustomFacturaPosCarta, Accordion, CustomTextField
} from "../components";
import {condicionesVenta, mediosPago} from "../constants";

const pageLabels = {totalItems: "Documentos", genericError: "Ha ocurrido un error"};

const listRequest = {
  "facturas": listFacturaRequest,
  "tiquetes": listTiqueteRequest,
  "notasCredito": listNotaCreditoRequest
};

const isActive = (active = false) => active ? "Activa" : "Anulada"

const getCondicion = (condicion) => condicionesVenta().find(item => item.value === condicion)?.text;

const getMedioPago = (medio) => mediosPago().find(item => item.value === medio)?.text;

export const VentaHistorialPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const configuracion = useConfiguracion();
  const [comprobanteImprimir, setComprobanteImprimir] = useState({});
  const [nombreComprobante, setNombreComprobante] = useState("");
  const comprobanteRef = useRef();
  const handlePrint = useReactToPrint({content: () => comprobanteRef.current, documentTitle: nombreComprobante});
  const cuenta = controller.usuario.cuenta.get;
  const cuentaData = controller.usuario.cuenta.data;
  const logo = cuentaData.imagen ? `data:image/${cuentaData.imagenTipo ?? "png"};base64, ${cuentaData.imagen}` : false;
  const sucursal = controller.usuario.sucursal.get;
  const collapse = controller.session.width < 1000;
  const paginationClass = controller.session.width > 991 ? "pt-32" : "";
  const selectClass = controller.session.width > 604 ? "max-width-564" : "max-width-410";

  useEffect(() => {
    setNombreComprobante("");
    setComprobanteImprimir({});
    if (cuenta) {
      if (pageHandler.isMounted) {
        configuracion.setObject(cuentaData.features);
      }
    }
  }, [cuentaData]);

  useEffect(() => {
    if (sucursal) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [sucursal, pageHandler.item.tipoDocumento.value]); 

  useEffect(() => {
    if (comprobanteImprimir.isSet) {
      handlePrint();
    }
  }, [comprobanteImprimir]);

  const loadItems = async(page, size, sort, searchObject = {}) => {
    try {
      pageHandler.initProcess(true);
      if (!searchObject.startDate) {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 12);
        searchObject.startDate = getStringReducedFromDate(startDate);
      }
      if (!searchObject.endDate) {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        searchObject.endDate = getStringReducedFromDate(endDate);
      }
      const response = await listRequest[pageHandler.item.tipoDocumento.value](cuenta, sucursal, {page, size, sort}, searchObject);
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const anularComprobante = async() => {
    try {
      pageHandler.initProcess(true);
      const response = await crearNotaCreditoRequest(cuenta, sucursal, pageHandler.item.anular.id, pageHandler.item.getNotaCreditoObject());
      if (pageHandler.isMounted) {
        if (response.id) {
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
            pageHandler.handleAlert(`Comprobante ${pageHandler.item.anular.numeroConsecutivo} anulado mediante la nota de cr\u00E9dito ${response.numeroConsecutivo}`);
        }
        pageHandler.item.setAnular({});
        pageHandler.setIsLoading(false);
      }
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleEnviarCorreo = async(clave) => {
    try {
      pageHandler.initProcess(true);
      const response = await reenviarEmail(cuenta, clave, pageHandler.item.tipoCorreo);
      if (pageHandler.isMounted) {
        if (response) {
          pageHandler.handleAlert(`Correo enviado para comprobante ${clave}`);
        }
        pageHandler.setIsLoading(false);
      }
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleImprimir = (item) => {
    setNombreComprobante(item.numeroConsecutivo);
    setComprobanteImprimir(item);
  } 

  const getImpresion = () => {
    if (configuracion.values.printSize === "01") {
      return <CustomFacturaPos facturaRef={comprobanteRef} factura={comprobanteImprimir} fontSize={configuracion.values.fontSize} decimales={configuracion.values.cantidadDecimales} formato={configuracion.values.numeroFormato} />;
    } else if (configuracion.values.printSize === "02") {
      return <CustomFacturaCarta facturaRef={comprobanteRef} factura={comprobanteImprimir} logo={logo} logoLargo={configuracion.values.logoProporcion === "02"} logoSize={configuracion.values.logoSize} fontSize={configuracion.values.fontSize} decimales={configuracion.values.cantidadDecimales} formato={configuracion.values.numeroFormato} />;
    } else if (configuracion.values.printSize === "03") {
      return <CustomFacturaPosCarta facturaRef={comprobanteRef} factura={comprobanteImprimir} logo={logo} logoSize={configuracion.values.logoSize} fontSize={configuracion.values.fontSize} decimales={configuracion.values.cantidadDecimales} formato={configuracion.values.numeroFormato} />;
    }
  }

  const executeSearch = (searchObject = {}) => {
    pageHandler.setSearchObject(searchObject);
    pageHandler.setOffSet(0);
    loadItems(0, pageHandler.limit, pageHandler.filter, searchObject);
  }

  const handleBuscar = () => {
    if (pageHandler.isMounted) {
      const searchObject = {text: pageHandler.textoBusqueda.value};
      executeSearch(searchObject);
    }
  }

  const handleLimpiar= () => {
    if (pageHandler.isMounted) {
      pageHandler.textoBusqueda.setValue("");
      executeSearch();
    }
  }

  if (sucursal) {
    return (
      <Container className="full-container">
        {comprobanteImprimir.isSet ? getImpresion() : null}
        <Modal size="lg" centered show={pageHandler.item.anular.id > 0} onHide={() => pageHandler.item.setAnular({})} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Anular Documento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Est&aacute; seguro que desea anular este documento, esta acci&oacute;n no se puede revertir</p>
            <p><strong>Consecutivo:</strong> {pageHandler.item.anular.numeroConsecutivo}</p>
            <p><strong>Total Comprobante:</strong> {pageHandler.item.anular.resumenFactura?.totalComprobante}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.item.setAnular({})}>Cancelar</Button>
            <Button variant="danger" className="ml-5" disabled={pageHandler.isLoading} onClick={anularComprobante}>Anular</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="lg" centered show={pageHandler.item.detalle.id > 0} onHide={() => pageHandler.item.setDetalle({})} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Comprobante</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {pageHandler.item.detalle.informacionReferencia?.length ? (
              pageHandler.item.detalle.informacionReferencia.map(referencia => (
                <>
                  <p><strong>Documento de Referencia:</strong></p>
                  <p>&#160;&#160;<strong>Clave:</strong> {referencia.numero ?? ""}</p>
                  <p>&#160;&#160;<strong>Raz&oacute;n:</strong> {referencia.razon ?? ""}</p>
                </>
              ))
            ) : null}
            <p><strong>Estado:</strong> {isActive(setBoolean(pageHandler.item.detalle.activa))}</p>
            <p><strong>Estado de Env&iacute;o:</strong> {String(pageHandler.item.detalle.statusDeEnvio ?? "--")}</p>
            <p><strong>Estado de Respuesta:</strong> {String(pageHandler.item.detalle.statusDeRespuesta ?? "--")}</p>
            <p><strong>Estado de Comprobante:</strong> {String(pageHandler.item.detalle.statusFactura ?? "--")}</p>
            <p><strong>Fecha:</strong> {localStringDate(pageHandler.item.detalle.fechaEmision)}</p>
            <p><strong>Clave:</strong> {String(pageHandler.item.detalle.clave)}</p>
            <p><strong>Consecutivo:</strong> {String(pageHandler.item.detalle.numeroConsecutivo)}</p>
            {pageHandler.item.detalle.otros?.otroTexto?.[0] ? (
              <p><strong>Observaciones:</strong> {pageHandler.item.detalle.otros.otroTexto[0]}</p>
            ) : null}
            <p><strong>Actividad:</strong> {String(pageHandler.item.detalle.codigoActividad)}</p>
            <p><strong>Condici&oacute;n:</strong> {getCondicion(pageHandler.item.detalle.condicionVenta)}</p>
            <p><strong>Nombre de Receptor:</strong> {String(pageHandler.item.detalle.receptor?.nombre ?? "--")}</p>
            <p><strong>Identificaci&oacute;n de Receptor:</strong> {String(pageHandler.item.detalle.receptor?.identificacion.numero ?? "--")}</p>
            <p><strong>Medio de Pago:</strong>
            {pageHandler.item.detalle.medioPago?.map((item, index) => (
              <span key={`medioPago${index}`}>
                {" "}{getMedioPago(item)}
              </span>
            ))}
            </p>
            <p><strong>Total Gravado:</strong> {pageHandler.item.detalle.resumenFactura?.totalGravado ?? "--"}</p>
            <p><strong>Total Exonerado:</strong> {pageHandler.item.detalle.resumenFactura?.totalExonerado ?? "--"}</p>
            <p><strong>Total Exento:</strong> {pageHandler.item.detalle.resumenFactura?.totalExento ?? "--"}</p>
            <p><strong>Total Descuentos:</strong> {pageHandler.item.detalle.resumenFactura?.totalDescuentos ?? "--"}</p>
            <p><strong>Total Impuestos:</strong> {pageHandler.item.detalle.resumenFactura?.totalImpuesto ?? "--"}</p>
            <p><strong>Total Comprobante:</strong> {pageHandler.item.detalle.resumenFactura?.totalComprobante ?? "--"}</p>
            <p><strong>L&iacute;neas:</strong></p>
            {pageHandler.item.detalle.detalleServicio?.lineaDetalle.map((item, index) => (
              <p key={`linea${index}`}>
                <strong>Cabys:</strong> {item.codigo ?? "--"}, <strong>C&oacute;digo:</strong> {item.codigoComercial?.[0]?.codigo ?? "--"},{" "}
                <strong>Detalle:</strong> {item.detalle ?? "--"}, <strong>Medida:</strong> {item.unidadMedida ?? "--"},{" "}
                <strong>Cantidad:</strong> {item.cantidad ?? "--"}, <strong>Descuento:</strong> {item.descuento?.[0]?.montoDescuento ?? "--"},{" "}
                <strong>Impuestos:</strong> {item.impuestoNeto ?? item.impuesto?.[0]?.monto ?? "--"}, <strong>Total:</strong> {item.montoTotalLinea ?? "--"}
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.item.setDetalle({})}>Cancelar</Button>
          </Modal.Footer>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <Row>
          <Accordion className="full-size">
            <Accordion.Toggle as={Button} variant="link" eventKey="0">B&uacute;squeda de Comprobantes</Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Col>
                <Row>
                  <Col xs={12} sm={7} md={6} lg={5} xl={4}>
                    <CustomTextField required id="searchText" placeholder="Texto de b&uacute;squeda" input={pageHandler.textoBusqueda.input} />
                  </Col>
                  <Col>
                    <Button variant="primary" className="mr-10 mb-10" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleBuscar : null}>Buscar</Button>
                    <Button variant="secondary" className="mb-10" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleLimpiar : null}>Limpiar</Button>
                  </Col>
                </Row>
              </Col>
            </Accordion.Collapse>
          </Accordion>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <div className={paginationClass}>
              <CustomPagination pageHandler={pageHandler} loadItems={loadItems} badgeLabel={pageLabels.totalItems} showBadge={collapse} showButton={false} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className={selectClass}>
              <CustomSelectField id="tipoDocumento" label="Tipo de Documento" input={pageHandler.item.tipoDocumento.select} options={pageHandler.item.tipoDocumento.options} />
            </div>
          </Col>
        </Row>
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Fecha: {localStringDate(element.fechaEmision)}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Consecutivo: {String(element.numeroConsecutivo ?? "--")}</ListGroup.Item>
                    <ListGroup.Item>Receptor: {String(element.receptor?.nombre ?? "--")}</ListGroup.Item>
                    <ListGroup.Item>Actividad: {String(element.codigoActividad ?? "--")}</ListGroup.Item>
                    <ListGroup.Item>Estado: {isActive(setBoolean(element.activa))}</ListGroup.Item>
                    <ListGroup.Item>Total: {String(element.resumenFactura?.totalComprobante ?? "--")}</ListGroup.Item>
                    <ListGroup.Item>Respuesta: {String(element.statusDeRespuesta ?? "--")}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button className="ml-10 mt-5" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.setDetalle(element)}>Detalles</Button>
                      <Button className="ml-10 mt-5" variant="success" disabled={pageHandler.isLoading} onClick={() => handleImprimir(pageHandler.item.mapObject(element))}>Imprimir</Button>
                      {
                        pageHandler.item.tipoCorreo
                          ? <Button className="ml-10 mt-5" variant="secondary" disabled={pageHandler.isLoading} onClick={() => handleEnviarCorreo(element.clave)}>Enviar Correo</Button>
                          : null
                      }
                      {
                        setBoolean(element.activa) && pageHandler.item.tipoDocumento.value !== "notasCredito"
                          ? <Button className="ml-10 mt-5" variant="danger" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.setAnular(element)}>Anular</Button>
                          : null
                      }
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  {pageHandler.isSuperAdmin ? <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" /> : null}
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="fechaEmision" headerLabel="Fecha" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="numeroConsecutivo" headerLabel="Consecutivo" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="receptor.nombre" headerLabel="Receptor" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="codigoActividad" headerLabel="Actividad" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="activa" headerLabel="Estado" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="resumenFactura.totalComprobante" headerLabel="Total" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="statusDeRespuesta" headerLabel="Respuesta" />
                  <th className="width-242">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      {pageHandler.isSuperAdmin ? <td>{element.id}</td> : null}
                      <td>{localStringDate(element.fechaEmision)}</td>
                      <td>{String(element.numeroConsecutivo ?? "--")}</td>
                      <td>{String(element.receptor?.nombre ?? "--")}</td>
                      <td>{String(element.codigoActividad ?? "--")}</td>
                      <td>{isActive(setBoolean(element.activa))}</td>
                      <td>{String(element.resumenFactura?.totalComprobante ?? "--")}</td>
                      <td>{String(element.statusDeRespuesta ?? "--")}</td>
                      <td>
                        <Button className="ml-10 mt-5" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.setDetalle(element)}>Detalles</Button>
                        <Button className="ml-10 mt-5" variant="success" disabled={pageHandler.isLoading} onClick={() => handleImprimir(pageHandler.item.mapObject(element))}>Imprimir</Button>
                        {
                          pageHandler.item.tipoCorreo
                            ? <Button className="ml-10 mt-5" variant="secondary" disabled={pageHandler.isLoading} onClick={() => handleEnviarCorreo(element.clave)}>Enviar Correo</Button>
                            : null
                        }
                        {
                          setBoolean(element.activa) && pageHandler.item.tipoDocumento.value !== "notasCredito"
                            ? <Button className="ml-10 mt-5" variant="danger" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.setAnular(element)}>Anular</Button>
                            : null
                        }
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} showButton={false} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>);
}