import {createHeaders, createParams, request} from "./api";

export const createSucursalRequest = async(sucursal, cuentaId) => {
  try {
    const response = await request(`sucursales/create/${cuentaId}`, {method: "POST", body: JSON.stringify(sucursal), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Datos incorrectos" : error;
    throw errorMessage;
  }
}

export const updateSucursalRequest = async(sucursal, id) => {
  try {
    const response = await request(`sucursales/${id}`, {method: "POST", body: JSON.stringify(sucursal), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    const errorMessage = error === "Status 400" ? "Datos incorrectos" : error;
    throw errorMessage;
  }
}

export const getSucursalRequest = async(id) => {
  try {
    const response = await request(`sucursales/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listSucursalesRequest = async(cuentaId, queryParams) => {
  try {
    const response = await request(`sucursales/list/${cuentaId}${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const updateUsuarioSucursalesRequest = async(sucursales, usuarioId) => {
  try {
    const response = await request("sucursales/actualizarSucursales", {method: "POST", body: JSON.stringify({sucursales, usuarioId}), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}