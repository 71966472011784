import {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {select, remove, getCuentaLimiteRequest, getCuentaRequest, getSucursalRequest} from "../helpers";

const pagesNoNavbar = ["comprobante"];

export const useController = () => {
  const [usuarioId, setUsuarioId] = useState(0);
  const [usuarioRol, setUsuarioRol] = useState("VENDEDOR");
  const [cuentaSelected, setCuentaSelected] = useState(0);
  const [sucursalSelected, setSucursalSelected] = useState(0);
  const [cuenta, setCuenta] = useState({});
  const [sucursal, setSucursal] = useState({});
  const [usuarioCuentas, setUsuarioCuentas] = useState([]);
  const [usuarioSucursales, setUsuarioSucursales] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [limites, setLimites] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const handleWidth = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWidth);
    return () => window.removeEventListener("resize", handleWidth);
  }, []);

  useEffect(() => {
    const loadInfo = async() => {
      try {
        const isAuthenticated = await select("authenticated");
        if (isAuthenticated && (!authenticated || usuarioId)) {
          setAuthenticated(true);
          const id = await select("usuarioId");
          const rol = await select("usuarioRol");
          const cuentas = await select("usuarioCuentas");
          const sucursales = await select("usuarioSucursales");
          const cuenta = await select("cuentaSelected");
          const sucursal = await select("sucursalSelected");
          setUsuarioId(Number(id || 0));
          setUsuarioRol(String(rol || "VENDEDOR"));
          setUsuarioCuentas(cuentas || []);
          setUsuarioSucursales(sucursales || []);
          setCuentaSelected(Number(cuenta || 0));
          setSucursalSelected(Number(sucursal || 0));
          if (cuenta) {
            await getCuentaData(cuenta);
          }
          if (sucursal) {
            await getSucursalData(sucursal);
          }
        } else {
          setShowLogin(true);
        }
      } catch(error) {
        setShowLogin(true);
        if (error) {
          console.log(error);
        }
      }
    }
    loadInfo();
  }, [authenticated]);

  useEffect(() => {
    const changePath = async() => {
      try {
        const path = location.pathname;
        if (path !== activePage) {
          setActivePage(String(path));
        } else if (unauthorized) {
          await remove("tokens");
          await remove("authenticated");
          setAuthenticated(false);
        }
      } catch(error) {
        if (error) {
          console.log(error);
        }
      }
    }
    changePath();
  }, [location.pathname, unauthorized]);

  const getCuentaData = async(id = cuentaSelected) => {
    try {
      const cuentaData = await getCuentaRequest(id);
      const limites = await getCuentaLimiteRequest(id);
      setCuenta(cuentaData);
      setLimites(limites || {});
    } catch(error) {
      if (error) {
        console.log(error);
      }
    }
  }

  const getSucursalData = async(id = sucursalSelected) => {
    try {
      const sucursalData = await getSucursalRequest(id);
      setSucursal(sucursalData);
    } catch(error) {
      if (error) {
        console.log(error);
      }
    }
  }

  const hideNavbar = pagesNoNavbar.includes(location.pathname.split("/")[1]);

  return {
    usuario: {
      id: {get: usuarioId, set: setUsuarioId},
      rol: {get: usuarioRol, set: setUsuarioRol},
      cuentas: {get: usuarioCuentas, set: setUsuarioCuentas},
      sucursales: {get: usuarioSucursales, set: setUsuarioSucursales},
      cuenta: {get: cuentaSelected, data: cuenta, set: setCuentaSelected, setData: setCuenta, getCuentaData},
      sucursal: {get: sucursalSelected, data: sucursal, set: setSucursalSelected, setData: setSucursal, getSucursalData},
      limite: {
        sucursales: Number(limites.cantSucursalesCuenta || 0),
        usuarios: Number(limites.cantUsuarioCuenta || 0)
      }
    },
    session: {
      authenticated: {get: authenticated, set: setAuthenticated},
      unauthorized: {get: unauthorized, set: setUnauthorized},
      showLogin: {get: showLogin, set: setShowLogin},
      activePage: {get: activePage, set: setActivePage},
      width,
      history,
      location,
      hideNavbar
    }
  };
}