import {useState} from "react";
import {CustomOption} from "../components";
import {codigosPais} from "../constants";

export const useTelefono = () => {
  const [codigoPais, setCodigoPais] = useState("506");
  const [numTelefono, setNumTelefono] = useState("");

  const handleCodigoPais = (e) => setCodigoPais(e.target.value);

  const handleNumTelefono = (e) => setNumTelefono(e.target.value);

  const displayCodigosPais = () => codigosPais().map(element => <CustomOption key={element.key} item={element} />)

  const reset = () => {
    setCodigoPais("506");
    setNumTelefono("");
  }

  const setObject = (data) => {
    setCodigoPais(String(data.codigoPais || "506"));
    setNumTelefono(String(data.numTelefono || ""));
  }

  return {
    getObject: {codigoPais, numTelefono},
    codigoPais: {value: codigoPais, onChange: handleCodigoPais},
    numTelefono: {value: numTelefono, onChange: handleNumTelefono},
    codigosPais: displayCodigosPais,
    setCodigoPais,
    setNumTelefono,
    reset,
    setObject
  };
}
