import DatePicker, {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import {Form} from "./bootstrap";
registerLocale("es", es);

export const CustomDatePicker = ({id, label, placeholder = "", handler, minDate, maxDate, showTimeSelect = false, monthYear = false, disabled = false}) => (
  <Form.Group controlId={id}>
    <Form.Label>{label}</Form.Label>
    <DatePicker
      showMonthDropdown showYearDropdown dropdownMode="select" showPopperArrow={false} minDate={minDate} maxDate={maxDate} locale="es" className="form-control"
      placeholderText={placeholder} {...handler} {...(showTimeSelect ? {showTimeSelect, timeIntervals:1, timeFormat:"p", dateFormat:"Pp"} : {})}
      {...(monthYear ? {showMonthYearPicker: true, dateFormat: "MM/yyyy"} : {})} disabled={disabled}
    />
  </Form.Group>
);