export const sortAlphabetical = (array, field) => array.sort((a,b) => a[field].localeCompare(b[field]));

export const sortNumeric = (array) => array.sort((a,b) => Number(a)-Number(b));

export const lowerCase = (value) => String(value).toLowerCase();

export const numberFormat = (number = 0, format = "number") => format === "number" ? number : (number).toLocaleString(format);

export const roundNumber = (number = 0, decimals = 5) => decimals === "auto" ? Number(number) : (Number(number) + Number.EPSILON).toFixed(Number(decimals));

export const multiplyNumbers = (firstNumber = 0, secondNumber = 0, decimals = 5) => roundNumber((Number(firstNumber) * Number(secondNumber)), decimals);

export const divideNumbers = (firstNumber = 0, secondNumber = 1, decimals = 4) => roundNumber((Number(firstNumber) / Number(secondNumber)), decimals);

export const subtractNumbers = (firstNumber = 0, secondNumber = 0, decimals = 5) => roundNumber((Number(firstNumber) - Number(secondNumber)), decimals);

export const addNumbers = (firstNumber = 0, secondNumber = 0, decimals = 5) => roundNumber((Number(firstNumber) + Number(secondNumber)), decimals);

export const cutString = (string = "", length = 1) => String(string).substring(0, length);

export const normalizeString = (value) => value.replace(/\s/g, "").toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");

export const addLeadingZero = (number) => ('0' + String(number)).slice(-2);

export const localStringDate = (dateString = "") => dateString ? new Date(dateString).toLocaleString('es-US') : "--";

export const cleanStringDate = (dateString = "") => dateString?.split("T")?.[0] || "--";

export const cleanStringDateTime = (dateString = "") => {
  const dateParts = dateString?.split("T");
  return dateParts?.length === 2 ? `${dateParts[0]} ${dateParts[1].substring(0, 5)}` : "--";
}

export const getStringReducedFromDate = (date) => `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}-${addLeadingZero(date.getDate())}`;

export const getTime = (date) => `${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;

export const getStringFromDate = (date) => `${getStringReducedFromDate(date)}T00:00:00`;

export const getStringFromDateWithTime = (date) => `${getStringReducedFromDate(date)}T${getTime(date)}:00`;

export const getDateFromString = (dateString, addMonths = 0) => {
  const [year, month, day] = dateString.split("T")[0].split("-");
  return new Date(Number(year), Number(month) - 1 + addMonths, Number(day));
}

export const getDateFromStringWithTime = (dateString) => {
  const [datePart, timePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");
  return new Date(Number(year), Number(month) - 1, Number(day), hours, minutes);
}

export const isDateString = (dateString) => {
  const [year, month, day] = dateString.split("T")[0].split("-");
  return !isNaN(year) && !isNaN(month) && !isNaN(day)
}

export const isJson = (value) => {
  try {
    return typeof JSON.parse(value) === "object";
  } catch (_) {
    return false;
  }
}

export const setBoolean = (value) => {
  if (String(value).toLowerCase() === "true") {
    return true;
  } else if (String(value).toLowerCase() === "false") {
    return false;
  }
  return !!value;
}

export const initForm = (event) => {
  event.preventDefault();
  event.stopPropagation();
  return event.currentTarget;
}

export const parseXml = (xml) => {
  try {
    let obj = {};
    if (xml.children.length > 0) {
      for (let i = 0; i < xml.children.length; i++) {
        const item = xml.children.item(i);
        const nodeName = item.nodeName;
        if (typeof (obj[nodeName]) == "undefined") {
          obj[nodeName] = parseXml(item);
        } else {
          if (typeof (obj[nodeName].push) == "undefined") {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(parseXml(item));
        }
      }
    } else {
      obj = xml.textContent;
    }
    return obj;
  } catch (error) {
    if (error) {
      console.log(error);
    }
    return false;
  }
}