export const codigosImpuesto = () => [
  {key: "1ci", text: "IVA (Impuesto al Valor Agregado)", value: "01"},
  {key: "2ci", text: "Impuesto Selectivo de Consumo", value: "02"},
  {key: "3ci", text: "Impuesto Único a los Combustibles", value: "03"},
  {key: "4ci", text: "Impuesto específico de Bebidas Alcohólicas", value: "04"},
  {key: "5ci", text: "Impuesto Específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador", value: "05"},
  {key: "6ci", text: "Impuesto a los Productos de Tabaco", value: "06"},
  {key: "7ci", text: "IVA (cálculo especial)", value: "07"},
  {key: "8ci", text: "IVA Régimen de Bienes Usados (Factor)", value: "08"},
  {key: "9ci", text: "Impuesto Específico al Cemento", value: "12"},
  {key: "10ci", text: "Otros", value: "99"}
];
