import {tiposIdentificacion, codigosPais} from ".";

export const clienteFields = () => [
  {key: "1cf", text: "Nombre", value: "nombre", title: "nombre", selected: false, input: false, max: 100, editInput: "text", required: true, dependency: false},
  {key: "2cf", text: "Nombre Comercial", value: "nombreComercial", title: "comercial", selected: false, input: false, max: 80, editInput: "text", required: false, dependency: false},
  {key: "3cf", text: "Correo Electr\u00f3nico", value: "correoElectronico", title: "correo", selected: false, input: false, min: 6, max: 160, editInput: "text", required: true, dependency: false},
  {key: "4cf", text: "Tipo de Identificaci\u00f3n", value: "tipoIdentificacion", title: "tipo", selected: false, input: "select", selectOptions: tiposIdentificacion(), general: false, generalValue: "opcionGeneral", editInput: "select", required: true, dependency: false},
  {key: "5cf", text: "N\u00FAmero de Identificaci\u00f3n", value: "numero", title: "identificacion", selected: false, input: false, min: 9, max: 12, editInput: "text", required: true, dependency: false},
  {key: "6cf", text: "Identificaci\u00f3n Extranjero", value: "identificacionExtranjero", title: "extranjero", selected: false, input: false, max: 20, editInput: "text", required: false, dependency: false},
  {key: "7cf", text: "C\u00f3digo de Pa\u00EDs", value: "codigoPais", title: "pais", selected: false, input: "select", selectOptions: codigosPais(), general: false, generalValue: "opcionGeneral", editInput: "select", required: false, dependency: false},
  {key: "8cf", text: "N\u00FAmero de Tel\u00E9fono", value: "numTelefono", title: "telefono", selected: false, input: false, min: 8, max: 12, editInput: "text", required: false, dependency: false},
  {key: "9cf", text: "Provincia", value: "provincia", title: "provincia", selected: false, input: false, editInput: "text", required: true, dependency: true},
  {key: "10cf", text: "Cant\u00f3n", value: "canton", title: "canton", selected: false, input: false, editInput: "text", required: true, dependency: true},
  {key: "11cf", text: "Distrito", value: "distrito", title: "distrito", selected: false, input: false, editInput: "text", required: true, dependency: true},
  {key: "12cf", text: "Barrio", value: "barrio", title: "barrio", selected: false, input: false, editInput: "text", required: false, dependency: true},
  {key: "13cf", text: "Direcci\u00f3n Exacta", value: "direccion", title: "direccion", selected: false, input: false, max: 160, editInput: "text", required: true, dependency: false}
];