import {createHeaders, createParams, request} from "./api";

export const aceptarFacturaRequest = async(cuentaId, sucursalId, mensaje) => {
  try {
    const response = await request(`mensajeReceptor/${cuentaId}/${sucursalId}`, {method: "POST", body: JSON.stringify(mensaje), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getRespuestaRequest = async(cuentaId, sucursalId, id) => {
  try {
    const response = await request(`mensajeReceptor/${cuentaId}/${sucursalId}/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listRespuestaRequest = async(cuentaId, sucursalId, queryParams) => {
  try {
    const response = await request(`mensajeReceptor/list/${cuentaId}/${sucursalId}${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getRespuestaClaveRequest = async(clave) => {
  try {
    const response = await request(`mensajeReceptor/${clave}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    if (error === "Status 404") {
      return false;
    }
    throw error;
  }
}