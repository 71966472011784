import {useEffect} from "react";
import {useUsuario as useItem, usePageHandler} from "../customHooks";
import {
  createUsuarioRequest as createRequest, updateUsuarioRequest as updateRequest, deleteUsuarioRequest as deleteRequest,
  listUsuariosRequest as listRequest, validarUsernameRequest, validarEmailRequest, initForm
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert, CustomTextField,
  CustomSelectField, CustomPagination, CustomTableHeader
} from "../components";
const pageLabels = {
  itemCreate: "Crear usuario", itemUpdate: "Modificar usuario", itemDelete: "Eliminar usuario", itemCreated: "Usuario creado",
  itemUpdated: "Usuario modificado", itemDeleted: "Usuario eliminado", totalItems: "Usuarios", genericError: "Ha ocurrido un error"
};

export const UsuarioPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem, {cuentasUsuario: controller.usuario.cuentas.get, usuarioId: controller.usuario.id.get, role: controller.usuario.rol.get});
  const cuenta = controller.usuario.cuenta.get;
  const limiteUsuarios = controller.usuario.limite.usuarios;
  const collapse = controller.session.width < 1000;

  useEffect(() => {
    if (cuenta) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [cuenta]);

  const loadItems = async(page, size, sort) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, {page, size, sort});
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          if (pageHandler.item.userNameChanged()) {
            await validarUsernameRequest(pageHandler.item.userName.value);
          }
          if (pageHandler.item.correoElectronicoChanged()) {
            await validarEmailRequest(pageHandler.item.correoElectronico.value);
          }
          if (pageHandler.modalAction === "create") {
            await createRequest(pageHandler.item.getObject, cuenta);
          } else {
            await updateRequest(pageHandler.item.getObject, pageHandler.item.id);
          }
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
          pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const handleDelete = () => {
    const remove = async() => {
      try {
        await deleteRequest(pageHandler.item.id, cuenta);
        await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
        pageHandler.handleDeleteItem(pageLabels.itemDeleted);
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    remove();
  }

  if (cuenta) {
    return (
      <Container className="full-container">
        <Modal centered animation={false} show={pageHandler.modalAction === "delete"} onHide={() => pageHandler.setModalAction("")}>
          <Modal.Header closeButton><Modal.Title>{pageLabels.itemDelete}</Modal.Title></Modal.Header>
          <Modal.Body>Confirmar esta acci&oacute;n</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.setModalAction("")}>Cerrar</Button>
            <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={pageHandler.openModal} onHide={() => pageHandler.setOpenModal(false)} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : pageLabels.itemUpdate}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              <Row>
                <Col lg>
                  <CustomTextField required id="itemUsername" label="Nombre de usuario" input={pageHandler.item.userName.input} invalid="Este campo es requerido" />
                  <CustomTextField required={pageHandler.modalAction === "create"} id="itemPassword" label="Contrase&ntilde;a de usuario" input={pageHandler.item.password.input} invalid="Este campo es requerido" />
                  <CustomTextField required type="email" id="itemCorreo" label="Correo Electr&oacute;nico" input={pageHandler.item.correoElectronico.input} invalid="Formato de correo incorrecto" />
                  <CustomSelectField id="itemRole" label="Rol de usuario" input={pageHandler.item.roles.select} options={pageHandler.item.roles.options} />
                </Col>
                <Col lg>
                  <CustomSelectField id="itemCuentas" label="Cuentas" multiple htmlSize="6" input={pageHandler.item.cuentas.select} options={pageHandler.item.cuentas.options} />
                  <CustomSelectField id="itemSucursales" label="Sucursales" multiple htmlSize="6" input={pageHandler.item.sucursales.select} options={pageHandler.item.sucursales.options} />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => pageHandler.setOpenModal(false)} disabled={pageHandler.isLoading}>Cerrar</Button>
              <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <CustomPagination
          pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems}
          showBadge={collapse} limite={limiteUsuarios} disableButton={pageHandler.totalItems >= limiteUsuarios}
        />
        {
          collapse ? (
            pageHandler.items.map((element) =>
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Nombre de usuario: {element.userName}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Correo Electr&oacute;nico: {element.correoElectronico}</ListGroup.Item>
                    <ListGroup.Item>Rol de usuario: {element.roles && element.roles.length ? element.roles[0] : "--"}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                      <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  {pageHandler.isSuperAdmin ? <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" /> : null}
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="userName" headerLabel="Nombre de usuario" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="correoElectronico" headerLabel="Correo Electr&oacute;nico" />
                  <th>Rol de usuario</th>
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems} &nbsp;&nbsp; L&iacute;mite: {limiteUsuarios}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      {pageHandler.isSuperAdmin ? <td>{element.id}</td> : null}
                      <td>{element.userName}</td>
                      <td>{element.correoElectronico}</td>
                      <td>{element.roles && element.roles.length ? element.roles[0] : "--"}</td>
                      <td>
                        <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                        <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} disableButton={pageHandler.totalItems >= limiteUsuarios} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}