import {useEffect} from "react";
import {writeFile, utils} from "xlsx";
import {useReporte as useItem, usePageHandler} from "../customHooks";
import {
  Container, CustomAlert, Form, Row, Col, CustomSelectField, CustomTextField, CustomDatePicker, Button, CustomPagination, Card, ListGroup, Table, Modal
} from "../components";
import {
  reporteTotalesNetosCliente, reporteFacturasEmitidas, reporteTiquetesEmitidos, reporteTotalesNetosPorcentajeImpuestos, reporteFacturasPorcentajes,
  reporteFacturasAceptadas, reporteFacturasAceptadasPorcentaje, reporteFacturasAceptadasPorEmision, reporteFacturasAceptadasPorcentajePorEmision,
  localStringDate, addNumbers, multiplyNumbers, subtractNumbers, sortNumeric, reporteNotasEmitidas, getStringReducedFromDate
} from "../helpers";
import {condicionesImpuesto} from "../constants";

const pageLabels = {totalItems: "Registros", genericError: "Ha ocurrido un error"};

const consolidado = () => <CustomAlert type="info" body="Proceda a descargar el reporte" show />

const facturas = (pageWidth, items) => {
  if (pageWidth >= 1547) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Consecutivo</th>
            <th>Actividad</th>
            <th>Cliente</th>
            <th>Identificaci&oacute;n</th>
            <th>Gravado</th>
            <th>Exento</th>
            <th>Exonerado</th>
            <th>Descuentos</th>
            <th>Impuestos</th>
            <th>IVA Devuelto</th>
            <th>Otros Cargos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{localStringDate(element.fechaEmision)}</td>
                <td>{element.numeroConsecutivo}</td>
                <td>{element.codigoActividad}</td>
                <td>{element.receptorNombre}</td>
                <td>{element.receptorIdentificacion ?? element.receptorIdentificacionExtranjero}</td>
                <td>{element.resumenFactura.totalGravado ?? "0"}</td>
                <td>{element.resumenFactura.totalExento ?? "0"}</td>
                <td>{element.resumenFactura.totalExonerado ?? "0"}</td>
                <td>{element.resumenFactura.totalDescuentos ?? "0"}</td>
                <td>{element.resumenFactura.totalImpuesto ?? "0"}</td>
                <td>{element.resumenFactura.totalIVADevuelto ?? "0"}</td>
                <td>{element.resumenFactura.totalOtrosCargos ?? "0"}</td>
                <td>{element.resumenFactura.totalComprobante ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Fecha:&#160;{localStringDate(element.fechaEmision)} &#160;&#160; Consecutivo:&#160;{element.numeroConsecutivo} &#160;&#160; Actividad:&#160;{element.codigoActividad}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Cliente:&#160;{element.receptorNombre} &#160;&#160; Identificaci&oacute;n:&#160;{element.receptorIdentificacion ?? element.receptorIdentificacionExtranjero}</ListGroup.Item>
              <ListGroup.Item>Gravado:&#160;{element.resumenFactura.totalGravado ?? "0"} &#160;&#160; Exento:&#160;{element.resumenFactura.totalExento ?? "0"} &#160;&#160; Exonerado:&#160;{element.resumenFactura.totalExonerado ?? "0"} &#160;&#160; Descuentos:&#160;{element.resumenFactura.totalDescuentos ?? "0"}</ListGroup.Item>
              <ListGroup.Item>Impuestos:&#160;{element.resumenFactura.totalImpuesto ?? "0"} &#160;&#160; IVA&#160;Devuelto:&#160;{element.resumenFactura.totalIVADevuelto ?? "0"} &#160;&#160; Otros&#160;Cargos:&#160;{element.resumenFactura.totalOtrosCargos ?? "0"} &#160;&#160; Total:&#160;{element.resumenFactura.totalComprobante ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const notasCredito = (pageWidth, items) => {
  if (pageWidth >= 1547) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Consecutivo</th>
            <th>Actividad</th>
            <th>Cliente</th>
            <th>Identificaci&oacute;n</th>
            <th>Gravado</th>
            <th>Exento</th>
            <th>Exonerado</th>
            <th>Descuentos</th>
            <th>Impuestos</th>
            <th>IVA Devuelto</th>
            <th>Otros Cargos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{localStringDate(element.fechaEmision)}</td>
                <td>{element.numeroConsecutivo}</td>
                <td>{element.codigoActividad}</td>
                <td>{element.receptorNombre}</td>
                <td>{element.receptorIdentificacion ?? element.receptorIdentificacionExtranjero}</td>
                <td>{element.resumenFactura.totalGravado ?? "0"}</td>
                <td>{element.resumenFactura.totalExento ?? "0"}</td>
                <td>{element.resumenFactura.totalExonerado ?? "0"}</td>
                <td>{element.resumenFactura.totalDescuentos ?? "0"}</td>
                <td>{element.resumenFactura.totalImpuesto ?? "0"}</td>
                <td>{element.resumenFactura.totalIVADevuelto ?? "0"}</td>
                <td>{element.resumenFactura.totalOtrosCargos ?? "0"}</td>
                <td>{element.resumenFactura.totalComprobante ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Fecha:&#160;{localStringDate(element.fechaEmision)} &#160;&#160; Consecutivo:&#160;{element.numeroConsecutivo} &#160;&#160; Actividad:&#160;{element.codigoActividad}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Cliente:&#160;{element.receptorNombre} &#160;&#160; Identificaci&oacute;n:&#160;{element.receptorIdentificacion ?? element.receptorIdentificacionExtranjero}</ListGroup.Item>
              <ListGroup.Item>Gravado:&#160;{element.resumenFactura.totalGravado ?? "0"} &#160;&#160; Exento:&#160;{element.resumenFactura.totalExento ?? "0"} &#160;&#160; Exonerado:&#160;{element.resumenFactura.totalExonerado ?? "0"} &#160;&#160; Descuentos:&#160;{element.resumenFactura.totalDescuentos ?? "0"}</ListGroup.Item>
              <ListGroup.Item>Impuestos:&#160;{element.resumenFactura.totalImpuesto ?? "0"} &#160;&#160; IVA&#160;Devuelto:&#160;{element.resumenFactura.totalIVADevuelto ?? "0"} &#160;&#160; Otros&#160;Cargos:&#160;{element.resumenFactura.totalOtrosCargos ?? "0"} &#160;&#160; Total:&#160;{element.resumenFactura.totalComprobante ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const facturasPorcentaje = (pageWidth, items) => {
  if (pageWidth >= 1370) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Consecutivo</th>
            <th>Actividad</th>
            <th>Cliente</th>
            <th>Identificaci&oacute;n</th>
            <th>Descuentos</th>
            <th>Porcentaje IVA</th>
            <th>Impuestos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{localStringDate(element.fecha)}</td>
                <td>{element.consecutivo}</td>
                <td>{element.codigoActividad}</td>
                <td>{element.nombreCliente}</td>
                <td>{element.identificacion}</td>
                <td>{element.descuento ?? "0"}</td>
                <td>{element.porcentaje ?? "0"}</td>
                <td>{element.impuestos ?? "0"}</td>
                <td>{element.total ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Fecha:&#160;{localStringDate(element.fecha)} &#160;&#160; Consecutivo:&#160;{element.consecutivo} &#160;&#160; Actividad:&#160;{element.codigoActividad}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Cliente:&#160;{element.nombreCliente} &#160;&#160; Identificaci&oacute;n:&#160;{element.identificacion}</ListGroup.Item>
              <ListGroup.Item>Descuentos:&#160;{element.descuento ?? "0"} &#160;&#160; Porcentaje&#160;IVA:&#160;{element.porcentaje ?? "0"} &#160;&#160; Impuestos:&#160;{element.impuestos ?? "0"} &#160;&#160; Total:&#160;{element.total ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const tiquetes = (pageWidth, items) => {
  if (pageWidth >= 1325) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Consecutivo</th>
            <th>Actividad</th>
            <th>Gravado</th>
            <th>Exento</th>
            <th>Exonerado</th>
            <th>Descuentos</th>
            <th>Impuestos</th>
            <th>IVA Devuelto</th>
            <th>Otros Cargos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{localStringDate(element.fechaEmision)}</td>
                <td>{element.numeroConsecutivo}</td>
                <td>{element.codigoActividad}</td>
                <td>{element.resumenFactura.totalGravado ?? "0"}</td>
                <td>{element.resumenFactura.totalExento ?? "0"}</td>
                <td>{element.resumenFactura.totalExonerado ?? "0"}</td>
                <td>{element.resumenFactura.totalDescuentos ?? "0"}</td>
                <td>{element.resumenFactura.totalImpuesto ?? "0"}</td>
                <td>{element.resumenFactura.totalIVADevuelto ?? "0"}</td>
                <td>{element.resumenFactura.totalOtrosCargos ?? "0"}</td>
                <td>{element.resumenFactura.totalComprobante ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Fecha:&#160;{localStringDate(element.fechaEmision)} &#160;&#160; Consecutivo:&#160;{element.numeroConsecutivo} &#160;&#160; Actividad:&#160;{element.codigoActividad}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Gravado:&#160;{element.resumenFactura.totalGravado ?? "0"} &#160;&#160; Exento:&#160;{element.resumenFactura.totalExento ?? "0"} &#160;&#160; Exonerado:&#160;{element.resumenFactura.totalExonerado ?? "0"} &#160;&#160; Descuentos:&#160;{element.resumenFactura.totalDescuentos ?? "0"}</ListGroup.Item>
              <ListGroup.Item>Impuestos:&#160;{element.resumenFactura.totalImpuesto ?? "0"} &#160;&#160; IVA&#160;Devuelto:&#160;{element.resumenFactura.totalIVADevuelto ?? "0"} &#160;&#160; Otros&#160;Cargos:&#160;{element.resumenFactura.totalOtrosCargos ?? "0"} &#160;&#160; Total:&#160;{element.resumenFactura.totalComprobante ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const totalesCliente = (pageWidth, items) => {
  if (pageWidth >= 574) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Identificaci&oacute;n</th>
            <th>Venta Neta</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{element.nombreCliente}</td>
                <td>{element.identificacion}</td>
                <td>{element.ventaNeta ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Cliente:&#160;{element.nombreCliente} &#160;&#160; Identificaci&oacute;n:&#160;{element.identificacion}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Venta&#160;Neta:&#160;{element.ventaNeta ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const totalesNetos = (pageWidth, items) => {
  if (pageWidth >= 574) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Porcentaje IVA</th>
            <th>Subtotal</th>
            <th>Impuestos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{element.porcentajeImpuesto ?? "0"}</td>
                <td>{element.subtotal ?? "0"}</td>
                <td>{element.impuestos ?? "0"}</td>
                <td>{element.total ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Porcentaje&#160;IVA:&#160;{element.porcentajeImpuesto ?? "0"}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Subtotal:&#160;{element.subtotal ?? "0"} &#160;&#160; Impuestos:&#160;{element.impuestos ?? "0"} &#160;&#160; Total:&#160;{element.total ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const aceptadas = (pageWidth, items) => {
  if (pageWidth >= 1537) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Consecutivo</th>
            <th>Clave</th>
            <th>Emitida</th>
            <th>Aceptada</th>
            <th>Actividad</th>
            <th>Emisor</th>
            <th>Identificaci&oacute;n</th>
            <th>Condici&oacute;n</th>
            <th>Impuestos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{element.consecutivoReceptor.substring(0,10)}<br />{element.consecutivoReceptor.substring(10)}</td>
                <td>{element.claveEmisor.substring(0,25)}<br />{element.claveEmisor.substring(25)}</td>
                <td>{localStringDate(element.fechaEmision)}</td>
                <td>{localStringDate(element.fechaAceptacion)}</td>
                <td>{element.codigoActividad ?? ""}</td>
                <td>{element.nombreEmisor ?? ""}</td>
                <td>{element.identificacion ?? ""}</td>
                <td>{getCondicionImpuesto(element.condicionImpuesto)}</td>
                <td>{element.impuestos ?? "0"}</td>
                <td>{element.total ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Consecutivo:&#160;{element.consecutivoReceptor} &#160;&#160; Clave:&#160;{element.claveEmisor}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Emitida:&#160;{localStringDate(element.fechaEmision)} &#160;&#160; Aceptada:&#160;{localStringDate(element.fechaAceptacion)} &#160;&#160; Actividad:&#160;{element.codigoActividad ?? ""}</ListGroup.Item>
              <ListGroup.Item>Emisor:&#160;{element.nombreEmisor ?? ""} &#160;&#160; Identificaci&oacute;n:&#160;{element.identificacion ?? ""}</ListGroup.Item>
              <ListGroup.Item>Condici&oacute;n:&#160;{getCondicionImpuesto(element.condicionImpuesto)} &#160;&#160; Impuestos:&#160;{element.impuestos ?? "0"} &#160;&#160; Total:&#160;{element.total ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const aceptadasPorcentaje =(pageWidth, items) => {
  if (pageWidth >= 1700) {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Consecutivo</th>
            <th>Clave</th>
            <th>Emitida</th>
            <th>Aceptada</th>
            <th>Actividad</th>
            <th>Emisor</th>
            <th>Identificaci&oacute;n</th>
            <th>Detalle</th>
            <th>Cantidad</th>
            <th>Precio</th>
            <th>Tarifa</th>
            <th>Impuesto</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((element, index) =>
              <tr key={`lineaReporte${index}`}>
                <td>{element.consecutivoReceptor.substring(0,10)}<br />{element.consecutivoReceptor.substring(10)}</td>
                <td>{element.claveEmisor.substring(0,17)}<br />{element.claveEmisor.substring(17,34)}<br />{element.claveEmisor.substring(34)}</td>
                <td>{localStringDate(element.fechaEmision)}</td>
                <td>{localStringDate(element.fechaAceptacion)}</td>
                <td>{element.codigoActividad ?? ""}</td>
                <td>{element.nombreEmisor ?? ""}</td>
                <td>{element.identificacion ?? ""}</td>
                <td>{element.detalle ?? ""}</td>
                <td>{element.cantidad ?? "0"}</td>
                <td>{element.precioUnitario ?? "0"}</td>
                <td>{element.tarifa ?? "0"}</td>
                <td>{element.impuesto ?? "0"}</td>
                <td>{element.total ?? "0"}</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    );
  } else {
    return (
      items.map((element, index) =>
        <Row className="m-0" key={`lineaReporte${index}`}>
          <Card border="secondary" className="full-size mb-10">
            <Card.Header className="font-bold">Consecutivo:&#160;{element.consecutivoReceptor} &#160;&#160; Clave:&#160;{element.claveEmisor}</Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Emitida:&#160;{localStringDate(element.fechaEmision)} &#160;&#160; Aceptada:&#160;{localStringDate(element.fechaAceptacion)} &#160;&#160; Actividad:&#160;{element.codigoActividad ?? ""}</ListGroup.Item>
              <ListGroup.Item>Emisor:&#160;{element.nombreEmisor ?? ""} &#160;&#160; Identificaci&oacute;n:&#160;{element.identificacion ?? ""}</ListGroup.Item>
              <ListGroup.Item>Detalle:&#160;{element.detalle ?? ""} &#160;&#160; Cantidad:&#160;{element.cantidad ?? "0"} &#160;&#160; Precio:&#160;{element.precioUnitario ?? "0"} &#160;&#160; Tarifa:&#160;{element.tarifa ?? "0"} &#160;&#160; Impuesto:&#160;{element.impuesto ?? "0"} &#160;&#160; Total:&#160;{element.total ?? "0"}</ListGroup.Item>
            </ListGroup>
          </Card>
        </Row>
      )
    );
  }
}

const mapElements = (elements, empty, numberFields, labels) => {
  const totales = elements.reduce((sumTotales, element) => {
    const sumTotalesAux = JSON.parse(JSON.stringify(sumTotales));
    for (let i = empty.length + 1; i < labels.length; i++) {
      sumTotalesAux[i] = addNumbers(sumTotales[i], element[i]);
    }
    return sumTotalesAux;
  }, [...empty, "Totales:", ...numberFields]);
  return [labels].concat(elements).concat([totales]);
}

const mapFacturas = (items) => mapElements(
  items.map(item => [
    localStringDate(item.fechaEmision), `# ${item.clave}`, `# ${item.numeroConsecutivo}`,item.activa ? "" : "Anulada mediante nota",
    item.codigoActividad, item.receptorNombre ?? "", `ID ${item.receptorIdentificacion ?? item.receptorIdentificacionExtranjero ?? ""}`,
    Number(item.resumenFactura.totalExento), Number(item.resumenFactura.totalExonerado), Number(item.resumenFactura.totalGravado),
    Number(item.resumenFactura.totalVenta), Number(item.resumenFactura.totalDescuentos), Number(item.resumenFactura.totalVentaNeta),
    Number(item.resumenFactura.totalImpuesto), Number(item.resumenFactura.totalComprobante)
  ]),
  ["", "", "", "", "", ""], [0, 0, 0, 0, 0, 0, 0, 0], ["Fecha", "Clave", "Consecutivo", "Referencia", "Actividad", "Cliente", "Identificaci\u00F3n", "Exento", "Exonerado", "Gravado", "Venta", "Descuentos", "Venta Neta", "Impuestos", "Total"]
);

const mapNotasCredito = (items) => mapElements(
  items.map(item => [
    localStringDate(item.fechaEmision), `# ${item.clave}`, `# ${item.numeroConsecutivo}`, `# ${item.informacionReferencia[0].numero.substring(21, 41)}`,
    item.codigoActividad, item.receptorNombre ?? "", `ID ${item.receptorIdentificacion ?? item.receptorIdentificacionExtranjero ?? ""}`,
    Number(item.resumenFactura.totalExento), Number(item.resumenFactura.totalExonerado), Number(item.resumenFactura.totalGravado),
    Number(item.resumenFactura.totalVenta), Number(item.resumenFactura.totalDescuentos), Number(item.resumenFactura.totalVentaNeta),
    Number(item.resumenFactura.totalImpuesto), Number(item.resumenFactura.totalComprobante)
  ]),
  ["", "", "", "", "", ""], [0, 0, 0, 0, 0, 0, 0, 0], ["Fecha", "Clave", "Consecutivo", "Referencia", "Actividad", "Cliente", "Identificaci\u00F3n", "Exento", "Exonerado", "Gravado", "Venta", "Descuentos", "Venta Neta", "Impuestos", "Total"]
);

const mapFacturasPorcentaje = (items) => mapElements(
  items.map(item => [
    localStringDate(item.fecha), `# ${item.consecutivo}`, item.codigoActividad, item.nombreCliente ?? "", `ID ${item.identificacion ?? ""}`,
    Number(item.porcentaje), Number(item.impuestos), Number(item.total) 
  ]),
  ["", "", "", "", ""], [0, 0], ["Fecha", "Consecutivo", "Actividad", "Cliente", "Identificaci\u00F3n", "Tarifa", "Impuestos", "Total"]
);

const mapTiquetes = (items) => mapElements(
  items.map(item => [
    localStringDate(item.fechaEmision), `# ${item.clave}`, `# ${item.numeroConsecutivo}`,item.activa ? "" : "Anulada mediante nota", item.codigoActividad,
    Number(item.resumenFactura.totalExento), Number(item.resumenFactura.totalExonerado), Number(item.resumenFactura.totalGravado),
    Number(item.resumenFactura.totalVenta), Number(item.resumenFactura.totalDescuentos), Number(item.resumenFactura.totalVentaNeta),
    Number(item.resumenFactura.totalImpuesto), Number(item.resumenFactura.totalComprobante)
  ]),
  ["", "", "", ""], [0, 0, 0, 0, 0, 0, 0, 0], ["Fecha", "Clave", "Consecutivo", "Referencia", "Actividad", "Exento", "Exonerado", "Gravado", "Venta", "Descuentos", "Venta Neta", "Impuestos", "Total"]
);

const mapTotalesCliente = (items) => mapElements(
  items.map(item => [item.nombreCliente ?? "", item.tipoCedula ?? "", `ID ${item.identificacion ?? ""}`, Number(item.ventaNeta)]),
  ["", ""], [0], ["Cliente", "Tipo", "Identificaci\u00F3n", "Venta Neta"]
);

const mapTotalesNetos = (items) => mapElements(
  items.map(item => [item.porcentajeImpuesto ?? "", Number(item.subtotal), Number(item.impuestos), Number(item.total)]),
  [], [0, 0, 0], ["Tarifa", "Subtotal", "Impuestos", "Total"]
);

const mapAceptadas = (items) => mapElements(
  items.map(item => [
    localStringDate(item.fechaEmision), localStringDate(item.fechaAceptacion), `# ${item.claveEmisor}`, `# ${item.consecutivoReceptor}`,
    item.codigoActividad ?? "", item.nombreEmisor ?? "", `ID ${item.identificacion ?? ""}`, getCondicionImpuesto(item.condicionImpuesto),
    Number(item.impuestos), Number(item.total)
  ]),
  ["", "", "", "", "", "", ""], [0, 0], ["Emitida", "Aceptada", "Clave del Emisor", "Consecutivo", "Actividad", "Emisor", "Identificaci\u00F3n", "Condicion", "Impuestos", "Total"]
);

const calcularImpuesto = (item) => {
  if (item.impuesto) {
    return item.impuesto;
  }
  const subtotal = multiplyNumbers(item.cantidad, item.precioUnitario);
  return subtractNumbers(item.total, subtotal);
}

const mapAceptadasPorcentaje = (items) => mapElements(
  items.map(item => [
    localStringDate(item.fechaEmision), localStringDate(item.fechaAceptacion), `# ${item.claveEmisor}`, `# ${item.consecutivoReceptor}`,
    item.codigoActividad ?? "", item.nombreEmisor ?? "", `ID ${item.identificacion ?? ""}`, Number(item.numeroLinea), item.detalle ?? "", `COD ${item.codigo ?? ""}`,
    Number(item.cantidad), Number(item.precioUnitario), Number(item.tarifa), calcularImpuesto(item), Number(item.total)
  ]),
  ["", "", "", "", "", "", "", "", "", "", "", ""], [0, 0], ["Emitida", "Aceptada", "Clave del Emisor", "Consecutivo", "Actividad", "Emisor", "Identificaci\u00F3n", "L\u00EDnea", "Detalle", "C\u00F3digo", "Cantidad", "Precio Unidad", "Tarifa", "Impuesto", "Total"]
);

const mapAceptadasPorcentajeNeto = (items) => {
  const itemsReduced = [];
  let porcentajes = [];
  items.forEach(item => {
    const index = itemsReduced.findIndex(itemReduced => itemReduced.consecutivoReceptor === item.consecutivoReceptor);
    const porcentajeAdded = porcentajes.findIndex(porcentaje => porcentaje === item.tarifa);
    if (porcentajeAdded === -1) {
      porcentajes.push(item.tarifa);
    }
    if (index === -1) {
      const itemReduced = {
        claveEmisor: item.claveEmisor,
        codigoActividad: item.codigoActividad,
        consecutivoReceptor: item.consecutivoReceptor,
        fechaAceptacion: item.fechaAceptacion,
        fechaEmision: item.fechaEmision,
        identificacion: item.identificacion,
        nombreEmisor: item.nombreEmisor
      };
      itemReduced[item.tarifa] = {
        impuesto: calcularImpuesto(item),
        total: item.total,
      };
      itemsReduced.push(itemReduced);
    } else {
      if (itemsReduced[index][item.tarifa]) {
        itemsReduced[index][item.tarifa].impuesto = addNumbers(itemsReduced[index][item.tarifa].impuesto, calcularImpuesto(item));
        itemsReduced[index][item.tarifa].total = addNumbers(itemsReduced[index][item.tarifa].total, item.total);
      } else {
        itemsReduced[index][item.tarifa] = {
          impuesto: calcularImpuesto(item),
          total: item.total,
        };
      }
    }
  });
  porcentajes = sortNumeric(porcentajes);
  return mapElements(
    itemsReduced.map(item => [
      localStringDate(item.fechaEmision), localStringDate(item.fechaAceptacion), `# ${item.claveEmisor}`, `# ${item.consecutivoReceptor}`,
      item.codigoActividad ?? "", item.nombreEmisor ?? "", `ID ${item.identificacion ?? ""}`
    ].concat(porcentajes.map(percentage => [item[percentage]?.impuesto ?? 0, item[percentage]?.total ?? 0]).flat(1))),
    ["", "", "", "", "", ""], porcentajes.map(_ => [0, 0]).flat(1),
    ["Emitida", "Aceptada", "Clave del Emisor", "Consecutivo", "Actividad", "Emisor", "Identificaci\u00F3n"].concat(porcentajes.map(percentage => [`Impuesto (${percentage}%)`, `Total (${percentage}%)`]).flat(1))
  );
};

const reportes = {
  consolidado: {title: "Consolidado (Facturas, Tiquetes, Notas)", componente: consolidado},
  facturas: {title: "Facturas Electr\u00F3nicas", reporte: reporteFacturasEmitidas, componente: facturas, mapItems: mapFacturas},
  tiquetes: {title: "Tiquetes Electr\u00F3nicos", reporte: reporteTiquetesEmitidos, componente: tiquetes, mapItems: mapTiquetes},
  notasCredito: {title: "Notas de Cr\u00E9dito", reporte: reporteNotasEmitidas, componente: notasCredito, mapItems: mapNotasCredito},
  facturasPorcentaje: {title: "Facturas Electr\u00F3nicas por L\u00EDneas", reporte: reporteFacturasPorcentajes, componente: facturasPorcentaje, mapItems: mapFacturasPorcentaje},
  totalesCliente: {title: "Ventas Netas por Cliente", reporte: reporteTotalesNetosCliente, componente: totalesCliente, mapItems: mapTotalesCliente},
  totalesNetos: {title: "Total de Impuestos por Porcentaje", reporte: reporteTotalesNetosPorcentajeImpuestos, componente: totalesNetos, mapItems: mapTotalesNetos},
  aceptadas: {title: "Facturas Aceptadas (Buscar por Fecha de Aceptaci\u00F3n)", reporte: reporteFacturasAceptadas, componente: aceptadas, mapItems: mapAceptadas},
  aceptadasPorcentaje: {title: "Facturas Aceptadas por L\u00EDneas (Buscar por Fecha de Aceptaci\u00F3n)", reporte: reporteFacturasAceptadasPorcentaje, componente: aceptadasPorcentaje, mapItems: mapAceptadasPorcentaje},
  aceptadasPorcentajeNeto: {title: "Facturas Aceptadas por Porcentajes (Buscar por Fecha de Aceptaci\u00F3n)", reporte: reporteFacturasAceptadasPorcentaje, componente: aceptadasPorcentaje, mapItems: mapAceptadasPorcentajeNeto},
  aceptadasEmision: {title: "Facturas Aceptadas (Buscar por Fecha de Emisi\u00F3n)", reporte: reporteFacturasAceptadasPorEmision, componente: aceptadas, mapItems: mapAceptadas},
  aceptadasPorcentajeEmision: {title: "Facturas Aceptadas por L\u00EDneas (Buscar por Fecha de Emisi\u00F3n)", reporte: reporteFacturasAceptadasPorcentajePorEmision, componente: aceptadasPorcentaje, mapItems: mapAceptadasPorcentaje},
  aceptadasPorcentajeNetoEmision: {title: "Facturas Aceptadas por Porcentajes (Buscar por Fecha de Emisi\u00F3n)", reporte: reporteFacturasAceptadasPorcentajePorEmision, componente: aceptadasPorcentaje, mapItems: mapAceptadasPorcentajeNeto}
};

const tipoReportes = () => Object.entries(reportes).map(([key, data]) => ({key: `${key}tr`, text: data.title, value: key}));

const getCondicionImpuesto = (value) => condicionesImpuesto().find(element => element.value === value)?.text ?? "";

export const ReportePage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem, tipoReportes);
  const sucursal = controller.usuario.sucursal.get;

  useEffect(() => {
    cleanReporte();
  }, [sucursal]);

  useEffect(() => {
    if (pageHandler.item.download) {
      const table = document.getElementById("tablaReporte");
      const book = utils.table_to_book(table);
      const tipo = pageHandler.item.tipoArchivo.objectSelected();
      writeFile(book, `${pageHandler.item.nombreArchivo.value}${tipo.ext}`, {bookType: tipo.value});
      pageHandler.item.setShowModal(false);
    }
  }, [pageHandler.item.download]);

  const loadItems = async(page, size, _) => {
    try {
      pageHandler.initProcess(true);
      if (pageHandler.item.tipoReporte.value === "consolidado") {
        pageHandler.handleLoadItems({content: [{}]});
      } else {
        const fechaAux = new Date(pageHandler.item.fechaFinal.selected);
        fechaAux.setDate(fechaAux.getDate() + 1);
        const fechaFinal = getStringReducedFromDate(fechaAux);
        const response = await reportes[pageHandler.item.tipoReporte.value].reporte([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page, size});
        pageHandler.handleLoadItems(response);
      }
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const generarReporte = () => {
    pageHandler.item.nombreArchivo.setValue(pageHandler.item.tipoReporte.value);
    pageHandler.setOffSet(0);
    loadItems(0, pageHandler.limit);
  }

  const cleanReporte = () => {
    if (pageHandler.items.length > 0) {
      pageHandler.handleLoadItems({});
    }
  }

  const descargarReporte = () => {
    const downloadData = async() => {
      try {
        const fechaAux = new Date(pageHandler.item.fechaFinal.selected);
        fechaAux.setDate(fechaAux.getDate() + 1);
        const fechaFinal = getStringReducedFromDate(fechaAux);
        if (pageHandler.item.tipoReporte.value === "consolidado") {
          const {totalElements: facturasTotal} = await reporteFacturasEmitidas([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: 1});
          const {totalElements: tiquetesTotal} = await reporteTiquetesEmitidos([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: 1});
          const {totalElements: notasTotal} = await reporteNotasEmitidas([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: 1});
          const {content: facturas} = facturasTotal ? await reporteFacturasEmitidas([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: facturasTotal}) : {content: []};
          const {content: tiquetes} = tiquetesTotal ? await reporteTiquetesEmitidos([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: tiquetesTotal}) : {content: []};
          const {content: notas} = notasTotal ? await reporteNotasEmitidas([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: notasTotal}) : {content: []};
          const facturaArray = facturas.map(item => [
            localStringDate(item.fechaEmision), `# ${item.clave}`, `# ${item.numeroConsecutivo}`, item.activa ? "" : "Anulada mediante nota",
            item.codigoActividad, item.receptorNombre ?? "", `ID ${item.receptorIdentificacion ?? item.receptorIdentificacionExtranjero ?? ""}`,
            Number(item.resumenFactura.totalExento), Number(item.resumenFactura.totalExonerado), Number(item.resumenFactura.totalGravado),
            Number(item.resumenFactura.totalVenta), Number(item.resumenFactura.totalDescuentos), Number(item.resumenFactura.totalVentaNeta),
            Number(item.resumenFactura.totalImpuesto), Number(item.resumenFactura.totalComprobante)
          ]);
          const tiqueteArray = tiquetes.map(item => [
            localStringDate(item.fechaEmision), `# ${item.clave}`, `# ${item.numeroConsecutivo}`, item.activa ? "" : "Anulada mediante nota",
            item.codigoActividad, "", "", Number(item.resumenFactura.totalExento), Number(item.resumenFactura.totalExonerado),
            Number(item.resumenFactura.totalGravado), Number(item.resumenFactura.totalVenta), Number(item.resumenFactura.totalDescuentos),
            Number(item.resumenFactura.totalVentaNeta), Number(item.resumenFactura.totalImpuesto), Number(item.resumenFactura.totalComprobante)
          ]);
          const notaArray = notas.map(item => [
            localStringDate(item.fechaEmision), `# ${item.clave}`, `# ${item.numeroConsecutivo}`, `# ${item.informacionReferencia[0].numero.substring(21, 41)}`,
            item.codigoActividad, item.receptorNombre ?? "", `ID ${item.receptorIdentificacion ?? item.receptorIdentificacionExtranjero ?? ""}`,
            Number(item.resumenFactura.totalExento), Number(item.resumenFactura.totalExonerado), Number(item.resumenFactura.totalGravado),
            Number(item.resumenFactura.totalVenta), Number(item.resumenFactura.totalDescuentos), Number(item.resumenFactura.totalVentaNeta),
            Number(item.resumenFactura.totalImpuesto), Number(item.resumenFactura.totalComprobante)
          ]);
          const totales = ["", "", "", "", "", "", "", 0, 0, 0, 0, 0, 0, 0, 0];
          for (const item of facturaArray) {
            for (let i = 7; i < 15; i++) {
              totales[i] = addNumbers(totales[i], item[i]);
            }
          }
          for (const item of tiqueteArray) {
            for (let i = 7; i < 15; i++) {
              totales[i] = addNumbers(totales[i], item[i]);
            }
          }
          for (const item of notaArray) {
            for (let i = 7; i < 15; i++) {
              totales[i] = subtractNumbers(totales[i], item[i]);
            }
          }
          const titles = ["Fecha", "Clave", "Consecutivo", "Referencia", "Actividad", "Cliente", "Identificaci\u00F3n", "Exento", "Exonerado", "Gravado", "Venta", "Descuentos", "Venta Neta", "Impuestos", "Total"];
          const separador = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
          let reporte = [];
          if (facturaArray.length) {
            reporte.push(["Facturas", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
            reporte.push(titles)
            reporte = reporte.concat(facturaArray);
            reporte.push(separador);
          }
          if (tiqueteArray.length) {
            reporte.push(["Tiquetes", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
            reporte.push(titles)
            reporte = reporte.concat(tiqueteArray);
            reporte.push(separador);
          }
          if (notaArray.length) {
            reporte.push(["Notas", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
            reporte.push(titles)
            reporte = reporte.concat(notaArray);
            reporte.push(separador);
          }
          reporte.push(["Totales", "", "", "", "", "", "", "Exento", "Exonerado", "Gravado", "Venta", "Descuentos", "Venta Neta", "Impuestos", "Total"]);
          reporte.push(totales);
          pageHandler.item.setDownload(reporte);
        } else {
          const response = await reportes[pageHandler.item.tipoReporte.value].reporte([sucursal], pageHandler.item.fechaInicio.getDate(), fechaFinal, {page: 0, size: pageHandler.totalItems});
          const items = reportes[pageHandler.item.tipoReporte.value].mapItems(response.content);
          pageHandler.item.setDownload(items);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    downloadData();
  }

  if (sucursal) {
    return (
      <Container className="full-container">
        <Modal size="lg" centered show={pageHandler.item.showModal} onHide={() => pageHandler.item.setShowModal(false)} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="modalTitle">Descargar Reporte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomSelectField id="tipoArchivo" label="Tipo de Archivo" input={pageHandler.item.tipoArchivo.select} options={pageHandler.item.tipoArchivo.options} />
            <CustomTextField id="nombreArchivo" label="Nombre de archivo" input={pageHandler.item.nombreArchivo.input} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.item.setShowModal(false)}>Cerrar</Button>
            <Button variant="primary" onClick={descargarReporte}>Descargar</Button>
          </Modal.Footer>
        </Modal>
        {pageHandler.item.download ? (
          <table className="display-none" id="tablaReporte">
            <tbody>
              {pageHandler.item.download.map((row, rowIndex) => <tr key={`row_${rowIndex}`}>
                {row.map((cell, cellIndex) => <th key={`cell_${rowIndex}_${cellIndex}`}>{cell}</th>)}
              </tr>)}
            </tbody>
          </table> 
        ) : null}
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <Form noValidate>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={5}>
              <CustomSelectField id="tipoReporte" label="Tipo de Reporte" input={pageHandler.item.tipoReporte.select} options={pageHandler.item.tipoReporte.options} onClick={cleanReporte} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={2}>
              <CustomDatePicker id="fechaInicio" label="Fecha de Inicio" placeholder="Seleccionar Fecha" handler={pageHandler.item.fechaInicio.datePicker} maxDate={pageHandler.item.fechaFinal.selected ? pageHandler.item.fechaFinal.selected : null} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={2}>
              <CustomDatePicker id="fechaFinal" label="Fecha Final" placeholder="Seleccionar Fecha" handler={pageHandler.item.fechaFinal.datePicker} minDate={pageHandler.item.fechaInicio.selected ? pageHandler.item.fechaInicio.selected : null} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={3}>
              <Form.Group controlId="generarReporte">
                <Form.Label>&nbsp;</Form.Label>
                <div className="display-block">
                  <Button variant="primary" onClick={generarReporte} disabled={pageHandler.isLoading || pageHandler.item.fechaInicio.empty || pageHandler.item.fechaFinal.empty}>Generar</Button>
                  <Button variant="secondary ml-10" onClick={() => pageHandler.item.setShowModal(true)} disabled={pageHandler.isLoading || pageHandler.items.length === 0}>Descargar</Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {
          pageHandler.items.length > 0 ? (
            <>
              <CustomPagination pageHandler={pageHandler} loadItems={loadItems} showButton={false} badgeLabel={pageLabels.totalItems} showBadge />
              {reportes[pageHandler.item.tipoReporte.value].componente(controller.session.width, pageHandler.items)}
              <CustomPagination pageHandler={pageHandler} loadItems={loadItems} showButton={false} />
            </>
          ) : null
        }
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>);
}