import {useState, useRef} from "react";
import {useFormInput, useFormSelect, useFormCheck, useIdentificacion, useTelefono, useUbicacion, useBuscarPersona, useCodigoActividad} from "./index";
import {ambientesHacienda, estadosCuenta} from "../constants";
import {setBoolean} from "../helpers";

export const useCuenta = () => {
  const [id, setId] = useState(0);
  const codigoActividad = useCodigoActividad();
  const buscarPersona = useBuscarPersona();
  const nombre = useFormInput("");
  const nombreComercial = useFormInput("");
  const usuario = useFormInput("");
  const contrasena = useFormInput("");
  const pin = useFormInput("");
  const cantSucursales = useFormInput("");
  const cantUsuario = useFormInput("");
  const precio = useFormInput("");
  const correoElectronico = useFormInput("");
  const ultimoMes = useFormInput("");
  const mostrarMsj = useFormInput("0");
  const enviarEmailTiquete = useFormCheck(false);
  const enviarEmailNotaCredito = useFormCheck(false);
  const enviarEmailMensajeReceptor = useFormCheck(false);
  const isProduction = useFormSelect(true, ambientesHacienda);
  const activo = useFormSelect(true, estadosCuenta);
  const identificacion = useIdentificacion();
  const telefono = useTelefono();
  const ubicacion = useUbicacion();
  const actividades = useRef([]);

  const reset = () => {
    setId(0);
    codigoActividad.reset();
    nombre.setValue("");
    nombreComercial.setValue("");
    usuario.setValue("");
    contrasena.setValue("");
    pin.setValue("");
    cantSucursales.setValue("");
    cantUsuario.setValue("");
    precio.setValue("");
    correoElectronico.setValue("");
    ultimoMes.setValue("");
    mostrarMsj.setValue("0");
    enviarEmailTiquete.setValue(false);
    enviarEmailNotaCredito.setValue(false);
    enviarEmailMensajeReceptor.setValue(false);
    isProduction.setValue(true);
    activo.setValue(true);
    identificacion.reset();
    telefono.reset();
    ubicacion.reset();
    actividades.current = [];
  }

  const setObject = (data) => {
    nombre.setValue(String(data.nombre ?? ""));
    nombreComercial.setValue(String(data.nombreComercial ?? ""));
    usuario.setValue(String(data.usuario ?? ""));
    contrasena.setValue(String(data.contrasena ?? ""));
    pin.setValue(String(data.pin ?? ""));
    cantSucursales.setValue(String(data.cantSucursales ?? ""));
    cantUsuario.setValue(String(data.cantUsuario ?? ""));
    precio.setValue(String(data.precio ?? ""));
    correoElectronico.setValue(String(data.correoElectronico ?? ""));
    ultimoMes.setValue(String(data.ultimoMes ?? ""));
    mostrarMsj.setValue(String(data.mostrarMsj ?? "0"));
    enviarEmailTiquete.setValue(setBoolean(data.enviarEmailTiquete));
    enviarEmailNotaCredito.setValue(setBoolean(data.enviarEmailNotaCredito));
    enviarEmailMensajeReceptor.setValue(setBoolean(data.enviarEmailMensajeReceptor));
    isProduction.setValue(setBoolean(data.isProduction));
    activo.setValue(setBoolean(data.activo));
    identificacion.setObject(data.identificacion ?? {});
    telefono.setObject(data.telefono ?? {}); 
    ubicacion.setObject(data.ubicacion ?? {});
    actividades.current = [];
  }

  const setBusqueda = () => {
    nombre.setValue(String(buscarPersona.nombre ?? nombre.value));
    identificacion.setNumero(String(buscarPersona.numeroIdentificacion ?? identificacion.numero.value));
    identificacion.setTipoIdentificacion(String(buscarPersona.tipoIdentificacion ?? identificacion.tipoIdentificacion.value));
    correoElectronico.setValue(String(buscarPersona.correoElectronico !== "--" ? buscarPersona.correoElectronico : correoElectronico.value));
    actividades.current = buscarPersona.actividades?.filter(actividad => actividad.agregar);
  }

  return {
    getObject: {
      nombre: nombre.value,
      nombreComercial: nombreComercial.value,
      usuario: usuario.value,
      contrasena: contrasena.value,
      pin: pin.value,
      cantSucursales: cantSucursales.value,
      cantUsuario: cantUsuario.value,
      precio: precio.value,
      correoElectronico: correoElectronico.value,
      ultimoMes: ultimoMes.value,
      mostrarMsj: mostrarMsj.value,
      enviarEmailTiquete: enviarEmailTiquete.value,
      enviarEmailNotaCredito: enviarEmailNotaCredito.value,
      enviarEmailMensajeReceptor: enviarEmailMensajeReceptor.value,
      isProduction: isProduction.value,
      activo: activo.value,
      identificacion: identificacion.getObject,
      telefono: telefono.getObject,
      ubicacion: ubicacion.getObject
    },
    actividades: actividades.current,
    id,
    nombre,
    nombreComercial,
    usuario,
    contrasena,
    pin,
    cantSucursales,
    cantUsuario,
    precio,
    correoElectronico,
    ultimoMes,
    mostrarMsj,
    enviarEmailTiquete,
    enviarEmailNotaCredito,
    enviarEmailMensajeReceptor,
    isProduction,
    activo,
    identificacion,
    telefono,
    ubicacion,
    codigoActividad,
    buscarPersona,
    setId,
    reset,
    setObject,
    setBusqueda
  };
}