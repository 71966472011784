import {useEffect} from "react";
import {useConsecutivo as useItem, usePageHandler} from "../customHooks";
import {listRangoRequest, updateRangoRequest, initForm} from "../helpers";
import {Container, Button, Card, ListGroup, Form, CustomAlert} from "../components";
const pageLabels = {itemUpdated: "Consecutivos actualizados", genericError: "Ha ocurrido un error"};

export const ConsecutivoPage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const sucursal = controller.usuario.sucursal.get;

  useEffect(() => {
    if (sucursal) {
      pageHandler.item.reset();
      loadItems();
    }
  }, [sucursal]);

  const loadItems = async() => {
    try {
      pageHandler.initProcess(true);
      const response = await listRangoRequest(sucursal);
      if (pageHandler.isMounted) {
        pageHandler.item.setObject(response[0]);
        pageHandler.setIsLoading(false);
      }
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          await updateRangoRequest(pageHandler.item.getObject, pageHandler.item.id);
          if (pageHandler.isMounted) {
            pageHandler.item.setLockFields(true)
          }
          pageHandler.handleAlert(pageLabels.itemUpdated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError);
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  }

  const getInput = (label, input) => (
    <ListGroup.Item>
      {label}:
      <Form.Control
        className={`display-inline auto-size ml-10 ${pageHandler.item.lockFields ? "" : "float-right"}`} disabled={pageHandler.item.lockFields}
        plaintext={pageHandler.item.lockFields} required type="text" placeholder="Facturas" {...input}
      />
    </ListGroup.Item>
  );

  if (sucursal) {
    return (
      <Container className="mid-container">
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <Form inline noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
          <Card className="full-size">
            <Card.Header className="text-center">Consecutivos</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {getInput('Facturas', pageHandler.item.facturaElectronica.input)}
                {getInput('Facturas de Compra', pageHandler.item.facturaElectronicaCompra.input)}
                {getInput('Facturas de Exportaci\u00f3n', pageHandler.item.facturaElectronicaExportacion.input)}
                {getInput('Tiquetes', pageHandler.item.tiqueteElectronico.input)}
                {getInput('Notas de Cr\u00e9dito', pageHandler.item.notaCreditoElectronica.input)}
                {getInput('Notas de D\u00e9bito', pageHandler.item.notaDebitoElectronica.input)}
                {getInput('Mensajes de Receptor', pageHandler.item.mensajeReceptor.input)}
                {getInput('Mensajes de Hacienda', pageHandler.item.mensajeHacienda.input)}
              </ListGroup>
            </Card.Body>
            <Card.Footer className="text-center">
              <Button variant="danger" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.setLockFields(!pageHandler.item.lockFields)}>
                  {pageHandler.item.lockFields ? "Editar Consecutivos" : "Cancelar"}
              </Button>
              {
                pageHandler.item.lockFields ? null : (
                  <Button className="ml-10" variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
                )
              }
            </Card.Footer>
          </Card>
        </Form>
      </Container>
    );
  }

  return (
    <Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>
  );
}