import {useState} from "react";
import {useFormInput, useFormSelect, useBuscarPersona} from "./index";
import {tiposCodigosActividad} from "../constants";

export const useCodigoActividad = (cuentaId) => {
  const buscarPersona = useBuscarPersona();
  const [id, setId] = useState(0);
  const nombre = useFormInput("");
  const codigoActividad = useFormInput("");
  const isPrincipal = useFormSelect("P", tiposCodigosActividad);

  const reset = () => {
    setId(0);
    nombre.setValue("");
    codigoActividad.setValue("");
    isPrincipal.setValue("P");
  }

  const setObject = (data) => {
    nombre.setValue(String(data.nombre || ""));
    codigoActividad.setValue(String(data.codigoActividad || ""));
    isPrincipal.setValue(String(data.isPrincipal || "P"));
  }

  const setBusqueda = (data) => {
    nombre.setValue(String(data.descripcion || nombre.value));
    codigoActividad.setValue(String(data.codigo || codigoActividad.value));
    isPrincipal.setValue(String(tiposCodigosActividad().find(item => item.text === data.tipo)?.value || "P"));
  }

  const getFromBusqueda = (data, cuenta) => ({
      nombre: data.descripcion || "",
      codigoActividad: data.codigo || "",
      isPrincipal: tiposCodigosActividad().find(item => item.text === data.tipo)?.value || "P",
      cuentaId: cuenta
    })

  return {
    getObject: {
      nombre: nombre.value,
      codigoActividad: codigoActividad.value,
      isPrincipal: isPrincipal.value,
      cuentaId
    },
    id,
    nombre,
    codigoActividad,
    isPrincipal,
    buscarPersona,
    setId,
    reset,
    setObject,
    setBusqueda,
    getFromBusqueda,
  };
}
