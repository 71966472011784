import {useFormSelect} from "../customHooks";

const printSizes = [
  {key: "1ps", text: "POS-7.6cm", value: "01"},
  {key: "2ps", text: "POS-22cm", value: "03"},
  {key: "3ps", text: "CARTA", value: "02"}
];

const fontSizes = [
  {key: "1fs", text: "Mediano", value: "01"},
  {key: "2fs", text: "Grande", value: "02"},
  {key: "3fs", text: "Extra Grande", value: "03"}
];

const logoSizes = [
  {key: "1ls", text: "Logo Mediano", value: "01"},
  {key: "2ls", text: "Logo Grande", value: "02"},
  {key: "3ls", text: "Logo Extra Grande", value: "03"}
];

const logoProporciones = [
  {key: "1lp", text: "Logo Cuadrado", value: "01"},
  {key: "2lp", text: "Logo Largo", value: "02"}
];

const tipoPrecios = [
  {key: "1tp", value: "subTotal", text: "Subtotal (Sin Impuestos)"},
  {key: "2tp", value: "total", text: "Total (Incluyendo Impuestos)"}
];

const tipoDescuentos = [
  {key: "1td", value: "porcentaje", text: "Porcentaje"},
  {key: "2td", value: "neto", text: "Monto Neto"}
];

const numeroDecimales = [
  {key: "1cd", value: "0", text: "Sin decimales (6)"},
  {key: "2cd", value: "1", text: "1 decimal (5.6)"},
  {key: "3cd", value: "2", text: "2 decimales (5.56)"},
  {key: "4cd", value: "3", text: "3 decimales (5.556)"},
  {key: "5cd", value: "4", text: "4 decimales (5.5556)"},
  {key: "6cd", value: "5", text: "5 decimales (5.55556)"},
  {key: "7cd", value: "auto", text: "Din\u00E1mico (los necesarios)"}
];

const numeroFormatos = [
  {key: "1nf", value: "number", text: "Notaci\u00F3n de n\u00FAmero"},
  {key: "2nf", value: "de-DE", text: "Coma para decimales y punto para miles"},
  {key: "3nf", value: "en-US", text: "Punto para decimales y coma para miles"}
];

export const useConfiguracion = () => {
  const printSize = useFormSelect("01", () => printSizes);
  const fontSize = useFormSelect("01", () => fontSizes);
  const logoSize = useFormSelect("02", () => logoSizes);
  const logoProporcion = useFormSelect("01", () => logoProporciones);
  const tipoPrecio = useFormSelect("subTotal", () => tipoPrecios);
  const tipoDescuento = useFormSelect("porcentaje", () => tipoDescuentos);
  const cantidadDecimales = useFormSelect("auto", () => numeroDecimales);
  const numeroFormato = useFormSelect("number", () => numeroFormatos);

  const configuraciones = {
    printSize: {item: printSize, default: "01"},
    fontSize: {item: fontSize, default: "01"},
    logoSize: {item: logoSize, default: "02"},
    logoProporcion: {item: logoProporcion, default: "01"},
    tipoPrecio: {item: tipoPrecio, default: "subTotal"},
    tipoDescuento: {item: tipoDescuento, default: "porcentaje"},
    cantidadDecimales: {item: cantidadDecimales, default: "auto"},
    numeroFormato: {item: numeroFormato, default: "number"}
  };

  const setObject = (data) => {
    const alreadySet = [];
    if (data && data.length) {
      data.forEach(config => {
        const [key, value] = config.split("&");
        alreadySet.push(key);
        configuraciones[key]?.item.setValue(value);
      });
    }
    Object.keys(configuraciones).forEach(key => {
      if (!alreadySet.includes(key)) {
        configuraciones[key].item.setValue(configuraciones[key].default);
      }
    });
  }

  const getObject = () => ({
    features: [
      `printSize&${printSize.value}`,
      `fontSize&${fontSize.value}`,
      `logoSize&${logoSize.value}`,
      `logoProporcion&${logoProporcion.value}`,
      `tipoPrecio&${tipoPrecio.value}`,
      `tipoDescuento&${tipoDescuento.value}`,
      `cantidadDecimales&${cantidadDecimales.value}`,
      `numeroFormato&${numeroFormato.value}`
    ]
  })

  return {
    values: {
      printSize: printSize.value,
      fontSize: fontSize.value,
      logoSize: logoSize.value,
      logoProporcion: logoProporcion.value,
      tipoPrecio: tipoPrecio.value,
      tipoDescuento: tipoDescuento.value,
      cantidadDecimales: cantidadDecimales.value,
      numeroFormato: numeroFormato.value
    },
    printSize,
    fontSize,
    logoSize,
    logoProporcion,
    tipoPrecio,
    tipoDescuento,
    cantidadDecimales,
    numeroFormato,
    setObject,
    getObject
  };
}