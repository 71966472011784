import {useState} from "react";
import {useFormInput, useTelefono, useUbicacion} from "./index";

export const useSucursal = () => {
  const [id, setId] = useState(0);
  const nombre = useFormInput("");
  const numeroSucursal = useFormInput("");
  const correoElectronico = useFormInput("");
  const telefono = useTelefono();
  const ubicacion = useUbicacion();

  const reset = () => {
    setId(0);
    nombre.setValue("");
    numeroSucursal.setValue("");
    correoElectronico.setValue("");
    telefono.reset();
    ubicacion.reset();
  }

  const setObject = (data) => {
    nombre.setValue(String(data.nombre || ""));
    numeroSucursal.setValue(String(data.numeroSucursal || ""));
    correoElectronico.setValue(String(data.correoElectronico || ""));
    telefono.setObject(data.telefono || {});
    ubicacion.setObject(data.ubicacion || {});
  }

  return {
    getObject: {
      nombre: nombre.value,
      numeroSucursal: numeroSucursal.value,
      correoElectronico: correoElectronico.value,
      telefono: telefono.getObject,
      ubicacion: ubicacion.getObject
    },
    id,
    nombre,
    numeroSucursal,
    correoElectronico,
    telefono,
    ubicacion,
    setId,
    reset,
    setObject
  };
}