import {useEffect} from "react";
import {useCliente as useItem, usePageHandler} from "../customHooks";
import {
  createClienteRequest as createRequest, updateClienteRequest as updateRequest, deleteClienteRequest as deleteRequest,
  listClienteRequest as listRequest, initForm, haciendaConsultarContribuyente, haciendaConsultarCorreo
} from "../helpers";
import {
  Container, Button, Modal, Form, Col, Row, Table, Card, ListGroup, CustomAlert, CustomTextField, CustomSelectField,
  CustomPagination, CustomTableHeader, Accordion
} from "../components";
const pageLabels = {
  itemCreate: "Crear cliente", itemUpdate: "Modificar cliente", itemDelete: "Eliminar cliente", itemCreated: "Cliente creado",
  itemUpdated: "Cliente modificado", itemDeleted: "Cliente eliminado", totalItems: "Clientes", genericError: "Ha ocurrido un error"
};

export const ClientePage = ({controller}) => {
  const pageHandler = usePageHandler(controller, useItem);
  const cuenta = controller.usuario.cuenta.get;

  useEffect(() => {
    if (cuenta) {
      pageHandler.setOffSet(0);
      loadItems(0, pageHandler.limit, pageHandler.filter);
    }
  }, [cuenta]);

  const loadItems = async(page, size, sort, searchObject) => {
    try {
      pageHandler.initProcess(true);
      const response = await listRequest(cuenta, {page, size, sort}, searchObject);
      pageHandler.handleLoadItems(response);
    } catch(error) {
      pageHandler.handleErrors(error, pageLabels.genericError);
    }
  }

  const handleSave = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const save = async() => {
        try {
          pageHandler.initProcess(true);
          if (pageHandler.modalAction === "create") {
            await createRequest(pageHandler.item.getObject, cuenta);
          } else {
            await updateRequest(pageHandler.item.getObject, pageHandler.item.id);
          }
          await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
          pageHandler.handleSaveItem(pageHandler.modalAction === "create" ? pageLabels.itemCreated : pageLabels.itemUpdated);
        } catch(error) {
          pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
        }
      }
      save();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const handleDelete = () => {
    const remove = async() => {
      try {
        await deleteRequest(pageHandler.item.id);
        await loadItems(pageHandler.offSet, pageHandler.limit, pageHandler.filter);
        pageHandler.handleDeleteItem(pageLabels.itemDeleted);
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError);
      }
    }
    remove();
  }

  const closeModal = () => {
    pageHandler.setOpenModal(false);
    pageHandler.item.buscarPersona.cleanHaciendaSearch();
  }

  const handleSearch = () => {
    const search = async() => {
      try {
        pageHandler.initProcess(true);
        const searchResult = await haciendaConsultarContribuyente(pageHandler.item.buscarPersona.searchInput.value);
        const {Resultado} = await haciendaConsultarCorreo(pageHandler.item.buscarPersona.searchInput.value);
        if (pageHandler.isMounted) {
          pageHandler.item.buscarPersona.setObject(searchResult, Resultado?.Correos?.[0]?.Correo);
          pageHandler.setIsLoading(false);
        }
      } catch(error) {
        pageHandler.handleErrors(error, pageLabels.genericError, true, "guardarTitle");
      } 
    }
    search();
  }

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const handleSeleccionar = () => {
    if (pageHandler.isMounted) {
      pageHandler.item.setBusqueda();
      pageHandler.item.buscarPersona.cleanHaciendaSearch();
    }
  }

  const executeSearch = (searchObject = {}) => {
    pageHandler.setSearchObject(searchObject);
    pageHandler.setOffSet(0);
    loadItems(0, pageHandler.limit, pageHandler.filter, searchObject);
  }

  const handleBuscar = () => {
    if (pageHandler.isMounted) {
      const searchObject = {text: pageHandler.textoBusqueda.value};
      executeSearch(searchObject);
    }
  }

  const handleLimpiar= () => {
    if (pageHandler.isMounted) {
      pageHandler.textoBusqueda.setValue("");
      executeSearch();
    }
  }

  if (cuenta) {
    const collapse = controller.session.width < 1000;

    return (
      <Container className="full-container">
        <Modal centered animation={false} show={pageHandler.modalAction === "delete"} onHide={() => pageHandler.setModalAction("")}>
          <Modal.Header closeButton><Modal.Title>{pageLabels.itemDelete}</Modal.Title></Modal.Header>
          <Modal.Body>Confirmar esta acci&oacute;n</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.setModalAction("")}>Cerrar</Button>
            <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" centered show={pageHandler.openModal} onHide={closeModal} animation={false}>
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleSave : null} onKeyDown={pageHandler.preventEnterKey}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">{pageHandler.modalAction === "create" ? pageLabels.itemCreate : pageLabels.itemUpdate}</Modal.Title>
              <Button className="ml-20" variant="primary" disabled={pageHandler.isLoading} onClick={() => pageHandler.item.buscarPersona.cleanHaciendaSearch(!pageHandler.item.buscarPersona.haciendaSearch)}>
                {pageHandler.item.buscarPersona.haciendaSearch ? "Regresar" : <>Buscar por n&uacute;mero de identificaci&oacute;n</>}
              </Button>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert type="danger" dismissible body={pageHandler.formAlert} show={pageHandler.showFormAlert} onClose={pageHandler.setShowFormAlert} />
              {
                pageHandler.item.buscarPersona.haciendaSearch ? (
                  <Row>
                    <Col lg={5}>
                      <CustomTextField id="searchIdentificacion" label="N&uacute;mero de Identificaci&oacute;n" input={pageHandler.item.buscarPersona.searchInput.input} onKeyDown={handleSearchEnter}/>
                    </Col>
                    <Col lg={7}>
                      <Row className="ml-0"><strong>Nombre:</strong>&nbsp;{pageHandler.item.buscarPersona.nombre}</Row>
                      <Row className="ml-0"><strong>Tipo de Identificaci&oacute;n:</strong>&nbsp;{pageHandler.item.buscarPersona.getTipoIdentificacion()}</Row>
                      <Row className="ml-0"><strong>N&uacute;mero de Identificaci&oacute;n:</strong>&nbsp;{pageHandler.item.buscarPersona.numeroIdentificacion}</Row>
                      <Row className="ml-0"><strong>R&eacute;gimen:</strong>&nbsp;{pageHandler.item.buscarPersona.regimen}</Row>
                      <Row className="ml-0"><strong>Administraci&oacute;n Tributaria:</strong>&nbsp;{pageHandler.item.buscarPersona.administracion}</Row>
                      <Row className="ml-0"><strong>Estado:</strong>&nbsp;{pageHandler.item.buscarPersona.estado}</Row>
                      <Row className="ml-0"><strong>Correo Electr&oacute;nico:</strong>&nbsp;{pageHandler.item.buscarPersona.correoElectronico}</Row>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col lg>
                      <CustomTextField required id="itemNombre" label="Nombre" input={pageHandler.item.nombre.input} invalid="Este campo es requerido" />
                      <CustomTextField id="itemNombreComercial" label="Nombre Comercial" input={pageHandler.item.nombreComercial.input} invalid="Este campo es requerido" />
                      <CustomSelectField id="itemTipoIdentificacion" label="Tipo de Identificaci&oacute;n" input={pageHandler.item.identificacion.tipoIdentificacion} options={pageHandler.item.identificacion.tiposIdentificacion} />
                      <CustomTextField required id="itemNumeroIdentificacion" label="N&uacute;mero de Identificaci&oacute;n" input={pageHandler.item.identificacion.numero} invalid="Este campo es requerido" />
                      <CustomTextField id="itemIdentificacionExtranjero" label="Identificaci&oacute;n de Extranjero" input={pageHandler.item.identificacionExtranjero.input} />
                      <CustomSelectField id="itemCodigoTelefono" label="C&oacute;digo de Pa&iacute;s" input={pageHandler.item.telefono.codigoPais} options={pageHandler.item.telefono.codigosPais} />
                      <CustomTextField id="itemNumeroTelefono" label="N&uacute;mero de Tel&eacute;fono" input={pageHandler.item.telefono.numTelefono} invalid="Este campo es requerido" />
                    </Col>
                    <Col lg>
                      <CustomSelectField id="itemProvincia" label="Provincia" input={pageHandler.item.ubicacion.provincia} options={pageHandler.item.ubicacion.provincias} />
                      <CustomSelectField id="itemCanton" label="Cant&oacute;n" input={pageHandler.item.ubicacion.canton} options={pageHandler.item.ubicacion.cantones} />
                      <CustomSelectField id="itemDistrito" label="Distrito" input={pageHandler.item.ubicacion.distrito} options={pageHandler.item.ubicacion.distritos} />
                      <CustomSelectField id="itemBarrio" label="Barrio" input={pageHandler.item.ubicacion.barrio} options={pageHandler.item.ubicacion.barrios} />
                      <CustomTextField required id="itemDireccion" label="Direcci&oacute;n Exacta" input={pageHandler.item.ubicacion.otrasSenas} invalid="Este campo es requerido" />
                      <CustomTextField id="itemCorreo" label="Correo Electr&oacute;nico" input={pageHandler.item.correoElectronico.input} invalid="Formato de correo incorrecto" />
                    </Col>
                  </Row>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal} disabled={pageHandler.isLoading}>Cerrar</Button>
              {
                pageHandler.item.buscarPersona.haciendaSearch
                ? pageHandler.item.buscarPersona.nombre !== "--"
                ? <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSeleccionar : null}>Seleccionar</Button>
                : <Button variant="primary" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleSearch : null}>{pageHandler.isLoading ? "Buscando…" : "Buscar"}</Button>
                : <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Guardar"}</Button>
              }
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert type={pageHandler.alertType} dismissible body={pageHandler.alert} show={pageHandler.showAlert} onClose={pageHandler.setShowAlert} />
        <Row>
          <Accordion className="full-size">
            <Accordion.Toggle as={Button} variant="link" eventKey="0">B&uacute;squeda de Clientes</Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Col>
                <Row>
                  <Col xs={12} sm={7} md={6} lg={5} xl={4}>
                    <CustomTextField required id="searchText" placeholder="Texto de b&uacute;squeda" input={pageHandler.textoBusqueda.input} />
                  </Col>
                  <Col>
                    <Button variant="primary" className="mr-10 mb-10" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleBuscar : null}>Buscar</Button>
                    <Button variant="secondary" className="mb-10" disabled={pageHandler.isLoading} onClick={!pageHandler.isLoading ? handleLimpiar : null}>Limpiar</Button>
                  </Col>
                </Row>
              </Col>
            </Accordion.Collapse>
          </Accordion>
        </Row>
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} badgeLabel={pageLabels.totalItems} showBadge={collapse} />
        {
          collapse ? (
            pageHandler.items.map((element) => 
              <Row className="m-0" key={element.id}>
                <Card border="secondary" className="full-size mb-10">
                  <Card.Header className="font-bold">Nombre: {element.nombre}</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Identificaci&oacute;n: {element.identificacion.numero}</ListGroup.Item>
                    <ListGroup.Item>Correo Electr&oacute;nico: {element.correoElectronico}</ListGroup.Item>
                    {pageHandler.isSuperAdmin ? <ListGroup.Item>ID: {element.id}</ListGroup.Item> : null}
                    <ListGroup.Item>
                      <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                      <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Row>
            )
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="id" headerLabel="ID" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="identificacion" headerLabel="Identificaci&oacute;n" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="nombre" headerLabel="Nombre" />
                  <CustomTableHeader pageHandler={pageHandler} loadItems={loadItems} headerName="correoElectronico" headerLabel="Correo Electr&oacute;nico" />
                  <th className="width-232">{pageLabels.totalItems}: {pageHandler.totalItems}</th>
                </tr>
              </thead>
              <tbody>
                {
                  pageHandler.items.map((element) =>
                    <tr key={element.id}>
                      <td>{element.id}</td>
                      <td>{element.identificacion.numero}</td>
                      <td>{element.nombre}</td>
                      <td>{element.correoElectronico}</td>
                      <td>
                        <Button variant="primary" onClick={() => pageHandler.openUpdateModal(element)}>Modificar</Button>
                        <Button variant="danger" className="ml-10" onClick={() => pageHandler.openDeleteModal(element.id)}>Eliminar</Button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          )
        }
        <CustomPagination pageHandler={pageHandler} loadItems={loadItems} buttonLabel={pageLabels.itemCreate} />
      </Container>
    );
  }
  return (<Container className="full-container"><CustomAlert type="warning" body="Seleccione una cuenta" show /></Container>);
}