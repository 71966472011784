import {tiposLinea, codigosImpuesto, tarifasIVA, unidadesMedida} from ".";

export const productoFields = () => [
  {key: "1pf", text: "Tipo", value: "isProducto", title: "tipo", selected: false, equalTo: "producto", input: "select", selectOptions: tiposLinea(), general: false, generalValue: "opcionGeneral", editInput: "select", required: true, dependency: false},
  {key: "2pf", text: "Detalle", value: "detalle", title: "detalle", selected: false, input: false, max: 200, editInput: "text", required: true, dependency: false},
  {key: "3pf", text: "C\u00f3digo Cabys", value: "codigo", title: "cabys", selected: false, input: false, number: true, length: 13, editInput: "text", required: true, dependency: false},
  {key: "4pf", text: "C\u00f3digo Interno", value: "codigoComercial", title: "interno", selected: false, input: false, max: 20, editInput: "text", required: true, dependency: false},
  {key: "5pf", text: "Stock", value: "stock", title: "stock", selected: false, input: "text", general: false, generalValue: "", number: true, editInput: "text", required: true, dependency: false},
  {key: "6pf", text: "Precio Minorista", value: "precioUnitarioMinorista", title: "minorista", selected: false, input: "text", general: false, generalValue: "", number: true, editInput: "text", required: true, dependency: false},
  {key: "7pf", text: "Precio Mayorista", value: "precioUnitarioMayorista", title: "mayorista", selected: false, input: "text", general: false, generalValue: "", number: true, editInput: "text", dependency: false},
  {key: "8pf", text: "Tipo de Impuesto", value: "impuestoCodigoImpuesto", title: "impuesto", selected: false, input: "select", selectOptions: codigosImpuesto(), general: false, generalValue: "opcionGeneral", editInput: "select", required: true, dependency: false},
  {key: "9pf", text: "Tipo de Tarifa", value: "impuestoCodigoTarifa", title: "tarifa", selected: false, input: "select", selectOptions: tarifasIVA(), general: false, generalValue: "opcionGeneral", editInput: "select", dependency: true},
  {key: "10pf", text: "Tarifa de Impuesto", value: "impuestoTarifa", title: "porcentaje", selected: false, input: "text", general: false, generalValue: "", number: true, editInput: "text", dependency: true},
  {key: "11pf", text: "Factor IVA", value: "impuestoFactorIVA", title: "factor", selected: false, input: "text", general: false, generalValue: "", number: true, editInput: "text", dependency: true},
  {key: "12pf", text: "Unidad de medida", value: "unidadMedida", title: "unidad", selected: false, input: "select", selectOptions: unidadesMedida(), general: false, generalValue: "opcionGeneral", editInput: "select", required: true, dependency: false}
];
