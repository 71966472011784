import {insert, select} from "./storage";
import {isJson} from "./methods";
const api = "https://api.bluespartantechnologies.com/api";
const authorization = window.btoa(unescape(encodeURIComponent("blueS:thisIsSparta")));

export const createParams = (params) => `?${Object.entries(params).map(([key, value]) => `${key}=${value}`).join("&")}`;

export const createBody = (data_array) => {
  const body = new FormData();
  Object.entries(data_array).map(([key, value]) => body.append(key, value));
  return body;
}

export const createHeaders = async(bearer, jsonType = false) => {
  try {
    const headers = new Headers();
    const tokens = await select("tokens");
    headers.append("Authorization", bearer && tokens ? `Bearer ${tokens.access_token}` : `Basic ${authorization}`);
    if (jsonType) {
      headers.append("Content-Type", "application/json");
    }
    return headers;
  } catch(error) {
    throw error;
  }
}

const refreshTokens = async(url, params) => {
  try {
    let error = "unauthorized";
    const tokens = await select("tokens");
    if (tokens) {
      const refresh = await fetch(`${api}/oauth/token`, {method: "POST", body: createBody({grant_type: "refresh_token", refresh_token: tokens.refresh_token}), headers: await createHeaders(false)});
      if (refresh.status === 200) {
        await insert("tokens", await refresh.json());
        params.headers = await createHeaders(true);
        const response = await fetch(`${api}/${url}`, params);
        if (response.status === 200) {
          const data = await response.text();
          return isJson(data) ? JSON.parse(data) : data;
        }
        error = `Status ${response.status}`;
      }
    }
    throw error;
  } catch(error) {
    throw error;
  }
}

export const request = async(url, params) => {
  try {
    const response = await fetch(`${api}/${url}`, params);
    if (response.status === 200) {
      const data = await response.text();
      return isJson(data) ? JSON.parse(data) : data;
    } else if (response.status === 401) {
      return await refreshTokens(url, params);
    }
    const error = `Status ${response.status}`;
    throw error;
  } catch(error) {
    throw error;
  }
}