import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Container} from "../components";

export const ComprobantePage = () => {
  const {id} = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="full-container">
      Mostrar comprobante {id}
    </Container>
  );
}