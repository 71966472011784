import {createHeaders, createParams, request} from "./api";

export const createClienteRequest = async(cliente, cuentaId) => {
  try {
    const response = await request(`clientes/${cuentaId}`, {method: "POST", body: JSON.stringify(cliente), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const updateClienteRequest = async(cliente, id) => {
  try {
    const response = await request(`clientes/update/${id}`, {method: "POST", body: JSON.stringify(cliente), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const searchClienteRequest = async(text, cuentaId, queryParams) => {
  try {
    const response = await request(`clientes/search/${cuentaId}${createParams(queryParams)}`, {method: "POST", body: JSON.stringify({text}), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const deleteClienteRequest = async(id) => {
  try {
    await request(`clientes/${id}`, {method: "DELETE", headers: await createHeaders(true)});
    return "Cliente eliminado";
  } catch(error) {
    throw error;
  }
}

export const getClienteRequest = async(id) => {
  try {
    const response = await request(`clientes/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listClienteRequest = async(cuentaId, queryParams, searchObject = {}) => {
  try {
    const response = await request(`clientes/${cuentaId}/cuenta${createParams(queryParams)}`, {method: "POST", body: JSON.stringify(searchObject), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}