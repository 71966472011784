import {useEffect, useState, useRef} from "react";
import {useDatePicker, useFormInput} from "./index";
import {getStringReducedFromDate} from "../helpers";

export const usePago = () => {
  const [montoCalculado, setMontoCalculado] = useState(0);
  const detalle = useFormInput("");
  const referencia = useFormInput("");
  const monto = useFormInput(0);
  const fechaInicio = useDatePicker(undefined);
  const fechaFinal = useDatePicker(undefined);
  const precio = useRef(15);

  useEffect(() => {
    let months = 0;
    if (!fechaInicio.empty && !fechaFinal.empty) {
      months = 1 + fechaFinal.selected.getMonth() - fechaInicio.selected.getMonth() + (12 * (fechaFinal.selected.getFullYear() - fechaInicio.selected.getFullYear()));
    } else if (!fechaInicio.empty || !fechaFinal.empty) {
      months = 1;
    }
    const montoMonths = months * precio.current;
    setMontoCalculado(montoMonths);
    monto.setValue(montoMonths);
  }, [fechaInicio.selected, fechaFinal.selected]);

  const reset = () => {
    setMontoCalculado(0);
    detalle.setValue("");
    referencia.setValue("");
    monto.setValue(0);
    fechaFinal.setSelected(undefined);
  }

  const getMesesCancelados = () => {
    if (!fechaInicio.empty && !fechaFinal.empty) {
      return `${getStringReducedFromDate(fechaInicio.selected)} -> ${getStringReducedFromDate(fechaFinal.selected)}`;
    } else if (!fechaInicio.empty) {
      return getStringReducedFromDate(fechaInicio.selected);
    } else if (!fechaFinal.empty) {
      return getStringReducedFromDate(fechaFinal.selected);
    }
    return "";
  }

  const getUltimoMes = () => {
    if (!fechaFinal.empty) {
      return getStringReducedFromDate(fechaFinal.selected);
    } else if (!fechaInicio.empty) {
      return getStringReducedFromDate(fechaInicio.selected);
    }
    return "";
  }

  const getObject = () => ({
    detalles: detalle.value,
    numeroReferencia: referencia.value,
    montoCancelado: monto.value,
    mesesCancelados: getMesesCancelados()
  });

  const setPrecio = (value) => precio.current = Number(value);

  return {
    montoCalculado,
    detalle,
    referencia,
    monto,
    fechaInicio,
    fechaFinal,
    getObject,
    reset,
    setPrecio,
    getUltimoMes
  };
}