import {useEffect, useRef} from "react";
import QRious from "qrious";

export const CustomQRcode = ({url, size}) => {
  const canvas = useRef(null);

  useEffect(() => {
    if (canvas != null && canvas.current != null) {
      new QRious({element: canvas.current, value: url, size: size});
    }
  });

  return(<canvas ref={canvas}/>);
}