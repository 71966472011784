import {useState, useEffect} from "react";
import {read, utils} from "xlsx";
import {useFormSelect} from ".";
import {archivosImportar, separadorArchivos, incluirHeaders} from "../constants";
import {Form, Row, Col, CustomTextField, CustomSelectField, CustomAlert, Table, Modal, Button} from "../components";

export const usePageImportHandler = (useItem, fields) => {
  const item = useItem();
  const [showInfo, setShowInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [alert, setAlert] = useState("");
  const [editIndex, setEditIndex] = useState(0);
  const [editData, setEditData] = useState({});
  const [dataArchivo, setDataArchivo] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const[tableHeaderValid, setTableHeaderValid] = useState([]); 
  const [selectValues, setSelectValues] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [tableBodyCreated, setTableBodyCreated] = useState([]);
  const [tableBodyValid, setTableBodyValid] = useState([]);
  const [tableBodyValidFields, setTableBodyValidFields] = useState([]);
  const tipoArchivo = useFormSelect(".xlsx", archivosImportar);
  const tipoSeparador = useFormSelect(",", separadorArchivos);
  const incluyeHeaders = useFormSelect(true, incluirHeaders);
  const archivoSeleccionado = archivosImportar().find(item => item.value === tipoArchivo.value);
  const allSelected = (selectValues.findIndex(item => !item) < 0);
  const allValid = tableBodyValid.find(item => !item) ?? true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arrayLength = dataArchivo.reduce((x, y) => Math.max(x, y.length), 0);
    const headers = [], body = [], validFields = [], rowIndex = Number(incluyeHeaders.value);
    const selectValuesAux = [];
    for (let i=0; i<arrayLength; i++) {
      let headerValue = "", selectValue = false;
      if (rowIndex) {
        headerValue = String(dataArchivo[0][i]);
        const optionIndex = fields.findIndex(option => option.title === headerValue.toLowerCase());
        if (optionIndex >= 0) {
          selectValue = fields[optionIndex].value;
          fields[optionIndex].selected = true;
        }
      }
      headers.push(headerValue);
      selectValuesAux.push(selectValue);
    }
    for (let i=rowIndex; i<dataArchivo.length; i++) {
      const row = [], rowValidFields = [];
      for (let j=0; j<arrayLength; j++) {
        row.push(dataArchivo[i][j] ?? "");
        rowValidFields.push(0);
      }
      body.push(row);
      validFields.push(rowValidFields);
    }
    setSelectValues(selectValuesAux);
    setSelectOptions(fields);
    setTableHeaderValid(headers.map(_ => 0));
    setTableBodyValid(body.map(_ => false));
    setTableBodyCreated(body.map(_ => false));
    setTableHeader(headers);
    setTableBody(body);
    setTableBodyValidFields(validFields);
  }, [dataArchivo]);

  const setFileData = (data) => {
    setDataArchivo(data);
    setIsLoading(false);
  }

  const funcionesCargarArchivo = {
    cargarXls: (fileReader, file, _) => {
      fileReader.onload = function() {
        const excel = read(fileReader.result, {type: 'binary'});
        const sheet = excel.SheetNames[0];
        const data = utils.sheet_to_json(excel.Sheets[sheet], {header:1});
        setFileData(data);
      }
      fileReader.readAsBinaryString(file);
    },
    cargarCsv: (fileReader, file, separator) => {
      fileReader.onload = function() {
        const lines = fileReader.result.split("\n");
        const data = lines.filter(line => line).map(line => line.replace("\r", "").split(separator));
        setFileData(data);
      }
      fileReader.readAsText(file, "ISO-8859-1");
    }
  };

  const cargarArchivo = async(files) => {
    try {
      if (files && files.length) {
        const file = files[0];
        if (file.type === archivoSeleccionado.type || file.name.endsWith(archivoSeleccionado.value)) {
          setIsLoading(true);
          funcionesCargarArchivo[archivoSeleccionado.cargar](new FileReader(), file, tipoSeparador.value);
        } else {
          setAlertType("danger");
          setAlert(`Tipo de archivo incorrecto, debe seleccionar un archivo con extensi\u00f3n ${archivoSeleccionado.value}`);
          setShowAlert(true);
        }
      }
    } catch(error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const invalidateTable = () => {
    const tableHeaderValidAux = tableHeaderValid.map(_ => 0);
    const tableBodyValidAux = tableBodyValid.map(_ => false);
    const tableBodyValidFieldsAux = tableBodyValidFields.map(row => row.map(_ => 0));
    setTableHeaderValid(tableHeaderValidAux);
    setTableBodyValid(tableBodyValidAux);
    setTableBodyValidFields(tableBodyValidFieldsAux);
  }

  const setSelectedValue = ({target: {value}}, index) => {
    const selectValuesAux = [...selectValues];
    const selectOptionsAux = [...selectOptions];
    selectValuesAux[index] = value;
    const optionIndex = selectOptions.findIndex(option => option.value === value);
    selectOptionsAux[optionIndex].selected = true;
    setSelectValues(selectValuesAux);
    setSelectOptions(selectOptionsAux);
  }

  const removeSelectedValue = (index) => {
    const selectValuesAux = [...selectValues];
    const selectOptionsAux = [...selectOptions];
    const optionIndex = selectOptions.findIndex(option => option.value === selectValuesAux[index]);
    selectValuesAux[index] = false;
    selectOptionsAux[optionIndex].selected = false;
    setSelectValues(selectValuesAux);
    setSelectOptions(selectOptionsAux);
    invalidateTable();
  }

  const getSelectedText = (value) => selectOptions.find(item => item.value === value)?.text ?? "";

  const newColumn = () => {
    setSelectValues([...selectValues, false]);
    setTableHeader([...tableHeader, ""]);
    setTableBodyValid(tableBody.map(_ => false));
    setTableBody(tableBody.map(item => [...item, ""]));
    invalidateTable();
  }

  const removeColumn = (index) => {
    const selectValuesAux = [...selectValues];
    const tableHeaderAux = [...tableHeader];
    const tableBodyAux = tableBody.map(item => {
      const itemAux = [...item];
      itemAux.splice(index, 1);
      return itemAux;
    });
    selectValuesAux.splice(index, 1);
    tableHeaderAux.splice(index, 1);
    setSelectValues(selectValuesAux);
    setTableHeader(tableHeaderAux);
    setTableBodyValid(tableBody.map(_ => false));
    setTableBody(tableBodyAux);
    invalidateTable();
  }

  const getColumnInput = (value) => {
    if (value) {
      const optionIndex = selectOptions.findIndex(option => option.value === value);
      const option = JSON.parse(JSON.stringify(selectOptions[optionIndex]));
      if (option.input) {
        if (option.input === "select") {
          const handleSelectChange = ({target: {value}}) => {
            option.general = (value !== "opcionGeneral");
            option.generalValue = ["true", "false"].includes(value) ? (value === "true") : value;
            const selectOptionsAux = JSON.parse(JSON.stringify(selectOptions));
            selectOptionsAux[optionIndex] = option
            setSelectOptions(selectOptionsAux);
            invalidateTable();
          }
          return (
            <Form.Control as="select" custom value={option.generalValue} onChange={handleSelectChange} multiple={false}>
              <option value={"opcionGeneral"}>Opci&oacute;n general</option>
              {option.selectOptions.map(item => <option key={item.key} value={item.import ?? item.value}>{item.text}</option>)}
            </Form.Control>
          );
        } else if (option.input === "text") {
          const handleInputChange = ({target: {value}}) => {
            option.general = (value !== "");
            option.generalValue = value;
            const selectOptionsAux = JSON.parse(JSON.stringify(selectOptions));
            selectOptionsAux[optionIndex] = option
            setSelectOptions(selectOptionsAux);
            invalidateTable();
          }
          return (
            <Form.Control placeholder="Opci&oacute;n general" value={option.generalValue} onChange={handleInputChange} />
          );
        }
      }
    }
    return null;
  }

  const getEditInput = (value, index) => {
    const option = selectOptions.find(option => option.value === value);
    if (option && option.editInput) {
      if (option.editInput === "select") {
        const handleSelectChange = ({target: {value}}) => {
          const editDataAux = [...editData];
          editDataAux[index] = ["true", "false"].includes(value) ? (value === "true") : value;
          setEditData(editDataAux);
        }
        return (
          <Form.Group controlId={`${value}editInput`}>
            <Form.Label>{option.text}</Form.Label>
            <Form.Control as="select" custom value={editData[index]} onChange={handleSelectChange} multiple={false}>
              <option value={"opcionGeneral"}>Seleccionar opci&oacute;n</option>
              {option.selectOptions.map(item => <option key={item.key} value={item.import ?? item.value}>{item.text}</option>)}
            </Form.Control>
          </Form.Group>
        );
      } else if (option.editInput === "text") {
        const handleInputChange = ({target: {value}}) => {
          const editDataAux = [...editData];
          editDataAux[index] = value;
          setEditData(editDataAux);
        }
        return (
          <Form.Group controlId={`${value}editInput`}>
            <Form.Label>{option.text}</Form.Label>
            <Form.Control placeholder={option.text} value={editData[index]} onChange={handleInputChange} />
          </Form.Group>
        );
      }
    }
    return null;
  }

  const setUpdateItem = (data, index) => {
    setEditData(data);
    setEditIndex(index);
    setEditItem(true);
  }

  const updateItem = () => {
    const tableBodyAux = JSON.parse(JSON.stringify(tableBody));
    const tableBodyValidAux = [...tableBodyValid];
    const tableBodyValidFieldsAux = JSON.parse(JSON.stringify(tableBodyValidFields));
    tableBodyAux[editIndex] = editData;
    tableBodyValidAux[editIndex] = false;
    tableBodyValidFieldsAux[editIndex] = tableBodyValidFieldsAux[editIndex].map(_ => 0);
    setTableBody(tableBodyAux);
    setEditItem(false);
    setEditIndex(0);
    setEditData({});
    setTableBodyValid(tableBodyValidAux);
    setTableBodyValidFields(tableBodyValidFieldsAux);
  }

  const validateGeneralFields = () => {
    const tableHeaderValidAux = [...tableHeaderValid];
    const generalFields = selectOptions.filter(item => item.general);
    let validGeneral = true;
    for (let i = 0; i < generalFields.length; i++) {
      const option = generalFields[i];
      const index = selectValues.findIndex(item => item === option.value);
      if (option.editInput === "select") {
        tableHeaderValidAux[index] = 1;
      } else if (option.editInput === "text") {
        const invalidNumber = option.number && isNaN(option.generalValue);
        const invalidLength = option.length && option.generalValue.length !== option.length;
        const invalidMax = option.max && option.generalValue.length > option.max;
        const invalidValue = invalidNumber || invalidLength || invalidMax;
        tableHeaderValidAux[index] = invalidValue ? 2 : 1;
        if (invalidValue) {
          validGeneral = false;
        }
      }
    }
    setTableHeaderValid(tableHeaderValidAux);
    return validGeneral;
  }

  const validateOptionFields = () => {
    const missingOptions = selectOptions.filter(item => !item.selected && item.required);
    let valid = validateGeneralFields();
    if (missingOptions.length) {
      valid = false;
      setAlert(`Campos obligatorios que deben ser incluidos: ${missingOptions.map(item => item.text).join(", ")}`);
      setShowAlert(true);
    } else {
      setAlert("");
      setShowAlert(false);
    }
    return valid;
  }

  const getFieldState = (index) => {
    if (index === 1) {
      return <>&#9989;</>;
    } else if (index === 2) {
      return <>&#10060;</>;
    } else if (index === 3) {
      return <>&#128993;</>;
    }
    return null;
  }

  const saveAll = async(saveItem) => {
    const tableBodyCreatedAux = [...tableBodyCreated];
    for (let i = 0; i < tableBody.length; i++) {
      if (await saveItem(tableBody[i], i)) {
        tableBodyCreatedAux[i] = true;
      }
    }
    setTableBodyCreated(tableBodyCreatedAux);
  }

  const getAlert = () => (
    <CustomAlert type={alertType} dismissible body={alert} show={showAlert} onClose={setShowAlert} />
  )

  const getInfoModal = (item, items, fields) => (
    <Modal size="xl" centered show={showInfo} onHide={() => setShowInfo(false)} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title id="infoTitle">Archivo para importar {items}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Formatos aceptados</h4>
        <p>Se debe utilizar un archivo de Excel (.xlsx), un archivo de Excel de versiones 97-2003 (.xls) o un archivo de valores separados por comas (.csv) en el cual se puede utilizar coma (,) o punto y coma (;) como separador.</p>
        <h4>Estructura</h4>
        <p>Se debe utilizar una estructura de datos en forma de tabla, agregando en cada columna una propiedad del {item} y en cada fila los datos de un {item} en espec&iacute;fico. Si se quiere se pueden agregar t&iacute;tulos en la primera fila o agregar solamente los datos de los {items} siempre y cuando se utilice el mismo orden de los campos para cada {item}.</p>
        <h4>Campos del {item}</h4>
        <p>Estos son los campos de cada {item}, se detalla cada campo y su formato, las posibles opciones o restricciones del valor del campo y si es un campo obligatorio. Para cada columna en el archivo se puede agregar un t&iacute;tulo si as&iacute; se desea, en caso de agregar un t&iacute;tulo este puede tener cualquier valor, pero se recomienda agregar el valor entre par&eacute;ntesis (no es necesario utilizar solamente letras min&uacute;sculas, se pueden utilizar letras may&uacute;sculas mientras la palabra sea la misma) para que las columnas del archivo se asignen autom&aacute;ticamente a los campos del {item}, de lo contrario esto se debe hacer de forma manual.</p>
        {fields}
        <p>No es necesario agregar todos los campos al archivo, se puede completar la informaci&oacute;n necesaria en pantalla.</p>
        <h4>Cambios en pantalla</h4>
        <p>Se pueden agregar columnas extra para completar la informaci&oacute;n utilizando el bot&oacute;n <Button variant="success">+</Button></p>
        <p>Se pueden eliminar columnas innecesarias con el bot&oacute;n <Button variant="danger">-</Button></p>
        <p>En los componentes llamados 'Seleccionar Campo' se deben seleccionar los campos del {item} a los cuales corresponde cada columna, si se agregaron t&iacute;tulos a las columnas con los valores recomendados entre par&eacute;ntesis este proceso se har&aacute; de manera autom&aacute;tica.</p>
        <p>Dependiendo del campo que se seleccione se mostrar&aacute; un input donde se puede seleccionar o ingresar el valor para la columna, esto afectar&aacute; a todos los {items}.</p>
        <h4>Validar datos</h4>
        <p>Para cargar los {items} se debe de validar los datos ingresados, para poder validar los datos se debe de seleccionar los campos del {item} a los cuales corresponde cada columna, una vez seleccionados los campos para todas las columnas se habilitan los botones para validar los datos lo cual puede hacerse para un {item} en espec&iacute;fico o para todos los {items}.</p>
        <p>{getFieldState(1)} este &iacute;cono indica que el dato ingresado es v&aacute;lido.</p>
        <p>{getFieldState(2)} este &iacute;cono indica que el dato ingresado no es v&aacute;lido.</p>
        <p>{getFieldState(3)} este &iacute;cono indica que se va a utilizar el dato general agregado en lugar del valor del {item}.</p>
        <h4>Importar {items}</h4>
        <p>Cuando los datos se hayan validado correctamente se habilitan dos botones para importar los {items}:</p>
        <p>Con el bot&oacute;n <Button variant="success">Cargar Todo</Button> se pueden importar todos los {items} que se hayan validado correctamente.</p>
        <p>Con el bot&oacute;n <Button variant="success">Cargar</Button> se puede importar un {item} en espec&iacute;fico que se haya validado correctamente.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowInfo(false)}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  )

  const getEditModal = (item) => (
    <Modal size="lg" centered show={editItem} onHide={() => setEditItem(false)} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title id="infoTitle">Editar {item}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectValues.map((field, index) => <Row key={`${field}${index}`}><Col>{getEditInput(field, index)}</Col></Row>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setEditItem(false)}>Cerrar</Button>
        <Button onClick={updateItem}>Guardar</Button>
      </Modal.Footer>
    </Modal>
  )

  const getFileForm = () => (
    <Form noValidate>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={2}>
          <CustomSelectField id="tipoArchivo" label="Tipo de Archivo" input={tipoArchivo.select} options={tipoArchivo.options} />
        </Col>
        {
          archivoSeleccionado?.separador ? (
            <Col xs={12} sm={12} md={6} lg={6} xl={2}>
              <CustomSelectField id="separadorArchivo" label="Separador de Archivo" input={tipoSeparador.select} options={tipoSeparador.options} />
            </Col>
          ) : null
        }
        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
          <CustomSelectField id="incluirHeaders" label="T&iacute;tulos de columna en la primera fila" input={incluyeHeaders.select} options={incluyeHeaders.options} />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
          <Form.Group controlId="cargarArchivo">
            <Form.Label>Cargar Archivo</Form.Label>
            <div className="display-block">
              <Button className="float-left mr-10" disabled={isLoading} variant="info" onClick={() => setShowInfo(true)}>Como construir el Archivo</Button>
              <Form.File
                disabled={isLoading} data-browse={"Cargar"} custom accept={tipoArchivo.value} onChange={(e) => cargarArchivo(e.target.files)}
                className="width-75" label=""
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  )

  const getTable = (saveItem, validateRow, validateTable) => tableBody.length ? (
    <Table striped bordered hover responsive size="sm">
      <thead>
        <tr>
          <th className="width-159">{selectValues.length < fields.length ? <Button variant="success" onClick={newColumn}>+</Button> : null}</th>
          {tableHeader.map((header, index) => <th className="min-width-200" key={`tableHeader${index}`}>
            <span className="display-inline-block pt-7">{header}</span>
            <Button className="float-right" variant="danger" onClick={() => removeColumn(index)}>-</Button>
          </th>)}
          <th className="width-159">{selectValues.length < fields.length ? <Button variant="success" onClick={newColumn}>+</Button> : null}</th>
        </tr>
        <tr>
          <th>{(allSelected && !allValid) ? <Button variant="info" onClick={validateTable}>Validar Todo</Button> : null}</th>
          {tableHeader.map((_, index) =>
          <th key={`tableHeaderSelect${index}`}>
            {
              selectValues[index]
              ? <CustomTextField
                  id={`valueSelected${index}`} readOnly input={{value: getSelectedText(selectValues[index])}} className="m-0"
                  append={<Button variant="danger" onClick={() => removeSelectedValue(index)}>X</Button>}
                />
              : <Form.Control as="select" custom value={selectValues[index]} onChange={(e) => setSelectedValue(e, index)} multiple={false}>
                  <option value={false}>Seleccionar Campo</option>
                  {selectOptions.filter(item => !item.selected).map(item => <option key={`${index}${item.key}`} value={item.value}>{item.text}</option>)}
                </Form.Control>
            }
          </th>)}
          <th>{(allSelected && !allValid) ? <Button variant="info" onClick={validateTable}>Validar Todo</Button> : null}</th>
        </tr>
        <tr>
          <th>{tableBodyValid.find(item => item) ? <Button variant="success" onClick={() => saveAll(saveItem)}>Cargar Todo</Button> : null}</th>
          {tableHeader.map((_, index) =>
          <th className="position-relative" key={`tableHeaderSelect${index}`}>
            {getColumnInput(selectValues[index])}
            <span className="overlay-right">{getFieldState(tableHeaderValid[index])}</span>
          </th>)}
          <th>{tableBodyValid.find(item => item) ? <Button variant="success" onClick={() => saveAll(saveItem)}>Cargar Todo</Button> : null}</th>
        </tr>
      </thead>
      <tbody>
        {
          tableBody.map((row, rowIndex) =>
            <tr className={tableBodyCreated[rowIndex] ? "b-green" : ""} key={`tableRow${rowIndex}`}>
              <td>{allSelected && !tableBodyCreated[rowIndex] ?
                <>
                  <Button onClick={() => setUpdateItem(row, rowIndex)}>Editar</Button>
                  {tableBodyValid[rowIndex]
                    ? <Button className="ml-5" variant="success" onClick={() => saveItem(row, rowIndex)}>Cargar</Button>
                    : <Button className="ml-5" variant="info" onClick={() => validateRow(row, rowIndex)}>Validar</Button>
                  }
                </>
              : null}</td>
              {row.map((cell, cellIndex) => <td key={`tableCell${rowIndex}${cellIndex}`}>
                {cell}
                {tableBodyCreated[rowIndex] ? null : <span className="float-right">{getFieldState(tableBodyValidFields[rowIndex][cellIndex])}</span>}
              </td>)}
              <td>{allSelected && !tableBodyCreated[rowIndex] ?
                <>
                  <Button onClick={() => setUpdateItem(row, rowIndex)}>Editar</Button>
                  {tableBodyValid[rowIndex]
                    ? <Button className="ml-5" variant="success" onClick={() => saveItem(row, rowIndex)}>Cargar</Button>
                    : <Button className="ml-5" variant="info" onClick={() => validateRow(row, rowIndex)}>Validar</Button>
                  }
                </>
              : null}</td>
            </tr>
          )
        }
      </tbody>
    </Table>
  ) : null

  return {
    archivoSeleccionado,
    tableBodyValidFields,
    tableBodyValid,
    tableBodyCreated,
    tableBody,
    selectValues,
    selectOptions,
    item,
    showAlert,
    setShowAlert,
    setAlert,
    setTableBodyValidFields,
    setTableBodyValid,
    setTableBodyCreated,
    validateOptionFields,
    getAlert,
    getInfoModal,
    getEditModal,
    getFileForm,
    getTable
  };
}