import {createHeaders, createParams, request} from "./api";

const buildObject = (nombreReporte, sucursales, dateFrom, dateTo) => ({nombreReporte, sucursales, dateFrom, dateTo});

const crearReporteRequest = async(reporte, queryParams) => {
  try {
    const response = await request(`reportes${createParams(queryParams)}`, {method: "POST", body: JSON.stringify(reporte), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const reporteNotasEmitidas = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("NOTAS_EMITIDAS", sucursales, dateFrom, dateTo), queryParams);

export const reporteFacturasEmitidas = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("FACTURAS_EMITIDAS", sucursales, dateFrom, dateTo), queryParams);

export const reporteFacturasPorcentajes = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("FACTURAS_PORCENTAJES_SOLO_FACTURAS", sucursales, dateFrom, dateTo), queryParams);

export const reporteTiquetesEmitidos = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("TIQUETES_EMITIDOS", sucursales, dateFrom, dateTo), queryParams);

export const reporteTotalesNetosCliente = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("TOTALES_NETOS_CLIENTE", sucursales, dateFrom, dateTo), queryParams);

export const reporteTotalesNetosPorcentajeImpuestos = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("TOTALES_NETOS_PORCENTAJE_IMPUESTOS", sucursales, dateFrom, dateTo), queryParams);

export const reporteFacturasAceptadas = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("FACTURAS_ACEPTADAS", sucursales, dateFrom, dateTo), queryParams);

export const reporteFacturasAceptadasPorcentaje = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("FACTURAS_ACEPTADAS_PORCENTAJE_IMPUESTOS", sucursales, dateFrom, dateTo), queryParams);

export const reporteFacturasAceptadasPorEmision = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("FACTURAS_ACEPTADAS_POR_EMISION", sucursales, dateFrom, dateTo), queryParams);

export const reporteFacturasAceptadasPorcentajePorEmision = async(sucursales, dateFrom, dateTo, queryParams) =>
  await crearReporteRequest(buildObject("FACTURAS_ACEPTADAS_PORCENTAJE_IMPUESTOS_POR_EMISION", sucursales, dateFrom, dateTo), queryParams);

export const reenviarEmail = async(cuentaId, clave, tipo) => {
  try {
    return await request(`reportes/reenviarEmails/${cuentaId}/${clave}/${tipo}`, {method: "GET", headers: await createHeaders(true, true)});
  } catch(error) {
    throw error;
  }
}