import {createHeaders, createParams, request} from "./api";

export const crearFacturaRequest = async(cuentaId, sucursalId, factura) => {
  try {
    const response = await request(`facturaElectronica/${cuentaId}/${sucursalId}`, {method: "POST", body: JSON.stringify(factura), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const crearTiqueteRequest = async(cuentaId, sucursalId, tiquete) => {
  try {
    const response = await request(`tiqueteElectronica/${cuentaId}/${sucursalId}`, {method: "POST", body: JSON.stringify(tiquete), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const crearNotaCreditoRequest = async(cuentaId, sucursalId, facturaId, notaCredito) => {
  try {
    const response = await request(`notaCreditoElectronica/${cuentaId}/${sucursalId}/${facturaId}`, {method: "POST", body: JSON.stringify(notaCredito), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listFacturaRequest = async(cuentaId, sucursalId, queryParams, searchObject = {}) => {
  try {
    const response = await request(`facturaElectronica/list/${cuentaId}/${sucursalId}${createParams(queryParams)}`, {method: "POST", body: JSON.stringify(searchObject), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listTiqueteRequest = async(cuentaId, sucursalId, queryParams, searchObject = {}) => {
  try {
    const response = await request(`tiqueteElectronica/list/${cuentaId}/${sucursalId}${createParams(queryParams)}`, {method: "POST", body: JSON.stringify(searchObject), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listNotaCreditoRequest = async(cuentaId, sucursalId, queryParams, searchObject = {}) => {
  try {
    const response = await request(`notaCreditoElectronica/list/${cuentaId}/${sucursalId}${createParams(queryParams)}`, {method: "POST", body: JSON.stringify(searchObject), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}