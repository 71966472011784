export const unidadesMedida = () => [
  {key: "1um", text: "Unidad (Unid)", value: "Unid"},
  {key: "2um", text: "Alquiler de uso habitacional (Al)", value: "Al"},
  {key: "3um", text: "Alquiler de uso comercial (Alc)", value: "Alc"},
  {key: "4um", text: "Servicios Profesionales (Sp)", value: "Sp"},
  {key: "5um", text: "Servicios personales (Spe)", value: "Spe"},
  {key: "6um", text: "Servicios técnicos (St)", value: "St"},
  {key: "7um", text: "Otro tipo de servicio (Os)", value: "Os"},
  {key: "8um", text: "Comisiones (Cm)", value: "Cm"},
  {key: "9um", text: "Intereses (I)", value: "I"},
  {key: "10um", text: "Gramo (g)", value: "g"},
  {key: "11um", text: "Kilogramo (kg)", value: "kg"},
  {key: "12um", text: "tonelada (t)", value: "t"},
  {key: "13um", text: "Onzas (Oz)", value: "Oz"},
  {key: "14um", text: "Galón (Gal)", value: "Gal"},
  {key: "15um", text: "litro (L)", value: "L"},
  {key: "16um", text: "mililitro (mL)", value: "mL"},
  {key: "17um", text: "Metro (m)", value: "m"},
  {key: "18um", text: "Kilómetro (Km)", value: "Km"},
  {key: "19um", text: "Milímetro (mm)", value: "mm"},
  {key: "20um", text: "pulgada (ln)", value: "ln"},
  {key: "21um", text: "Mol (mol)", value: "mol"},
  {key: "22um", text: "metro por segundo (m/s)", value: "m/s"},
  {key: "23um", text: "metro por segundo cuadrado (m/s²)", value: "m/s²"},
  {key: "24um", text: "metro cuadrado (m²)", value: "m²"},
  {key: "25um", text: "metro cúbico (m³)", value: "m³"},
  {key: "26um", text: "minuto (min)", value: "min"},
  {key: "27um", text: "kilogramo por metro cúbico (kg/m³)", value: "kg/m³"},
  {key: "28um", text: "kilovatios (Kw)", value: "Kw"},
  {key: "29um", text: "lumen (lm)", value: "lm"},
  {key: "30um", text: "lux (lx)", value: "lx"},
  {key: "31um", text: "uno (indice de refracción) (1)", value: "1"},
  {key: "32um", text: "minuto (´)", value: "´"},
  {key: "33um", text: "segundo (´´)", value: "´´"},
  {key: "34um", text: "grado Celsius (°C)", value: "°C"},
  {key: "35um", text: "1 por metro (1/m)", value: "1/m"},
  {key: "36um", text: "Ampere (A)", value: "A"},
  {key: "37um", text: "ampere por metro (A/m)", value: "A/m"},
  {key: "38um", text: "ampere por metro cuadrado (A/m²)", value: "A/m²"},
  {key: "39um", text: "bel (B)", value: "B"},
  {key: "40um", text: "Becquerel (Bq)", value: "Bq"},
  {key: "41um", text: "coulomb (C)", value: "C"},
  {key: "42um", text: "coulomb por kilogramo (C/kg)", value: "C/kg"},
  {key: "43um", text: "coulomb por metro cuadrado (C/m²)", value: "C/m²"},
  {key: "44um", text: "coulomb por metro cúbico (C/m³)", value: "C/m³"},
  {key: "45um", text: "Candela (cd)", value: "cd"},
  {key: "46um", text: "candela por metro cuadrado (cd/m²)", value: "cd/m²"},
  {key: "47um", text: "centímetro (cm)", value: "cm"},
  {key: "48um", text: "día (d)", value: "d"},
  {key: "49um", text: "electronvolt (eV)", value: "eV"},
  {key: "50um", text: "farad (F)", value: "F"},
  {key: "51um", text: "farad por metro (F/m)", value: "F/m"},
  {key: "52um", text: "gray (Gy)", value: "Gy"},
  {key: "53um", text: "gray por segundo (Gy/s)", value: "Gy/s"},
  {key: "54um", text: "henry (H)", value: "H"},
  {key: "55um", text: "hora (h)", value: "h"},
  {key: "56um", text: "henry por metro (H/m)", value: "H/m"},
  {key: "57um", text: "hertz (Hz)", value: "Hz"},
  {key: "58um", text: "Joule (J)", value: "J"},
  {key: "59um", text: "joule por kilogramo kelvin (J/ (kg·K))", value: "J/ (kg·K)"},
  {key: "60um", text: "joule por mol kelvin (J/ (mol·K))", value: "J/ (mol·K)"},
  {key: "61um", text: "joule por kelvin (J/K)", value: "J/K"},
  {key: "62um", text: "joule por kilogramo (J/kg)", value: "J/kg"},
  {key: "63um", text: "joule por metro cúbico (J/m³)", value: "J/m³"},
  {key: "64um", text: "joule por mol (J/mol)", value: "J/mol"},
  {key: "65um", text: "Kelvin (K)", value: "K"},
  {key: "66um", text: "katal (kat)", value: "kat"},
  {key: "67um", text: "katal por metro cúbico (kat/m³)", value: "kat/m³"},
  {key: "68um", text: "mol por metro cúbico (mol/m³)", value: "mol/m³"},
  {key: "69um", text: "newton (N)", value: "N"},
  {key: "70um", text: "newton por metro (N/m)", value: "N/m"},
  {key: "71um", text: "newton metro (N·m)", value: "N·m"},
  {key: "72um", text: "neper (Np)", value: "Np"},
  {key: "73um", text: "grado (º)", value: "º"},
  {key: "74um", text: "pascal (Pa)", value: "Pa"},
  {key: "75um", text: "pascal segundo (Pa·s)", value: "Pa·s"},
  {key: "76um", text: "radián (rad)", value: "rad"},
  {key: "77um", text: "radián por segundo (rad/s)", value: "rad/s"},
  {key: "78um", text: "radián por segundo cuadrado (rad/s²)", value: "rad/s²"},
  {key: "79um", text: "Segundo (s)", value: "s"},
  {key: "80um", text: "siemens (S)", value: "S"},
  {key: "81um", text: "estereorradián (sr)", value: "sr"},
  {key: "82um", text: "sievert (Sv)", value: "Sv"},
  {key: "83um", text: "tesla (T)", value: "T"},
  {key: "84um", text: "unidad de masa atómica unificada (u)", value: "u"},
  {key: "85um", text: "unidad astronómica (ua)", value: "ua"},
  {key: "86um", text: "volt (V)", value: "V"},
  {key: "87um", text: "volt por metro (V/m)", value: "V/m"},
  {key: "88um", text: "Watt (W)", value: "W"},
  {key: "89um", text: "watt por metro kevin (W/ (m·K))", value: "W/ (m·K)"},
  {key: "90um", text: "watt por metro cuadrado estereorradián (W/ (m²·sr))", value: "W/ (m²·sr)"},
  {key: "91um", text: "watt por metro cuadrado (W/m²)", value: "W/m²"},
  {key: "92um", text: "watt por estereorradián (W/sr)", value: "W/sr"},
  {key: "93um", text: "weber (Wb)", value: "Wb"},
  {key: "94um", text: "ohm (Ω)", value: "Ω"},
  {key: "95um", text: "Otros (especificar)", value: "Otros"}
  
];
