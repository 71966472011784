import {useState} from "react";
import {getStringReducedFromDate} from "../helpers";

export const useDatePicker = (initialValue = undefined) => {
  const [selected, setSelected] = useState(initialValue);

  const onChange = (date) => setSelected(date);

  const getDate = () => selected ? getStringReducedFromDate(selected) : "--";

  return {
    datePicker: {selected, onChange},
    selected,
    empty: selected === undefined,
    setSelected,
    getDate,
  };
}