export const codigosPais = () => [
  {key: "1cc", text: "Afganistán (+93)", value: 93},
  {key: "2cc", text: "Albania (+355)", value: 355},
  {key: "3cc", text: "Alemania (+49)", value: 49},
  {key: "4cc", text: "Angola (+244)", value: 244},
  {key: "7cc", text: "Antillas Holandesas (+599)", value: 599},
  {key: "8cc", text: "Arabia Saudita (+966)", value: 966},
  {key: "9cc", text: "Argelia (+213)", value: 213},
  {key: "10cc", text: "Argentina (+54)", value: 54},
  {key: "11cc", text: "Armenia (+374)", value: 374},
  {key: "12cc", text: "Aruba (+297)", value: 297},
  {key: "13cc", text: "Australia (+61)", value: 61},
  {key: "14cc", text: "Austria (+43)", value: 43},
  {key: "15cc", text: "Azerbaiyán (+994)", value: 994},
  {key: "17cc", text: "Bangladés (+880)", value: 880},
  {key: "19cc", text: "Bélgica (+32)", value: 32},
  {key: "20cc", text: "Belice (+501)", value: 501},
  {key: "21cc", text: "Benín (+229)", value: 229},
  {key: "23cc", text: "Bielorrusia (+375)", value: 375},
  {key: "24cc", text: "Bolivia (+591)", value: 591},
  {key: "25cc", text: "Bosnia y Herzegovina (+387)", value: 387},
  {key: "26cc", text: "Botsuana (+267)", value: 267},
  {key: "27cc", text: "Brasil (+55)", value: 55},
  {key: "28cc", text: "Brunéi (+673)", value: 673},
  {key: "29cc", text: "Bulgaria (+359)", value: 359},
  {key: "30cc", text: "Burkina Faso (+226)", value: 226},
  {key: "31cc", text: "Burundi (+257)", value: 257},
  {key: "32cc", text: "Bután (+975)", value: 975},
  {key: "33cc", text: "Cabo Verde (+238)", value: 238},
  {key: "34cc", text: "Camboya (+855)", value: 855},
  {key: "35cc", text: "Camerún (+237)", value: 237},
  {key: "37cc", text: "Catar (+974)", value: 974},
  {key: "38cc", text: "Chad (+235)", value: 235},
  {key: "39cc", text: "Chequia (+420)", value: 420},
  {key: "40cc", text: "Chile (+56)", value: 56},
  {key: "41cc", text: "China (+86)", value: 86},
  {key: "42cc", text: "Chipre (+357)", value: 357},
  {key: "43cc", text: "Colombia (+57)", value: 57},
  {key: "44cc", text: "Comoras (+269)", value: 269},
  {key: "45cc", text: "Congo (+242)", value: 242},
  {key: "46cc", text: "Corea (+82)", value: 82},
  {key: "47cc", text: "Corea del Norte (+850)", value: 850},
  {key: "48cc", text: "Costa de Marfil (+225)", value: 225},
  {key: "49cc", text: "Costa Rica (+506)", value: 506},
  {key: "50cc", text: "Croacia (+385)", value: 385},
  {key: "51cc", text: "Cuba (+53)", value: 53},
  {key: "52cc", text: "Diego García (+246)", value: 246},
  {key: "53cc", text: "Dinamarca (+45)", value: 45},
  {key: "55cc", text: "Ecuador (+593)", value: 593},
  {key: "56cc", text: "Egipto (+20)", value: 20},
  {key: "57cc", text: "El Salvador (+503)", value: 503},
  {key: "58cc", text: "Emiratos Árabes Unidos (+971)", value: 971},
  {key: "59cc", text: "Eritrea (+291)", value: 291},
  {key: "61cc", text: "Eslovaquia (+421)", value: 421},
  {key: "62cc", text: "Eslovenia (+386)", value: 386},
  {key: "63cc", text: "España (+34)", value: 34},
  {key: "64cc", text: "Estonia (+372)", value: 372},
  {key: "65cc", text: "Etiopía (+251)", value: 251},
  {key: "66cc", text: "Federación Rusa (+7)", value: 7},
  {key: "67cc", text: "Filipinas (+63)", value: 63},
  {key: "68cc", text: "Finlandia (+358)", value: 358},
  {key: "69cc", text: "Fiyi (+679)", value: 679},
  {key: "70cc", text: "Francia (+33)", value: 33},
  {key: "71cc", text: "Gabón (+241)", value: 241},
  {key: "73cc", text: "Gambia (+220)", value: 220},
  {key: "74cc", text: "Georgia (+995)", value: 995},
  {key: "75cc", text: "Ghana (+233)", value: 233},
  {key: "76cc", text: "Gibraltar (+350)", value: 350},
  {key: "78cc", text: "Grecia (+30)", value: 30},
  {key: "79cc", text: "Groenlandia (+299)", value: 299},
  {key: "80cc", text: "Guadalupe (+590)", value: 590},
  {key: "82cc", text: "Guatemala (+502)", value: 502},
  {key: "83cc", text: "Guayana Francesa (+594)", value: 594},
  {key: "84cc", text: "Guinea (+224)", value: 224},
  {key: "85cc", text: "Guinea Ecuatorial (+240)", value: 240},
  {key: "86cc", text: "Guinea-Bissau (+245)", value: 245},
  {key: "87cc", text: "Guyana (+592)", value: 592},
  {key: "88cc", text: "Haití (+509)", value: 509},
  {key: "89cc", text: "Holanda (+31)", value: 31},
  {key: "90cc", text: "Honduras (+504)", value: 504},
  {key: "91cc", text: "HongKong (+852)", value: 852},
  {key: "92cc", text: "Hungría (+36)", value: 36},
  {key: "93cc", text: "India (+91)", value: 91},
  {key: "94cc", text: "Indonesia (+62)", value: 62},
  {key: "95cc", text: "Inglaterra (+44)", value: 44},
  {key: "96cc", text: "Irak (+964)", value: 964},
  {key: "97cc", text: "Irán (+98)", value: 98},
  {key: "98cc", text: "Irlanda (+353)", value: 353},
  {key: "100cc", text: "Isla Ascensión (+247)", value: 247},
  {key: "101cc", text: "Isla Norfolk (+6723)", value: 6723},
  {key: "102cc", text: "Islandia (+354)", value: 354},
  {key: "104cc", text: "Islas Cook (+682)", value: 682},
  {key: "105cc", text: "Islas Feroe (+298)", value: 298},
  {key: "106cc", text: "Islas Malvinas (+500)", value: 500},
  {key: "108cc", text: "Islas Marshall (+692)", value: 692},
  {key: "109cc", text: "Islas Salomón (+677)", value: 677},
  {key: "113cc", text: "Israel (+972)", value: 972},
  {key: "114cc", text: "Italia (+39)", value: 39},
  {key: "116cc", text: "Japón (+81)", value: 81},
  {key: "117cc", text: "Jordania (+962)", value: 962},
  {key: "119cc", text: "Kenia (+254)", value: 254},
  {key: "120cc", text: "Kirguistán (+996)", value: 996},
  {key: "121cc", text: "Kiribati (+686)", value: 686},
  {key: "122cc", text: "Kuwait (+965)", value: 965},
  {key: "123cc", text: "Laos (+856)", value: 856},
  {key: "124cc", text: "Lesoto (+266)", value: 266},
  {key: "125cc", text: "Letonia (+371)", value: 371},
  {key: "126cc", text: "Líbano (+961)", value: 961},
  {key: "127cc", text: "Liberia (+231)", value: 231},
  {key: "128cc", text: "Libia (+218)", value: 218},
  {key: "129cc", text: "Liechtenstein (+423)", value: 423},
  {key: "130cc", text: "Lituania (+370)", value: 370},
  {key: "131cc", text: "Luxemburgo (+352)", value: 352},
  {key: "132cc", text: "Macao (+853)", value: 853},
  {key: "133cc", text: "Macedonia (+389)", value: 389},
  {key: "134cc", text: "Madagascar (+261)", value: 261},
  {key: "135cc", text: "Malasia (+60)", value: 60},
  {key: "136cc", text: "Malaui (+265)", value: 265},
  {key: "137cc", text: "Maldivas (+960)", value: 960},
  {key: "138cc", text: "Malí (+223)", value: 223},
  {key: "139cc", text: "Malta (+356)", value: 356},
  {key: "140cc", text: "Marruecos (+212)", value: 212},
  {key: "141cc", text: "Martinica (+596)", value: 596},
  {key: "142cc", text: "Mauricio (+230)", value: 230},
  {key: "143cc", text: "Mauritania (+222)", value: 222},
  {key: "145cc", text: "México (+52)", value: 52},
  {key: "146cc", text: "Micronesia (+691)", value: 691},
  {key: "147cc", text: "Moldavia (+373)", value: 373},
  {key: "148cc", text: "Mónaco (+377)", value: 377},
  {key: "149cc", text: "Mongolia (+976)", value: 976},
  {key: "150cc", text: "Montenegro (+382)", value: 382},
  {key: "152cc", text: "Mozambique (+258)", value: 258},
  {key: "153cc", text: "Myanmar (+95)", value: 95},
  {key: "154cc", text: "Namibia (+264)", value: 264},
  {key: "155cc", text: "Nauru (+674)", value: 674},
  {key: "156cc", text: "Nepal (+977)", value: 977},
  {key: "157cc", text: "Nicaragua (+505)", value: 505},
  {key: "158cc", text: "Níger (+227)", value: 227},
  {key: "159cc", text: "Nigeria (+234)", value: 234},
  {key: "160cc", text: "Niue (+683)", value: 683},
  {key: "161cc", text: "Noruega (+47)", value: 47},
  {key: "162cc", text: "Nueva Caledonia (+687)", value: 687},
  {key: "163cc", text: "Nueva Zelanda (+64)", value: 64},
  {key: "164cc", text: "Omán (+968)", value: 968},
  {key: "165cc", text: "Pakistán (+92)", value: 92},
  {key: "166cc", text: "Palaos (+680)", value: 680},
  {key: "167cc", text: "Palestina (+970)", value: 970},
  {key: "168cc", text: "Panamá (+507)", value: 507},
  {key: "169cc", text: "Papúa Nueva Guinea (+675)", value: 675},
  {key: "170cc", text: "Paraguay (+595)", value: 595},
  {key: "171cc", text: "Perú (+51)", value: 51},
  {key: "172cc", text: "Polinesia Francesa (+689)", value: 689},
  {key: "173cc", text: "Polonia (+48)", value: 48},
  {key: "174cc", text: "Portugal (+351)", value: 351},
  {key: "175cc", text: "Principado de Andorra (+376)", value: 376},
  {key: "177cc", text: "Reino de Bahréin (+973)", value: 973},
  {key: "179cc", text: "República Centroafricana (+236)", value: 236},
  {key: "180cc", text: "República Democrática del Congo (+243)", value: 243},
  {key: "181cc", text: "Reunión (+262)", value: 262},
  {key: "182cc", text: "Ruanda (+250)", value: 250},
  {key: "183cc", text: "Rumanía (+40)", value: 40},
  {key: "185cc", text: "Samoa (+685)", value: 685},
  {key: "189cc", text: "San Marino (+378)", value: 378},
  {key: "191cc", text: "San Pedro y Miquelón (+508)", value: 508},
  {key: "193cc", text: "Santa Elena (+290)", value: 290},
  {key: "195cc", text: "Santo Tomé y Príncipe (+239)", value: 239},
  {key: "196cc", text: "Satélite Inmarsat (+870)", value: 870},
  {key: "197cc", text: "Satélite Iridium (+8816)", value: 8816},
  {key: "198cc", text: "Satélite Thuraya (+882)", value: 882},
  {key: "199cc", text: "Senegal (+221)", value: 221},
  {key: "200cc", text: "Serbia (+381)", value: 381},
  {key: "201cc", text: "Seychelles (+248)", value: 248},
  {key: "202cc", text: "Sierra Leona (+232)", value: 232},
  {key: "203cc", text: "Singapur (+65)", value: 65},
  {key: "205cc", text: "Siria (+963)", value: 963},
  {key: "206cc", text: "Somalia (+252)", value: 252},
  {key: "207cc", text: "Sri Lanka (+94)", value: 94},
  {key: "208cc", text: "Suazilandia (+268)", value: 268},
  {key: "209cc", text: "Sudáfrica (+27)", value: 27},
  {key: "210cc", text: "Sudán (+249)", value: 249},
  {key: "211cc", text: "Sudán del Sur (+211)", value: 211},
  {key: "212cc", text: "Suecia (+46)", value: 46},
  {key: "213cc", text: "Suiza (+41)", value: 41},
  {key: "214cc", text: "Surinam (+597)", value: 597},
  {key: "215cc", text: "Tailandia (+66)", value: 66},
  {key: "216cc", text: "Taiwán (+886)", value: 886},
  {key: "217cc", text: "Tanzania (+255)", value: 255},
  {key: "218cc", text: "Tayikistán (+992)", value: 992},
  {key: "219cc", text: "Timor Oriental (+670)", value: 670},
  {key: "220cc", text: "Togo (+228)", value: 228},
  {key: "221cc", text: "Tokelau (+690)", value: 690},
  {key: "222cc", text: "Tonga (+676)", value: 676},
  {key: "224cc", text: "Túnez (+216)", value: 216},
  {key: "225cc", text: "Turkmenistán (+993)", value: 993},
  {key: "226cc", text: "Turquía (+90)", value: 90},
  {key: "227cc", text: "Tuvalu (+688)", value: 688},
  {key: "228cc", text: "Ucrania (+380)", value: 380},
  {key: "229cc", text: "Uganda (+256)", value: 256},
  {key: "230cc", text: "Uruguay (+598)", value: 598},
  {key: "231cc", text: "USA (+1)", value: 1},
  {key: "232cc", text: "Uzbekistán (+998)", value: 998},
  {key: "233cc", text: "Vanuatu (+678)", value: 678},
  {key: "235cc", text: "Venezuela (+58)", value: 58},
  {key: "236cc", text: "Vietnam (+84)", value: 84},
  {key: "237cc", text: "Wallis y Futuna (+681)", value: 681},
  {key: "238cc", text: "Yemen (+967)", value: 967},
  {key: "239cc", text: "Yibuti (+253)", value: 253},
  {key: "240cc", text: "Zambia (+260)", value: 260},
  {key: "241cc", text: "Zimbabue (+263)", value: 263}
];
