import {useState} from "react";
import {CustomOption} from "../components";
import {tiposIdentificacion} from "../constants";

export const useIdentificacion = () => {
  const [tipoIdentificacion, setTipoIdentificacion] = useState("01");
  const [numero, setNumero] = useState("");

  const handleTipoIdentificacion = (e) => setTipoIdentificacion(e.target.value);

  const handleNumero = (e) => setNumero(e.target.value);

  const displayTiposIdentificacion = () => tiposIdentificacion().map(element => <CustomOption key={element.key} item={element} />)

  const reset = () => {
    setTipoIdentificacion("01");
    setNumero("");
  }

  const setObject = (data) => {
    setTipoIdentificacion(String(data.tipoIdentificacion || data.tipo || "01"));
    setNumero(String(data.numero || ""));
  }

  return {
    getObject : {tipoIdentificacion, numero, tipo: tipoIdentificacion},
    tipoIdentificacion: {value: tipoIdentificacion, onChange: handleTipoIdentificacion},
    numero: {value: numero, onChange: handleNumero},
    tiposIdentificacion: displayTiposIdentificacion,
    setTipoIdentificacion,
    setNumero,
    reset,
    setObject
  };
}