import {useState} from "react";
import {useFormInput} from "./index";

export const useConsecutivo = () => {
  const [lockFields, setLockFields] = useState(true);
  const [id, setId] = useState(0);
  const facturaElectronica = useFormInput(0);
  const facturaElectronicaCompra = useFormInput(0);
  const facturaElectronicaExportacion = useFormInput(0);
  const tiqueteElectronico = useFormInput(0);
  const notaCreditoElectronica = useFormInput(0);
  const notaDebitoElectronica = useFormInput(0);
  const mensajeReceptor = useFormInput(0);
  const mensajeHacienda = useFormInput(0);

  const reset = () => {
    setId(0);
    facturaElectronica.setValue(0);
    facturaElectronicaCompra.setValue(0);
    facturaElectronicaExportacion.setValue(0);
    tiqueteElectronico.setValue(0);
    notaCreditoElectronica.setValue(0);
    notaDebitoElectronica.setValue(0);
    mensajeReceptor.setValue(0);
    mensajeHacienda.setValue(0);
  }

  const setObject = (data) => {
    setId(data.id || 0);    
    facturaElectronica.setValue(Number(data.facturaElectronica || 0));
    facturaElectronicaCompra.setValue(Number(data.facturaElectronicaCompra || 0));
    facturaElectronicaExportacion.setValue(Number(data.facturaElectronicaExportacion || 0));
    tiqueteElectronico.setValue(Number(data.tiqueteElectronico || 0));
    notaCreditoElectronica.setValue(Number(data.notaCreditoElectronica || 0));
    notaDebitoElectronica.setValue(Number(data.notaDebitoElectronica || 0));
    mensajeReceptor.setValue(Number(data.mensajeReceptor || 0));
    mensajeHacienda.setValue(Number(data.mensajeHacienda || 0));
  }

  return {
    getObject: {
      facturaElectronica: facturaElectronica.value,
      facturaElectronicaCompra: facturaElectronicaCompra.value,
      facturaElectronicaExportacion: facturaElectronicaExportacion.value,
      tiqueteElectronico: tiqueteElectronico.value,
      notaCreditoElectronica: notaCreditoElectronica.value,
      notaDebitoElectronica: notaDebitoElectronica.value,
      mensajeReceptor: mensajeReceptor.value,
      mensajeHacienda: mensajeHacienda.value
    },
    id,
    facturaElectronica,
    facturaElectronicaCompra,
    facturaElectronicaExportacion,
    tiqueteElectronico,
    notaCreditoElectronica,
    notaDebitoElectronica,
    mensajeReceptor,
    mensajeHacienda,
    lockFields,
    setLockFields,
    reset,
    setObject
  };
}
