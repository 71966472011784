import {createHeaders, createParams, request} from "./api";

export const createProductoRequest = async(producto) => {
  try {
    const response = await request("productos", {method: "POST", body: JSON.stringify(producto), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const updateProductoRequest = async(producto, id) => {
  try {
    const response = await request("productos", {method: "POST", body: JSON.stringify({...producto, id}), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const deleteProductoRequest = async(id) => {
  try {
    await request(`productos/${id}`, {method: "DELETE", headers: await createHeaders(true)});
    return "Producto eliminado";
  } catch(error) {
    throw error;
  }
}

export const getProductoRequest = async(id) => {
  try {
    const response = await request(`productos/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const searchProductoRequest = async(sucursalId, text, queryParams) => {
  try {
    const response = await request(`productos/search/${sucursalId}/${text}${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listProductoRequest = async(sucursalId, queryParams, searchObject = {}) => {
  try {
    const response = await request(`productos/${sucursalId}/list${createParams(queryParams)}`, {method: "POST", body: JSON.stringify(searchObject), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}