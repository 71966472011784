import {useEffect} from "react";
import {Container} from "../components";

export const UnknownPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="full-container">
      <p>P&aacute;gina desconocida</p>
    </Container>
  )
}