import {usePageImportHandler, useProducto} from "../customHooks";
import {createProductoRequest} from "../helpers";
import {Container, CustomAlert} from "../components";
import {productoFields} from "../constants";

export const ImportarProductosPage = ({controller}) => {
  const pageHandler = usePageImportHandler(useProducto, productoFields());

  const validateItem = (itemData, index, validGeneral) => {
    const rowValidFields = [...pageHandler.tableBodyValidFields[index]];
    let validRow = validGeneral, invalidImpuesto = [];
    for (let i = 0; i < pageHandler.selectValues.length; i++) {
      const option = pageHandler.selectOptions.find(item => item.value === pageHandler.selectValues[i]);
      if (!option.dependency) {
        let value = String(itemData[i]);
        if (option.general) {
          rowValidFields[i] = 3;
          value = option.generalValue;
        } else {
          let invalidField = false;
          const invalidBlank = option.required && value === "";
          if (option.editInput === "select") {
            const validOptions = option.selectOptions.map(item => item.import ?? item.value);
            const invalidOption = value !== "" && !validOptions.includes(value);
            invalidField = invalidBlank || invalidOption;
          } else if (option.editInput === "text") {
            const invalidNumber = option.number && value !== "" && isNaN(value);
            const invalidLength = option.length && value.length !== option.length;
            const invalidMax = option.max && value.length > option.max;
            invalidField = invalidNumber || invalidLength || invalidMax || invalidBlank;
          }
          rowValidFields[i] = invalidField ? 2 : 1;
          if (invalidField) {
            validRow = false;
          }
        }
        if (option.value === "impuestoCodigoImpuesto") {
          const codigoTarifa = pageHandler.selectOptions.find(item => item.value === "impuestoCodigoTarifa");
          const impuestoTarifa = pageHandler.selectOptions.find(item => item.value === "impuestoTarifa");
          const impuestoFactor = pageHandler.selectOptions.find(item => item.value === "impuestoFactorIVA");
          const includesImpuestos = ["01", "07"].includes(value);
          if (includesImpuestos) {
            if (codigoTarifa.selected) {
              if (!codigoTarifa.general) {
                const codigoTarifaIndex = pageHandler.selectValues.findIndex(item => item === codigoTarifa.value);
                const validOptions = codigoTarifa.selectOptions.map(item => item.value);
                rowValidFields[codigoTarifaIndex] = validOptions.includes(itemData[codigoTarifaIndex]) ? 1 : 2;
                if (rowValidFields[codigoTarifaIndex] === 2) {
                  validRow = false;
                }
              }
            } else {
              validRow = false;
              invalidImpuesto.push(codigoTarifa.text);
            }
          }
          if (!includesImpuestos) {
            if (impuestoTarifa.selected) {
              if (!impuestoTarifa.general) {
                const impuestoTarifaIndex = pageHandler.selectValues.findIndex(item => item === impuestoTarifa.value);
                rowValidFields[impuestoTarifaIndex] = isNaN(itemData[impuestoTarifaIndex]) || itemData[impuestoTarifaIndex] === "" ? 2 : 1;
                if (rowValidFields[impuestoTarifaIndex] === 2) {
                  validRow = false;
                }
              }
            } else {
              validRow = false;
              invalidImpuesto.push(impuestoTarifa.text);
            }
          }
          if (value === "08") {
            if (impuestoFactor.selected) {
              if (!impuestoFactor.general) {
                const impuestoFactorIndex = pageHandler.selectValues.findIndex(item => item === impuestoFactor.value);
                rowValidFields[impuestoFactorIndex] = isNaN(itemData[impuestoFactorIndex]) || itemData[impuestoFactorIndex] === "" ? 2 : 1;
                if (rowValidFields[impuestoFactorIndex] === 2) {
                  validRow = false;
                }
              }
            } else {
              validRow = false;
              invalidImpuesto.push(impuestoFactor.text);
            }
          }
        }
      }
    }
    return [validRow, rowValidFields, invalidImpuesto];
  }

  const validateRow = (itemData, index) => {
    const validFields = pageHandler.validateOptionFields();
    const [validRow, rowValidFields, invalidImpuesto] = validateItem(itemData, index, validFields);
    const tableBodyValidAux = [...pageHandler.tableBodyValid];
    const tableBodyValidFieldsAux = JSON.parse(JSON.stringify(pageHandler.tableBodyValidFields));
    tableBodyValidAux[index] = validRow;
    tableBodyValidFieldsAux[index] = rowValidFields;
    if (invalidImpuesto.length) {
      if (pageHandler.showAlert) {
        pageHandler.setAlert(`${alert}. Campos necesarios para algunos productos: ${invalidImpuesto.join(", ")}`);
      } else {
        pageHandler.setAlert(`Campos necesarios para algunos productos: ${invalidImpuesto.join(", ")}`);
        pageHandler.setShowAlert(true);
      }
    }
    pageHandler.setTableBodyValid(tableBodyValidAux);
    pageHandler.setTableBodyValidFields(tableBodyValidFieldsAux);
  }

  const validateTable = () => {
    const validFields = pageHandler.validateOptionFields();
    const tableBodyValidAux = [...pageHandler.tableBodyValid];
    const tableBodyValidFieldsAux = JSON.parse(JSON.stringify(pageHandler.tableBodyValidFields));
    let invalidImpuestoArray = [];
    for (let index = 0; index < pageHandler.tableBody.length; index++) {
      const [validRow, rowValidFields, invalidImpuesto] = validateItem(pageHandler.tableBody[index], index, validFields);
      invalidImpuestoArray = [...invalidImpuestoArray, ...invalidImpuesto];
      tableBodyValidAux[index] = validRow;
      tableBodyValidFieldsAux[index] = rowValidFields;
    }
    invalidImpuestoArray = [...new Set(invalidImpuestoArray)];
    if (invalidImpuestoArray.length) {
      if (pageHandler.showAlert) {
        pageHandler.setAlert(`${alert}. Campos necesarios para algunos productos: ${invalidImpuestoArray.join(", ")}`);
      } else {
        pageHandler.setAlert(`Campos necesarios para algunos productos: ${invalidImpuestoArray.join(", ")}`);
        pageHandler.setShowAlert(true);
      }
    }
    pageHandler.setTableBodyValid(tableBodyValidAux);
    pageHandler.setTableBodyValidFields(tableBodyValidFieldsAux);
  }

  const saveItem = async(itemData, index) => {
    try {
      if (pageHandler.tableBodyValid[index] && !pageHandler.tableBodyCreated[index]) {
        const itemObject = pageHandler.item.getObjectFromImport(itemData, pageHandler.selectValues, pageHandler.selectOptions, controller.usuario.sucursal.get);
        await createProductoRequest(itemObject);
        const tableBodyCreatedAux = [...pageHandler.tableBodyCreated];
        tableBodyCreatedAux[index] = true;
        pageHandler.setTableBodyCreated(tableBodyCreatedAux);
        return true;
      }
      return false;
    } catch(error) {
      console.log(error);
      return false;
    }
  }

  if (controller.usuario.sucursal.get) {
    return (
      <Container className="full-container">
        {pageHandler.getAlert()}
        {pageHandler.getInfoModal("producto", "productos", <>
          <p><strong>Tipo (tipo):</strong> si es producto o servicio. Opciones: producto, servicio. Es obligatorio.</p>
          <p><strong>Detalle (detalle):</strong> nombre o descripci&oacute;n del producto. Texto de hasta 200 caracteres. Es obligatorio.</p>
          <p><strong>C&oacute;digo Cabys (cabys):</strong> c&oacute;digo cabys del producto. N&uacute;mero de 13 d&iacute;gitos. Es obligatorio.</p>
          <p><strong>C&oacute;digo Interno (interno):</strong> c&oacute;digo interno o de barras del producto. Texto de hasta 20 caracteres. Es obligatorio y debe ser &uacute;nico para cada producto.</p>
          <p><strong>Stock (stock):</strong> stock en inventario del producto. Campo num&eacute;rico. Es obligatorio.</p>
          <p><strong>Precio Minorista (minorista):</strong> precio minorista del producto sin incluir los impuestos. Campo num&eacute;rico. Es obligatorio.</p>
          <p><strong>Precio Mayorista (mayorista):</strong> precio mayorista del producto sin incluir los impuestos. Campo num&eacute;rico. No es obligatorio, si no se define se utilizar&aacute; el precio minorista por defecto.</p>
          <p><strong>Tipo de Impuesto (impuesto):</strong> tipo de impuesto del producto (Utilizar el c&oacute;digo de 2 d&iacute;gitos, por ejemplo 01 para IVA). Opciones: 01 - IVA (Impuesto al Valor Agregado), 02 - Impuesto Selectivo de Consumo, 03 - Impuesto Único a los Combustibles, 04 - Impuesto específico de Bebidas Alcohólicas, 05 - Impuesto Específico sobre las bebidas envasadas sin contenido alcohólico y jabones de tocador, 06 - Impuesto a los Productos de Tabaco, 07 - IVA (cálculo especial), 08 - IVA Régimen de Bienes Usados (Factor), 12 - Impuesto Específico al Cemento, 99 - Otros. Es obligatorio.</p>
          <p><strong>Tipo de Tarifa (tarifa):</strong> solamente necesario si el tipo de impuesto es 01 o 07, tipo de tarifa del impuesto del producto (Utilizar el c&oacute;digo de 2 d&iacute;gitos, por ejemplo 08 para la tarifa general de 13%). Opciones: 01 - Tarifa 0% (Exento), 02 - Tarifa reducida 1%, 03 - Tarifa reducida 2%, 04 - Tarifa reducida 4%, 05 - Transitorio 0%, 06 - Transitorio 4%, 07 - Transitorio 8%, 08 - Tarifa general 13%.</p>
          <p><strong>Tarifa de Impuesto (porcentaje):</strong> solamente necesario si el tipo de impuesto es diferente de 01 o 07. Campo num&eacute;rico.</p>
          <p><strong>Factor IVA (factor):</strong> solamente necesario si el tipo de impuesto es 08. Campo num&eacute;rico.</p>
          <p><strong>Unidad de Medida (unidad):</strong> unidad de medida del producto (Utilizar el s&iacute;mbolo de la unidad). Opciones m&aacute;s comunes (ver lista completa en la pantalla de productos o en la opci&oacute;n general para esta columna): Unid - Unidad, Al - Alquiler de uso habitacional, Alc - Alquiler de uso comercial, Sp - Servicios Profesionales, Spe - Servicios personales, St - Servicios técnicos, Os - Otro tipo de servicio, g - Gramo, Kg - Kilogramo, L - Litro, mL - mililitro, m - Metro. Es obligatorio.</p>
        </>)}
        {pageHandler.getEditModal("Producto")}
        {pageHandler.getFileForm()}
        {pageHandler.getTable(saveItem, validateRow, validateTable)}
      </Container>
    );
  }
  return (
    <Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show /></Container>
  );
}