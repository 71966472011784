import {useState, useEffect, useRef} from "react";

export const usePageHandlerReduced = (useItem) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const item = useItem();
  const isMounted = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => isMounted.current = false;
  }, []);

  return {
    isMounted: isMounted.current,
    isLoading,
    showAlert,
    formValidated,
    openModal,
    alert,
    alertType,
    item,
    setIsLoading,
    setShowAlert,
    setFormValidated,
    setOpenModal,
    setAlert,
    setAlertType
  };
}
