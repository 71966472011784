import {useState} from "react";

import {useFormInput, useFormSelect} from "./index";
import {unidadesMedida, codigosImpuesto, tarifasIVA, tiposLinea} from "../constants";

export const useProducto = (sucursalId) => {
  const [haciendaSearch, setHaciendaSearch] = useState(false);
  const [codigos, setCodigos] = useState([]);
  const searchInput = useFormInput("");
  const [id, setId] = useState(0);
  const detalle = useFormInput("");
  const codigo = useFormInput("");
  const codigoComercial = useFormInput("");
  const stock = useFormInput("");
  const impuestoFactorIVA = useFormInput("");
  const precioUnitarioMayorista = useFormInput("");
  const precioUnitarioMinorista = useFormInput("");
  const unidadMedida = useFormSelect("Unid", unidadesMedida);
  const impuestoCodigoImpuesto = useFormSelect("01", codigosImpuesto);
  const impuestoCodigoTarifa = useFormSelect("08", tarifasIVA);
  const isProducto = useFormSelect(true, tiposLinea);

  const getImpuestoTarifa = () => tarifasIVA().find(element => element.value === impuestoCodigoTarifa.value).tarifa;

  const reset = () => {
    setId(0);
    detalle.setValue("");
    codigo.setValue("");
    codigoComercial.setValue("");
    stock.setValue("");
    impuestoFactorIVA.setValue("");
    precioUnitarioMayorista.setValue("");
    precioUnitarioMinorista.setValue("");
    unidadMedida.setValue("Unid");
    impuestoCodigoImpuesto.setValue("01");
    impuestoCodigoTarifa.setValue("08");
    isProducto.setValue(true);
  }

  const setObject = (data) => {
    detalle.setValue(data.detalle || "");
    codigo.setValue(data.codigo || "");
    codigoComercial.setValue(data.codigoComercial || "");
    stock.setValue(data.stock || "");
    impuestoFactorIVA.setValue(data.impuestoCodigoImpuesto === "08" ? data.impuestoFactorIVA : data.impuestoTarifa);
    precioUnitarioMayorista.setValue(data.precioUnitarioMayorista || "");
    precioUnitarioMinorista.setValue(data.precioUnitarioMinorista || "");
    unidadMedida.setValue(data.unidadMedida || "Unid");
    impuestoCodigoImpuesto.setValue(data.impuestoCodigoImpuesto || "01");
    impuestoCodigoTarifa.setValue(data.impuestoCodigoTarifa || "08");
    isProducto.setValue(!!data.isProducto);
  }

  const setBusqueda = (data) => {
    detalle.setValue(detalle.value || data.descripcion || "");
    codigo.setValue(data.codigo || "");
    impuestoCodigoTarifa.setValue(tarifasIVA().find(item => item.tarifa === data.impuesto).value || "08");
  }

  const cleanHaciendaSearch = (search = false) => {
    setTimeout(() => setHaciendaSearch(search));
    searchInput.setValue("");
    setCodigos([]);
  }

  const getObjectFromImport = (data, keys, options, sucursalId) => {
    const producto = keys.reduce((object, key, index) => {
      const option = options.find(item => item.value === key);
      let value = option.general ? option.generalValue : data[index];
      value = option.equalTo ? (option.equalTo === value) : value;
      return { ...object, [key]: value}
    }, {sucursalId});
    if (!producto.impuestoTarifa && producto.impuestoCodigoTarifa) {
      producto.impuestoTarifa = tarifasIVA().find(element => element.value === producto.impuestoCodigoTarifa).tarifa;
    }
    return producto;
  }

  return {
    getObject: {
      detalle: detalle.value,
      codigo: codigo.value,
      codigoComercial: codigoComercial.value,
      stock: stock.value,
      impuestoFactorIVA: impuestoCodigoImpuesto.value === "08" ? impuestoFactorIVA.value : "",
      precioUnitarioMayorista: precioUnitarioMayorista.value,
      precioUnitarioMinorista: precioUnitarioMinorista.value,
      unidadMedida: unidadMedida.value,
      impuestoCodigoImpuesto: impuestoCodigoImpuesto.value,
      impuestoCodigoTarifa: (impuestoCodigoImpuesto.value === "01" || impuestoCodigoImpuesto.value === "07") ? impuestoCodigoTarifa.value : "",
      isProducto: isProducto.value,
      impuestoTarifa: (impuestoCodigoImpuesto.value === "01" || impuestoCodigoImpuesto.value === "07") ? getImpuestoTarifa() : impuestoCodigoImpuesto.value !== "08" ? impuestoFactorIVA.value : "",
      sucursalId
    },
    id,
    detalle,
    codigo,
    codigoComercial,
    stock,
    impuestoFactorIVA,
    precioUnitarioMayorista,
    precioUnitarioMinorista,
    unidadMedida,
    impuestoCodigoImpuesto,
    impuestoCodigoTarifa,
    isProducto,
    haciendaSearch,
    codigos,
    searchInput,
    setCodigos,
    setHaciendaSearch,
    setId,
    reset,
    setObject,
    setBusqueda,
    cleanHaciendaSearch,
    getObjectFromImport
  };
}
