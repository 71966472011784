import {createHeaders, request} from "./api";

export const abrirCajaRequest = async(sucursalId) => {
  try {
    const response = await request(`cajas/abrir/${sucursalId}`, {method: "POST", headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const cerrarCajaRequest = async(sucursalId) => {
  try {
    const response = await request(`cajas/cerrar/${sucursalId}`, {method: "POST", headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const estadoCajaRequest = async(sucursalId) => {
  try {
    const response = await request(`cajas/estadoActual/${sucursalId}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    return false;
  } catch(error) {
    throw error;
  }
}

export const updateCajaRequest = async(cajaId, caja) => {
  try {
    const response = await request(`cajas/${cajaId}`, {method: "POST", body: JSON.stringify(caja), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    return false;
  } catch(error) {
    throw error;
  }
}