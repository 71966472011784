import {createHeaders, createParams, request} from "./api";

export const saveExoneracionRequest = async(exoneracion, cuentaId) => {
  try {
    const response = await request(`exoneraciones/${cuentaId}`, {method: "POST", body: JSON.stringify(exoneracion), headers: await createHeaders(true, true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const deleteExoneracionRequest = async(id) => {
  try {
    await request(`exoneraciones/${id}`, {method: "DELETE", headers: await createHeaders(true)});
    return "Exoneraci\u00f3n eliminada";
  } catch(error) {
    throw error;
  }
}

export const getExoneracionRequest = async(id) => {
  try {
    const response = await request(`exoneraciones/exoneracion/${id}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const listExoneracionRequest = async(cuentaId, queryParams) => {
  try {
    const response = await request(`exoneraciones/${cuentaId}${createParams(queryParams)}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}

export const getExoneracionClienteRequest = async(cuenta, identificacion) => {
  try {
    const response = await request(`exoneraciones/identificacion/${cuenta}/${identificacion}`, {method: "GET", headers: await createHeaders(true)});
    if (typeof response === "object") {
      return response;
    }
    throw response;
  } catch(error) {
    throw error;
  }
}