import {useState, useRef} from "react";
import {useFormInput, useFormCheck, useFormSelect} from "./index";

const tipoPrecios = () => [
  {key: "1tp", value: "subTotal", text: "Subtotal"},
  {key: "2tp", value: "total", text: "Total"},
];

const tipoDescuentos = () => [
  {key: "1td", value: "porcentaje", text: "%"},
  {key: "2td", value: "neto", text: "Neto"},
];

export const useLineaVenta= () => {
  const [loadingProductos, setLoadingProductos] = useState(false);
  const [productos, setProductos] = useState([]);
  const [nuevaLinea, setNuevaLinea] = useState(false);
  const [producto, setProducto] = useState({});
  const [exoneracion, setExoneracion] = useState({});
  const [exoneraciones, setExoneraciones] = useState([]);
  const cantidad = useFormInput("");
  const precioUnitario = useFormInput("");
  const descuento = useFormInput("");
  const naturalezaDescuento = useFormInput("");
  const agregarExoneracion = useFormCheck(false);
  const tipoPrecio = useFormSelect("subTotal", tipoPrecios);
  const tipoDescuento = useFormSelect("porcentaje", tipoDescuentos);
  const productoInput = useRef();

  const setLinea = (precio = 0, productoData = {}) => {
    setProducto(productoData);
    setNuevaLinea(true);
    cantidad.setValue(1);
    precioUnitario.setValue(precio);
    descuento.setValue(0);
    naturalezaDescuento.setValue("Descuento");
  }

  const handleCancelarLinea = () => {
    setProducto({});
    setProductos([]);
    setNuevaLinea(false);
    setExoneracion({});
    setExoneraciones([]);
    cantidad.setValue("");
    precioUnitario.setValue("");
    descuento.setValue("");
    naturalezaDescuento.setValue("");
    agregarExoneracion.setValue(false);
  };

  const getData = () => ({
    nuevaLinea,
    producto,
    exoneracion,
    cantidad: cantidad.value,
    precioUnitario: precioUnitario.value,
    descuento: descuento.value,
    naturalezaDescuento: naturalezaDescuento.value,
  })

  const setData = (data = false) => {
    if (data) {
      setNuevaLinea(data.nuevaLinea);
      setProducto(data.producto);
      setExoneracion(data.exoneracion);
      cantidad.setValue(data.cantidad);
      precioUnitario.setValue(data.precioUnitario);
      descuento.setValue(data.descuento);
      naturalezaDescuento.setValue(data.naturalezaDescuento);
    }
  }

  return {
    getLinea: {
      producto,
      exoneracion,
      cantidad: cantidad.value,
      precioUnitario: precioUnitario.value,
      descuento: descuento.value,
      naturalezaDescuento: naturalezaDescuento.value,
      tipoPrecio: tipoPrecio.value,
      tipoDescuento: tipoDescuento.value
    },
    nuevaLinea,
    cantidad,
    precioUnitario,
    descuento,
    naturalezaDescuento,
    loadingProductos,
    producto,
    productos,
    productoInput,
    agregarExoneracion,
    exoneracion,
    exoneraciones,
    tipoPrecio,
    tipoDescuento,
    setExoneracion,
    setExoneraciones,
    setProductos,
    setLoadingProductos,
    setNuevaLinea,
    setLinea,
    handleCancelarLinea,
    getData,
    setData,
  };
}