import {Switch, Route} from "react-router-dom";
import {CustomRoute} from "./index";
import {
  HomePage, LoginPage, CuentaPage, SucursalPage, UsuarioPage, CodigoActividadPage, ExoneracionPage, ClientePage, ProductoPage, ConsecutivoPage,
  CajaPage, VentaPage, ComprobantePage, UnknownPage, ImportarProductosPage, ImportarClientesPage, AceptarFacturaPage, AceptarHistorialPage,
  VentaHistorialPage, ReportePage, ConfiguracionPage, PagosPage
} from "../pages"

const privatePages = [
  {path: "cuenta", page: CuentaPage}, {path: "sucursal", page: SucursalPage}, {path: "usuario", page: UsuarioPage}, {path: "configuracion", page: ConfiguracionPage},
  {path: "codigoActividad", page: CodigoActividadPage}, {path: "exoneracion", page: ExoneracionPage}, {path: "cliente", page: ClientePage}, {path: "pago", page: PagosPage},
  {path: "producto", page: ProductoPage}, {path: "consecutivo", page: ConsecutivoPage}, {path: "caja", page: CajaPage}, {path: "venta", page: VentaPage},
  {path: "ventaHistorial", page: VentaHistorialPage}, {path: "aceptarFactura", page: AceptarFacturaPage}, {path: "aceptarHistorial", page: AceptarHistorialPage},
  {path: "importarProductos", page: ImportarProductosPage}, {path: "importarClientes", page: ImportarClientesPage}, {path: "reportes", page: ReportePage}
];

export const CustomSwitch = ({controller}) => (
  <Switch>
    <Route path="/login"><LoginPage controller={controller} /></Route>
    <Route path="/comprobante/:id"><ComprobantePage /></Route>
    <CustomRoute exact path="/" session={controller.session} page={<HomePage controller={controller} />} />
    {privatePages.map((item, index) => <CustomRoute key={`page-${index}`} path={`/${item.path}`} session={controller.session} page={<item.page controller={controller} />} />)}
    <Route path="*"><UnknownPage /></Route>
  </Switch>
);