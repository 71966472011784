import {useState, useEffect, useRef} from "react";
import {CustomOption} from "../components";
import {ubicaciones} from "../constants";

export const useUbicacion = () => {
  const [provincia, setProvincia] = useState("1");
  const [canton, setCanton] = useState("1");
  const [distrito, setDistrito] = useState("1");
  const [barrio, setBarrio] = useState("1");
  const [otrasSenas, setOtrasSenas] = useState("");
  const cantones = useRef([]);
  const distritos = useRef([]);
  const barrios = useRef([]);

  useEffect(() => {
    cantones.current = ubicaciones().find(element => element.value === provincia).cantones;
    distritos.current = cantones.current.find(element => element.value === canton).distritos;
    barrios.current = distritos.current.find(element => element.value === distrito).barrios;
  }, []);

  const handleProvincia = (e) => {
    setProvincia(e.target.value);
    setCanton("1");
    setDistrito("1");
    setBarrio("1");
    cantones.current = ubicaciones().find(element => element.value === e.target.value).cantones;
    distritos.current = cantones.current.find(element => element.value === "1").distritos;
    barrios.current = distritos.current.find(element => element.value === "1").barrios;
  }

  const handleCanton = (e) => {
    setCanton(e.target.value);
    setDistrito("1");
    setBarrio("1");
    distritos.current = cantones.current.find(element => element.value === e.target.value).distritos;
    barrios.current = distritos.current.find(element => element.value === "1").barrios;
  }

  const handleDistrito = (e) => {
    setDistrito(e.target.value);
    setBarrio("1");
    barrios.current = distritos.current.find(element => element.value === e.target.value).barrios;
  }

  const handleBarrio = (e) => setBarrio(e.target.value);

  const handleOtrasSenas = (e) => setOtrasSenas(e.target.value);

  const displayProvincias = () => ubicaciones().map(element => <CustomOption key={element.key} item={element} />)

  const displayCantones = () => cantones.current.map(element => <CustomOption key={element.key} item={element} />)

  const displayDistritos = () => distritos.current.map(element => <CustomOption key={element.key} item={element} />);

  const displayBarrios = () => barrios.current.map(element => <CustomOption key={element.key} item={element} />);

  const reset = () => {
    setProvincia("1");
    setCanton("1");
    setDistrito("1");
    setBarrio("1");
    setOtrasSenas("");
    cantones.current = ubicaciones().find(element => element.value === "1").cantones;
    distritos.current = cantones.current.find(element => element.value === "1").distritos;
    barrios.current = distritos.current.find(element => element.value === "1").barrios;
  }

  const setObject = (data) => {
    setProvincia(String(data.provincia || "1"));
    setCanton(String(data.canton || "1"));
    setDistrito(String(data.distrito || "1"));
    setBarrio(String(data.barrio || "1"));
    setOtrasSenas(String(data.otrasSenas || ""));
    cantones.current = ubicaciones().find(element => element.value === (String(data.provincia || "1"))).cantones;
    distritos.current = cantones.current.find(element => element.value === (String(data.canton || "1"))).distritos;
    barrios.current = distritos.current.find(element => element.value === (String(data.distrito || "1"))).barrios;
  }

  return {
    getObject: {provincia, canton, distrito, barrio, otrasSenas},
    provincia: {value: provincia, onChange: handleProvincia},
    canton: {value: canton, onChange: handleCanton},
    distrito: {value: distrito, onChange: handleDistrito},
    barrio: {value: barrio, onChange: handleBarrio},
    otrasSenas: {value: otrasSenas, onChange: handleOtrasSenas},
    provincias: displayProvincias,
    cantones: displayCantones,
    distritos: displayDistritos,
    barrios: displayBarrios,
    setProvincia,
    setCanton,
    setDistrito,
    setBarrio,
    setOtrasSenas,
    reset,
    setObject,
    ubicaciones
  };
}
